import {useEffect, useState} from 'react';
import {parseQueryString} from '../../utils/string-utils';
import {useAuthorizeByCodeMutation, useRefreshMutation} from '../../queries-generated/types';
import useUser from './useUser';

export function makeCodeRedirect() {
	const url = `${process.env.REACT_APP_AUTH_SERVER}/auth/realms/${
		process.env.REACT_APP_AUTH_REALM
	}/protocol/openid-connect/auth?client_id=${
		process.env.REACT_APP_AUTH_CLIENT
	}&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(`${location.origin}`)}`;

	location.href = url;
}

function useAuth() {
	const [error, setError] = useState<any>();
	const [makeAuthorizeByCode] = useAuthorizeByCodeMutation();
	const [refresh] = useRefreshMutation();
	const {refreshTimeout} = useUser();
	const queryString = parseQueryString(location.search.slice(1));

	const [loading, setLoading] = useState<boolean>(!!queryString.code);

	useEffect(() => {
		if (refreshTimeout) {
			setInterval(() => refresh(), (refreshTimeout - 60) * 1000);
		}
		refresh();
		// TODO return
	}, [refreshTimeout, refresh]);

	useEffect(() => {
		if (queryString.code) {
			makeAuthorizeByCode({variables: {code: queryString.code}})
				.then(() => {
					setLoading(false);
					history.pushState(null, '', '/');
				})
				.catch(ex => {
					setLoading(false);
					setError(ex);
					location.href = '/';
				});
		}
	}, [makeAuthorizeByCode, queryString.code]);

	return {loading: loading, error: error};
}

export default useAuth;
