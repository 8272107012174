import React from 'react';
import css from './NoKeyWordSnippet.module.css';

const NoKeyWordSnippet: React.FC = () => {
	return (
		<div className={css.container}>
			<h3>Для отображения ресурсов выберите, пожалуйста, нужную сущность в боковом меню, разбитом на приказы</h3>
		</div>
	);
};

export default NoKeyWordSnippet;
