import {
	Dependence,
	DependenceValueType,
	DependencyFilter,
	Field,
	FieldControlType,
	FieldShownType,
	FieldType,
	FileValue,
	GridAction,
	GridForm,
	GridFormButton,
	GridFormButtonFieldValidation,
	LinkOptions,
	PossibleValue,
	RefValuesDocument,
} from '../../../../queries-generated/types';
import {Action, CommonFilterField, RequestedColumn, ViewItem} from '../../../view/api/useViewItemTree';
import {ApolloClient} from '@apollo/client';
import {indexBy} from '../../../../utils/array-utils';
import {Value as DateRangeValue} from '../../../pirsInputs/InputDateRange/InputDateRange';
import {filterObject, getValueByPath, setValueByPath} from '../../../../utils/object-utils';
import React, {ReactNode, useCallback, useMemo} from 'react';
import {formatBites, formatBitesPerSeconds, formatBytes, humanNumber} from '../../../../utils/number-utils';
import css from '../../../DataGrid/DataGrid.module.css';
import {getContrastYIQ} from '../../../../utils/css-utils';
import {format, parseIso} from 'ts-date/locale/ru';
import DataGrid, {MIN_CELL_WIDTH} from '../../../DataGrid/DataGrid';
import useLocalStorage from '../../../hooks/useLocalStorage';
import {isValidSubnet} from '../../../../utils/ip-utils';
import Diff from './showTypes/Diff/Diff';
import Button, {ButtonProps} from '../../../pirsInputs/Button/Button';
import DataGridActionButton from '../../../DataGrid/DataGridActionButton';
import DataGridLink from '../../../DataGrid/DataGridLink';
import getIcon from '../../../../utils/getIcon';
import humanJoin from '../../../../utils/humanJoin';
import {wordByCount} from '../../../../utils/word-by-count';
import stripHtmlToText from '../../../../utils/stripHtmlToText';
import SelectedContactsCount from '../../../pirsInputs/InputContacts/SelectedContactsCount';
import {CURRENT_DATE_CONST} from '../../../pirsInputs/InputDateTime/InputDateTime';
import GridActionButtonWithForm from '../../../DataGrid/GridActionButtonWithForm';

export const colors = {
	0: '#B00000',
	1: '#C41E3A',
	2: '#FF0000',
	3: '#FF8000',
	4: '#ffff00',
	5: '#00BFFF',
	6: '#CCCCCC',
	7: '#FFFFFF',
	8: '#008000',
	9: '#D2E5FF',
};

export type FieldTreeItem = string | FieldTreeItem[];

export type CustomForm = {
	id: string;
	title: string;
	fields: FieldTreeItem[];
	buttons: GridFormButton[];
	width?: number;
};

type DataGridLinkOptions = LinkOptions & {onClick?: (item: any | undefined, selectedIds?: string[]) => void};

export type DataGridAction = GridAction & {
	headAction?: DataGridLinkOptions;
	itemAction?: DataGridLinkOptions;
};

// TODO: С учетом новых типов
export function filterToRouteParam(value: any) {
	if (value.duration || value.from || value.to) {
		return (value.from || '').substr(0, 10) + '|' + (value.to || '').substr(0, 10) + '|' + (value.duration || '');
	}
	if (Array.isArray(value)) {
		return (value as PossibleValue[]).map(possibleValueToId).join(',');
	} else if (value?.id) {
		return possibleValueToId(value as PossibleValue);
	} else {
		return value;
	}
}

type RouteValueType = number | string | boolean | PossibleValue | PossibleValue[] | undefined | DateRangeValue;
function routeParamToFilter(value: string | PossibleValue[], field: Field): RouteValueType {
	switch (field.type) {
		case FieldType.String:
			return decodeURIComponent(value as string);
		case FieldType.Boolean:
			return value === 'true';
		case FieldType.Int:
		case FieldType.Float:
			const number = parseInt(value as string, 10);
			return isNaN(number) ? undefined : number;

		case FieldType.ModelPossibleValue: {
			if (value && field.possibleValuesRef && Array.isArray(value) && value.length) {
				return field.isArray ? value : value[0];
			}
			if (field.possibleValues) {
				if (field.isArray) {
					const arrayValues = (value as string).split(',');
					const filteredValues = field.possibleValues.filter(possibleValue =>
						arrayValues.includes(possibleValue.id),
					);
					return filteredValues.length ? filteredValues : undefined;
				} else {
					return field.possibleValues!.find(possibleValue => possibleValue.id === value);
				}
			}

			return;
		}
		case FieldType.Structure:
			switch (field.controlType) {
				case FieldControlType.DateRangeDuration:
					const [from, to, duration] = decodeURIComponent(value as string).split('|');
					return {from, to, duration};
			}
			return;

		default:
			return value.toString();
	}
}

export function possibleValueToId(possibleValue: PossibleValue | string): string {
	return typeof possibleValue === 'string' ? possibleValue : possibleValue.id;
}

export function filterToDataSourceParam(value: any) {
	if (Array.isArray(value)) {
		return (value as PossibleValue[]).map(possibleValueToId).join(',');
	} else if (value?.id) {
		return possibleValueToId(value as PossibleValue);
	} else {
		return value;
	}
}

export function filtersToDataSourceParams(
	values: PlainObjectOf<any> | undefined,
	requestedColumns?: RequestedColumn[],
): PlainObjectOf<any> {
	const newValues: PlainObjectOf<any> = {};

	if (requestedColumns && requestedColumns.length) {
		newValues.columns = requestedColumns;
	}

	if (values) {
		for (const key of Object.keys(values)) {
			const value = values[key];
			newValues[key] = filterToDataSourceParam(value);
		}
	}

	return newValues;
}

// FIXME: Убрать, кажется этоткак-йто хуевый формат
// TODO Убрать если не будем хранить общие фильтры в формате {[guid]: {[fieldId]: value}}
export function commonFiltersFieldsToFormValues(commonFiltersFields?: CommonFilterField[]): PlainObjectOf<any> {
	if (commonFiltersFields && commonFiltersFields.length) {
		return commonFiltersFields.reduce((values, field) => {
			values[field.guid] = {[field.id]: field.defaultValue ?? null};

			return values;
		}, {});
	}
	return {};
}

// TODO Убрать если не будем хранить общие фильтры в формате {[guid]: {[fieldId]: value}}
export function formValuesToCommonFiltersFields(values: PlainObjectOf<any>, commonFiltersFields: CommonFilterField[]) {
	const filters = {};

	commonFiltersFields.forEach(field => {
		if (field.id in values) {
			filters[field.guid] = {[field.id]: values[field.id]};
		}
	});

	return filters;
}

// TODO Убрать если не будем хранить общие фильтры в формате {[guid]: {[fieldId]: value}}
export function flatCommonFilters(commonFilters?: PlainObjectOf<any>): PlainObjectOf<any> {
	if (!commonFilters) return {};
	let filters = {};
	for (const guid in commonFilters) {
		filters = {...filters, ...commonFilters[guid]};
	}
	return filters;
}

export function filtersToRouteParams(values: PlainObjectOf<any>) {
	const params = {};

	for (const key in values) {
		if (values[key]) params[key] = filterToRouteParam(values[key]);
	}

	return params;
}

export function getLinkedFiltersValues(commonRootFiltersValues: PlainObjectOf<any>, viewItem?: ViewItem | null) {
	if (
		viewItem?.dataSource?.commonFilters &&
		viewItem.dataSource.commonFilters.length &&
		commonRootFiltersValues &&
		Object.keys(commonRootFiltersValues).length
	) {
		return viewItem.dataSource.commonFilters.reduce((linkedFiltersResult, commonFilterGuid) => {
			if (commonRootFiltersValues[commonFilterGuid]) {
				const filteredFilters = filterObject(
					commonRootFiltersValues[commonFilterGuid],
					value => value !== undefined,
				);
				linkedFiltersResult = {...linkedFiltersResult, ...filteredFilters};
			}
			return linkedFiltersResult;
		}, {});
	}
	return {};
}

export async function resolveFiltersFromRouter(
	fields: Field[],
	routeParams: PlainObjectOf<any>,
	apolloClient: ApolloClient<any>,
): Promise<PlainObjectOf<RouteValueType>> {
	const availableFields = fields.filter(field => routeParams[field.id]);

	if (!availableFields.length) return {};

	const refsInput = availableFields
		.filter(field => field.possibleValuesRef)
		.map(field => ({
			refType: field.possibleValuesRef,
			ids: routeParams[field.id].split(','),
			field: field.id,
		}));

	if (refsInput.length) {
		const result = await apolloClient
			.query({query: RefValuesDocument, variables: {input: refsInput}})
			.then(result => indexBy<any, any>(result.data.refValues, 'field'));
		if (result) {
			return availableFields.reduce((filters, field) => {
				if (result[field.id]) {
					if (field.possibleValuesRef) {
						filters[field.id] = routeParamToFilter(result[field.id].values, field);
					}
				} else {
					filters[field.id] = routeParamToFilter(routeParams[field.id], field);
				}
				return filters;
			}, {});
		} else {
			return {};
		}
	} else {
		return availableFields.reduce((filters, field) => {
			filters[field.id] = routeParamToFilter(routeParams[field.id], field);
			return filters;
		}, {});
	}
}

export function isNotifiedDataSource(viewItemActions?: Action[]): boolean {
	if (!viewItemActions || !viewItemActions.length) return false;
	return !!viewItemActions.find(action => action.props.isNotified);
}

export function flatViewItems(viewItem: ViewItem): ViewItem[] {
	let items: ViewItem[] = [];
	items.push(viewItem);
	if (viewItem.children) {
		for (const child of viewItem.children) {
			items = items.concat(flatViewItems(child));
		}
	}
	return items;
}

export const VALIDATION_REQUIRED_TEXT = 'Это поле обязательно для заполнения';
const VALIDATION_EMAIL_TEXT = 'Некорректный адрес почты';
const VALIDATION_JSON_TEXT = 'Некорректный формат JSON';
const VALIDATION_SUBNET_TEXT =
	'Данные по IPv4 или IPv6 подсети необходимо вводить в нотации CIDR, например, 192.168.1.0/24';
const VALIDATION_UNKNOWN_FORMAT = 'Некорректный формат';
const VALIDATION_DATE_MIN = 'Дата слишком ранняя';
const VALIDATION_DATE_MAX = 'Дата слишком поздняя';
const VALIDATION_FILES_LENGTH_MIN = (minFilesLength: number) =>
	`Нужно загрузить не менее ${wordByCount(minFilesLength, ['файла', 'файлов', 'файлов'], true)}`;

const EMAIL_REGEXP = /^(.+@.+\..+)?$/;
// eslint-disable-next-line no-useless-escape
const JSON_REGEXP = /[^,:{}\[\]0-9.\-+Eaeflnr-u \n\r\t]/;

function validRequiredModelPossibleValue(field: Field, value: any): boolean {
	if (field.isArray && Array.isArray(value) && value.length) return true;
	if (!field.isArray && !Array.isArray(value) && value !== null && typeof value === 'object') return true;
	return false;
}

// Возвращает текст ошибки если поле не валидно
export function validateField(
	field: Field,
	value: any,
	buttonValidation?: GridFormButtonFieldValidation,
): string | undefined {
	const required = buttonValidation?.required ?? field.required;
	const regExp = buttonValidation?.regExp ?? field.validation?.regExp;
	const errorText = buttonValidation?.errorText ?? field.validation?.errorText;

	if (required) {
		if (value === null || value === undefined) return VALIDATION_REQUIRED_TEXT;
		if (field.controlType === FieldControlType.Contacts && (value === null || !value.ids || !value.ids.length))
			return VALIDATION_REQUIRED_TEXT;
		if (field.type === FieldType.String && !value) return VALIDATION_REQUIRED_TEXT;
		if (field.type === FieldType.ModelPossibleValue && !validRequiredModelPossibleValue(field, value)) {
			return VALIDATION_REQUIRED_TEXT;
		}
	}

	if (regExp) {
		if (!new RegExp(regExp).test(value?.toString())) return errorText || VALIDATION_UNKNOWN_FORMAT;
	} else {
		const controlType = getControlType(field);

		let error = '';

		switch (controlType) {
			case FieldControlType.Email:
				if (!EMAIL_REGEXP.test(value)) error = VALIDATION_EMAIL_TEXT;
				break;
			case FieldControlType.Json:
				if (!JSON_REGEXP.test(value)) error = VALIDATION_JSON_TEXT;
				break;
			case FieldControlType.Subnet:
				if (!isValidSubnet(value)) error = VALIDATION_SUBNET_TEXT;
				break;
			case FieldControlType.Date:
			case FieldControlType.DateTime:
				if (field.max) {
					const max = field.max === CURRENT_DATE_CONST ? new Date().toISOString() : field.max;
					if (value > max) error = VALIDATION_DATE_MAX;
				}
				if (field.min) {
					const min = field.min === CURRENT_DATE_CONST ? new Date().toISOString() : field.min;
					if (value < min) error = VALIDATION_DATE_MIN;
				}
				break;
			case FieldControlType.File:
				if (field.isArray && field.min && (value || []).length < field.min) {
					error = VALIDATION_FILES_LENGTH_MIN(field.min);
				}
				break;
		}

		if (error) {
			return errorText || error;
		}
	}
}

export function validateFields(
	fields: Field[],
	values: PlainObjectOf<PossibleValue | PossibleValue[] | number | string | null | undefined>,
	buttonValidation?: GridFormButtonFieldValidation[],
): PlainObjectOf<string> {
	const errors = {};

	fields.forEach(field => {
		const value = getValueByPath(values, field.id);
		const errorMessage = validateField(
			field,
			value,
			buttonValidation?.find(validation => validation.id === field.id),
		);
		if (errorMessage) {
			errors[field.id] = errorMessage;
		}
	});

	return errors;
}

export function getControlType(field: Field) {
	if (field.controlType) return field.controlType;

	switch (field.type) {
		case FieldType.String:
			return FieldControlType.String;
		case FieldType.Float:
		case FieldType.Int:
			return FieldControlType.Number;
		case FieldType.DateTime:
			return FieldControlType.DateTime;
		case FieldType.Date:
			return FieldControlType.Date;
		case FieldType.Boolean:
			return FieldControlType.CheckBox;
		default:
			return null;
	}
}

export function getShowType(field: Field) {
	if (field.showType) return field.showType;

	switch (field.type) {
		case FieldType.String:
			return FieldShownType.String;
		case FieldType.Float:
		case FieldType.Int:
			return FieldShownType.Number;
		case FieldType.DateTime:
			return FieldShownType.DateTimeLong;
		case FieldType.Date:
			return FieldShownType.DateLong;
		case FieldType.Boolean:
			return FieldShownType.YesNo;
		default:
			return null;
	}
}

export function isFieldFilled(value: any, field: Field) {
	const controlType = getControlType(field);

	switch (controlType) {
		case FieldControlType.CheckBox:
		case FieldControlType.Boolean:
			return true;
		case FieldControlType.Date:
		case FieldControlType.DateTime:
		case FieldControlType.Number:
		case FieldControlType.Select:
			return value != null;
		case FieldControlType.Combobox:
			return field.isArray ? value != null && value.length > 0 : value != null;
		default:
			return !!value;
	}
}

export type FieldProps = {
	disabled?: boolean;
	enabled?: boolean;
	filters?: DependencyFilter[];
	helperTexts?: string[];
};

export type FieldsProps = PlainObjectOf<FieldProps>;

export function checkFieldDependency(dependence: Dependence, masterValue: any, masterField: Field) {
	if (!dependence.valueType) return true;

	const primitiveCustomValues = dependence.values;
	const primitiveMasterValue = fieldValueToPrimitiveValue(masterValue);
	switch (dependence.valueType) {
		case DependenceValueType.Filled:
			return isFieldFilled(masterValue, masterField);
		case DependenceValueType.Nullable:
			return !isFieldFilled(masterValue, masterField);
		case DependenceValueType.CustomAnd:
			if (!primitiveCustomValues || primitiveCustomValues.length === 0) return false;
			return primitiveCustomValues?.every(customValue => {
				if (Array.isArray(primitiveMasterValue)) {
					return primitiveMasterValue.includes(customValue);
				} else {
					return primitiveMasterValue == customValue;
				}
			});
		case DependenceValueType.CustomOr:
			if (!primitiveCustomValues || primitiveCustomValues.length === 0) return false;
			return primitiveCustomValues?.some(customValue => {
				if (Array.isArray(primitiveMasterValue)) {
					return primitiveMasterValue.includes(customValue);
				} else {
					return primitiveMasterValue == customValue;
				}
			});
	}
}

export function fieldValueToPrimitiveValue(value: any) {
	if (Array.isArray(value)) {
		return (value as PossibleValue[]).map(possibleValueToId);
	} else if (value?.id) {
		return possibleValueToId(value);
	} else {
		return value;
	}
}

export function flatFields(fields: Field[], visible?: boolean, path?: string): Field[] {
	let newFields: Field[] = [];

	for (let i = 0; i < fields.length; i++) {
		const field = fields[i];
		const currentPath = path ? path + '.' + field.id : field.id;
		if (field.fields) {
			const parentFields = flatFields(field.fields, visible, currentPath);
			newFields = [...newFields, ...parentFields];
		}

		if ((visible === true && field.visible) || (visible === false && field.detail) || visible == null)
			newFields.push({...field, id: currentPath});
	}

	return newFields;
}

export function getItemByField(field: Field, item: PlainObjectOf<any>) {
	return getValueByPath(item, field.id);
}

const fileToString = (file: FileValue) => (
	<>
		{file.title} ({formatBites(file.size)})
	</>
);

export function resolveTypesInItem(
	value: any,
	field: Field,
	showType: FieldShownType | null,
	item: PlainObjectOf<any>,
	details: boolean,
	dataSourceId: string,
	reload: () => void,
	fields?: Field[],
	forms?: GridForm[],
): ReactNode {
	let result: ReactNode = '';

	switch (showType) {
		case FieldShownType.String:
			result = value ? (
				<span dangerouslySetInnerHTML={{__html: stripHtmlToText(value).replace(/\n/g, '<br />')}} />
			) : (
				''
			);
			break;
		case FieldShownType.Toggle:
		case FieldShownType.YesNo:
			result = value ? 'Да' : 'Нет';
			break;
		case FieldShownType.Number:
			const r = parseFloat(value + '');
			result = isNaN(r) ? '' : humanNumber(r);
			break;
		case FieldShownType.Bytes:
			result = formatBytes(value);
			break;
		case FieldShownType.Bits:
			result = formatBites(value);
			break;
		case FieldShownType.BitsPerSeconds:
			result = formatBitesPerSeconds(value);
			break;
		case FieldShownType.Status:
			if (!value || !value.title) result = '';

			result = (
				<div className={css.status}>
					<div
						style={{
							backgroundColor: colors[value.value],
						}}
					/>
					{value.title}
				</div>
			);
			break;
		case FieldShownType.Severity:
			if (!value || !value.title) result = '';

			const fontColor = colors[value.value] && getContrastYIQ(colors[value.value]);
			result = (
				<div
					style={{
						backgroundColor: colors[value.value],
						color: fontColor,
					}}
					className={css.severity}
				>
					{value.title}
				</div>
			);
			break;
		case FieldShownType.Link: // Для случаев когда Field type Structure (не путать с shownType)
			if (!value || !value.title) return '';

			result = resolveLinkByItemAndLinkOptions(
				value.title,
				value,
				{dataSourceId, reload, item, massAction: false, fields, forms},
				{link: true},
			);
			break;
		case FieldShownType.DateLong:
			result = format(parseIso(value), 'DD MMMM YYYY');
			break;
		case FieldShownType.DateTimeLong:
			result = format(parseIso(value), 'DD MMMM YYYY, в HH:mm:ss');
			break;
		case FieldShownType.DateRangeDuration:
			result = `С ${format(parseIso(value.from), 'DD MMMM YYYY')} по ${format(
				parseIso(value.to),
				'DD MMMM YYYY',
			)}`;
			break;
		case FieldShownType.Phone:
			// TODO: ПРАВИЛЬНО форматировать номер. Нужна либа какая-то со словарями кодов стран и форматом локальных номеров
			const phoneRegExp = new RegExp(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);

			if (field.isArray && Array.isArray(value)) {
				const phones = value.map(number => {
					const formatPhone = number.toString().replace(phoneRegExp, '+$1($2)$3-$4-$5');
					return <a href={`tel:+${number}`}>{formatPhone}</a>;
				});

				result = phones.length > 0 ? humanJoin(phones) : null;
			} else {
				const phone = value ? value.toString().replace(phoneRegExp, '+$1($2)$3-$4-$5') : '';

				result = phone ? <a href={`tel:+${value}`}>{phone}</a> : null;
			}

			break;
		case FieldShownType.Email:
			if (field.isArray && Array.isArray(value)) {
				const emails = value.map(mail => {
					return <a href={`mailto:${mail}`}>{mail}</a>;
				});

				result = emails.length > 0 ? humanJoin(emails) : null;
			} else {
				result = value ? <a href={`mailto:${value}`}>{value}</a> : null;
			}

			break;
		case FieldShownType.Diff:
			result = value ? <Diff value={value} details={!!details} isArray={!!field.isArray} /> : null;
			break;
		case FieldShownType.Icon:
			result = value ? getIcon(value) : null;
			break;
		case FieldShownType.Contacts:
			result = value ? <SelectedContactsCount value={value} /> : null;
			break;
		case FieldShownType.File:
			if (!value) result = null;
			else if (!details || (details && !field.isArray)) {
				result = !value ? null : field.isArray ? humanJoin(value.map(fileToString)) : fileToString(value);
			} else {
				result = (
					<DataGrid
						className={css.filesTable}
						options={{
							theme: 'neutral',
							fields: [
								{id: 'title', title: 'Название', visible: true, type: FieldType.String},
								{
									id: 'size',
									title: 'Размер',
									visible: true,
									type: FieldType.String,
									showType: FieldShownType.Bits,
									width: 100,
								},
							],
						}}
						items={value}
					/>
				);
			}
			break;
		default:
			result = value ? value.toString() : value;
	}

	// Для случаев, когда field имеет тип отличный от FieldShownType.Link, но имеет linkOptions
	if (field.linkOptions && result) {
		result = resolveLinkByItemAndLinkOptions(
			result,
			field.linkOptions,
			{dataSourceId, reload, item, massAction: false, fields, forms},
			{link: true},
		);
	}

	if (field.isGeneral) {
		result = <div className={css.generalField}>{result}</div>;
	}

	return result;
}

export function resolveLinkByItemAndLinkOptions(
	content: React.ReactNode,
	linkOptions: DataGridLinkOptions,
	options: {
		item?: any;
		selectedIds?: string[];
		dataSourceId: string;
		reload();
		massAction: boolean;
		fields?: Field[];
		forms?: GridForm[];
	},

	buttonProps?: Partial<Omit<ButtonProps, 'onClick'>>,
) {
	const {dataSourceId, reload, item, selectedIds, massAction} = options;

	const confirmObject = linkOptions.confirmTitle
		? {confirmTitle: linkOptions.confirmTitle, confirmText: linkOptions.confirmText}
		: null;

	if (linkOptions.url) {
		let url = '';
		if (massAction) {
			const parsedUrl = new URL(url);
			if (selectedIds?.length) parsedUrl.searchParams.append('ids', selectedIds.join(','));
			url = parsedUrl.toString();
		} else if (item) {
			url = linkOptions.url.replace(/{(.*?)}/g, (_x, fieldId) => {
				return item[fieldId];
			});
		} else {
			url = linkOptions.url;
		}

		return (
			<DataGridLink {...buttonProps} href={url} target={linkOptions.target}>
				{content}
			</DataGridLink>
		);
	} else if (linkOptions.route || linkOptions.params) {
		const params: PlainObjectOf<any> = {};

		if (linkOptions.params) {
			for (const paramKey in linkOptions.params) {
				params[paramKey] =
					typeof linkOptions.params[paramKey] === 'string' && item
						? linkOptions.params[paramKey].replace(/{(.*?)}/g, (_x, fieldId) => {
								return item[fieldId];
						  })
						: linkOptions.params[paramKey];
			}
		}

		if (massAction && selectedIds?.length) {
			params.ids = selectedIds.join(',');
		}

		return (
			<DataGridLink {...buttonProps} route={linkOptions.route} params={params} target={linkOptions.target}>
				{content}
			</DataGridLink>
		);
	} else if (linkOptions.onClick) {
		return (
			<DataGridActionButton
				{...buttonProps}
				dataSourceId={dataSourceId}
				item={item}
				selectedIds={selectedIds}
				massAction={massAction}
				onClick={() => {
					linkOptions.onClick!(item, selectedIds);
				}}
				{...confirmObject}
			>
				{content}
			</DataGridActionButton>
		);
	} else if (linkOptions.gridAction) {
		return (
			<DataGridActionButton
				{...buttonProps}
				actionName={linkOptions.gridAction}
				dataSourceId={dataSourceId}
				item={item}
				selectedIds={selectedIds}
				reload={reload}
				massAction={massAction}
				{...confirmObject}
			>
				{content}
			</DataGridActionButton>
		);
	} else if (linkOptions.gridForm) {
		const form = options.forms?.find(form => form.id === linkOptions.gridForm);
		if (form) {
			return (
				<GridActionButtonWithForm
					{...buttonProps}
					dataSourceId={dataSourceId}
					form={form}
					fields={options.fields}
					reload={reload}
					id={item?.id}
					selectedIds={selectedIds}
					massAction={massAction}
				>
					{content}
				</GridActionButtonWithForm>
			);
		} else {
			return <Button {...buttonProps}>{content}</Button>;
		}
	} else {
		return content;
	}
}

export function defaultFormEmptyValues(fields: Field[]) {
	const values: PlainObjectOf<any> = {};
	fields.forEach(field => {
		const type = getControlType(field);
		switch (type) {
			case FieldControlType.Boolean:
			case FieldControlType.CheckBox:
				values[field.id] = field.defaultValue || false;
				break;
			default:
				values[field.id] = field.defaultValue || null;
				break;
		}
	});
	return values;
}

export function calculateTableWidths(
	fields: Field[],
	options: {
		selectable?: boolean;
		groupFieldsIds: string[];
		actionButtons?: DataGridAction[];
		hasDetails: boolean;
	},
) {
	if (!fields.length) return;

	const {selectable, actionButtons, groupFieldsIds, hasDetails} = options;

	const columnsWidth: Map<string, string> = new Map();

	let actionsWidth = 0;
	const actionButtonsCount = [selectable, ...(actionButtons?.map(Boolean) || []), groupFieldsIds.length].filter(
		Boolean,
	).length;
	if (actionButtonsCount > 0) actionsWidth += actionButtonsCount * 34 + (actionButtonsCount - 1) * 4;
	if (actionsWidth) columnsWidth.set('_actions', actionsWidth + 4 + 'px');

	for (let i = 0; i < fields.length; i++) {
		const field = fields[i];
		if (field.showType === FieldShownType.Icon) {
			columnsWidth.set(field.id, `40px`);
		} else if (field.width) {
			columnsWidth.set(field.id, `${field.width}px`);
		} else if (field.minWidth || field.maxWidth) {
			columnsWidth.set(
				field.id,
				`minmax(${field.minWidth || MIN_CELL_WIDTH}px, ${field.maxWidth ? field.maxWidth + 'px' : '1fr'})`,
			);
		} else {
			columnsWidth.set(field.id, `minmax(${MIN_CELL_WIDTH}px, 1fr)`);
		}
	}

	if (hasDetails) columnsWidth.set('_details', 36 + 'px');

	return columnsWidth;
}

export enum RefreshType {
	NONE = 'none',
	COMPONENT = 'component',
	DASHBOARD = 'dashboard',
}

export function getPollInterval(
	viewItem: ViewItem,
	root: ViewItem,
	localStorageViewItemRefreshInterval?: number,
	localStorageRootRefreshInterval?: number,
): number {
	const refreshActionItem =
		viewItem.actions && viewItem.actions.length && viewItem.actions.find(action => action.props.refreshType);

	if (refreshActionItem) {
		const {refreshType, refreshInterval} = refreshActionItem.props;

		if (viewItem.id === 'root') {
			return typeof localStorageRootRefreshInterval === 'number'
				? localStorageRootRefreshInterval
				: refreshInterval || 0;
		}

		switch (refreshType.id) {
			case RefreshType.COMPONENT:
				return typeof localStorageViewItemRefreshInterval === 'number'
					? localStorageViewItemRefreshInterval
					: refreshInterval || 0;
			case RefreshType.DASHBOARD: {
				if (typeof localStorageRootRefreshInterval === 'number') return localStorageRootRefreshInterval;
				const refreshActionRoot =
					root?.actions && root.actions.length && root.actions?.find(action => action.props.refreshType);

				if (refreshActionRoot) {
					return refreshActionRoot.props.refreshInterval || 0;
				}
				return 0;
			}
			case RefreshType.NONE:
				return 0;
			default:
				return 0;
		}
	} else {
		return 0;
	}
}

function getRefreshIntervalPath(dashboardId?: string, version?: number, viewItemId?: string): string | undefined {
	if (!dashboardId || version === undefined || !viewItemId) return;

	return `${dashboardId}:${version}.${viewItemId}`;
}

export function useRefreshIntervalFromLocalStorage(dashboardId?: string, version?: number, viewItem?: ViewItem) {
	const [refreshIntervals, setRefreshIntervals, clearRefreshIntervals] = useLocalStorage<PlainObjectOf<any>>(
		'dashboardRefreshIntervals',
		{},
	);

	const viewItemRefreshIntervalPath = useMemo(() => {
		return getRefreshIntervalPath(dashboardId, version, viewItem?.id);
	}, [dashboardId, version, viewItem?.id]);

	const viewItemRefreshInterval = useMemo((): number | undefined => {
		if (!viewItemRefreshIntervalPath) return;
		return getValueByPath(refreshIntervals, viewItemRefreshIntervalPath);
	}, [refreshIntervals, viewItemRefreshIntervalPath]);

	const rootRefreshInterval = useMemo((): number | undefined => {
		const rootRefreshIntervalPath = getRefreshIntervalPath(dashboardId, version, 'root');
		if (!rootRefreshIntervalPath) return;

		return getValueByPath(refreshIntervals, rootRefreshIntervalPath);
	}, [refreshIntervals, dashboardId, version]);

	const getRefreshInterval = useCallback(
		(dashboardId?: string, version?: number, viewItemId?: string): number | undefined => {
			const path = getRefreshIntervalPath(dashboardId, version, viewItemId);
			if (!path) return;
			return getValueByPath(refreshIntervals, path);
		},
		[refreshIntervals],
	);

	const setRefreshInterval = useCallback(
		(interval: number): void => {
			if (viewItemRefreshIntervalPath) {
				const newObj = {...refreshIntervals};
				setValueByPath(newObj, viewItemRefreshIntervalPath, interval);
				setRefreshIntervals(newObj);
			}
		},
		[refreshIntervals, viewItemRefreshIntervalPath, setRefreshIntervals],
	);

	return {
		refreshIntervals,
		viewItemRefreshInterval,
		rootRefreshInterval,
		getRefreshInterval,
		setRefreshInterval,
		clearRefreshIntervals,
	};
}

export function uniqueFieldKey(field: Field | CommonFilterField): string {
	return `${field.id}:${field.type}`;
}
