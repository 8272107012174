import React from 'react';
import FallbackComponent from './FallbackComponent';

class ErrorBoundary extends React.Component<{}, {hasError: boolean}> {
	state = {hasError: false};

	static getDerivedStateFromError() {
		return {hasError: true};
	}

	render() {
		const {hasError} = this.state;

		if (hasError) {
			return <FallbackComponent />;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
