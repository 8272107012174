import React, {useCallback, useState} from 'react';
import {usePirsGetSessionQuery, usePirsCloseSessionMutation, PirsGetSessionQuery} from '../../queries-generated/types';
import css from './ResourceRegisterAccumulatedChanges.module.css';
import Button from '../pirsInputs/Button/Button';
import CustomErrorPopup from '../controlls/CustomErrorPopup/CustomErrorPopup';
import {format} from 'ts-date/locale/ru';
import {ApolloError} from '@apollo/client';
import ModalPopup from '../controlls/ModalPopup/ModalPopup';
import {getError} from '../../utils/error';

type Props = {
	data?: PirsGetSessionQuery;
	loading: boolean;
	error?: ApolloError;
	reFetchSession: () => void;
};

const AccumulatedChangesWithPresentData: React.FC<Props> = ({data, error, loading, reFetchSession}) => {
	const [
		applyData,
		{data: mutationData, error: mutationError, loading: mutationLoading},
	] = usePirsCloseSessionMutation();

	const handleApplyData = useCallback(() => {
		applyData().then(() => {
			reFetchSession();
		});
	}, [applyData, reFetchSession]);

	const [showError, setShowError] = useState<boolean>(false);

	const handleOpenError = useCallback(() => {
		setShowError(true);
	}, [setShowError]);

	const handleCloseError = useCallback(() => {
		setShowError(false);
	}, [setShowError]);

	return (
		<div className={css.container}>
			{mutationError && <CustomErrorPopup error={mutationError} reFetch={handleApplyData} />}
			<ModalPopup header={'Ошибка'} open={showError} maxWidth={600} onClose={handleCloseError}>
				{getError(error)?.message}
			</ModalPopup>
			<div className={css.message}>
				{loading ? (
					<span>Загружаются сведения о накопленных изменениях...</span>
				) : error ? (
					<div className={css.errorMessage}>
						Произошла ошибка загрузки накопленных изменений.{' '}
						<span className={css.showError} onClick={handleOpenError}>
							Показать ошибку
						</span>
					</div>
				) : data?.pirsGetSession ? (
					<span>
						Имеются накопленные данные начиная с{' '}
						{format(new Date(data.pirsGetSession), 'DD MMMM YYYY, HH:mm')} еще не переданные в ЦМУ ССОП
					</span>
				) : (
					<span>Накопленных данных на данный момент нет</span>
				)}
			</div>
			<Button
				loading={loading || mutationLoading}
				disabled={(!error && !data?.pirsGetSession) || loading || mutationLoading}
				className={css.presentData}
				onClick={error ? reFetchSession : handleApplyData}
			>
				{data?.pirsGetSession
					? 'Представить данные в ЦМУ ССОП'
					: mutationData?.pirsCloseSession
					? 'Данные успешно представлены'
					: error
					? 'Обновить'
					: 'накопленных данных нет'}
			</Button>
		</div>
	);
};

const useAccumulatedChangesWithPresentData = () => {
	const {data, loading, error, refetch} = usePirsGetSessionQuery({
		fetchPolicy: 'cache-and-network',
		notifyOnNetworkStatusChange: true,
	});

	const handleReFetchSession = useCallback(() => {
		refetch();
	}, [refetch]);

	return {
		reFetchSession: handleReFetchSession,
		AccumulatedChanges: (
			<AccumulatedChangesWithPresentData
				loading={loading}
				reFetchSession={handleReFetchSession}
				error={error}
				data={data}
			/>
		),
	};
};

export default useAccumulatedChangesWithPresentData;
