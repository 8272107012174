import * as React from 'react';
import css from '../SurveyResourceAsPage/SurveyResourceAsPage.module.css';
import MainLayout from '../../components/layouts/MainLayout';
import {useRouter} from 'react-named-hooks-router';
import {AnomaliesRoutes} from '../AlertsSuperAnomaliesActivePage/AlertsSuperAnomaliesActivePage';
import View from '../../components/view/View';

const AlertsSuperAnomaliesResourceOwnersPage: React.FC = () => {
	const {routeParams} = useRouter<{id: string}>();

	return (
		<MainLayout className={css.container} routes={AnomaliesRoutes}>
			<View
				id={'a28ae4c5-4cf1-48b7-be10-63e83b36a36d'}
				filters={{
					anomalyId: parseInt(routeParams.id, 10),
				}}
			/>
		</MainLayout>
	);
};

export default AlertsSuperAnomaliesResourceOwnersPage;
