import * as React from 'react';
import AuthLayout from '../../components/layouts/AuthLayount';
import ProgressRing from '../../components/controlls/Loader/ProgressRing';
import Card, {CardItem} from '../../components/controlls/Card/Card';

const AuthorizePage: React.FC = () => {
	return (
		<AuthLayout>
			{process.env.REACT_APP_CROP === 'true' ? (
				<Card>
					<CardItem
						header={
							'Для входа в ЦМУ ССОП, воспользуйтесь «Порталом Заявителей» Федеральной службы по надзору в сфере связи, информационных технологий и массовых коммуникаций'
						}
					/>
				</Card>
			) : (
				<ProgressRing />
			)}
		</AuthLayout>
	);
};

export default AuthorizePage;
