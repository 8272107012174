import {DependencyFilter, DependenceBehaviour, Field} from '../../queries-generated/types';
import {useEffect, useMemo, useState} from 'react';
import {checkFieldDependency, FieldsProps, fieldValueToPrimitiveValue} from '../settings/views/components/helper';

type Props = {
	fields: Field[];
	values: PlainObjectOf<any>;
	setFieldValue: (field: string, value: any) => void;
};

function useDependencies(props: Props) {
	const {fields, values, setFieldValue} = props;

	const fieldsWithDependencies = useMemo(() => {
		return fields && fields.filter(field => field.slaveDependencies || field.masterDependencies);
	}, [fields]);

	const [fieldsProps, setFieldsProps] = useState<FieldsProps>({});

	useEffect(() => {
		if (!fieldsWithDependencies || !fields) return;

		const newFieldsProps: FieldsProps = {};

		fieldsWithDependencies.forEach(field => {
			newFieldsProps[field.id] = {};

			field.slaveDependencies &&
				field.slaveDependencies.forEach(dependency => {
					const checkResult = dependency.masters.every(master => {
						const masterField = fields.find(field => field.id === master.fieldId);
						if (!masterField) return false;
						return checkFieldDependency(master, values[master.fieldId], masterField);
					});

					let disabledTriggered = false;
					let enabledTriggered = false;
					let filtersTriggered = false;

					switch (dependency.behaviour) {
						case DependenceBehaviour.Disable:
							if (checkResult) disabledTriggered = true;
							if (!newFieldsProps[field.id].disabled) newFieldsProps[field.id].disabled = checkResult;
							break;
						case DependenceBehaviour.Enable:
							if (checkResult) enabledTriggered = true;
							if (!newFieldsProps[field.id].enabled) newFieldsProps[field.id].enabled = checkResult;
							break;
						case DependenceBehaviour.Filter:
							if (checkResult) {
								if (checkResult) filtersTriggered = true;
								const filters: DependencyFilter[] = [];

								dependency.masters.forEach(master => {
									const masterField = fields.find(field => field.id === master.fieldId);
									if (!masterField) return;
									const value = fieldValueToPrimitiveValue(values[master.fieldId]);
									filters.push({
										fieldId: master.fieldId,
										refType: masterField.possibleValuesRef,
										values: Array.isArray(value) ? value : [value],
									});
								});

								newFieldsProps[field.id].filters = newFieldsProps[field.id].filters
									? [...newFieldsProps[field.id].filters!, ...filters]
									: filters;
							}
					}
					if (
						dependency.helperText &&
						(disabledTriggered ||
							(dependency.behaviour === DependenceBehaviour.Enable && !enabledTriggered) ||
							filtersTriggered)
					) {
						if (!newFieldsProps[field.id].helperTexts) newFieldsProps[field.id].helperTexts = [];
						newFieldsProps[field.id].helperTexts!.push(dependency.helperText!);
					}
				});

			field.masterDependencies &&
				field.masterDependencies.forEach(dependency => {
					const checkResult = dependency.slaves.every(slave => {
						const slaveField = fields.find(field => field.id === slave.fieldId);
						if (!slaveField) return false;
						return checkFieldDependency(slave, values[field.id], field);
					});

					let disabledTriggered = false;
					let enabledTriggered = false;
					let filtersTriggered = false;

					switch (dependency.behaviour) {
						case DependenceBehaviour.Disable:
							if (checkResult) disabledTriggered = true;
							dependency.slaves.forEach(slave => {
								if (!newFieldsProps[slave.fieldId]) newFieldsProps[slave.fieldId] = {};
								if (!newFieldsProps[slave.fieldId].disabled)
									newFieldsProps[slave.fieldId].disabled = checkResult;
							});

							break;
						case DependenceBehaviour.Enable:
							if (checkResult) enabledTriggered = true;
							dependency.slaves.forEach(slave => {
								if (!newFieldsProps[slave.fieldId]) newFieldsProps[slave.fieldId] = {};
								if (!newFieldsProps[slave.fieldId].enabled)
									newFieldsProps[slave.fieldId].enabled = checkResult;
							});
							break;
						case DependenceBehaviour.Filter:
							if (checkResult) {
								filtersTriggered = true;
								const value = fieldValueToPrimitiveValue(values[field.id]);
								const filter: DependencyFilter = {
									fieldId: field.id,
									refType: field.possibleValuesRef,
									values: Array.isArray(value) ? value : [value],
								};
								dependency.slaves.forEach(slave => {
									if (!newFieldsProps[slave.fieldId]) newFieldsProps[slave.fieldId] = {};
									newFieldsProps[slave.fieldId].filters = newFieldsProps[slave.fieldId].filters
										? [...newFieldsProps[slave.fieldId].filters!, filter]
										: [filter];
								});
							}
					}

					dependency.slaves.forEach(slave => {
						if (
							slave.helperText &&
							(disabledTriggered ||
								(dependency.behaviour === DependenceBehaviour.Enable && !enabledTriggered) ||
								filtersTriggered)
						) {
							if (!newFieldsProps[slave.fieldId].helperTexts)
								newFieldsProps[slave.fieldId].helperTexts = [];
							newFieldsProps[slave.fieldId].helperTexts!.push(slave.helperText!);
						}
					});
				});
		});

		Object.keys(newFieldsProps).forEach(fieldId => {
			if (newFieldsProps[fieldId].disabled || newFieldsProps[fieldId].enabled === false)
				setFieldValue(fieldId, null);
		});

		setFieldsProps(newFieldsProps);
	}, [fieldsWithDependencies, fields, values]);

	return fieldsProps;
}

export default useDependencies;
