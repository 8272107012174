import React, {useCallback} from 'react';
import * as SvgIcon from '../../SvgIcon';
import cls from '../../../utils/cls';
import Field from '../Field/Field';
import css from './Dropdown.modules.css';
import useDropDown, {ComboboxInterface} from './useDropDown';
import fieldCss from '../Field/Field.module.css';
import Button from '../Button/Button';
import {wordByCount} from '../../../utils/word-by-count';

type Props<T> = ComboboxInterface<T> & Omit<React.HTMLAttributes<HTMLDivElement>, keyof ComboboxInterface<T>>;

function Dropdown<T>(props: Props<T>) {
	const {
		searchable,
		label,
		itemToHtml: itemToHtmlProps,
		disabled,
		placeholder,
		itemToString: itemToStringProps,
		getItems,
		items,
		onToggle: onToggleProps,
		onClear,
		popoverClassName,
		inputClassName,
		listClassName,
		inputStyle,
		forwardRef,
		queryable,
		itemToPrimaryKey,
		minLengthQuery,
		/* Убираем из divProps (это атрибуты с U) */
		multiple,
		value,
		defaultValue,
		itemsToHtml: itemsToHtmlProps,
		onChange,
		/* Убираем из divProps (это атрибуты commonProps) */
		error,
		helperText,
		bottomText,
		noButtons,
		disableClear,
		underlined,
		name,
		...divProps
	} = props;

	const handleChange = useCallback(
		(values: T | T[] | null) => {
			(onChange as any)(values);
		},
		[onChange],
	);

	const {
		PopoverContent,
		hasFocus,
		itemsToHtml,
		itemToHtml,
		setInputValue,
		fieldRef,
		close,
		onChangeInput,
		onToggle,
		inputProps,
		inputValue,
		isOpen,
		id,
	} = useDropDown({...props, onChange: handleChange});

	const isButton = (!isOpen && !hasFocus) || !searchable;

	const topLabel =
		!!label &&
		(!!props.placeholder ||
			(isOpen && !!inputValue) ||
			(props.multiple && !!props.value && props.value.length > 0) ||
			(!props.multiple && (props.value != null || !!itemToHtml(props.value!))));

	const hasValue =
		!!inputValue ||
		(props.multiple && !!props.value && props.value.length > 0) ||
		(!props.multiple && props.value != null);

	const handleClearValue = useCallback(() => {
		if (!onClear) {
			if (props.multiple) props.onChange([]);
			else props.onChange(null);
			setInputValue('');
		} else {
			onClear();
		}
		close();
	}, [props.value, props.multiple, props.onChange]);

	return (
		<Field
			commonProps={props}
			isOpen={isOpen}
			hasFocus={isOpen}
			topLabel={topLabel}
			bordered={!underlined}
			underlined={underlined}
			innerRef={fieldRef}
			inputId={id}
			{...divProps}
			buttons={
				<>
					{hasValue && !disabled && !disableClear && (
						<Button action iconOnly onClick={handleClearValue} className={fieldCss.actionButton}>
							<SvgIcon.CrossIcon width={14} height={14} />
						</Button>
					)}
					{!disabled && (
						<Button
							action
							iconOnly
							onClick={onToggle}
							className={cls(fieldCss.actionButton, fieldCss.inactiveActionButton)}
							tabIndex={-1}
						>
							<SvgIcon.ChevronDownIcon
								className={cls(css.dropDownArrow, isOpen && css.dropDownArrowOpen)}
								width={14}
								height={14}
							/>
						</Button>
					)}
				</>
			}
		>
			{isButton ? (
				<button {...inputProps} style={inputStyle} type="button">
					<div>{itemsToHtml(props.value as any)}</div>
				</button>
			) : (
				<input
					{...inputProps}
					style={inputStyle}
					placeholder={placeholder}
					onChange={onChangeInput}
					value={inputValue}
					name={name}
					autoFocus={hasFocus ? true : undefined}
				/>
			)}

			{PopoverContent}
		</Field>
	);
}

export function getItemsToString<T>(getItemToString: (item: T) => string) {
	return (items: T[] | null) => {
		if (items?.length) {
			if (items.length === 1) return getItemToString(items[0]);
			else {
				return `${getItemToString(items[0])} и еще ${wordByCount(
					items.length - 1,
					['значение', 'значения', 'значений'],
					true,
				)}`;
			}
		} else return '';
	};
}

export default Dropdown;
