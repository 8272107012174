import React, {FormEvent, useCallback, useMemo, useRef, useState} from 'react';
import {
	Field,
	GridForm,
	GridFormButton,
	GridFormButtonBehaviour,
	useDataSourceItemQuery,
	useGridFormMutation,
} from '../../queries-generated/types';
import ModalPopup from '../controlls/ModalPopup/ModalPopup';
import ErrorSnippet from '../ErrorSnippet/ErrorSnippet';
import FormShimmer from '../settings/views/ViewEdit/constructor/form/FormShimmer';
import dashboardFieldCss from '../settings/views/ViewEdit/constructor/form/DashboardFields.module.css';
import Button from '../pirsInputs/Button/Button';
import {CrossIcon} from '../SvgIcon';
import {
	defaultFormEmptyValues,
	FieldProps,
	FieldTreeItem,
	flatFields,
	resolveLinkByItemAndLinkOptions,
	validateFields,
} from '../settings/views/components/helper';
import useUnsubmittedValues from '../unsubmittedValues/useUnsubmittedValues';
import {Form as FormikForm, FormikProvider, useFormik} from 'formik';
import useDependencies from '../view/useDependencies';
import useDeleteConfirm from '../hooks/useDeleteConfirm/useDeleteConfirm';
import getIcon from '../../utils/getIcon';
import {mapObject, omitNull} from '../../utils/object-utils';
import {notifyDataSource} from '../toast/DataSourceToast';
import DashboardField from '../settings/views/ViewEdit/constructor/form/DashboardField';
import css from './DataGridForm.module.css';

type Props = {
	dataSourceId: string;
	id?: string;
	selectedIds?: string[];
	fields: Field[];
	open: boolean;
	onClose();
	onSuccess();
	form: GridForm;
};

export function renderFieldsTree(fieldsTree: FieldTreeItem[], fields: Field[], fieldsProps: PlainObjectOf<FieldProps>) {
	const result = fieldsTree.map(fieldsTreeItem => {
		if (typeof fieldsTreeItem === 'string') {
			return (
				<DashboardField
					key={fieldsTreeItem}
					field={{...(fields.find(field => field.id === fieldsTreeItem) as Field), name: fieldsTreeItem}}
					fieldProps={fieldsProps}
				/>
			);
		} else {
			return (
				<div key={fieldsTreeItem.join(',')} className={css.line}>
					{renderFieldsTree(fieldsTreeItem, fields, fieldsProps)}
				</div>
			);
		}
	});

	return result.flat(10) as JSX.Element[];
}

export function getButton(
	baseButton: GridFormButton,
	isDirtyForm: boolean,
	onClick: (button: GridFormButton) => void,
	onCancel: () => void,
	customLoading: boolean,
	dataSourceId: string,
	item: any,
	fields: Field[],
) {
	let buttonOptions: Partial<GridFormButton> = {};
	let handleClick: any = undefined;
	let loading = false;

	switch (baseButton.behaviour) {
		case GridFormButtonBehaviour.Create:
		case GridFormButtonBehaviour.Update:
			buttonOptions = {
				icon: 'Save',
				primary: true,
				disableUnchanged: true,
				type: 'submit',
			};
			loading = customLoading;
			break;
		case GridFormButtonBehaviour.Cancel:
			buttonOptions = {
				icon: 'Cross',
				primary: false,
				disableUnchanged: false,
				type: 'button',
			};
			handleClick = onCancel;
			break;
		default:
			handleClick = onClick;
			loading = customLoading;
	}
	const button = {...omitNull(buttonOptions), ...omitNull(baseButton)};

	return button.action ? (
		resolveLinkByItemAndLinkOptions(
			<>
				{button.icon && getIcon(button.icon)} {button.title}
			</>,
			button.action,
			{dataSourceId, item, reload: () => {}, fields, massAction: false},
			{
				type: button.type as never,
				secondary: !button.primary,
				disabled: button.disable || (button.disableUnchanged && !isDirtyForm),
				loading,
			},
		)
	) : (
		<Button
			type={button.type as never}
			secondary={!button.primary}
			onClick={handleClick && (() => handleClick(button))}
			disabled={button.disableUnchanged && !isDirtyForm}
			loading={loading}
		>
			{button.icon && getIcon(button.icon)} {button.title}
		</Button>
	);
}

type Values = PlainObjectOf<any>;

const DataGridForm: React.FC<Props> = ({dataSourceId, fields, id, selectedIds, open, onClose, onSuccess, form}) => {
	const isEditForm = !!id;
	const {unsubmittedValues, setUnsubmittedValues, clearUnsubmittedValues} = useUnsubmittedValues(
		!isEditForm ? `${dataSourceId}-create` : '',
	);

	const [unsubmittedValuesState] = useState(unsubmittedValues);
	const submittedButton = useRef<null | GridFormButton>(null);

	const {data, loading, error} = useDataSourceItemQuery({
		variables: {dataSourceId, id: id || ''},
		skip: !id,
		fetchPolicy: 'no-cache',
	});
	const flatFieldIds = useMemo(() => form.fields.flat(10) as string[], [form.fields]);
	const visibleFields = useMemo(() => {
		const relationFields = flatFields(fields);
		return relationFields.filter(field => flatFieldIds.includes(field.id));
	}, [fields, flatFieldIds]);

	const values = useMemo(() => defaultFormEmptyValues(visibleFields), [visibleFields]);

	const submit = (button: GridFormButton, values: any) => {
		setCustomSubmitLoading({...customSubmitLoading, [button.id]: true});

		customSubmit({
			variables: {
				dataSourceId,
				formId: form.id,
				id: button.id,
				selectedIds: selectedIds || [],
				item: values,
			},
		})
			.then(({data}) => {
				notifyDataSource(data?.gridForm?.notifications);
				onSuccess();
			})
			.finally(() => setCustomSubmitLoading({...customSubmitLoading, [button.id]: false}));
	};

	const formikBag = useFormik<Values>({
		initialValues: isEditForm ? data?.dataSourceItem.item : unsubmittedValuesState || values,
		enableReinitialize: true,
		validate: values => {
			if (visibleFields) {
				const errors = validateFields(visibleFields, values, submittedButton.current?.validation);
				if (Object.keys(errors).length) {
					return errors;
				}
			}
		},
		onSubmit: values => {
			clearUnsubmittedValues();

			const button = form.buttons.find(
				button =>
					button.behaviour &&
					[GridFormButtonBehaviour.Create, GridFormButtonBehaviour.Update].includes(button.behaviour),
			);
			if (button) {
				submit(button, values);
			}
		},
	});

	const [customSubmitLoading, setCustomSubmitLoading] = useState<PlainObjectOf<boolean>>({});
	const [customSubmit, {error: customSubmitError}] = useGridFormMutation();

	const handleCustomFormButtonClick = useCallback(
		async (button: GridFormButton) => {
			submittedButton.current = button;
			formikBag.setSubmitting(true);
			const errors = await formikBag.validateForm(formikBag.values);
			const errorKeys = Object.keys(errors);
			formikBag.setTouched(
				mapObject(errors, () => true),
				false,
			);
			if (!errorKeys.length) {
				submit(button, formikBag.values);
			}
		},
		[formikBag.values, dataSourceId, customSubmitLoading, onSuccess],
	);

	const {openConfirm, Confirm} = useDeleteConfirm({
		header: 'Несохраненные изменения',
		text: isEditForm ? (
			<>
				<p>В форме остались несохраненные изменения.</p>
				<p>Вы уверены, что хотите закрыть форму без сохранения?</p>
			</>
		) : (
			<>
				<p>В форме остались изменения. Мы запомним их, но новый элемент в базе не появится.</p>
				<p>Вы уверены, что хотите закрыть форму?</p>
			</>
		),
		callback: onClose,
	});

	const handleClose = useCallback(() => {
		if (formikBag.dirty) {
			openConfirm();
			setUnsubmittedValues(formikBag.values);
		} else onClose();
	}, [onClose, formikBag.values, formikBag.dirty]);

	const fieldsProps = useDependencies({
		setFieldValue: formikBag.setFieldValue,
		values: formikBag.values,
		fields: visibleFields as Field[],
	});

	const maxOneLineCount = useMemo(() => {
		return Math.max(...form.fields.map(line => (Array.isArray(line) ? line.length : 1)));
	}, [form.fields]);

	const handleSubmit = useCallback(
		(event: FormEvent) => {
			submittedButton.current =
				form.buttons.find(
					button =>
						button.behaviour &&
						[GridFormButtonBehaviour.Update, GridFormButtonBehaviour.Create].includes(button.behaviour),
				) || null;
			formikBag.submitForm();
			event.preventDefault();
		},
		[form.buttons],
	);

	return (
		<>
			{Confirm}
			<ModalPopup
				open={open}
				onClose={handleClose}
				maxWidth={form.width || Math.max(450, maxOneLineCount * 250)}
				header={form.title}
			>
				{error || customSubmitError ? (
					<>
						<ul className={dashboardFieldCss.form}>
							<ErrorSnippet error={(error || customSubmitError)!} errorHeader={'Ошибка сохранения'} />
						</ul>
						<div className={'modalPopupButtons'}>
							<Button type="button" secondary onClick={handleClose}>
								<CrossIcon />
								Закрыть
							</Button>
						</div>
					</>
				) : loading ? (
					<FormShimmer count={form.fields.length} buttons />
				) : (
					<FormikProvider value={formikBag}>
						<FormikForm onSubmit={handleSubmit}>
							<ul className={dashboardFieldCss.form}>
								{renderFieldsTree(form.fields, visibleFields, fieldsProps)}
							</ul>
							<div className={'modalPopupButtons'}>
								{form.buttons
									.filter(button => !button.hidden)
									.map(button => (
										<React.Fragment key={button.id}>
											{getButton(
												button,
												formikBag.dirty,
												handleCustomFormButtonClick,
												onClose,
												customSubmitLoading[button.id],
												dataSourceId,
												id ? {id} : null,
												fields,
											)}
										</React.Fragment>
									))}
							</div>
						</FormikForm>
					</FormikProvider>
				)}
			</ModalPopup>
		</>
	);
};

export default DataGridForm;
