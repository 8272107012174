import React from 'react';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import ToggleModelItemOld from '../../components/FullTable/ToggleModelItemOld';

enum TypeName {
	USER = 'user',
	ORG = 'org',
	PVVS = 'pvvs',
	KEYCLOAK = 'keycloak',
}

function formatTypeName(type: TypeName) {
	switch (type) {
		case TypeName.USER:
			return 'Внешние пользователи';
		case TypeName.ORG:
			return 'Организации';
		case TypeName.PVVS:
			return 'Внешние системы';
		case TypeName.KEYCLOAK:
			return 'Внутренние пользователи';
		default:
			return type;
	}
}

const RecipientBindingTypeTable: React.FC = () => {
	const {Table} = useFullTableWithRefetch('recipientBindingType', {
		tableFields: ['name', 'epp', 'sms', 'mail', 'pvvs'],
		cellCallback: {
			name: value => {
				return formatTypeName(value);
			},
			epp: (value, item) => {
				return (
					<ToggleModelItemOld
						modelName={'recipientBindingType'}
						value={value}
						id={item.id}
						params={{name: 'epp'}}
					/>
				);
			},
			sms: (value, item) => {
				return (
					<ToggleModelItemOld
						modelName={'recipientBindingType'}
						value={value}
						id={item.id}
						params={{name: 'sms'}}
					/>
				);
			},
			mail: (value, item) => {
				return (
					<ToggleModelItemOld
						modelName={'recipientBindingType'}
						value={value}
						id={item.id}
						params={{name: 'mail'}}
					/>
				);
			},
			pvvs: (value, item) => {
				return (
					<ToggleModelItemOld
						modelName={'recipientBindingType'}
						value={value}
						id={item.id}
						params={{name: 'pvvs'}}
					/>
				);
			},
		},
	});
	return <Table />;
};

export default RecipientBindingTypeTable;
