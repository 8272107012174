import React from 'react';
import {useField, useFormikContext} from 'formik';
import {default as OriginalInput, Props as ParentProps} from './AutoComplete';

type Props<T> = Omit<ParentProps<T>, 'value' | 'onChange'> & {
	name: string;
	onCustomChange?: (value: string, originalValue: T) => void;
};

function AutoComplete<T>(props: Props<T>) {
	// @ts-ignore
	const [field, meta] = useField(props);
	const {setFieldValue} = useFormikContext();
	const {onCustomChange, ...fieldProps} = props;
	return (
		<OriginalInput
			{...field}
			{...(fieldProps as any)}
			error={meta.touched && meta.error}
			onChange={(value, originalValue) => {
				setFieldValue(field.name as never, value);
				if (onCustomChange) onCustomChange(value!, originalValue as T);
			}}
		/>
	);
}

export default AutoComplete;
