exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".AsInfoSnippet-module__container__2ElLk {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tflex-wrap: nowrap;\r\n}\r\n\r\n.AsInfoSnippet-module__list__2luyR {\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tlist-style: none;\r\n}\r\n\r\n.AsInfoSnippet-module__asInfo__1Weg9 {\r\n\tmargin-right: 10px;\r\n}\r\n\r\n.AsInfoSnippet-module__info__4M5jr {\r\n\tmargin: 0 0 10px 0;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\SurveyResourceAsPage\\AsInfoSnippet.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,iBAAiB;AAClB;;AAEA;CACC,SAAS;CACT,UAAU;CACV,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;AACnB","file":"AsInfoSnippet.module.css","sourcesContent":[".container {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tflex-wrap: nowrap;\r\n}\r\n\r\n.list {\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tlist-style: none;\r\n}\r\n\r\n.asInfo {\r\n\tmargin-right: 10px;\r\n}\r\n\r\n.info {\r\n\tmargin: 0 0 10px 0;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"container": "AsInfoSnippet-module__container__2ElLk",
	"list": "AsInfoSnippet-module__list__2luyR",
	"asInfo": "AsInfoSnippet-module__asInfo__1Weg9",
	"info": "AsInfoSnippet-module__info__4M5jr"
};