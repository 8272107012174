import React, {useCallback, useState} from 'react';
import {getScrollbarWidth} from './helper';
import css from './Calendar.module.module.css';
import DaysCalendar from './DaysCalendar';
import MonthsCalendar from './MonthsCalendar';
import YearsCalendar from './YearsCalendar';
import TimeCalendar from './TimeCalendar';
import {useConst} from '../../hooks/useConst';
import {resetMonth} from 'ts-date/locale/ru';
import DurationCalendar from './DurationCalendar';

export const MAGIC_CUSTOM_DURATION: DurationIso = '_custom_';

export const CUSTOM_PERIOD: DurationItem = {
	duration: MAGIC_CUSTOM_DURATION,
	title: 'Выбор',
};

export type DurationItem = {
	duration: DurationIso;
	title: string;
};

export type Value = {
	from: Date | null;
	to: Date | null;
	duration: DurationIso | null;
};

type Props = {
	value: Value | null;
	onChange: (value: Value) => void;
	minDate?: Date;
	maxDate?: Date;
	width?: number;
	time?: boolean;
	selectDurations?: boolean;
	seconds?: boolean;
	range?: boolean;
	durations?: DurationItem[];
};

export const FROM_DATE = new Date('1921-01-01T00:00:00');
export const END_DATE = new Date('2122-01-01T00:00:00');

export const stopPropagation = (e: React.MouseEvent) => {
	e.stopPropagation();
	e.nativeEvent.stopImmediatePropagation();
};

const Calendar: React.FC<Props> = ({
	value,
	onChange,
	minDate,
	maxDate,
	width: propsWidth,
	time,
	seconds,
	durations,
	selectDurations,
	range,
}) => {
	const width = propsWidth || 350;
	const height = (width / 7) * 6;
	const scrollbarWidth = useConst(() => getScrollbarWidth());
	const [mode, setMode] = useState<'days' | 'months' | 'years'>('days');
	const [dateCursor, setDateCursor] = useState(resetMonth(value?.from || new Date()));
	const handleSetMonths = useCallback(e => {
		e.stopPropagation();
		setMode('months');
	}, []);
	const handleSetYears = useCallback(e => {
		e.stopPropagation();
		setMode('years');
	}, []);
	const handleSetDays = useCallback(e => {
		e.stopPropagation();
		setMode('days');
	}, []);

	const handleChangeTime = useCallback(dateTime => onChange({from: dateTime, to: null, duration: null}), [onChange]);

	const handleChangeDuration = useCallback(duration => onChange({from: null, to: null, ...value, duration}), [
		onChange,
		value,
	]);

	return (
		<div className={css.calendarWrapper}>
			{mode === 'days' && (
				<DaysCalendar
					value={value}
					onChange={onChange}
					width={width}
					height={height}
					rowHeight={height / 6}
					scrollbarWidth={scrollbarWidth}
					switchToMonths={handleSetMonths}
					minDate={minDate}
					maxDate={maxDate}
					range={range}
					dateCursor={dateCursor}
					onChangeDateCursor={setDateCursor}
				/>
			)}
			{mode === 'months' && (
				<MonthsCalendar
					value={value}
					width={width}
					height={height}
					rowHeight={(height + height / 6) / 4}
					scrollbarWidth={scrollbarWidth}
					switchToDays={handleSetDays}
					switchToYears={handleSetYears}
					minDate={minDate}
					maxDate={maxDate}
					range={range}
					dateCursor={dateCursor}
					onChangeDateCursor={setDateCursor}
				/>
			)}
			{mode === 'years' && (
				<YearsCalendar
					value={value}
					width={width}
					height={height}
					rowHeight={(height + height / 6) / 4}
					scrollbarWidth={scrollbarWidth}
					switchToDays={handleSetDays}
					switchToMonths={handleSetMonths}
					minDate={minDate}
					maxDate={maxDate}
					range={range}
					dateCursor={dateCursor}
					onChangeDateCursor={setDateCursor}
				/>
			)}
			{time && !range && (
				<TimeCalendar
					scrollbarWidth={scrollbarWidth}
					value={value?.from || null}
					onChange={handleChangeTime}
					seconds={seconds}
					height={height + height / 6}
					minTime={minDate}
					maxTime={maxDate}
				/>
			)}
			{selectDurations && durations && (
				<DurationCalendar
					height={height + height / 6}
					items={durations}
					onChange={handleChangeDuration}
					value={value?.duration || MAGIC_CUSTOM_DURATION}
				/>
			)}
		</div>
	);
};

export default Calendar;
