import React, {useCallback, useState, useMemo} from 'react';
import {Form, FormikProvider, useFormik, FieldArray} from 'formik';
import Button from '../../../components/pirsInputs/Button/Button';
import css from './RecipientGroupRuleForm.module.css';
import formCss from '../../../components/FullTable/FullTableForm.module.css';
import TableFormSelector from '../../../components/controlls/TableFormSelector/TableFormSelector.formik';
import {ApolloError} from '@apollo/client';
import Input from '../../../components/pirsInputs/Input/Input.formik';
import {useRecipientGroupDataQuery, useSubmitRecipientFormMutation} from 'queries-generated/types';
import Selector from '../../../components/pirsInputs/Selector/Selector.formik';
import RadioButtonGroup from '../../../components/pirsInputs/RadioButton/RadioButtonGroup.formik';
import RecipientCombobox from '../../../components/controlls/RecipientCombobox/RecipientCombobox.formik';
import {tryParse} from '../../../components/hooks/useLocalStorage';
import {Item} from '../RecipientGroupRulePage';
import CustomErrorPopup from '../../../components/controlls/CustomErrorPopup/CustomErrorPopup';
import ProgressRing from '../../../components/controlls/Loader/ProgressRing';

type Props = {
	item?: Item;
	recipientGroupId: string;
	onClose: () => void;
	reFetchTable: () => void;
};

enum RadioButtonType {
	DICTIONARY = 'dictionary',
	REGEXP = 'regexp',
}

function patchInitialParams(params: {
	[key: string]: {value: string; type: RadioButtonType};
}): {name: string; value: string; type: RadioButtonType}[] {
	return Object.keys(params).map(key => ({
		name: key,
		value: params[key].value,
		type: params[key].type,
	}));
}

function patchSubmitValues(values: {
	recipientGroupId: string;
	recipientType: string;
	params: {name: string; value: {key: string; value: string} | string; type: RadioButtonType}[];
}): {recipientGroupId: string; recipientType: string; params: {[key: string]: any} | undefined} {
	return {
		...values,
		params: values.params.length
			? values.params.reduce((current, param) => {
					if (param.name && param.value && param.type) {
						current[param.name] = {
							type: param.type,
							value: (param.value as {value: string}).value || param.value,
						};
					}
					return current;
			  }, {})
			: undefined,
	};
}

const RecipientGroupRuleForm: React.FC<Props> = ({item, recipientGroupId, onClose, reFetchTable}) => {
	const [recipientType, setRecipientType] = useState<string>(item?.recipientType || '');

	const {data: recipientGroupData, loading: recipientGroupDataLoading} = useRecipientGroupDataQuery();
	const [submitRecipientForm, {loading: submitRecipientFormLoading}] = useSubmitRecipientFormMutation({
		notifyOnNetworkStatusChange: true,
	});
	const [submitRecipientFormError, setSubmitRecipientFormError] = useState<ApolloError>();

	const recipientParams = useMemo(() => {
		if (recipientGroupData?.recipientGroupData && recipientType) {
			const id = recipientGroupData.recipientGroupData.findIndex(recipient => recipient.key === recipientType);
			if (id > -1) return recipientGroupData.recipientGroupData[id].params;
		} else {
			return [];
		}
	}, [recipientGroupData, recipientType]);

	const formikBag = useFormik({
		initialValues: {
			recipientGroupId,
			recipientType,
			params: item?.itemParams ? patchInitialParams(tryParse(item.itemParams)) : [],
		},
		onSubmit: values => {
			setSubmitRecipientFormError(undefined);
			submitRecipientForm({
				variables: {
					id: item?.itemId,
					item: patchSubmitValues(values),
				},
			})
				.then(() => {
					onClose();
					reFetchTable();
				})
				.catch(error => setSubmitRecipientFormError(error));
		},
		validateOnChange: false,
		validateOnMount: false,
		validateOnBlur: false,
	});

	const handleChangeRecipientType = useCallback(
		value => {
			formikBag.resetForm();
			formikBag.setFieldValue('recipientType', value);
			setRecipientType(value);
		},
		[setRecipientType],
	);

	const items = recipientParams?.map(item => item.key);
	const itemToHtml = key => {
		const item = recipientParams?.find(item => item.key === key);
		if (item) return item.name;
		else return key;
	};

	return (
		<>
			{submitRecipientFormError && <CustomErrorPopup maxWidth={600} error={submitRecipientFormError} />}
			{recipientGroupDataLoading ? (
				<ProgressRing />
			) : (
				<FormikProvider value={formikBag}>
					<Form>
						<ul className={formCss.fields}>
							<li>
								<TableFormSelector
									onCustomChange={handleChangeRecipientType}
									defaultValue={recipientType}
									label={'Тип получателя'}
									name={'recipientType'}
									url={'/settings/integration/recipienttype'}
									disabled={!!item}
								/>
							</li>
						</ul>
						{recipientType ? (
							<FieldArray
								name={'params'}
								render={arrayHelpers => {
									return (
										<>
											{arrayHelpers.form.values.params.length > 0 &&
												arrayHelpers.form.values.params.map((param, index) => {
													return (
														<ul className={css.inputList} key={index}>
															<Selector
																label={'Выбор параметра получателя'}
																itemToString={itemToHtml}
																items={items || []}
																name={`params[${index}].name`}
															/>
															{param.name ? (
																<>
																	<li>
																		<RadioButtonGroup
																			horizontal
																			name={`params[${index}].type`}
																			value={param.type}
																			onCustomChange={value => {
																				arrayHelpers.form.setFieldValue(
																					`params[${index}].value`,
																					'',
																				);
																				arrayHelpers.form.setFieldValue(
																					`params[${index}].type`,
																					value,
																				);
																			}}
																			buttons={[
																				{
																					label: 'Регулярное выражение',
																					value: RadioButtonType.REGEXP,
																				},
																				{
																					label: 'Словарь',
																					value: RadioButtonType.DICTIONARY,
																				},
																			]}
																		/>
																	</li>
																	<li>
																		{param.type === RadioButtonType.REGEXP ? (
																			<Input
																				label={'Регулярное выражение'}
																				name={`params[${index}].value`}
																				defaultValue={param.value}
																			/>
																		) : (
																			<RecipientCombobox
																				label={'Введите значение'}
																				name={`params[${index}].value`}
																				type={recipientType}
																				param={param.name}
																			/>
																		)}
																	</li>
																</>
															) : (
																<div className={css.helpText}>
																	Выберите пожалуйста параметр для получателя
																</div>
															)}
															<li className={css.deleteButton}>
																<div>
																	<Button
																		secondary
																		onClick={() => arrayHelpers.remove(index)}
																	>
																		Удалить
																	</Button>
																</div>
															</li>
														</ul>
													);
												})}
											<div className={css.addButton}>
												<Button
													secondary
													onClick={() =>
														arrayHelpers.push({
															name: '',
															value: '',
															type: RadioButtonType.DICTIONARY,
														})
													}
												>
													Добавить
												</Button>
											</div>
										</>
									);
								}}
							/>
						) : (
							<div>Выберите пожалуйста тип получателя</div>
						)}
						<ul className={formCss.buttons}>
							<li>
								<Button
									disabled={!recipientType || submitRecipientFormLoading}
									type={'submit'}
									loading={submitRecipientFormLoading}
								>
									Сохранить
								</Button>
							</li>
							<li>
								<Button onClick={onClose} secondary>
									Отмена
								</Button>
							</li>
						</ul>
					</Form>
				</FormikProvider>
			)}
		</>
	);
};

export default RecipientGroupRuleForm;
