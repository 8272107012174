import {useCallback, useRef} from 'react';
import {colors} from '../helper';
import {GraphGridQuery, GraphTimeLineGridQuery} from '../../../../../queries-generated/types';

function useTableItemMouseOver(
	tableGraph: GraphTimeLineGridQuery['graphTimeLineGrid'] | GraphGridQuery['graphGrid'],
	graph: any,
	replayInProgress: boolean,
) {
	const lastEdgesState = useRef({});
	const lastNodesState = useRef({});
	const handleMouseEnter = useCallback(
		(item: any) => {
			const options = tableGraph?.options?.highlightOptions;
			if (graph.current && !replayInProgress) {
				if (item.linkedEdges) {
					item.linkedEdges.forEach(edgeId => {
						lastEdgesState.current[edgeId] = graph.current.body.data.edges.get(edgeId);
						const thickness = options?.edge?.thickness || 100;
						graph.current.body.data.edges.update([
							{
								id: edgeId,
								background: {
									color: options?.edge?.color ? colors[options.edge.color] : undefined,
									size: thickness,
									enabled: true,
									dashes: [thickness / 10, thickness / 20],
								},
							},
						]);
					});
				}

				if (item.linkedNodes) {
					item.linkedNodes.forEach(nodeId => {
						lastNodesState.current[nodeId] = graph.current.body.data.nodes.get(nodeId);
						graph.current.body.data.nodes.update([
							{
								id: nodeId,
								color: options?.node?.borderColor
									? {border: colors[options.node.borderColor]}
									: undefined,
								borderWidth: options?.node?.borderThickness ? options.node.borderThickness / 100 : 10,
							},
						]);
					});
				}
			}
		},
		[replayInProgress, tableGraph],
	);

	const handleMouseLeave = useCallback((item: any) => {
		if (graph.current) {
			if (item.linkedEdges) {
				item.linkedEdges.forEach(edgeId => {
					graph.current.body.data.edges.update([
						{background: {enabled: false}, ...lastEdgesState.current[edgeId], id: edgeId},
					]);
				});
			}
			if (item.linkedNodes) {
				item.linkedNodes.forEach(nodeId => {
					graph.current.body.data.nodes.update([{...lastNodesState.current[nodeId], id: nodeId}]);
				});
			}
		}
	}, []);

	return {handleMouseEnter, handleMouseLeave};
}

export default useTableItemMouseOver;
