import React from 'react';
import {CommonInputInterface} from '../../pirsInputs/CommonInputInterface';
import Selector from '../../pirsInputs/Selector/Selector';
import {useDictionaryQuery} from '../../../queries-generated/types';
import ProgressRing from '../Loader/ProgressRing';
import Field from '../../pirsInputs/Field/Field';

export interface Props<T> extends CommonInputInterface<T | T[] | null> {
	id?: string;
	/* текущее значение */
	value: T | null;
	/* Название класса */
	className?: string;
	/* Название словаря */
	dictionary: string;
	/* Доступные ключи */
	dictionaryKeys?: string[];
	/* Доступные значения */
	dictionaryValues?: string[];
}

function DictionarySelector<T>(props: Props<T>) {
	const {dictionary, dictionaryKeys, dictionaryValues, ...otherProps} = props;
	const {error, loading, data} = useDictionaryQuery({
		variables: {
			id: dictionary,
			keys: dictionaryKeys,
		},
	});

	if (error) return <>Error</>;

	const items = data?.dictionary.map(item => item.key);
	const itemToHtml = key => {
		const item = data?.dictionary.find(item => item.key === key);
		if (item) return item.value;
		else return key;
	};

	return loading ? (
		<Field commonProps={otherProps} topLabel={true} style={{height: 30, marginTop: 29}}>
			<ProgressRing />
		</Field>
	) : (
		<Selector {...otherProps} items={(items as any) || []} itemToString={itemToHtml} />
	);
}

export default DictionarySelector;
