import React from 'react';
import css from './Breadcrumbs.module.css';
import cls from '../../utils/cls';
import Button from '../pirsInputs/Button/Button';
import {ChevronRightIcon} from '../SvgIcon';

export type Breadcrumb = {
	id: string;
	route?: string;
	params?: {[key: string]: string | undefined};
	target?: string;
	href?: string;
	onClick?: (event: React.MouseEvent) => void;
	className?: string;
	content: React.ReactNode;
};

type Props = {
	items: Breadcrumb[];
	className?: string;
	onClick?: (item: Breadcrumb) => void;
};

const Breadcrumbs: React.FC<Props> = ({items, className, onClick: onClickGlobal}) => {
	return (
		<div className={cls(css.wrapper, className)}>
			{items.map((item, index) => {
				const {onClick, id, content, ...buttonProps} = item;
				return (
					<React.Fragment key={id}>
						{index !== 0 && <ChevronRightIcon className={css.chevron} width={14} height={14} />}
						<Button
							link
							{...buttonProps}
							className={css.crumb}
							onClick={onClick || (() => onClickGlobal && onClickGlobal(item))}
						>
							{content}
						</Button>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default Breadcrumbs;
