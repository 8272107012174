import React, {useCallback, useEffect, useRef, useState} from 'react';
import css from './Tabs.module.css';
import cls from '../../../utils/cls';
import usePrevious from '../../hooks/usePrevious';
import Button from '../../pirsInputs/Button/Button';
import resetAnimation from '../../../utils/resetAnimation';

type Props = {
	className?: string;
	defaultSelected?: number;
	selected?: number;
	onChange?: (selected: number) => void;
	vertical?: boolean;
	onChangeOrder?: (index: number) => void;
};

type TabProps = {
	header: React.ReactNode | string;
	className?: string;
	headerClassName?: string;
	onClick?: () => void;
};

export const Tab: React.FC<TabProps> = () => {
	return null;
};

const Tabs: React.FC<Props> = ({children, onChangeOrder, className, vertical, defaultSelected, ...props}) => {
	const [currentIndex, setCurrentIndex] = useState(defaultSelected ?? props.selected ?? 0);
	const childArray: React.ReactElement[] = React.Children.toArray(children) as React.ReactElement[];
	const [dragOver, setDragOver] = useState(false);
	const headersRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const prevChildArrayLength = usePrevious(childArray.length);

	useEffect(() => {
		if (prevChildArrayLength !== childArray.length) {
			setCurrentIndex(0);
		}
	}, [prevChildArrayLength, childArray.length]);

	const selected = 'selected' in props ? props.selected || 0 : currentIndex;
	const handleChange = useCallback(
		index => {
			setCurrentIndex(index);
			if (props.onChange) props.onChange(index);
			resetAnimation(contentRef.current);
		},
		[props],
	);

	const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	}, []);

	const handleDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setDragOver(true);
	}, []);

	const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setDragOver(false);
	}, []);

	const handleDrop = useCallback(
		(event: React.DragEvent<HTMLDivElement>) => {
			const headers = headersRef.current;
			event.stopPropagation();
			if (!headers || !onChangeOrder) return;

			const newPosition = event.clientX; // - boundary.left;
			for (let i = 0; i < headers.children.length; i++) {
				const header = headers.children[i];
				const boundary = header.getBoundingClientRect();

				if (newPosition >= boundary.left && newPosition <= boundary.left + boundary.width / 2) {
					onChangeOrder(i);
					break;
				} else if (
					newPosition >= boundary.left + boundary.width / 2 &&
					newPosition <= boundary.left + boundary.width
				) {
					onChangeOrder(i + 1);
					break;
				}
			}

			setDragOver(false);
		},
		[onChangeOrder],
	);

	const [dragInProgress, setDragInProgress] = useState(false);

	const handleDragStart = useCallback(() => {
		setDragInProgress(true);
	}, []);

	const handleDragEnd = useCallback(() => {
		setDragInProgress(false);
	}, []);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
			const target = e.target;
			if (target) {
				if (e.keyCode === 37) {
					// @ts-ignore
					target?.previousSibling?.focus();
					e.preventDefault();
				} else if (e.keyCode === 39) {
					// @ts-ignore
					target?.nextSibling?.focus();
					e.preventDefault();
				} else if (e.keyCode === 13) {
					handleChange(index);
				}
			}
		},
		[handleChange],
	);

	return (
		<div className={cls(css.wrapper, className, vertical ? css.vertical : css.horizontal)} role={'toolbar'}>
			<div
				className={cls(css.headers, dragOver && css.dragOver)}
				onDragOver={onChangeOrder ? handleDragOver : undefined}
				onDragEnter={onChangeOrder ? handleDragEnter : undefined}
				onDragLeave={onChangeOrder ? handleDragLeave : undefined}
				onDrop={onChangeOrder ? handleDrop : undefined}
				ref={headersRef}
				role="tablist"
			>
				{childArray.map((element, index: number) => {
					return (
						<Button
							action
							key={index}
							onClick={() => (element.props.onClick ? element.props.onClick() : handleChange(index))}
							draggable={!!(index === selected && onChangeOrder)}
							onDragStart={onChangeOrder ? handleDragStart : undefined}
							onDragEnd={onChangeOrder ? handleDragEnd : undefined}
							onKeyDown={e => handleKeyDown(e, index)}
							className={cls(
								css.header,
								dragInProgress && css.dragInProgress,
								index === selected && css.current,
								element.props && element.props.headerClassName,
							)}
							role="tab"
							aria-selected={index === selected ? 'true' : 'false'}
							tabIndex={index === selected ? 0 : -1}
						>
							{element.props && element.props.header}
						</Button>
					);
				})}
			</div>

			{childArray[selected] && childArray[selected].props && (
				<div className={cls(css.content, childArray[selected].props.className)} ref={contentRef}>
					{childArray[selected].props.children}
				</div>
			)}
		</div>
	);
};

export default Tabs;
