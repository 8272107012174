import React, {useCallback, useState} from 'react';
import formCss from '../FullTable/FullTableForm.module.css';
import OldDictionarySelector from '../controlls/OldDictionarySelector/OldDictionarySelector.formik';
import DictionarySelector from '../controlls/DictionarySelector/DictionarySelector.formik';
import TextArea from '../pirsInputs/TextArea/TextArea.formik';
import Input from '../pirsInputs/Input/Input.formik';
import Button from '../pirsInputs/Button/Button';
import {TableColumn, useModelItemsQuery} from '../../queries-generated/types';
import {indexBy} from '../../utils/array-utils';
import ProgressRing from '../controlls/Loader/ProgressRing';
import {useFormikContext} from 'formik';
import {Values} from './ModifyIncident';
import Selector from '../pirsInputs/Selector/Selector.formik';
import IncidentFile from './IncidentFile';
import InputDateTime from '../pirsInputs/InputDateTime/InputDateTime.formik';
import css from './IncidentFile.module.css';
import cls from '../../utils/cls';
import IncidentsComments from './IncidentsComments';
import {UploadFileData, UploadFileStatus} from '../commonRedux/UploadFileStore/UploadFileQueueReducer';
import useConfirm from '../hooks/useConfirm/useConfirm';
import {CrossIcon} from '../SvgIcon';

type SetStringArrState = (prev: string[]) => string[];

enum FormType {
	SSOP_NEW = 'SSOP_NEW',
	SSOP_EDIT = 'SSOP_EDIT',
	ASBI_NEW = 'ASBI_NEW',
	ASBI_EDIT = 'ASBI_EDIT',
	OTHER = 'OTHER',
}

type Props = {
	allColumns: TableColumn[];
	onClose: () => void;
	onSubmit: () => void;
	loading: boolean;
	incident?: any;
	beRemoveAttachedFileIds: string[];
	setBeRemoveAttachedFileIds: (par: SetStringArrState | string[]) => void;
	setPreparedFilesIds: (par: SetStringArrState | string[]) => void;
	preparedFiles: UploadFileData[];
	setPreparedFiles: (files: UploadFileData[]) => void;
	selectedCategoryValue: string | null;
	setSelectedCategoryValue: (value: string | null) => void;
};

const IncidentForm: React.FC<Props> = ({
	allColumns,
	onClose,
	onSubmit,
	loading,
	incident,
	beRemoveAttachedFileIds,
	setBeRemoveAttachedFileIds,
	setPreparedFilesIds,
	preparedFiles,
	setPreparedFiles,
	selectedCategoryValue,
	setSelectedCategoryValue,
}) => {
	const [openComments, setOpenComments] = useState(false);
	const handleToggleComments = useCallback(() => {
		setOpenComments(!openComments);
	}, [openComments]);

	const formikBag = useFormikContext<Values>();
	const {data: typesData} = useModelItemsQuery({
		variables: {model: 'incidentsType', variables: {filter: `category_id eq ${formikBag.values.category}`}},
		skip: !formikBag.values.category,
	});

	const pageId = formikBag.values.id ? `incident${formikBag.values.id}` : 'newIncident';

	const handleTogglePrepareRemoveFile = useCallback(
		(id: string) => {
			setBeRemoveAttachedFileIds(ids => {
				if (!ids.includes(id)) return [...ids, id];
				else return ids.filter(fileId => fileId !== id);
			});
		},
		[setBeRemoveAttachedFileIds],
	);

	const handleSetPreparedFileId = useCallback(
		(id: string) => {
			setPreparedFilesIds(ids => [...ids, id]);
		},
		[setPreparedFilesIds],
	);

	const handleDeletePreparedFileId = useCallback(
		(id: string) => {
			setPreparedFilesIds(ids => ids.filter(fileId => fileId !== id));
		},
		[setPreparedFilesIds],
	);

	const formType = useCallback(() => {
		const cat = selectedCategoryValue || (incident && incident['category.dictionary']);
		switch (cat) {
			case 'Инцидент ССОП':
				return incident ? FormType.SSOP_EDIT : FormType.SSOP_NEW;
			case 'АСБИ инфраструктура':
				return incident ? FormType.ASBI_EDIT : FormType.ASBI_NEW;
			default:
				return FormType.OTHER;
		}
	}, [incident, selectedCategoryValue]);

	const {showConfirm, ConfirmComponent} = useConfirm({
		header: 'Часть файлов в процессе выгрузки',
		message: 'Если продолжить, файлы могут быть потеряны',
		confirmButtonTitle: 'Продолжить с потерей файлов',
		cancelButtonTitle: 'Подождать',
		onConfirm: onSubmit,
	});

	const checkUploadsThenSubmit = useCallback(() => {
		const loadingNow = preparedFiles.some(
			file => file.status === UploadFileStatus.LOADING || file.status === UploadFileStatus.QUEUE,
		);
		if (!loadingNow) onSubmit();
		else showConfirm();
	}, [onSubmit, preparedFiles, showConfirm]);

	if (!allColumns.length) return <ProgressRing />;
	const columns = indexBy(allColumns, 'id');

	const category = formikBag.values.category?.toString();

	return (
		<>
			{openComments && incident && <IncidentsComments onClose={handleToggleComments} incident={incident} />}
			{ConfirmComponent}
			<ul className={formCss.fields}>
				{!!incident && (
					<li>
						<h2 className={formCss.header}>Инцидент {incident.external_id}</h2>
					</li>
				)}
				<li>
					<OldDictionarySelector
						label={columns.category.title}
						dictionary={columns.category.oldDictionary!}
						dictionaryKeys={columns.category.dictionaryKeys}
						dictionaryValues={columns.category.dictionaryValues}
						name={'category'}
						disabled={!!incident}
						onValueChange={setSelectedCategoryValue}
					/>
				</li>
				{[FormType.SSOP_EDIT, FormType.SSOP_NEW].includes(formType()) && (
					<>
						<li>
							<OldDictionarySelector
								label={columns.severity.title}
								dictionary={columns.severity.oldDictionary!}
								name={'severity'}
							/>
						</li>
						<li>
							<OldDictionarySelector
								label={columns.issue_source.title}
								dictionary={columns.issue_source.oldDictionary!}
								name={'issue_source'}
							/>
						</li>
						<li>
							<DictionarySelector
								label={columns.operator.title}
								dictionary={columns.operator.dictionary!}
								name={'operator'}
							/>
						</li>
						<li>
							<OldDictionarySelector
								label={columns.ssop_type.title}
								dictionary={columns.ssop_type.oldDictionary!}
								name={'ssop_type'}
							/>
						</li>
						<li>
							<OldDictionarySelector
								dictionary={columns.ssop_service.oldDictionary!}
								name={'ssop_service'}
								label={columns.ssop_service.title}
							/>
						</li>
						<li>
							<TextArea name={'description'} label={columns.description.title} />
						</li>
					</>
				)}
				{formType() === FormType.ASBI_NEW && (
					<>
						<li>
							<Selector
								name={'type'}
								label={columns.type.title}
								items={
									typesData?.modelItems.items
										.filter(item => item.id && item.category_id.toString() === category)
										.map(item => item.id) || []
								}
								itemToString={item => {
									const type = typesData?.modelItems.items.find(type => type.id === item);
									if (type) return type.name;
									return item;
								}}
							/>
						</li>
						<li>
							<OldDictionarySelector
								dictionary={columns.asbi_event_type.oldDictionary!}
								name={'asbi_event_type'}
								label={columns.asbi_event_type.title}
							/>
						</li>
						<li>
							<InputDateTime name={'occured'} label={columns.occured.title} />
						</li>
						<li>
							<InputDateTime name={'detected'} label={columns.detected.title} />
						</li>
						<li>
							<Input name={'asbi_location_address'} label={columns.asbi_location_address.title} />
						</li>
						<li>
							<Input name={'asbi_device_model'} label={columns.asbi_device_model.title} />
						</li>
						<li>
							<TextArea name={'description'} label={columns.description.title} />
						</li>
						<li>
							<Input name={'asbi_additional_info'} label={columns.asbi_additional_info.title} />
						</li>
					</>
				)}
			</ul>
			{[FormType.SSOP_NEW, FormType.SSOP_EDIT, FormType.ASBI_NEW, FormType.ASBI_EDIT].includes(formType()) && (
				<>
					<ul className={formCss.fields}>
						{incident && incident.files && !!incident.files.length && (
							<li>
								<div>Приложенные файлы:</div>
								<ul className={css.fileList}>
									{incident.files.map(file => (
										<li key={file.id} className={css.file}>
											<Button iconOnly onClick={() => handleTogglePrepareRemoveFile(file.id)}>
												<CrossIcon />
											</Button>
											<div
												className={cls(
													beRemoveAttachedFileIds.includes(file.id) && css.strikethrough,
												)}
											>
												{file.name}
											</div>
										</li>
									))}
								</ul>
							</li>
						)}
						<li className={formCss.fileWrapper}>
							<IncidentFile
								pageId={pageId}
								setUploadedFiles={handleSetPreparedFileId}
								setRemovedFiles={handleDeletePreparedFileId}
								setFiles={setPreparedFiles}
								incidentId={incident?.id || undefined}
								buttonTitle={'Добавить файл'}
								buttonColor={'inherit'}
							/>
						</li>
					</ul>
					{[FormType.ASBI_EDIT, FormType.SSOP_EDIT].includes(formType()) && (
						<ul className={formCss.fields}>
							<li>
								<Button onClick={handleToggleComments} secondary>
									Открыть комментарии
								</Button>
							</li>
						</ul>
					)}
					<ul className={formCss.buttons}>
						<li>
							<Button loading={loading} onClick={checkUploadsThenSubmit}>
								{formikBag.values.id ? 'Редактировать' : 'Создать'}
							</Button>
						</li>
						<li>
							<Button onClick={onClose} color={'secondary'}>
								Отмена
							</Button>
						</li>
					</ul>
				</>
			)}
		</>
	);
};

export default IncidentForm;
