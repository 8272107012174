import {TableColumnType} from '../../queries-generated/types';
import {isValidSubnet} from '../../utils/ip-utils';

function validateColumn(value: any, type: TableColumnType, regExp?: string) {
	if (regExp) {
		return new RegExp(regExp).test(value);
	} else {
		switch (type) {
			case TableColumnType.Boolean:
				return typeof value === 'boolean';
			case TableColumnType.DateTime:
				// eslint-disable-next-line no-useless-escape
				return /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/.test(
					value,
				);

			case TableColumnType.Number:
				return typeof value === 'number';
			case TableColumnType.FileSize:
				return typeof value === 'number' && value >= 0 && Number.isInteger(value);
			case TableColumnType.String:
				return typeof value === 'string';
			case TableColumnType.Json:
				// eslint-disable-next-line no-useless-escape
				return /[^,:{}\[\]0-9.\-+Eaeflnr-u \n\r\t]/.test(value);
			case TableColumnType.Subnet:
				return isValidSubnet(value);
		}
	}
}

export default validateColumn;
