import React from 'react';
import css from './Calendar.module.module.css';
import listCss from '../Combobox/Dropdown.modules.css';
import {CUSTOM_PERIOD, DurationItem} from './Calendar';
import List from '../../List/List';

type Props = {
	value: DurationIso | null;
	onChange: (value: DurationIso) => void;
	height: number;
	items: DurationItem[];
};

const DurationCalendar: React.FC<Props> = ({value, onChange, items, height}) => {
	return (
		<div className={css.timeWrapper}>
			<div style={{height: height / 7}} className={css.timeHeader}>
				Продолжительность
			</div>
			<div style={{height}} className={listCss.list}>
				<List
					items={[...items, CUSTOM_PERIOD]}
					itemSelected={item => item.duration === value}
					onClick={item => onChange(item.duration)}
				/>
			</div>
		</div>
	);
};

export default DurationCalendar;
