import {format, formatDateIso, formatLocalIso, parse, parseIso} from 'ts-date/esm';
import {wordByCount} from './word-by-count';

enum DURATION_UNITS {
	Months = 'Months',
	Weeks = 'Weeks',
	Days = 'Days',
	Hours = 'Hours',
	Minutes = 'Minutes',
	Seconds = 'Seconds',
}

const durationUnitsValues = {
	[DURATION_UNITS.Months]: 24 * 60 * 60 * 30,
	[DURATION_UNITS.Weeks]: 24 * 60 * 60 * 7,
	[DURATION_UNITS.Days]: 24 * 60 * 60,
	[DURATION_UNITS.Hours]: 60 * 60,
	[DURATION_UNITS.Minutes]: 60,
	[DURATION_UNITS.Seconds]: 1,
};

const humanDurationUnitsValues = {
	[DURATION_UNITS.Months]: value => wordByCount(value, ['месяц', 'месяца', 'месяцев']),
	[DURATION_UNITS.Weeks]: value => wordByCount(value, ['неделя', 'недели', 'недель']),
	[DURATION_UNITS.Days]: value => wordByCount(value, ['день', 'дня', 'дней']),
	[DURATION_UNITS.Hours]: value => wordByCount(value, ['час', 'часа', 'часов']),
	[DURATION_UNITS.Minutes]: value => wordByCount(value, ['минута', 'минуты', 'минут']),
	[DURATION_UNITS.Seconds]: value => wordByCount(value, ['секунда', 'секунды', 'секунд']),
};

export function durationArray(duration: number, baseUnit: DURATION_UNITS = DURATION_UNITS.Seconds) {
	// NOTE: Приводим время к секундам
	duration = duration / durationUnitsValues[baseUnit];
	const durations: Array<{unit: DURATION_UNITS; value: number}> = [];
	let remains = duration;

	(Object.keys(durationUnitsValues) as DURATION_UNITS[]).forEach(unit => {
		const whole = Math.floor(remains / durationUnitsValues[unit]);
		remains = remains % durationUnitsValues[unit];
		if (whole) {
			durations.push({unit, value: whole});
		}
	});

	return durations;
}

export function duration(duration: number, baseUnit: DURATION_UNITS = DURATION_UNITS.Seconds) {
	return durationArray(duration, baseUnit)
		.map(duration => `${duration.value} ${humanDurationUnitsValues[duration.unit](duration.value)}`)
		.join('\u00A0');
}

export const HUMAN_FORMAT = 'DD.MM.YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export function humanDateToIso(dateString: string | null) {
	if (!dateString) return null;
	const date = parse(dateString, HUMAN_FORMAT);
	return formatDateIso(date);
}
export function dateIsoToHuman(dateIso?: DateIso | null, formatPattern: string = HUMAN_FORMAT) {
	if (!dateIso) return null;
	const date = parseIso(dateIso);
	return format(date, formatPattern);
}

export function dateToHuman(date: Date, formatPattern: string = HUMAN_FORMAT) {
	return format(date, formatPattern);
}

export function equalsDate(date1, date2) {
	if (date1 && date2) {
		return date1.substr(0, 10) === date2.substr(0, 10);
	}
	return false;
}

export function formatDateIsoAsLocalTimeOrThrow(date: Date): string {
	const result = formatLocalIso(date);
	if (result === null) throw new Error('Failed to parse as ISO 8601 date: "' + date.toString() + '"');
	return result;
}

export function formatDateIsoAsUtcTimeOrThrow(date: Date): string {
	const falselyDisplacedDate = new Date(date);
	falselyDisplacedDate.setMinutes(falselyDisplacedDate.getMinutes() + falselyDisplacedDate.getTimezoneOffset());
	const result = formatLocalIso(falselyDisplacedDate);
	if (result === null) throw new Error('Failed to parse as ISO 8601 date: "' + date.toString() + '"');
	return result + 'Z';
}

export function dateTimeToDateIso(date: Date): DateIso {
	return date.toISOString().substr(0, 10);
}
