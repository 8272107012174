import React, {useCallback, useRef, useState} from 'react';
import inputCss from '../Input/Input.module.css';
import {CommonInputInterface} from '../CommonInputInterface';
import {useFocus} from '../inputHooks';
import Field from '../Field/Field';
import css from './InputNumber.module.css';
import uid from '../../../utils/uid';
import cls from '../../../utils/cls';
import {ChevronDownIcon, ChevronUpIcon, CrossIcon} from '../../SvgIcon';
import {isMultiple, precision} from '../../../utils/number-utils';
import fieldCss from '../Field/Field.module.css';
import Button from '../Button/Button';

interface InputInterface extends CommonInputInterface<number | null> {
	setRef?: React.RefObject<HTMLInputElement>;
	value: number | null;
}

export type InputProps = InputInterface & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const InputNumber: React.FC<InputProps> = props => {
	const {
		error: propsError,
		disabled,
		className,
		helperText,
		underlined,
		bottomText,
		label,
		onChange: _,
		setRef,
		...inputProps
	} = props;
	const {hasFocus, onBlur, onFocus} = useFocus(props);
	const {value, onChange} = props;
	const [fieldValue, setFieldValue] = useState<string>();
	const ref = useRef<HTMLInputElement>(null);
	const max = (inputProps.max as number) ?? Infinity;
	const min = (inputProps.min as number) ?? -Infinity;
	const step = inputProps.step === 'any' ? null : (inputProps.step as number) ?? null;
	const [customError, setCustomError] = useState<string>();

	const handleIncrement = useCallback(() => {
		if (props.disabled || props.readOnly) return;
		const currentValue = parseFloat((value || '').toString()) || (inputProps.min as number) || 0;
		const fixedLen = Math.max(precision(currentValue), precision(step));
		const newValue = parseFloat((currentValue + (step || 1)).toFixed(fixedLen));
		onChange(newValue <= max ? newValue : max);
	}, [onChange, value, max, step, inputProps?.min, props.disabled, props.readOnly]);

	const handleDecrement = useCallback(() => {
		if (props.disabled || props.readOnly) return;
		const currentValue = parseFloat((value || '').toString()) || (inputProps.min as number) || 0;
		const fixedLen = Math.max(precision(currentValue), precision(step));
		const newValue = parseFloat((currentValue - (step || 1)).toFixed(fixedLen));
		onChange(newValue >= min ? newValue : min);
	}, [onChange, value, min, step, inputProps?.min, props.disabled, props.readOnly]);

	const handleScroll = useCallback(
		(event: any) => {
			if (hasFocus) event.deltaY > 0 ? handleDecrement() : handleIncrement();
		},
		[handleDecrement, handleIncrement, hasFocus],
	);

	const handleValueChange = useCallback(
		(field: string) => {
			if (props.disabled || props.readOnly) return;
			const number = parseFloat(field);
			if (number.toString() === field) {
				const value = number <= min ? min : number >= max ? max : number;
				if (step && !isMultiple(value, step)) setCustomError(`Число должно быть кратно ${step}`);
				else setCustomError(undefined);
				onChange(value);
				setFieldValue(undefined);
			} else {
				setFieldValue(field);
				setCustomError(undefined);
			}
		},
		[min, max, step, props.disabled, props.readOnly, setCustomError, onChange, setFieldValue],
	);

	const handleFieldChange = useCallback(
		event => {
			const value = event?.target?.value || '';
			if (value === '') {
				onChange(null);
				setFieldValue(undefined);
				return;
			}
			let afterDotCount = 0;
			let hasDot = false;
			const floatLen = precision(step);
			const field = [...value]
				.map((current, index, arr) => {
					if (step && hasDot && afterDotCount > floatLen) return '';

					if (index === 0 && current === '-') return '-';
					const prev = parseInt(arr[index - 1], 10);
					const currentNumber = parseInt(current, 10);
					if (current === ',' || current === '.') {
						if (Number.isFinite(prev) && !hasDot) {
							hasDot = true;
							return !step || floatLen > 0 ? '.' : '';
						} else return '';
					}
					if (isFinite(currentNumber)) {
						afterDotCount++;
						return current;
					}
					return '';
				})
				.join('');
			handleValueChange(field);
		},
		[onChange, step, handleValueChange, setFieldValue],
	);

	const handleBlur = useCallback(
		e => {
			if (fieldValue && ['.', '-'].includes(fieldValue[fieldValue.length - 1])) {
				const field = fieldValue.substring(0, fieldValue.length - 1);
				handleValueChange(field);
			}

			if (onBlur) onBlur(e);
		},
		[fieldValue, value, onBlur, handleValueChange],
	);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.keyCode === 38) {
				handleIncrement();
				e.preventDefault();
			} else if (e.keyCode === 40) {
				handleDecrement();
				e.preventDefault();
			}
		},
		[handleIncrement, handleDecrement],
	);

	const handleClearValue = useCallback(() => {
		onChange(null);
	}, []);

	const id = props.id || 'Input-' + uid();
	const error = customError || propsError;
	const hasError = error && error !== true;

	return (
		<Field
			commonProps={{...props, error}}
			hasFocus={hasFocus}
			underlined={underlined}
			bordered={!underlined}
			inputId={id}
			buttons={
				<>
					{value?.toString() && !disabled && (
						<Button action iconOnly onClick={handleClearValue} className={fieldCss.actionButton}>
							<CrossIcon width={14} height={14} />
						</Button>
					)}
					<Button
						action
						iconOnly
						onClick={handleIncrement}
						className={fieldCss.actionButton}
						disabled={(value || 0) >= max || disabled}
					>
						<ChevronUpIcon width={14} height={14} />
					</Button>
					<Button
						action
						iconOnly
						onClick={handleDecrement}
						className={fieldCss.actionButton}
						disabled={(value || 0) <= min || disabled}
					>
						<ChevronDownIcon width={14} height={14} />
					</Button>
				</>
			}
			topLabel={!!fieldValue || !!value?.toString() || hasFocus}
			className={className}
		>
			<input
				id={id}
				disabled={disabled}
				className={cls(
					css.input,
					!underlined && inputCss.bordered,
					inputCss.input,
					hasError && inputCss.hasError,
					hasFocus && inputCss.hasFocus,
					disabled && inputCss.disabled,
				)}
				{...(inputProps as any)}
				onChange={handleFieldChange}
				onBlur={handleBlur}
				onFocus={onFocus}
				onKeyDown={handleKeyDown}
				ref={ref}
				onWheel={handleScroll}
				value={fieldValue || value?.toString() || ''}
				type={'tel'}
				autoComplete={'off'}
			/>
		</Field>
	);
};

export default InputNumber;
