import React from 'react';
import Shimmer from '../Shimmer/Shimmer';

const ButtonShimmer: React.FC = () => {
	return (
		<Shimmer>
			<div style={{height: 34, width: 100, borderRadius: 6}} />
		</Shimmer>
	);
};

export default ButtonShimmer;
