import React from 'react';
import {CommonInputInterface} from '../../pirsInputs/CommonInputInterface';
import Selector from '../../pirsInputs/Selector/Selector';
import {useTableFormSelectorItemsQuery} from '../../../queries-generated/types';

export interface Props<T> extends CommonInputInterface<T | T[] | null> {
	id?: string;
	/* текущее значение */
	value: T | T[] | null;
	/* Название класса */
	className?: string;
	/* url по которому получать {key: string, value: string}, преобразования к данному виду проводить gql */
	url: string;
	/* Для множественного выбора значений в селекторе */
	multiple?: boolean;
}

function TableFormSelector<T>(props: Props<T>) {
	const {url, ...otherProps} = props;
	const {error, data} = useTableFormSelectorItemsQuery({
		variables: {
			url,
		},
	});

	if (error) return <>Error</>;

	const items = data?.tableFormSelectorItems.map(item => item.key);
	const itemToHtml = key => {
		const item = data?.tableFormSelectorItems.find(item => item.key === key);
		if (item) return item.value;
		else return key;
	};

	return <Selector {...otherProps} items={(items as any) || []} itemToString={itemToHtml} />;
}

export default TableFormSelector;
