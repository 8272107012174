import React from 'react';
import {wordByCount} from '../../../utils/word-by-count';
import {DataGridTree} from '../../DataGrid/useDataGridSelection';
import {InputContactsValue} from './InputContacts';

type Props = {
	value?: InputContactsValue;
};

export function contactsCountString(count: number) {
	if (!count) return 'Ничего не выбрано';

	return wordByCount(count, [
		'Выбран {d} контакт или группа',
		'Выбрано {d} контакта или группы',
		'Выбрано {d} контактов или групп',
	]);
}

const calculateCount = (value: InputContactsValue) => {
	const {totalSelected, tree} = value;

	if (totalSelected != null) return totalSelected;

	if (!tree) return 0;

	return calculateTreeCount(tree);
};

const calculateTreeCount = (directory: DataGridTree) => {
	if (!directory) return 0;

	let count = directory.totalSelected;
	if (directory.children) {
		count += directory.children.map(tree => calculateCount({tree})).reduce((acc, count) => acc + count, 0);
	}

	return count;
};

/*const isFullSelection = (value: InputContactsValue) => {
	const {totalSelected, total, tree} = value;

	if (total != null && total === totalSelected) return true;

	if (!tree) return false;

	return (tree.exclude && tree.exclude.length === 0) || (tree.include && tree.include.length === tree.total);
};*/

const SelectedContactsCount: React.FC<Props> = ({value}) => {
	if (!value) return <span>Ничего не выбрано</span>;

	return <span>{contactsCountString(calculateCount(value))}</span>;
};

export default SelectedContactsCount;
