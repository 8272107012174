import {Entity} from './useMetadata';
import React, {useCallback, useMemo, useState} from 'react';
import Selector from '../../pirsInputs/Selector/Selector';
import {FormikErrors} from 'formik/dist/types';
import css from './EntitiesMenu.module.css';
import {CrossFilled, WarningIcon} from '../../SvgIcon';
import useInspectorComments from './useInspectorComments';

type Props = {
	entities: Entity[];
	errors: FormikErrors<any>;
	parentRef: React.RefObject<HTMLDivElement>;
	headerRef: React.RefObject<HTMLDivElement>;
};
const EntitiesMenu: React.FC<Props> = ({entities, errors, parentRef, headerRef}) => {
	const [anchor, setAnchor] = useState();
	const {inspectorComments, showComments} = useInspectorComments();

	const handleChange = useCallback(
		item => {
			setAnchor(item);
			const el = document.getElementById(item);
			const content = document.getElementById('__content_root');

			if (el && content && parentRef.current && headerRef.current) {
				content.scrollTop =
					el.getBoundingClientRect().top -
					parentRef.current.getBoundingClientRect().top -
					headerRef.current.getBoundingClientRect().height;
			}
		},
		[headerRef, parentRef],
	);

	const formikErrorsPathList = Object.keys(errors);
	const inspectorCommentsParentPathList = useMemo(() => {
		if (inspectorComments?.length && showComments) {
			return inspectorComments.reduce((previousValue, currentValue) => {
				previousValue.push(currentValue.path[0]);
				return previousValue;
			}, [] as string[]);
		} else {
			return [];
		}
	}, [inspectorComments, showComments]);

	return (
		<Selector
			items={entities.map(entity => entity.attributePath)}
			value={anchor}
			itemToString={value => {
				const entiry = entities.find(entity => entity.attributePath === value);
				if (entiry) return entiry.caption;
				return '';
			}}
			itemToHtml={item => {
				const entry = entities.find(entity => entity.attributePath === item);
				if (entry && formikErrorsPathList.includes(entry.attributePath)) {
					return (
						<div className={css.itemToHtml}>
							{entry.caption} <CrossFilled className={css.validateErrorIcon} />
						</div>
					);
				} else if (entry && inspectorCommentsParentPathList.includes(entry.attributePath)) {
					return (
						<div className={css.itemToHtml}>
							{entry.caption} <WarningIcon className={css.inspectorCommentIcon} />
						</div>
					);
				} else if (entry) {
					return entry.caption;
				} else {
					return '';
				}
			}}
			onChange={handleChange}
		/>
	);
};
export default EntitiesMenu;
