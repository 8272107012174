import React from 'react';
import {TableRow, TableCell, TableBody} from '@material-ui/core';
import ResourceAttachmentsTableRow from './ResourceAttachmentsTableRow';
import css from './ResourceAttachmentsTableBody.module.css';
import UploadAttachmentsProgress from './UploadAttachmentsProgress';
import {ResourceAttachmentsTableItem} from '../../../queries-generated/types';

type Props = {
	columnsLength: number;
	onDelete: (id: any) => Promise<void>;
	tableItems?: ResourceAttachmentsTableItem[] | any;
	pageId?: string;
};

const ResourceAttachmentsTableBody: React.FC<Props> = ({tableItems, onDelete, columnsLength, pageId}) => {
	return (
		<TableBody>
			<UploadAttachmentsProgress pageId={pageId || ''} />

			{!tableItems?.length && (
				<TableRow>
					<TableCell colSpan={columnsLength}>
						<h3 className={css.errorMessage}>Похоже, вы еще не добавили ни одного файла</h3>
					</TableCell>
				</TableRow>
			)}

			{tableItems &&
				tableItems.length > 0 &&
				tableItems.map((tableItem, index) => {
					return <ResourceAttachmentsTableRow onDelete={onDelete} key={index} tableItem={tableItem} />;
				})}
		</TableBody>
	);
};

export default ResourceAttachmentsTableBody;
