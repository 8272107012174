import React, {useMemo} from 'react';
import {Link, useRouter} from 'react-named-hooks-router';
import cls from '../../utils/cls';
import css from './MainLayout.css';
import {HelpIcon, Logo} from '../SvgIcon';
import NotificationsComponent from '../Notifications/NotificationsComponent';
import UserInfoComponent from '../user/UserInfoComponent';
import ContextHelp from '../ContextHelp/ContextHelp';
import RightPanelButton from '../controlls/RightPanelButton/RightPanelButton';
import MainMenu from './MainMenu/MainMenu';
import useSessionStorage from '../hooks/useSessionStorage';
import List from '../List/List';

type Props = {
	routes?: {[name: string]: string};
	className?: string;
	wrapperClassName?: string;
	contextHelpKey?: string;
	padding?: boolean;
};

const MainLayout: React.FC<Props> = ({routes, className, padding, wrapperClassName, children, contextHelpKey}) => {
	const {routeName} = useRouter();

	const [allowedRoutes] = useSessionStorage<string[]>('allowed-routes');

	const menuItems = useMemo(() => {
		if (!routes) return [];

		return Object.keys(routes)
			.map(routeKey => {
				if (!allowedRoutes.includes(routes[routeKey])) return null;
				return {title: routeKey, route: routes[routeKey], selected: routes[routeKey] === routeName};
			})
			.filter(Boolean) as Array<{title: string; route: string; selected: boolean}>;
	}, [routes, routeName]);

	return (
		<div className={cls(css.wrapper, wrapperClassName)}>
			<div className={css.appBar}>
				<div className={css.left}>
					<div className={css.logo}>
						<Link route={'home'} tabIndex={0}>
							<Logo width={40} height={40} />
						</Link>
					</div>
					<div className={css.mainLinks}>
						<List
							items={menuItems}
							selectItems={menuItems.filter(item => item.selected)}
							overflow
							tabIndex={0}
							horizontal
						/>
					</div>
				</div>
				<div className={css.right}>
					<div className={css.links}>
						{contextHelpKey && (
							<RightPanelButton
								action
								iconOnly
								panelContent={<ContextHelp contextHelpKey={contextHelpKey} />}
								panelHeader={'Справочная информация'}
							>
								<HelpIcon />
							</RightPanelButton>
						)}
						<UserInfoComponent />
						<NotificationsComponent />
					</div>
				</div>
			</div>
			<div className={css.main}>
				<MainMenu />
				<div className={cls(className, css.content, padding && css.padding)} id={'__content_root'}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default MainLayout;
