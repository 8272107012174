import React, {useCallback, useEffect, useState} from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import {FileValue, FieldValidation} from '../../../queries-generated/types';
import useUploadFiles from './useUploadFiles';
import {notifyToastError} from '../../toast/Toast';
import {formatBytes} from '../../../utils/number-utils';
import {getFileExtension, getFileType, imagesTypes} from '../../../utils/files';
import uid from '../../../utils/uid';
import getImageDataUrl from '../../../utils/getImageDataUrl';
import cls from '../../../utils/cls';
import css from './DashboardInputFile.module.css';
import FilePreview from './FilePreview';
import InputFile from '../InputFile/InputFile';

interface InputInterface extends CommonInputInterface<FileValue | null> {
	forwardRef?: React.RefObject<HTMLInputElement>;
	validation?: FieldValidation;
}

const chunkLength = Number(process.env.REACT_APP_CHUNK_LENGTH);

export type InputProps = InputInterface & {
	link?: boolean;
	iconOnly?: boolean;
	action?: boolean;
	preview?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const DashboardInputFile: React.FC<InputProps> = props => {
	const {
		error,
		disabled,
		className,
		children,
		helperText,
		onChange,
		value: propsValue,
		defaultValue,
		forwardRef: propsForwardRef,
		validation,
		max,
		...inputProps
	} = props;

	const {setFiles, handleUploadFile, uploadingFiles, handleDelete, value} = useUploadFiles(
		(propsValue || defaultValue ? [propsValue || defaultValue] : []) as FileValue[],
	);

	// TODO
	useEffect(() => {
		onChange(value.length ? value[0] : null);
	}, [value]);

	const handleUploadFiles = useCallback(
		async (file: File) => {
			if (validation?.maxFileSize && file.size > validation.maxFileSize) {
				notifyToastError(`Размер файла ${file.name} превышает ${formatBytes(validation.maxFileSize)}`);
				return;
			}

			const ext = getFileExtension(file.name);
			if (validation?.allowedExtensions && !validation.allowedExtensions.includes(ext)) {
				notifyToastError(
					`Для загрузки доступны файлы следующих расширений: ${validation.allowedExtensions.join(', ')}`,
				);
				return;
			}

			const id = uid() + '';
			let imageDataUrl: string | undefined;
			if (imagesTypes.includes(file.type)) {
				imageDataUrl = await getImageDataUrl(file);
			}

			setFiles(current => ({
				...current,
				[id]: {
					id,
					type: getFileType(file.name),
					imageDataUrl,
					file,
					title: file.name,
					size: file.size,
					chunks: Math.ceil(file.size / Number(chunkLength)),
				},
			}));

			handleUploadFile(file, id);
		},
		[handleUploadFile],
	);

	const [dropHighlight, setDropHighlight] = useState(false);

	const handleDragEnter = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		setDropHighlight(true);
	}, []);
	const handleDragOver = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		setDropHighlight(true);
	}, []);

	const handleDragLeave = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		setDropHighlight(false);
	}, []);

	const handleDrop = useCallback(
		(event: React.DragEvent) => {
			if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
				handleUploadFiles(event.dataTransfer.files.item(0)!);
			}
			event.preventDefault();
			setDropHighlight(false);
		},
		[handleUploadFiles],
	);

	return (
		<div
			className={cls(css.wrapper, dropHighlight && css.dropHighlight)}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			<InputFile {...inputProps} onChange={handleUploadFiles} />
			<div className={css.preview}>
				{value?.map(file => (
					<FilePreview key={file.id} file={file} onDelete={handleDelete} />
				))}

				{uploadingFiles.map(uploadedFile => (
					<FilePreview key={uploadedFile.id} file={uploadedFile} />
				))}
			</div>
			<div className={css.dragEnterPlaceholder}>Перетащите файлы в эту область для загрузки</div>
		</div>
	);
};

export default DashboardInputFile;
