exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".SurveySummaryCharts-module__wrapper__1U3vS {\r\n\tmargin: 0;\r\n\tpadding: 10px;\r\n\tposition: relative;\r\n}\r\n\r\n.SurveySummaryCharts-module__stackedsWrapper__kjGCY {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tdisplay: flex;\r\n\tflex-flow: row wrap;\r\n\tjustify-content: flex-start;\r\n}\r\n\r\n.SurveySummaryCharts-module__stackedWrapperItem__32Xhl {\r\n\tpadding: 10px;\r\n\twidth: calc(33.33% - 20px);\r\n}\r\n\r\n.SurveySummaryCharts-module__stackedWideWrapperItem__3xGBx {\r\n\tpadding: 10px;\r\n\twidth: calc(100% - 20px);\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\SurveySummaryChartsPage\\SurveySummaryCharts.module.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,SAAS;CACT,UAAU;CACV,aAAa;CACb,mBAAmB;CACnB,2BAA2B;AAC5B;;AAEA;CACC,aAAa;CACb,0BAA0B;AAC3B;;AAEA;CACC,aAAa;CACb,wBAAwB;AACzB","file":"SurveySummaryCharts.module.css","sourcesContent":[".wrapper {\r\n\tmargin: 0;\r\n\tpadding: 10px;\r\n\tposition: relative;\r\n}\r\n\r\n.stackedsWrapper {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tdisplay: flex;\r\n\tflex-flow: row wrap;\r\n\tjustify-content: flex-start;\r\n}\r\n\r\n.stackedWrapperItem {\r\n\tpadding: 10px;\r\n\twidth: calc(33.33% - 20px);\r\n}\r\n\r\n.stackedWideWrapperItem {\r\n\tpadding: 10px;\r\n\twidth: calc(100% - 20px);\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"wrapper": "SurveySummaryCharts-module__wrapper__1U3vS",
	"stackedsWrapper": "SurveySummaryCharts-module__stackedsWrapper__kjGCY",
	"stackedWrapperItem": "SurveySummaryCharts-module__stackedWrapperItem__32Xhl",
	"stackedWideWrapperItem": "SurveySummaryCharts-module__stackedWideWrapperItem__3xGBx"
};