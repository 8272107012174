import {ViewItem} from './api/useViewItemTree';
import * as React from 'react';
import getViewHtml from './api/getViewHtml';
import css from './ViewComponent.module.css';
import useComponentCommonButtons, {ComponentCommonButtonsProps, renderCommonButtons} from './useComponentCommonButtons';
import cls from '../../utils/cls';

type Props = {
	viewItem: ViewItem;
	parentViewItem?: ViewItem;
	getPollInterval?: (viewItem: ViewItem) => number;
	componentCommonButtonsProps?: ComponentCommonButtonsProps;
	theme?: 'body' | 'neutral';
};

const ViewComponent: React.FC<Props> = ({
	viewItem,
	parentViewItem,
	getPollInterval,
	componentCommonButtonsProps,
	theme,
}) => {
	const commonButtons = useComponentCommonButtons(viewItem, componentCommonButtonsProps);

	if (!viewItem || !viewItem.id || !viewItem.component) return null;

	const width =
		parentViewItem &&
		parentViewItem.component.type === 'HorizontalBlock' &&
		(viewItem.component.props.width
			? viewItem.component.props.width
			: (`${100 /
					parentViewItem.children.filter(viewItem => !viewItem.component.props.width).length}%` as string) ||
			  undefined);

	const isFullHeight = viewItem.component.props.height === '100%';
	return (
		<div style={{width, height: isFullHeight ? '100%' : undefined}} className={css.componentWrapper}>
			{viewItem.id === 'root' && viewItem.component.type === 'Dashboard' && (
				<div className={cls(css.filters, css.commonFilters)}>{renderCommonButtons(commonButtons)}</div>
			)}
			{getViewHtml(
				viewItem,
				<>
					{viewItem.children.map(nextViewItem => (
						<ViewComponent
							viewItem={nextViewItem}
							parentViewItem={viewItem}
							key={nextViewItem.id}
							getPollInterval={getPollInterval}
							theme={viewItem.component.type === 'Snippet' || theme === 'neutral' ? 'neutral' : 'body'}
						/>
					))}
				</>,
				{
					skipQuery: !viewItem.dataSource?.type,
					pollInterval: getPollInterval ? getPollInterval(viewItem) : undefined,
					theme: theme || 'body',
					css,
					componentCommonButtonsProps,
				},
			)}
		</div>
	);
};

export default ViewComponent;
