import React from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {Diagram} from '../../../../../queries-generated/types';
import ChartWrapperWithData from '../ChartsBase/ChartWrapperWithData';
import {ChartTypes} from '../ChartsBase/ChartWrapper';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	pollInterval?: number;
};

const defaultData: Diagram = {
	series: [
		{
			name: 'Brands',
			points: [
				{
					name: 'Chrome',
					y: 61.41,
				},
				{
					name: 'Internet Explorer',
					y: 11.84,
				},
				{
					name: 'Firefox',
					y: 10.85,
				},
				{
					name: 'Edge',
					y: 4.67,
				},
				{
					name: 'Safari',
					y: 4.18,
				},
				{
					name: 'Sogou Explorer',
					y: 1.64,
				},
				{
					name: 'Opera',
					y: 1.6,
				},
				{
					name: 'QQ',
					y: 1.2,
				},
				{
					name: 'Other',
					y: 2.61,
				},
			],
		},
	],
};

const PieChart: React.FC<Props> = ({viewItem, skipQuery, pollInterval}) => {
	return (
		<ChartWrapperWithData
			pollInterval={pollInterval}
			viewItem={viewItem}
			skipQuery={skipQuery}
			defaultData={defaultData}
			mapper={diagram => diagram}
			type={ChartTypes.Pie}
		/>
	);
};

export default PieChart;
