exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".GridDiagramBase-module__wrapper__31dQk {\r\n\tdisplay: flex;\r\n\tposition: relative;\r\n}\r\n\r\n\t.GridDiagramBase-module__wrapper__31dQk .GridDiagramBase-module__table__231Kt,\r\n\t.GridDiagramBase-module__wrapper__31dQk .GridDiagramBase-module__chart__wpkZU {\r\n\t\twidth: 50%;\r\n\t}\r\n\r\n\t.GridDiagramBase-module__wrapper__31dQk.GridDiagramBase-module__top__Wt6F4,\r\n\t.GridDiagramBase-module__wrapper__31dQk.GridDiagramBase-module__bottom__3NQF7 {\r\n\t\tflex-direction: column;\r\n\t}\r\n\r\n\t.GridDiagramBase-module__wrapper__31dQk.GridDiagramBase-module__top__Wt6F4 .GridDiagramBase-module__table__231Kt,\r\n\t\t.GridDiagramBase-module__wrapper__31dQk.GridDiagramBase-module__top__Wt6F4 .GridDiagramBase-module__chart__wpkZU,\r\n\t\t.GridDiagramBase-module__wrapper__31dQk.GridDiagramBase-module__bottom__3NQF7 .GridDiagramBase-module__table__231Kt,\r\n\t\t.GridDiagramBase-module__wrapper__31dQk.GridDiagramBase-module__bottom__3NQF7 .GridDiagramBase-module__chart__wpkZU {\r\n\t\t\twidth: 100%;\r\n\t\t}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\settings\\views\\components\\GridDiagramBase\\GridDiagramBase.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,kBAAkB;AAgBnB;;CAdC;;EAEC,UAAU;CACX;;CAEA;;EAEC,sBAAsB;CAMvB;;CAJC;;;;GAEC,WAAW;EACZ","file":"GridDiagramBase.module.css","sourcesContent":[".wrapper {\r\n\tdisplay: flex;\r\n\tposition: relative;\r\n\r\n\t.table,\r\n\t.chart {\r\n\t\twidth: 50%;\r\n\t}\r\n\r\n\t&.top,\r\n\t&.bottom {\r\n\t\tflex-direction: column;\r\n\r\n\t\t.table,\r\n\t\t.chart {\r\n\t\t\twidth: 100%;\r\n\t\t}\r\n\t}\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"wrapper": "GridDiagramBase-module__wrapper__31dQk",
	"table": "GridDiagramBase-module__table__231Kt",
	"chart": "GridDiagramBase-module__chart__wpkZU",
	"top": "GridDiagramBase-module__top__Wt6F4",
	"bottom": "GridDiagramBase-module__bottom__3NQF7"
};