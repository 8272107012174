exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".EntitiesList-module__commentButtonWrapper__1Wgl_ {\r\n\twidth: 24px;\r\n\tmargin-bottom: 10px;\r\n}\r\n\r\n\t.EntitiesList-module__commentButtonWrapper__1Wgl_ .EntitiesList-module__commentButton__3JCOq {\r\n\t\tpadding: 0;\r\n\t\tmin-width: auto;\r\n\t\twidth: 24px;\r\n\t\theight: 24px;\r\n\t\tborder-radius: 50%;\r\n\t}\r\n\r\n\t.EntitiesList-module__commentButtonWrapper__1Wgl_ .EntitiesList-module__commentButton__3JCOq .EntitiesList-module__warningIcon__2goPn {\r\n\t\t\tfill: var(--warning-foreground);\r\n\t\t}\r\n\r\n\t.EntitiesList-module__comment__17v18 {\r\n\tfont-size: 14px;\r\n\tmargin-top: 4px;\r\n\twidth: 300px;\r\n\tpadding: 10px;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\pirs\\form\\EntitiesList.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,mBAAmB;AAapB;;CAXC;EACC,UAAU;EACV,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;CAKnB;;CAHC;GACC,+BAA+B;EAChC;;CAIF;CACC,eAAe;CACf,eAAe;CACf,YAAY;CACZ,aAAa;AACd","file":"EntitiesList.module.css","sourcesContent":[".commentButtonWrapper {\r\n\twidth: 24px;\r\n\tmargin-bottom: 10px;\r\n\r\n\t.commentButton {\r\n\t\tpadding: 0;\r\n\t\tmin-width: auto;\r\n\t\twidth: 24px;\r\n\t\theight: 24px;\r\n\t\tborder-radius: 50%;\r\n\r\n\t\t.warningIcon {\r\n\t\t\tfill: var(--warning-foreground);\r\n\t\t}\r\n\t}\r\n}\r\n\r\n.comment {\r\n\tfont-size: 14px;\r\n\tmargin-top: 4px;\r\n\twidth: 300px;\r\n\tpadding: 10px;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"commentButtonWrapper": "EntitiesList-module__commentButtonWrapper__1Wgl_",
	"commentButton": "EntitiesList-module__commentButton__3JCOq",
	"warningIcon": "EntitiesList-module__warningIcon__2goPn",
	"comment": "EntitiesList-module__comment__17v18"
};