export enum PredefinedPeriod {
	H1 = 'H1',
	H2 = 'H2',
	H3 = 'H3',
	H6 = 'H6',
	H12 = 'H12',
	D1 = 'D1',
	D2 = 'D2',
	D3 = 'D3',
	W1 = 'W1',
	W2 = 'W2',
	M1 = 'M1',
	M3 = 'M3',
	Custom = 'Custom',
}

export const PredefinedPeriodValues: {[key in PredefinedPeriod]: string} = {
	H1: '1 час',
	H2: '2 часа',
	H3: '3 часа',
	H6: '6 часов',
	H12: '12 часов',
	D1: '1 день',
	D2: '2 дня',
	D3: '3 дня',
	W1: '1 неделя',
	W2: '2 недели',
	M1: '1 месяц',
	M3: '3 месяца',
	Custom: 'Выбор',
};

export type Interval = {
	from: Date;
	to: Date;
};

export const getPredefinedInterval = (predefined: PredefinedPeriod, to?: Date): Interval => {
	const toDate = to ? new Date(to) : new Date();
	if (!to) toDate.setMinutes(Math.trunc(toDate.getMinutes() - (toDate.getMinutes() % 5)));

	toDate.setSeconds(0);
	toDate.setMilliseconds(0);

	const fromDate: Date = new Date(toDate);
	switch (predefined) {
		case PredefinedPeriod.H1:
			fromDate.setHours(fromDate.getHours() - 1);
			break;
		case PredefinedPeriod.H2:
			fromDate.setHours(fromDate.getHours() - 2);
			break;
		case PredefinedPeriod.H3:
			fromDate.setHours(fromDate.getHours() - 3);
			break;
		case PredefinedPeriod.H6:
			fromDate.setHours(fromDate.getHours() - 6);
			break;
		case PredefinedPeriod.H12:
			fromDate.setHours(fromDate.getHours() - 12);
			break;
		case PredefinedPeriod.D1:
			fromDate.setDate(fromDate.getDate() - 1);
			break;
		case PredefinedPeriod.D2:
			fromDate.setDate(fromDate.getDate() - 2);
			break;
		case PredefinedPeriod.D3:
			fromDate.setDate(fromDate.getDate() - 3);
			break;
		case PredefinedPeriod.W1:
			fromDate.setDate(fromDate.getDate() - 7);
			break;
		case PredefinedPeriod.W2:
			fromDate.setDate(fromDate.getDate() - 14);
			break;
		case PredefinedPeriod.M1:
			fromDate.setMonth(fromDate.getMonth() - 1);
			break;
		case PredefinedPeriod.M3:
			fromDate.setMonth(fromDate.getMonth() - 3);
			break;
		default:
			console.error('period invalid');
			break;
	}

	return {from: fromDate, to: toDate};
};

export default getPredefinedInterval;
