exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".SurveyResourceOwnersPage-module__headerDataWrapper__1k_gx {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n\tflex-wrap: nowrap;\r\n\tpadding-top: 5px;\r\n\tpadding-left: 10px;\r\n}\r\n\r\n.SurveyResourceOwnersPage-module__stackedHeaderItems__vVFNw {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tflex-wrap: nowrap;\r\n\tjustify-content: flex-start;\r\n\talign-items: center;\r\n}\r\n\r\n.SurveyResourceOwnersPage-module__combobox__kklfZ {\r\n\twidth: 300px;\r\n\tmargin-right: 10px;\r\n}\r\n\r\n.SurveyResourceOwnersPage-module__fab__3yKHG {\r\n\tbottom: 4em;\r\n\tright: 2em;\r\n}\r\n\r\n.SurveyResourceOwnersPage-module__content__3hwVA {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tpadding: 10px;\r\n\tgap: 10px;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\SurveyResourceOwnersPage\\SurveyResourceOwnersPage.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,mBAAmB;CACnB,iBAAiB;CACjB,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,iBAAiB;CACjB,2BAA2B;CAC3B,mBAAmB;AACpB;;AAEA;CACC,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,UAAU;AACX;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,aAAa;CACb,SAAS;AACV","file":"SurveyResourceOwnersPage.module.css","sourcesContent":[".headerDataWrapper {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n\tflex-wrap: nowrap;\r\n\tpadding-top: 5px;\r\n\tpadding-left: 10px;\r\n}\r\n\r\n.stackedHeaderItems {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tflex-wrap: nowrap;\r\n\tjustify-content: flex-start;\r\n\talign-items: center;\r\n}\r\n\r\n.combobox {\r\n\twidth: 300px;\r\n\tmargin-right: 10px;\r\n}\r\n\r\n.fab {\r\n\tbottom: 4em;\r\n\tright: 2em;\r\n}\r\n\r\n.content {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tpadding: 10px;\r\n\tgap: 10px;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"headerDataWrapper": "SurveyResourceOwnersPage-module__headerDataWrapper__1k_gx",
	"stackedHeaderItems": "SurveyResourceOwnersPage-module__stackedHeaderItems__vVFNw",
	"combobox": "SurveyResourceOwnersPage-module__combobox__kklfZ",
	"fab": "SurveyResourceOwnersPage-module__fab__3yKHG",
	"content": "SurveyResourceOwnersPage-module__content__3hwVA"
};