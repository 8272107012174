exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".Comboboxes-module__item__18xPU {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tfont-size: 12px;\r\n}\r\n\r\n.Comboboxes-module__likeIcon__3blFt {\r\n\tfont-size: 20px;\r\n\tmargin-right: 5px;\r\n}\r\n\r\n.Comboboxes-module__itemMore__1htWk {\r\n\tfont-size: 14px;\r\n}\r\n\r\n.Comboboxes-module__userFilter__3ibDu {\r\n\tmargin: 3px 0;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\Comboboxes\\Comboboxes.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;AACd","file":"Comboboxes.module.css","sourcesContent":[".item {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tfont-size: 12px;\r\n}\r\n\r\n.likeIcon {\r\n\tfont-size: 20px;\r\n\tmargin-right: 5px;\r\n}\r\n\r\n.itemMore {\r\n\tfont-size: 14px;\r\n}\r\n\r\n.userFilter {\r\n\tmargin: 3px 0;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"item": "Comboboxes-module__item__18xPU",
	"likeIcon": "Comboboxes-module__likeIcon__3blFt",
	"itemMore": "Comboboxes-module__itemMore__1htWk",
	"userFilter": "Comboboxes-module__userFilter__3ibDu"
};