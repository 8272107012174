import React, {Dispatch, ReducerAction, ReducerState, useContext, useEffect, useMemo, useReducer} from 'react';
import {ActionsUnion} from '../../../redux/helper';
import {
	resourceRegisterUploadFileActions,
	resourceRegisterUploadFileInitialState,
	resourceRegisterUploadFileReducer,
	ResourceRegisterUploadFileState,
	ResourceRegisterUploadFileStatus,
} from './ResourceRegistryUploadFilesReducer';
import {reduxLogger} from '../helpers/reduxLogger';
import {useBeforeUnload} from '../../hooks/useBeforeUnload';
import {useResourceRegisterUploadFileQueue} from './useResourceRegisterUploadFileQueue';

export type ResourceRegisterUploadFileContextType = {
	state: ReducerState<typeof resourceRegisterUploadFileReducer>;
	dispatch: Dispatch<ReducerAction<typeof resourceRegisterUploadFileReducer>>;
} & typeof resourceRegisterUploadFileActions;

export const ResourceRegisterUploadFileContext = React.createContext<ResourceRegisterUploadFileContextType>(
	null as any,
);

const numberOfActiveUploads = 10;

function ResourceRegisterUploadFileProvider({children}) {
	const [state, dispatch] = useReducer(
		reduxLogger<ResourceRegisterUploadFileState, ActionsUnion<typeof resourceRegisterUploadFileActions>>(
			resourceRegisterUploadFileReducer,
		),
		resourceRegisterUploadFileInitialState,
	);

	const actionBounds = useMemo(() => {
		const actionBounds = {};
		Object.keys(resourceRegisterUploadFileActions).forEach(actionKey => {
			const action = resourceRegisterUploadFileActions[actionKey];
			actionBounds[actionKey] = payload => dispatch(action(payload));
		});
		return actionBounds;
	}, [dispatch]);
	const value = {
		state,
		dispatch,
		...actionBounds,
	};

	const {uploadFile} = useResourceRegisterUploadFileQueue(value);
	useEffect(() => {
		const activeUploads = state.fileUploadQueueArray.filter(
			fileObj => fileObj.status === ResourceRegisterUploadFileStatus.LOADING,
		);
		if (activeUploads.length < numberOfActiveUploads) {
			const queueUploads = state.fileUploadQueueArray.filter(
				fileObj => fileObj.status === ResourceRegisterUploadFileStatus.QUEUE,
			);
			if (queueUploads.length) {
				for (let i = 0; i < numberOfActiveUploads; i++) {
					const file = queueUploads[i];
					if (file) {
						dispatch(
							resourceRegisterUploadFileActions.setFileStatus({
								id: file.id,
								fileStatus: ResourceRegisterUploadFileStatus.LOADING,
							}),
						);
						uploadFile(file.id, file.file, file.url);
					}
				}
			}
		}
	}, [state, state.fileUploadQueueArray.length]);

	useBeforeUnload(
		Boolean(
			state.fileUploadQueueArray.filter(fileObj => fileObj.status === ResourceRegisterUploadFileStatus.LOADING)
				.length,
		),
	);

	return (
		<ResourceRegisterUploadFileContext.Provider value={value as any}>
			{children}
		</ResourceRegisterUploadFileContext.Provider>
	);
}

export function useResourceRegisterUploadFiles(id?: string) {
	const {
		addFiles,
		removeFiles,
		state: {fileUploadQueueArray},
	} = useContext(ResourceRegisterUploadFileContext);

	const files = id ? fileUploadQueueArray.filter(fileObj => fileObj.pageId === id) : fileUploadQueueArray;

	return {addFiles, removeFiles, files};
}

export default ResourceRegisterUploadFileProvider;
