import React, {useCallback, useContext, useMemo, useReducer} from 'react';
import {ActionsUnion, createAction} from '../../redux/helper';

type State = {
	forms: PlainObjectOf<PlainObjectOf<any>>; // {formId: {key1: 'val', key2: 'val'}}
};

const initialState: State = {
	forms: {},
};

enum ActionType {
	SET_FORM = 'SET_FORM',
	CLEAR_FORM = 'CLEAR_FORM',
}

const actions = {
	setForm: (payload: {id: string; values: PlainObjectOf<any>}) => createAction(ActionType.SET_FORM, payload),
	clearForm: (id: string) => createAction(ActionType.CLEAR_FORM, id),
};

export function reducer(state: State, action: ActionsUnion<typeof actions>): State {
	switch (action.type) {
		case ActionType.SET_FORM:
			if (action.payload.id) {
				return {
					...state,
					forms: {
						...state.forms,
						[action.payload.id]: action.payload.values,
					},
				};
			}
			return state;
		case ActionType.CLEAR_FORM:
			if (action.payload && state.forms[action.payload]) {
				const forms = {...state.forms};
				delete forms[action.payload];
				return {
					...state,
					forms,
				};
			}
			return state;
		default:
			return state;
	}
}

type UnsubmittedValuesContextTypeProviderProps = {
	children: React.ReactNode;
};

type UnsubmittedValuesContextType = State & typeof actions;

export const UnsubmittedValuesContext = React.createContext<UnsubmittedValuesContextType>(null as any);

export function UnsubmittedValuesProvider({children}: UnsubmittedValuesContextTypeProviderProps) {
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
	});

	const actionBounds = useMemo(() => {
		const actionBounds = {};
		Object.keys(actions).forEach(actionKey => {
			const action = actions[actionKey];
			actionBounds[actionKey] = payload => {
				dispatch(action(payload));
			};
		});
		return actionBounds;
	}, [dispatch]);

	const value = {
		...state,
		...actionBounds,
	};

	return <UnsubmittedValuesContext.Provider value={value as any}>{children}</UnsubmittedValuesContext.Provider>;
}

function useUnsubmittedValues(formId: string) {
	const {forms, clearForm, setForm} = useContext(UnsubmittedValuesContext);

	const handleClearForm = useCallback(() => {
		clearForm(formId);
	}, [formId]);

	const handleSetForm = useCallback(
		(values: PlainObjectOf<any>) => {
			setForm({id: formId, values});
		},
		[formId],
	);

	return {
		unsubmittedValues: forms[formId],
		clearUnsubmittedValues: handleClearForm,
		setUnsubmittedValues: handleSetForm,
	};
}

export default useUnsubmittedValues;
