import React from 'react';
import {Entity} from '../form/useMetadata';
import ChoiceGroupOnlyValues from './ChoiceGroupOnlyValues';
import EntityItemOnlyValues from './EntityItemOnlyValues';
import css from './EntitiesListOnlyValues.module.css';
import cls from '../../../utils/cls';
import {isDirtyValues} from '../form/helper';

type Props = {
	entities: Entity[];
	deep?: number;
	values: any;
};

const EntitiesListOnlyValues: React.FC<Props> = ({entities, deep, values}) => {
	const choiceGroup: Entity[] = [];

	return isDirtyValues(values) ? (
		<div className={cls(deep ? css.level : css.base)}>
			{entities.map((entity, index) => {
				if (entity.choiceGroup && entities.length === index + 1) {
					choiceGroup.push(entity);
					return (
						<ChoiceGroupOnlyValues
							key={entity.attributePath}
							values={values}
							entities={choiceGroup}
							deep={deep}
						/>
					);
				} else if (entity.choiceGroup) {
					choiceGroup.push(entity);
					return null;
				} else if (choiceGroup.length) {
					return (
						<ChoiceGroupOnlyValues
							key={entity.attributePath}
							values={values}
							entities={choiceGroup}
							deep={deep}
						/>
					);
				} else {
					return (
						<EntityItemOnlyValues
							key={entity.attributePath}
							values={values[entity.keyword]}
							entity={entity}
							deep={deep}
						/>
					);
				}
			})}
		</div>
	) : null;
};

export default EntitiesListOnlyValues;
