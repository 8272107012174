import {
	ModelItemsDocument,
	ModelItemsQuery,
	ModelItemsQueryVariables,
	PossibleValue,
	TableColumn,
	TableDictionaryControl,
	TableRelationType,
} from '../../queries-generated/types';
import React from 'react';
import css from './FullTableForm.module.css';
import comboboxesCss from '../Comboboxes/Comboboxes.module.css';
import {ApolloClient} from '@apollo/client';

export type CustomFormProps = {
	columns: TableColumn[];
	onClose: () => void;
	editMode: boolean;
	submitFormLoading: boolean;
};

export function promiseFileReader(file) {
	return new Promise(resolve => {
		const fr = new FileReader();
		fr.onload = () => {
			resolve(fr.result);
		};
		fr.readAsDataURL(file);
	});
}

export function patchValuesForCombobox(values: {[key: string]: any}, columns: TableColumn[]) {
	const newValues = {};
	columns.forEach(column => {
		const value = values[column.id];

		if (
			(column.dictionary || column.oldDictionary) &&
			column.dictionaryControl === TableDictionaryControl.Combobox
		) {
			newValues[column.id] = {key: value};
		} else if (column.multiSelector) {
			if (value) {
				newValues[column.id] = value.map(value => value.id);
			}
		} else if (column.possibleValues && typeof value === 'string') {
			newValues[column.id] = {id: value, title: value};
		} else {
			newValues[column.id] = value;
		}
	});

	return newValues;
}

export function patchValuesForSubmit(values: {[key: string]: any}, columns: TableColumn[]): PlainObjectOf<any> {
	const newValues = {};
	columns.forEach(column => {
		const value = values[column.id];

		// Для совместимости с текущим бэком, он присылает строку и мы ему в ответ из селектора строку TEST-1103
		if (column.possibleValues && typeof value === 'object' && value.id) {
			newValues[column.id] = value.id;
		} else if (values[column.id] && typeof value === 'object' && value.key) {
			newValues[column.id] = value.key;
		} else {
			newValues[column.id] = value;
		}
	});

	return newValues;
}

export function getFilteredColumns(allColumns: TableColumn[], editMode: boolean): TableColumn[] {
	return allColumns.filter(
		column => (editMode && column.canEdit) || (!editMode && (column.canCreate || column.canEdit)),
	);
}

export function getDefaultValues(filteredColumns: TableColumn[]): PlainObjectOf<any> {
	const defaultValues = {};

	filteredColumns.forEach(column => {
		defaultValues[column.id] =
			column.isArray ||
			(column.relation?.type &&
				[TableRelationType.HasMany, TableRelationType.ManyToMany].includes(column.relation.type))
				? []
				: null;
		if (column.defaultValue !== null) {
			defaultValues[column.id] = column.defaultValue;
		}
	});
	return defaultValues;
}

export const InputLabel: React.FC<{title: string; required?: boolean}> = ({required, title}) => {
	return (
		<>
			{title}
			{required && <span className={css.required}>*</span>}
		</>
	);
};

export function getCommonColumnProps(
	column: TableColumn,
): {name: string; label: React.ReactNode; disabled: boolean | undefined} {
	return {
		name: column.id,
		label: <InputLabel required={column.required} title={column.title} />,
		disabled: column.disabled,
	};
}

export function getComboboxItems<T>(
	model: string,
	apolloClient: ApolloClient<object>,
	minLength: number,
	variables?: PlainObjectOf<any>,
): (value: unknown) => Promise<T[]> {
	return value => {
		return new Promise(resolve => {
			if ((!value && minLength === 0) || (typeof value === 'string' && value.length >= minLength)) {
				apolloClient
					.query<ModelItemsQuery, ModelItemsQueryVariables>({
						query: ModelItemsDocument,
						variables: {
							model,
							variables: {
								query: typeof value === 'string' && value.length >= minLength ? value : undefined,
								...variables,
							},
						},
					})
					.then(result => {
						resolve(result.data.modelItems.items as T[]);
					});
			} else resolve([]);
		});
	};
}

export function modelValueToPossibleValue(value: PlainObjectOf<any>, column: TableColumn): PossibleValue | null {
	const possibleValueMapper = column.relation?.possibleValueMapper;
	if (!value || !possibleValueMapper) return null;

	return {
		id: value[possibleValueMapper?.id || 'id'],
		title: value[possibleValueMapper.title],
		firstLine: possibleValueMapper?.firstLine && value[possibleValueMapper.firstLine],
		secondLine: possibleValueMapper?.secondLine && value[possibleValueMapper.secondLine],
		iconText: possibleValueMapper?.iconText && value[possibleValueMapper.iconText],
	};
}

export const modelPossibleValueToHtml = (value: PossibleValue | null) => {
	return (
		<>
			{value ? (
				<div className={comboboxesCss.item}>
					{value.iconText && <div className={comboboxesCss.likeIcon}>{value.iconText}</div>}
					<div>
						<div>{value.firstLine || value.title}</div>
						<div>{value.secondLine}</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export const modelPossibleValueToString = (value: PossibleValue | null): string => {
	const title = value && typeof value === 'object' && value.title;
	if (!title) return '';
	return title;
};
