import React, {useState} from 'react';
import {ViewItem} from 'components/view/api/useViewItemTree';
import {UserFiltersProvider} from './api/useUserFilters';
import ViewWrapperWithContext, {VirtualDashboardIds} from './ViewWrapperWithContext';

type Props = {
	root: ViewItem;
	id?: string;
	version?: number;
	virtualDashboardId?: VirtualDashboardIds;
	filters?: PlainObjectOf<any>;
};

type DataSourceContextType = {
	reloadCounter: number;
	setReloadCounter: (counter: number) => void;
};

type ViewOptionsContextType = {
	dashboardId?: string;
	version?: number;
	root?: ViewItem;
};

export const DataSourceContext = React.createContext<DataSourceContextType>({
	reloadCounter: 0,
	setReloadCounter: () => {},
});
export const ViewOptionsContext = React.createContext<ViewOptionsContextType>({});

const ViewWrapper: React.FC<Props> = ({id, root, version, virtualDashboardId, filters}) => {
	const [reloadCounter, setReloadCounter] = useState(0);

	return (
		<DataSourceContext.Provider value={{reloadCounter, setReloadCounter}}>
			<ViewOptionsContext.Provider value={{dashboardId: id, version, root}}>
				<UserFiltersProvider commonFiltersFields={root.commonFilters || []} filters={filters} id={id}>
					<ViewWrapperWithContext
						root={root}
						setReloadCounter={setReloadCounter}
						reloadCounter={reloadCounter}
						id={id}
						version={version}
						virtualDashboardId={virtualDashboardId}
					/>
				</UserFiltersProvider>
			</ViewOptionsContext.Provider>
		</DataSourceContext.Provider>
	);
};

export default ViewWrapper;
