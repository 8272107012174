import React, {useCallback, useEffect, useRef, useState} from 'react';
import css from './SplitScreen.module.css';
import cls from '../../../../utils/cls';

type Props = {
	align: 'top' | 'left' | 'right' | 'bottom';
	width: number | string;
	height: number | string;
	parentRef: React.RefObject<HTMLDivElement>;
};

function getDimensions(fullDimension: string | number, newDimension?: number) {
	const numDimension = parseInt(fullDimension as string, 10);
	const isPercent = typeof fullDimension === 'string' && fullDimension.includes('%');
	if (newDimension === undefined) {
		const dimension = isPercent ? numDimension / 2 + '%' : numDimension / 2;
		return {first: dimension, second: dimension};
	} else {
		if (isPercent) {
			return {first: newDimension, second: `calc(${fullDimension} - ${newDimension}px)`};
		} else {
			return {first: newDimension, second: (fullDimension as number) - newDimension};
		}
	}
}

type ResultDimensions = {
	firstHeight: number | string;
	firstWidth: number | string;
	secondHeight: number | string;
	secondWidth: number | string;
};

function useSplitScreen(
	props: Props,
): ResultDimensions & {ResizeControl: JSX.Element; firstCss: string; secondCss: string; reloadCounter: number} {
	const {align, height, width, parentRef} = props;
	const resizerRef = useRef<HTMLDivElement>(null);
	const [reloadCounter, setReloadCounter] = useState(0);
	const [dimensions, setDimensions] = useState<{first: number | string; second: number | string}>(
		getDimensions(['top', 'bottom'].includes(align) ? height : width),
	);
	const resizeTop = useRef<number | string>();
	const [resizeInProgress, setResizeInProgress] = useState(false);

	const handleResize = useCallback(
		(event: any) => {
			if (resizeInProgress && parentRef.current && resizerRef.current) {
				if (['top', 'bottom'].includes(align)) {
					const dragTop = event.pageY - parentRef.current.getBoundingClientRect().top;
					const {height} = parentRef.current.getBoundingClientRect();
					resizeTop.current = dragTop >= height ? height : dragTop < 0 ? 0 : dragTop;
					resizerRef.current.style.top = resizeTop.current.toString() + 'px';
				} else {
					const dragLeft = event.pageX - parentRef.current.getBoundingClientRect().left;
					const {width} = parentRef.current.getBoundingClientRect();
					resizeTop.current = dragLeft >= width ? width : dragLeft < 0 ? 0 : dragLeft;
					resizerRef.current.style.left = resizeTop.current.toString() + 'px';
				}
			}
		},
		[resizeInProgress, align],
	);

	const handleMouseDown = useCallback(() => {
		setResizeInProgress(true);
	}, []);

	const handleStopResize = useCallback(() => {
		if (resizeTop) {
			setDimensions(
				getDimensions(['top', 'bottom'].includes(align) ? height : width, resizeTop.current as number),
			);
			setReloadCounter(reloadCounter + 1);
			setResizeInProgress(false);
		}
	}, [align, height, width, reloadCounter, resizeInProgress]);

	useEffect(() => {
		document.body.addEventListener('mouseup', handleStopResize);
		document.body.addEventListener('mousemove', handleResize);

		return () => {
			document.body.removeEventListener('mouseup', handleStopResize);
			document.body.removeEventListener('mousemove', handleResize);
		};
	}, [handleStopResize, handleResize]);

	const ResizeControl = (
		<div className={css.wrapper}>
			<div className={cls(css.backdrop, resizeInProgress && css.active)} />
			<div
				className={cls(css.resize, css[align], resizeInProgress && css.active)}
				onMouseDown={handleMouseDown}
				ref={resizerRef}
				style={{
					top: ['top', 'bottom'].includes(align) ? dimensions.first : undefined,
					left: ['left', 'right'].includes(align) ? dimensions.first : undefined,
				}}
			>
				<div className={css.dragIcon} />
			</div>
		</div>
	);

	let resultDimensions: Partial<ResultDimensions> = {};

	if (align === 'bottom') {
		resultDimensions = {
			firstHeight: dimensions.first,
			firstWidth: '100%',
			secondHeight: dimensions.second,
			secondWidth: '100%',
		};
	} else if (align === 'top') {
		resultDimensions = {
			firstHeight: dimensions.second,
			firstWidth: '100%',
			secondHeight: dimensions.first,
			secondWidth: '100%',
		};
	} else if (align === 'left') {
		resultDimensions = {
			firstHeight: '100%',
			firstWidth: dimensions.second,
			secondHeight: '100%',
			secondWidth: dimensions.first,
		};
	} else {
		resultDimensions = {
			firstHeight: '100%',
			firstWidth: dimensions.first,
			secondHeight: '100%',
			secondWidth: dimensions.second,
		};
	}

	return {
		...(resultDimensions as ResultDimensions),
		ResizeControl,
		firstCss: css.firstComponent,
		secondCss: css.secondComponent,
		reloadCounter,
	};
}

export default useSplitScreen;
