import React, {useCallback, useState} from 'react';
import Button from '../pirsInputs/Button/Button';
import cls from '../../utils/cls';
import css from './ReadMore.module.css';

type Props = React.HTMLAttributes<HTMLDivElement> & {text?: string; visibleTextLength?: number};

const ReadMore: React.FC<Props> = ({text = '', visibleTextLength = 80, className, ...otherProps}) => {
	const [showMore, setShowMore] = useState<boolean>(false);
	const handleToggleShowMore = useCallback(() => {
		setShowMore(!showMore);
	}, [showMore, setShowMore]);

	const textLength = text.length;

	return (
		<div {...otherProps}>
			{textLength < visibleTextLength ? (
				<span className={css.text}>{text}</span>
			) : !showMore && textLength > visibleTextLength ? (
				<>
					<span className={css.text}>{text.slice(0, visibleTextLength)}</span>...
					<Button className={cls(css.button, css.showButton)} action iconOnly onClick={handleToggleShowMore}>
						показать весь текст
					</Button>
				</>
			) : (
				<>
					<span className={css.text}>{text}</span>
					<Button className={css.button} action iconOnly onClick={handleToggleShowMore}>
						скрыть текст
					</Button>
				</>
			)}
		</div>
	);
};

export default ReadMore;
