import React, {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {DataSourceNotification, GraphGrid, FieldType, useGraphGridQuery} from '../../../../../queries-generated/types';
import {DataSourceContext} from '../../../../view/ViewWrapper';
import usePrevious from '../../../../hooks/usePrevious';
import {isNotifiedDataSource, filtersToDataSourceParams} from '../helper';
import {useComponentFilters} from '../../../../view/api/useUserFilters';
import {notifyDataSource} from '../../../../toast/DataSourceToast';
import ErrorSnippet from '../../../../ErrorSnippet/ErrorSnippet';
import {NetworkStatus} from '@apollo/client';
import cssError from '../ChartsBase/ChartWrapperWithData.module.css';
import ProgressRing from '../../../../controlls/Loader/ProgressRing';
import css from './GraphGridComponent.module.css';
import DataGrid from '../../../../DataGrid/DataGrid';
import cls from '../../../../../utils/cls';
import GraphBase from '../Graph/GraphBase';
import useDataGridOptions from '../GridComponent/useDataGridOptions';
import DataGridPagination from '../../../../DataGrid/DataGridPagination';
import useSplitScreen from '../../splitScreen/useSplitScreen';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import viewComponentCss from '../../../../view/ViewComponent.module.css';
import useComponentCommonButtons from '../../../../view/useComponentCommonButtons';
import useTableItemMouseOver from '../GraphTimeLineGridComponent/useTableItemMouseOver';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	height: number | string;
	pollInterval?: number;
};

const defaultData: GraphGrid = {
	nodes: [
		{id: '1', label: '1 узел'},
		{id: '2', label: '2 узел'},
		{id: '3', label: '3 узел'},
		{id: '4', label: '4 узел'},
	],
	edges: [
		{
			id: '1-2',
			from: '1',
			to: '2',
		},
		{
			id: '1-3',
			from: '1',
			to: '3',
		},
		{
			id: '1-4',
			from: '1',
			to: '4',
		},
	],
	fields: [
		{id: '1', title: 'Колонка 1', type: FieldType.String, visible: true},
		{id: '2', title: 'Колонка 2', type: FieldType.String, visible: true, defaultSort: true},
		{id: '3', title: 'Колонка 3', type: FieldType.String, visible: true},
	],
	tableItems: [
		{'1': 'Запись 1', '2': 'Запись 2', '3': 'Запись 3'},
		{'1': 'Запись 4', '2': 'Запись 5', '3': 'Запись 6'},
		{'1': 'Запись 7', '2': 'Запись 8', '3': 'Запись 9'},
	],
	options: {
		total: 3,
	},
};

const GraphGridComponent: React.FC<Props> = ({viewItem, skipQuery, height, pollInterval}) => {
	const {reloadCounter} = useContext(DataSourceContext);
	const prevReloadCounter = usePrevious(reloadCounter);

	const align = viewItem.component.props?.tableAlignment?.id || 'bottom';

	const {filters, waiting} = useComponentFilters(viewItem);
	const {setData} = useComponentsStoredData(viewItem.id);

	const {
		page,
		firstDefaultSort,
		itemsPerPage,
		sortOrder,
		sort,
		onChangeSort,
		onChangeItemsPerPage,
		onChangePage,
		total,
		setGridData,
	} = useDataGridOptions({
		defaultNumberOfRows: viewItem.component?.props.defaultNumberOfRows,
		viewItemId: viewItem.id,
	});

	const wrapperRef = useRef<HTMLDivElement>(null);
	const graph = useRef<any>(null);
	const setGraph = useCallback(graphObj => {
		graph.current = graphObj;
	}, []);

	const {firstHeight, secondHeight, firstWidth, secondWidth, ResizeControl, secondCss, firstCss} = useSplitScreen({
		align,
		width: '100%',
		height,
		parentRef: wrapperRef,
	});

	if (filters) {
		if (sort) filters.sort = `${sortOrder ? '+' : '-'}${sort}`;
		if (itemsPerPage) {
			filters.startIndex = page * itemsPerPage;
			filters.endIndex = page * itemsPerPage + (itemsPerPage - 1);
		}
	}

	const {data, loading, refetch, error, networkStatus} = useGraphGridQuery({
		variables: {
			dataSourceId: viewItem.dataSource?.type || '',
			filters: filtersToDataSourceParams(filters, viewItem.dataSource?.columns),
		},
		skip: skipQuery || waiting,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		pollInterval,
		onCompleted: ({graphGrid}) => {
			setGridData({fields: graphGrid.fields, options: graphGrid.options});
			if (isNotifiedDataSource(viewItem.actions))
				notifyDataSource(graphGrid.notifications as DataSourceNotification[]);
		},
	});
	useEffect(() => {
		const graph = skipQuery ? defaultData : data?.graphGrid;
		setData({...viewItem.dataSource, data: graph});
	}, [defaultData, data, skipQuery, viewItem]);

	useEffect(() => {
		if (prevReloadCounter !== undefined && reloadCounter !== prevReloadCounter && !skipQuery) {
			refetch();
		}
	}, [reloadCounter, skipQuery, prevReloadCounter]);

	const gridRef = useRef<HTMLDivElement>(null);

	const graphGrid = skipQuery ? defaultData : data?.graphGrid;

	const {handleMouseLeave, handleMouseEnter} = useTableItemMouseOver(graphGrid!, graph, false);

	const commonButtons = useComponentCommonButtons(viewItem);

	const gridOptions = useMemo(() => {
		return {
			sort: sort || firstDefaultSort,
			onChangeSort: onChangeSort,
			sortOrder: sortOrder,
			fields: graphGrid?.fields,
			onRowMouseEnter: handleMouseEnter,
			onRowMouseLeave: handleMouseLeave,
		};
	}, [sort, firstDefaultSort, onChangeSort, graphGrid?.fields]);

	if (error)
		return <ErrorSnippet error={error} errorHeader={'Ошибка загрузки данных для таблицы'} refetch={refetch} />;

	if ((loading && networkStatus !== NetworkStatus.poll) || !graphGrid)
		return (
			<div className={cssError.error}>
				<ProgressRing />
			</div>
		);

	const Graph = (
		<div className={cls(css.chart, firstCss)} style={{height: firstHeight, width: firstWidth}}>
			<GraphBase
				viewItemId={viewItem.id}
				data={graphGrid}
				direction={viewItem.component.props.alignment?.id || 'star'}
				hideDeleted={viewItem.component.props.hide_deleted}
				hasHistory={!!graphGrid.options.hasHistory}
				hasHistoryInitialMoment={!!graphGrid.options.hasHistoryInitialMoment}
				hasHistoryPresentMoment={!!graphGrid.options.hasHistoryPresentMoment}
				historyDates={graphGrid.options.historyDates || []}
				firstProgressionItem={viewItem.component.props.firstProgressionItem}
				progressionStep={viewItem.component.props.progressionStep}
				showDirectionControl={viewItem.component.props.showDirectionControl}
				showReplay={viewItem.component.props.showReplay || false}
				replayIntervalInSeconds={viewItem.component.props.replayIntervalInSeconds || 10}
				commonButtons={['bottom', 'left'].includes(align) ? commonButtons : undefined}
				onReady={setGraph}
			/>
		</div>
	);

	const Grid = (
		<div className={cls(css.table, secondCss)} ref={gridRef} style={{height: secondHeight, width: secondWidth}}>
			{graphGrid.tableItems.length > 0 ? (
				<div className={css.tableWrapper}>
					{['top', 'right'].includes(align) && (
						<div className={viewComponentCss.filters}>
							{commonButtons?.RefreshAction}
							{commonButtons?.FilterAction}
						</div>
					)}
					<DataGrid
						options={gridOptions}
						items={graphGrid.tableItems}
						loading={loading && networkStatus !== NetworkStatus.poll}
						className={css.tableContent}
					/>
					<DataGridPagination
						onChangePage={onChangePage}
						onChangeItemsPerPage={onChangeItemsPerPage}
						itemsPerPage={itemsPerPage}
						page={page}
						total={total}
					/>
				</div>
			) : (
				<p>Нет данных.</p>
			)}
		</div>
	);

	const Content = ['top', 'left'].includes(align) ? (
		<>
			{Grid}
			{Graph}
		</>
	) : (
		<>
			{Graph}
			{Grid}
		</>
	);

	return (
		<div className={cls(css.wrapper, css[align])} style={{height}} ref={wrapperRef}>
			{Content}
			{ResizeControl}
		</div>
	);
};

export default GraphGridComponent;
