import {useCallback, useMemo} from 'react';
import {useModelItemsQuery} from '../../queries-generated/types';

function resolveAnomalyFamilyText(anomalyFamilyId: string, asFrom: string, asTo: string): string {
	switch (anomalyFamilyId) {
		case '0':
		case '3':
		case '4':
			return `из АС ${asFrom}, в АС ${asTo}`;
		case '1':
			return `через АС ${asFrom}, в АС ${asTo}`;
		case '2':
			return `захвачено у АС ${asFrom}, АС захватчика ${asTo}`;
		default:
			return '';
	}
}

export enum SuperAnomalyType {
	ACTIVE = 'active',
	NESTED = 'nested',
	IGNORED = 'ignored',
}

export const useSuperAnomaly = (id: string, superAnomalyType?: SuperAnomalyType) => {
	const filter = useMemo(() => {
		return JSON.stringify({
			field: 'id',
			operator: '=',
			type: 'int',
			value: parseInt(id, 10),
		});
	}, [id]);

	const model = useMemo((): string => {
		switch (superAnomalyType) {
			case SuperAnomalyType.NESTED:
				return 'superAnomalyNested';
			case SuperAnomalyType.IGNORED:
				return 'superAnomalyIgnored';
			default:
				return 'superAnomalyActive';
		}
	}, [superAnomalyType]);

	const {data, loading, error, refetch} = useModelItemsQuery({
		variables: {
			model,
			variables: {
				filter,
			},
		},
	});

	const item = data?.modelItems.items[0];

	const modelItemToHeader = useCallback((item?: PlainObjectOf<any>) => {
		if (item) {
			const {anomaly_type, anomaly_family, as_from, as_to} = item;

			const asFrom = as_from ? `${as_from.name}(${as_from.number})` : '';
			const asTo = as_to ? `${as_to.name}(${as_to.number})` : '';

			return `${anomaly_type.name}, ${resolveAnomalyFamilyText(anomaly_family.id, asFrom, asTo)}`;
		}

		return '';
	}, []);

	return {
		header: modelItemToHeader(item),
		modelItemToHeader,
		superAnomalyItem: item,
		loading,
		error,
		refetch,
	};
};
