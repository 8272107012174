import React, {useCallback, useEffect, useState} from 'react';
import css from './ResourceAttachmentsTableContainer.module.css';
import {useRouter} from 'react-named-hooks-router';
import {usePirsAttachmentDeleteMutation, usePirsAttachmentsQuery} from '../../../queries-generated/types';
import Button from '../../../components/pirsInputs/Button/Button';
import InputFile from '../../../components/pirsInputs/InputFile/InputFile.formik';
import {Form, FormikProvider, useFormik} from 'formik';
import {useResourceRegisterUploadFiles} from '../../../components/commonRedux/ResourceRegistryUploadFiles/ResourceRegistryUploadFilesProvider';
import {ResourceRegisterUploadFileStatus} from '../../../components/commonRedux/ResourceRegistryUploadFiles/ResourceRegistryUploadFilesReducer';
import CustomErrorPopup from '../../../components/controlls/CustomErrorPopup/CustomErrorPopup';
import {ApolloError} from '@apollo/client';
import ResourceAttachmentsTable from './ResourceAttachmentsTable';
import {notifyToastError} from '../../../components/toast/Toast';
import {EditIcon, FileUploadIcon} from '../../../components/SvgIcon';

type Values = {
	file: File | undefined;
};

type Props = {
	reFetchSession: () => void;
};

const ResourceAttachmentsTableContainer: React.FC<Props> = ({reFetchSession}) => {
	const {routeParams} = useRouter<{keyword: string; id: string}>();
	const pageId = `resourceAttachmentsPage/${routeParams.keyword}/${routeParams.id}`;
	const {addFiles, files} = useResourceRegisterUploadFiles(pageId);

	const [deleteAttachmentError, setDeleteAttachmentError] = useState<ApolloError>();
	const [deleteAttachment] = usePirsAttachmentDeleteMutation();

	const {data, error, loading, refetch} = usePirsAttachmentsQuery({
		variables: {
			input: {
				resourceId: Number(routeParams.id),
				keyword: routeParams.keyword,
			},
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
	});

	useEffect(() => {
		files.forEach(file => {
			if (file.status === ResourceRegisterUploadFileStatus.LOADED) {
				refetch();
				reFetchSession();
			}
		});
	}, [files, reFetchSession]);

	const handleDelete = useCallback(
		async id => {
			setDeleteAttachmentError(undefined);
			deleteAttachment({
				variables: {
					attachmentId: id,
				},
			})
				.then(() => {
					reFetchSession();
					refetch();
				})
				.catch(error => {
					setDeleteAttachmentError(error);
				});
		},
		[deleteAttachment, routeParams, reFetchSession],
	);

	const formikBag = useFormik<Values>({
		initialValues: {
			file: undefined,
		},
		onSubmit: values => {
			if (values.file) {
				if (values.file.size === 0) {
					notifyToastError('Ваш файл пустой, укажите корректный файл');
				} else if (values.file.size > 1024 * 1024 * 10) {
					// Это ограничение установленно в ПИРС
					notifyToastError('Размер загружаемого документа должен быть менее 10 МБайт');
				} else {
					addFiles([
						{
							pageId,
							file: values.file!,
							url: `/services/uploadPirsAttachment/${routeParams.keyword}/${routeParams.id}`,
						},
					]);
				}
			}
		},
		enableReinitialize: true,
	});

	const handleSubmit = () => {
		setTimeout(() => {
			formikBag.submitForm();
		});
	};

	return (
		<div className={css.container}>
			{deleteAttachmentError && <CustomErrorPopup error={deleteAttachmentError} />}
			<ResourceAttachmentsTable
				pageId={pageId}
				tableData={data?.pirsAttachments}
				refetch={refetch}
				onDelete={handleDelete}
				error={error}
				loading={loading}
			/>
			<div className={css.footer}>
				<FormikProvider value={formikBag}>
					<Form>
						<InputFile name={'file'} onCustomChange={handleSubmit}>
							<FileUploadIcon />
							Загрузить новый файл
						</InputFile>
					</Form>
				</FormikProvider>
				<Button
					route={'resourceRegisterInstance'}
					params={{type: routeParams.keyword, id: routeParams.id}}
					secondary
				>
					<EditIcon />
					Перейти к детальным сведениям
				</Button>
			</div>
		</div>
	);
};

export default ResourceAttachmentsTableContainer;
