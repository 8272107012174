import React from 'react';
import cls from '../../../../../utils/cls';
import css from '../../../../view/ViewComponent.module.css';

type Props = React.HTMLAttributes<HTMLDivElement> & {type?: 'h1' | 'h2' | 'h3'};

const Heading: React.FC<Props> = props => {
	const {children, className, type = 'h1', ...otherProps} = props;
	const typeClassNames = cls(
		type === 'h1' && css.heading1,
		type === 'h2' && css.heading2,
		type === 'h3' && css.heading3,
	);
	const classNames = className ? cls(className, typeClassNames) : cls(css.component, typeClassNames);

	return (
		<div className={classNames} {...otherProps}>
			{children}
		</div>
	);
};

export default Heading;
