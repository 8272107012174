import * as React from 'react';
import css from './FileUploadSnippet.module.css';
import Button from '../../components/pirsInputs/Button/Button';
import {format} from 'ts-date/esm/locale/ru';
import {formatBytes} from '../../utils/number-utils';
import {useRouter} from 'react-named-hooks-router';

type Props = {
	onSubmitForm: () => void;
	onCancelUpload: () => void;
	fileName: string;
	createdAt: Date;
	uploadedAt: Date;
	size: number;
	id: string;
};

const FileUploadSnippetDone: React.FC<Props> = ({
	onCancelUpload,
	onSubmitForm,
	fileName,
	createdAt,
	uploadedAt,
	size,
	id,
}) => {
	const {params} = useRouter<{id: string}>();
	const {id: sectionId} = params;

	return (
		<div className={css.wrapper}>
			<div className={css.buttonArea}>
				<Button onClick={onSubmitForm}>Представить</Button>
			</div>
			<div className={css.dataArea}>
				<ul className={css.dataList}>
					<li>
						Файл{' '}
						<a href={`/services/filesConsole/${id}/?isLocal=true&sectionId=${sectionId}`} download>
							{fileName}
						</a>{' '}
						загружен успешно
					</li>
					<li>Создан: {format(createdAt, 'DD.MM.YYYY HH:mm')}</li>
					<li>Загружен: {format(uploadedAt, 'DD.MM.YYYY HH:mm')}</li>
					<li>Размер: {formatBytes(size)}</li>
					<li>
						<a className={css.link} onClick={onCancelUpload}>
							Удалить
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default FileUploadSnippetDone;
