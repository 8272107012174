import React, {useCallback, useEffect, useRef, useState} from 'react';
import ModalPopup from '../../controlls/ModalPopup/ModalPopup';
import cls from '../../../utils/cls';
import Button from '../../pirsInputs/Button/Button';
import css from './useDeleteConfirm.module.css';

type Props = {
	onConfirm: () => void;
	onCancel: () => void;
	open: boolean;
	text: string | React.ReactNode;
	header?: string | React.ReactNode;
};
type UseProps = {
	callback: (...props: any) => void;
	text?: string | React.ReactNode;
	header?: string | React.ReactNode;
};
const DeleteConfirm: React.FC<Props> = ({open, text, header, onCancel, onConfirm}) => {
	const okRef = useRef<any>(null);

	useEffect(() => {
		if (okRef.current) {
			setTimeout(() => okRef.current.focus());
		}
	}, [open]);

	return (
		<ModalPopup open={open} onClose={onCancel} maxWidth={400} header={header}>
			<div className={cls('modal-popup-form')}>{text}</div>
			<div className={cls('modal-popup-buttons', css.buttons)}>
				<Button type={'button'} secondary onClick={onCancel} visibleFocus>
					Нет
				</Button>
				<Button type={'button'} setRef={okRef} onClick={onConfirm} visibleFocus>
					Да
				</Button>
			</div>
		</ModalPopup>
	);
};
const useDeleteConfirm = ({callback, text, header}: UseProps, dependencies?: any[]) => {
	const propsRef = useRef<any>([]);

	const [open, setOpen] = useState(false);

	const handleCancel = useCallback(() => {
		setOpen(false);
	}, [setOpen, dependencies]);

	const handleConfirm = useCallback(() => {
		setOpen(false);
		callback(...propsRef.current);
	}, [setOpen, dependencies]);

	return {
		openConfirm: (...props) => {
			setOpen(true);
			if (props) propsRef.current = props;
		},
		open,
		Confirm: (
			<DeleteConfirm
				text={text || 'Вы уверены?'}
				header={header}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
				open={open}
			/>
		),
	};
};
export default useDeleteConfirm;
