import React from 'react';
import EntityComponent from './EntityComponent';
import css from './Entity.module.css';
import cls from '../../../utils/cls';
import EntitiesCollection from './EntitiesCollection';
import {Entity} from './useMetadata';
import EntitiesList from './EntitiesList';

type Props = {
	entity: Entity;
	deep?: number;
	name: string;
	errors?: any;
	required: boolean;
	type: string;
	id?: string;
};

const EntityItem: React.FC<Props> = ({entity, required, deep, name, errors, type, id}) => {
	return (
		<div key={entity.attributePath}>
			{entity.childAttributes && entity.childAttributes.length > 0 ? (
				<div className={cls(!deep ? css.mainWrapper : css.wrapper)}>
					{!!deep && <h3>{entity.caption}</h3>}
					<div
						className={cls(
							!!deep && css.childrenWrapper,
							errors && typeof errors === 'string' && css.errorBlock,
						)}
					>
						{!deep && (
							<h1
								id={entity.attributePath}
								className={cls(errors && typeof errors === 'string' && css.errorText)}
							>
								{entity.caption}
							</h1>
						)}
						{entity.__typename === 'ObjectCollectionDescriptor' ? (
							<EntitiesCollection
								entity={entity}
								name={name}
								deep={deep}
								errors={errors ? errors[entity.keyword] : undefined}
								required={required}
								id={id}
								type={type}
							/>
						) : (
							<EntitiesList
								deep={(deep || 0) + 1}
								name={name}
								entities={entity.childAttributes}
								errors={errors ? errors[entity.keyword] : undefined}
								required={required}
								id={id}
								type={type}
							/>
						)}
					</div>
				</div>
			) : entity.__typename === 'ScalarCollectionDescriptor' ? (
				<div className={cls(!deep ? css.mainWrapper : css.wrapper)}>
					{!!deep && <h3>{entity.caption}</h3>}
					{!deep && <h1 id={entity.attributePath}>{entity.caption}</h1>}
					<EntitiesCollection
						entity={entity}
						name={name}
						deep={deep}
						required={required}
						id={id}
						type={type}
					/>
				</div>
			) : (
				<div className={cls(!deep && css.mainWrapper)}>
					{!deep && <h1 id={entity.attributePath}>{entity.caption}</h1>}
					<EntityComponent entity={entity} name={name} required={required} />
				</div>
			)}
			{errors && typeof errors === 'string' && <p className={css.errorText}>{errors}</p>}
		</div>
	);
};

export default EntityItem;
