import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
	ModelItemDocument,
	ModelItemQuery,
	ModelItemQueryVariables,
	SortOrder,
	TableColumn,
	TableMeta,
	useCreateModelMutation,
	useDeleteModelMutation,
	useModelItemsQuery,
	useUpdateModelMutation,
	TableData,
	WithRelation,
} from '../../queries-generated/types';
import {useRouter} from 'react-named-hooks-router';
import useDeepCompareEffect from '../../components/hooks/useDeepCompareEffect';
import FullTableWithHook from '../../components/FullTable/FullTableWithHook';
import {useApolloClient} from '@apollo/client';
import {ApolloError} from '@apollo/client';
import {CustomFormProps} from './FullTableFormHelpers';
import {FormikErrors} from 'formik';
import {notifyToastError} from '../toast/Toast';

export type FullTableBagType = {
	onChangeSort: (column: string) => void;
	onChangePage: (page: number) => void;
	onChangeItemsPerPage: (itemsPerPage: number) => void;
	onUserWantsCreate: () => void;
	onUserWantsUpdate: (id: any) => void;
	onUserWantsDelete: (id: any) => void;
	onDelete: (id: any) => void;
	onMassDelete: () => void;
	onChange: (item: any, id: any) => void;
	onClose: () => void;
	reload: () => void;
	page: number;
	sort: SortOrder;
	sortBy: string;
	getItemById: (id: string) => Promise<any>;
	itemsPerPage: number;
	meta: TableMeta | undefined;
	editableId: string | boolean;
	deletableId: string | undefined;
	allColumns: TableColumn[];
	visibleColumns: TableColumn[];
	selectedItems: PlainObjectOf<any>[];
	detailsColumns?: TableColumn[];
	onSelect?: (items: PlainObjectOf<any>[]) => void;
};

export type FullTableQueryBag = {
	loading: boolean;
	submitFormLoading: boolean;
	data?: {modelItems: TableData};
	modelItemsError?: ApolloError;
};

type Params = {
	tableFields: string[];
	editable?: boolean;
	creatable?: boolean;
	deletable?: boolean;
	massDeletable?: boolean;
	selectable?: boolean;
	editTitle?: string;
	showEditButtonCallback?: (item: {[key: string]: any}) => boolean;
	showDeleteButtonCallback?: (item: {[key: string]: any}) => boolean;
	createTitle?: string;
	variables?: any;
	cellCallback?: {[row: string]: (value: any, item: {[key: string]: any}) => React.ReactNode};
	detailCellCallback?: {[row: string]: (value: any, item: {[key: string]: any}) => React.ReactNode};
	detailsFields?: string[];
	additionalControls?: (item: {[key: string]: any}) => React.ReactNode;
	additionalFilters?:
		| React.ReactNode
		| ((selectedItems: PlainObjectOf<any>[], items: PlainObjectOf<any>[] | undefined) => React.ReactNode);
	updateForm?: React.FC<CustomFormProps>;
	createForm?: React.FC<CustomFormProps>;
	validateFn?: (values: any) => FormikErrors<any>;
	submitFn?: (values: any) => any;
	skip?: boolean;
	initItemsPerPage?: number;
	withRelations?: WithRelation[];
	actionTableHeadCellCallback?: (selectedItems: PlainObjectOf<any>[]) => React.ReactNode;
};

function useFullTableWithRefetch(
	model: string,
	options: Params,
): {Table: () => JSX.Element; refresh: () => void; columns: TableColumn[]; selectedItems: PlainObjectOf<any>[]} {
	const {variables, withRelations, tableFields, detailsFields, skip, initItemsPerPage} = options;
	const {routeParams, routeName, pushRoute} = useRouter<{
		page?: string;
		itemsPerPage?: string;
		sort?: SortOrder;
		sortBy?: string;
	}>();

	const client = useApolloClient();

	const [sortBy, setSortBy] = useState<string>(routeParams.sortBy || 'id');
	const [sort, setSort] = useState<SortOrder>(routeParams.sort || SortOrder.Desc);
	const [page, setPage] = useState<number>(Number(routeParams.page) || 0);
	const [itemsPerPage, setItemsPerPage] = useState<number>(
		Number(routeParams?.itemsPerPage) || initItemsPerPage || 30,
	);
	const [meta, setMeta] = useState<TableMeta>();
	const [allColumns, setAllColumns] = useState<TableColumn[]>([]);
	const [visibleColumns, setVisibleColumns] = useState<TableColumn[]>([]);
	const [detailsColumns, setDetailsColumns] = useState<TableColumn[]>();
	const [editableId, setEditableId] = useState<string | boolean>(false);
	const [deletableId, setDeletableId] = useState<string>();
	const [selectedItems, setSelectedItems] = useState<PlainObjectOf<any>[]>([]);

	const [createModel, {loading: createModelLoading}] = useCreateModelMutation();
	const [updateModel, {loading: updateModelLoading}] = useUpdateModelMutation();
	const [deleteModel] = useDeleteModelMutation();

	const {data, loading, error: modelItemsError, refetch} = useModelItemsQuery({
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		variables: {
			model,
			params: {
				sortBy,
				sort,
				page,
				itemsPerPage,
			},
			withRelations,
			variables,
		},
		notifyOnNetworkStatusChange: true,
		skip,
		onCompleted: () => {
			setSelectedItems([]);
		},
	});

	// Для того чтобы не выскакивало модельное окно с прошлыми ошибками мутаций при перезагрузке таблицы
	const handleRefetchModel = useCallback(() => {
		refetch();
	}, [refetch]);

	const handleChangeSort = useCallback(
		field => {
			const isDesc = sortBy === field && sort === SortOrder.Desc;
			const sortOrder = isDesc ? SortOrder.Asc : SortOrder.Desc;
			setSort(sortOrder);
			setSortBy(field);
			pushRoute(routeName, {...routeParams, sort: sortOrder, sortBy: field});
		},
		[pushRoute, routeName, routeParams, sort, sortBy, setSort, setSortBy],
	);

	const handleChangePage = useCallback(
		page => {
			setPage(page);
			pushRoute(routeName, {...routeParams, page});
		},
		[setPage, pushRoute, routeParams, routeName],
	);

	const handleChangeItemsPerPage = useCallback(
		itemsPerPage => {
			setItemsPerPage(itemsPerPage);
			pushRoute(routeName, {...routeParams, itemsPerPage});
		},
		[pushRoute, routeName, routeParams, setItemsPerPage],
	);

	const getItemById = useCallback(
		(id: string) => {
			const query = client.query;
			return query<ModelItemQuery, ModelItemQueryVariables>({
				query: ModelItemDocument,
				variables: {
					model,
					id,
					withRelations,
				},
				fetchPolicy: 'no-cache',
			}).then(result => result.data.modelItem.item);
		},
		[client.query, model],
	);

	const handleClose = useCallback((): void => {
		setEditableId(false);
		setDeletableId(undefined);
	}, [setEditableId, setDeletableId]);

	const handleCreate = useCallback(
		(item): void => {
			createModel({variables: {model, items: [item]}})
				.then(() => {
					handleRefetchModel();
					handleClose();
				})
				.catch(error => {
					notifyToastError(error);
				});
		},
		[createModel, model, handleRefetchModel, handleClose],
	);

	const handleUpdate = useCallback(
		(item, id): void => {
			updateModel({variables: {model, items: [{...item, id: id.toString()}]}})
				.then(() => {
					handleRefetchModel();
					handleClose();
				})
				.catch(error => {
					notifyToastError(error);
				});
		},
		[model, updateModel, handleRefetchModel, handleClose],
	);

	const handleUserWantsCreate = useCallback((): void => {
		setEditableId(true);
	}, [setEditableId]);

	const handleUserWantsUpdate = useCallback(id => setEditableId(id), [setEditableId]);
	const handleUserWantsDelete = useCallback(id => setDeletableId(id), [setDeletableId]);

	const handleDelete = useCallback(
		(id): void => {
			deleteModel({variables: {model, ids: [id.toString()]}})
				.then(handleRefetchModel)
				.catch(error => {
					notifyToastError(error);
				});
		},
		[deleteModel, model, handleRefetchModel],
	);

	const handleChange = useCallback(
		(item, id): void => {
			if (id) {
				handleUpdate(item, id);
			} else {
				handleCreate(item);
			}
		},
		[handleUpdate, handleCreate],
	);

	// NOTE: On First Load
	useEffect(() => {
		if (data?.modelItems.columns && !allColumns.length && !visibleColumns.length && !detailsColumns) {
			setAllColumns(data.modelItems.columns);

			const columns = data.modelItems.columns
				.filter(column => tableFields?.includes(column.id))
				.sort((column1, column2) => (column1.order || 0) - (column2.order || 0));
			setVisibleColumns(columns);

			const details = data.modelItems.columns.filter(column => detailsFields?.includes(column.id));
			setDetailsColumns(details);

			const defaultSortColumn = data?.modelItems.columns.find(column => column.defaultSort);
			if (defaultSortColumn) {
				const sortOrder = SortOrder.Desc;
				setSort(sortOrder);
				setSortBy(defaultSortColumn.id);
				pushRoute(routeName, {...routeParams, sort: sortOrder, sortBy: defaultSortColumn.id});
			}
		}
	}, [data, visibleColumns, detailsColumns, allColumns]);

	useDeepCompareEffect(() => {
		setPage(0);
	}, [variables]);

	useDeepCompareEffect(() => {
		if (data?.modelItems.meta) {
			setMeta(data.modelItems.meta);
		}
	}, [data?.modelItems.meta, meta]);

	const handleMassDelete = useCallback(() => {
		const ids = selectedItems.map(item => item.id);
		return deleteModel({variables: {model, ids}})
			.then(handleRefetchModel)
			.catch(error => {
				notifyToastError(error);
			});
	}, [selectedItems, deleteModel, model, handleRefetchModel]);

	const fullTableBag: FullTableBagType = {
		onChangeSort: handleChangeSort,
		onChangePage: handleChangePage,
		onChangeItemsPerPage: handleChangeItemsPerPage,
		onUserWantsCreate: handleUserWantsCreate,
		onUserWantsUpdate: handleUserWantsUpdate,
		onUserWantsDelete: handleUserWantsDelete,
		onDelete: handleDelete,
		onMassDelete: handleMassDelete,
		onChange: handleChange,
		onClose: handleClose,
		reload: handleRefetchModel,
		page,
		sort,
		sortBy,
		itemsPerPage,
		allColumns,
		visibleColumns,
		detailsColumns,
		meta,
		editableId,
		deletableId,
		getItemById,
		onSelect: setSelectedItems,
		selectedItems,
	};

	const queryBag: FullTableQueryBag = {
		data,
		loading,
		modelItemsError,
		submitFormLoading: updateModelLoading || createModelLoading,
	};

	const Table = FullTableComponent();
	// @ts-ignore
	Table.data = {
		model,
		queryBag,
		fullTableBag,
		...options,
	};

	return {
		Table,
		refresh: handleRefetchModel,
		columns: allColumns,
		selectedItems,
	};
}

function FullTableComponent() {
	const Table = useRef(() => {
		// @ts-ignore
		const data = Table.current.data;
		return <FullTableWithHook {...data} />;
	});

	return Table.current;
}

export default useFullTableWithRefetch;
