import React, {HTMLAttributes} from 'react';
import css from './Badge.module.css';
import cls from '../../utils/cls';

type BadgeProps = HTMLAttributes<HTMLSpanElement> & {
	badgeContent?: number | string;
	max?: number;
	color?: 'primary' | 'secondary';
};

const Badge: React.FC<BadgeProps> = ({children, badgeContent, color = 'primary', max, className, ...badgeProps}) => {
	const badgeClassnames = cls(className, css.badgeContent, color === 'secondary' ? css.secondary : css.primary);

	return (
		<span className={css.badgeContainer}>
			{children}
			<span {...badgeProps} className={badgeClassnames}>
				{typeof badgeContent === 'number' && max && badgeContent > max ? <>{max}+</> : badgeContent}
			</span>
		</span>
	);
};

export default Badge;
