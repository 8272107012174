import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import CommonProvider from './components/commonRedux/CommonProvider';
import Routes from './Routes';
import initApollo from './apollo/initApollo';
import ThemeProvider from './ThemeProvider';
import UploadFileProvider from './components/commonRedux/UploadFileStore/UploadFileQueueProvider';
import ResourceRegisterUploadFileProvider from './components/commonRedux/ResourceRegistryUploadFiles/ResourceRegistryUploadFilesProvider';
import {Toaster} from 'react-hot-toast';
import {ApolloProvider} from '@apollo/client';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import {UnsubmittedValuesProvider} from './components/unsubmittedValues/useUnsubmittedValues';

const apolloClient = initApollo(null);
ReactDOM.render(
	<ThemeProvider>
		<ErrorBoundary>
			<ApolloProvider client={apolloClient}>
				<CommonProvider>
					<UploadFileProvider>
						<UnsubmittedValuesProvider>
							<ResourceRegisterUploadFileProvider>
								<Routes />
								<Toaster
									reverseOrder={true}
									position={'top-right'}
									toastOptions={{style: {padding: 0}, icon: null}}
								/>
							</ResourceRegisterUploadFileProvider>
						</UnsubmittedValuesProvider>
					</UploadFileProvider>
				</CommonProvider>
			</ApolloProvider>
		</ErrorBoundary>
	</ThemeProvider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
