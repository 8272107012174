import React, {useCallback, useMemo} from 'react';
import css from './DataGrid.module.css';
import {arrayIntersect, removeArrayElementByIndex} from '../../utils/array-utils';
import cls from '../../utils/cls';
import DataGridRow, {TableRowOptions} from './DataGridRow';
import {DataGridAction} from '../settings/views/components/helper';

type TableRowGroupOptions = {
	groupFieldsIds: string[];
	groupId: string;
	checkColors?: {[key: string]: string};
	openGroups: string[];
	onToggle(ids: string[]);
};

type Props = {
	group: {items: Array<PlainObjectOf<any>>; renderedVisibleItems: Array<PlainObjectOf<React.ReactNode>>};
	rowOptions: TableRowOptions;
	options: TableRowGroupOptions;
	actionButtons?: DataGridAction[];
	index: number;
};

const DataGridRowGroup: React.FC<Props> = ({group, actionButtons, rowOptions, options, index: groupIndex}) => {
	const {groupFieldsIds, groupId, checkColors, openGroups, onToggle} = options;
	const {visibleFields, detailsFields, onSelect, selectable, selectedIds, onCellRender, onActionRender} = rowOptions;
	const itemIds = useMemo(() => group.items.map(item => item.id), [group.items]);

	const isSelected = useMemo(
		() => selectedIds && itemIds.length > 0 && arrayIntersect(itemIds, selectedIds).length === itemIds.length,
		[selectedIds, itemIds],
	);

	const handleSelect = useCallback(() => {
		if (onSelect) {
			onSelect([...itemIds]);
		}
	}, [itemIds, onSelect]);

	const groupItem = useMemo(() => {
		const firstItem = group.items[0];
		const groupItem = {
			id: groupId,
		};
		groupFieldsIds.forEach(id => {
			groupItem[id] = firstItem[id];
		});
		return groupItem;
	}, [groupFieldsIds, groupId, group]);

	const groupRenderedItem = useMemo(() => {
		const firstItem = group.renderedVisibleItems[0];
		const groupItem = {
			id: groupId,
		};
		groupFieldsIds.forEach(id => {
			groupItem[id] = firstItem[id];
		});
		return groupItem;
	}, [groupFieldsIds, groupId, group]);

	const handleToggle = useCallback(() => {
		const index = openGroups.indexOf(groupId);
		if (index !== -1) {
			onToggle(removeArrayElementByIndex(openGroups, index));
		} else {
			onToggle([...openGroups, groupId]);
		}
	}, [groupId, onToggle, openGroups]);

	const open = useMemo(() => openGroups.includes(groupId), [openGroups, groupId]);

	return (
		<>
			<div className={cls(css.groupWrapper, isSelected && css.selected)} role="presentation">
				<DataGridRow
					item={groupItem}
					renderedVisibleItem={groupRenderedItem}
					actionButtons={actionButtons}
					options={{
						selectable,
						selectedIds: isSelected ? [groupId] : [],
						onSelect: handleSelect,
						visibleFields,
						fakeDetailsButton: detailsFields && detailsFields?.length > 0,
						isGroupHeader: true,
						groupOpen: open,
						onGroupToggle: handleToggle,
						onCellRender,
						onActionRender,
					}}
					index={groupIndex}
				/>
				{open && (
					<div role="rowgroup" className={css.group}>
						{group.items.map((item, index) => (
							<DataGridRow
								key={index}
								item={item}
								actionButtons={actionButtons}
								renderedVisibleItem={group.renderedVisibleItems[index]}
								index={index + groupIndex + 1}
								options={{
									...rowOptions,
									checkColor: checkColors && item.id && checkColors[item.id],
									groupPart: true,
									onCellRender,
									onActionRender,
								}}
							/>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default DataGridRowGroup;
