import React from 'react';
import {TableRow, TableCell, TableBody} from '@material-ui/core';
import ResourceTableRow from './ResourceTableRow';
import css from './ResourceTableBody.module.css';
import {ResourceRegisterTableItem} from '../../queries-generated/types';

type Props = {
	columnsLength: number;
	onDelete?: (id: any) => void;
	keyword?: string;
	tableItems?: ResourceRegisterTableItem[] | any;
};

const ResourceTableBody: React.FC<Props> = ({tableItems, onDelete, columnsLength, keyword}) => {
	return (
		<TableBody>
			{!tableItems?.length && (
				<TableRow>
					<TableCell colSpan={columnsLength}>
						<h3 className={css.errorMessage}>Похоже, вы еще не добавили ни одной записи</h3>
					</TableCell>
				</TableRow>
			)}

			{tableItems &&
				tableItems.length > 0 &&
				tableItems.map((tableItem, index) => {
					return <ResourceTableRow keyword={keyword} onDelete={onDelete} key={index} tableItem={tableItem} />;
				})}
		</TableBody>
	);
};

export default ResourceTableBody;
