import React from 'react';
import {HelpFile} from '../../../queries-generated/types';
import css from './HelpFilesList.module.css';

type Props = {
	helpFiles: HelpFile[];
};

const HelpFileList: React.FC<Props> = ({helpFiles}) => {
	return (
		<ul className={css.wrapper}>
			{helpFiles.map(file => (
				<li key={file.id}>
					<a download target="_blank" href={`/services/helpFile/${file.id}`}>
						{file.title}
					</a>
				</li>
			))}
		</ul>
	);
};

export default HelpFileList;
