import React, {useCallback, useMemo, useState} from 'react';
import ModalPopup from '../../controlls/ModalPopup/ModalPopup';
import Button from '../Button/Button';
import {CheckIcon, CrossIcon} from '../../SvgIcon';
import DataGrid, {DataGridOptions} from '../../DataGrid/DataGrid';
import {onCellRenderWithDataSource} from '../../DataGrid/onCellRender';
import onGridActionRender from '../../DataGrid/onGridActionRender';
import useDataGridOptions from '../../settings/views/components/GridComponent/useDataGridOptions';
import Breadcrumbs, {Breadcrumb} from '../../Breadcrumbs/Breadcrumbs';
import DataGridHeadPanel from '../../DataGrid/DataGridHeadPanel';
import DataGridPagination from '../../DataGrid/DataGridPagination';
import ErrorSnippet from '../../ErrorSnippet/ErrorSnippet';
import useDataGridSelection, {DataGridTree} from '../../DataGrid/useDataGridSelection';
import {useGridQuery} from '../../../queries-generated/types';
import css from './ContactPicker.module.css';
import SelectedContactsCount from './SelectedContactsCount';
import useToggle from '../../hooks/useToggle';
import SelectedContacts from './SelectedContacts';

export const defaultNumberOfRows = 50;

type Props = {
	tree: DataGridTree;
	onChange: (directories: DataGridTree) => void;
	onClose: () => void;
	excludeDirectoryId?: string;
	directiveId?: string;
};

const contactsDataSource = 'dc35fac4-407a-4e83-b6e0-23ae27597c3b';
const contactsByDirectiveDataSource = '153dcb30-df53-4c65-870b-b879bc27daa5';
const rootBreadcrumb = {id: 'root', content: 'Начало'};

enum CHANGE_DIRECTORY_STATUS {
	IN_PROGRESS,
	SUCCESS,
}

const ContactPicker: React.FC<Props> = ({onClose, tree: treeProps, onChange, excludeDirectoryId, directiveId}) => {
	const [tree, setTree] = useState<DataGridTree>(treeProps);
	const [directoryId, setDirectoryId] = useState('root');
	const [changeDirectoryStatus, setChangeDirectoryStatus] = useState<CHANGE_DIRECTORY_STATUS>();
	const [path, setPath] = useState<Breadcrumb[]>([]);
	const [openSelected, toggleOpenSelected] = useToggle();

	const {
		setGridData,
		onChangePage,
		onChangeSort,
		onChangeItemsPerPage,
		total,
		firstDefaultSort,
		options,
		fields,
		page,
		sort,
		sortOrder,
		itemsPerPage,
		firstItemsPerPage,
	} = useDataGridOptions({
		defaultNumberOfRows,
	});
	const [searchQuery, setSearchQuery] = useState('');

	const filters: PlainObjectOf<any> = {directoryId, excludeDirectoryId};
	if (directiveId) filters.directiveId = directiveId;
	if (sort) filters.sort = `${sortOrder ? '+' : '-'}${sort}`;
	if (itemsPerPage && (itemsPerPage !== firstItemsPerPage || firstItemsPerPage === defaultNumberOfRows)) {
		filters.startIndex = page * itemsPerPage;
		filters.endIndex = page * itemsPerPage + itemsPerPage;
	}
	if (searchQuery) filters.searchQuery = searchQuery;

	const dataSourceId = directiveId ? contactsByDirectiveDataSource : contactsDataSource;

	const {data, loading, refetch, error} = useGridQuery({
		variables: {
			dataSourceId,
			filters,
		},
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		notifyOnNetworkStatusChange: true,
		onCompleted: result => {
			const dataSourceContentTable = result?.grid;
			if (dataSourceContentTable) {
				setChangeDirectoryStatus(CHANGE_DIRECTORY_STATUS.SUCCESS);
				setGridData({fields: dataSourceContentTable.fields, options: dataSourceContentTable.options});
			}
		},
	});

	const handleChangeDirectory = useCallback(
		(directoryId: string, name: string) => {
			setChangeDirectoryStatus(CHANGE_DIRECTORY_STATUS.IN_PROGRESS);
			setDirectoryId(directoryId);
			setPath([...path, {id: directoryId, content: name}]);
			onChangePage(0);
			setSearchQuery('');
		},
		[path, onChangePage],
	);

	const gridOptions: DataGridOptions = useMemo(() => {
		return {
			sort: sort || firstDefaultSort,
			onChangeSort,
			sortOrder,
			fields,
			selectable: true,
			selectedDirectories: tree,
			onSelectDirectories: setTree,
			onCellRender: onCellRenderWithDataSource(dataSourceId, fields, [], refetch, handleChangeDirectory),
			onActionRender: onGridActionRender(dataSourceId, fields || [], [], refetch),
			theme: 'neutral',
			// Не давать "выбрать все" если есть фильтр поиска, так как нет возможности обработать этот кейс
			fullSelectable: !searchQuery,
			total: loading ? 0 : total,
			directoryId: changeDirectoryStatus === CHANGE_DIRECTORY_STATUS.SUCCESS ? directoryId : undefined,
			path,
		};
	}, [
		sort,
		firstDefaultSort,
		path,
		loading,
		onChangeSort,
		options,
		tree,
		fields,
		total,
		directoryId,
		sortOrder,
		refetch,
		handleChangeDirectory,
		changeDirectoryStatus,
		searchQuery,
	]);

	const handleClickBreadCrumbs = useCallback(
		(item: Breadcrumb) => {
			setDirectoryId(item.id);
			const index = path.findIndex(pathItem => item.id === pathItem.id);
			setPath(path.slice(0, index + 1));
			onChangePage(0);
			setSearchQuery('');
		},
		[path, onChangePage],
	);

	const handleChange = useCallback(() => {
		onChange(tree);
	}, [tree, onChange]);

	const {selectedPageIds} = useDataGridSelection(data?.grid.items, gridOptions);

	return (
		<ModalPopup open={true} header={'Выбор контактов'} onClose={onClose}>
			{directiveId && openSelected && (
				<SelectedContacts
					tree={tree}
					setTree={setTree}
					directiveId={directiveId}
					onClose={toggleOpenSelected}
				/>
			)}
			<div className={css.tableWrapper}>
				{error ? (
					<ErrorSnippet error={error} errorHeader={'Ошибка загрузки данных для таблицы'} refetch={refetch} />
				) : (
					<>
						<div onClick={toggleOpenSelected} className={css.selectedContacts}>
							<SelectedContactsCount value={{tree}} />
						</div>
						<Breadcrumbs items={[rootBreadcrumb, ...path]} onClick={handleClickBreadCrumbs} />
						<DataGridHeadPanel
							reLoad={refetch}
							loading={loading}
							minLengthSearchQuery={options?.minLengthSearchQuery}
							searchable={options?.searchable}
							onChangeSearchQuery={setSearchQuery}
							selectedIds={selectedPageIds}
							onActionButtonRender={onGridActionRender(contactsDataSource, fields || [], [], refetch)}
						/>
						<DataGrid options={gridOptions} items={data?.grid.items} loading={loading} />
						<DataGridPagination
							onChangePage={onChangePage}
							onChangeItemsPerPage={onChangeItemsPerPage}
							itemsPerPage={itemsPerPage}
							page={page}
							total={total}
						/>
					</>
				)}
			</div>
			<div className="modalPopupButtons">
				<Button secondary onClick={onClose}>
					<CrossIcon />
					Отмена
				</Button>
				<Button onClick={handleChange}>
					<CheckIcon />
					Выбрать
				</Button>
			</div>
		</ModalPopup>
	);
};

export default ContactPicker;
