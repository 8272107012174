/* eslint-disable */
import {DocumentNode} from 'graphql';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};

import * as ApolloReactHooks from '@apollo/client';
import {useCallback, useEffect, useState} from 'react';
import {getApolloError} from '../utils/error';
import {useSessionPopup} from '../components/hooks/useUser';

function useLogout(apolloError) {
	const {openSessionPopup, setSessionPopup} = useSessionPopup();
	if (apolloError) {
		const error = getApolloError(apolloError);
		if (
			!openSessionPopup &&
			[
				'ERROR_INVALID_REFRESH_TOKEN',
				'ERROR_LOGIN_FAILED',
				'ERROR_INVALID_ACCESS_TOKEN',
				'ERROR_ACCESS_TOKEN_EXPIRED',
				'ERROR_LOGOUT_FAILED',
				'ERROR_REFRESH_FAILED',
				'ERROR_INVALID_EXTERNAL_TOKEN',
				'ERROR_EXTERNAL_TOKEN_LOGIN_FAILED',
			].includes(error.code)
		) {
			setSessionPopup(true);
		}
	}
}

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	DateTimeIso: string;
	JSON: any;
	Timestamp: any;
};

export type Action = {
	/** ID источника actions */
	id: Scalars['String'];
	/** Название для пользователя */
	title: Scalars['String'];
	description: Scalars['String'];
	/** Массив параметров */
	fields: Array<Field>;
};

export type Agent = {
	id: Scalars['Int'];
	type: Scalars['String'];
};

export enum Aggregation {
	FiveMinutes = 'FiveMinutes',
	Hour = 'Hour',
	Day = 'Day',
}

export type AnomaliesByFilterInput = {
	/** Дополнительный путь для url */
	additionalUrlPath?: Maybe<Scalars['String']>;
	/** Метрика (тип события): anomaly_type|agent_type|interface|as_from|router|action|as_to|agent|severity */
	eventType: EventType;
	/** Детализация: */
	periodDetail?: Maybe<PeriodDetail>;
	/** Ограничение числа возвращаемых значений по оси Х */
	limitX?: Maybe<Scalars['Int']>;
	/** Ограничение числа возвращаемых значений по оси У */
	limitY?: Maybe<Scalars['Int']>;
	/** Filter expression */
	filterTIN?: Maybe<Scalars['String']>;
	filterAsTo?: Maybe<Scalars['Int']>;
	filterAsFrom?: Maybe<Scalars['Int']>;
	filterAgentType?: Maybe<Scalars['Int']>;
	/** Для случаев когда нужен фильтр в обход составления фильтров по условиям */
	customFilter?: Maybe<Scalars['String']>;
};

export type AnomaliesBySourceInput = {
	/** Детализация: */
	detail: PeriodDetail;
	/** Ограничение числа возвращаемых значений по оси Х */
	limitX?: Maybe<Scalars['Int']>;
	/** Ограничение числа возвращаемых значений по оси У */
	limitY?: Maybe<Scalars['Int']>;
	/** Источник, если не указан, будет запрашиваться общее количество аномалий по всем источникам */
	source?: Maybe<AnomalySource>;
	filter?: Maybe<Scalars['String']>;
};

export type AnomaliesItem = {
	time: Scalars['String'];
	value: Scalars['Int'];
};

export type AnomaliesItems = {
	name: Scalars['String'];
	color?: Maybe<Scalars['String']>;
	data: Array<AnomaliesItem>;
};

export type AnomaliesQuantityInput = {
	/** Детализация: hour|minute|day */
	detail: PeriodDetail;
	/** Ограничение числа возвращаемых значений по оси Х */
	limitX?: Maybe<Scalars['Int']>;
};

export type AnomaliesResult = {
	series: Array<AnomaliesItems>;
};

export type AnomalyModelNames = {
	opened: Scalars['String'];
	closed: Scalars['String'];
};

export enum AnomalySource {
	Pasmon = 'pasmon',
	Ranr = 'ranr',
	Asbi = 'asbi',
	Nsdi = 'nsdi',
}

export type As = {
	/** Id */
	id: Scalars['Int'];
	/** Название */
	title: Scalars['String'];
	/** Страна */
	country: Scalars['String'];
	/** Регистратор (ripencc, apnic,...) */
	registry: Scalars['String'];
	/** Организация */
	organization: Scalars['String'];
	operatorAName?: Maybe<Scalars['String']>;
	operatorAInn?: Maybe<Scalars['String']>;
};

export type AsConnectionsInput = {
	/** Автономная система А */
	asAId: Scalars['Int'];
	/** Автономная система Б */
	asBIds: Array<Scalars['Int']>;
	hopCount?: Maybe<Scalars['Int']>;
	linkCount?: Maybe<Scalars['Int']>;
	/** Фильтры (короткие) */
	filters?: Maybe<FiltersShortWithoutDirection>;
};

export type AsCountByCountryResponse = {
	country: Scalars['String'];
	count: Scalars['Int'];
};

export type AsCountByRegistriesResponse = {
	registry: Scalars['String'];
	count: Scalars['Int'];
};

export type AsForGraph = {
	/** Первый АС */
	asFrom: As;
	/** Второй */
	asTo: As;
	/** Пропусканая способность */
	bandwidth?: Maybe<Scalars['String']>;
	/** Загрузка на входе */
	inputUtilization?: Maybe<Scalars['String']>;
	/** Загрузка на выходе */
	outputUtilization?: Maybe<Scalars['String']>;
	/** Загрузка на входе в процентах от пропускной способности */
	inputUtilizationPercentage?: Maybe<Scalars['Float']>;
	/** Загрузка на выходе в процентах от пропускной способности */
	outputUtilizationPercentage?: Maybe<Scalars['Float']>;
	startAt?: Maybe<Scalars['DateTimeIso']>;
	endAt?: Maybe<Scalars['DateTimeIso']>;
};

export type AsLinksAbroadCountInput = {
	/** Страна */
	country: Scalars['String'];
	/** Filters */
	filters?: Maybe<FiltersShort>;
	/** Ограничение числа возвращаемых значений */
	limit?: Maybe<Scalars['Int']>;
	/** Тип выборки (top, bottom) */
	order?: Maybe<Order>;
};

export type AsLinksAbroadInput = {
	/** Номеров АС */
	ids: Array<Scalars['Int']>;
	/** Фильтры (короткие) */
	filters?: Maybe<FiltersShortWithoutDirection>;
	hopCount?: Maybe<Scalars['Int']>;
	linkCount?: Maybe<Scalars['Int']>;
};

export type AsLinksCount = {
	key: Scalars['String'];
	as: As;
	count: Scalars['Int'];
};

export type AsLinksCountFull = {
	key: Scalars['String'];
	asA?: Maybe<As>;
	asB?: Maybe<As>;
	countryA?: Maybe<Scalars['String']>;
	countryB?: Maybe<Scalars['String']>;
	registryA?: Maybe<Scalars['String']>;
	registryB?: Maybe<Scalars['String']>;
	count: Scalars['Int'];
};

export type AsLinksCountFullInput = {
	/** Filters */
	filters?: Maybe<FiltersFull>;
	/** Поля для группировки */
	groupBy: Array<GroupBy>;
	/** Ограничение числа возвращаемых значений */
	limit?: Maybe<Scalars['Int']>;
	/** Тип выборки (top, bottom) */
	order?: Maybe<Order>;
};

export type AsLinksCountFullResponse = {
	items: Array<AsLinksCountFull>;
	other: Scalars['Int'];
	total: Scalars['Int'];
};

export type AsLinksCountInput = {
	/** Filters */
	filters?: Maybe<FiltersFull>;
	/** Ограничение числа возвращаемых значений */
	limit?: Maybe<Scalars['Int']>;
	/** Тип выборки (top, bottom) */
	order?: Maybe<Order>;
};

export type AsLinksCountResponse = {
	items: Array<AsLinksCount>;
	other: Scalars['Int'];
	total: Scalars['Int'];
};

export type AsLinksInput = {
	/** Массив номеров АС */
	ids: Array<Scalars['Int']>;
	/** Глубина */
	hops: Scalars['Int'];
	/** Фильтры (короткие) */
	filters?: Maybe<FiltersShortWithoutDirection>;
};

export type AsNetworkHistoryInput = {
	/** Номера АС */
	ids: Array<Scalars['Int']>;
	/** Страны */
	countries: Array<Scalars['String']>;
	/** Регистраторы */
	registries: Array<Scalars['String']>;
	/** Длительность в секундах (если 0, то смотреть startAt и endAt) */
	duration: Scalars['Int'];
	/** Дата начала */
	startAt?: Maybe<Scalars['DateTimeIso']>;
	/** Дата конца */
	endAt?: Maybe<Scalars['DateTimeIso']>;
};

export type AsNetworkInput = {
	/** Номера АС */
	ids: Array<Scalars['Int']>;
	/** Страны */
	countries: Array<Scalars['String']>;
	/** Регистраторы */
	registries: Array<Scalars['String']>;
	filters?: Maybe<FiltersShortWithoutDirection>;
};

export type AsTrafficByTimeInput = {
	/** Поля для группировки. Возможные значения: asA, asB, ipA, ipB, portA, portB, proto, direction. Пример: asA,asB,portA */
	groupBy: Array<GroupBy>;
	/** Фильтры Netflow */
	filters?: Maybe<AsTrafficFilters>;
	/** Выбранная метрика (packets, bytes, flows) */
	metric?: Maybe<Metric>;
	/** Дата начала */
	dateFrom: Scalars['DateTimeIso'];
	/** Дата окончания */
	dateTo: Scalars['DateTimeIso'];
	/**
	 * Значение периода агрегации трафика Netflow в секундах.
	 * При выборе периода (dateFrom-dateTo) в 30 дней и значении aggregation, приведённой к секундам в 24*60*60 (1 день) будет
	 * 30 временных интервалов по оси абсцисс (точек на диаграмме Netflow)
	 */
	aggregation?: Maybe<Aggregation>;
	/**
	 * Ограничение числа возвращаемых комбинаций (количества графиков в интерфейсе). Числом комбинацией будут сочетания
	 * возвращаемых данных в зависимости от запрошенной группировки. Например, все уникальные сочетания asA:asB:proto -
	 * трафик от asA к asB по протоколу proto
	 */
	combinationsLimit?: Maybe<Scalars['Int']>;
	/** Фильтр по оператору */
	operatorInn?: Maybe<Scalars['String']>;
};

export type AsTrafficByTimeItem = {
	/** Трафик в пакетах */
	packets?: Maybe<Scalars['String']>;
	/** Трафик в байтах */
	bytes?: Maybe<Scalars['String']>;
	/** Трафик в потоках */
	flows?: Maybe<Scalars['String']>;
	/** Номер АС */
	asAId?: Maybe<Scalars['String']>;
	/** Номер АС */
	asBId?: Maybe<Scalars['String']>;
	/** Отметка времени */
	time?: Maybe<Scalars['Int']>;
	/** Протокол */
	protocol?: Maybe<Scalars['String']>;
	/** Ip */
	ipA?: Maybe<Scalars['String']>;
	/** Ip */
	ipB?: Maybe<Scalars['String']>;
	/** Порт */
	portA?: Maybe<Scalars['String']>;
	/** Порт */
	portB?: Maybe<Scalars['String']>;
	/** Направление */
	directions: Array<Direction>;
};

export type AsTrafficFilters = {
	asAId?: Maybe<Array<Scalars['Int']>>;
	asBId?: Maybe<Array<Scalars['Int']>>;
	ipA?: Maybe<Array<Scalars['String']>>;
	ipB?: Maybe<Array<Scalars['String']>>;
	portACode?: Maybe<Array<Scalars['String']>>;
	portBCode?: Maybe<Array<Scalars['String']>>;
	protocolCode?: Maybe<Array<Scalars['String']>>;
	directions?: Maybe<Array<Direction>>;
};

export type AsTrafficInput = {
	/** Filters */
	filters?: Maybe<AsTrafficFilters>;
	/** Поля для группировки */
	groupBy?: Maybe<Array<GroupBy>>;
	/** Ограничение числа возвращаемых значений */
	limit?: Maybe<Scalars['Int']>;
	/** Тип выборки (top, bottom) */
	order?: Maybe<Order>;
	/** Единицы измерения */
	metric?: Maybe<Metric>;
	/** Дата начала */
	dateFrom: Scalars['DateTimeIso'];
	/** Дата окончания */
	dateTo: Scalars['DateTimeIso'];
};

export type AsTrafficItem = {
	key: Scalars['String'];
	/** Трафик в пакетах */
	packets?: Maybe<Scalars['String']>;
	/** Трафик в байтах */
	bytes?: Maybe<Scalars['String']>;
	/** Трафик в потоках */
	flows?: Maybe<Scalars['String']>;
	/** Номер АС */
	asAId?: Maybe<Scalars['String']>;
	/** Название АС */
	asAName?: Maybe<Scalars['String']>;
	/** Номер и название АС */
	asAIdName?: Maybe<Scalars['String']>;
	/** Номер АС */
	asBId?: Maybe<Scalars['String']>;
	/** Название АС */
	asBName?: Maybe<Scalars['String']>;
	/** Номер и название АС */
	asBIdName?: Maybe<Scalars['String']>;
	/** Протокол */
	protocol?: Maybe<Scalars['String']>;
	/** Ip */
	ipA?: Maybe<Scalars['String']>;
	/** Ip */
	ipB?: Maybe<Scalars['String']>;
	/** Порт */
	portA?: Maybe<Scalars['String']>;
	/** Порт */
	portB?: Maybe<Scalars['String']>;
	/** Направление */
	directions: Array<Direction>;
	/** Название оператора */
	operatorAName?: Maybe<Scalars['String']>;
	/** Название оператора */
	operatorBName?: Maybe<Scalars['String']>;
	/** ИНН оператора */
	operatorAInn?: Maybe<Scalars['String']>;
	/** ИНН оператора */
	operatorBInn?: Maybe<Scalars['String']>;
	/** Id оператора */
	operatorAId?: Maybe<Scalars['String']>;
};

export type AsTrafficResult = {
	items: Array<AsTrafficItem>;
	other: Scalars['String'];
	total: Scalars['String'];
};

export type AsTrafficSankeyInput = {
	/** номер АС, относительно которой всё строится ("центральная АС") */
	asId: Scalars['Int'];
	/** Выбранная метрика (packets, bytes, flows) */
	metric: Metric;
	/** Фильтр по направлению. Пример: 0 - только входящие, 1 - только исходящие, 2 - в обе стороны */
	directions: Array<Direction>;
	/** Количество уровней (хопов) для исходящих связей */
	forwardHops: Scalars['Int'];
	/** Количество уровней (хопов) для входящих связей */
	backwardHops: Scalars['Int'];
	/** Поля группировки. Строки, разделенные запятыми: as,port,ip,proto */
	groupBy: Scalars['String'];
	/** макисмальное количество взаимодействующих АС между собой */
	limitOneHop: Scalars['Int'];
	/** максимальное количество всех АС (получается как сумма всех взаимодействующих АС между собой) */
	limitAllHops: Scalars['Int'];
	/** Дата и время начала отчетного периода в формате timestamp (секунды) */
	startDate?: Maybe<Scalars['Int']>;
	/** Дата и время окончания отчетного периода в формате timestamp (секунды) */
	endDate?: Maybe<Scalars['Int']>;
};

export type AsTrafficSankeyLinkItem = {
	source: Scalars['Int'];
	target: Scalars['Int'];
	value: Scalars['Float'];
};

export type AsTrafficSankeyNodeItem = {
	name: Scalars['String'];
};

export type AsTrafficSankeyResult = {
	nodes: Array<AsTrafficSankeyNodeItem>;
	links: Array<AsTrafficSankeyLinkItem>;
};

export type AttachmentsInput = {
	resourceId: Scalars['Int'];
	from?: Maybe<Scalars['DateTimeIso']>;
	to?: Maybe<Scalars['DateTimeIso']>;
	page?: Maybe<Scalars['Int']>;
	itemsPerPage?: Maybe<Scalars['Int']>;
	keyword: Scalars['String'];
};

export type AvailableColumn = {
	id: Scalars['String'];
	title: Scalars['String'];
	order: Scalars['Int'];
	visible: Scalars['Boolean'];
	canVisible: Scalars['Boolean'];
	details?: Maybe<Scalars['Boolean']>;
	canSort?: Maybe<Scalars['Boolean']>;
	defaultSort?: Maybe<Scalars['Boolean']>;
};

export type CommonFiltersPreset = {
	id: Scalars['String'];
	dashboardId: Scalars['String'];
	version: Scalars['Int'];
	title: Scalars['String'];
	filters: Scalars['JSON'];
};

export type CommonFiltersPresetCreateInput = {
	dashboardId: Scalars['String'];
	version: Scalars['Int'];
	title: Scalars['String'];
	filters: Scalars['JSON'];
};

export type CommonFiltersPresetListInput = {
	dashboardId: Scalars['String'];
	version: Scalars['Int'];
};

export type CommonFiltersPresetUpdateInput = {
	id: Scalars['String'];
	filters: Scalars['JSON'];
};

export type ConfigurationGroup = {
	key: Scalars['String'];
	value: Scalars['String'];
};

export type ContextHelpResponse = {
	id: Scalars['String'];
	key: Scalars['String'];
	value: Scalars['String'];
};

export type ConversationFile = {
	id: Scalars['String'];
	title: Scalars['String'];
	/** @deprecated Field no longer supported */
	size: Scalars['Int'];
	path: Scalars['String'];
};

export type ConversationMessage = {
	id: Scalars['String'];
	text: Scalars['String'];
	authorName: Scalars['String'];
	createdAt: Scalars['DateTimeIso'];
	isService?: Maybe<Scalars['Boolean']>;
	files: Array<ConversationFile>;
};

export type DataSource = {
	/** ID источника данных */
	id: Scalars['String'];
	/** Название для пользователя */
	title: Scalars['String'];
	description: Scalars['String'];
	/** Массив параметров */
	fields: Array<Field>;
	availableColumns?: Maybe<Array<AvailableColumn>>;
};

export type DataSourceNotification = {
	color: Scalars['Int'];
	title: Scalars['String'];
	content?: Maybe<Scalars['String']>;
};

export type DeleteBatchInput = {
	sectionId: Scalars['String'];
};

export type Dependence = {
	fieldId: Scalars['String'];
	valueType?: Maybe<DependenceValueType>;
	values?: Maybe<Array<Scalars['JSON']>>;
	/**
	 * Структура с маппингом id ModelPossibleValue главного поля на id ModelPossibleValue подчиненного.
	 * Пример: {"masterPossibleValueId1": ["slavePossibleValueId1", "slavePossibleValueId2"], "masterPossibleValueId2": ["slavePossibleValueId1", "slavePossibleValueId3"]}
	 * Нужно только в случае поведения Filter и когда подчиненное поле имеет possibleValues (не имея, соответственно, possibleValuesSourceModel)
	 */
	mapFilterValues?: Maybe<Scalars['JSON']>;
	/**
	 * Подсказка поля. Выводится под полем, если сработало условие.
	 * Предполагается, что будет использоваться, если это ModelMasterDependencies. Тогда выведется у всех подчиненных полей
	 */
	helperText?: Maybe<Scalars['String']>;
};

export enum DependenceBehaviour {
	/** Подчиненное поле будет "выключено" */
	Disable = 'Disable',
	/** Подчиненное поле будет "включено" */
	Enable = 'Enable',
	/** При выполнении условий подчиненное поле будет обязательным */
	Required = 'Required',
	/**
	 * При выполнении условий значения в подчиненном поле будут фильтроваться (для Combobox, Select)
	 * Для такого поведения slave всегда будут очищаться, при смене значений мастера
	 */
	Filter = 'Filter',
}

export enum DependenceValueType {
	/** Массив любых значений (логика ИЛИ) */
	CustomOr = 'CustomOr',
	/** Массив любых значений (логика И) */
	CustomAnd = 'CustomAnd',
	/** Значение не выбрано */
	Nullable = 'Nullable',
	/** Выбрано что угодно (не пустое) */
	Filled = 'Filled',
}

export type DependencyFilter = {
	fieldId: Scalars['String'];
	values: Array<Scalars['String']>;
	refType?: Maybe<Scalars['String']>;
};

export type Diagram = {
	series: Array<DiagramSeries>;
	notifications?: Maybe<Array<DataSourceNotification>>;
};

export type DiagramPoint = {
	y: Scalars['Float'];
	x?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
};

export type DiagramSeries = {
	id?: Maybe<Scalars['String']>;
	points: Array<DiagramPoint>;
	name?: Maybe<Scalars['String']>;
	startPoint?: Maybe<Scalars['Float']>;
	pointInterval?: Maybe<Scalars['Float']>;
	baseLine?: Maybe<Scalars['String']>;
	yMeasure?: Maybe<SeriesMeasure>;
	groupName?: Maybe<Scalars['String']>;
	mergeId?: Maybe<Scalars['String']>;
};

export type DictionaryItem = {
	key: Scalars['String'];
	value: Scalars['String'];
};

export enum DictionaryType {
	/** справочник типов аномалий */
	Anomaly = 'anomaly',
	/** справочник типов агентов */
	Agent = 'agent',
	/** справочник уровней критичности */
	Severity = 'severity',
	/** справочник типов действий */
	Action = 'action',
}

export enum Direction {
	Input = 'Input',
	Output = 'Output',
}

export enum EventType {
	AnomalyType = 'anomaly_type',
	AgentType = 'agent_type',
	Interface = 'interface',
	AsFrom = 'as_from',
	Router = 'router',
	Action = 'action',
	AsTo = 'as_to',
	Agent = 'agent',
	Severity = 'severity',
	OperatorInn = 'operator_inn',
}

export type Field = {
	id: Scalars['String'];
	/** Название поля для пользователя */
	title: Scalars['String'];
	/** Описание поля (будет выведено под полем при редактировании) */
	description?: Maybe<Scalars['String']>;
	/** Тип */
	type: FieldType;
	/** Тип отображения */
	showType?: Maybe<FieldShownType>;
	/** Тип контрола */
	controlType?: Maybe<FieldControlType>;
	/** Массив. Для типов вроде [String], [Number]. */
	isArray?: Maybe<Scalars['Boolean']>;
	/** Список доступных значений для редактирования некоторых типов полей */
	possibleValues?: Maybe<Array<PossibleValue>>;
	/** Название рефа для ручки, которая возвращает [ModelPossibleValue!] для текста для определенных типов полей */
	possibleValuesRef?: Maybe<Scalars['String']>;
	/** Порядок столбца внутри таблицы */
	order?: Maybe<Scalars['Int']>;
	/** Показывать ли столбец в таблице. По умолчанию true */
	visible?: Maybe<Scalars['Boolean']>;
	/** Показывать ли столбец в деталировке. По умолчанию false */
	detail?: Maybe<Scalars['Boolean']>;
	/** Сортировка по полю по умолчанию. Только у одного поля этот параметр может быть true. По умолчанию false */
	defaultSort?: Maybe<Scalars['Boolean']>;
	/** Возможна ли сортировка (это значит, что клиент имеет право делать дополнительные запросы, указывая это поле в сортировке). По умолчанию false */
	canSort?: Maybe<Scalars['Boolean']>;
	/** Обязательное поле (при редактировании). По умолчанию false */
	required?: Maybe<Scalars['Boolean']>;
	/** Недоступно для изменения (будет отображено в форме, но заблокировано). По умолчанию false */
	disabled?: Maybe<Scalars['Boolean']>;
	/** Валидация для формы */
	validation?: Maybe<FieldValidation>;
	/** Минимальное значение для контролов типа Number, Date и Range (для Range - обязательное) */
	min?: Maybe<Scalars['JSON']>;
	/** Максимальное значение для контролов типа Number, Date и Range (для Range - обязательное) */
	max?: Maybe<Scalars['JSON']>;
	/**
	 * Шаг изменения.
	 * Для Number позволяет указать шаг (позволяет менять тип. Например при 1 числа будут натуральные, при 0.1 - с одним знаком после запятой. Кроме того будет "мягкая" валидация с предупреждением, если число не кратно шагу. При скроле или нажатии кнопок спинера число будет меняться с этим шагом). По умолчанию - null, можно вводить любое число
	 * Для range используется, как шаг переключения значения. По умолчанию - 1.
	 */
	step?: Maybe<Scalars['Float']>;
	/** Значение по умолчанию для формы. Ожидается тип из type. */
	defaultValue?: Maybe<Scalars['JSON']>;
	/** Ссылка. делает поле ссылкой в таблице */
	linkOptions?: Maybe<LinkOptions>;
	/** Связь */
	relation?: Maybe<Relation>;
	/** Зависимости полей, если это поле подчиненное */
	slaveDependencies?: Maybe<Array<SlaveDependencies>>;
	/** Зависимости полей, если это поле главное */
	masterDependencies?: Maybe<Array<MasterDependencies>>;
	/** Название группы (человекопонятное, будет выведено в качестве заголовка) */
	groupTitle?: Maybe<Scalars['String']>;
	/** Вложенные колонки, если идет выборка со связями */
	fields?: Maybe<Array<Field>>;
	/** Минимальная введеная длина символов для инициализации запроса в некоторых компонентах(например в комбобоксе) */
	minLengthQuery?: Maybe<Scalars['Int']>;
	/** Поле таблицы, по которому выполняется группировка */
	isGroupField?: Maybe<Scalars['Boolean']>;
	/** Минимальная ширина столбца */
	minWidth?: Maybe<Scalars['Int']>;
	/** Максимальная ширина столбца */
	maxWidth?: Maybe<Scalars['Int']>;
	/** Фиксированная ширина ячейки */
	width?: Maybe<Scalars['Int']>;
	/** Главное поле */
	isGeneral?: Maybe<Scalars['Boolean']>;
	resizable?: Maybe<Scalars['Boolean']>;
	autoHeightAdjustable?: Maybe<Scalars['Boolean']>;
};

export enum FieldControlType {
	/** Любое число. Поддерживает min, max, step */
	Number = 'Number',
	/** Строка */
	String = 'String',
	/** Строка, но при вводе поле с дополнительными строками */
	TextArea = 'TextArea',
	/** Чекбокс */
	CheckBox = 'CheckBox',
	/** Дата и время */
	DateTime = 'DateTime',
	/** Дата */
	Date = 'Date',
	/** Булеан, checkbox */
	Boolean = 'Boolean',
	/** Строка, JSON с предвалидацией на клиенте */
	Json = 'JSON',
	/** Строка, Ip адрес или маска с предвалидацией */
	Subnet = 'Subnet',
	/** Строка, поле с автодополнением, требует possibleValuesSourceModel или possibleValues */
	AutoComplete = 'AutoComplete',
	/** ModelPossibleValue, поле без поиска с выпадающим списком, требует possibleValues */
	Select = 'Select',
	/** ModelPossibleValue, выглядит, как набор радио кнопок, требует possibleValues */
	RadioButtons = 'RadioButtons',
	/** ModelPossibleValue, поле с поиском и выпадающим списком, требует possibleValuesSourceModel или possibleValues */
	Combobox = 'Combobox',
	/** Строка, пароль */
	Password = 'Password',
	/** Число, телефон */
	Phone = 'Phone',
	/** Число, слайдер, требует min и max. Поддерживает step */
	Slider = 'Slider',
	/** Интервал дат с указанием периода, ожидается Structure {from: DateTimeIso, to: DateTimeIso, duration: DurationIso} */
	DateRangeDuration = 'DateRangeDuration',
	/** Строка с адресом почты */
	Email = 'Email',
	/** Файл */
	File = 'File',
	/** Селектор "Не выбрано/Да/Нет" */
	NullableBoolean = 'NullableBoolean',
	/** Пикер с контактами. */
	Contacts = 'Contacts',
	/** Пикер с контактами. Всегда ходит в одну ручку */
	ContactsStateLess = 'ContactsStateLess',
}

export enum FieldShownType {
	/** Любое число */
	Number = 'Number',
	/** Строка */
	String = 'String',
	/** Дата и время в формате 01 января 2021 года, 18:36 */
	DateTimeLong = 'DateTimeLong',
	/** Дата в формате 01 января 2021 года */
	DateLong = 'DateLong',
	/** Булеан в формате Да/Нет */
	YesNo = 'YesNo',
	/** Отображается как кнопка тогл, которая сразу дает возможность поменять статус */
	Toggle = 'Toggle',
	/** Число, будет отформатировано, как скорость */
	BitsPerSeconds = 'BitsPerSeconds',
	/** Число, будет отформатировано, как размер в байтах */
	Bytes = 'Bytes',
	/** Число, будет отформатировано, как размер в битах */
	Bits = 'Bits',
	/**
	 * Структура в формате {value: Int!, title: String!}, будет отображена, как ячейка, закрашенная цветом {value}, в которой будет написано {title}
	 * TODO: нужно более подходящее, гибкое название
	 */
	Severity = 'Severity',
	/** Структура в формате {value: Int!, title: String!}, будет отображена, как круг с цветом {value} после которой будет написано {title} */
	Status = 'Status',
	/** Структура в формате {title: String!, url: String, route: String, params: JSON, target: String} позволяющая использовать произвольную ссылку */
	Link = 'Link',
	/** Число телефон, без плюса. Отобразится в формате +7 (123) 456-78-90 */
	Phone = 'Phone',
	/** Интервал дат с указанием периода, ожидается Structure {from: DateTimeIso, to: DateTimeIso, duration: DurationIso} */
	DateRangeDuration = 'DateRangeDuration',
	/** Строка с адресом почты, будет отображена как ссылка mailto */
	Email = 'Email',
	/** Diff */
	Diff = 'Diff',
	/** Строка с названием иконки. Будет отображено как иконка. Field для такого типа будет отображаться иначе. Не будет учитываться (отображаться) сортировка для поля и его название. Будет установлена ширина */
	Icon = 'Icon',
	/** Файл */
	File = 'File',
	Conversations = 'Conversations',
	Contacts = 'Contacts',
}

export enum FieldType {
	/** Не будет выводиться, пока для relations не укажут useAsControl и possibleValueMapper */
	Relation = 'Relation',
	/** Формат Number, контрол Number */
	Int = 'Int',
	/** Формат Number, контрол Number */
	Float = 'Float',
	/** Формат String, контрол String */
	String = 'String',
	/** Формат DateTimeLong, контрол DateTime */
	DateTime = 'DateTime',
	/** Формат DateLong, контрол Date */
	Date = 'Date',
	/** Формат YeaNo, контрол Checkbox */
	Boolean = 'Boolean',
	/** Особое значение с произвольным JSON, для которого обязательно нужно указать тип форматирования и тип контрола */
	Structure = 'Structure',
	/** Особое значение, использующееся для набора радиокнопок, автокомплитов, комбобоксов и селектов. Имеет строгий формат ModelPossibleValue */
	ModelPossibleValue = 'ModelPossibleValue',
}

export type FieldValidation = {
	/** Регулярное выражение для валидации. В случае массива валидирует 1 элемент. Если не указано, будет использован тип колонки */
	regExp?: Maybe<Scalars['String']>;
	/** Текст ошибки валидации */
	errorText?: Maybe<Scalars['String']>;
	maxFileSize?: Maybe<Scalars['Int']>;
	maxFilesSize?: Maybe<Scalars['Int']>;
	allowedExtensions?: Maybe<Array<Scalars['String']>>;
};

export type FileInput = {
	/** Название файла */
	name: Scalars['String'];
	/** id секции */
	sectionId: Scalars['String'];
	/** Размер файла (бывает очень боьшой) */
	size: Scalars['String'];
	/** Количество чанков (бывает много) */
	numberOfChunks: Scalars['String'];
};

export type FileListInput = {
	sectionId: Scalars['String'];
	orgINN?: Maybe<Scalars['String']>;
};

export type FileSection = {
	id: Scalars['String'];
	title: Scalars['String'];
	description: Scalars['String'];
	enabled: Scalars['Boolean'];
};

export type FileStatusInput = {
	sectionId: Scalars['String'];
};

export type FileStatusResponce = {
	id?: Maybe<Scalars['String']>;
	fileName?: Maybe<Scalars['String']>;
	size?: Maybe<Scalars['Int']>;
	createdAt?: Maybe<Scalars['DateTimeIso']>;
	uploadedAt?: Maybe<Scalars['DateTimeIso']>;
	status: Scalars['String'];
};

export type FileValue = {
	size: Scalars['Int'];
	location: Scalars['String'];
	id: Scalars['String'];
	title: Scalars['String'];
};

export type FiltersFull = {
	/** Фильтр по номерам АС (АС A), которые должны быть включены в результат. Пример: 123456,789 */
	asAInclude?: Maybe<Array<Scalars['Int']>>;
	/** Фильтр по номерам АС (АС A), которые должны быть исключены из результата. Пример: 123456,789 */
	asAExclude?: Maybe<Array<Scalars['Int']>>;
	/** Фильтр по номерам АС (АС B), которые должны быть включены в результат. Пример: 123456,789 */
	asBInclude?: Maybe<Array<Scalars['Int']>>;
	/** Фильтр по номерам АС (АС B), которые должны быть исключены из результата. Пример: 123456,789 */
	asBExclude?: Maybe<Array<Scalars['Int']>>;
	/** Фильтр по странам (страна A), которые должны быть включены в результат. Пример: ru,us */
	countryAInclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по странам (страна A), которые должны быть исключены из результата. Пример: ru,us */
	countryAExclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по странам (страна B), которые должны быть включены результат. Пример: ru,us */
	countryBInclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по странам (страна B), которые должны быть исключены из результата. Пример: ru,us */
	countryBExclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по регистраторам(A), которые должны быть включены в результат. Пример: ripencc,apnic */
	registryAInclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по регистраторам(A), которые должны быть исключены из результата. Пример: ripencc,apnic */
	registryAExclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по регистраторам (Б), которые должны быть включены в результат. Пример: ripencc,apnic */
	registryBInclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по регистраторам (Б), которые должны быть исключены из результата. Пример: ripencc,apnic */
	registryBExclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по направлению, которое должно быть включено в результат. Пример: 0 - только входящие, 1 - только исходящие, 2 - в обе стороны */
	directionInclude?: Maybe<Array<Direction>>;
};

export type FiltersShort = {
	/** Фильтр по номерам АС, которые должны быть включены в результат. Пример: 123456,789 */
	asInclude?: Maybe<Array<Scalars['Int']>>;
	/** Фильтр по номерам АС, которые должны быть исключены из результата. Пример: 123456,789 */
	asExclude?: Maybe<Array<Scalars['Int']>>;
	/** Фильтр по странам, которые должны быть включены в результат. Пример: ru,us */
	countryInclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по странам, которые должны быть исключены из результата. Пример: ru,us */
	countryExclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по регистраторам, которые должны быть включены в результат. Пример: ripencc,apnic */
	registryInclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по регистраторам, которые должны быть исключены из результата. Пример: ripencc,apnic */
	registryExclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по направлению, которое должно быть включено в результат. Пример: 0 - только входящие, 1 - только исходящие, 2 - в обе стороны */
	directionInclude?: Maybe<Array<Direction>>;
};

export type FiltersShortWithoutDirection = {
	/** Фильтр по номерам АС, которые должны быть включены в результат. Пример: 123456,789 */
	asInclude?: Maybe<Array<Scalars['Int']>>;
	/** Фильтр по номерам АС, которые должны быть исключены из результата. Пример: 123456,789 */
	asExclude?: Maybe<Array<Scalars['Int']>>;
	/** Фильтр по странам, которые должны быть включены в результат. Пример: ru,us */
	countryInclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по странам, которые должны быть исключены из результата. Пример: ru,us */
	countryExclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по регистраторам, которые должны быть включены в результат. Пример: ripencc,apnic */
	registryInclude?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по регистраторам, которые должны быть исключены из результата. Пример: ripencc,apnic */
	registryExclude?: Maybe<Array<Scalars['String']>>;
	nextHops?: Maybe<Scalars['Int']>;
	firstHop?: Maybe<Scalars['Int']>;
	operatorInn?: Maybe<Scalars['String']>;
	bgpAgents?: Maybe<Array<Scalars['String']>>;
	/** Длительность в секундах (если 0, то смотреть startAt и endAt) */
	duration?: Maybe<Scalars['Int']>;
	/** Дата начала */
	startAt?: Maybe<Scalars['DateTimeIso']>;
	/** Дата конца */
	endAt?: Maybe<Scalars['DateTimeIso']>;
};

export type FindQuery = {
	fields: Array<Field>;
	item: Scalars['JSON'];
	options: Scalars['JSON'];
	notifications?: Maybe<Array<DataSourceNotification>>;
};

export type Flowcollector = {
	id: Scalars['String'];
	org_inn: Scalars['String'];
	name: Scalars['String'];
	ip_address: Scalars['String'];
};

export type Flowoperator = {
	id: Scalars['String'];
	org_inn: Scalars['String'];
	org_short_name: Scalars['String'];
};

export type FlowoperatorInput = {
	/** Поиск по нескольким атрибутам */
	query?: Maybe<Scalars['String']>;
};

export type FlowoperatorItem = {
	/** ИД оператора */
	id: Scalars['String'];
	/** ИНН оргагизации */
	orgInn: Scalars['String'];
	/** Сокращённое название организации */
	orgShortName: Scalars['String'];
};

export type Graph = {
	nodes: Array<GraphNode>;
	edges: Array<GraphEdge>;
	notifications?: Maybe<Array<DataSourceNotification>>;
	options: GraphOptions;
};

export type GraphEdge = {
	id: Scalars['String'];
	from: Scalars['String'];
	to: Scalars['String'];
	label?: Maybe<Scalars['String']>;
	layers?: Maybe<Array<Scalars['String']>>;
	ranges?: Maybe<Array<GraphRange>>;
	options?: Maybe<GraphEdgeOptions>;
};

export type GraphEdgeOptions = {
	arrows?: Maybe<Scalars['JSON']>;
	color?: Maybe<Scalars['Int']>;
	line?: Maybe<Scalars['String']>;
	thickness?: Maybe<Scalars['Int']>;
	popoverContent?: Maybe<Scalars['JSON']>;
};

export type GraphGrid = {
	nodes: Array<GraphNode>;
	edges: Array<GraphEdge>;
	notifications?: Maybe<Array<DataSourceNotification>>;
	options: GraphGridOptions;
	fields: Array<Field>;
	tableItems: Array<Scalars['JSON']>;
};

export type GraphGridOptions = {
	total: Scalars['Int'];
	defaultNumItems?: Maybe<Scalars['Int']>;
	collapsedGroups?: Maybe<Array<Scalars['String']>>;
	actions?: Maybe<Array<GridAction>>;
	initDate?: Maybe<Scalars['DateTimeIso']>;
	hasImportant?: Maybe<Scalars['Boolean']>;
	layers?: Maybe<Array<Scalars['String']>>;
	hasHistory?: Maybe<Scalars['Boolean']>;
	historyDates?: Maybe<Array<Scalars['DateTimeIso']>>;
	hasHistoryInitialMoment?: Maybe<Scalars['Boolean']>;
	hasHistoryPresentMoment?: Maybe<Scalars['Boolean']>;
	hasRank?: Maybe<Scalars['Boolean']>;
	maxRank?: Maybe<Scalars['Int']>;
	layersCardTitle?: Maybe<Scalars['String']>;
	highlightOptions?: Maybe<GridHighlightOptions>;
};

export type GraphNode = {
	id: Scalars['String'];
	label?: Maybe<Scalars['String']>;
	layers?: Maybe<Array<Scalars['String']>>;
	ranges?: Maybe<Array<GraphRange>>;
	options?: Maybe<GraphNodeOptions>;
};

export type GraphNodeOptions = {
	layers?: Maybe<Array<Scalars['String']>>;
	rank?: Maybe<Scalars['Int']>;
	borderColor?: Maybe<Scalars['Int']>;
	borderThickness?: Maybe<Scalars['Int']>;
	style?: Maybe<Scalars['String']>;
	image?: Maybe<Scalars['String']>;
	isImportant?: Maybe<Scalars['Boolean']>;
	path?: Maybe<Scalars['JSON']>;
	popoverContent?: Maybe<Scalars['JSON']>;
};

export type GraphOptions = {
	initDate?: Maybe<Scalars['DateTimeIso']>;
	hasImportant?: Maybe<Scalars['Boolean']>;
	layers?: Maybe<Array<Scalars['String']>>;
	hasHistory?: Maybe<Scalars['Boolean']>;
	historyDates?: Maybe<Array<Scalars['DateTimeIso']>>;
	hasHistoryInitialMoment?: Maybe<Scalars['Boolean']>;
	hasHistoryPresentMoment?: Maybe<Scalars['Boolean']>;
	hasRank?: Maybe<Scalars['Boolean']>;
	maxRank?: Maybe<Scalars['Int']>;
	layersCardTitle?: Maybe<Scalars['String']>;
};

export type GraphRange = {
	layers?: Maybe<Array<Scalars['String']>>;
	options?: Maybe<Scalars['JSON']>;
	startAt?: Maybe<Scalars['DateTimeIso']>;
	endAt?: Maybe<Scalars['DateTimeIso']>;
	label?: Maybe<Scalars['String']>;
};

export type GraphTimeLineGrid = {
	nodes: Array<GraphNode>;
	edges: Array<GraphEdge>;
	notifications?: Maybe<Array<DataSourceNotification>>;
	options: GraphTimeLineGridOptions;
	fields: Array<Field>;
	itemsByTime: Array<GraphTimeLineGridItemsByTime>;
};

export type GraphTimeLineGridItemsByTime = {
	items: Array<Scalars['JSON']>;
	time?: Maybe<Scalars['DateTimeIso']>;
};

export type GraphTimeLineGridOptions = {
	initDate?: Maybe<Scalars['DateTimeIso']>;
	hasImportant?: Maybe<Scalars['Boolean']>;
	layers?: Maybe<Array<Scalars['String']>>;
	hasHistory?: Maybe<Scalars['Boolean']>;
	historyDates?: Maybe<Array<Scalars['DateTimeIso']>>;
	hasHistoryInitialMoment?: Maybe<Scalars['Boolean']>;
	hasHistoryPresentMoment?: Maybe<Scalars['Boolean']>;
	hasRank?: Maybe<Scalars['Boolean']>;
	maxRank?: Maybe<Scalars['Int']>;
	layersCardTitle?: Maybe<Scalars['String']>;
	highlightOptions?: Maybe<GridHighlightOptions>;
};

export type Grid = {
	fields: Array<Field>;
	options: GridOptions;
	items: Array<Scalars['JSON']>;
	notifications?: Maybe<Array<DataSourceNotification>>;
};

export type GridAction = {
	id: Scalars['String'];
	title: Scalars['String'];
	icon?: Maybe<Scalars['String']>;
	details?: Maybe<Scalars['Boolean']>;
	headAction?: Maybe<LinkOptions>;
	itemAction?: Maybe<LinkOptions>;
	isMassAction?: Maybe<Scalars['Boolean']>;
};

export type GridDiagram = {
	series: Array<GridDiagramSeries>;
	fields: Array<Field>;
	options?: Maybe<Scalars['JSON']>;
	notifications?: Maybe<Array<DataSourceNotification>>;
};

export type GridDiagramPoint = {
	y: Scalars['Float'];
	x?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	tableItem?: Maybe<Scalars['JSON']>;
};

export type GridDiagramSeries = {
	id: Scalars['String'];
	points: Array<GridDiagramPoint>;
	name: Scalars['String'];
	startPoint?: Maybe<Scalars['Float']>;
	pointInterval?: Maybe<Scalars['Float']>;
	baseLine?: Maybe<Scalars['String']>;
	yMeasure?: Maybe<SeriesMeasure>;
	groupName?: Maybe<Scalars['String']>;
	mergeId?: Maybe<Scalars['String']>;
};

export type GridForm = {
	id: Scalars['String'];
	title: Scalars['String'];
	fields?: Maybe<Scalars['JSON']>;
	buttons: Array<GridFormButton>;
	width?: Maybe<Scalars['Int']>;
};

export type GridFormButton = {
	id: Scalars['String'];
	title: Scalars['String'];
	icon?: Maybe<Scalars['String']>;
	primary?: Maybe<Scalars['Boolean']>;
	disableUnchanged?: Maybe<Scalars['Boolean']>;
	type?: Maybe<Scalars['String']>;
	behaviour?: Maybe<GridFormButtonBehaviour>;
	action?: Maybe<LinkOptions>;
	validation?: Maybe<Array<GridFormButtonFieldValidation>>;
	disable?: Maybe<Scalars['Boolean']>;
	hidden?: Maybe<Scalars['Boolean']>;
};

export enum GridFormButtonBehaviour {
	Create = 'Create',
	Update = 'Update',
	Cancel = 'Cancel',
	Custom = 'Custom',
}

export type GridFormButtonFieldValidation = {
	id: Scalars['String'];
	required?: Maybe<Scalars['Boolean']>;
	regExp?: Maybe<Scalars['String']>;
	errorText?: Maybe<Scalars['String']>;
};

export type GridHighlightOptions = {
	edge?: Maybe<GraphEdgeOptions>;
	node?: Maybe<GraphNodeOptions>;
};

export type GridOptions = {
	total: Scalars['Int'];
	defaultNumItems?: Maybe<Scalars['Int']>;
	selectable?: Maybe<Scalars['Boolean']>;
	searchable?: Maybe<Scalars['Boolean']>;
	minLengthSearchQuery?: Maybe<Scalars['Int']>;
	collapsedGroups?: Maybe<Array<Scalars['String']>>;
	actions?: Maybe<Array<GridAction>>;
	forms?: Maybe<Array<GridForm>>;
	custom?: Maybe<Scalars['JSON']>;
};

export enum GroupBy {
	AsA = 'asA',
	AsB = 'asB',
	CountryA = 'countryA',
	CountryB = 'countryB',
	RegistryA = 'registryA',
	RegistryB = 'registryB',
	Direction = 'direction',
	IpA = 'ipA',
	IpB = 'ipB',
	PortA = 'portA',
	PortB = 'portB',
	Protocol = 'protocol',
}

export type HelpFile = {
	id: Scalars['String'];
	title: Scalars['DateTimeIso'];
	sectionId?: Maybe<Scalars['String']>;
};

export type HelpFileListInput = {
	sectionId?: Maybe<Scalars['String']>;
};

export type HomeButton = {
	id: Scalars['String'];
	title: Scalars['String'];
	type: HomeButtonType;
	data?: Maybe<Scalars['String']>;
};

export enum HomeButtonType {
	Static = 'Static',
	Dynamic = 'Dynamic',
	Route = 'Route',
}

export type IncidentsInput = {
	itemsPerPage?: Maybe<Scalars['Int']>;
	sortBy?: Maybe<Scalars['String']>;
	sort?: Maybe<SortOrder>;
	page: Scalars['Int'];
};

export type InformationMessageResponse = {
	id: Scalars['String'];
	key: Scalars['String'];
	value?: Maybe<Scalars['String']>;
};

export type Item = {
	recipientGroupId: Scalars['String'];
	recipientType: Scalars['String'];
	params?: Maybe<Scalars['JSON']>;
};

export type LinkItem = {
	id: Scalars['String'];
	link: Scalars['String'];
};

export type LinkOptions = {
	/** Название экшена */
	gridAction?: Maybe<Scalars['String']>;
	/** Название формы */
	gridForm?: Maybe<Scalars['String']>;
	/** Название роута */
	route?: Maybe<Scalars['String']>;
	/**
	 * Объект с параметрами. В качестве значений можно использовать id поля в фигурных скобках
	 * Например: {id: '{id}'} возьмет id из текущего item
	 */
	params?: Maybe<Scalars['JSON']>;
	/** URL */
	url?: Maybe<Scalars['String']>;
	target?: Maybe<Scalars['String']>;
	confirmTitle?: Maybe<Scalars['String']>;
	confirmText?: Maybe<Scalars['String']>;
};

export type MasterDependencies = {
	/** Набор зависимостей, указываемых для главного поля */
	slaves: Array<Dependence>;
	/** Поведение зависимости */
	behaviour: DependenceBehaviour;
};

export type Match = {
	start: Scalars['Int'];
	end: Scalars['Int'];
};

export enum Metric {
	Packets = 'packets',
	Bytes = 'bytes',
	Flows = 'flows',
}

export type ModelInput = {
	itemsPerPage?: Maybe<Scalars['Int']>;
	sortBy?: Maybe<Scalars['String']>;
	sort?: Maybe<SortOrder>;
	page?: Maybe<Scalars['Int']>;
	startIndex?: Maybe<Scalars['Int']>;
	endIndex?: Maybe<Scalars['Int']>;
};

export type Mutation = {
	authorize: Scalars['String'];
	authorizeByCode: Scalars['String'];
	authorizeByThirdPartyToken: Scalars['String'];
	logout?: Maybe<Scalars['Boolean']>;
	refresh: Scalars['String'];
	addConversationMessage: ConversationMessage;
	dataSourceDelete: Scalars['Boolean'];
	dataSourceDeleteFile?: Maybe<Scalars['Boolean']>;
	dataSourceUpdate: FindQuery;
	dataSourceUploadChunk: FileValue;
	gridAction: Grid;
	gridForm: FindQuery;
	createFile: Scalars['String'];
	deleteBatch: Scalars['Boolean'];
	publishFile: PublishFileResult;
	uploadChunk: Scalars['Boolean'];
	assignCorrelator: Scalars['Boolean'];
	withdrawCorrelator: Scalars['Boolean'];
	saveScript: SaveScriptResult;
	validateScript: ValidationScriptResult;
	assignAdapter: Scalars['Boolean'];
	withdrawAdapter: Scalars['Boolean'];
	createModel: Array<Scalars['JSON']>;
	deleteModel: Array<Scalars['String']>;
	toggleModelItem: ToggleModelItemResponse;
	updateModel: Array<Scalars['JSON']>;
	readNotifications: Array<Notification>;
	pirsAttachmentDelete: Scalars['Boolean'];
	pirsCloseSession?: Maybe<Scalars['Boolean']>;
	pirsCreate: PirsShortInstance;
	pirsDelete?: Maybe<Scalars['Boolean']>;
	pirsUpdate: PirsShortInstance;
	submitRecipientForm: Scalars['String'];
	reportExicution: Scalars['Boolean'];
	updateReportConfiguration: ViewPage;
	uploadSettingsFile: Scalars['String'];
	commonFiltersPresetCreate: CommonFiltersPreset;
	commonFiltersPresetUpdate: CommonFiltersPreset;
	createViewsPage: ViewPage;
	customForm: Scalars['JSON'];
	deleteViewsPage: Scalars['Boolean'];
	updateViewsPage: ViewPage;
};

export type MutationAuthorizeArgs = {
	login: Scalars['String'];
	password: Scalars['String'];
};

export type MutationAuthorizeByCodeArgs = {
	code: Scalars['String'];
};

export type MutationAuthorizeByThirdPartyTokenArgs = {
	token: Scalars['String'];
};

export type MutationAddConversationMessageArgs = {
	conversationId: Scalars['String'];
	dataSourceId: Scalars['String'];
	itemId: Scalars['String'];
	fieldId: Scalars['String'];
	text: Scalars['String'];
};

export type MutationDataSourceDeleteArgs = {
	dataSourceId: Scalars['String'];
	ids: Array<Scalars['String']>;
};

export type MutationDataSourceDeleteFileArgs = {
	id: Scalars['String'];
};

export type MutationDataSourceUpdateArgs = {
	dataSourceId: Scalars['String'];
	item: Scalars['JSON'];
};

export type MutationDataSourceUploadChunkArgs = {
	id?: Maybe<Scalars['String']>;
	chunk: Scalars['Int'];
	chunks: Scalars['Int'];
	title: Scalars['String'];
	size: Scalars['Int'];
	content: Scalars['String'];
};

export type MutationGridActionArgs = {
	dataSourceId: Scalars['String'];
	action: Scalars['String'];
	ids: Array<Scalars['String']>;
};

export type MutationGridFormArgs = {
	dataSourceId: Scalars['String'];
	formId: Scalars['String'];
	id: Scalars['String'];
	selectedIds: Array<Scalars['String']>;
	item: Scalars['JSON'];
};

export type MutationCreateFileArgs = {
	input: FileInput;
};

export type MutationDeleteBatchArgs = {
	input: DeleteBatchInput;
};

export type MutationPublishFileArgs = {
	input: PublishFileInput;
};

export type MutationUploadChunkArgs = {
	input: UploadChunkInput;
};

export type MutationAssignCorrelatorArgs = {
	correlatorId: Scalars['String'];
	configurationId: Scalars['String'];
};

export type MutationWithdrawCorrelatorArgs = {
	correlatorId: Scalars['String'];
	configurationId: Scalars['String'];
};

export type MutationSaveScriptArgs = {
	input: SaveScriptInput;
};

export type MutationValidateScriptArgs = {
	modelName: Scalars['String'];
	content: Scalars['String'];
};

export type MutationAssignAdapterArgs = {
	adapterId: Scalars['String'];
	scriptId: Scalars['String'];
};

export type MutationWithdrawAdapterArgs = {
	adapterId: Scalars['String'];
	scriptId: Scalars['String'];
};

export type MutationCreateModelArgs = {
	model: Scalars['String'];
	items: Array<Scalars['JSON']>;
	withRelations?: Maybe<Array<WithRelation>>;
};

export type MutationDeleteModelArgs = {
	model: Scalars['String'];
	ids: Array<Scalars['String']>;
};

export type MutationToggleModelItemArgs = {
	input: ToggleModelItemInput;
};

export type MutationUpdateModelArgs = {
	model: Scalars['String'];
	items: Array<Scalars['JSON']>;
	withRelations?: Maybe<Array<WithRelation>>;
};

export type MutationReadNotificationsArgs = {
	notificationsIds: Array<Scalars['String']>;
};

export type MutationPirsAttachmentDeleteArgs = {
	attachmentId: Scalars['String'];
};

export type MutationPirsCreateArgs = {
	type: Scalars['String'];
	doc?: Maybe<Scalars['JSON']>;
};

export type MutationPirsDeleteArgs = {
	type: Scalars['String'];
	id: Scalars['ID'];
};

export type MutationPirsUpdateArgs = {
	type: Scalars['String'];
	id: Scalars['ID'];
	doc?: Maybe<Scalars['JSON']>;
	instanceVersion: Scalars['ID'];
};

export type MutationSubmitRecipientFormArgs = {
	id?: Maybe<Scalars['String']>;
	item: Item;
};

export type MutationReportExicutionArgs = {
	formValues: Scalars['JSON'];
};

export type MutationUpdateReportConfigurationArgs = {
	id: Scalars['String'];
	name: Scalars['String'];
	content: Scalars['String'];
};

export type MutationUploadSettingsFileArgs = {
	input: UploadSettingsFileInput;
};

export type MutationCommonFiltersPresetCreateArgs = {
	input?: Maybe<CommonFiltersPresetCreateInput>;
};

export type MutationCommonFiltersPresetUpdateArgs = {
	input?: Maybe<CommonFiltersPresetUpdateInput>;
};

export type MutationCreateViewsPageArgs = {
	name: Scalars['String'];
	content: Scalars['String'];
};

export type MutationCustomFormArgs = {
	formId: Scalars['String'];
	values: Scalars['JSON'];
};

export type MutationDeleteViewsPageArgs = {
	id: Scalars['String'];
};

export type MutationUpdateViewsPageArgs = {
	id: Scalars['String'];
	name: Scalars['String'];
	content: Scalars['String'];
};

export type Networkport = {
	id: Scalars['String'];
	port_number: Scalars['String'];
	service_name?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	transport_protocol?: Maybe<Scalars['String']>;
};

export type Networkprotocol = {
	id: Scalars['String'];
	decimal: Scalars['String'];
	keyword?: Maybe<Scalars['String']>;
	protocol?: Maybe<Scalars['String']>;
};

export type Notification = {
	id: Scalars['String'];
	createdAt: Scalars['DateTimeIso'];
	message: Scalars['String'];
	checked: Scalars['Boolean'];
};

export type NotificationsData = {
	notifications: Array<Maybe<Notification>>;
	hasNext?: Maybe<Scalars['Boolean']>;
};

export type OnceTableData = {
	item: Scalars['JSON'];
	columns: Array<TableColumn>;
	meta: TableMeta;
};

export enum Order {
	Top = 'top',
	Bottom = 'bottom',
}

export type OrgMonitorInput = {
	/** Перечень ИНН, для которых необходимо получение статуса мониторинга */
	orgInns: Array<Scalars['String']>;
};

export type OrgMonitorItem = {
	tin: Scalars['String'];
	bgpStatus: OrgMonitorStatus;
	snmpStatus: OrgMonitorStatus;
	netflowStatus: OrgMonitorStatus;
};

export enum OrgMonitorStatus {
	Valid = 'valid',
	Invalid = 'invalid',
	Unknown = 'unknown',
}

export type OuterLinksInput = {
	/** Идентификаторы внешних ресурсов */
	outerIds: Array<Scalars['String']>;
};

export type Params = {
	key: Scalars['String'];
	name: Scalars['String'];
};

export enum PeriodDetail {
	Hour = 'hour',
	Minute = 'minute',
	Day = 'day',
}

export type PirsListInput = {
	type: Scalars['String'];
	first: Scalars['Int'];
	after?: Maybe<Scalars['Int']>;
	textSearchFilter?: Maybe<Scalars['String']>;
	hideRejected?: Maybe<Scalars['Boolean']>;
	hideOpen?: Maybe<Scalars['Boolean']>;
	hideDisused?: Maybe<Scalars['Boolean']>;
	hideWithoutInspectorComments?: Maybe<Scalars['Boolean']>;
	sort?: Maybe<SortOrder>;
	sortBy?: Maybe<Scalars['String']>;
};

export type PirsOrders = {
	ordersName: Array<Scalars['String']>;
	orders: Scalars['JSON'];
};

export type PirsShortInstance = PirsShortInstanceResult | PirsShortInstanceErrors;

export type PirsShortInstanceError = {
	userFriendlyMessage: Scalars['String'];
	path?: Maybe<Array<Scalars['String']>>;
};

export type PirsShortInstanceErrors = {
	errors: Array<PirsShortInstanceError>;
};

export type PirsShortInstanceResult = {
	id: Scalars['String'];
	lastDocChangeInstant: Scalars['String'];
	instanceVersion: Scalars['String'];
};

export type PossibleValue = {
	id: Scalars['String'];
	title: Scalars['String'];
	firstLine?: Maybe<Scalars['String']>;
	secondLine?: Maybe<Scalars['String']>;
	iconText?: Maybe<Scalars['String']>;
};

export type PossibleValueMapper = {
	/** По умолчанию будет использован id элемента */
	id?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	firstLine?: Maybe<Scalars['String']>;
	secondLine?: Maybe<Scalars['String']>;
	iconText?: Maybe<Scalars['String']>;
};

export type PublishFileInput = {
	/** id секции */
	sectionId: Scalars['String'];
};

export type PublishFileResult = {
	status: Scalars['String'];
	publishAt?: Maybe<Scalars['DateTimeIso']>;
};

export type Query = {
	/** Словарь */
	dictionary: Array<DictionaryItem>;
	/** Словарь */
	oldDictionary: Array<DictionaryItem>;
	/** Версия GraphQL API */
	version?: Maybe<Scalars['String']>;
	anomaliesByFilter: AnomaliesResult;
	anomaliesBySource: AnomaliesResult;
	/** Данные для графика алертов по времени */
	anomaliesQuantity: AnomaliesResult;
	anomaliesModelNames: Array<AnomalyModelNames>;
	asConnections: Array<AsForGraph>;
	asCountByCountries: Array<AsCountByCountryResponse>;
	asCountByRegistries: Array<AsCountByRegistriesResponse>;
	asLinks: Array<AsForGraph>;
	asLinksAbroad: Array<AsForGraph>;
	asLinksAbroadCount: AsLinksCountResponse;
	asLinksCount: AsLinksCountResponse;
	asLinksCountFull: AsLinksCountFullResponse;
	/** Топология АС */
	asNetwork: Array<AsForGraph>;
	/** Топология АС */
	asNetworkHistory: Array<AsForGraph>;
	user: User;
	contextHelp: ContextHelpResponse;
	informationMessage: InformationMessageResponse;
	conversations: Array<ConversationMessage>;
	dataSourceItem: FindQuery;
	diagram: Diagram;
	graph: Graph;
	graphGrid: GraphGrid;
	graphTimeLineGrid: GraphTimeLineGrid;
	grid: Grid;
	gridDiagram: GridDiagram;
	refData: Array<PossibleValue>;
	relationData: Grid;
	/** Словарь */
	companyDictionary: Array<DictionaryItem>;
	fileSections: Array<FileSection>;
	filesList: Array<RemoteFile>;
	fileStatus: FileStatusResponce;
	helpFilesList: Array<HelpFile>;
	getConfigurationGroup: Array<ConfigurationGroup>;
	getScript: Script;
	getScriptHistory: Array<ScriptHistory>;
	getScripts: Array<Scripts>;
	modelItem: OnceTableData;
	modelItemResolve: OnceTableData;
	modelItems: TableData;
	tableFormSelectorItems: Array<SelectorItem>;
	asTraffic: AsTrafficResult;
	/** Статистика Netflow */
	asTrafficByTime: Array<AsTrafficByTimeItem>;
	asTrafficSankey: AsTrafficSankeyResult;
	/** Статистика Netflow */
	flowoperator: Array<FlowoperatorItem>;
	newUnreadNotifications: Array<Maybe<Notification>>;
	notificationList: NotificationsData;
	notificationsCount: Scalars['Int'];
	outerLinks: Array<Maybe<LinkItem>>;
	pirsAttachments: ResourceAttachmentsTable;
	pirsGetSession?: Maybe<Scalars['DateTimeIso']>;
	pirsInstance?: Maybe<Scalars['JSON']>;
	pirsInstanceDetails?: Maybe<Scalars['JSON']>;
	pirsList: ResourceRegisterTable;
	pirsRefTypeSearch: Array<Scalars['JSON']>;
	pirsSidebar: PirsOrders;
	recipientDictionary: Array<Maybe<DictionaryItem>>;
	recipientGroupData: Array<Recipient>;
	getSchedule: Scalars['JSON'];
	reportConfiguration: ViewPage;
	schedules: TableData;
	orgMonitor: Array<OrgMonitorItem>;
	/** кнопкии на главной странице */
	homeButtons: Array<HomeButton>;
	actions: Array<Action>;
	commonFiltersPresetList: Array<CommonFiltersPreset>;
	componentsList: Array<ViewComponent>;
	dataSource: Array<DataSource>;
	refGraphPopoverValue?: Maybe<Scalars['String']>;
	refValues: Array<RefValuesResult>;
	viewsPage: ViewPage;
};

export type QueryDictionaryArgs = {
	id: Scalars['String'];
	keys?: Maybe<Array<Scalars['String']>>;
	values?: Maybe<Array<Scalars['String']>>;
	suggest?: Maybe<Scalars['String']>;
};

export type QueryOldDictionaryArgs = {
	id: Scalars['String'];
};

export type QueryAnomaliesByFilterArgs = {
	input: AnomaliesByFilterInput;
};

export type QueryAnomaliesBySourceArgs = {
	input: AnomaliesBySourceInput;
};

export type QueryAnomaliesQuantityArgs = {
	input: AnomaliesQuantityInput;
};

export type QueryAnomaliesModelNamesArgs = {
	input: Scalars['String'];
};

export type QueryAsConnectionsArgs = {
	input: AsConnectionsInput;
};

export type QueryAsCountByCountriesArgs = {
	countries: Array<Scalars['String']>;
};

export type QueryAsCountByRegistriesArgs = {
	registries: Array<Scalars['String']>;
};

export type QueryAsLinksArgs = {
	input: AsLinksInput;
};

export type QueryAsLinksAbroadArgs = {
	input: AsLinksAbroadInput;
};

export type QueryAsLinksAbroadCountArgs = {
	input: AsLinksAbroadCountInput;
};

export type QueryAsLinksCountArgs = {
	input: AsLinksCountInput;
};

export type QueryAsLinksCountFullArgs = {
	input: AsLinksCountFullInput;
};

export type QueryAsNetworkArgs = {
	input: AsNetworkInput;
};

export type QueryAsNetworkHistoryArgs = {
	input: AsNetworkHistoryInput;
};

export type QueryContextHelpArgs = {
	key: Scalars['String'];
};

export type QueryInformationMessageArgs = {
	key: Scalars['String'];
};

export type QueryConversationsArgs = {
	conversationId: Scalars['String'];
	dataSourceId: Scalars['String'];
	itemId: Scalars['String'];
	fieldId: Scalars['String'];
};

export type QueryDataSourceItemArgs = {
	dataSourceId: Scalars['String'];
	id: Scalars['String'];
};

export type QueryDiagramArgs = {
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
};

export type QueryGraphArgs = {
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
};

export type QueryGraphGridArgs = {
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
};

export type QueryGraphTimeLineGridArgs = {
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
};

export type QueryGridArgs = {
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
};

export type QueryGridDiagramArgs = {
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
};

export type QueryRefDataArgs = {
	type: Scalars['String'];
	dependencyFilters?: Maybe<Array<DependencyFilter>>;
	searchQuery?: Maybe<Scalars['String']>;
};

export type QueryRelationDataArgs = {
	model: Scalars['String'];
	dependencyFilters?: Maybe<Array<DependencyFilter>>;
	filters?: Maybe<Scalars['JSON']>;
};

export type QueryCompanyDictionaryArgs = {
	suggest?: Maybe<Scalars['String']>;
};

export type QueryFilesListArgs = {
	input: FileListInput;
};

export type QueryFileStatusArgs = {
	input: FileStatusInput;
};

export type QueryHelpFilesListArgs = {
	input: HelpFileListInput;
};

export type QueryGetScriptArgs = {
	modelName: Scalars['String'];
	scriptId: Scalars['String'];
	versionId: Scalars['String'];
};

export type QueryGetScriptHistoryArgs = {
	modelName: Scalars['String'];
	scriptId: Scalars['String'];
};

export type QueryGetScriptsArgs = {
	modelName: Scalars['String'];
	variables?: Maybe<Scalars['JSON']>;
};

export type QueryModelItemArgs = {
	model: Scalars['String'];
	id: Scalars['String'];
	withRelations?: Maybe<Array<WithRelation>>;
};

export type QueryModelItemResolveArgs = {
	model: Scalars['String'];
	item: Scalars['JSON'];
	withRelations?: Maybe<Array<WithRelation>>;
};

export type QueryModelItemsArgs = {
	model: Scalars['String'];
	params?: Maybe<ModelInput>;
	variables?: Maybe<Scalars['JSON']>;
	withRelations?: Maybe<Array<WithRelation>>;
};

export type QueryTableFormSelectorItemsArgs = {
	url: Scalars['String'];
};

export type QueryAsTrafficArgs = {
	input: AsTrafficInput;
};

export type QueryAsTrafficByTimeArgs = {
	input: AsTrafficByTimeInput;
};

export type QueryAsTrafficSankeyArgs = {
	input: AsTrafficSankeyInput;
};

export type QueryFlowoperatorArgs = {
	input: FlowoperatorInput;
};

export type QueryNewUnreadNotificationsArgs = {
	afterId?: Maybe<Scalars['String']>;
};

export type QueryNotificationListArgs = {
	checked: Scalars['Boolean'];
	before?: Maybe<Scalars['String']>;
};

export type QueryOuterLinksArgs = {
	input: OuterLinksInput;
};

export type QueryPirsAttachmentsArgs = {
	input: AttachmentsInput;
};

export type QueryPirsInstanceArgs = {
	type: Scalars['String'];
	id?: Maybe<Scalars['ID']>;
};

export type QueryPirsInstanceDetailsArgs = {
	type: Scalars['String'];
	id: Scalars['ID'];
	attributePath: Scalars['String'];
};

export type QueryPirsListArgs = {
	input?: Maybe<PirsListInput>;
};

export type QueryPirsRefTypeSearchArgs = {
	type: Scalars['String'];
	queryText: Scalars['String'];
};

export type QueryRecipientDictionaryArgs = {
	type: Scalars['String'];
	param: Scalars['String'];
	suggest?: Maybe<Scalars['String']>;
};

export type QueryGetScheduleArgs = {
	id: Scalars['String'];
};

export type QueryReportConfigurationArgs = {
	id: Scalars['String'];
};

export type QuerySchedulesArgs = {
	input: IncidentsInput;
};

export type QueryOrgMonitorArgs = {
	input: OrgMonitorInput;
};

export type QueryActionsArgs = {
	ids: Array<Scalars['String']>;
};

export type QueryCommonFiltersPresetListArgs = {
	input?: Maybe<CommonFiltersPresetListInput>;
};

export type QueryDataSourceArgs = {
	ids: Array<Scalars['String']>;
};

export type QueryRefGraphPopoverValueArgs = {
	refType: Scalars['String'];
	nodeFromId: Scalars['String'];
	nodeToId: Scalars['String'];
	currentHistoryDate?: Maybe<Scalars['String']>;
};

export type QueryRefValuesArgs = {
	input: Array<RefValuesInput>;
};

export type QueryViewsPageArgs = {
	id: Scalars['String'];
};

export type Recipient = {
	name: Scalars['String'];
	key: Scalars['String'];
	params: Array<Params>;
};

export type RefValuesInput = {
	refType: Scalars['String'];
	ids: Array<Scalars['String']>;
	field?: Maybe<Scalars['String']>;
};

export type RefValuesResult = {
	refType: Scalars['String'];
	values: Array<PossibleValue>;
	field?: Maybe<Scalars['String']>;
};

export type Relation = {
	/** Тип связи */
	type: RelationType;
	/** Название модели */
	model: Scalars['String'];
	/** Параметры запроса */
	filters?: Maybe<Scalars['JSON']>;
	/** Внешний ключ */
	foreignKey?: Maybe<Scalars['String']>;
	/** Локальный ключ (id по умолчанию) */
	localKey?: Maybe<Scalars['String']>;
	/** Модель связка (для MANY_TO_MANY) */
	through?: Maybe<Scalars['String']>;
	/** Локальный ключ таблицы связки */
	throughLocalKey?: Maybe<Scalars['String']>;
	/** Внешний ключ таблицы связки */
	throughForeignKey?: Maybe<Scalars['String']>;
	/** Локальный ключ, указывает на поле с массивом ключей. Только для HAS_MANY */
	throughArrayKey?: Maybe<Scalars['String']>;
	/** Маппер для типа ModelPossibleValue в формате {id?: 'fieldName', 'title': 'fieldName', firstLine?: 'fieldName', secondaryLine?: 'fieldName', iconLine?: 'fieldName'} */
	possibleValueMapper?: Maybe<PossibleValueMapper>;
};

export enum RelationType {
	/** Во внешней таблице ссылка на текущую (результат много записей) */
	HasMany = 'HAS_MANY',
	/** Во внешней таблице однозначное соответствие записи (результат 1 запись) */
	HasOne = 'HAS_ONE',
	/** В текущей таблице ссылка на внешнюю (результат 1 запись) */
	BelongsTo = 'BELONGS_TO',
	/** Текущая и внешняя таблица связаны через связующую (результат массив) */
	ManyToMany = 'MANY_TO_MANY',
}

export type RemoteFile = {
	id: Scalars['String'];
	regNumber: Scalars['Int'];
	fileName: Scalars['String'];
	submittedAt: Scalars['DateTimeIso'];
	fileSize: Scalars['Int'];
	status: Scalars['String'];
	statusMessage: Scalars['String'];
};

export type ResourceAttachmentsTable = {
	columns: Array<ResourceAttachmentsTableColumns>;
	items: Array<Maybe<ResourceAttachmentsTableItem>>;
};

export type ResourceAttachmentsTableColumns = {
	name: Scalars['String'];
	order: Scalars['Int'];
};

export type ResourceAttachmentsTableItem = {
	fileId: Scalars['String'];
	fileName: Scalars['String'];
	fileSize: Scalars['Int'];
	uploadAt: Scalars['String'];
};

export type ResourceRegisterTable = {
	headers: Array<Array<ResourceRegisterTableColumns>>;
	items: Array<Maybe<ResourceRegisterTableItem>>;
	hasNextPage?: Maybe<Scalars['Boolean']>;
	canDelete: Scalars['Boolean'];
};

export type ResourceRegisterTableColumns = {
	/** Имя столбца на английском, так же как называется аттрибут из которого был получен title */
	attributePath: Scalars['String'];
	/** Имя столбца на русском для пользователя */
	caption: Scalars['String'];
	rowspan?: Maybe<Scalars['Int']>;
	colspan?: Maybe<Scalars['Int']>;
	isHeader?: Maybe<Scalars['Boolean']>;
};

export type ResourceRegisterTableItem = {
	/** uuid ресурса или файла */
	id: Scalars['String'];
	values: Array<ResourceRegisterTableValues>;
	reconciliationStatus: Scalars['String'];
};

export type ResourceRegisterTableValues = {
	/** Имя столбца на английском, которому принадлежит значение ячейки */
	attributePath: Scalars['String'];
	/** Значение ячейки */
	value?: Maybe<Scalars['String']>;
	matches?: Maybe<Array<Match>>;
};

export enum SankeyGroupBy {
	As = 'As',
	Port = 'Port',
	Ip = 'Ip',
	Proto = 'Proto',
}

export type SaveScriptInput = {
	modelName: Scalars['String'];
	id: Scalars['String'];
	content: Scalars['String'];
};

export type SaveScriptResult = {
	problem?: Maybe<Scalars['String']>;
	isValid: Scalars['Boolean'];
	newVersionId?: Maybe<Scalars['Int']>;
};

export type Script = {
	id: Scalars['String'];
	name: Scalars['String'];
	version: Scalars['Int'];
	content: Scalars['String'];
};

export type ScriptHistory = {
	createdAt: Scalars['DateTimeIso'];
	key: Scalars['String'];
	value: Scalars['String'];
};

export type Scripts = {
	key: Scalars['String'];
	value: Scalars['String'];
};

export type SelectorItem = {
	key: Scalars['String'];
	value: Scalars['String'];
};

export enum SeriesMeasure {
	Number = 'Number',
	Bite = 'Bite',
	Byte = 'Byte',
}

export type SlaveDependencies = {
	/** Набор зависимостей, указываемых для подчиненного поля */
	masters: Array<Dependence>;
	/** Поведение зависимости */
	behaviour: DependenceBehaviour;
	/** Подсказка для подчиненного поля. Выводится под полем, если сработало условие */
	helperText?: Maybe<Scalars['String']>;
};

export enum SortOrder {
	Asc = 'ASC',
	Desc = 'DESC',
}

export type TableColumn = {
	/** ID столбца (как в бэке) */
	id: Scalars['String'];
	/** Заголовок для пользователя */
	title: Scalars['String'];
	/** Тип */
	type?: Maybe<TableColumnType>;
	/** Массив. Для типов вроде [String], [Number]. */
	isArray?: Maybe<Scalars['Boolean']>;
	/** Максимальное количество отображаемых элементов для массива в тиаблице */
	maxShownItems?: Maybe<Scalars['Int']>;
	/** Валидация */
	validation?: Maybe<TableColumnValidation>;
	/** Если тип DateTime, можно задать маску для преобразования в строку */
	formatTemplate?: Maybe<Scalars['String']>;
	/** Порядок столбца (умолчание, перебивается) */
	order?: Maybe<Scalars['Int']>;
	/**
	 * Показыкать ли столбец в таблице или только в деталировке (умолчание, перебивается)
	 * @deprecated
	 */
	visible?: Maybe<Scalars['Boolean']>;
	/** Сортировка по полю (умолчание, перебивается) */
	defaultSort?: Maybe<Scalars['Boolean']>;
	/** Возможна ли сортировка */
	canSort?: Maybe<Scalars['Boolean']>;
	/** Возможно ли создание */
	canCreate?: Maybe<Scalars['Boolean']>;
	/** Возмножно ли редактирование (если не указано canCreate, то работает и для него) */
	canEdit?: Maybe<Scalars['Boolean']>;
	/** Словарь для recipienttype/dictionary нужно передать тип: user, org, keycloak, pvvs */
	recipientTypeDictionary?: Maybe<Scalars['String']>;
	/** Старый словарь `${name}.${type}` из url `/im/${name}/dictionary?type=${type}` */
	oldDictionary?: Maybe<Scalars['String']>;
	/** Словарь `dictionary/${dictionary}` */
	dictionary?: Maybe<Scalars['String']>;
	/** Фильтр по ключам словаря */
	dictionaryKeys?: Maybe<Array<Scalars['String']>>;
	/** Фильтр по значениям словаря */
	dictionaryValues?: Maybe<Array<Scalars['String']>>;
	/** Обязательное поле (при редактировании) */
	required?: Maybe<Scalars['Boolean']>;
	/** Недоустпно для изменения */
	disabled?: Maybe<Scalars['Boolean']>;
	/** Значение по умолчанию для формы */
	defaultValue?: Maybe<Scalars['JSON']>;
	/**
	 * Ссылка (роут)
	 * @deprecated
	 */
	httpLink?: Maybe<TableHttpLink>;
	/** Тип селектора для словарного значения при изменении записи */
	dictionaryControl?: Maybe<TableDictionaryControl>;
	/** Связь */
	relation?: Maybe<TableRelation>;
	/** Url для получения значений для селектора */
	selectorUrl?: Maybe<Scalars['String']>;
	/** Для множественного выбора значений в селекторе */
	multiSelector?: Maybe<Scalars['Boolean']>;
	/** Вложенные колонки (добавляются автоматически) */
	columns?: Maybe<Array<TableColumn>>;
	/** Минимальное значение для инпута типа Number */
	min?: Maybe<Scalars['Float']>;
	/** Максимальное значение для инпута типа Number */
	max?: Maybe<Scalars['Float']>;
	/** тип рефа для автодополнения типа AutoComplete */
	refType?: Maybe<Scalars['String']>;
	/** Список доступных значений для редактирования некоторых типов полей */
	possibleValues?: Maybe<Array<PossibleValue>>;
	/** Минимальная длина символов для инициализации запроса в некоторых компонентах(например в комбобоксе) */
	refQueryMinLength?: Maybe<Scalars['Int']>;
};

export enum TableColumnType {
	Number = 'Number',
	Float = 'Float',
	String = 'String',
	MultiLineString = 'MultiLineString',
	DateTime = 'DateTime',
	Boolean = 'Boolean',
	FileSize = 'FileSize',
	Json = 'JSON',
	Subnet = 'Subnet',
	Toggle = 'Toggle',
	AutoComplete = 'AutoComplete',
	ModelNavigationUrl = 'ModelNavigationUrl',
}

export type TableColumnValidation = {
	/** Регулярное выражение для валидации. В случае массива валидирует 1 элемент. Если не указано, будет использован тип колонки */
	regExp?: Maybe<Scalars['String']>;
	/** Текст ошибки валидации */
	errorText: Scalars['String'];
};

export type TableData = {
	items: Array<Scalars['JSON']>;
	columns: Array<TableColumn>;
	meta: TableMeta;
};

export enum TableDictionaryControl {
	Selector = 'Selector',
	Combobox = 'Combobox',
}

export type TableHttpLink = {
	/** Название роута */
	route: Scalars['String'];
	/**
	 * Объект с параметрами. В качестве значений можно использовать значения в фигурных скобках
	 * Например: {id: '{id}'} возьмет id из текущего item
	 */
	params?: Maybe<Scalars['JSON']>;
};

/** Это для поповера, надо пофиксить */
export type TableInterfaceHttpLink = {
	/** Название роута */
	route: Scalars['String'];
	/** params для роутинга внутри приложения */
	params?: Maybe<Scalars['JSON']>;
	/** _blank или _self открывать ссылку в новом окне или в текущем для внутренних или внешних ссылок */
	target?: Maybe<Scalars['String']>;
};

export type TableMeta = {
	/** Общее количество элементов */
	total: Scalars['Int'];
};

export type TableRelation = {
	/** Тип связи */
	type: TableRelationType;
	/** Название модели */
	model: Scalars['String'];
	/** Параметры запроса */
	params?: Maybe<Scalars['JSON']>;
	/** Ленивая загрузка */
	lazyLoading?: Maybe<Scalars['Boolean']>;
	/** Внешний ключ */
	foreignKey?: Maybe<Scalars['String']>;
	/** Локальный ключ (id по умолчанию) */
	localKey?: Maybe<Scalars['String']>;
	/** Модель связка (для MANY_TO_MANY) */
	through?: Maybe<Scalars['String']>;
	/** Локальный ключ таблицы связки */
	throughLocalKey?: Maybe<Scalars['String']>;
	/** Внешний ключ таблицы связки */
	throughForeignKey?: Maybe<Scalars['String']>;
	/** Локальный ключ, указывает на поле с массивом ключей. Только для HAS_MANY */
	throughArrayKey?: Maybe<Scalars['String']>;
	/** Маппер для типа PosibleValue в формате {id?: 'fieldName', 'title': 'fieldName', firstLine?: 'fieldName', secondaryLine?: 'fieldName', iconLine?: 'fieldName'} */
	possibleValueMapper?: Maybe<PossibleValueMapper>;
};

export enum TableRelationType {
	/** Во внешней таблице ссылка на текущую (результат много записей) */
	HasMany = 'HAS_MANY',
	/** Во внешней таблице однозначное соотвествие записи (результат 1 запись) */
	HasOne = 'HAS_ONE',
	/** В текущей таблице ссылка на внешлюю (результат 1 запись) */
	BelongsTo = 'BELONGS_TO',
	/** Текущая и внешняя таблица связаны через связующую (результат массив) */
	ManyToMany = 'MANY_TO_MANY',
}

export enum ToggleModelItem {
	Enable = 'enable',
	Disable = 'disable',
}

export type ToggleModelItemInput = {
	model: Scalars['String'];
	id: Scalars['String'];
	toggle: ToggleModelItem;
	params?: Maybe<Scalars['JSON']>;
};

export type ToggleModelItemResponse = {
	id: Scalars['String'];
	enabled: Scalars['Boolean'];
};

export type UploadChunkInput = {
	/** Идентификатор */
	id: Scalars['String'];
	/** Номер чанка */
	index: Scalars['Int'];
	/** Контрольна сумма чанка */
	checkSum: Scalars['String'];
	/** Размер чанка */
	size: Scalars['Int'];
	/** Контент */
	content: Scalars['String'];
};

export type User = {
	shortOrganizationName?: Maybe<Scalars['String']>;
	shortUserName: Scalars['String'];
	name?: Maybe<Scalars['String']>;
	tin?: Maybe<Scalars['String']>;
	login: Scalars['String'];
	permissions: Array<Scalars['String']>;
	roles: Array<Scalars['String']>;
	refreshTimeout?: Maybe<Scalars['Int']>;
};

export type ValidationScriptResult = {
	problem?: Maybe<Scalars['String']>;
	isValid: Scalars['Boolean'];
};

export type ViewComponent = {
	/** ID компонента */
	id: Scalars['String'];
	code: Scalars['String'];
	group?: Maybe<Scalars['String']>;
	/** Название компонента для пользователя */
	title: Scalars['String'];
	/** Массив доступных источников данных */
	dataSources?: Maybe<Array<DataSource>>;
	description?: Maybe<Scalars['String']>;
	/** Флаг, указывает, что компонент может иметь дочерние компоненты */
	canHasChild: Scalars['Boolean'];
	/** Массив свойств компонента */
	fields: Array<Field>;
	actions?: Maybe<Array<Action>>;
};

export type ViewPage = {
	id: Scalars['String'];
	name: Scalars['String'];
	content: Scalars['String'];
	enabled: Scalars['Boolean'];
	version: Scalars['Int'];
};

export type WithRelation = {
	/** Название рэлейшена (поле модели, которое для него используется). Можно указывать вглубину, ставя точки между именами */
	name: Scalars['String'];
	/** Параметры для рэлэйшена, как для modelItems */
	params?: Maybe<ModelInput>;
};

export type UploadSettingsFileInput = {
	name: Scalars['String'];
	size: Scalars['String'];
	chunks: Scalars['String'];
};

export type AnomaliesByFilterQueryVariables = Exact<{
	input: AnomaliesByFilterInput;
}>;

export type AnomaliesByFilterQuery = {
	anomaliesByFilter: {
		series: Array<Pick<AnomaliesItems, 'name'> & {data: Array<Pick<AnomaliesItem, 'time' | 'value'>>}>;
	};
};

export type AnomaliesBySourceQueryVariables = Exact<{
	input: AnomaliesBySourceInput;
}>;

export type AnomaliesBySourceQuery = {
	anomaliesBySource: {
		series: Array<Pick<AnomaliesItems, 'name' | 'color'> & {data: Array<Pick<AnomaliesItem, 'time' | 'value'>>}>;
	};
};

export type AnomaliesQuantityQueryVariables = Exact<{
	input: AnomaliesQuantityInput;
}>;

export type AnomaliesQuantityQuery = {
	anomaliesQuantity: {
		series: Array<Pick<AnomaliesItems, 'name' | 'color'> & {data: Array<Pick<AnomaliesItem, 'time' | 'value'>>}>;
	};
};

export type AnomaliesModelNamesQueryVariables = Exact<{
	input: Scalars['String'];
}>;

export type AnomaliesModelNamesQuery = {anomaliesModelNames: Array<Pick<AnomalyModelNames, 'opened' | 'closed'>>};

export type AsConnectionsQueryVariables = Exact<{
	input: AsConnectionsInput;
}>;

export type AsConnectionsQuery = {
	asConnections: Array<
		Pick<
			AsForGraph,
			| 'bandwidth'
			| 'inputUtilization'
			| 'outputUtilization'
			| 'inputUtilizationPercentage'
			| 'outputUtilizationPercentage'
			| 'startAt'
			| 'endAt'
		> & {
			asFrom: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
			asTo: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
		}
	>;
};

export type AsCountByCountriesQueryVariables = Exact<{
	countries: Array<Scalars['String']> | Scalars['String'];
}>;

export type AsCountByCountriesQuery = {asCountByCountries: Array<Pick<AsCountByCountryResponse, 'country' | 'count'>>};

export type AsCountByRegistriesQueryVariables = Exact<{
	registries: Array<Scalars['String']> | Scalars['String'];
}>;

export type AsCountByRegistriesQuery = {
	asCountByRegistries: Array<Pick<AsCountByRegistriesResponse, 'registry' | 'count'>>;
};

export type AsLinksQueryVariables = Exact<{
	input: AsLinksInput;
}>;

export type AsLinksQuery = {
	asLinks: Array<
		Pick<
			AsForGraph,
			| 'bandwidth'
			| 'inputUtilization'
			| 'outputUtilization'
			| 'inputUtilizationPercentage'
			| 'outputUtilizationPercentage'
		> & {
			asFrom: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
			asTo: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
		}
	>;
};

export type AsLinksAbroadQueryVariables = Exact<{
	input: AsLinksAbroadInput;
}>;

export type AsLinksAbroadQuery = {
	asLinksAbroad: Array<
		Pick<
			AsForGraph,
			| 'bandwidth'
			| 'inputUtilization'
			| 'outputUtilization'
			| 'inputUtilizationPercentage'
			| 'outputUtilizationPercentage'
			| 'startAt'
			| 'endAt'
		> & {
			asFrom: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
			asTo: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
		}
	>;
};

export type AsLinksAbroadCountQueryVariables = Exact<{
	input: AsLinksAbroadCountInput;
}>;

export type AsLinksAbroadCountQuery = {
	asLinksAbroadCount: Pick<AsLinksCountResponse, 'other' | 'total'> & {
		items: Array<
			Pick<AsLinksCount, 'count'> & {
				as: Pick<
					As,
					'id' | 'title' | 'country' | 'registry' | 'organization' | 'operatorAName' | 'operatorAInn'
				>;
			}
		>;
	};
};

export type AsLinksCountQueryVariables = Exact<{
	input: AsLinksCountInput;
}>;

export type AsLinksCountQuery = {
	asLinksCount: Pick<AsLinksCountResponse, 'other' | 'total'> & {
		items: Array<
			Pick<AsLinksCount, 'count'> & {
				as: Pick<
					As,
					'id' | 'title' | 'country' | 'registry' | 'organization' | 'operatorAName' | 'operatorAInn'
				>;
			}
		>;
	};
};

export type AsNetworkQueryVariables = Exact<{
	input: AsNetworkInput;
}>;

export type AsNetworkQuery = {
	asNetwork: Array<
		Pick<
			AsForGraph,
			| 'bandwidth'
			| 'inputUtilization'
			| 'outputUtilization'
			| 'inputUtilizationPercentage'
			| 'outputUtilizationPercentage'
		> & {
			asFrom: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
			asTo: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
		}
	>;
};

export type AsNetworkHistoryQueryVariables = Exact<{
	input: AsNetworkHistoryInput;
}>;

export type AsNetworkHistoryQuery = {
	asNetworkHistory: Array<
		Pick<
			AsForGraph,
			| 'bandwidth'
			| 'inputUtilization'
			| 'outputUtilization'
			| 'inputUtilizationPercentage'
			| 'outputUtilizationPercentage'
			| 'startAt'
			| 'endAt'
		> & {
			asFrom: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
			asTo: Pick<As, 'id' | 'title' | 'country' | 'registry' | 'organization'>;
		}
	>;
};

export type AuthorizeByCodeMutationVariables = Exact<{
	code: Scalars['String'];
}>;

export type AuthorizeByCodeMutation = Pick<Mutation, 'authorizeByCode'>;

export type AuthorizeByThirdPartyTokenMutationVariables = Exact<{
	token: Scalars['String'];
}>;

export type AuthorizeByThirdPartyTokenMutation = Pick<Mutation, 'authorizeByThirdPartyToken'>;

export type LogoutMutationVariables = Exact<{[key: string]: never}>;

export type LogoutMutation = Pick<Mutation, 'logout'>;

export type RefreshMutationVariables = Exact<{[key: string]: never}>;

export type RefreshMutation = Pick<Mutation, 'refresh'>;

export type UserQueryVariables = Exact<{[key: string]: never}>;

export type UserQuery = {
	user: Pick<
		User,
		| 'shortOrganizationName'
		| 'shortUserName'
		| 'tin'
		| 'login'
		| 'name'
		| 'permissions'
		| 'roles'
		| 'refreshTimeout'
	>;
};

export type ContextHelpQueryVariables = Exact<{
	key: Scalars['String'];
}>;

export type ContextHelpQuery = {contextHelp: Pick<ContextHelpResponse, 'id' | 'key' | 'value'>};

export type InformationMessageQueryVariables = Exact<{
	key: Scalars['String'];
}>;

export type InformationMessageQuery = {informationMessage: Pick<InformationMessageResponse, 'id' | 'key' | 'value'>};

export type DataSourceDeleteMutationVariables = Exact<{
	dataSourceId: Scalars['String'];
	ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DataSourceDeleteMutation = Pick<Mutation, 'dataSourceDelete'>;

export type DataSourceDeleteFileMutationVariables = Exact<{
	id: Scalars['String'];
}>;

export type DataSourceDeleteFileMutation = Pick<Mutation, 'dataSourceDeleteFile'>;

export type DataSourceItemQueryVariables = Exact<{
	dataSourceId: Scalars['String'];
	id: Scalars['String'];
}>;

export type DataSourceItemQuery = {
	dataSourceItem: Pick<FindQuery, 'item' | 'options'> & {fields: Array<FieldsFragment>};
};

export type DataSourceUpdateMutationVariables = Exact<{
	dataSourceId: Scalars['String'];
	item: Scalars['JSON'];
}>;

export type DataSourceUpdateMutation = {
	dataSourceUpdate: Pick<FindQuery, 'item' | 'options'> & {
		fields: Array<FieldsFragment>;
		notifications?: Maybe<Array<Pick<DataSourceNotification, 'title' | 'color' | 'content'>>>;
	};
};

export type DataSourceUploadChunkMutationVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	chunk: Scalars['Int'];
	chunks: Scalars['Int'];
	title: Scalars['String'];
	size: Scalars['Int'];
	content: Scalars['String'];
}>;

export type DataSourceUploadChunkMutation = {
	dataSourceUploadChunk: Pick<FileValue, 'size' | 'location' | 'id' | 'title'>;
};

export type DiagramQueryVariables = Exact<{
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
}>;

export type DiagramQuery = {
	diagram: {
		series: Array<
			Pick<
				DiagramSeries,
				'id' | 'name' | 'startPoint' | 'pointInterval' | 'baseLine' | 'yMeasure' | 'groupName'
			> & {points: Array<Pick<DiagramPoint, 'x' | 'y' | 'name'>>}
		>;
		notifications?: Maybe<Array<Pick<DataSourceNotification, 'color' | 'title' | 'content'>>>;
	};
};

export type GraphQueryVariables = Exact<{
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
}>;

export type GraphQuery = {
	graph: {
		nodes: Array<
			Pick<GraphNode, 'id' | 'label' | 'layers'> & {
				options?: Maybe<NodeOptionsFragment>;
				ranges?: Maybe<Array<Pick<GraphRange, 'startAt' | 'endAt' | 'layers' | 'options' | 'label'>>>;
			}
		>;
		edges: Array<
			Pick<GraphEdge, 'id' | 'from' | 'to' | 'layers' | 'label'> & {
				ranges?: Maybe<Array<Pick<GraphRange, 'startAt' | 'endAt' | 'layers' | 'options' | 'label'>>>;
				options?: Maybe<EdgeOptionsFragment>;
			}
		>;
		options: Pick<
			GraphOptions,
			| 'initDate'
			| 'hasImportant'
			| 'layers'
			| 'hasHistory'
			| 'historyDates'
			| 'hasHistoryInitialMoment'
			| 'hasHistoryPresentMoment'
			| 'hasRank'
			| 'maxRank'
			| 'layersCardTitle'
		>;
		notifications?: Maybe<Array<Pick<DataSourceNotification, 'color' | 'title' | 'content'>>>;
	};
};

export type NodeOptionsFragment = Pick<
	GraphNodeOptions,
	| 'layers'
	| 'rank'
	| 'borderColor'
	| 'borderThickness'
	| 'style'
	| 'image'
	| 'isImportant'
	| 'path'
	| 'popoverContent'
>;

export type EdgeOptionsFragment = Pick<GraphEdgeOptions, 'arrows' | 'color' | 'line' | 'thickness' | 'popoverContent'>;

export type GraphGridQueryVariables = Exact<{
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
}>;

export type GraphGridQuery = {
	graphGrid: Pick<GraphGrid, 'tableItems'> & {
		nodes: Array<
			Pick<GraphNode, 'id' | 'label' | 'layers'> & {
				options?: Maybe<NodeOptionsFragment>;
				ranges?: Maybe<Array<Pick<GraphRange, 'startAt' | 'endAt' | 'layers' | 'options' | 'label'>>>;
			}
		>;
		edges: Array<
			Pick<GraphEdge, 'id' | 'from' | 'to' | 'layers' | 'label'> & {
				ranges?: Maybe<Array<Pick<GraphRange, 'startAt' | 'endAt' | 'layers' | 'options' | 'label'>>>;
				options?: Maybe<EdgeOptionsFragment>;
			}
		>;
		options: Pick<
			GraphGridOptions,
			| 'initDate'
			| 'hasImportant'
			| 'layers'
			| 'hasHistory'
			| 'historyDates'
			| 'hasHistoryInitialMoment'
			| 'hasHistoryPresentMoment'
			| 'hasRank'
			| 'maxRank'
			| 'layersCardTitle'
			| 'total'
			| 'defaultNumItems'
			| 'collapsedGroups'
		> & {
			actions?: Maybe<Array<GridActionsFragment>>;
			highlightOptions?: Maybe<{edge?: Maybe<EdgeOptionsFragment>; node?: Maybe<NodeOptionsFragment>}>;
		};
		fields: Array<FieldsFragment>;
		notifications?: Maybe<Array<Pick<DataSourceNotification, 'color' | 'title' | 'content'>>>;
	};
};

export type GraphTimeLineGridQueryVariables = Exact<{
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
}>;

export type GraphTimeLineGridQuery = {
	graphTimeLineGrid: {
		nodes: Array<
			Pick<GraphNode, 'id' | 'label' | 'layers'> & {
				options?: Maybe<NodeOptionsFragment>;
				ranges?: Maybe<Array<Pick<GraphRange, 'startAt' | 'endAt' | 'layers' | 'options' | 'label'>>>;
			}
		>;
		edges: Array<
			Pick<GraphEdge, 'id' | 'from' | 'to' | 'layers' | 'label'> & {
				ranges?: Maybe<Array<Pick<GraphRange, 'startAt' | 'endAt' | 'layers' | 'options' | 'label'>>>;
				options?: Maybe<EdgeOptionsFragment>;
			}
		>;
		options: Pick<
			GraphTimeLineGridOptions,
			| 'initDate'
			| 'hasImportant'
			| 'layers'
			| 'hasHistory'
			| 'historyDates'
			| 'hasHistoryInitialMoment'
			| 'hasHistoryPresentMoment'
			| 'hasRank'
			| 'maxRank'
			| 'layersCardTitle'
		> & {highlightOptions?: Maybe<{edge?: Maybe<EdgeOptionsFragment>; node?: Maybe<NodeOptionsFragment>}>};
		fields: Array<FieldsFragment>;
		itemsByTime: Array<Pick<GraphTimeLineGridItemsByTime, 'time' | 'items'>>;
		notifications?: Maybe<Array<Pick<DataSourceNotification, 'color' | 'title' | 'content'>>>;
	};
};

export type GridQueryVariables = Exact<{
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
}>;

export type GridQuery = {
	grid: Pick<Grid, 'items'> & {
		fields: Array<{fields?: Maybe<Array<FieldsFragment>>} & FieldsFragment>;
		options: Pick<
			GridOptions,
			| 'total'
			| 'defaultNumItems'
			| 'selectable'
			| 'searchable'
			| 'minLengthSearchQuery'
			| 'collapsedGroups'
			| 'custom'
		> & {actions?: Maybe<Array<GridActionsFragment>>; forms?: Maybe<Array<GridFormsFragment>>};
		notifications?: Maybe<Array<Pick<DataSourceNotification, 'color' | 'title' | 'content'>>>;
	};
};

export type LinkOptionsFragment = Pick<
	LinkOptions,
	'gridAction' | 'gridForm' | 'route' | 'params' | 'url' | 'target' | 'confirmText' | 'confirmTitle'
>;

export type GridActionsFragment = Pick<GridAction, 'id' | 'title' | 'icon' | 'details' | 'isMassAction'> & {
	headAction?: Maybe<LinkOptionsFragment>;
	itemAction?: Maybe<LinkOptionsFragment>;
};

export type GridFormsFragment = Pick<GridForm, 'id' | 'title' | 'fields' | 'width'> & {
	buttons: Array<GridFormButtonsFragment>;
};

export type GridFormButtonsFragment = Pick<
	GridFormButton,
	'id' | 'title' | 'icon' | 'type' | 'behaviour' | 'disableUnchanged' | 'primary' | 'disable' | 'hidden'
> & {
	action?: Maybe<LinkOptionsFragment>;
	validation?: Maybe<Array<Pick<GridFormButtonFieldValidation, 'id' | 'regExp' | 'errorText' | 'required'>>>;
};

export type FieldsFragment = Pick<
	Field,
	| 'id'
	| 'title'
	| 'description'
	| 'type'
	| 'showType'
	| 'controlType'
	| 'isArray'
	| 'possibleValuesRef'
	| 'order'
	| 'visible'
	| 'detail'
	| 'defaultSort'
	| 'canSort'
	| 'required'
	| 'disabled'
	| 'min'
	| 'max'
	| 'step'
	| 'defaultValue'
	| 'groupTitle'
	| 'minLengthQuery'
	| 'isGroupField'
	| 'minWidth'
	| 'maxWidth'
	| 'width'
	| 'isGeneral'
	| 'resizable'
	| 'autoHeightAdjustable'
> & {
	possibleValues?: Maybe<Array<Pick<PossibleValue, 'id' | 'title' | 'firstLine' | 'secondLine' | 'iconText'>>>;
	linkOptions?: Maybe<LinkOptionsFragment>;
	slaveDependencies?: Maybe<
		Array<
			Pick<SlaveDependencies, 'behaviour' | 'helperText'> & {
				masters: Array<Pick<Dependence, 'fieldId' | 'valueType' | 'values' | 'mapFilterValues' | 'helperText'>>;
			}
		>
	>;
	masterDependencies?: Maybe<
		Array<
			Pick<MasterDependencies, 'behaviour'> & {
				slaves: Array<Pick<Dependence, 'fieldId' | 'valueType' | 'values' | 'mapFilterValues' | 'helperText'>>;
			}
		>
	>;
	relation?: Maybe<
		Pick<
			Relation,
			| 'type'
			| 'model'
			| 'foreignKey'
			| 'localKey'
			| 'through'
			| 'throughArrayKey'
			| 'throughForeignKey'
			| 'throughLocalKey'
			| 'filters'
		> & {
			possibleValueMapper?: Maybe<
				Pick<PossibleValueMapper, 'id' | 'title' | 'firstLine' | 'iconText' | 'secondLine'>
			>;
		}
	>;
	validation?: Maybe<
		Pick<FieldValidation, 'allowedExtensions' | 'errorText' | 'maxFileSize' | 'maxFilesSize' | 'regExp'>
	>;
};

export type GridActionMutationVariables = Exact<{
	dataSourceId: Scalars['String'];
	action: Scalars['String'];
	ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GridActionMutation = {
	gridAction: Pick<Grid, 'items'> & {
		fields: Array<FieldsFragment>;
		options: Pick<
			GridOptions,
			'total' | 'defaultNumItems' | 'selectable' | 'searchable' | 'minLengthSearchQuery' | 'collapsedGroups'
		> & {actions?: Maybe<Array<GridActionsFragment>>; forms?: Maybe<Array<GridFormsFragment>>};
		notifications?: Maybe<Array<Pick<DataSourceNotification, 'color' | 'title' | 'content'>>>;
	};
};

export type GridDiagramQueryVariables = Exact<{
	dataSourceId: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
}>;

export type GridDiagramQuery = {
	gridDiagram: Pick<GridDiagram, 'options'> & {
		series: Array<
			Pick<
				GridDiagramSeries,
				'id' | 'name' | 'startPoint' | 'pointInterval' | 'baseLine' | 'yMeasure' | 'groupName' | 'mergeId'
			> & {points: Array<Pick<GridDiagramPoint, 'x' | 'y' | 'name' | 'tableItem'>>}
		>;
		fields: Array<FieldsFragment>;
		notifications?: Maybe<Array<Pick<DataSourceNotification, 'color' | 'title' | 'content'>>>;
	};
};

export type GridFormMutationVariables = Exact<{
	dataSourceId: Scalars['String'];
	formId: Scalars['String'];
	id: Scalars['String'];
	selectedIds: Array<Scalars['String']> | Scalars['String'];
	item: Scalars['JSON'];
}>;

export type GridFormMutation = {
	gridForm: Pick<FindQuery, 'item' | 'options'> & {
		fields: Array<FieldsFragment>;
		notifications?: Maybe<Array<Pick<DataSourceNotification, 'color' | 'content' | 'title'>>>;
	};
};

export type RefDataQueryVariables = Exact<{
	searchQuery: Scalars['String'];
	type: Scalars['String'];
	dependencyFilters?: Maybe<Array<DependencyFilter> | DependencyFilter>;
}>;

export type RefDataQuery = {
	refData: Array<Pick<PossibleValue, 'id' | 'title' | 'firstLine' | 'secondLine' | 'iconText'>>;
};

export type RelationDataQueryVariables = Exact<{
	model: Scalars['String'];
	filters?: Maybe<Scalars['JSON']>;
	dependencyFilters?: Maybe<Array<DependencyFilter> | DependencyFilter>;
}>;

export type RelationDataQuery = {
	relationData: Pick<Grid, 'items'> & {
		fields: Array<{fields?: Maybe<Array<FieldsFragment>>} & FieldsFragment>;
		options: Pick<GridOptions, 'total' | 'defaultNumItems'>;
	};
};

export type DictionaryQueryVariables = Exact<{
	id: Scalars['String'];
	keys?: Maybe<Array<Scalars['String']> | Scalars['String']>;
	suggest?: Maybe<Scalars['String']>;
}>;

export type DictionaryQuery = {dictionary: Array<Pick<DictionaryItem, 'key' | 'value'>>};

export type CompanyDictionaryQueryVariables = Exact<{
	suggest?: Maybe<Scalars['String']>;
}>;

export type CompanyDictionaryQuery = {companyDictionary: Array<Pick<DictionaryItem, 'key' | 'value'>>};

export type CreateFileMutationVariables = Exact<{
	input: FileInput;
}>;

export type CreateFileMutation = Pick<Mutation, 'createFile'>;

export type DeleteBatchMutationVariables = Exact<{
	input: DeleteBatchInput;
}>;

export type DeleteBatchMutation = Pick<Mutation, 'deleteBatch'>;

export type FileSectionsQueryVariables = Exact<{[key: string]: never}>;

export type FileSectionsQuery = {fileSections: Array<Pick<FileSection, 'id' | 'title' | 'description' | 'enabled'>>};

export type FileStatusQueryVariables = Exact<{
	input: FileStatusInput;
}>;

export type FileStatusQuery = {
	fileStatus: Pick<FileStatusResponce, 'id' | 'status' | 'size' | 'fileName' | 'createdAt' | 'uploadedAt'>;
};

export type FilesListQueryVariables = Exact<{
	input: FileListInput;
}>;

export type FilesListQuery = {
	filesList: Array<
		Pick<RemoteFile, 'id' | 'regNumber' | 'fileName' | 'submittedAt' | 'fileSize' | 'status' | 'statusMessage'>
	>;
};

export type HelpFilesListQueryVariables = Exact<{
	input: HelpFileListInput;
}>;

export type HelpFilesListQuery = {helpFilesList: Array<Pick<HelpFile, 'id' | 'title' | 'sectionId'>>};

export type PublishFileMutationVariables = Exact<{
	input: PublishFileInput;
}>;

export type PublishFileMutation = {publishFile: Pick<PublishFileResult, 'status' | 'publishAt'>};

export type UploadChunkMutationVariables = Exact<{
	input: UploadChunkInput;
}>;

export type UploadChunkMutation = Pick<Mutation, 'uploadChunk'>;

export type AssignCorrelatorMutationVariables = Exact<{
	correlatorId: Scalars['String'];
	configurationId: Scalars['String'];
}>;

export type AssignCorrelatorMutation = Pick<Mutation, 'assignCorrelator'>;

export type WithdrawCorrelatorMutationVariables = Exact<{
	correlatorId: Scalars['String'];
	configurationId: Scalars['String'];
}>;

export type WithdrawCorrelatorMutation = Pick<Mutation, 'withdrawCorrelator'>;

export type GetConfigurationGroupQueryVariables = Exact<{[key: string]: never}>;

export type GetConfigurationGroupQuery = {getConfigurationGroup: Array<Pick<ConfigurationGroup, 'key' | 'value'>>};

export type GetScriptQueryVariables = Exact<{
	modelName: Scalars['String'];
	scriptId: Scalars['String'];
	versionId: Scalars['String'];
}>;

export type GetScriptQuery = {getScript: Pick<Script, 'id' | 'name' | 'version' | 'content'>};

export type GetScriptHistoryQueryVariables = Exact<{
	modelName: Scalars['String'];
	scriptId: Scalars['String'];
}>;

export type GetScriptHistoryQuery = {getScriptHistory: Array<Pick<ScriptHistory, 'key' | 'value' | 'createdAt'>>};

export type GetScriptsQueryVariables = Exact<{
	modelName: Scalars['String'];
	variables?: Maybe<Scalars['JSON']>;
}>;

export type GetScriptsQuery = {getScripts: Array<Pick<Scripts, 'key' | 'value'>>};

export type SaveScriptMutationVariables = Exact<{
	input: SaveScriptInput;
}>;

export type SaveScriptMutation = {saveScript: Pick<SaveScriptResult, 'problem' | 'isValid' | 'newVersionId'>};

export type ValidateScriptMutationVariables = Exact<{
	modelName: Scalars['String'];
	content: Scalars['String'];
}>;

export type ValidateScriptMutation = {validateScript: Pick<ValidationScriptResult, 'problem' | 'isValid'>};

export type AssignAdapterMutationVariables = Exact<{
	adapterId: Scalars['String'];
	scriptId: Scalars['String'];
}>;

export type AssignAdapterMutation = Pick<Mutation, 'assignAdapter'>;

export type WithdrawAdapterMutationVariables = Exact<{
	adapterId: Scalars['String'];
	scriptId: Scalars['String'];
}>;

export type WithdrawAdapterMutation = Pick<Mutation, 'withdrawAdapter'>;

export type TableFormSelectorItemsQueryVariables = Exact<{
	url: Scalars['String'];
}>;

export type TableFormSelectorItemsQuery = {tableFormSelectorItems: Array<Pick<SelectorItem, 'key' | 'value'>>};

export type CreateModelMutationVariables = Exact<{
	model: Scalars['String'];
	items: Array<Scalars['JSON']> | Scalars['JSON'];
	withRelations?: Maybe<Array<WithRelation> | WithRelation>;
}>;

export type CreateModelMutation = Pick<Mutation, 'createModel'>;

export type DeleteModelMutationVariables = Exact<{
	model: Scalars['String'];
	ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteModelMutation = Pick<Mutation, 'deleteModel'>;

export type ModelItemQueryVariables = Exact<{
	model: Scalars['String'];
	id: Scalars['String'];
	withRelations?: Maybe<Array<WithRelation> | WithRelation>;
}>;

export type ModelItemQuery = {
	modelItem: Pick<OnceTableData, 'item'> & {
		columns: Array<
			{
				columns?: Maybe<
					Array<
						{
							columns?: Maybe<
								Array<
									{
										columns?: Maybe<
											Array<
												{
													columns?: Maybe<
														Array<
															{columns?: Maybe<Array<ColumnsFragment>>} & ColumnsFragment
														>
													>;
												} & ColumnsFragment
											>
										>;
									} & ColumnsFragment
								>
							>;
						} & ColumnsFragment
					>
				>;
			} & ColumnsFragment
		>;
		meta: Pick<TableMeta, 'total'>;
	};
};

export type ColumnsFragment = Pick<
	TableColumn,
	| 'id'
	| 'title'
	| 'type'
	| 'isArray'
	| 'maxShownItems'
	| 'formatTemplate'
	| 'order'
	| 'visible'
	| 'defaultSort'
	| 'canSort'
	| 'canCreate'
	| 'canEdit'
	| 'recipientTypeDictionary'
	| 'oldDictionary'
	| 'dictionary'
	| 'dictionaryKeys'
	| 'dictionaryValues'
	| 'required'
	| 'disabled'
	| 'defaultValue'
	| 'dictionaryControl'
	| 'selectorUrl'
	| 'multiSelector'
	| 'min'
	| 'max'
	| 'refType'
	| 'refQueryMinLength'
> & {
	validation?: Maybe<Pick<TableColumnValidation, 'regExp' | 'errorText'>>;
	httpLink?: Maybe<Pick<TableHttpLink, 'route' | 'params'>>;
	relation?: Maybe<
		Pick<
			TableRelation,
			| 'type'
			| 'model'
			| 'params'
			| 'lazyLoading'
			| 'foreignKey'
			| 'localKey'
			| 'through'
			| 'throughLocalKey'
			| 'throughForeignKey'
		> & {
			possibleValueMapper?: Maybe<
				Pick<PossibleValueMapper, 'id' | 'title' | 'firstLine' | 'secondLine' | 'iconText'>
			>;
		}
	>;
	possibleValues?: Maybe<Array<Pick<PossibleValue, 'id' | 'title' | 'firstLine' | 'secondLine' | 'iconText'>>>;
};

export type ModelItemResolveQueryVariables = Exact<{
	model: Scalars['String'];
	item: Scalars['JSON'];
	withRelations?: Maybe<Array<WithRelation> | WithRelation>;
}>;

export type ModelItemResolveQuery = {
	modelItemResolve: Pick<OnceTableData, 'item'> & {
		columns: Array<
			{
				columns?: Maybe<
					Array<
						{
							columns?: Maybe<
								Array<
									{
										columns?: Maybe<
											Array<
												{
													columns?: Maybe<
														Array<
															{columns?: Maybe<Array<ColumnsFragment>>} & ColumnsFragment
														>
													>;
												} & ColumnsFragment
											>
										>;
									} & ColumnsFragment
								>
							>;
						} & ColumnsFragment
					>
				>;
			} & ColumnsFragment
		>;
		meta: Pick<TableMeta, 'total'>;
	};
};

export type ModelItemsQueryVariables = Exact<{
	model: Scalars['String'];
	params?: Maybe<ModelInput>;
	variables?: Maybe<Scalars['JSON']>;
	withRelations?: Maybe<Array<WithRelation> | WithRelation>;
}>;

export type ModelItemsQuery = {
	modelItems: Pick<TableData, 'items'> & {
		columns: Array<
			{
				columns?: Maybe<
					Array<
						{
							columns?: Maybe<
								Array<
									{
										columns?: Maybe<
											Array<
												{
													columns?: Maybe<
														Array<
															{columns?: Maybe<Array<ColumnsFragment>>} & ColumnsFragment
														>
													>;
												} & ColumnsFragment
											>
										>;
									} & ColumnsFragment
								>
							>;
						} & ColumnsFragment
					>
				>;
			} & ColumnsFragment
		>;
		meta: Pick<TableMeta, 'total'>;
	};
};

export type ToggleModelItemMutationVariables = Exact<{
	input: ToggleModelItemInput;
}>;

export type ToggleModelItemMutation = {toggleModelItem: Pick<ToggleModelItemResponse, 'id' | 'enabled'>};

export type UpdateModelMutationVariables = Exact<{
	model: Scalars['String'];
	items: Array<Scalars['JSON']> | Scalars['JSON'];
	withRelations?: Maybe<Array<WithRelation> | WithRelation>;
}>;

export type UpdateModelMutation = Pick<Mutation, 'updateModel'>;

export type AsTrafficQueryVariables = Exact<{
	input: AsTrafficInput;
}>;

export type AsTrafficQuery = {
	asTraffic: Pick<AsTrafficResult, 'other' | 'total'> & {
		items: Array<
			Pick<
				AsTrafficItem,
				| 'key'
				| 'bytes'
				| 'asAId'
				| 'asAName'
				| 'asAIdName'
				| 'operatorAInn'
				| 'operatorAName'
				| 'asBId'
				| 'asBName'
				| 'asBIdName'
				| 'operatorBName'
				| 'operatorBInn'
				| 'operatorAId'
			>
		>;
	};
};

export type FlowoperatorQueryVariables = Exact<{
	input: FlowoperatorInput;
}>;

export type FlowoperatorQuery = {flowoperator: Array<Pick<FlowoperatorItem, 'id' | 'orgInn' | 'orgShortName'>>};

export type AsTrafficSankeyQueryVariables = Exact<{
	input: AsTrafficSankeyInput;
}>;

export type AsTrafficSankeyQuery = {
	asTrafficSankey: {
		nodes: Array<Pick<AsTrafficSankeyNodeItem, 'name'>>;
		links: Array<Pick<AsTrafficSankeyLinkItem, 'source' | 'target' | 'value'>>;
	};
};

export type AsTrafficByTimeQueryVariables = Exact<{
	input: AsTrafficByTimeInput;
}>;

export type AsTrafficByTimeQuery = {
	asTrafficByTime: Array<
		Pick<
			AsTrafficByTimeItem,
			| 'bytes'
			| 'packets'
			| 'flows'
			| 'asAId'
			| 'asBId'
			| 'protocol'
			| 'ipA'
			| 'ipB'
			| 'portA'
			| 'portB'
			| 'directions'
			| 'time'
		>
	>;
};

export type NewUnreadNotificationsQueryVariables = Exact<{
	afterId?: Maybe<Scalars['String']>;
}>;

export type NewUnreadNotificationsQuery = {
	newUnreadNotifications: Array<Maybe<Pick<Notification, 'id' | 'message' | 'createdAt' | 'checked'>>>;
};

export type NotificationListQueryVariables = Exact<{
	checked: Scalars['Boolean'];
	before?: Maybe<Scalars['String']>;
}>;

export type NotificationListQuery = {
	notificationList: Pick<NotificationsData, 'hasNext'> & {
		notifications: Array<Maybe<Pick<Notification, 'id' | 'createdAt' | 'message' | 'checked'>>>;
	};
};

export type NotificationsCountQueryVariables = Exact<{[key: string]: never}>;

export type NotificationsCountQuery = Pick<Query, 'notificationsCount'>;

export type ReadNotificationsMutationVariables = Exact<{
	notificationsIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type ReadNotificationsMutation = {
	readNotifications: Array<Pick<Notification, 'id' | 'message' | 'createdAt' | 'checked'>>;
};

export type OldDictionaryQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type OldDictionaryQuery = {oldDictionary: Array<Pick<DictionaryItem, 'key' | 'value'>>};

export type OuterLinksQueryVariables = Exact<{
	input: OuterLinksInput;
}>;

export type OuterLinksQuery = {outerLinks: Array<Maybe<Pick<LinkItem, 'id' | 'link'>>>};

export type PirsAttachmentDeleteMutationVariables = Exact<{
	attachmentId: Scalars['String'];
}>;

export type PirsAttachmentDeleteMutation = Pick<Mutation, 'pirsAttachmentDelete'>;

export type PirsAttachmentsQueryVariables = Exact<{
	input: AttachmentsInput;
}>;

export type PirsAttachmentsQuery = {
	pirsAttachments: {
		columns: Array<Pick<ResourceAttachmentsTableColumns, 'name' | 'order'>>;
		items: Array<Maybe<Pick<ResourceAttachmentsTableItem, 'fileId' | 'fileName' | 'fileSize' | 'uploadAt'>>>;
	};
};

export type PirsDeleteMutationVariables = Exact<{
	type: Scalars['String'];
	id: Scalars['ID'];
}>;

export type PirsDeleteMutation = Pick<Mutation, 'pirsDelete'>;

export type PirsCloseSessionMutationVariables = Exact<{[key: string]: never}>;

export type PirsCloseSessionMutation = Pick<Mutation, 'pirsCloseSession'>;

export type PirsCreateMutationVariables = Exact<{
	type: Scalars['String'];
	doc?: Maybe<Scalars['JSON']>;
}>;

export type PirsCreateMutation = {
	pirsCreate:
		| Pick<PirsShortInstanceResult, 'id' | 'lastDocChangeInstant' | 'instanceVersion'>
		| {errors: Array<Pick<PirsShortInstanceError, 'userFriendlyMessage' | 'path'>>};
};

export type PirsGetSessionQueryVariables = Exact<{[key: string]: never}>;

export type PirsGetSessionQuery = Pick<Query, 'pirsGetSession'>;

export type PirsInstanceQueryVariables = Exact<{
	type: Scalars['String'];
	id?: Maybe<Scalars['ID']>;
}>;

export type PirsInstanceQuery = Pick<Query, 'pirsInstance'>;

export type PirsInstanceDetailsQueryVariables = Exact<{
	type: Scalars['String'];
	id: Scalars['ID'];
	attributePath: Scalars['String'];
}>;

export type PirsInstanceDetailsQuery = Pick<Query, 'pirsInstanceDetails'>;

export type PirsListQueryVariables = Exact<{
	input?: Maybe<PirsListInput>;
}>;

export type PirsListQuery = {
	pirsList: Pick<ResourceRegisterTable, 'hasNextPage' | 'canDelete'> & {
		headers: Array<
			Array<Pick<ResourceRegisterTableColumns, 'attributePath' | 'caption' | 'rowspan' | 'colspan' | 'isHeader'>>
		>;
		items: Array<
			Maybe<
				Pick<ResourceRegisterTableItem, 'id' | 'reconciliationStatus'> & {
					values: Array<
						Pick<ResourceRegisterTableValues, 'attributePath' | 'value'> & {
							matches?: Maybe<Array<Pick<Match, 'start' | 'end'>>>;
						}
					>;
				}
			>
		>;
	};
};

export type PirsRefTypeSearchQueryVariables = Exact<{
	type: Scalars['String'];
	queryText: Scalars['String'];
}>;

export type PirsRefTypeSearchQuery = Pick<Query, 'pirsRefTypeSearch'>;

export type PirsSidebarQueryVariables = Exact<{[key: string]: never}>;

export type PirsSidebarQuery = {pirsSidebar: Pick<PirsOrders, 'orders' | 'ordersName'>};

export type PirsUpdateMutationVariables = Exact<{
	type: Scalars['String'];
	instanceVersion: Scalars['ID'];
	doc: Scalars['JSON'];
	id: Scalars['ID'];
}>;

export type PirsUpdateMutation = {
	pirsUpdate:
		| Pick<PirsShortInstanceResult, 'id' | 'lastDocChangeInstant' | 'instanceVersion'>
		| {errors: Array<Pick<PirsShortInstanceError, 'userFriendlyMessage' | 'path'>>};
};

export type RecipientDictionaryQueryVariables = Exact<{
	type: Scalars['String'];
	param: Scalars['String'];
	suggest?: Maybe<Scalars['String']>;
}>;

export type RecipientDictionaryQuery = {recipientDictionary: Array<Maybe<Pick<DictionaryItem, 'key' | 'value'>>>};

export type RecipientGroupDataQueryVariables = Exact<{[key: string]: never}>;

export type RecipientGroupDataQuery = {
	recipientGroupData: Array<Pick<Recipient, 'name' | 'key'> & {params: Array<Pick<Params, 'key' | 'name'>>}>;
};

export type SubmitRecipientFormMutationVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	item: Item;
}>;

export type SubmitRecipientFormMutation = Pick<Mutation, 'submitRecipientForm'>;

export type GetScheduleQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type GetScheduleQuery = Pick<Query, 'getSchedule'>;

export type ReportConfigurationQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type ReportConfigurationQuery = {reportConfiguration: Pick<ViewPage, 'id' | 'name' | 'content' | 'enabled'>};

export type ReportExicutionMutationVariables = Exact<{
	formValues: Scalars['JSON'];
}>;

export type ReportExicutionMutation = Pick<Mutation, 'reportExicution'>;

export type SchedulesQueryVariables = Exact<{
	input: IncidentsInput;
}>;

export type SchedulesQuery = {
	schedules: Pick<TableData, 'items'> & {
		columns: Array<
			Pick<
				TableColumn,
				| 'id'
				| 'title'
				| 'type'
				| 'order'
				| 'visible'
				| 'defaultSort'
				| 'canSort'
				| 'canCreate'
				| 'canEdit'
				| 'oldDictionary'
				| 'dictionary'
				| 'dictionaryKeys'
				| 'required'
				| 'disabled'
				| 'defaultValue'
			>
		>;
		meta: Pick<TableMeta, 'total'>;
	};
};

export type UpdateReportConfigurationMutationVariables = Exact<{
	id: Scalars['String'];
	name: Scalars['String'];
	content: Scalars['String'];
}>;

export type UpdateReportConfigurationMutation = {updateReportConfiguration: Pick<ViewPage, 'id' | 'name' | 'content'>};

export type UploadSettingsFileMutationVariables = Exact<{
	input: UploadSettingsFileInput;
}>;

export type UploadSettingsFileMutation = Pick<Mutation, 'uploadSettingsFile'>;

export type OrgMonitorQueryVariables = Exact<{
	input: OrgMonitorInput;
}>;

export type OrgMonitorQuery = {
	orgMonitor: Array<Pick<OrgMonitorItem, 'tin' | 'bgpStatus' | 'snmpStatus' | 'netflowStatus'>>;
};

export type HomeButtonsQueryVariables = Exact<{[key: string]: never}>;

export type HomeButtonsQuery = {homeButtons: Array<Pick<HomeButton, 'id' | 'title' | 'type' | 'data'>>};

export type VersionQueryVariables = Exact<{[key: string]: never}>;

export type VersionQuery = Pick<Query, 'version'>;

export type ActionsQueryVariables = Exact<{
	ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type ActionsQuery = {
	actions: Array<Pick<Action, 'id' | 'title' | 'description'> & {fields: Array<FieldsFragment>}>;
};

export type CommonFiltersPresetCreateMutationVariables = Exact<{
	input: CommonFiltersPresetCreateInput;
}>;

export type CommonFiltersPresetCreateMutation = {
	commonFiltersPresetCreate: Pick<CommonFiltersPreset, 'id' | 'dashboardId' | 'version' | 'title' | 'filters'>;
};

export type CommonFiltersPresetListQueryVariables = Exact<{
	input: CommonFiltersPresetListInput;
}>;

export type CommonFiltersPresetListQuery = {
	commonFiltersPresetList: Array<Pick<CommonFiltersPreset, 'id' | 'dashboardId' | 'version' | 'title' | 'filters'>>;
};

export type CommonFiltersPresetUpdateMutationVariables = Exact<{
	input: CommonFiltersPresetUpdateInput;
}>;

export type CommonFiltersPresetUpdateMutation = {
	commonFiltersPresetUpdate: Pick<CommonFiltersPreset, 'id' | 'dashboardId' | 'version' | 'title' | 'filters'>;
};

export type ComponentsListQueryVariables = Exact<{[key: string]: never}>;

export type ComponentsListQuery = {
	componentsList: Array<
		Pick<ViewComponent, 'id' | 'code' | 'group' | 'title' | 'description' | 'canHasChild'> & {
			dataSources?: Maybe<
				Array<Pick<DataSource, 'id' | 'title' | 'description'> & {fields: Array<FieldsFragment>}>
			>;
			fields: Array<FieldsFragment>;
			actions?: Maybe<Array<Pick<Action, 'id' | 'title' | 'description'> & {fields: Array<FieldsFragment>}>>;
		}
	>;
};

export type CreateViewsPageMutationVariables = Exact<{
	name: Scalars['String'];
	content: Scalars['String'];
}>;

export type CreateViewsPageMutation = {createViewsPage: Pick<ViewPage, 'id' | 'name'>};

export type CustomFormMutationVariables = Exact<{
	formId: Scalars['String'];
	values: Scalars['JSON'];
}>;

export type CustomFormMutation = Pick<Mutation, 'customForm'>;

export type DataSourceQueryVariables = Exact<{
	ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DataSourceQuery = {
	dataSource: Array<
		Pick<DataSource, 'id' | 'title' | 'description'> & {
			fields: Array<FieldsFragment>;
			availableColumns?: Maybe<
				Array<
					Pick<
						AvailableColumn,
						'id' | 'title' | 'order' | 'canSort' | 'defaultSort' | 'visible' | 'details' | 'canVisible'
					>
				>
			>;
		}
	>;
};

export type DeleteViewsPageMutationVariables = Exact<{
	id: Scalars['String'];
}>;

export type DeleteViewsPageMutation = Pick<Mutation, 'deleteViewsPage'>;

export type RefGraphPopoverValueQueryVariables = Exact<{
	refType: Scalars['String'];
	nodeFromId: Scalars['String'];
	nodeToId: Scalars['String'];
	currentHistoryDate?: Maybe<Scalars['String']>;
}>;

export type RefGraphPopoverValueQuery = Pick<Query, 'refGraphPopoverValue'>;

export type RefValuesQueryVariables = Exact<{
	input: Array<RefValuesInput> | RefValuesInput;
}>;

export type RefValuesQuery = {
	refValues: Array<
		Pick<RefValuesResult, 'refType' | 'field'> & {
			values: Array<Pick<PossibleValue, 'id' | 'title' | 'firstLine' | 'secondLine' | 'iconText'>>;
		}
	>;
};

export type UpdateViewsPageMutationVariables = Exact<{
	id: Scalars['String'];
	name: Scalars['String'];
	content: Scalars['String'];
}>;

export type UpdateViewsPageMutation = {updateViewsPage: Pick<ViewPage, 'id' | 'name' | 'content' | 'version'>};

export type ViewsPageQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type ViewsPageQuery = {viewsPage: Pick<ViewPage, 'id' | 'name' | 'content' | 'version'>};

export type AddConversationMessageMutationVariables = Exact<{
	conversationId: Scalars['String'];
	dataSourceId: Scalars['String'];
	itemId: Scalars['String'];
	fieldId: Scalars['String'];
	text: Scalars['String'];
}>;

export type AddConversationMessageMutation = {
	addConversationMessage: Pick<ConversationMessage, 'id' | 'authorName' | 'createdAt' | 'isService' | 'text'>;
};

export type ConversationsQueryVariables = Exact<{
	conversationId: Scalars['String'];
	dataSourceId: Scalars['String'];
	itemId: Scalars['String'];
	fieldId: Scalars['String'];
}>;

export type ConversationsQuery = {
	conversations: Array<
		Pick<ConversationMessage, 'id' | 'authorName' | 'createdAt' | 'isService' | 'text'> & {
			files: Array<Pick<ConversationFile, 'id' | 'size' | 'title' | 'path'>>;
		}
	>;
};

export const NodeOptionsFragmentDoc = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'NodeOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GraphNodeOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'rank'}},
					{kind: 'Field', name: {kind: 'Name', value: 'borderColor'}},
					{kind: 'Field', name: {kind: 'Name', value: 'borderThickness'}},
					{kind: 'Field', name: {kind: 'Name', value: 'style'}},
					{kind: 'Field', name: {kind: 'Name', value: 'image'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isImportant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'path'}},
					{kind: 'Field', name: {kind: 'Name', value: 'popoverContent'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export const EdgeOptionsFragmentDoc = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'EdgeOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GraphEdgeOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'arrows'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{kind: 'Field', name: {kind: 'Name', value: 'line'}},
					{kind: 'Field', name: {kind: 'Name', value: 'thickness'}},
					{kind: 'Field', name: {kind: 'Name', value: 'popoverContent'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export const LinkOptionsFragmentDoc = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export const GridActionsFragmentDoc = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridActions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridAction'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'icon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'details'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'headAction'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'itemAction'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isMassAction'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export const GridFormButtonsFragmentDoc = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridFormButtons'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridFormButton'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'icon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disableUnchanged'}},
					{kind: 'Field', name: {kind: 'Name', value: 'primary'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'action'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'required'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'disable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hidden'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export const GridFormsFragmentDoc = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridForms'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridForm'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'fields'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'buttons'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'GridFormButtons'}}],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridFormButtons'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridFormButton'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'icon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disableUnchanged'}},
					{kind: 'Field', name: {kind: 'Name', value: 'primary'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'action'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'required'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'disable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hidden'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export const FieldsFragmentDoc = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export const ColumnsFragmentDoc = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Columns'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TableColumn'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxShownItems'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'formatTemplate'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canCreate'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canEdit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'recipientTypeDictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'oldDictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryKeys'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryValues'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'httpLink'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'route'}},
								{kind: 'Field', name: {kind: 'Name', value: 'params'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryControl'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{kind: 'Field', name: {kind: 'Name', value: 'params'}},
								{kind: 'Field', name: {kind: 'Name', value: 'lazyLoading'}},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
										],
									},
								},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'selectorUrl'}},
					{kind: 'Field', name: {kind: 'Name', value: 'multiSelector'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'refType'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'refQueryMinLength'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export const AnomaliesByFilterDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AnomaliesByFilter'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AnomaliesByFilterInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'anomaliesByFilter'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'series'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'name'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'data'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'time'}},
														{kind: 'Field', name: {kind: 'Name', value: 'value'}},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AnomaliesByFilterQueryResult = Apollo.QueryResult<AnomaliesByFilterQuery, AnomaliesByFilterQueryVariables>;
export const AnomaliesBySourceDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AnomaliesBySource'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AnomaliesBySourceInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'anomaliesBySource'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'series'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'name'}},
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'data'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'time'}},
														{kind: 'Field', name: {kind: 'Name', value: 'value'}},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AnomaliesBySourceQueryResult = Apollo.QueryResult<AnomaliesBySourceQuery, AnomaliesBySourceQueryVariables>;
export const AnomaliesQuantityDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AnomaliesQuantity'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AnomaliesQuantityInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'anomaliesQuantity'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'series'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'name'}},
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'data'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'time'}},
														{kind: 'Field', name: {kind: 'Name', value: 'value'}},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AnomaliesQuantityQueryResult = Apollo.QueryResult<AnomaliesQuantityQuery, AnomaliesQuantityQueryVariables>;
export const AnomaliesModelNamesDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AnomaliesModelNames'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'anomaliesModelNames'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'opened'}},
								{kind: 'Field', name: {kind: 'Name', value: 'closed'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AnomaliesModelNamesQueryResult = Apollo.QueryResult<
	AnomaliesModelNamesQuery,
	AnomaliesModelNamesQueryVariables
>;
export const AsConnectionsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsConnections'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsConnectionsInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asConnections'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asFrom'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asTo'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'bandwidth'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilizationPercentage'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilizationPercentage'}},
								{kind: 'Field', name: {kind: 'Name', value: 'startAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'endAt'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsConnectionsQueryResult = Apollo.QueryResult<AsConnectionsQuery, AsConnectionsQueryVariables>;
export const AsCountByCountriesDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsCountByCountries'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'countries'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asCountByCountries'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'countries'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'countries'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'country'}},
								{kind: 'Field', name: {kind: 'Name', value: 'count'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsCountByCountriesQueryResult = Apollo.QueryResult<
	AsCountByCountriesQuery,
	AsCountByCountriesQueryVariables
>;
export const AsCountByRegistriesDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsCountByRegistries'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'registries'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asCountByRegistries'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'registries'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'registries'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
								{kind: 'Field', name: {kind: 'Name', value: 'count'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsCountByRegistriesQueryResult = Apollo.QueryResult<
	AsCountByRegistriesQuery,
	AsCountByRegistriesQueryVariables
>;
export const AsLinksDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsLinks'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsLinksInput'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asLinks'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asFrom'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asTo'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'bandwidth'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilizationPercentage'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilizationPercentage'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsLinksQueryResult = Apollo.QueryResult<AsLinksQuery, AsLinksQueryVariables>;
export const AsLinksAbroadDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsLinksAbroad'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsLinksAbroadInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asLinksAbroad'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asFrom'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asTo'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'bandwidth'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilizationPercentage'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilizationPercentage'}},
								{kind: 'Field', name: {kind: 'Name', value: 'startAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'endAt'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsLinksAbroadQueryResult = Apollo.QueryResult<AsLinksAbroadQuery, AsLinksAbroadQueryVariables>;
export const AsLinksAbroadCountDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsLinksAbroadCount'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsLinksAbroadCountInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asLinksAbroadCount'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'as'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'id'}},
														{kind: 'Field', name: {kind: 'Name', value: 'title'}},
														{kind: 'Field', name: {kind: 'Name', value: 'country'}},
														{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
														{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
														{kind: 'Field', name: {kind: 'Name', value: 'operatorAName'}},
														{kind: 'Field', name: {kind: 'Name', value: 'operatorAInn'}},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'count'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'other'}},
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsLinksAbroadCountQueryResult = Apollo.QueryResult<
	AsLinksAbroadCountQuery,
	AsLinksAbroadCountQueryVariables
>;
export const AsLinksCountDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsLinksCount'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsLinksCountInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asLinksCount'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'count'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'as'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'id'}},
														{kind: 'Field', name: {kind: 'Name', value: 'title'}},
														{kind: 'Field', name: {kind: 'Name', value: 'country'}},
														{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
														{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
														{kind: 'Field', name: {kind: 'Name', value: 'operatorAName'}},
														{kind: 'Field', name: {kind: 'Name', value: 'operatorAInn'}},
													],
												},
											},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'other'}},
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsLinksCountQueryResult = Apollo.QueryResult<AsLinksCountQuery, AsLinksCountQueryVariables>;
export const AsNetworkDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsNetwork'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsNetworkInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asNetwork'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asFrom'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asTo'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'bandwidth'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilizationPercentage'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilizationPercentage'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsNetworkQueryResult = Apollo.QueryResult<AsNetworkQuery, AsNetworkQueryVariables>;
export const AsNetworkHistoryDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsNetworkHistory'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsNetworkHistoryInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asNetworkHistory'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asFrom'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'asTo'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'country'}},
											{kind: 'Field', name: {kind: 'Name', value: 'registry'}},
											{kind: 'Field', name: {kind: 'Name', value: 'organization'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'bandwidth'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilization'}},
								{kind: 'Field', name: {kind: 'Name', value: 'inputUtilizationPercentage'}},
								{kind: 'Field', name: {kind: 'Name', value: 'outputUtilizationPercentage'}},
								{kind: 'Field', name: {kind: 'Name', value: 'startAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'endAt'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsNetworkHistoryQueryResult = Apollo.QueryResult<AsNetworkHistoryQuery, AsNetworkHistoryQueryVariables>;
export const AuthorizeByCodeDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'AuthorizeByCode'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'authorizeByCode'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'code'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AuthorizeByCodeMutationResult = Apollo.MutationResult<AuthorizeByCodeMutation>;
export type AuthorizeByCodeMutationOptions = Apollo.BaseMutationOptions<
	AuthorizeByCodeMutation,
	AuthorizeByCodeMutationVariables
>;
export const AuthorizeByThirdPartyTokenDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'AuthorizeByThirdPartyToken'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'authorizeByThirdPartyToken'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'token'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AuthorizeByThirdPartyTokenMutationResult = Apollo.MutationResult<AuthorizeByThirdPartyTokenMutation>;
export type AuthorizeByThirdPartyTokenMutationOptions = Apollo.BaseMutationOptions<
	AuthorizeByThirdPartyTokenMutation,
	AuthorizeByThirdPartyTokenMutationVariables
>;
export const LogoutDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'Logout'},
			selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'logout'}}]},
		},
	],
} as unknown) as DocumentNode;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RefreshDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'Refresh'},
			selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'refresh'}}]},
		},
	],
} as unknown) as DocumentNode;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<RefreshMutation, RefreshMutationVariables>;
export const UserDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'User'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'user'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'shortOrganizationName'}},
								{kind: 'Field', name: {kind: 'Name', value: 'shortUserName'}},
								{kind: 'Field', name: {kind: 'Name', value: 'tin'}},
								{kind: 'Field', name: {kind: 'Name', value: 'login'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
								{kind: 'Field', name: {kind: 'Name', value: 'permissions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'roles'}},
								{kind: 'Field', name: {kind: 'Name', value: 'refreshTimeout'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const ContextHelpDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ContextHelp'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'key'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'contextHelp'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'key'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'key'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ContextHelpQueryResult = Apollo.QueryResult<ContextHelpQuery, ContextHelpQueryVariables>;
export const InformationMessageDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'InformationMessage'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'key'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'informationMessage'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'key'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'key'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type InformationMessageQueryResult = Apollo.QueryResult<
	InformationMessageQuery,
	InformationMessageQueryVariables
>;
export const DataSourceDeleteDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DataSourceDelete'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'dataSourceDelete'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'ids'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DataSourceDeleteMutationResult = Apollo.MutationResult<DataSourceDeleteMutation>;
export type DataSourceDeleteMutationOptions = Apollo.BaseMutationOptions<
	DataSourceDeleteMutation,
	DataSourceDeleteMutationVariables
>;
export const DataSourceDeleteFileDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DataSourceDeleteFile'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'dataSourceDeleteFile'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DataSourceDeleteFileMutationResult = Apollo.MutationResult<DataSourceDeleteFileMutation>;
export type DataSourceDeleteFileMutationOptions = Apollo.BaseMutationOptions<
	DataSourceDeleteFileMutation,
	DataSourceDeleteFileMutationVariables
>;
export const DataSourceItemDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'DataSourceItem'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'dataSourceItem'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'item'}},
								{kind: 'Field', name: {kind: 'Name', value: 'options'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DataSourceItemQueryResult = Apollo.QueryResult<DataSourceItemQuery, DataSourceItemQueryVariables>;
export const DataSourceUpdateDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DataSourceUpdate'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'item'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'dataSourceUpdate'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'item'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'item'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'item'}},
								{kind: 'Field', name: {kind: 'Name', value: 'options'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DataSourceUpdateMutationResult = Apollo.MutationResult<DataSourceUpdateMutation>;
export type DataSourceUpdateMutationOptions = Apollo.BaseMutationOptions<
	DataSourceUpdateMutation,
	DataSourceUpdateMutationVariables
>;
export const DataSourceUploadChunkDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DataSourceUploadChunk'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'chunk'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'chunks'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'title'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'size'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'dataSourceUploadChunk'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'chunk'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'chunk'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'chunks'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'chunks'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'title'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'title'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'size'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'size'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'content'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'size'}},
								{kind: 'Field', name: {kind: 'Name', value: 'location'}},
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DataSourceUploadChunkMutationResult = Apollo.MutationResult<DataSourceUploadChunkMutation>;
export type DataSourceUploadChunkMutationOptions = Apollo.BaseMutationOptions<
	DataSourceUploadChunkMutation,
	DataSourceUploadChunkMutationVariables
>;
export const DiagramDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Diagram'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'diagram'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'filters'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'series'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'points'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'x'}},
														{kind: 'Field', name: {kind: 'Name', value: 'y'}},
														{kind: 'Field', name: {kind: 'Name', value: 'name'}},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'name'}},
											{kind: 'Field', name: {kind: 'Name', value: 'startPoint'}},
											{kind: 'Field', name: {kind: 'Name', value: 'pointInterval'}},
											{kind: 'Field', name: {kind: 'Name', value: 'baseLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'yMeasure'}},
											{kind: 'Field', name: {kind: 'Name', value: 'groupName'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DiagramQueryResult = Apollo.QueryResult<DiagramQuery, DiagramQueryVariables>;
export const GraphDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Graph'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'graph'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'filters'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'nodes'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'label'}},
											{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'options'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'NodeOptions'},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'ranges'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'startAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'endAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
														{kind: 'Field', name: {kind: 'Name', value: 'options'}},
														{kind: 'Field', name: {kind: 'Name', value: 'label'}},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'edges'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'from'}},
											{kind: 'Field', name: {kind: 'Name', value: 'to'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'ranges'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'startAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'endAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
														{kind: 'Field', name: {kind: 'Name', value: 'options'}},
														{kind: 'Field', name: {kind: 'Name', value: 'label'}},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'options'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'EdgeOptions'},
														},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'label'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'options'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'initDate'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasImportant'}},
											{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasHistory'}},
											{kind: 'Field', name: {kind: 'Name', value: 'historyDates'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasHistoryInitialMoment'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasHistoryPresentMoment'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasRank'}},
											{kind: 'Field', name: {kind: 'Name', value: 'maxRank'}},
											{kind: 'Field', name: {kind: 'Name', value: 'layersCardTitle'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'NodeOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GraphNodeOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'rank'}},
					{kind: 'Field', name: {kind: 'Name', value: 'borderColor'}},
					{kind: 'Field', name: {kind: 'Name', value: 'borderThickness'}},
					{kind: 'Field', name: {kind: 'Name', value: 'style'}},
					{kind: 'Field', name: {kind: 'Name', value: 'image'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isImportant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'path'}},
					{kind: 'Field', name: {kind: 'Name', value: 'popoverContent'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'EdgeOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GraphEdgeOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'arrows'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{kind: 'Field', name: {kind: 'Name', value: 'line'}},
					{kind: 'Field', name: {kind: 'Name', value: 'thickness'}},
					{kind: 'Field', name: {kind: 'Name', value: 'popoverContent'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GraphQueryResult = Apollo.QueryResult<GraphQuery, GraphQueryVariables>;
export const GraphGridDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GraphGrid'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'graphGrid'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'filters'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'nodes'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'label'}},
											{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'options'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'NodeOptions'},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'ranges'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'startAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'endAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
														{kind: 'Field', name: {kind: 'Name', value: 'options'}},
														{kind: 'Field', name: {kind: 'Name', value: 'label'}},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'edges'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'from'}},
											{kind: 'Field', name: {kind: 'Name', value: 'to'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'ranges'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'startAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'endAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
														{kind: 'Field', name: {kind: 'Name', value: 'options'}},
														{kind: 'Field', name: {kind: 'Name', value: 'label'}},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'options'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'EdgeOptions'},
														},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'label'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'options'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'initDate'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasImportant'}},
											{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasHistory'}},
											{kind: 'Field', name: {kind: 'Name', value: 'historyDates'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasHistoryInitialMoment'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasHistoryPresentMoment'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasRank'}},
											{kind: 'Field', name: {kind: 'Name', value: 'maxRank'}},
											{kind: 'Field', name: {kind: 'Name', value: 'layersCardTitle'}},
											{kind: 'Field', name: {kind: 'Name', value: 'total'}},
											{kind: 'Field', name: {kind: 'Name', value: 'defaultNumItems'}},
											{kind: 'Field', name: {kind: 'Name', value: 'collapsedGroups'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'actions'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'GridActions'},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'highlightOptions'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'edge'},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {kind: 'Name', value: 'EdgeOptions'},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'node'},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {kind: 'Name', value: 'NodeOptions'},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'tableItems'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'NodeOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GraphNodeOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'rank'}},
					{kind: 'Field', name: {kind: 'Name', value: 'borderColor'}},
					{kind: 'Field', name: {kind: 'Name', value: 'borderThickness'}},
					{kind: 'Field', name: {kind: 'Name', value: 'style'}},
					{kind: 'Field', name: {kind: 'Name', value: 'image'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isImportant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'path'}},
					{kind: 'Field', name: {kind: 'Name', value: 'popoverContent'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'EdgeOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GraphEdgeOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'arrows'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{kind: 'Field', name: {kind: 'Name', value: 'line'}},
					{kind: 'Field', name: {kind: 'Name', value: 'thickness'}},
					{kind: 'Field', name: {kind: 'Name', value: 'popoverContent'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridActions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridAction'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'icon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'details'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'headAction'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'itemAction'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isMassAction'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GraphGridQueryResult = Apollo.QueryResult<GraphGridQuery, GraphGridQueryVariables>;
export const GraphTimeLineGridDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GraphTimeLineGrid'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'graphTimeLineGrid'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'filters'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'nodes'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'label'}},
											{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'options'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'NodeOptions'},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'ranges'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'startAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'endAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
														{kind: 'Field', name: {kind: 'Name', value: 'options'}},
														{kind: 'Field', name: {kind: 'Name', value: 'label'}},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'edges'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'from'}},
											{kind: 'Field', name: {kind: 'Name', value: 'to'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'ranges'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'startAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'endAt'}},
														{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
														{kind: 'Field', name: {kind: 'Name', value: 'options'}},
														{kind: 'Field', name: {kind: 'Name', value: 'label'}},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'options'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'EdgeOptions'},
														},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'label'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'options'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'initDate'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasImportant'}},
											{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasHistory'}},
											{kind: 'Field', name: {kind: 'Name', value: 'historyDates'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasHistoryInitialMoment'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasHistoryPresentMoment'}},
											{kind: 'Field', name: {kind: 'Name', value: 'hasRank'}},
											{kind: 'Field', name: {kind: 'Name', value: 'maxRank'}},
											{kind: 'Field', name: {kind: 'Name', value: 'layersCardTitle'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'highlightOptions'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'edge'},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {kind: 'Name', value: 'EdgeOptions'},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'node'},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {kind: 'Name', value: 'NodeOptions'},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'itemsByTime'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'time'}},
											{kind: 'Field', name: {kind: 'Name', value: 'items'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'NodeOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GraphNodeOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'layers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'rank'}},
					{kind: 'Field', name: {kind: 'Name', value: 'borderColor'}},
					{kind: 'Field', name: {kind: 'Name', value: 'borderThickness'}},
					{kind: 'Field', name: {kind: 'Name', value: 'style'}},
					{kind: 'Field', name: {kind: 'Name', value: 'image'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isImportant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'path'}},
					{kind: 'Field', name: {kind: 'Name', value: 'popoverContent'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'EdgeOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GraphEdgeOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'arrows'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{kind: 'Field', name: {kind: 'Name', value: 'line'}},
					{kind: 'Field', name: {kind: 'Name', value: 'thickness'}},
					{kind: 'Field', name: {kind: 'Name', value: 'popoverContent'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GraphTimeLineGridQueryResult = Apollo.QueryResult<GraphTimeLineGridQuery, GraphTimeLineGridQueryVariables>;
export const GridDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Grid'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'grid'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'filters'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'fields'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'options'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'total'}},
											{kind: 'Field', name: {kind: 'Name', value: 'defaultNumItems'}},
											{kind: 'Field', name: {kind: 'Name', value: 'selectable'}},
											{kind: 'Field', name: {kind: 'Name', value: 'searchable'}},
											{kind: 'Field', name: {kind: 'Name', value: 'minLengthSearchQuery'}},
											{kind: 'Field', name: {kind: 'Name', value: 'collapsedGroups'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'actions'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'GridActions'},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'forms'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'GridForms'},
														},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'custom'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'items'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridActions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridAction'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'icon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'details'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'headAction'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'itemAction'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isMassAction'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridForms'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridForm'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'fields'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'buttons'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'GridFormButtons'}}],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridFormButtons'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridFormButton'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'icon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disableUnchanged'}},
					{kind: 'Field', name: {kind: 'Name', value: 'primary'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'action'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'required'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'disable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hidden'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GridQueryResult = Apollo.QueryResult<GridQuery, GridQueryVariables>;
export const GridActionDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'GridAction'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'action'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'gridAction'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'action'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'action'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'ids'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'options'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'total'}},
											{kind: 'Field', name: {kind: 'Name', value: 'defaultNumItems'}},
											{kind: 'Field', name: {kind: 'Name', value: 'selectable'}},
											{kind: 'Field', name: {kind: 'Name', value: 'searchable'}},
											{kind: 'Field', name: {kind: 'Name', value: 'minLengthSearchQuery'}},
											{kind: 'Field', name: {kind: 'Name', value: 'collapsedGroups'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'actions'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'GridActions'},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'forms'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'GridForms'},
														},
													],
												},
											},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'items'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridActions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridAction'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'icon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'details'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'headAction'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'itemAction'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isMassAction'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridForms'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridForm'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'fields'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'buttons'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'GridFormButtons'}}],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'GridFormButtons'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GridFormButton'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'icon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disableUnchanged'}},
					{kind: 'Field', name: {kind: 'Name', value: 'primary'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'action'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'required'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'disable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hidden'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GridActionMutationResult = Apollo.MutationResult<GridActionMutation>;
export type GridActionMutationOptions = Apollo.BaseMutationOptions<GridActionMutation, GridActionMutationVariables>;
export const GridDiagramDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GridDiagram'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'gridDiagram'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'filters'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'series'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'points'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'x'}},
														{kind: 'Field', name: {kind: 'Name', value: 'y'}},
														{kind: 'Field', name: {kind: 'Name', value: 'name'}},
														{kind: 'Field', name: {kind: 'Name', value: 'tableItem'}},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'name'}},
											{kind: 'Field', name: {kind: 'Name', value: 'startPoint'}},
											{kind: 'Field', name: {kind: 'Name', value: 'pointInterval'}},
											{kind: 'Field', name: {kind: 'Name', value: 'baseLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'yMeasure'}},
											{kind: 'Field', name: {kind: 'Name', value: 'groupName'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mergeId'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'options'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GridDiagramQueryResult = Apollo.QueryResult<GridDiagramQuery, GridDiagramQueryVariables>;
export const GridFormDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'GridForm'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'formId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'selectedIds'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
							},
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'item'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'gridForm'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'formId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'formId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'selectedIds'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'selectedIds'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'item'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'item'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'item'}},
								{kind: 'Field', name: {kind: 'Name', value: 'options'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'color'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GridFormMutationResult = Apollo.MutationResult<GridFormMutation>;
export type GridFormMutationOptions = Apollo.BaseMutationOptions<GridFormMutation, GridFormMutationVariables>;
export const RefDataDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'RefData'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'searchQuery'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dependencyFilters'}},
					type: {
						kind: 'ListType',
						type: {
							kind: 'NonNullType',
							type: {kind: 'NamedType', name: {kind: 'Name', value: 'DependencyFilter'}},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'refData'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'searchQuery'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'searchQuery'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'type'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dependencyFilters'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dependencyFilters'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type RefDataQueryResult = Apollo.QueryResult<RefDataQuery, RefDataQueryVariables>;
export const RelationDataDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'RelationData'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dependencyFilters'}},
					type: {
						kind: 'ListType',
						type: {
							kind: 'NonNullType',
							type: {kind: 'NamedType', name: {kind: 'Name', value: 'DependencyFilter'}},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relationData'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'model'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'filters'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'filters'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dependencyFilters'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dependencyFilters'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'fields'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'options'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'total'}},
											{kind: 'Field', name: {kind: 'Name', value: 'defaultNumItems'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'items'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type RelationDataQueryResult = Apollo.QueryResult<RelationDataQuery, RelationDataQueryVariables>;
export const DictionaryDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Dictionary'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'keys'}},
					type: {
						kind: 'ListType',
						type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'suggest'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'dictionary'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'keys'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'keys'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'suggest'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'suggest'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DictionaryQueryResult = Apollo.QueryResult<DictionaryQuery, DictionaryQueryVariables>;
export const CompanyDictionaryDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'CompanyDictionary'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'suggest'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'companyDictionary'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'suggest'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'suggest'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type CompanyDictionaryQueryResult = Apollo.QueryResult<CompanyDictionaryQuery, CompanyDictionaryQueryVariables>;
export const CreateFileDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateFile'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'FileInput'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createFile'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type CreateFileMutationResult = Apollo.MutationResult<CreateFileMutation>;
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<CreateFileMutation, CreateFileMutationVariables>;
export const DeleteBatchDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DeleteBatch'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DeleteBatchInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'deleteBatch'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DeleteBatchMutationResult = Apollo.MutationResult<DeleteBatchMutation>;
export type DeleteBatchMutationOptions = Apollo.BaseMutationOptions<DeleteBatchMutation, DeleteBatchMutationVariables>;
export const FileSectionsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'FileSections'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'fileSections'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'description'}},
								{kind: 'Field', name: {kind: 'Name', value: 'enabled'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type FileSectionsQueryResult = Apollo.QueryResult<FileSectionsQuery, FileSectionsQueryVariables>;
export const FileStatusDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'FileStatus'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'FileStatusInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'fileStatus'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'status'}},
								{kind: 'Field', name: {kind: 'Name', value: 'size'}},
								{kind: 'Field', name: {kind: 'Name', value: 'fileName'}},
								{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'uploadedAt'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type FileStatusQueryResult = Apollo.QueryResult<FileStatusQuery, FileStatusQueryVariables>;
export const FilesListDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'FilesList'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'FileListInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'filesList'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regNumber'}},
								{kind: 'Field', name: {kind: 'Name', value: 'fileName'}},
								{kind: 'Field', name: {kind: 'Name', value: 'submittedAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'fileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'status'}},
								{kind: 'Field', name: {kind: 'Name', value: 'statusMessage'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type FilesListQueryResult = Apollo.QueryResult<FilesListQuery, FilesListQueryVariables>;
export const HelpFilesListDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'HelpFilesList'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'HelpFileListInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'helpFilesList'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'sectionId'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type HelpFilesListQueryResult = Apollo.QueryResult<HelpFilesListQuery, HelpFilesListQueryVariables>;
export const PublishFileDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'PublishFile'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'PublishFileInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'publishFile'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'status'}},
								{kind: 'Field', name: {kind: 'Name', value: 'publishAt'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PublishFileMutationResult = Apollo.MutationResult<PublishFileMutation>;
export type PublishFileMutationOptions = Apollo.BaseMutationOptions<PublishFileMutation, PublishFileMutationVariables>;
export const UploadChunkDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'UploadChunk'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'UploadChunkInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'uploadChunk'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type UploadChunkMutationResult = Apollo.MutationResult<UploadChunkMutation>;
export type UploadChunkMutationOptions = Apollo.BaseMutationOptions<UploadChunkMutation, UploadChunkMutationVariables>;
export const AssignCorrelatorDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'AssignCorrelator'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'correlatorId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'configurationId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'assignCorrelator'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'correlatorId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'correlatorId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'configurationId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'configurationId'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AssignCorrelatorMutationResult = Apollo.MutationResult<AssignCorrelatorMutation>;
export type AssignCorrelatorMutationOptions = Apollo.BaseMutationOptions<
	AssignCorrelatorMutation,
	AssignCorrelatorMutationVariables
>;
export const WithdrawCorrelatorDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'WithdrawCorrelator'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'correlatorId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'configurationId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'withdrawCorrelator'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'correlatorId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'correlatorId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'configurationId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'configurationId'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type WithdrawCorrelatorMutationResult = Apollo.MutationResult<WithdrawCorrelatorMutation>;
export type WithdrawCorrelatorMutationOptions = Apollo.BaseMutationOptions<
	WithdrawCorrelatorMutation,
	WithdrawCorrelatorMutationVariables
>;
export const GetConfigurationGroupDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetConfigurationGroup'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getConfigurationGroup'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GetConfigurationGroupQueryResult = Apollo.QueryResult<
	GetConfigurationGroupQuery,
	GetConfigurationGroupQueryVariables
>;
export const GetScriptDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetScript'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'modelName'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'scriptId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'versionId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getScript'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'modelName'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'modelName'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'scriptId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'scriptId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'versionId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'versionId'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
								{kind: 'Field', name: {kind: 'Name', value: 'version'}},
								{kind: 'Field', name: {kind: 'Name', value: 'content'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GetScriptQueryResult = Apollo.QueryResult<GetScriptQuery, GetScriptQueryVariables>;
export const GetScriptHistoryDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetScriptHistory'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'modelName'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'scriptId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getScriptHistory'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'modelName'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'modelName'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'scriptId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'scriptId'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
								{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GetScriptHistoryQueryResult = Apollo.QueryResult<GetScriptHistoryQuery, GetScriptHistoryQueryVariables>;
export const GetScriptsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetScripts'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'modelName'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'variables'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getScripts'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'modelName'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'modelName'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'variables'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'variables'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GetScriptsQueryResult = Apollo.QueryResult<GetScriptsQuery, GetScriptsQueryVariables>;
export const SaveScriptDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'SaveScript'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'SaveScriptInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'saveScript'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'problem'}},
								{kind: 'Field', name: {kind: 'Name', value: 'isValid'}},
								{kind: 'Field', name: {kind: 'Name', value: 'newVersionId'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type SaveScriptMutationResult = Apollo.MutationResult<SaveScriptMutation>;
export type SaveScriptMutationOptions = Apollo.BaseMutationOptions<SaveScriptMutation, SaveScriptMutationVariables>;
export const ValidateScriptDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'ValidateScript'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'modelName'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validateScript'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'modelName'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'modelName'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'content'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'problem'}},
								{kind: 'Field', name: {kind: 'Name', value: 'isValid'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ValidateScriptMutationResult = Apollo.MutationResult<ValidateScriptMutation>;
export type ValidateScriptMutationOptions = Apollo.BaseMutationOptions<
	ValidateScriptMutation,
	ValidateScriptMutationVariables
>;
export const AssignAdapterDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'AssignAdapter'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'adapterId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'scriptId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'assignAdapter'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'scriptId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'scriptId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'adapterId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'adapterId'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AssignAdapterMutationResult = Apollo.MutationResult<AssignAdapterMutation>;
export type AssignAdapterMutationOptions = Apollo.BaseMutationOptions<
	AssignAdapterMutation,
	AssignAdapterMutationVariables
>;
export const WithdrawAdapterDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'WithdrawAdapter'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'adapterId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'scriptId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'withdrawAdapter'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'scriptId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'scriptId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'adapterId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'adapterId'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type WithdrawAdapterMutationResult = Apollo.MutationResult<WithdrawAdapterMutation>;
export type WithdrawAdapterMutationOptions = Apollo.BaseMutationOptions<
	WithdrawAdapterMutation,
	WithdrawAdapterMutationVariables
>;
export const TableFormSelectorItemsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'TableFormSelectorItems'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'url'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'tableFormSelectorItems'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'url'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'url'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type TableFormSelectorItemsQueryResult = Apollo.QueryResult<
	TableFormSelectorItemsQuery,
	TableFormSelectorItemsQueryVariables
>;
export const CreateModelDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateModel'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'items'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}}},
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
					type: {
						kind: 'ListType',
						type: {
							kind: 'NonNullType',
							type: {kind: 'NamedType', name: {kind: 'Name', value: 'WithRelation'}},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createModel'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'model'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'items'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'items'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'withRelations'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type CreateModelMutationResult = Apollo.MutationResult<CreateModelMutation>;
export type CreateModelMutationOptions = Apollo.BaseMutationOptions<CreateModelMutation, CreateModelMutationVariables>;
export const DeleteModelDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DeleteModel'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'deleteModel'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'model'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'ids'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DeleteModelMutationResult = Apollo.MutationResult<DeleteModelMutation>;
export type DeleteModelMutationOptions = Apollo.BaseMutationOptions<DeleteModelMutation, DeleteModelMutationVariables>;
export const ModelItemDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ModelItem'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
					type: {
						kind: 'ListType',
						type: {
							kind: 'NonNullType',
							type: {kind: 'NamedType', name: {kind: 'Name', value: 'WithRelation'}},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'modelItem'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'model'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'withRelations'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'item'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'columns'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Columns'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'columns'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'Columns'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'columns'},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {kind: 'Name', value: 'Columns'},
																	},
																	{
																		kind: 'Field',
																		name: {kind: 'Name', value: 'columns'},
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'FragmentSpread',
																					name: {
																						kind: 'Name',
																						value: 'Columns',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'columns',
																					},
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'FragmentSpread',
																								name: {
																									kind: 'Name',
																									value: 'Columns',
																								},
																							},
																							{
																								kind: 'Field',
																								name: {
																									kind: 'Name',
																									value: 'columns',
																								},
																								selectionSet: {
																									kind:
																										'SelectionSet',
																									selections: [
																										{
																											kind:
																												'FragmentSpread',
																											name: {
																												kind:
																													'Name',
																												value:
																													'Columns',
																											},
																										},
																									],
																								},
																							},
																						],
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'meta'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'Field', name: {kind: 'Name', value: 'total'}}],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Columns'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TableColumn'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxShownItems'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'formatTemplate'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canCreate'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canEdit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'recipientTypeDictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'oldDictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryKeys'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryValues'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'httpLink'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'route'}},
								{kind: 'Field', name: {kind: 'Name', value: 'params'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryControl'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{kind: 'Field', name: {kind: 'Name', value: 'params'}},
								{kind: 'Field', name: {kind: 'Name', value: 'lazyLoading'}},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
										],
									},
								},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'selectorUrl'}},
					{kind: 'Field', name: {kind: 'Name', value: 'multiSelector'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'refType'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'refQueryMinLength'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ModelItemQueryResult = Apollo.QueryResult<ModelItemQuery, ModelItemQueryVariables>;
export const ModelItemResolveDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ModelItemResolve'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'item'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
					type: {
						kind: 'ListType',
						type: {
							kind: 'NonNullType',
							type: {kind: 'NamedType', name: {kind: 'Name', value: 'WithRelation'}},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'modelItemResolve'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'model'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'item'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'item'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'withRelations'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'item'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'columns'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Columns'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'columns'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'Columns'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'columns'},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {kind: 'Name', value: 'Columns'},
																	},
																	{
																		kind: 'Field',
																		name: {kind: 'Name', value: 'columns'},
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'FragmentSpread',
																					name: {
																						kind: 'Name',
																						value: 'Columns',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'columns',
																					},
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'FragmentSpread',
																								name: {
																									kind: 'Name',
																									value: 'Columns',
																								},
																							},
																							{
																								kind: 'Field',
																								name: {
																									kind: 'Name',
																									value: 'columns',
																								},
																								selectionSet: {
																									kind:
																										'SelectionSet',
																									selections: [
																										{
																											kind:
																												'FragmentSpread',
																											name: {
																												kind:
																													'Name',
																												value:
																													'Columns',
																											},
																										},
																									],
																								},
																							},
																						],
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'meta'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'Field', name: {kind: 'Name', value: 'total'}}],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Columns'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TableColumn'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxShownItems'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'formatTemplate'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canCreate'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canEdit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'recipientTypeDictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'oldDictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryKeys'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryValues'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'httpLink'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'route'}},
								{kind: 'Field', name: {kind: 'Name', value: 'params'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryControl'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{kind: 'Field', name: {kind: 'Name', value: 'params'}},
								{kind: 'Field', name: {kind: 'Name', value: 'lazyLoading'}},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
										],
									},
								},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'selectorUrl'}},
					{kind: 'Field', name: {kind: 'Name', value: 'multiSelector'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'refType'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'refQueryMinLength'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ModelItemResolveQueryResult = Apollo.QueryResult<ModelItemResolveQuery, ModelItemResolveQueryVariables>;
export const ModelItemsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ModelItems'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'params'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'ModelInput'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'variables'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
					type: {
						kind: 'ListType',
						type: {
							kind: 'NonNullType',
							type: {kind: 'NamedType', name: {kind: 'Name', value: 'WithRelation'}},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'modelItems'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'model'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'params'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'params'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'variables'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'variables'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'withRelations'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'items'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'columns'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Columns'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'columns'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'Columns'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'columns'},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {kind: 'Name', value: 'Columns'},
																	},
																	{
																		kind: 'Field',
																		name: {kind: 'Name', value: 'columns'},
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'FragmentSpread',
																					name: {
																						kind: 'Name',
																						value: 'Columns',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'columns',
																					},
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'FragmentSpread',
																								name: {
																									kind: 'Name',
																									value: 'Columns',
																								},
																							},
																							{
																								kind: 'Field',
																								name: {
																									kind: 'Name',
																									value: 'columns',
																								},
																								selectionSet: {
																									kind:
																										'SelectionSet',
																									selections: [
																										{
																											kind:
																												'FragmentSpread',
																											name: {
																												kind:
																													'Name',
																												value:
																													'Columns',
																											},
																										},
																									],
																								},
																							},
																						],
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'meta'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'Field', name: {kind: 'Name', value: 'total'}}],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Columns'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TableColumn'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxShownItems'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'formatTemplate'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canCreate'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canEdit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'recipientTypeDictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'oldDictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryKeys'}},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryValues'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'httpLink'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'route'}},
								{kind: 'Field', name: {kind: 'Name', value: 'params'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'dictionaryControl'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{kind: 'Field', name: {kind: 'Name', value: 'params'}},
								{kind: 'Field', name: {kind: 'Name', value: 'lazyLoading'}},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
										],
									},
								},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'selectorUrl'}},
					{kind: 'Field', name: {kind: 'Name', value: 'multiSelector'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'refType'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'refQueryMinLength'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ModelItemsQueryResult = Apollo.QueryResult<ModelItemsQuery, ModelItemsQueryVariables>;
export const ToggleModelItemDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'ToggleModelItem'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'ToggleModelItemInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'toggleModelItem'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'enabled'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ToggleModelItemMutationResult = Apollo.MutationResult<ToggleModelItemMutation>;
export type ToggleModelItemMutationOptions = Apollo.BaseMutationOptions<
	ToggleModelItemMutation,
	ToggleModelItemMutationVariables
>;
export const UpdateModelDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'UpdateModel'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'items'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}}},
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
					type: {
						kind: 'ListType',
						type: {
							kind: 'NonNullType',
							type: {kind: 'NamedType', name: {kind: 'Name', value: 'WithRelation'}},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'updateModel'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'model'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'items'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'items'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'withRelations'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'withRelations'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type UpdateModelMutationResult = Apollo.MutationResult<UpdateModelMutation>;
export type UpdateModelMutationOptions = Apollo.BaseMutationOptions<UpdateModelMutation, UpdateModelMutationVariables>;
export const AsTrafficDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsTraffic'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsTrafficInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asTraffic'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'key'}},
											{kind: 'Field', name: {kind: 'Name', value: 'bytes'}},
											{kind: 'Field', name: {kind: 'Name', value: 'asAId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'asAName'}},
											{kind: 'Field', name: {kind: 'Name', value: 'asAIdName'}},
											{kind: 'Field', name: {kind: 'Name', value: 'operatorAInn'}},
											{kind: 'Field', name: {kind: 'Name', value: 'operatorAName'}},
											{kind: 'Field', name: {kind: 'Name', value: 'asBId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'asBName'}},
											{kind: 'Field', name: {kind: 'Name', value: 'asBIdName'}},
											{kind: 'Field', name: {kind: 'Name', value: 'operatorBName'}},
											{kind: 'Field', name: {kind: 'Name', value: 'operatorBInn'}},
											{kind: 'Field', name: {kind: 'Name', value: 'operatorAId'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'other'}},
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsTrafficQueryResult = Apollo.QueryResult<AsTrafficQuery, AsTrafficQueryVariables>;
export const FlowoperatorDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Flowoperator'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'FlowoperatorInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'flowoperator'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'orgInn'}},
								{kind: 'Field', name: {kind: 'Name', value: 'orgShortName'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type FlowoperatorQueryResult = Apollo.QueryResult<FlowoperatorQuery, FlowoperatorQueryVariables>;
export const AsTrafficSankeyDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsTrafficSankey'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsTrafficSankeyInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asTrafficSankey'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'nodes'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'links'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'source'}},
											{kind: 'Field', name: {kind: 'Name', value: 'target'}},
											{kind: 'Field', name: {kind: 'Name', value: 'value'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsTrafficSankeyQueryResult = Apollo.QueryResult<AsTrafficSankeyQuery, AsTrafficSankeyQueryVariables>;
export const AsTrafficByTimeDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AsTrafficByTime'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AsTrafficByTimeInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'asTrafficByTime'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'bytes'}},
								{kind: 'Field', name: {kind: 'Name', value: 'packets'}},
								{kind: 'Field', name: {kind: 'Name', value: 'flows'}},
								{kind: 'Field', name: {kind: 'Name', value: 'asAId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'asBId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'protocol'}},
								{kind: 'Field', name: {kind: 'Name', value: 'ipA'}},
								{kind: 'Field', name: {kind: 'Name', value: 'ipB'}},
								{kind: 'Field', name: {kind: 'Name', value: 'portA'}},
								{kind: 'Field', name: {kind: 'Name', value: 'portB'}},
								{kind: 'Field', name: {kind: 'Name', value: 'directions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'time'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AsTrafficByTimeQueryResult = Apollo.QueryResult<AsTrafficByTimeQuery, AsTrafficByTimeQueryVariables>;
export const NewUnreadNotificationsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'NewUnreadNotifications'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'afterId'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'newUnreadNotifications'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'afterId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'afterId'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'message'}},
								{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'checked'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type NewUnreadNotificationsQueryResult = Apollo.QueryResult<
	NewUnreadNotificationsQuery,
	NewUnreadNotificationsQueryVariables
>;
export const NotificationListDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'NotificationList'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'checked'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'before'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'notificationList'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'checked'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'checked'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'before'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'before'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'notifications'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
											{kind: 'Field', name: {kind: 'Name', value: 'message'}},
											{kind: 'Field', name: {kind: 'Name', value: 'checked'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'hasNext'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type NotificationListQueryResult = Apollo.QueryResult<NotificationListQuery, NotificationListQueryVariables>;
export const NotificationsCountDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'NotificationsCount'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{kind: 'Field', name: {kind: 'Name', value: 'notificationsCount'}}],
			},
		},
	],
} as unknown) as DocumentNode;
export type NotificationsCountQueryResult = Apollo.QueryResult<
	NotificationsCountQuery,
	NotificationsCountQueryVariables
>;
export const ReadNotificationsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'ReadNotifications'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'notificationsIds'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'readNotifications'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'notificationsIds'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'notificationsIds'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'message'}},
								{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'checked'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ReadNotificationsMutationResult = Apollo.MutationResult<ReadNotificationsMutation>;
export type ReadNotificationsMutationOptions = Apollo.BaseMutationOptions<
	ReadNotificationsMutation,
	ReadNotificationsMutationVariables
>;
export const OldDictionaryDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'OldDictionary'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'oldDictionary'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type OldDictionaryQueryResult = Apollo.QueryResult<OldDictionaryQuery, OldDictionaryQueryVariables>;
export const OuterLinksDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'OuterLinks'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'OuterLinksInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'outerLinks'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'link'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type OuterLinksQueryResult = Apollo.QueryResult<OuterLinksQuery, OuterLinksQueryVariables>;
export const PirsAttachmentDeleteDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'PirsAttachmentDelete'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'attachmentId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsAttachmentDelete'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'attachmentId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'attachmentId'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsAttachmentDeleteMutationResult = Apollo.MutationResult<PirsAttachmentDeleteMutation>;
export type PirsAttachmentDeleteMutationOptions = Apollo.BaseMutationOptions<
	PirsAttachmentDeleteMutation,
	PirsAttachmentDeleteMutationVariables
>;
export const PirsAttachmentsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PirsAttachments'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AttachmentsInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsAttachments'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'columns'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'name'}},
											{kind: 'Field', name: {kind: 'Name', value: 'order'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fileId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'fileName'}},
											{kind: 'Field', name: {kind: 'Name', value: 'fileSize'}},
											{kind: 'Field', name: {kind: 'Name', value: 'uploadAt'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsAttachmentsQueryResult = Apollo.QueryResult<PirsAttachmentsQuery, PirsAttachmentsQueryVariables>;
export const PirsDeleteDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'PirsDelete'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsDelete'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'type'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsDeleteMutationResult = Apollo.MutationResult<PirsDeleteMutation>;
export type PirsDeleteMutationOptions = Apollo.BaseMutationOptions<PirsDeleteMutation, PirsDeleteMutationVariables>;
export const PirsCloseSessionDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'PirsCloseSession'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{kind: 'Field', name: {kind: 'Name', value: 'pirsCloseSession'}}],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsCloseSessionMutationResult = Apollo.MutationResult<PirsCloseSessionMutation>;
export type PirsCloseSessionMutationOptions = Apollo.BaseMutationOptions<
	PirsCloseSessionMutation,
	PirsCloseSessionMutationVariables
>;
export const PirsCreateDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'PirsCreate'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'doc'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsCreate'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'type'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'doc'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'doc'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'InlineFragment',
									typeCondition: {
										kind: 'NamedType',
										name: {kind: 'Name', value: 'PirsShortInstanceResult'},
									},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'lastDocChangeInstant'}},
											{kind: 'Field', name: {kind: 'Name', value: 'instanceVersion'}},
										],
									},
								},
								{
									kind: 'InlineFragment',
									typeCondition: {
										kind: 'NamedType',
										name: {kind: 'Name', value: 'PirsShortInstanceErrors'},
									},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'errors'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'userFriendlyMessage'},
														},
														{kind: 'Field', name: {kind: 'Name', value: 'path'}},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsCreateMutationResult = Apollo.MutationResult<PirsCreateMutation>;
export type PirsCreateMutationOptions = Apollo.BaseMutationOptions<PirsCreateMutation, PirsCreateMutationVariables>;
export const PirsGetSessionDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PirsGetSession'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{kind: 'Field', name: {kind: 'Name', value: 'pirsGetSession'}}],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsGetSessionQueryResult = Apollo.QueryResult<PirsGetSessionQuery, PirsGetSessionQueryVariables>;
export const PirsInstanceDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PirsInstance'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsInstance'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'type'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsInstanceQueryResult = Apollo.QueryResult<PirsInstanceQuery, PirsInstanceQueryVariables>;
export const PirsInstanceDetailsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PirsInstanceDetails'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'attributePath'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsInstanceDetails'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'type'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'attributePath'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'attributePath'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsInstanceDetailsQueryResult = Apollo.QueryResult<
	PirsInstanceDetailsQuery,
	PirsInstanceDetailsQueryVariables
>;
export const PirsListDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PirsList'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'PirsListInput'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsList'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'headers'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'attributePath'}},
											{kind: 'Field', name: {kind: 'Name', value: 'caption'}},
											{kind: 'Field', name: {kind: 'Name', value: 'rowspan'}},
											{kind: 'Field', name: {kind: 'Name', value: 'colspan'}},
											{kind: 'Field', name: {kind: 'Name', value: 'isHeader'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'values'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'Field', name: {kind: 'Name', value: 'attributePath'}},
														{kind: 'Field', name: {kind: 'Name', value: 'value'}},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'matches'},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {kind: 'Name', value: 'start'},
																	},
																	{kind: 'Field', name: {kind: 'Name', value: 'end'}},
																],
															},
														},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'reconciliationStatus'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
								{kind: 'Field', name: {kind: 'Name', value: 'canDelete'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsListQueryResult = Apollo.QueryResult<PirsListQuery, PirsListQueryVariables>;
export const PirsRefTypeSearchDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PirsRefTypeSearch'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'queryText'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsRefTypeSearch'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'type'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'queryText'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'queryText'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsRefTypeSearchQueryResult = Apollo.QueryResult<PirsRefTypeSearchQuery, PirsRefTypeSearchQueryVariables>;
export const PirsSidebarDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PirsSidebar'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsSidebar'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'orders'}},
								{kind: 'Field', name: {kind: 'Name', value: 'ordersName'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsSidebarQueryResult = Apollo.QueryResult<PirsSidebarQuery, PirsSidebarQueryVariables>;
export const PirsUpdateDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'PirsUpdate'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'instanceVersion'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'doc'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'pirsUpdate'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'type'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'instanceVersion'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'instanceVersion'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'doc'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'doc'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'InlineFragment',
									typeCondition: {
										kind: 'NamedType',
										name: {kind: 'Name', value: 'PirsShortInstanceResult'},
									},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'lastDocChangeInstant'}},
											{kind: 'Field', name: {kind: 'Name', value: 'instanceVersion'}},
										],
									},
								},
								{
									kind: 'InlineFragment',
									typeCondition: {
										kind: 'NamedType',
										name: {kind: 'Name', value: 'PirsShortInstanceErrors'},
									},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'errors'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'userFriendlyMessage'},
														},
														{kind: 'Field', name: {kind: 'Name', value: 'path'}},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type PirsUpdateMutationResult = Apollo.MutationResult<PirsUpdateMutation>;
export type PirsUpdateMutationOptions = Apollo.BaseMutationOptions<PirsUpdateMutation, PirsUpdateMutationVariables>;
export const RecipientDictionaryDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'RecipientDictionary'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'param'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'suggest'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'recipientDictionary'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'type'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'param'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'param'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'suggest'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'suggest'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'value'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type RecipientDictionaryQueryResult = Apollo.QueryResult<
	RecipientDictionaryQuery,
	RecipientDictionaryQueryVariables
>;
export const RecipientGroupDataDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'RecipientGroupData'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'recipientGroupData'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'params'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'key'}},
											{kind: 'Field', name: {kind: 'Name', value: 'name'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type RecipientGroupDataQueryResult = Apollo.QueryResult<
	RecipientGroupDataQuery,
	RecipientGroupDataQueryVariables
>;
export const SubmitRecipientFormDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'SubmitRecipientForm'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'item'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Item'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'submitRecipientForm'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'item'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'item'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type SubmitRecipientFormMutationResult = Apollo.MutationResult<SubmitRecipientFormMutation>;
export type SubmitRecipientFormMutationOptions = Apollo.BaseMutationOptions<
	SubmitRecipientFormMutation,
	SubmitRecipientFormMutationVariables
>;
export const GetScheduleDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetSchedule'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getSchedule'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type GetScheduleQueryResult = Apollo.QueryResult<GetScheduleQuery, GetScheduleQueryVariables>;
export const ReportConfigurationDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ReportConfiguration'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'reportConfiguration'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
								{kind: 'Field', name: {kind: 'Name', value: 'content'}},
								{kind: 'Field', name: {kind: 'Name', value: 'enabled'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ReportConfigurationQueryResult = Apollo.QueryResult<
	ReportConfigurationQuery,
	ReportConfigurationQueryVariables
>;
export const ReportExicutionDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'ReportExicution'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'formValues'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'reportExicution'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'formValues'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'formValues'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ReportExicutionMutationResult = Apollo.MutationResult<ReportExicutionMutation>;
export type ReportExicutionMutationOptions = Apollo.BaseMutationOptions<
	ReportExicutionMutation,
	ReportExicutionMutationVariables
>;
export const SchedulesDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Schedules'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'IncidentsInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'schedules'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'items'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'columns'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'type'}},
											{kind: 'Field', name: {kind: 'Name', value: 'order'}},
											{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
											{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
											{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
											{kind: 'Field', name: {kind: 'Name', value: 'canCreate'}},
											{kind: 'Field', name: {kind: 'Name', value: 'canEdit'}},
											{kind: 'Field', name: {kind: 'Name', value: 'oldDictionary'}},
											{kind: 'Field', name: {kind: 'Name', value: 'dictionary'}},
											{kind: 'Field', name: {kind: 'Name', value: 'dictionaryKeys'}},
											{kind: 'Field', name: {kind: 'Name', value: 'required'}},
											{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
											{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'meta'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'Field', name: {kind: 'Name', value: 'total'}}],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type SchedulesQueryResult = Apollo.QueryResult<SchedulesQuery, SchedulesQueryVariables>;
export const UpdateReportConfigurationDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'UpdateReportConfiguration'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'updateReportConfiguration'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'name'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'content'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
								{kind: 'Field', name: {kind: 'Name', value: 'content'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type UpdateReportConfigurationMutationResult = Apollo.MutationResult<UpdateReportConfigurationMutation>;
export type UpdateReportConfigurationMutationOptions = Apollo.BaseMutationOptions<
	UpdateReportConfigurationMutation,
	UpdateReportConfigurationMutationVariables
>;
export const UploadSettingsFileDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'UploadSettingsFile'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'uploadSettingsFileInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'uploadSettingsFile'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type UploadSettingsFileMutationResult = Apollo.MutationResult<UploadSettingsFileMutation>;
export type UploadSettingsFileMutationOptions = Apollo.BaseMutationOptions<
	UploadSettingsFileMutation,
	UploadSettingsFileMutationVariables
>;
export const OrgMonitorDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'OrgMonitor'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'OrgMonitorInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'orgMonitor'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'tin'}},
								{kind: 'Field', name: {kind: 'Name', value: 'bgpStatus'}},
								{kind: 'Field', name: {kind: 'Name', value: 'snmpStatus'}},
								{kind: 'Field', name: {kind: 'Name', value: 'netflowStatus'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type OrgMonitorQueryResult = Apollo.QueryResult<OrgMonitorQuery, OrgMonitorQueryVariables>;
export const HomeButtonsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'HomeButtons'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'homeButtons'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'data'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type HomeButtonsQueryResult = Apollo.QueryResult<HomeButtonsQuery, HomeButtonsQueryVariables>;
export const VersionDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Version'},
			selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'version'}}]},
		},
	],
} as unknown) as DocumentNode;
export type VersionQueryResult = Apollo.QueryResult<VersionQuery, VersionQueryVariables>;
export const ActionsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Actions'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'actions'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'ids'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'description'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ActionsQueryResult = Apollo.QueryResult<ActionsQuery, ActionsQueryVariables>;
export const CommonFiltersPresetCreateDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CommonFiltersPresetCreate'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'CommonFiltersPresetCreateInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'commonFiltersPresetCreate'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'dashboardId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'version'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type CommonFiltersPresetCreateMutationResult = Apollo.MutationResult<CommonFiltersPresetCreateMutation>;
export type CommonFiltersPresetCreateMutationOptions = Apollo.BaseMutationOptions<
	CommonFiltersPresetCreateMutation,
	CommonFiltersPresetCreateMutationVariables
>;
export const CommonFiltersPresetListDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'CommonFiltersPresetList'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'CommonFiltersPresetListInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'commonFiltersPresetList'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'dashboardId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'version'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type CommonFiltersPresetListQueryResult = Apollo.QueryResult<
	CommonFiltersPresetListQuery,
	CommonFiltersPresetListQueryVariables
>;
export const CommonFiltersPresetUpdateDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CommonFiltersPresetUpdate'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'CommonFiltersPresetUpdateInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'commonFiltersPresetUpdate'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'dashboardId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'version'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type CommonFiltersPresetUpdateMutationResult = Apollo.MutationResult<CommonFiltersPresetUpdateMutation>;
export type CommonFiltersPresetUpdateMutationOptions = Apollo.BaseMutationOptions<
	CommonFiltersPresetUpdateMutation,
	CommonFiltersPresetUpdateMutationVariables
>;
export const ComponentsListDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ComponentsList'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'componentsList'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'code'}},
								{kind: 'Field', name: {kind: 'Name', value: 'group'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'description'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'dataSources'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'fields'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'description'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'canHasChild'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'actions'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'fields'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}},
													],
												},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'description'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ComponentsListQueryResult = Apollo.QueryResult<ComponentsListQuery, ComponentsListQueryVariables>;
export const CreateViewsPageDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateViewsPage'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createViewsPage'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'name'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'content'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type CreateViewsPageMutationResult = Apollo.MutationResult<CreateViewsPageMutation>;
export type CreateViewsPageMutationOptions = Apollo.BaseMutationOptions<
	CreateViewsPageMutation,
	CreateViewsPageMutationVariables
>;
export const CustomFormDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CustomForm'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'formId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'values'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'JSON'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'customForm'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'formId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'formId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'values'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'values'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type CustomFormMutationResult = Apollo.MutationResult<CustomFormMutation>;
export type CustomFormMutationOptions = Apollo.BaseMutationOptions<CustomFormMutation, CustomFormMutationVariables>;
export const DataSourceDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'DataSource'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'dataSource'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'ids'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'description'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'fields'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Fields'}}],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'availableColumns'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'order'}},
											{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
											{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
											{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
											{kind: 'Field', name: {kind: 'Name', value: 'details'}},
											{kind: 'Field', name: {kind: 'Name', value: 'canVisible'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'LinkOptions'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LinkOptions'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'gridAction'}},
					{kind: 'Field', name: {kind: 'Name', value: 'gridForm'}},
					{kind: 'Field', name: {kind: 'Name', value: 'route'}},
					{kind: 'Field', name: {kind: 'Name', value: 'params'}},
					{kind: 'Field', name: {kind: 'Name', value: 'url'}},
					{kind: 'Field', name: {kind: 'Name', value: 'target'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmText'}},
					{kind: 'Field', name: {kind: 'Name', value: 'confirmTitle'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Fields'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Field'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'type'}},
					{kind: 'Field', name: {kind: 'Name', value: 'showType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'controlType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isArray'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'possibleValues'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'title'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
								{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'possibleValuesRef'}},
					{kind: 'Field', name: {kind: 'Name', value: 'order'}},
					{kind: 'Field', name: {kind: 'Name', value: 'visible'}},
					{kind: 'Field', name: {kind: 'Name', value: 'detail'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'canSort'}},
					{kind: 'Field', name: {kind: 'Name', value: 'required'}},
					{kind: 'Field', name: {kind: 'Name', value: 'disabled'}},
					{kind: 'Field', name: {kind: 'Name', value: 'min'}},
					{kind: 'Field', name: {kind: 'Name', value: 'max'}},
					{kind: 'Field', name: {kind: 'Name', value: 'step'}},
					{kind: 'Field', name: {kind: 'Name', value: 'defaultValue'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'linkOptions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'LinkOptions'}}],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'slaveDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'masters'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
								{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'masterDependencies'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'slaves'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'fieldId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'valueType'}},
											{kind: 'Field', name: {kind: 'Name', value: 'values'}},
											{kind: 'Field', name: {kind: 'Name', value: 'mapFilterValues'}},
											{kind: 'Field', name: {kind: 'Name', value: 'helperText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'behaviour'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'groupTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minLengthQuery'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isGroupField'}},
					{kind: 'Field', name: {kind: 'Name', value: 'minWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'maxWidth'}},
					{kind: 'Field', name: {kind: 'Name', value: 'width'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'relation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'type'}},
								{kind: 'Field', name: {kind: 'Name', value: 'model'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'possibleValueMapper'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'foreignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'localKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'through'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughArrayKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughForeignKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'throughLocalKey'}},
								{kind: 'Field', name: {kind: 'Name', value: 'filters'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isGeneral'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'validation'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'allowedExtensions'}},
								{kind: 'Field', name: {kind: 'Name', value: 'errorText'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFileSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'maxFilesSize'}},
								{kind: 'Field', name: {kind: 'Name', value: 'regExp'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'resizable'}},
					{kind: 'Field', name: {kind: 'Name', value: 'autoHeightAdjustable'}},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DataSourceQueryResult = Apollo.QueryResult<DataSourceQuery, DataSourceQueryVariables>;
export const DeleteViewsPageDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DeleteViewsPage'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'deleteViewsPage'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type DeleteViewsPageMutationResult = Apollo.MutationResult<DeleteViewsPageMutation>;
export type DeleteViewsPageMutationOptions = Apollo.BaseMutationOptions<
	DeleteViewsPageMutation,
	DeleteViewsPageMutationVariables
>;
export const RefGraphPopoverValueDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'RefGraphPopoverValue'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'refType'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'nodeFromId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'nodeToId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'currentHistoryDate'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'refGraphPopoverValue'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'refType'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'refType'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'nodeFromId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'nodeFromId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'nodeToId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'nodeToId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'currentHistoryDate'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'currentHistoryDate'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type RefGraphPopoverValueQueryResult = Apollo.QueryResult<
	RefGraphPopoverValueQuery,
	RefGraphPopoverValueQueryVariables
>;
export const RefValuesDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'RefValues'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: {
								kind: 'NonNullType',
								type: {kind: 'NamedType', name: {kind: 'Name', value: 'RefValuesInput'}},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'refValues'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'refType'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'values'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'firstLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'secondLine'}},
											{kind: 'Field', name: {kind: 'Name', value: 'iconText'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'field'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type RefValuesQueryResult = Apollo.QueryResult<RefValuesQuery, RefValuesQueryVariables>;
export const UpdateViewsPageDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'UpdateViewsPage'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'updateViewsPage'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'name'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'content'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'content'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
								{kind: 'Field', name: {kind: 'Name', value: 'content'}},
								{kind: 'Field', name: {kind: 'Name', value: 'version'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type UpdateViewsPageMutationResult = Apollo.MutationResult<UpdateViewsPageMutation>;
export type UpdateViewsPageMutationOptions = Apollo.BaseMutationOptions<
	UpdateViewsPageMutation,
	UpdateViewsPageMutationVariables
>;
export const ViewsPageDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ViewsPage'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'viewsPage'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
								{kind: 'Field', name: {kind: 'Name', value: 'content'}},
								{kind: 'Field', name: {kind: 'Name', value: 'version'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ViewsPageQueryResult = Apollo.QueryResult<ViewsPageQuery, ViewsPageQueryVariables>;
export const AddConversationMessageDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'AddConversationMessage'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'conversationId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'itemId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'fieldId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'text'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'addConversationMessage'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'conversationId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'conversationId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'itemId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'itemId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'fieldId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'fieldId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'text'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'text'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'authorName'}},
								{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'isService'}},
								{kind: 'Field', name: {kind: 'Name', value: 'text'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type AddConversationMessageMutationResult = Apollo.MutationResult<AddConversationMessageMutation>;
export type AddConversationMessageMutationOptions = Apollo.BaseMutationOptions<
	AddConversationMessageMutation,
	AddConversationMessageMutationVariables
>;
export const ConversationsDocument = ({
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Conversations'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'conversationId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'itemId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'fieldId'}},
					type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'conversations'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'conversationId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'conversationId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dataSourceId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dataSourceId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'itemId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'itemId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'fieldId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'fieldId'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'authorName'}},
								{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'isService'}},
								{kind: 'Field', name: {kind: 'Name', value: 'text'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'files'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'size'}},
											{kind: 'Field', name: {kind: 'Name', value: 'title'}},
											{kind: 'Field', name: {kind: 'Name', value: 'path'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown) as DocumentNode;
export type ConversationsQueryResult = Apollo.QueryResult<ConversationsQuery, ConversationsQueryVariables>;

/** hook для запроса "AnomaliesByFilter" */
export function useAnomaliesByFilterQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AnomaliesByFilterQuery, AnomaliesByFilterQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AnomaliesByFilterQuery, AnomaliesByFilterQueryVariables>(
		AnomaliesByFilterDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AnomaliesBySource" */
export function useAnomaliesBySourceQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AnomaliesBySourceQuery, AnomaliesBySourceQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AnomaliesBySourceQuery, AnomaliesBySourceQueryVariables>(
		AnomaliesBySourceDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AnomaliesQuantity" */
export function useAnomaliesQuantityQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AnomaliesQuantityQuery, AnomaliesQuantityQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AnomaliesQuantityQuery, AnomaliesQuantityQueryVariables>(
		AnomaliesQuantityDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AnomaliesModelNames" */
export function useAnomaliesModelNamesQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AnomaliesModelNamesQuery, AnomaliesModelNamesQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AnomaliesModelNamesQuery, AnomaliesModelNamesQueryVariables>(
		AnomaliesModelNamesDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsConnections" */
export function useAsConnectionsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsConnectionsQuery, AsConnectionsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsConnectionsQuery, AsConnectionsQueryVariables>(
		AsConnectionsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsCountByCountries" */
export function useAsCountByCountriesQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsCountByCountriesQuery, AsCountByCountriesQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsCountByCountriesQuery, AsCountByCountriesQueryVariables>(
		AsCountByCountriesDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsCountByRegistries" */
export function useAsCountByRegistriesQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsCountByRegistriesQuery, AsCountByRegistriesQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsCountByRegistriesQuery, AsCountByRegistriesQueryVariables>(
		AsCountByRegistriesDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsLinks" */
export function useAsLinksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AsLinksQuery, AsLinksQueryVariables>) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsLinksQuery, AsLinksQueryVariables>(AsLinksDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsLinksAbroad" */
export function useAsLinksAbroadQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsLinksAbroadQuery, AsLinksAbroadQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsLinksAbroadQuery, AsLinksAbroadQueryVariables>(
		AsLinksAbroadDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsLinksAbroadCount" */
export function useAsLinksAbroadCountQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsLinksAbroadCountQuery, AsLinksAbroadCountQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsLinksAbroadCountQuery, AsLinksAbroadCountQueryVariables>(
		AsLinksAbroadCountDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsLinksCount" */
export function useAsLinksCountQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsLinksCountQuery, AsLinksCountQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsLinksCountQuery, AsLinksCountQueryVariables>(
		AsLinksCountDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsNetwork" */
export function useAsNetworkQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsNetworkQuery, AsNetworkQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsNetworkQuery, AsNetworkQueryVariables>(AsNetworkDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsNetworkHistory" */
export function useAsNetworkHistoryQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsNetworkHistoryQuery, AsNetworkHistoryQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsNetworkHistoryQuery, AsNetworkHistoryQueryVariables>(
		AsNetworkHistoryDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "AuthorizeByCode" */
export function useAuthorizeByCodeMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<AuthorizeByCodeMutation, AuthorizeByCodeMutationVariables>,
) {
	return ApolloReactHooks.useMutation<AuthorizeByCodeMutation, AuthorizeByCodeMutationVariables>(
		AuthorizeByCodeDocument,
		baseOptions,
	);
}

/** hook для мутации "AuthorizeByThirdPartyToken" */
export function useAuthorizeByThirdPartyTokenMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		AuthorizeByThirdPartyTokenMutation,
		AuthorizeByThirdPartyTokenMutationVariables
	>,
) {
	return ApolloReactHooks.useMutation<
		AuthorizeByThirdPartyTokenMutation,
		AuthorizeByThirdPartyTokenMutationVariables
	>(AuthorizeByThirdPartyTokenDocument, baseOptions);
}

/** hook для мутации "Logout" */
export function useLogoutMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>,
) {
	return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
}

/** hook для мутации "Refresh" */
export function useRefreshMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshMutation, RefreshMutationVariables>,
) {
	return ApolloReactHooks.useMutation<RefreshMutation, RefreshMutationVariables>(RefreshDocument, baseOptions);
}

/** hook для запроса "User" */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "ContextHelp" */
export function useContextHelpQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ContextHelpQuery, ContextHelpQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<ContextHelpQuery, ContextHelpQueryVariables>(
		ContextHelpDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "InformationMessage" */
export function useInformationMessageQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<InformationMessageQuery, InformationMessageQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<InformationMessageQuery, InformationMessageQueryVariables>(
		InformationMessageDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "DataSourceDelete" */
export function useDataSourceDeleteMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<DataSourceDeleteMutation, DataSourceDeleteMutationVariables>,
) {
	return ApolloReactHooks.useMutation<DataSourceDeleteMutation, DataSourceDeleteMutationVariables>(
		DataSourceDeleteDocument,
		baseOptions,
	);
}

/** hook для мутации "DataSourceDeleteFile" */
export function useDataSourceDeleteFileMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		DataSourceDeleteFileMutation,
		DataSourceDeleteFileMutationVariables
	>,
) {
	return ApolloReactHooks.useMutation<DataSourceDeleteFileMutation, DataSourceDeleteFileMutationVariables>(
		DataSourceDeleteFileDocument,
		baseOptions,
	);
}

/** hook для запроса "DataSourceItem" */
export function useDataSourceItemQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<DataSourceItemQuery, DataSourceItemQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<DataSourceItemQuery, DataSourceItemQueryVariables>(
		DataSourceItemDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "DataSourceUpdate" */
export function useDataSourceUpdateMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<DataSourceUpdateMutation, DataSourceUpdateMutationVariables>,
) {
	return ApolloReactHooks.useMutation<DataSourceUpdateMutation, DataSourceUpdateMutationVariables>(
		DataSourceUpdateDocument,
		baseOptions,
	);
}

/** hook для мутации "DataSourceUploadChunk" */
export function useDataSourceUploadChunkMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		DataSourceUploadChunkMutation,
		DataSourceUploadChunkMutationVariables
	>,
) {
	return ApolloReactHooks.useMutation<DataSourceUploadChunkMutation, DataSourceUploadChunkMutationVariables>(
		DataSourceUploadChunkDocument,
		baseOptions,
	);
}

/** hook для запроса "Diagram" */
export function useDiagramQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiagramQuery, DiagramQueryVariables>) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<DiagramQuery, DiagramQueryVariables>(DiagramDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "Graph" */
export function useGraphQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GraphQuery, GraphQueryVariables>) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GraphQuery, GraphQueryVariables>(GraphDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "GraphGrid" */
export function useGraphGridQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<GraphGridQuery, GraphGridQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GraphGridQuery, GraphGridQueryVariables>(GraphGridDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "GraphTimeLineGrid" */
export function useGraphTimeLineGridQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<GraphTimeLineGridQuery, GraphTimeLineGridQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GraphTimeLineGridQuery, GraphTimeLineGridQueryVariables>(
		GraphTimeLineGridDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "Grid" */
export function useGridQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GridQuery, GridQueryVariables>) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GridQuery, GridQueryVariables>(GridDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "GridAction" */
export function useGridActionMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<GridActionMutation, GridActionMutationVariables>,
) {
	return ApolloReactHooks.useMutation<GridActionMutation, GridActionMutationVariables>(
		GridActionDocument,
		baseOptions,
	);
}

/** hook для запроса "GridDiagram" */
export function useGridDiagramQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<GridDiagramQuery, GridDiagramQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GridDiagramQuery, GridDiagramQueryVariables>(
		GridDiagramDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "GridForm" */
export function useGridFormMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<GridFormMutation, GridFormMutationVariables>,
) {
	return ApolloReactHooks.useMutation<GridFormMutation, GridFormMutationVariables>(GridFormDocument, baseOptions);
}

/** hook для запроса "RefData" */
export function useRefDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefDataQuery, RefDataQueryVariables>) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<RefDataQuery, RefDataQueryVariables>(RefDataDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "RelationData" */
export function useRelationDataQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<RelationDataQuery, RelationDataQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<RelationDataQuery, RelationDataQueryVariables>(
		RelationDataDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "Dictionary" */
export function useDictionaryQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<DictionaryQuery, DictionaryQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<DictionaryQuery, DictionaryQueryVariables>(
		DictionaryDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "CompanyDictionary" */
export function useCompanyDictionaryQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyDictionaryQuery, CompanyDictionaryQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<CompanyDictionaryQuery, CompanyDictionaryQueryVariables>(
		CompanyDictionaryDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "CreateFile" */
export function useCreateFileMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFileMutation, CreateFileMutationVariables>,
) {
	return ApolloReactHooks.useMutation<CreateFileMutation, CreateFileMutationVariables>(
		CreateFileDocument,
		baseOptions,
	);
}

/** hook для мутации "DeleteBatch" */
export function useDeleteBatchMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBatchMutation, DeleteBatchMutationVariables>,
) {
	return ApolloReactHooks.useMutation<DeleteBatchMutation, DeleteBatchMutationVariables>(
		DeleteBatchDocument,
		baseOptions,
	);
}

/** hook для запроса "FileSections" */
export function useFileSectionsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<FileSectionsQuery, FileSectionsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<FileSectionsQuery, FileSectionsQueryVariables>(
		FileSectionsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "FileStatus" */
export function useFileStatusQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<FileStatusQuery, FileStatusQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<FileStatusQuery, FileStatusQueryVariables>(
		FileStatusDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "FilesList" */
export function useFilesListQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<FilesListQuery, FilesListQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<FilesListQuery, FilesListQueryVariables>(FilesListDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "HelpFilesList" */
export function useHelpFilesListQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<HelpFilesListQuery, HelpFilesListQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<HelpFilesListQuery, HelpFilesListQueryVariables>(
		HelpFilesListDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "PublishFile" */
export function usePublishFileMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<PublishFileMutation, PublishFileMutationVariables>,
) {
	return ApolloReactHooks.useMutation<PublishFileMutation, PublishFileMutationVariables>(
		PublishFileDocument,
		baseOptions,
	);
}

/** hook для мутации "UploadChunk" */
export function useUploadChunkMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<UploadChunkMutation, UploadChunkMutationVariables>,
) {
	return ApolloReactHooks.useMutation<UploadChunkMutation, UploadChunkMutationVariables>(
		UploadChunkDocument,
		baseOptions,
	);
}

/** hook для мутации "AssignCorrelator" */
export function useAssignCorrelatorMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCorrelatorMutation, AssignCorrelatorMutationVariables>,
) {
	return ApolloReactHooks.useMutation<AssignCorrelatorMutation, AssignCorrelatorMutationVariables>(
		AssignCorrelatorDocument,
		baseOptions,
	);
}

/** hook для мутации "WithdrawCorrelator" */
export function useWithdrawCorrelatorMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<WithdrawCorrelatorMutation, WithdrawCorrelatorMutationVariables>,
) {
	return ApolloReactHooks.useMutation<WithdrawCorrelatorMutation, WithdrawCorrelatorMutationVariables>(
		WithdrawCorrelatorDocument,
		baseOptions,
	);
}

/** hook для запроса "GetConfigurationGroup" */
export function useGetConfigurationGroupQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<GetConfigurationGroupQuery, GetConfigurationGroupQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GetConfigurationGroupQuery, GetConfigurationGroupQueryVariables>(
		GetConfigurationGroupDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "GetScript" */
export function useGetScriptQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<GetScriptQuery, GetScriptQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GetScriptQuery, GetScriptQueryVariables>(GetScriptDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "GetScriptHistory" */
export function useGetScriptHistoryQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<GetScriptHistoryQuery, GetScriptHistoryQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GetScriptHistoryQuery, GetScriptHistoryQueryVariables>(
		GetScriptHistoryDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "GetScripts" */
export function useGetScriptsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<GetScriptsQuery, GetScriptsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GetScriptsQuery, GetScriptsQueryVariables>(
		GetScriptsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "SaveScript" */
export function useSaveScriptMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<SaveScriptMutation, SaveScriptMutationVariables>,
) {
	return ApolloReactHooks.useMutation<SaveScriptMutation, SaveScriptMutationVariables>(
		SaveScriptDocument,
		baseOptions,
	);
}

/** hook для мутации "ValidateScript" */
export function useValidateScriptMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateScriptMutation, ValidateScriptMutationVariables>,
) {
	return ApolloReactHooks.useMutation<ValidateScriptMutation, ValidateScriptMutationVariables>(
		ValidateScriptDocument,
		baseOptions,
	);
}

/** hook для мутации "AssignAdapter" */
export function useAssignAdapterMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<AssignAdapterMutation, AssignAdapterMutationVariables>,
) {
	return ApolloReactHooks.useMutation<AssignAdapterMutation, AssignAdapterMutationVariables>(
		AssignAdapterDocument,
		baseOptions,
	);
}

/** hook для мутации "WithdrawAdapter" */
export function useWithdrawAdapterMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<WithdrawAdapterMutation, WithdrawAdapterMutationVariables>,
) {
	return ApolloReactHooks.useMutation<WithdrawAdapterMutation, WithdrawAdapterMutationVariables>(
		WithdrawAdapterDocument,
		baseOptions,
	);
}

/** hook для запроса "TableFormSelectorItems" */
export function useTableFormSelectorItemsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<TableFormSelectorItemsQuery, TableFormSelectorItemsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<TableFormSelectorItemsQuery, TableFormSelectorItemsQueryVariables>(
		TableFormSelectorItemsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "CreateModel" */
export function useCreateModelMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<CreateModelMutation, CreateModelMutationVariables>,
) {
	return ApolloReactHooks.useMutation<CreateModelMutation, CreateModelMutationVariables>(
		CreateModelDocument,
		baseOptions,
	);
}

/** hook для мутации "DeleteModel" */
export function useDeleteModelMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteModelMutation, DeleteModelMutationVariables>,
) {
	return ApolloReactHooks.useMutation<DeleteModelMutation, DeleteModelMutationVariables>(
		DeleteModelDocument,
		baseOptions,
	);
}

/** hook для запроса "ModelItem" */
export function useModelItemQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ModelItemQuery, ModelItemQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<ModelItemQuery, ModelItemQueryVariables>(ModelItemDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "ModelItemResolve" */
export function useModelItemResolveQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ModelItemResolveQuery, ModelItemResolveQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<ModelItemResolveQuery, ModelItemResolveQueryVariables>(
		ModelItemResolveDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "ModelItems" */
export function useModelItemsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ModelItemsQuery, ModelItemsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<ModelItemsQuery, ModelItemsQueryVariables>(
		ModelItemsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "ToggleModelItem" */
export function useToggleModelItemMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleModelItemMutation, ToggleModelItemMutationVariables>,
) {
	return ApolloReactHooks.useMutation<ToggleModelItemMutation, ToggleModelItemMutationVariables>(
		ToggleModelItemDocument,
		baseOptions,
	);
}

/** hook для мутации "UpdateModel" */
export function useUpdateModelMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateModelMutation, UpdateModelMutationVariables>,
) {
	return ApolloReactHooks.useMutation<UpdateModelMutation, UpdateModelMutationVariables>(
		UpdateModelDocument,
		baseOptions,
	);
}

/** hook для запроса "AsTraffic" */
export function useAsTrafficQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsTrafficQuery, AsTrafficQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsTrafficQuery, AsTrafficQueryVariables>(AsTrafficDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "Flowoperator" */
export function useFlowoperatorQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<FlowoperatorQuery, FlowoperatorQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<FlowoperatorQuery, FlowoperatorQueryVariables>(
		FlowoperatorDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsTrafficSankey" */
export function useAsTrafficSankeyQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsTrafficSankeyQuery, AsTrafficSankeyQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsTrafficSankeyQuery, AsTrafficSankeyQueryVariables>(
		AsTrafficSankeyDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "AsTrafficByTime" */
export function useAsTrafficByTimeQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<AsTrafficByTimeQuery, AsTrafficByTimeQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<AsTrafficByTimeQuery, AsTrafficByTimeQueryVariables>(
		AsTrafficByTimeDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "NewUnreadNotifications" */
export function useNewUnreadNotificationsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<NewUnreadNotificationsQuery, NewUnreadNotificationsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<NewUnreadNotificationsQuery, NewUnreadNotificationsQueryVariables>(
		NewUnreadNotificationsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "NotificationList" */
export function useNotificationListQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationListQuery, NotificationListQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<NotificationListQuery, NotificationListQueryVariables>(
		NotificationListDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "NotificationsCount" */
export function useNotificationsCountQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationsCountQuery, NotificationsCountQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<NotificationsCountQuery, NotificationsCountQueryVariables>(
		NotificationsCountDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "ReadNotifications" */
export function useReadNotificationsMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<ReadNotificationsMutation, ReadNotificationsMutationVariables>,
) {
	return ApolloReactHooks.useMutation<ReadNotificationsMutation, ReadNotificationsMutationVariables>(
		ReadNotificationsDocument,
		baseOptions,
	);
}

/** hook для запроса "OldDictionary" */
export function useOldDictionaryQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<OldDictionaryQuery, OldDictionaryQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<OldDictionaryQuery, OldDictionaryQueryVariables>(
		OldDictionaryDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "OuterLinks" */
export function useOuterLinksQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<OuterLinksQuery, OuterLinksQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<OuterLinksQuery, OuterLinksQueryVariables>(
		OuterLinksDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "PirsAttachmentDelete" */
export function usePirsAttachmentDeleteMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		PirsAttachmentDeleteMutation,
		PirsAttachmentDeleteMutationVariables
	>,
) {
	return ApolloReactHooks.useMutation<PirsAttachmentDeleteMutation, PirsAttachmentDeleteMutationVariables>(
		PirsAttachmentDeleteDocument,
		baseOptions,
	);
}

/** hook для запроса "PirsAttachments" */
export function usePirsAttachmentsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<PirsAttachmentsQuery, PirsAttachmentsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<PirsAttachmentsQuery, PirsAttachmentsQueryVariables>(
		PirsAttachmentsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "PirsDelete" */
export function usePirsDeleteMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<PirsDeleteMutation, PirsDeleteMutationVariables>,
) {
	return ApolloReactHooks.useMutation<PirsDeleteMutation, PirsDeleteMutationVariables>(
		PirsDeleteDocument,
		baseOptions,
	);
}

/** hook для мутации "PirsCloseSession" */
export function usePirsCloseSessionMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<PirsCloseSessionMutation, PirsCloseSessionMutationVariables>,
) {
	return ApolloReactHooks.useMutation<PirsCloseSessionMutation, PirsCloseSessionMutationVariables>(
		PirsCloseSessionDocument,
		baseOptions,
	);
}

/** hook для мутации "PirsCreate" */
export function usePirsCreateMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<PirsCreateMutation, PirsCreateMutationVariables>,
) {
	return ApolloReactHooks.useMutation<PirsCreateMutation, PirsCreateMutationVariables>(
		PirsCreateDocument,
		baseOptions,
	);
}

/** hook для запроса "PirsGetSession" */
export function usePirsGetSessionQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<PirsGetSessionQuery, PirsGetSessionQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<PirsGetSessionQuery, PirsGetSessionQueryVariables>(
		PirsGetSessionDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "PirsInstance" */
export function usePirsInstanceQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<PirsInstanceQuery, PirsInstanceQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<PirsInstanceQuery, PirsInstanceQueryVariables>(
		PirsInstanceDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "PirsInstanceDetails" */
export function usePirsInstanceDetailsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<PirsInstanceDetailsQuery, PirsInstanceDetailsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<PirsInstanceDetailsQuery, PirsInstanceDetailsQueryVariables>(
		PirsInstanceDetailsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "PirsList" */
export function usePirsListQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<PirsListQuery, PirsListQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<PirsListQuery, PirsListQueryVariables>(PirsListDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "PirsRefTypeSearch" */
export function usePirsRefTypeSearchQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<PirsRefTypeSearchQuery, PirsRefTypeSearchQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<PirsRefTypeSearchQuery, PirsRefTypeSearchQueryVariables>(
		PirsRefTypeSearchDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "PirsSidebar" */
export function usePirsSidebarQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<PirsSidebarQuery, PirsSidebarQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<PirsSidebarQuery, PirsSidebarQueryVariables>(
		PirsSidebarDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "PirsUpdate" */
export function usePirsUpdateMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<PirsUpdateMutation, PirsUpdateMutationVariables>,
) {
	return ApolloReactHooks.useMutation<PirsUpdateMutation, PirsUpdateMutationVariables>(
		PirsUpdateDocument,
		baseOptions,
	);
}

/** hook для запроса "RecipientDictionary" */
export function useRecipientDictionaryQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<RecipientDictionaryQuery, RecipientDictionaryQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<RecipientDictionaryQuery, RecipientDictionaryQueryVariables>(
		RecipientDictionaryDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "RecipientGroupData" */
export function useRecipientGroupDataQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<RecipientGroupDataQuery, RecipientGroupDataQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<RecipientGroupDataQuery, RecipientGroupDataQueryVariables>(
		RecipientGroupDataDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "SubmitRecipientForm" */
export function useSubmitRecipientFormMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		SubmitRecipientFormMutation,
		SubmitRecipientFormMutationVariables
	>,
) {
	return ApolloReactHooks.useMutation<SubmitRecipientFormMutation, SubmitRecipientFormMutationVariables>(
		SubmitRecipientFormDocument,
		baseOptions,
	);
}

/** hook для запроса "GetSchedule" */
export function useGetScheduleQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduleQuery, GetScheduleQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<GetScheduleQuery, GetScheduleQueryVariables>(
		GetScheduleDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "ReportConfiguration" */
export function useReportConfigurationQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ReportConfigurationQuery, ReportConfigurationQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<ReportConfigurationQuery, ReportConfigurationQueryVariables>(
		ReportConfigurationDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "ReportExicution" */
export function useReportExicutionMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<ReportExicutionMutation, ReportExicutionMutationVariables>,
) {
	return ApolloReactHooks.useMutation<ReportExicutionMutation, ReportExicutionMutationVariables>(
		ReportExicutionDocument,
		baseOptions,
	);
}

/** hook для запроса "Schedules" */
export function useSchedulesQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<SchedulesQuery, SchedulesQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<SchedulesQuery, SchedulesQueryVariables>(SchedulesDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "UpdateReportConfiguration" */
export function useUpdateReportConfigurationMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		UpdateReportConfigurationMutation,
		UpdateReportConfigurationMutationVariables
	>,
) {
	return ApolloReactHooks.useMutation<UpdateReportConfigurationMutation, UpdateReportConfigurationMutationVariables>(
		UpdateReportConfigurationDocument,
		baseOptions,
	);
}

/** hook для мутации "UploadSettingsFile" */
export function useUploadSettingsFileMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<UploadSettingsFileMutation, UploadSettingsFileMutationVariables>,
) {
	return ApolloReactHooks.useMutation<UploadSettingsFileMutation, UploadSettingsFileMutationVariables>(
		UploadSettingsFileDocument,
		baseOptions,
	);
}

/** hook для запроса "OrgMonitor" */
export function useOrgMonitorQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<OrgMonitorQuery, OrgMonitorQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<OrgMonitorQuery, OrgMonitorQueryVariables>(
		OrgMonitorDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "HomeButtons" */
export function useHomeButtonsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<HomeButtonsQuery, HomeButtonsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<HomeButtonsQuery, HomeButtonsQueryVariables>(
		HomeButtonsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "Version" */
export function useVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VersionQuery, VersionQueryVariables>) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "Actions" */
export function useActionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActionsQuery, ActionsQueryVariables>) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<ActionsQuery, ActionsQueryVariables>(ActionsDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "CommonFiltersPresetCreate" */
export function useCommonFiltersPresetCreateMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		CommonFiltersPresetCreateMutation,
		CommonFiltersPresetCreateMutationVariables
	>,
) {
	return ApolloReactHooks.useMutation<CommonFiltersPresetCreateMutation, CommonFiltersPresetCreateMutationVariables>(
		CommonFiltersPresetCreateDocument,
		baseOptions,
	);
}

/** hook для запроса "CommonFiltersPresetList" */
export function useCommonFiltersPresetListQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		CommonFiltersPresetListQuery,
		CommonFiltersPresetListQueryVariables
	>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<CommonFiltersPresetListQuery, CommonFiltersPresetListQueryVariables>(
		CommonFiltersPresetListDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "CommonFiltersPresetUpdate" */
export function useCommonFiltersPresetUpdateMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		CommonFiltersPresetUpdateMutation,
		CommonFiltersPresetUpdateMutationVariables
	>,
) {
	return ApolloReactHooks.useMutation<CommonFiltersPresetUpdateMutation, CommonFiltersPresetUpdateMutationVariables>(
		CommonFiltersPresetUpdateDocument,
		baseOptions,
	);
}

/** hook для запроса "ComponentsList" */
export function useComponentsListQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ComponentsListQuery, ComponentsListQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<ComponentsListQuery, ComponentsListQueryVariables>(
		ComponentsListDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "CreateViewsPage" */
export function useCreateViewsPageMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<CreateViewsPageMutation, CreateViewsPageMutationVariables>,
) {
	return ApolloReactHooks.useMutation<CreateViewsPageMutation, CreateViewsPageMutationVariables>(
		CreateViewsPageDocument,
		baseOptions,
	);
}

/** hook для мутации "CustomForm" */
export function useCustomFormMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<CustomFormMutation, CustomFormMutationVariables>,
) {
	return ApolloReactHooks.useMutation<CustomFormMutation, CustomFormMutationVariables>(
		CustomFormDocument,
		baseOptions,
	);
}

/** hook для запроса "DataSource" */
export function useDataSourceQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<DataSourceQuery, DataSourceQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<DataSourceQuery, DataSourceQueryVariables>(
		DataSourceDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "DeleteViewsPage" */
export function useDeleteViewsPageMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteViewsPageMutation, DeleteViewsPageMutationVariables>,
) {
	return ApolloReactHooks.useMutation<DeleteViewsPageMutation, DeleteViewsPageMutationVariables>(
		DeleteViewsPageDocument,
		baseOptions,
	);
}

/** hook для запроса "RefGraphPopoverValue" */
export function useRefGraphPopoverValueQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<RefGraphPopoverValueQuery, RefGraphPopoverValueQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<RefGraphPopoverValueQuery, RefGraphPopoverValueQueryVariables>(
		RefGraphPopoverValueDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для запроса "RefValues" */
export function useRefValuesQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<RefValuesQuery, RefValuesQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<RefValuesQuery, RefValuesQueryVariables>(RefValuesDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "UpdateViewsPage" */
export function useUpdateViewsPageMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateViewsPageMutation, UpdateViewsPageMutationVariables>,
) {
	return ApolloReactHooks.useMutation<UpdateViewsPageMutation, UpdateViewsPageMutationVariables>(
		UpdateViewsPageDocument,
		baseOptions,
	);
}

/** hook для запроса "ViewsPage" */
export function useViewsPageQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ViewsPageQuery, ViewsPageQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<ViewsPageQuery, ViewsPageQueryVariables>(ViewsPageDocument, baseOptions);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}

/** hook для мутации "AddConversationMessage" */
export function useAddConversationMessageMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		AddConversationMessageMutation,
		AddConversationMessageMutationVariables
	>,
) {
	return ApolloReactHooks.useMutation<AddConversationMessageMutation, AddConversationMessageMutationVariables>(
		AddConversationMessageDocument,
		baseOptions,
	);
}

/** hook для запроса "Conversations" */
export function useConversationsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>,
) {
	const [refetchError, setRefetchError] = useState();
	const result = ApolloReactHooks.useQuery<ConversationsQuery, ConversationsQueryVariables>(
		ConversationsDocument,
		baseOptions,
	);
	const variables = baseOptions?.variables || {};
	useEffect(() => {
		setRefetchError(undefined);
	}, Object.values(variables));

	useLogout(result.error);

	const handleRefetch = useCallback(() => {
		setRefetchError(undefined);
		return result.refetch().catch(err => {
			setRefetchError(err);
			return Promise.reject(err);
		});
	}, [setRefetchError, result]);

	return {
		...result,
		networkStatus: refetchError ? 8 : result.networkStatus,
		error: refetchError || result.error,
		refetch: handleRefetch,
	};
}
