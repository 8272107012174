import React from 'react';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import ToggleModelItemOld from '../../components/FullTable/ToggleModelItemOld';
import {JSONSafeParse} from '../../utils/object-utils';

const IntegrationCorrelatorTable: React.FC = () => {
	const {Table} = useFullTableWithRefetch('integrationCorrelator', {
		tableFields: ['name', 'description', 'uuid', 'info', 'enabled'],
		detailsFields: ['info'],
		deletable: true,
		editable: true,
		cellCallback: {
			enabled: (value, item) => {
				return <ToggleModelItemOld modelName={'integrationCorrelator'} value={value} id={item.id} />;
			},
			info: value => (value && value !== '{}' ? 'Установлены' : 'Не установлены'),
		},
		detailCellCallback: {
			info: value => {
				const parsedValue = JSONSafeParse(value);
				if (parsedValue && typeof parsedValue === 'object') {
					if (Object.keys(parsedValue).length > 0) {
						return (
							<ul>
								{Object.keys(parsedValue).map(key => (
									<li key={key}>
										{key}: {parsedValue[key]}
									</li>
								))}
							</ul>
						);
					}
					return '';
				}
				return value;
			},
		},
	});
	return <Table />;
};

export default IntegrationCorrelatorTable;
