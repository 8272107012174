import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {List, ListRowProps, ScrollParams} from 'react-virtualized';
import {getDateOfCrossMonthLine, setDate} from './helper';
import {addMonth, addYear, diffMonth, format, resetMonth, resetYear} from 'ts-date/locale/ru';
import css from './Calendar.module.module.css';
import Button from '../Button/Button';
import {ArrowDownIcon, ArrowUpIcon, GoTodayIcon} from '../../SvgIcon';
import {END_DATE, FROM_DATE, stopPropagation, Value} from './Calendar';
import cls from '../../../utils/cls';
import {onlyUnique} from '../../../utils/array-utils';

type Props = {
	value: Value | null;
	minDate?: Date;
	maxDate?: Date;
	width: number;
	height: number;
	rowHeight: number;
	scrollbarWidth: number;
	switchToYears: (e: React.MouseEvent) => void;
	switchToDays: (e: React.MouseEvent) => void;
	dateCursor: Date;
	onChangeDateCursor: (date: Date) => void;
	range?: boolean;
};

const MonthsCalendar: React.FC<Props> = ({
	value,
	width,
	scrollbarWidth,
	height,
	rowHeight,
	switchToDays,
	switchToYears,
	range,
	dateCursor,
	onChangeDateCursor,
	...props
}) => {
	const fromDate = useMemo(() => (props.minDate ? addYear(props.minDate, -1) : FROM_DATE), [props.minDate]);
	const endDate = useMemo(() => (props.maxDate ? addYear(props.maxDate, 1) : END_DATE), [props.maxDate]);
	const monthsInLine = 4;
	const list = useRef<List>(null);

	useEffect(() => {
		if (list.current) {
			list.current.scrollToPosition(
				(diffMonth(resetYear(dateCursor), fromDate)! / monthsInLine) * rowHeight - rowHeight,
			);
		}
	}, []);

	const currentYear = useMemo(() => {
		if (!dateCursor) return resetYear(new Date());
		let currentYear: Date = null as any;
		if (dateCursor.getMonth() > 5) {
			currentYear = new Date(dateCursor.getFullYear() + 1, 0, 1);
		} else {
			currentYear = new Date(dateCursor.getFullYear(), 0, 1);
		}
		return currentYear;
	}, [dateCursor]);

	const todayDisabled = useMemo(() => {
		return resetYear(currentYear).getTime() === resetYear(new Date()).getTime();
	}, [currentYear]);

	const monthLinesCount = useMemo(() => diffMonth(endDate, fromDate)! / monthsInLine, [fromDate, endDate]);

	const MonthsLine = ({index, key, style}: ListRowProps) => {
		const day = getDateOfCrossMonthLine(index, fromDate);
		return (
			<div key={key} style={{...style}} className={css.line}>
				{Array(monthsInLine)
					.fill(undefined)
					.map((_x, monthIndex) => {
						const date = addMonth(day, monthIndex);
						const currentDate = resetMonth(date);
						const selectedDates = [resetMonth(value?.from || null), resetMonth(value?.to || null)].filter(
							Boolean,
						);
						const minDate = props.minDate && resetMonth(props.minDate);
						const maxDate = props.maxDate && resetMonth(props.maxDate);
						const isInCurrentYear = date.getFullYear() === currentYear.getFullYear();

						const nowDate = resetMonth(new Date());
						const isToday = currentDate.getTime() === nowDate.getTime();

						const isSelectedFrom =
							selectedDates.length > 0 && selectedDates[0]?.getTime() === currentDate.getTime();
						const isSelectedTo =
							selectedDates.length > 0 && selectedDates[1]?.getTime() === currentDate.getTime();

						const isDisabled = (minDate && currentDate < minDate) || (maxDate && currentDate > maxDate);
						const changeDate = setDate(value?.from || date, date.getFullYear(), date.getMonth());
						if (date >= fromDate && date <= endDate) {
							return (
								<div
									key={key + '-' + monthIndex}
									className={cls(
										css.item,
										css.month,
										!isInCurrentYear && css.otherMonth,
										isToday && css.today,
										(isSelectedFrom || isSelectedTo) && css.selected,
										isSelectedFrom && css.selectedFrom,
										isSelectedTo && css.selectedTo,
										isDisabled && css.disabled,
									)}
									onClick={e => {
										if (!isDisabled) {
											onChangeDateCursor(changeDate);
											switchToDays(e);
										}
									}}
									onMouseDown={stopPropagation}
									role={'button'}
									tabIndex={isDisabled ? undefined : -1}
									aria-disabled={isDisabled}
									aria-checked={isSelectedFrom || isSelectedTo}
									aria-hidden={!isInCurrentYear}
									aria-label={`Установка даты ${changeDate.toUTCString()}`}
								>
									{format(date, 'MMM')!.replace('.', '')}
								</div>
							);
						}
						return (
							<div
								key={key + '-' + monthIndex}
								aria-disabled={true}
								className={cls(css.item, css.month, css.disabled)}
							>
								{format(date, 'MMM')}
							</div>
						);
					})}
			</div>
		);
	};

	const handleScroll = useCallback(
		({scrollTop}: ScrollParams) => {
			const index = Math.ceil(scrollTop / rowHeight);
			onChangeDateCursor(getDateOfCrossMonthLine(index, fromDate));
		},
		[rowHeight],
	);

	const prevYearDisabled = useMemo(() => {
		return resetYear(fromDate) >= resetYear(addYear(currentYear, -1));
	}, [fromDate, currentYear]);

	const nextYearDisabled = useMemo(() => {
		return resetYear(endDate) <= resetYear(addYear(currentYear, 1));
	}, [endDate, currentYear]);

	const handleSetPrevYear = useCallback(() => {
		if (prevYearDisabled) return;
		const newDate = addYear(currentYear, -1);
		newDate.setDate(1);
		const index = diffMonth(newDate, fromDate)! / monthsInLine;
		list.current?.scrollToPosition(index * rowHeight - rowHeight);
		onChangeDateCursor(newDate);
	}, [fromDate, currentYear]);

	const handleSetNextYear = useCallback(() => {
		if (nextYearDisabled) return;
		const newDate = addYear(currentYear, 1);
		newDate.setDate(1);
		const index = diffMonth(newDate, fromDate)! / monthsInLine;
		list.current?.scrollToPosition(index * rowHeight - rowHeight);
		onChangeDateCursor(newDate);
	}, [fromDate, currentYear]);

	const handleToday = useCallback(() => {
		const now = new Date();
		const newDate = new Date(now.getFullYear(), 0, 1);
		const index = diffMonth(newDate, fromDate)! / monthsInLine;
		list.current?.scrollToPosition(index * rowHeight - rowHeight);
		onChangeDateCursor(newDate);
	}, []);

	const hasDuration = useMemo(() => {
		const selectedDates = [value?.from ? resetMonth(value.from) : null, value?.to ? resetMonth(value.to) : null]
			.filter(onlyUnique)
			.filter(Boolean);

		return selectedDates.length === 2;
	}, [value]);

	return (
		<div style={{width: width}} className={cls(css.wrapper, hasDuration && css.hasRange)}>
			<div className={css.header} style={{height: height / 6}}>
				<div className={css.title} onClick={switchToYears} onMouseDown={stopPropagation}>
					{format(currentYear, 'YYYY')} г.
				</div>
				<div className={css.actions}>
					<Button
						type={'button'}
						action
						iconOnly
						disabled={prevYearDisabled}
						onClick={handleSetPrevYear}
						onMouseDown={stopPropagation}
						title={'Предыдущий год'}
					>
						<ArrowUpIcon />
					</Button>
					<Button
						type={'button'}
						action
						iconOnly
						disabled={nextYearDisabled}
						onClick={handleSetNextYear}
						onMouseDown={stopPropagation}
						title={'Следующий год'}
					>
						<ArrowDownIcon />
					</Button>
					<Button
						type={'button'}
						action
						iconOnly
						disabled={todayDisabled}
						onClick={handleToday}
						onMouseDown={stopPropagation}
						title={'Сегодня'}
					>
						<GoTodayIcon />
					</Button>
				</div>
			</div>
			<List
				onScroll={handleScroll}
				rowCount={monthLinesCount}
				rowHeight={rowHeight}
				height={(height / 6) * 7}
				tabIndex={null}
				width={width + scrollbarWidth}
				rowRenderer={MonthsLine}
				ref={list}
			/>
		</div>
	);
};

export default MonthsCalendar;
