import React from 'react';
import css from '../settings/views/ViewEdit/constructor/form/DashboardFields.module.css';
import Button from '../pirsInputs/Button/Button';
import {CrossIcon, SaveIcon} from '../SvgIcon';
import {FormikErrors, FormikProvider, useFormik, Form as FormikForm} from 'formik';
import Input from '../pirsInputs/Input/Input.formik';
import {InputLabel} from '../FullTable/FullTableFormHelpers';
import {
	CommonFiltersPreset,
	CommonFiltersPresetListDocument,
	useCommonFiltersPresetCreateMutation,
	useCommonFiltersPresetUpdateMutation,
} from '../../queries-generated/types';
import {notifyToastError, notifyToastSuccess} from '../toast/Toast';

type Props = {
	dashboardId: string;
	version: number;
	commonFiltersValues: PlainObjectOf<any>;
	onChangeFiltersPreset: (value: CommonFiltersPreset) => void;
	onClose: () => void;
	id?: string | null;
};

type Values = {
	title: string;
};

const CommonFiltersPresetForm: React.FC<Props> = ({
	id,
	dashboardId,
	version,
	commonFiltersValues,
	onClose,
	onChangeFiltersPreset,
}) => {
	const editMode = !!id;

	const [createPreset, {loading: createPresetLoading}] = useCommonFiltersPresetCreateMutation();
	const [updatePreset, {loading: updatePresetLoading}] = useCommonFiltersPresetUpdateMutation();

	const formikBag = useFormik<Values>({
		initialValues: {
			title: '',
		},
		validate: values => {
			const errors: FormikErrors<Values> = {};
			if (!id && !values.title) {
				errors.title = 'Это поле обязательно для заполнения';
			}

			return errors;
		},

		onSubmit: values => {
			if (editMode) {
				updatePreset({
					variables: {
						input: {
							id,
							filters: commonFiltersValues,
						},
					},
				})
					.then(() => {
						notifyToastSuccess('Обновление значений общих фильтров прошло успешно');
						onClose();
					})
					.catch(error => {
						notifyToastError(error);
					});
			} else {
				createPreset({
					variables: {
						input: {
							title: values.title,
							dashboardId,
							version,
							filters: commonFiltersValues,
						},
					},
					refetchQueries: [
						{query: CommonFiltersPresetListDocument, variables: {input: {version: version, dashboardId}}},
					],
				})
					.then(result => {
						const preset = result.data?.commonFiltersPresetCreate;
						if (preset) {
							onChangeFiltersPreset(preset);
						}
						notifyToastSuccess('Сохранение значений общих фильтров прошло успешно');
						onClose();
					})
					.catch(error => {
						notifyToastError(error);
					});
			}
		},
	});

	return (
		<FormikProvider value={formikBag}>
			<FormikForm>
				{!editMode && (
					<ul className={css.form}>
						<li>
							<Input name={'title'} label={<InputLabel title={'Название'} required={true} />} />
						</li>
					</ul>
				)}
				<div className={css.buttons}>
					<Button
						type="submit"
						disabled={createPresetLoading || updatePresetLoading}
						loading={createPresetLoading || updatePresetLoading}
					>
						<SaveIcon />
						Сохранить
					</Button>
					<Button secondary onClick={onClose}>
						<CrossIcon />
						Отмена
					</Button>
				</div>
			</FormikForm>
		</FormikProvider>
	);
};

export default CommonFiltersPresetForm;
