import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import ChartWrapper, {ChartTypes} from '../ChartsBase/ChartWrapper';
import {
	DataSourceNotification,
	Field,
	FieldType,
	GridDiagram,
	GridDiagramSeries,
	useGridDiagramQuery,
} from '../../../../../queries-generated/types';
import usePrevious from '../../../../hooks/usePrevious';
import {isNotifiedDataSource, filtersToDataSourceParams} from '../helper';
import {useComponentFilters} from '../../../../view/api/useUserFilters';
import cssError from '../ChartsBase/ChartWrapperWithData.module.css';
import {NetworkStatus} from '@apollo/client';
import ProgressRing from '../../../../controlls/Loader/ProgressRing';
import DataGrid from '../../../../DataGrid/DataGrid';
import cls from '../../../../../utils/cls';
import {highchartColors} from '../ChartsBase/highchartColors';
import ErrorSnippet from '../../../../ErrorSnippet/ErrorSnippet';
import css from './GridDiagramBase.module.css';
import {notifyDataSource} from '../../../../toast/DataSourceToast';
import {DataSourceContext} from 'components/view/ViewWrapper';
import useSplitScreen from '../../splitScreen/useSplitScreen';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import {getArrayValueWithIndexExcess} from '../../../../../utils/array-utils';
import viewComponentCss from '../../../../view/ViewComponent.module.css';
import useComponentCommonButtons, {renderCommonButtons} from '../../../../view/useComponentCommonButtons';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	defaultData: GridDiagram;
	mapper: (data: GridDiagram) => GridDiagram;
	type: ChartTypes;
	align: 'top' | 'left' | 'right' | 'bottom';
	height: number | string;
	pollInterval?: number;
};

const GridDiagramBase: React.FC<Props> = ({
	viewItem,
	skipQuery,
	defaultData,
	mapper,
	type,
	height,
	align,
	pollInterval,
}) => {
	const {reloadCounter} = useContext(DataSourceContext);
	const prevReloadCounter = usePrevious(reloadCounter);

	const {filters, waiting} = useComponentFilters(viewItem);
	const {setData} = useComponentsStoredData(viewItem.id);

	const {data, loading, refetch, error, networkStatus} = useGridDiagramQuery({
		variables: {
			dataSourceId: viewItem.dataSource?.type || '',
			filters: filtersToDataSourceParams(filters, viewItem.dataSource?.columns),
		},
		skip: skipQuery || waiting,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		pollInterval,
		onCompleted: result => {
			if (isNotifiedDataSource(viewItem.actions))
				notifyDataSource(result.gridDiagram.notifications as DataSourceNotification[]);
		},
	});

	useEffect(() => {
		const diagram = skipQuery ? defaultData : data?.gridDiagram;
		setData({...viewItem.dataSource, data: diagram});
	}, [defaultData, data, skipQuery, viewItem]);

	useEffect(() => {
		if (prevReloadCounter !== undefined && reloadCounter !== prevReloadCounter && !skipQuery) {
			refetch();
		}
	}, [reloadCounter, skipQuery, prevReloadCounter]);

	const tableRef = useRef<HTMLDivElement>(null);

	const tableDiagram = skipQuery ? defaultData : data?.gridDiagram;

	// NOTE: текущее значение X
	const [activeX, setActiveX] = useState<string | null>(null);

	const mergedSeries = useMemo(() => {
		const mergedSeries: PlainObjectOf<Array<GridDiagramSeries>> = {};

		if (!tableDiagram?.series) return {};

		for (let i = 0; i < tableDiagram?.series.length; i++) {
			const series = tableDiagram.series[i];
			const mergeId = series.mergeId || series.id || i.toString();

			if (!mergedSeries[mergeId]) mergedSeries[mergeId] = [];

			mergedSeries[mergeId].push(series);
		}

		return mergedSeries;
	}, [tableDiagram?.series]);

	const [selectedMergedSeries, setSelectedMergedSeries] = useState<string[] | null>(null);

	const selectedSeries = useMemo(() => {
		if (!selectedMergedSeries) return [];
		const ids: string[] = [];
		let index = 0;

		selectedMergedSeries.forEach(id => {
			const seriesArray = mergedSeries[id];
			if (seriesArray)
				seriesArray.forEach(series => {
					ids.push(series.id || index.toString());
					index++;
				});
		});
		return ids;
	}, [selectedMergedSeries, mergedSeries]);

	// NOTE: Если передали дефолтное значение для таблицы, которое показывается, когда на точки не навели, то тут его предкешируем
	const defaultSeriesItems = useMemo(() => {
		const mergedSeriesIds = Object.keys(mergedSeries);
		if (mergedSeriesIds && tableDiagram?.options?.defaultXValue) {
			const items = {};
			mergedSeriesIds.forEach(id => {
				const seriesArray = mergedSeries[id];
				const series = seriesArray.find(series => !series.baseLine);
				if (series) {
					const point = series.points.find(point => point.x === tableDiagram?.options?.defaultXValue);
					if (point) items[id] = point.tableItem;
				}
			});
			return items;
		}
		return null;
	}, [mergedSeries]);

	// NOTE: Если передали дефолтное значение для таблицы, устанавливаем егот для первог раза (потом activeX всегда будет)
	useEffect(() => {
		if (tableDiagram?.options?.defaultXValue && !activeX) {
			setActiveX(tableDiagram?.options?.defaultXValue);
		}
	}, [tableDiagram?.options?.defaultXValue, activeX, setActiveX]);

	// NOTE: Обрабатываем наведение на точки графика, чтобы показать значения в таблице
	const handlePointOver = useCallback(
		(event: any) => {
			const x = new Date(event.target.x).toISOString();
			if (x !== activeX) setActiveX(x);
		},
		[activeX],
	);
	const handlePointOut = useCallback(() => {
		setActiveX(tableDiagram?.options?.defaultXValue || null);
	}, [tableDiagram?.options?.defaultXValue]);

	const wrapperRef = useRef<HTMLDivElement>(null);

	const {
		firstHeight,
		secondHeight,
		firstWidth,
		secondWidth,
		ResizeControl,
		secondCss,
		firstCss,
		reloadCounter: reloadCounterProportions,
	} = useSplitScreen({
		align,
		width: '100%',
		height,
		parentRef: wrapperRef,
	});

	// NOTE: Устанавливаем выбранные серии
	useEffect(() => {
		if (mergedSeries) {
			const mergedSeriesIds = Object.keys(mergedSeries);
			if (!mergedSeriesIds.length) return;

			setSelectedMergedSeries(
				mergedSeriesIds
					.map(id => {
						const seriesArray = mergedSeries[id];
						if (seriesArray.every(series => series.baseLine)) return null;
						else return id;
					})
					.filter(Boolean) as string[],
			);
		}
	}, [mergedSeries]);

	// NOTE: Группируем цвета по индексу, чтобы потом расскасить чекбоксы
	const checkColors = useMemo(() => {
		const mergedSeriesIds = Object.keys(mergedSeries);
		const colors = {};
		let seriesIndex = 0;
		mergedSeriesIds.forEach(id => {
			const seriesArray = mergedSeries[id];
			if (seriesArray.every(series => series.baseLine)) return;
			seriesIndex++;
			colors[id] = getArrayValueWithIndexExcess(highchartColors, seriesIndex - 1);
		});
		return colors;
	}, [mergedSeries]);

	const items = useMemo(() => {
		const mergedSeriesIds = Object.keys(mergedSeries);
		return mergedSeriesIds
			.map(id => {
				const seriesArray = mergedSeries[id];
				if (seriesArray.every(series => series.baseLine)) return;

				let tableItemBase;
				const series = seriesArray.find(series => !series.baseLine);
				if (activeX === tableDiagram?.options?.defaultXValue && defaultSeriesItems) {
					tableItemBase = defaultSeriesItems[id];
				} else {
					if (series) tableItemBase = series.points.find(point => point.x === activeX)?.tableItem;
				}

				return {id, name: series?.name, ...tableItemBase};
			})
			.filter(Boolean);
	}, [mergedSeries, activeX]);

	const patchedTableDiagram = useMemo(() => {
		if (!tableDiagram) return null;

		const patchedTableDiagram = mapper(tableDiagram);
		const series = patchedTableDiagram.series.map((series, index) => {
			const mergeId = series.mergeId || series.id || index.toString();
			const color = checkColors[mergeId];
			return {...series, color};
		});

		return {...patchedTableDiagram, series};
	}, [tableDiagram]);

	const tableOptions = useMemo(() => {
		if (!patchedTableDiagram) return {};

		const nameColumn: Field = {
			id: 'name',
			type: FieldType.String,
			title: patchedTableDiagram.options?.mainColumnName || 'Название',
			canSort: false,
			visible: true,
		};

		return {
			fields: [nameColumn, ...patchedTableDiagram.fields],
			selectable: true,
			selectedIds: selectedMergedSeries || [],
			onSelect: setSelectedMergedSeries,
			checkColors,
		};
	}, [patchedTableDiagram, selectedMergedSeries, checkColors]);

	const commonButtons = useComponentCommonButtons(viewItem);

	if (error)
		return <ErrorSnippet error={error} errorHeader={'Ошибка загрузки данных для таблицы'} refetch={refetch} />;

	if ((loading && networkStatus !== NetworkStatus.poll) || !patchedTableDiagram)
		return (
			<div className={cssError.error}>
				<ProgressRing />
			</div>
		);
	if (!patchedTableDiagram.series.length)
		return <div className={cssError.error}>За указанный период данные отсутствуют</div>;

	const Chart = (
		<div className={cls(css.chart, firstCss)} style={{height: firstHeight, width: firstWidth}}>
			<ChartWrapper
				viewItem={viewItem}
				data={patchedTableDiagram}
				type={type}
				title={viewItem.component?.props?.title}
				reload={refetch}
				width={typeof firstWidth === 'string' ? undefined : firstWidth}
				height={typeof firstHeight === 'string' ? undefined : firstHeight}
				options={viewItem.component?.props}
				hideLegend
				selectedSeries={selectedSeries || []}
				reloadCounter={reloadCounterProportions}
				onPointOver={handlePointOver}
				onPointOut={handlePointOut}
			/>
		</div>
	);
	const Table = (
		<div className={cls(css.table, secondCss)} ref={tableRef} style={{height: secondHeight, width: secondWidth}}>
			{items ? <DataGrid options={tableOptions} items={items} /> : 'Нет данных'}
		</div>
	);

	const Content = ['top', 'left'].includes(align) ? (
		<>
			{Table}
			{Chart}
		</>
	) : (
		<>
			{Chart}
			{Table}
		</>
	);

	return (
		<>
			<div className={viewComponentCss.filters}>{renderCommonButtons(commonButtons)}</div>
			<div className={cls(css.wrapper, css[align])} style={{height}} ref={wrapperRef}>
				{Content}
				{ResizeControl}
			</div>
		</>
	);
};

export default GridDiagramBase;
