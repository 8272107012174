import {GraphQLError} from 'graphql';
import {ApolloError} from '@apollo/client';

export type NormalizedError = {
	code: string;
	message: string;
	networkError?: boolean;
	traceId?: string | null;
};

type ParsedError = {
	code: string;
	message: string;
};

export function getTraceId(apolloError?: ApolloError | null): string | null {
	return (
		(apolloError &&
			apolloError.graphQLErrors &&
			apolloError.graphQLErrors[0] &&
			apolloError.graphQLErrors[0].traceId) ||
		null
	);
}

export function getError(
	apolloError?: ApolloError | GraphQLError[] | null,
	withNetworkMessage: boolean = true,
): ParsedError | null {
	if (!apolloError) return null;

	if (Array.isArray(apolloError)) {
		return {
			message: apolloError[0]?.message || DEFAULT_ERROR.message,
			code: apolloError[0]?.extensions?.code || apolloError[0]?.code || '',
		};
	}

	if (apolloError?.graphQLErrors?.length) {
		const error = apolloError.graphQLErrors[0];
		return {code: error?.extensions?.code || error?.code || '', message: error.message};
	}

	const networkError = apolloError.networkError;
	if (networkError && withNetworkMessage) {
		const result = (networkError as any).result;
		const message = result && result.errors && result.errors[0] && result.errors[0].message;

		return {
			code: networkError.name,
			message: message || networkError.message,
		};
	}

	if (apolloError.message) {
		return {
			code: apolloError.code || '',
			message: apolloError.message,
		};
	}

	return {
		message: 'Произошла неизвестная ошибка',
		code: 'UNKNOWN_ERROR',
	};
}

const DEFAULT_ERROR: NormalizedError = {
	message: 'Произошла неизвестная ошибка',
	code: 'UNKNOWN_ERROR',
};

export function getQueryError(errors?: readonly GraphQLError[]): NormalizedError {
	if (!errors || errors.length) return DEFAULT_ERROR;
	return {
		message: errors[0].message || DEFAULT_ERROR.message,
		code: errors[0].code || '',
		traceId: errors[0].traceId,
	};
}

export function getApolloError(error?: ApolloError | null): NormalizedError {
	const err: NormalizedError | null = getError(error, true);
	if (!err || !error) return DEFAULT_ERROR;
	if (error.networkError) err.networkError = true;
	err.traceId = getTraceId(error);
	return err;
}
