import React from 'react';
import {RemoteFile} from '../../queries-generated/types';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import ProgressRing from '../../components/controlls/Loader/ProgressRing';
import FilesListRow from './FilesListRow';

type Props = {
	files: RemoteFile[];
	loading: boolean;
};

const FilesList: React.FC<Props> = ({files, loading}) => {
	return (
		<>
			{loading && <ProgressRing />}
			{!loading && (
				<>
					{files.length > 0 ? (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Рег. номер</TableCell>
									<TableCell>Файл</TableCell>
									<TableCell>Дата загрузки</TableCell>
									<TableCell>Размер</TableCell>
									<TableCell>Результат</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{files.map(file => {
									return <FilesListRow key={file.id} file={file} colSpan={5} />;
								})}
							</TableBody>
						</Table>
					) : (
						<span>Похоже, пока файлы еще не загружены</span>
					)}
				</>
			)}
		</>
	);
};

export default FilesList;
