import React from 'react';
import css from './Calendar.module.module.css';
import cls from '../../../utils/cls';
import {stopPropagation} from './Calendar';

type Props = {
	selected: number;
	onChange: (value: number) => void;
	values: number[];
	rowHeight: number;
	useRef: React.RefObject<HTMLDivElement>;
	min?: number;
	max?: number;
};

const TimeList: React.FC<Props> = ({selected, useRef, values, onChange, rowHeight, min, max}) => {
	return (
		<div className={css.timeList} style={{width: rowHeight}} ref={useRef}>
			{values.map(value => {
				const disabled = (min != null && value < min) || (max != null && value > max);
				return (
					<div
						key={value}
						className={cls(
							css.item,
							css.time,
							value === selected && css.selected,
							disabled && css.disabled,
						)}
						onClick={() => !disabled && onChange(value)}
						onMouseDown={stopPropagation}
						style={{height: rowHeight}}
					>
						{value.toString().padStart(2, '0')}
					</div>
				);
			})}
		</div>
	);
};

export default TimeList;
