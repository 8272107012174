exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".FullTableForm-module__fields__3rA5M {\r\n\tlist-style: none;\r\n\tpadding: 0;\r\n\tmargin: 0;\r\n}\r\n\r\n\t.FullTableForm-module__fields__3rA5M li {\r\n\t\tmargin: 10px 0;\r\n\t}\r\n\r\n\t.FullTableForm-module__required__2h0_J {\r\n\tcolor: var(--error-foreground);\r\n}\r\n\r\n\t.FullTableForm-module__buttons__19Nmm {\r\n\tlist-style: none;\r\n\tpadding: 0;\r\n\tmargin: 0;\r\n\tdisplay: flex;\r\n\tmargin-top: 20px;\r\n}\r\n\r\n\t.FullTableForm-module__buttons__19Nmm li {\r\n\t\tmargin-right: 10px;\r\n\t}\r\n\r\n\t.FullTableForm-module__header__2w6E2 {\r\n\tcolor: var(--accent-fill-rest);\r\n}\r\n\r\n\t.FullTableForm-module__fileWrapper__u1Enl div {\r\n\t\twidth: 100%;\r\n\t}\r\n\r\n\t.FullTableForm-module__fileWrapper__u1Enl label {\r\n\t\twidth: 100%;\r\n\t}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\FullTable\\FullTableForm.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,UAAU;CACV,SAAS;AAKV;;CAHC;EACC,cAAc;CACf;;CAGD;CACC,8BAA8B;AAC/B;;CAEA;CACC,gBAAgB;CAChB,UAAU;CACV,SAAS;CACT,aAAa;CACb,gBAAgB;AAKjB;;CAHC;EACC,kBAAkB;CACnB;;CAGD;CACC,8BAA8B;AAC/B;;CAGC;EACC,WAAW;CACZ;;CACA;EACC,WAAW;CACZ","file":"FullTableForm.module.css","sourcesContent":[".fields {\r\n\tlist-style: none;\r\n\tpadding: 0;\r\n\tmargin: 0;\r\n\r\n\tli {\r\n\t\tmargin: 10px 0;\r\n\t}\r\n}\r\n\r\n.required {\r\n\tcolor: var(--error-foreground);\r\n}\r\n\r\n.buttons {\r\n\tlist-style: none;\r\n\tpadding: 0;\r\n\tmargin: 0;\r\n\tdisplay: flex;\r\n\tmargin-top: 20px;\r\n\r\n\tli {\r\n\t\tmargin-right: 10px;\r\n\t}\r\n}\r\n\r\n.header {\r\n\tcolor: var(--accent-fill-rest);\r\n}\r\n\r\n.fileWrapper {\r\n\tdiv {\r\n\t\twidth: 100%;\r\n\t}\r\n\tlabel {\r\n\t\twidth: 100%;\r\n\t}\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"fields": "FullTableForm-module__fields__3rA5M",
	"required": "FullTableForm-module__required__2h0_J",
	"buttons": "FullTableForm-module__buttons__19Nmm",
	"header": "FullTableForm-module__header__2w6E2",
	"fileWrapper": "FullTableForm-module__fileWrapper__u1Enl"
};