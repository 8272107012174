import React from 'react';
import Input from '../../pirsInputs/Input/Input.formik';
import TextArea from '../../pirsInputs/TextArea/TextArea.formik';
import InputNumber from '../../pirsInputs/InputNumber/InputNumber.formik';
import InputDate from '../../pirsInputs/InputDate/InputDate.formik';
import InputDateTime from '../../pirsInputs/InputDateTime/InputDateTime.formik';
import {Entity} from './useMetadata';
import css from './EntityComponent.module.css';
import {
	PirsRefTypeSearchDocument,
	PirsRefTypeSearchQuery,
	PirsRefTypeSearchQueryVariables,
} from '../../../queries-generated/types';
import {useApolloClient} from '@apollo/client';
import AutoComplete from '../../pirsInputs/AutoComplete/AutoComplete.formik';
import Combobox from '../../pirsInputs/Combobox/Combobox.formik';
import {getItemsByInput} from '../../pirsInputs/Combobox/Combobox';
import {JSONSafeParse} from '../../../utils/object-utils';
import {useFormikContext} from 'formik';
import useInspectorComments from './useInspectorComments';
import PopoverButton from '../../controlls/PopoverButton/PopoverButton';
import {WarningIcon} from '../../SvgIcon';
import NullableBoolean from '../../pirsInputs/NullableBoolean/NullableBoolean.formik';

type Props = {
	entity: Entity;
	name: string;
	required?: boolean;
};

type RefMatch = {
	start: number;
	end: number;
};

type RefType = {
	primaryString: {value: string; matches: RefMatch[]};
	secondaryString: {value: string; matches: RefMatch[]} | null;
	value: string;
};

const EntityComponent: React.FC<Props> = ({entity, required, name}) => {
	const props = {
		label:
			entity.__typename === 'ScalarCollectionDescriptor' ? (
				entity.elementCaption
			) : (
				<>
					{entity.caption}
					{required && <span style={{color: 'red'}}>*</span>}
				</>
			),
		helperText: entity.description && (
			<div
				dangerouslySetInnerHTML={{
					__html: entity.description.replace(/\\n/g, '<br />').replace(/\*/g, '<br />*'),
				}}
			/>
		),
		minLength: entity.scalarDataType?.minLength,
		maxLength: entity.scalarDataType?.maxLength,
		noValidate: true,
		name,
	};

	const {setFieldValue} = useFormikContext();
	const {inspectorCommentsPathList, getInspectorCommentByPath} = useInspectorComments();

	const apolloClient = useApolloClient();

	if (!entity.scalarDataType?.valueType) return null;
	const step = entity.scalarDataType.valueType === 'integer' ? 1 : 'any';
	const min = entity.scalarDataType.valueType === 'nonNegativeInteger' ? 0 : undefined;

	const getRefItems = async (possibleValuesRef: string, value: string) => {
		if (value && value.trim().length >= 2) {
			return new Promise(resolve => {
				apolloClient
					.query<PirsRefTypeSearchQuery, PirsRefTypeSearchQueryVariables>({
						query: PirsRefTypeSearchDocument,
						variables: {
							type: possibleValuesRef,
							queryText: value,
						},
					})
					.then(result => {
						resolve(result.data.pirsRefTypeSearch);
					});
			});
		}
	};

	const comboboxItemToString = (value: string | null) => {
		if (value === '') return 'Не выбрано';
		const item = entity.scalarDataType?.possibleValueList.find(item => item.value === value);
		if (item) return item.label;
		return '';
	};

	return (
		<div className={css.wrapper}>
			{entity.scalarDataType?.possibleValueList ? (
				<Combobox<string | null>
					itemToString={comboboxItemToString}
					getItems={getItemsByInput<string | null>(
						['', ...entity.scalarDataType?.possibleValueList.map(item => item.value)],
						comboboxItemToString,
					)}
					{...props}
				/>
			) : entity.scalarDataType.refType ? (
				<>
					<AutoComplete<RefType>
						getItems={value => getRefItems(entity.scalarDataType?.refType || '', value) as any}
						itemToHtml={value => {
							if (!value) return '';
							return (
								<div>
									<div>{value.primaryString.value}</div>
									{value.secondaryString && (
										<div className={css.refSearchSecondary}>{value.secondaryString.value}</div>
									)}
								</div>
							);
						}}
						itemToPrimaryKey={item => item.value}
						itemToString={value => {
							if (!value) return '';
							return value.primaryString.value;
						}}
						onCustomChange={(_value, originalValue) => {
							if (!originalValue) return;
							const data = JSONSafeParse(originalValue.value);
							const parent = name.split('.');
							parent.pop();
							const parentName = parent.join('.');
							if (data) {
								Object.keys(data).map(key => {
									setFieldValue((parentName + '.' + key) as never, data[key]);
								});
							}
						}}
						{...props}
					/>
				</>
			) : entity.scalarDataType.valueType === 'string' ? (
				<TextArea {...props} />
			) : entity.scalarDataType.valueType === 'boolean' ? (
				<NullableBoolean {...props} />
			) : ['integer', 'nonNegativeInteger', 'positiveInteger', 'decimal'].includes(
					entity.scalarDataType.valueType,
			  ) ? (
				<InputNumber {...props} step={step} min={min} />
			) : ['date'].includes(entity.scalarDataType.valueType) ? (
				<InputDate {...props} />
			) : ['dateTime'].includes(entity.scalarDataType.valueType) ? (
				<InputDateTime {...props} />
			) : (
				<Input {...props} />
			)}
			{inspectorCommentsPathList.includes(name) ? (
				<div className={css.commentButtonWrapper}>
					<PopoverButton
						popover={<div className={css.comment}>{getInspectorCommentByPath(name)}</div>}
						className={css.commentButton}
					>
						<WarningIcon className={css.warningIcon} />
					</PopoverButton>
				</div>
			) : null}
		</div>
	);
};

export default EntityComponent;
