import React from 'react';
import ModalPopup from '../controlls/ModalPopup/ModalPopup';
import {useCreateModelMutation, useModelItemsQuery} from '../../queries-generated/types';
import ProgressRing from '../controlls/Loader/ProgressRing';
import {Form, FormikErrors, FormikProvider, useFormik} from 'formik';
import TextArea from '../pirsInputs/TextArea/TextArea.formik';
import Button from '../pirsInputs/Button/Button';
import {parseIsoOrThrow, format} from 'ts-date/locale/ru';
import css from './IncidentsComments.module.css';
type Props = {
	onClose: () => void;
	incident: any;
};

type Values = {
	comment: string;
};

const IncidentsComments: React.FC<Props> = ({onClose, incident}) => {
	const {data, loading, refetch} = useModelItemsQuery({
		variables: {
			model: 'incidentsComment',
			variables: {
				issue_id: incident.id,
			},
		},
		pollInterval: 10000,
	});

	const [createModel, {loading: createLoading}] = useCreateModelMutation();

	const formikBag = useFormik<Values>({
		initialValues: {
			comment: '',
		},
		validate: values => {
			const errors: FormikErrors<Values> = {};
			if (!values.comment.trim().length) errors.comment = 'Не допускается ввод пустых комментариев';
			if (values.comment.length > 4095) errors.comment = 'Длина ограничена 4096 символами';
			return errors;
		},
		onSubmit: (values, {resetForm}) => {
			createModel({
				variables: {
					model: 'incidentsComment',
					items: [{comment: values.comment, issue_id: incident.id}],
				},
			}).then(() => {
				refetch();
				resetForm();
			});
		},
	});

	return (
		<ModalPopup open={true} onClose={onClose} maxWidth={400}>
			<h2>Комментарии инцидента {incident.external_id}</h2>
			{loading ? (
				<ProgressRing />
			) : (
				<>
					{data?.modelItems && data.modelItems.items.length > 0 ? (
						<ul className={css.list}>
							{data?.modelItems.items.map(item => {
								return (
									<li key={item.id}>
										<div>
											<b>
												{item.author} (
												{format(parseIsoOrThrow(item.date), 'DD MMMM YYYY в HH:mm')})
											</b>
										</div>
										<pre className={css.noHtmlFormat}>{item.comment}</pre>
									</li>
								);
							})}
						</ul>
					) : (
						<>Комментариев пока нет</>
					)}
				</>
			)}
			<FormikProvider value={formikBag}>
				<Form>
					<TextArea name={'comment'} maxLength={4096} label={'Новый комментарий'} resizable={true} />
					<ul className={css.buttons}>
						<li>
							<Button type={'submit'} loading={createLoading}>
								Добавить комментарий
							</Button>
						</li>
						<li>
							<Button type={'button'} color={'secondary'} onClick={onClose}>
								Отмена
							</Button>
						</li>
					</ul>
				</Form>
			</FormikProvider>
		</ModalPopup>
	);
};

export default IncidentsComments;
