import React from 'react';
import css from '../../../../view/ViewComponent.module.css';
import cls from '../../../../../utils/cls';

type Props = React.HTMLAttributes<HTMLDivElement> & {
	snippetWrapperClassName?: string;
	snippetHeaderClassName?: string;
	snippetBodyClassName?: string;
};

const Snippet: React.FC<Props> = props => {
	const {
		title,
		children,
		className,
		snippetWrapperClassName,
		snippetHeaderClassName,
		snippetBodyClassName,
		...otherProps
	} = props;

	return (
		<div className={cls(className, css.snippet, css.component)} {...otherProps}>
			<div className={cls(snippetWrapperClassName, css.snippetWrapper)}>
				<div className={cls(snippetHeaderClassName, css.snippetHeader)} title={title}>
					{title}
				</div>
				<div className={cls(snippetBodyClassName, css.snippetBody)}>{children}</div>
			</div>
		</div>
	);
};

export default Snippet;
