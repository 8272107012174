import React, {useCallback, useState} from 'react';
import useFullTableWithRefetch from '../../../components/FullTable/useFullTableHook';
import MainLayout from '../../../components/layouts/MainLayout';
import {SettingsReportsRoutes} from '../SettingsSchedulePage';
import {SettingsReportTemplatesForm} from './SettingsReportTemplatesForm';
import {DashboardIcon, SettingsIcon} from '../../../components/SvgIcon';
import ModalPopup from 'components/controlls/ModalPopup/ModalPopup';
import SettingsReportLaunchForm from './SettingsReportLaunchForm';
import {useReportExicutionMutation} from '../../../queries-generated/types';
import {notifyToastError, notifyToastSuccess} from '../../../components/toast/Toast';
import InputSearch from '../../../components/pirsInputs/InputSearch/InputSearch';
import Button from '../../../components/pirsInputs/Button/Button';

const SettingsReportTemplatesPage: React.FC = () => {
	const [mutation, {loading}] = useReportExicutionMutation();

	const [query, setQuery] = useState<string>();
	const [chosenTemplate, setChosenTemplate] = useState<PlainObjectOf<any>>();

	const handleChoseTemplate = useCallback(
		(template: PlainObjectOf<any>) => {
			setChosenTemplate(template);
		},
		[setChosenTemplate],
	);

	const handleClearTemplateId = useCallback(() => {
		setChosenTemplate(undefined);
	}, [setChosenTemplate]);

	const handleSubmitReport = useCallback(
		(formValues: PlainObjectOf<any>) => {
			mutation({variables: {formValues}})
				.then(() => {
					notifyToastSuccess('Отчет отправлен');
					handleClearTemplateId();
				})
				.catch(() => {
					notifyToastError('Ошибка отправки отчета');
				});
		},
		[mutation, handleClearTemplateId],
	);

	const {Table} = useFullTableWithRefetch('template', {
		tableFields: ['name', 'description', 'modified'],
		detailsFields: ['roles'],
		detailCellCallback: {
			roles: value => value && value.map(role => role.name).join(', '),
		},
		variables: {query},
		updateForm: SettingsReportTemplatesForm,
		createForm: SettingsReportTemplatesForm,
		creatable: true,
		editable: true,
		deletable: true,
		additionalFilters: <InputSearch value={query} onChange={setQuery} />,
		additionalControls: item => (
			<>
				<Button route={'settingsReportTemplatesEdit'} params={{id: item.id}} target={'_blank'} iconOnly action>
					<SettingsIcon />
				</Button>
				<Button
					onClick={() => handleChoseTemplate(item)}
					title={'Открыть форму экспорта отчета'}
					iconOnly
					action
				>
					<DashboardIcon />
				</Button>
			</>
		),
	});

	return (
		<MainLayout routes={SettingsReportsRoutes} contextHelpKey={'helpReportTemplate'}>
			<ModalPopup
				open={!!chosenTemplate}
				header={`Экспорт отчета ${chosenTemplate?.name}`}
				maxWidth={800}
				onClose={handleClearTemplateId}
			>
				{chosenTemplate?.id && (
					<SettingsReportLaunchForm
						templateId={chosenTemplate.id}
						onSubmit={handleSubmitReport}
						submitLoading={loading}
						onClose={handleClearTemplateId}
					/>
				)}
			</ModalPopup>
			<Table />
		</MainLayout>
	);
};

export default SettingsReportTemplatesPage;
