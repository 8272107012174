import React, {useCallback, useEffect} from 'react';
import css from './ModalPopup.module.css';
import Modal from '../../Modal';
import cls from '../../../utils/cls';
import * as SvgIcon from '../../SvgIcon';
import FocusLock from '../../FocusLock/FocusLock';

type Props = {
	isModal?: boolean;
	onClose?: () => void;
	open: boolean;
	header?: any;
	buttons?: any;
	className?: string;
	theme?: string;
	maxWidth?: number;
};

function toggleHtmlClass(open: boolean) {
	const documentElement = document.documentElement;
	if (!documentElement) return;
	if (open) {
		documentElement.classList.add('is-modal-popup-open');
	} else {
		documentElement.classList.remove('is-modal-popup-open');
	}
}

const ModalPopup: React.FC<Props> = props => {
	const {isModal, header, onClose, open, children, className, theme, maxWidth, ...other} = props;

	const close = useCallback(
		(event?: React.MouseEvent | React.KeyboardEvent) => {
			if (event) event.stopPropagation();
			if (!isModal && onClose) onClose();
		},
		[isModal, onClose],
	);

	useEffect(() => {
		toggleHtmlClass(open);

		return () => {
			if (open) toggleHtmlClass(false);
		};
	}, [open]);

	if (!open) return null;

	return (
		<Modal>
			<FocusLock focusOnOpen onEscClick={close}>
				<div className={cls(css.wrapper, className)}>
					<div className={cls(css.backdrop, 'modal-popup-backdrop in')} onClick={close} />

					<div
						className={cls('modal-popup', css.popup)}
						{...other}
						onClick={event => event.stopPropagation()}
						style={maxWidth ? {maxWidth} : undefined}
					>
						{!isModal && (
							<button
								type="button"
								className={cls(css.close, 'modal-popup-close')}
								onClick={close}
								aria-label={'close'}
							>
								<SvgIcon.CrossIcon />
							</button>
						)}

						{header && <div className={cls(css.header, 'modal-popup-header')}>{header}</div>}

						{children && <div className={cls(css.content, 'modal-popup-content')}>{children}</div>}
					</div>
				</div>
			</FocusLock>
		</Modal>
	);
};

ModalPopup.defaultProps = {
	theme: 'info',
};

export default ModalPopup;
