exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".FilePreview-module__wrapper__TQ8Sy {\r\n\twidth: 100px;\r\n\tposition: relative;\r\n}\r\n\r\n.FilePreview-module__preview__3j0Mt {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\theight: 100px;\r\n\twidth: 100px;\r\n}\r\n\r\n.FilePreview-module__title__29am0 {\r\n\ttext-align: center;\r\n\tword-wrap: break-word;\r\n}\r\n\r\n.FilePreview-module__delete__18akY {\r\n\tposition: absolute;\r\n\ttop: 0;\r\n\tright: 0;\r\n}\r\n\r\n.FilePreview-module__downloadable__6zlE0 {\r\n\tcursor: pointer;\r\n\ttext-decoration: none;\r\n\tcolor: inherit;\r\n}\r\n\r\n.FilePreview-module__downloadable__6zlE0:hover {\r\n\t\tcolor: inherit;\r\n\t\ttext-decoration: underline;\r\n\t}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\pirsInputs\\DashboardInputFile\\FilePreview.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,aAAa;CACb,YAAY;AACb;;AAEA;CACC,kBAAkB;CAClB,qBAAqB;AACtB;;AAEA;CACC,kBAAkB;CAClB,MAAM;CACN,QAAQ;AACT;;AAEA;CACC,eAAe;CACf,qBAAqB;CACrB,cAAc;AAMf;;AAJC;EACC,cAAc;EACd,0BAA0B;CAC3B","file":"FilePreview.module.css","sourcesContent":[".wrapper {\r\n\twidth: 100px;\r\n\tposition: relative;\r\n}\r\n\r\n.preview {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\theight: 100px;\r\n\twidth: 100px;\r\n}\r\n\r\n.title {\r\n\ttext-align: center;\r\n\tword-wrap: break-word;\r\n}\r\n\r\n.delete {\r\n\tposition: absolute;\r\n\ttop: 0;\r\n\tright: 0;\r\n}\r\n\r\n.downloadable {\r\n\tcursor: pointer;\r\n\ttext-decoration: none;\r\n\tcolor: inherit;\r\n\r\n\t&:hover {\r\n\t\tcolor: inherit;\r\n\t\ttext-decoration: underline;\r\n\t}\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"wrapper": "FilePreview-module__wrapper__TQ8Sy",
	"preview": "FilePreview-module__preview__3j0Mt",
	"title": "FilePreview-module__title__29am0",
	"delete": "FilePreview-module__delete__18akY",
	"downloadable": "FilePreview-module__downloadable__6zlE0"
};