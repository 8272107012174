import React, {useCallback} from 'react';
import css from './UploadAttachmentsProgress.module.css';
import {TableCell, TableRow} from '@material-ui/core';
import useDeleteConfirm from '../../../components/hooks/useDeleteConfirm/useDeleteConfirm';
import {useResourceRegisterUploadFiles} from '../../../components/commonRedux/ResourceRegistryUploadFiles/ResourceRegistryUploadFilesProvider';
import {ResourceRegisterUploadFileStatus} from '../../../components/commonRedux/ResourceRegistryUploadFiles/ResourceRegistryUploadFilesReducer';
import {CrossIcon} from '../../../components/SvgIcon';
import Button from '../../../components/pirsInputs/Button/Button';
import Progress from '../../../components/controlls/Progress/Progress';

type Props = {
	pageId: string;
};

type LoadingProps = {
	fileId: string;
	fileName: string;
	loadingProgress: number;
	requestInstance?: XMLHttpRequest;
};

const LoadingSnippet: React.FC<LoadingProps> = ({fileId, fileName, loadingProgress, requestInstance}) => {
	const {Confirm, openConfirm} = useDeleteConfirm({
		callback: () => {
			requestInstance && requestInstance.abort();
		},
		text: `Прекратить загрузку файла ${fileName}`,
	});
	return (
		<>
			{Confirm}
			<TableRow key={fileId}>
				<TableCell colSpan={3}>
					<div className={css.loadingBlock}>
						<div>Загрузка файла {fileName}</div>
						<div className={css.linearProgress}>
							<Progress value={loadingProgress || 0} />
						</div>

						<div>{loadingProgress} %</div>
					</div>
				</TableCell>
				<TableCell>
					<div className={css.controlsCell}>
						<Button title={'Прекратить загрузку файла'} iconOnly action onClick={openConfirm}>
							<CrossIcon />
						</Button>
					</div>
				</TableCell>
			</TableRow>
		</>
	);
};

type ErrorProps = {
	fileId: string;
	fileName: string;
	onDelete: (id: string) => void;
	error?: string;
};

const ErrorSnippet: React.FC<ErrorProps> = ({fileId, fileName, onDelete, error}) => {
	const handleDeleteFileError = useCallback(() => {
		onDelete(fileId);
	}, []);

	return (
		<TableRow key={fileId}>
			<TableCell colSpan={3}>
				<div className={css.loadingBlock}>
					Ошибка загрузки файла {fileName}
					{error && `: ${error}`}
				</div>
			</TableCell>
			<TableCell>
				<div className={css.controlsCell}>
					<Button iconOnly action onClick={handleDeleteFileError}>
						<CrossIcon />
					</Button>
				</div>
			</TableCell>
		</TableRow>
	);
};

const UploadAttachmentsProgress: React.FC<Props> = ({pageId}) => {
	const {removeFiles, files} = useResourceRegisterUploadFiles(pageId);

	const loadingFiles = files.filter(file => file.status === ResourceRegisterUploadFileStatus.LOADING);
	const errorFiles = files.filter(file => file.status === ResourceRegisterUploadFileStatus.ERROR);

	const handleDeleteFileError = useCallback((id: string) => {
		removeFiles([id]);
	}, []);

	return (
		<>
			{loadingFiles.length > 0
				? loadingFiles.map(uploadFileData => {
						const {
							id,
							file: {name},
							loadingProgress,
							requestInstance,
						} = uploadFileData;
						return (
							<LoadingSnippet
								key={id}
								fileId={id}
								fileName={name}
								loadingProgress={loadingProgress || 0}
								requestInstance={requestInstance}
							/>
						);
				  })
				: null}
			{errorFiles.length > 0
				? errorFiles.map(uploadFileData => {
						const {
							id,
							file: {name},
							error,
						} = uploadFileData;
						return (
							<ErrorSnippet
								key={id}
								fileId={id}
								fileName={name}
								onDelete={handleDeleteFileError}
								error={error}
							/>
						);
				  })
				: null}
		</>
	);
};

export default UploadAttachmentsProgress;
