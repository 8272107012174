import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import RightPanel from '../RightPanel/RightPanel';
import ProgressRing from '../controlls/Loader/ProgressRing';
import ErrorSnippet from '../ErrorSnippet/ErrorSnippet';
import {FormikProvider, useFormik, Form as FormikForm} from 'formik';
import css from '../settings/views/ViewEdit/constructor/form/DashboardFields.module.css';
import DashboardFields from '../settings/views/ViewEdit/constructor/form/DashboardFields';
import Button from '../pirsInputs/Button/Button';
import {CheckIcon, SaveIcon} from '../SvgIcon';
import useUserFilters from './api/useUserFilters';
import usePrevious from '../hooks/usePrevious';
import {flatCommonFilters, formValuesToCommonFiltersFields, validateFields} from '../settings/views/components/helper';
import {
	CommonFiltersPreset,
	Field,
	useCommonFiltersPresetListQuery,
	useDataSourceQuery,
} from '../../queries-generated/types';
import {CommonFilterField, ViewItem} from './api/useViewItemTree';
import useDependencies from './useDependencies';
import useDeleteConfirm from '../hooks/useDeleteConfirm/useDeleteConfirm';
import useOnClickOutside from '../hooks/useOnClickOutside';
import Selector from '../pirsInputs/Selector/Selector';
import useToggle from '../hooks/useToggle';
import ModalPopup from '../controlls/ModalPopup/ModalPopup';
import CommonFiltersPresetForm from './CommonFiltersPresetForm';
import useUnsubmittedValues from '../unsubmittedValues/useUnsubmittedValues';

type Props = {
	viewItems: ViewItem[];
	root: ViewItem;
	id?: string;
	version?: number;
};

const Filters: React.FC<Props> = ({version, id, root, viewItems}) => {
	const {
		open,
		close,
		filters,
		openComponent,
		setComponentFilters,
		waiting,
		matchInitFilters,
		getViewItemInitFilters,
		initFilters,
	} = useUserFilters();
	const viewItem = openComponent ? viewItems.find(viewItem => viewItem.id === openComponent) : null;
	const {unsubmittedValues, setUnsubmittedValues, clearUnsubmittedValues} = useUnsubmittedValues(
		`${id}-${viewItem?.id}-filters`,
	);
	const isCommonFilters = openComponent === 'root';

	const prevId = usePrevious(viewItem?.id);

	const hasCommonFiltersPresets = id && version && isCommonFilters;

	const rightPanelRef = useRef<HTMLDivElement>(null);

	const currentFilters =
		viewItem && (viewItem.id === 'root' ? flatCommonFilters(filters[viewItem?.id]) : filters[viewItem?.id]);

	// FIXME Убрать
	const [formikValuesInitialized, setFormikValuesInitialized] = useState<boolean>(false);

	const [filtersPreset, setFiltersPreset] = useState<CommonFiltersPreset | null>(null);
	const [initialValues, setInitialValues] = useState<PlainObjectOf<any>>({});

	const {data, loading, error, refetch} = useDataSourceQuery({
		// TODO: На кой хуй тут массив?
		variables: {ids: [viewItem?.dataSource?.type || '']},
		skip: !viewItem?.dataSource?.type,
		notifyOnNetworkStatusChange: true,
	});

	const fields = useMemo(() => {
		if (isCommonFilters) return root.commonFilters || [];

		if (viewItem && data) {
			return data.dataSource[0].fields.filter(field => viewItem.dataSource?.filteredByUser?.includes(field.id));
		}
	}, [data, viewItem, isCommonFilters, root]);

	const formikBag = useFormik<PlainObjectOf<any>>({
		enableReinitialize: true,
		initialValues,
		validate: values => {
			if (fields) {
				const errors = validateFields(fields, values);

				if (Object.keys(errors).length) {
					return errors;
				}
			}
		},

		onSubmit: values => {
			setComponentFilters({
				id: openComponent as string,
				filters: isCommonFilters
					? formValuesToCommonFiltersFields(values, fields as CommonFilterField[])
					: values,
				waiting: false,
			});

			clearUnsubmittedValues();

			setInitialValues(values);
		},
	});

	// FIXME убрать
	useEffect(() => {
		if (viewItem?.id !== prevId) {
			setFormikValuesInitialized(false);
		}
	}, [setFormikValuesInitialized, viewItem, prevId]);

	const resetForm = useCallback(
		(values: PlainObjectOf<any>, makeDirty?: boolean) => {
			if (makeDirty) {
				formikBag.setValues(values);
			} else {
				setInitialValues(values);
			}
		},
		[formikBag.setValues, setInitialValues],
	);

	// Наполняем values новыми полями fields (после смены dataSource)
	useEffect(() => {
		if (fields && viewItem && !formikValuesInitialized) {
			resetForm({
				...getViewItemInitFilters(viewItem, fields),
				// собственные фильтры viewItem
				...currentFilters,
				// несохраненные изменения
				...unsubmittedValues,
			});

			setFormikValuesInitialized(true);
		}
	}, [
		viewItem,
		currentFilters,
		fields,
		resetForm,
		setFormikValuesInitialized,
		formikValuesInitialized,
		unsubmittedValues,
		getViewItemInitFilters,
	]);

	const fieldsProps = useDependencies({
		setFieldValue: formikBag.setFieldValue,
		values: formikBag.values,
		fields: fields as Field[],
	});

	const {data: presetFilterData} = useCommonFiltersPresetListQuery({
		variables: {input: {version: version!, dashboardId: id!}},
		skip: !hasCommonFiltersPresets,
	});

	const handleChangeFiltersPreset = useCallback(
		(preset: CommonFiltersPreset | null): void => {
			setFiltersPreset(preset);
			if (preset) {
				preset.filters && resetForm(preset.filters, true);
			} else {
				resetForm(flatCommonFilters(initFilters['root']));
			}
		},
		[setFiltersPreset, initFilters, resetForm],
	);

	const {openConfirm: openCloseConfirm, Confirm: CloseConfirm} = useDeleteConfirm({
		header: 'Непримененные фильтры',
		text: (
			<>
				<p>В форме остались изменения. Они будут сохранены, но пока не применятся.</p>
				<p>Вы уверены, что хотите закрыть форму?</p>
			</>
		),
		callback: close,
	});

	const handleClose = useCallback(() => {
		if (open) {
			if (formikBag.dirty) {
				openCloseConfirm();
				setUnsubmittedValues(formikBag.values);
			} else close();
		}
	}, [formikBag.dirty, setUnsubmittedValues, formikBag.values, close, open]);

	const canMakeReset = useMemo(() => {
		if (viewItem && fields) {
			return !matchInitFilters(viewItem, fields);
		}
		return false;
	}, [matchInitFilters, viewItem, fields]);

	const handleClickResetButton = useCallback(() => {
		if (viewItem && fields) {
			setFiltersPreset(null);
			resetForm(
				isCommonFilters ? flatCommonFilters(initFilters['root']) : getViewItemInitFilters(viewItem, fields),
				true,
			);
		}
	}, [viewItem, isCommonFilters, resetForm, setFiltersPreset, fields, getViewItemInitFilters, initFilters]);

	useOnClickOutside(rightPanelRef, handleClose, document.getElementById('root'));

	const [openPresetForm, togglePresetForm] = useToggle(false);

	return (
		<>
			<ModalPopup
				open={openPresetForm}
				onClose={togglePresetForm}
				maxWidth={500}
				header={
					!filtersPreset
						? 'Сохранить значения общих фильтров'
						: `Вы уверены что хотите изменить значения общих фильтров ${filtersPreset?.title}?`
				}
			>
				{hasCommonFiltersPresets && (
					<CommonFiltersPresetForm
						id={filtersPreset && filtersPreset.id}
						dashboardId={id}
						version={version}
						commonFiltersValues={formikBag.values}
						onClose={togglePresetForm}
						onChangeFiltersPreset={handleChangeFiltersPreset}
					/>
				)}
			</ModalPopup>
			{CloseConfirm}
			<RightPanel
				open={open}
				onClose={handleClose}
				forwardRef={rightPanelRef}
				className={css.rightPanel}
				topStickyElements={
					hasCommonFiltersPresets && (
						<Selector<CommonFiltersPreset | null>
							items={[null, ...(presetFilterData?.commonFiltersPresetList || [])]}
							label={'Список предустановленных значений фильтров'}
							itemToString={item => (!item ? 'Не выбрано' : item?.title || '')}
							value={filtersPreset}
							onChange={handleChangeFiltersPreset}
						/>
					)
				}
			>
				{waiting || loading || !formikValuesInitialized ? (
					<ProgressRing />
				) : error ? (
					<ErrorSnippet error={error} refetch={refetch} />
				) : (
					<FormikProvider value={formikBag}>
						<FormikForm>
							<ul className={css.list}>
								{fields && (
									<DashboardFields
										fields={Object.values(fields).map(field => ({
											...field,
											name: `${field.id}`,
										}))}
										fieldsProps={fieldsProps}
									/>
								)}
							</ul>
							<div className={css.stickyButtons}>
								<Button
									type="submit"
									disabled={!formikBag.dirty && !unsubmittedValues}
									title={'Применить фильтры'}
								>
									<CheckIcon />
									Применить
								</Button>
								{hasCommonFiltersPresets && (
									<Button secondary onClick={togglePresetForm} title={'Сохранить пресет фильтров'}>
										<SaveIcon />
										Сохранить
									</Button>
								)}
								<Button
									disabled={!canMakeReset}
									className={css.resetButton}
									secondary
									title={'Сбросить на значения по умолчанию'}
									onClick={handleClickResetButton}
								>
									Сбросить
								</Button>
							</div>
						</FormikForm>
					</FormikProvider>
				)}
			</RightPanel>
		</>
	);
};

export default Filters;
