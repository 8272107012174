exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".AlertsAnomaliesPage-module__list__1_wsl li {\r\n\t\tlist-style: disc;\r\n\t\toverflow-wrap: anywhere;\r\n\t}\r\n\r\n.AlertsAnomaliesPage-module__selectorInput__2qbJ4 {\r\n\tpadding-left: 10px;\r\n}\r\n\r\n.AlertsAnomaliesPage-module__spacer__Cy8Xr {\r\n\theight: 78px;\r\n\tposition: relative;\r\n}\r\n\r\n.AlertsAnomaliesPage-module__filterGround__3ayCI {\r\n\twidth: 100%;\r\n\tpadding-bottom: 10px;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\AlertsAnomaliesPage\\AlertsAnomaliesPage.module.css"],"names":[],"mappings":"AACC;EACC,gBAAgB;EAChB,uBAAuB;CACxB;;AAGD;CACC,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,oBAAoB;AACrB","file":"AlertsAnomaliesPage.module.css","sourcesContent":[".list {\r\n\tli {\r\n\t\tlist-style: disc;\r\n\t\toverflow-wrap: anywhere;\r\n\t}\r\n}\r\n\r\n.selectorInput {\r\n\tpadding-left: 10px;\r\n}\r\n\r\n.spacer {\r\n\theight: 78px;\r\n\tposition: relative;\r\n}\r\n\r\n.filterGround {\r\n\twidth: 100%;\r\n\tpadding-bottom: 10px;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"list": "AlertsAnomaliesPage-module__list__1_wsl",
	"selectorInput": "AlertsAnomaliesPage-module__selectorInput__2qbJ4",
	"spacer": "AlertsAnomaliesPage-module__spacer__Cy8Xr",
	"filterGround": "AlertsAnomaliesPage-module__filterGround__3ayCI"
};