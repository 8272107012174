import React, {useRef} from 'react';
import {useField, useFormikContext} from 'formik';
import {default as OriginalInput} from '../../pirsInputs/Combobox/Combobox';
import {useApolloClient} from '@apollo/client';
import {
	DictionaryDocument,
	DictionaryItem,
	DictionaryQuery,
	DictionaryQueryVariables,
	useDictionaryQuery,
} from '../../../queries-generated/types';

function DictionaryCombobox(props: {
	name: string;
	onCustomChange?: (value: DictionaryItem | null) => void;
	/* Название словаря */
	dictionary: string;
	/* Доступные ключи */
	dictionaryKeys?: string[];
	/* Доступные значения */
	dictionaryValues?: string[];
	label?: React.ReactNode | string;
	disabled?: boolean;
}) {
	const [field, meta] = useField<{key: string; value: string}>(props as any);
	const {setFieldValue} = useFormikContext();
	const {onCustomChange, ...fieldProps} = props;
	const initValue = useRef((field.value as any).key as string);

	const {data} = useDictionaryQuery({
		variables: {
			id: props.dictionary,
			keys: [initValue.current],
		},
		skip: !initValue.current,
	});
	const client = useApolloClient();

	const value = field.value.key && !field.value.value ? data?.dictionary[0] : field.value;

	return (
		<OriginalInput<DictionaryItem>
			{...field}
			{...(fieldProps as any)}
			error={meta.error}
			value={value}
			getItems={value => {
				if (!value) return null;
				return client
					.query<DictionaryQuery, DictionaryQueryVariables>({
						query: DictionaryDocument,
						variables: {
							id: props.dictionary,
							suggest: value,
							keys: props.dictionaryKeys,
						},
					})
					.then(result => result.data.dictionary);
			}}
			itemToString={value => value?.value || ''}
			onChange={value => {
				setFieldValue(field.name, value);
				if (onCustomChange) onCustomChange(value);
			}}
		/>
	);
}

export default DictionaryCombobox;
