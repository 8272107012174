import {useApolloClient} from '@apollo/client';
import {useCallback} from 'react';
import {
	RefDataDocument,
	RefDataQuery,
	RefDataQueryVariables,
	PossibleValue,
	DependencyFilter,
} from '../../../../../../queries-generated/types';

type Props = {
	type: string;
	minLength?: number;
	dependencyFilters?: DependencyFilter[];
};

const useRefData = () => {
	const client = useApolloClient();

	return useCallback(({type, minLength = 1, dependencyFilters}: Props) => {
		return (searchQuery: string) => {
			return new Promise<PossibleValue[]>(resolve => {
				if (searchQuery.length >= minLength) {
					client
						.query<RefDataQuery, RefDataQueryVariables>({
							query: RefDataDocument,
							variables: {
								type,
								searchQuery,
								dependencyFilters,
							},
						})
						.then(result => {
							resolve(result.data.refData);
						});
				} else resolve([]);
			});
		};
	}, []);
};
export default useRefData;
