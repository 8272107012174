import {ViewItem} from './api/useViewItemTree';
import useUserFilters from './api/useUserFilters';
import {useCallback, useMemo, useState} from 'react';
import RefreshActionForm from './RefreshActionForm';
import {AcrobatIcon, DownloadIcon, ExcelIcon, FilterIcon, RefreshIcon, TimerIcon, WordIcon} from '../SvgIcon';
import * as React from 'react';
import Button from '../pirsInputs/Button/Button';
import {useDownloadFile} from '../../utils/downloadFile';
import PopoverButton from '../controlls/PopoverButton/PopoverButton';
import useUnsubmittedValues from '../unsubmittedValues/useUnsubmittedValues';
import Badge from '../Badge/Badge';
import {RefreshType} from '../settings/views/components/helper';
import Selector from '../pirsInputs/Selector/Selector';
import Checkbox from '../pirsInputs/Checkbox/Checkbox';
import css from './useComponentCommonButtons.module.css';

export type CommonButtons = {
	FilterAction?: JSX.Element;
	RefreshAction?: JSX.Element;
	ReloadAction?: JSX.Element;
	DownloadAction?: JSX.Element;
};

export type ComponentCommonButtonsProps = {
	viewId?: string;
	reloadCounter?: number;
	setReloadCounter?: (counter: number) => void;
	componentsProps?: React.MutableRefObject<PlainObjectOf<PlainObjectOf<any>>>;
	componentsData?: React.MutableRefObject<PlainObjectOf<PlainObjectOf<any>>>;
};

enum DownloadFileFormats {
	EXCEL = 'excel',
	WORD = 'word',
	PDF = 'pdf',
}
const defaultDownloadAction = {
	id: DownloadFileFormats.EXCEL,
	title: 'Excel',
	icon: <ExcelIcon width={24} height={24} />,
};
const downloadActions = [
	defaultDownloadAction,
	{id: DownloadFileFormats.WORD, title: 'Word', icon: <WordIcon width={24} height={24} />},
	{id: DownloadFileFormats.PDF, title: 'PDF', icon: <AcrobatIcon width={24} height={24} />},
];

function useComponentCommonButtons(viewItem: ViewItem, props?: ComponentCommonButtonsProps): CommonButtons {
	const isRoot = viewItem.id === 'root';
	const userFilters = useUserFilters();
	const {viewId, reloadCounter, setReloadCounter, componentsData, componentsProps} = props || {};
	const {unsubmittedValues} = useUnsubmittedValues(`${userFilters?.id}-${viewItem?.id}-filters`);

	const [downloadActionItem, setDownloadActionItem] = useState(defaultDownloadAction);
	const [fullExport, setFullExport] = useState(false);

	const handleToggleFilters = useCallback(() => {
		if (!userFilters) return;
		if (userFilters.openComponent === viewItem.id) {
			userFilters.close();
		} else {
			userFilters.setOpenComponent(viewItem.id);
		}
	}, [userFilters, viewItem]);

	const matchInitFilters = useMemo(() => {
		if (userFilters && viewItem.id && userFilters.matchInitFilters(viewItem, [])) {
			return true;
		}
	}, [userFilters?.filters, viewItem.id]);

	const [downloadFile, {loading: loadingFile}] = useDownloadFile({method: 'POST'});

	const handleDownload = useCallback(() => {
		let url: string;

		switch (downloadActionItem.id) {
			case DownloadFileFormats.EXCEL:
				url = '/services/dashboard/' + viewId + '/excel';
				break;
			case DownloadFileFormats.WORD:
				url = '/services/dashboard/' + viewId + '/word';
				break;
			case DownloadFileFormats.PDF:
				url = '/services/dashboard/' + viewId + '/pdf';
				break;
		}

		downloadFile(url, undefined, {
			filters: userFilters?.filters,
			props: componentsProps?.current,
			dataSources: componentsData?.current,
			fullExport,
		});
	}, [downloadActionItem, fullExport, viewId, userFilters, downloadFile]);

	const fullExportAction = useMemo(() => {
		return viewItem.actions?.find(action => action.props?.isFullExportEnabled);
	}, [viewItem]);

	const refresh = useCallback(() => {
		if (setReloadCounter && reloadCounter != null) setReloadCounter(reloadCounter + 1);
	}, [reloadCounter, setReloadCounter]);

	const refreshAction = useMemo(() => {
		if (viewItem.actions && viewItem.actions.length) {
			const action = viewItem.actions.find(action => action.props?.refreshType);
			if (isRoot && action && action.props.refreshType.id !== RefreshType.COMPONENT) return;
			return action;
		}
	}, [viewItem]);

	const hasUserDataSourceFilters = useMemo((): boolean => {
		const filteredByUser = viewItem.dataSource?.filteredByUser;
		return !!(filteredByUser && filteredByUser.length);
	}, [viewItem]);

	const hasCommonRootFilters = useMemo((): boolean => {
		return !!(isRoot && viewItem.commonFilters && viewItem.commonFilters.length);
	}, [viewItem]);

	if (!userFilters) return {};

	let RefreshAction;
	if (refreshAction) {
		RefreshAction = (
			<PopoverButton
				popover={<RefreshActionForm viewItem={viewItem} viewItemRefreshAction={refreshAction} />}
				hasChevron
				action
				iconOnly
			>
				<TimerIcon />
			</PopoverButton>
		);
	}

	let FilterAction;
	if (hasUserDataSourceFilters || (isRoot && hasCommonRootFilters)) {
		const button = (
			<Button
				action
				iconOnly
				onClick={handleToggleFilters}
				checked={userFilters.openComponent === viewItem.id || !matchInitFilters}
			>
				<FilterIcon />
			</Button>
		);
		if (unsubmittedValues) {
			FilterAction = <Badge badgeContent={'*'}>{button}</Badge>;
		} else {
			FilterAction = button;
		}
	}

	let ReloadAction;
	if (viewId && isRoot) {
		ReloadAction = (
			<Button action iconOnly onClick={refresh}>
				<RefreshIcon />
			</Button>
		);
	}

	let DownloadAction;
	if (viewId && isRoot) {
		DownloadAction = (
			<PopoverButton
				hasChevron
				action
				iconOnly
				withBackdrop
				popover={
					<div className={css.downloadPopover}>
						<Selector<any>
							items={downloadActions}
							value={downloadActionItem}
							onChange={item => setDownloadActionItem(item || defaultDownloadAction)}
							itemToString={item => item.title}
							disabled={loadingFile}
						/>
						{fullExportAction && (
							<Checkbox
								className={css.downloadCheckbox}
								onChange={setFullExport}
								checked={fullExport}
								label={'Выгрузить весь список'}
								disabled={loadingFile}
							/>
						)}
						<Button
							className={css.downloadButton}
							secondary
							onClick={handleDownload}
							disabled={loadingFile}
							loading={loadingFile}
						>
							Сформировать
						</Button>
					</div>
				}
			>
				<DownloadIcon />
			</PopoverButton>
		);
	}

	return {
		RefreshAction,
		FilterAction,
		ReloadAction,
		DownloadAction,
	};
}

export function renderCommonButtons(commonButtons?: CommonButtons) {
	return (
		<>
			{commonButtons?.DownloadAction}
			{commonButtons?.ReloadAction}
			{commonButtons?.RefreshAction}
			{commonButtons?.FilterAction}
		</>
	);
}

export default useComponentCommonButtons;
