import React from 'react';
import {Table} from '@material-ui/core';
import css from './ResourceTable.module.css';
import ResourceTableBody from './ResourceTableBody';
import ResourceTableHead from './ResourceTableHead';
import {ApolloError} from '@apollo/client';
import {SortOrder, ResourceRegisterTable} from '../../queries-generated/types';
import ProgressRing from '../controlls/Loader/ProgressRing';
import ErrorSnippet from '../ErrorSnippet/ErrorSnippet';

type Props = {
	onDelete?: (id: any) => void;
	refetch: () => void;
	tableData?: ResourceRegisterTable;
	handleChangeSort: (order: string) => void;
	error?: ApolloError;
	loading?: boolean;
	sort?: SortOrder;
	sortBy?: string;
	keyword?: string;
};

const ResourceTable: React.FC<Props> = ({
	keyword,
	tableData,
	error,
	refetch,
	loading,
	onDelete,
	sortBy,
	sort,
	handleChangeSort,
}) => {
	return (
		<div className={css.tableContainer}>
			{error && (
				<ErrorSnippet
					errorHeader={'Произошла ошибка загрузки данных'}
					error={
						'Пожалуйста, попробуйте перезагрузить страницу. Если ошибка сохраняется, обратитесь в техническую поддержку'
					}
					refetch={refetch}
				/>
			)}
			{loading && (
				<div className={css.loadingBlock}>
					<ProgressRing />
				</div>
			)}
			{!loading && !error && tableData && (
				<Table size={'small'}>
					<ResourceTableHead
						sort={sort}
						sortBy={sortBy}
						handleChangeSort={handleChangeSort}
						tableHead={tableData.headers}
					/>
					<ResourceTableBody
						keyword={keyword}
						onDelete={onDelete}
						tableItems={tableData.items}
						columnsLength={tableData.headers.length + 1}
					/>
				</Table>
			)}
		</div>
	);
};

export default ResourceTable;
