import React, {useCallback, useRef} from 'react';
import {FormikContextType} from 'formik';
import {Keys} from '../../utils/key-enum';
import {getError} from '../../utils/error';
import TextArea from '../pirsInputs/TextArea/TextArea.formik';

type Props = {
	error?: any;
	formikBag: FormikContextType<Values>;
};

export type Values = {
	text: string;
};

const MessageTextArea: React.FC<Props> = ({error, formikBag}) => {
	const inputRef = useRef<HTMLTextAreaElement>(null);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
			if (e.keyCode === Keys.ENTER && !e.ctrlKey && !e.shiftKey) {
				e.preventDefault();
				if (!formikBag.isSubmitting) formikBag.submitForm();
			}

			const input = inputRef.current;

			if (input && e.ctrlKey && e.keyCode === Keys.ENTER) {
				let value = '';
				const breakText = '\n';
				if (input.selectionStart || input.selectionStart === 0) {
					const startPos = input.selectionStart;
					const endPos = input.selectionEnd;
					value =
						formikBag.values.text.substring(0, startPos) +
						breakText +
						formikBag.values.text.substring(endPos, formikBag.values.text.length);
					setTimeout(() => {
						input.selectionStart = startPos + breakText.length;
						input.selectionEnd = input.selectionStart;
					});
				} else {
					value += breakText;
				}

				formikBag.setFieldValue('text', value);
			}
		},
		[formikBag],
	);

	return (
		<TextArea
			placeholder={'Введите сообщение. Нажмите Ctrl + Enter для вставки переноса строки'}
			setRef={inputRef}
			name={'text'}
			rows={1}
			onKeyDown={handleKeyDown}
			autoHeightAdjustable
			error={getError(error)?.message}
		/>
	);
};

export default MessageTextArea;
