const MAX_WIDTH = 100;
const MAX_HEIGHT = 100;

function getImageDataUrl(file: File, maxWidth = MAX_WIDTH, maxHeight = MAX_HEIGHT) {
	return new Promise<string>(resolve => {
		const reader = new FileReader();
		reader.onload = function(e) {
			const img = document.createElement('img');
			img.onload = () => {
				let width = img.width;
				let height = img.height;

				if (width > height) {
					if (width > maxWidth) {
						height *= maxWidth / width;
						width = maxWidth;
					}
				} else {
					if (height > maxHeight) {
						width *= maxHeight / height;
						height = maxHeight;
					}
				}

				const canvas = document.createElement('canvas');
				canvas.width = width;
				canvas.height = height;
				const ctx = canvas.getContext('2d');

				if (ctx) {
					ctx.drawImage(img, 0, 0, width, height);

					resolve(canvas.toDataURL(file.type));
				}
			};
			img.src = e.target?.result as string;
		};
		reader.readAsDataURL(file);
	});
}

export default getImageDataUrl;
