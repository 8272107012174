import React, {useCallback, useContext, useEffect, useState} from 'react';
import HomePage from './pages/HomePage/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import AsLinksDetailPage from './pages/AsLinksDetailPage/AsLinksDetailPage';
import ReportsTop from './pages/ReportsTopPage/ReportsTop';
import SettingsInterfacePage from './pages/settings/SettingsInterfacePage';
import AsLinksTopPage from './pages/AsLinksTopPage/AsLinksTopPage';
import SettingsWssourcePage from './pages/settings/SettingsWssourcePage';
import Router, {Route} from 'react-named-hooks-router';
import ViewListUpdatePage from './pages/settings/views/ViewsListUpdatePage';
import ViewPage from './pages/ViewPage/ViewPage';
import AlertsStatisticPage from './pages/AlertsStatisticPage/AlertsStatisticPage';
import AlertsAnomaliesPage from './pages/AlertsAnomaliesPage/AlertsAnomaliesPage';
import AuthorizeByThirdPartyTokenPage from './pages/AuthorizeByThirdPartyTokenPage/AuthorizeByThirdPartyTokenPage';
import useAuth, {makeCodeRedirect} from './components/hooks/useAuth';
import AuthorizePage from './pages/AuthorizePage/AuthorizePage';
import IncidentsPage from './pages/IncidentsPage/IncidentsPage';
import SurveySummaryPage from './pages/SurveySummaryPage/SurveySummaryPage';
import SurveyResourceOwnersPage from './pages/SurveyResourceOwnersPage/SurveyResourceOwnersPage';
import SurveyResourceAsPage from './pages/SurveyResourceAsPage/SurveyResourceAsPage';
import RequestsPage from './pages/RequestsPage/RequestsPage';
import SettingsSchedulePage from './pages/settings/SettingsSchedulePage';
import SettingsSavedItemsPage from './pages/settings/SettingsSavedItemsPage';
import SettingsReportTemplatesPage from './pages/settings/SettingsReportTemplatesPage/SettingsReportTemplatesPage';
import {writeSessionStorage} from './components/hooks/useSessionStorage';
import FileConsolePage from './pages/FileConsolePage/FileConsolePage';
import FilesConsolePage from './pages/FilesConsolePage/FilesConsolePage';
import FilesControlPage from './pages/FilesControlPage/FilesControlPage';
import FilesControlByOrganizationPage from './pages/FilesControlByOrganizationPage/FilesControlByOrganizationPage';
import LogoutPage from './pages/LogoutPage/LogoutPage';
import SettingsFilesPage from './pages/SettingsFilesPage/SettingsFilesPage';
import {CommonContext} from './components/commonRedux/CommonProvider';
import useUser, {useSessionPopup} from './components/hooks/useUser';
import {useUserQuery} from './queries-generated/types';
import SettingsRulePage from './pages/settings/SettingsRulePage/SettingsRulePage';
import ResourceRegisterInstancePage from './pages/ResourceRegisterInstancePage/ResourceRegisterInstancePage';
import ResourceRegisterPage from './pages/ResourceRegisterPage/ResourceRegisterPage';
import ResourceAttachmentPage from './pages/ResourceAttachmentPage/ResourceAttachmentPage';
import IntegrationConfigurationTablePage from './pages/IntegrationRulesTablePage/IntegrationConfigurationTablePage';
import IntegrationRulesEditorPage from './pages/IntegrationRulesEditorPage/IntegrationRulesEditorPage';
import RouterConfigurationPage from './pages/RouterConfigurationPage/RouterConfigurationPage';
import RouterConfigurationRulePage from 'pages/RouterConfigurationRulePage/RouterConfigurationRulePage';
import IntegrationNotificationStatusPage from './pages/IntegrationNotificationStatusPage/IntegrationNotificationStatusPage';
import RecipientGroupPage from './pages/RecipientGroupPage/RecipientGroupPage';
import RecipientGroupRulePage from './pages/RecipientGroupRulePage/RecipientGroupRulePage';
import IntegrationDataSourcePage from './pages/IntegrationDataSourcePage/IntegrationDataSourcePage';
import IntegrationModulesPage from './pages/IntegrationModulesPage/IntegrationModulesPage';
import IntegrationDetailedNotificationStatusPage from './pages/IntegrationDetailedNotificationStatusPage/IntegrationDetailedNotificationStatusPage';
import IntegrationUniversalScriptTablePage from './pages/IntegrationUniversalScriptTablePage/IntegrationUniversalScriptTablePage';
import IntegrationTranslatorsScriptTablePage from './pages/IntegrationTranslatorsScriptTablePage/IntegrationTranslatorsScriptTablePage';
import IntegrationTranslatorsScriptEditorPage from './pages/IntegrationTranslatorsScriptEditorPage/IntegrationTranslatorsScriptEditorPage';
import IntegrationUniversalScriptEditorPage from './pages/IntegrationUniversalScriptEditorPage/IntegrationUniversalScriptEditorPage';
import IntegrationCorrelatorConfigurationPage from './pages/IntegrationCorrelatorConfigurationPage/IntegrationCorrelatorConfigurationPage';
import IntegrationUniversalScriptAdapterPage from './pages/IntegrationUniversalScriptAdapterPage/IntegrationUniversalScriptAdapterPage';
import RecipientBindPage from './pages/RecipientBindPage/RecipientBindPage';
import IntegrationTranslatorRulePage from './pages/IntegrationTranslatorRulePage/IntegrationTranslatorRulePage';
import IntegrationNotificationsRecipientsPage from './pages/IntegrationNotificationsRecipientsPage/IntegrationNotificationsRecipientsPage';
import ModalPopup from './components/controlls/ModalPopup/ModalPopup';
import Button from './components/pirsInputs/Button/Button';
import SurveySummaryChartsPage from './pages/SurveySummaryChartsPage/SurveySummaryChartsPage';
import ViewListPage from './pages/ViewListPage/ViewListPage';
import View2Page from './pages/View2Page/View2Page';
import AlertsSuperAnomaliesActivePage from './pages/AlertsSuperAnomaliesActivePage/AlertsSuperAnomaliesActivePage';
import AlertsSuperAnomaliesNestedPage from './pages/AlertsSuperAnomaliesNestedPage/AlertsSuperAnomaliesNestedPage';
import AlertsSuperAnomaliesIgnoredPage from './pages/AlertsSuperAnomaliesIgnoredPage/AlertsSuperAnomaliesIgnoredPage';
import PadAnomalyThresholdsPage from './pages/PadAnomalyThresholdsPage/PadAnomalyThresholdsPage';
import PadNetflowThresholdPage from './pages/PadNetflowThresholdPage/PadNetflowThresholdPage';
import PadCatalogsPage from './pages/PadCatalogsPage/PadCatalogsPage';
import ICMPCodeTable from './pages/PadCatalogsPage/PadCatalogTables/ICMPCodeTable';
import AsGroupAsObjectsTable from './pages/PadCatalogsPage/PadCatalogTables/AsGroupAsObjectsTable';
import ServiceProtocolsTable from './pages/PadCatalogsPage/PadCatalogTables/ServiceProtocolsTable';
import ServiceProtocolsServiceProtocolGroupTable from './pages/PadCatalogsPage/PadCatalogTables/ServiceProtocolsServiceProtocolGroupTable';
import OperatorGroupOperatorsTable from './pages/PadCatalogsPage/PadCatalogTables/OperatorGroupOperatorsTable';
import PadNetworkServicePage from './pages/PadNetworkServicePage/PadNetworkServicePage';
import PadTaskPage from './pages/PadTaskPage/PadTaskPage';
import PadNetworkServiceDetailsPage from './pages/PadNetworkServiceDetailsPage/PadNetworkServiceDetailsPage';
import PadTaskDetailsPage from './pages/PadTaskDetailsPage/PadTaskDetailsPage';
import PadSuperAnomalyPriorityRulePage from './pages/PadSuperAnomalyPriorityRulePage/PadSuperAnomalyPriorityRulePage';
import PadSuperAnomalyIssueRulePage from './pages/PadSuperAnomalyIssueRulePage/PadSuperAnomalyIssueRulePage';
import AsLinksRegistriesPage from './pages/AsLinksRegistriesPage/AsLinksRegistriesPage';
import PadTrackingAsPage from './pages/PadTrackingAsPage/PadTrackingAsPage';
import PadTrackingOperatorPage from './pages/PadTrackingOperatorPage/PadTrackingOperatorPage';
import SettingsReportTemplatesEditPage from './pages/settings/SettingsReportTemplatesEditPage/SettingsReportTemplatesEditPage';
import AlertsSpreadPage from './pages/AlertsSpreadPage/AlertsSpreadPage';
import SurveyReportTemplatesPage from './pages/SurveyReportTemplatesPage/SurveyReportTemplatesPage';
import SettingsAuditPage from './pages/settings/SettingsAuditPage/SettingsAuditPage';
import SurveyReportsPage from 'pages/SurveyReportsPage/SurveyReportsPage';
import ReportsTrafficAnalysisPage from './pages/ReportsTrafficAnalysisPage/ReportsTrafficAnalysisPage';
import PadApiConfigurationPage from 'pages/PadApiConfigurationPage/PadApiConfigurationPage';
import InformationContactsPage from './pages/InformationContactsPage/InformationContactsPage';
import DirectiveContactsPage from './pages/DirectiveContactsPage/DirectiveContactsPage';
import DirectiveOrganizationsPage from './pages/DirectiveOrganizationsPage/DirectiveOrganizationsPage';
import DirectiveMailingGroupsPage from './pages/DirectiveMailingGroupsPage/DirectiveMailingGroupsPage';
import DirectiveUnconfirmedChangesPage from './pages/DirectiveCHPage/DirectiveUnconfirmedChangesPage';
import DirectiveChangesHistoryPage from './pages/DirectiveChangesHistoryPage/DirectiveChangesHistoryPage';
import DirectiveDirectivesPage from './pages/DirectiveDirectivesPage/DirectiveDirectivesPage';
import DirectiveDirectiveDraftsPage from './pages/DirectiveDirectiveDraftsPage/DirectiveDirectiveDraftsPage';
import DirectiveDirectiveInstancesPage from './pages/DirectiveDirectiveInstancesPage/DirectiveDirectiveInstancesPage';
import DirectiveDirectivePage from './pages/DirectiveDirectivePage/DirectiveDirectivePage';
import DirectiveOrganizationPage from './pages/DirectiveOrganizationPage/DirectiveOrganizationPage';
import InformationAnomaliesPage from './pages/InformationAnomaliesPage/InformationAnomaliesPage';
import SettingsTokensPage from './pages/SettingsTokensPage/SettingsTokensPage';
import InformationApiAccessPage from './pages/InformationApiAccessPage/InformationApiAccessPage';
import AlertsSuperAnomaliesResourceOwnersPage from './pages/AlertsSuperAnomaliesResourceOwnersPage/AlertsSuperAnomaliesResourceOwnersPage';
import makeId from './utils/makeId';
import SurveyTrafficExchangePointsPage from './pages/SurveyTrafficExchangePointsPage/SurveyTrafficExchangePointsPage';
import SurveyTrafficExchangePointsDetailsPage from './pages/SurveyTrafficExchangePointsDetailsPage/SurveyTrafficExchangePointsDetailsPage';
import AsLinksDetailsPage from './pages/AsLinksDetailPage/AsLinksDetailsPage';
import SurveyCrossBorderCommunicationLinesPage from './pages/SurveyCrossBorderCommunicationLinesPage/SurveyCrossBorderCommunicationLinesPage';

export type MainMenuAccessToken = {
	permissions: {mainMenuAccess?: string[]};
};

let allRoutes: Route[] = [];
let ssoRoutes: Route[] = [];
if (process.env.REACT_APP_CROP === 'true') {
	allRoutes = [
		{name: 'home', path: '/', page: HomePage},
		{name: 'logout', path: '/logout', page: LogoutPage},
		{name: 'filesConsole', path: '/information', page: FilesConsolePage},
		{name: 'fileConsole', path: '/information/file/:id', page: FileConsolePage},
		{name: 'informationContacts', path: '/information/contacts', page: InformationContactsPage},
		{name: 'incidents', path: '/incidents', page: IncidentsPage},
		{name: 'resourceRegister', path: '/resourceRegister', page: ResourceRegisterPage},
		{name: 'resourceRegisterInstance', path: '/resourceRegister/:type/:id', page: ResourceRegisterInstancePage},
		{name: 'resourceAttachments', path: '/resourceAttachments/:id', page: ResourceAttachmentPage},
	];
	ssoRoutes = [
		{name: 'logout', path: '/logout', page: LogoutPage},
		{name: 'home', path: '/', page: HomePage},
		{name: 'authorizeByThirdPartyToken', path: '/sso2', page: AuthorizeByThirdPartyTokenPage},
		{name: 'authorizeByThirdPartyToken2', path: '/sso2/:token', page: AuthorizeByThirdPartyTokenPage},
	];
} else {
	allRoutes = [
		{name: 'home', path: '/', page: HomePage},
		{name: 'asLinksTop', path: '/aslinks', page: AsLinksTopPage},
		{name: 'asLinksDetail', path: '/aslinks/detail', page: AsLinksDetailPage},
		{name: 'asLinksDetails', path: '/aslinks/details', page: AsLinksDetailsPage},
		{name: 'asLinksRegistries', path: '/aslinks/registries', page: AsLinksRegistriesPage},

		{name: 'reportsTop', path: '/reports', page: ReportsTop},
		{name: 'reportsTrafficAnalysis', path: '/reports/traffic-analysis', page: ReportsTrafficAnalysisPage},
		{name: 'view', path: '/views/:id', page: ViewPage},
		{name: 'alertsStatistic', path: '/alerts', page: AlertsStatisticPage},
		{name: 'alertsAnomalies', path: '/alerts/anomalies', page: AlertsAnomaliesPage},
		{
			name: 'alertsSuperAnomaliesActive',
			path: '/alerts/superAnomalies/active',
			page: AlertsSuperAnomaliesActivePage,
		},
		{
			name: 'alertsSuperAnomaliesNested',
			path: '/alerts/superAnomalies/nested',
			page: AlertsSuperAnomaliesNestedPage,
		},
		{
			name: 'alertsSuperAnomaliesIgnored',
			path: '/alerts/superAnomalies/ignored',
			page: AlertsSuperAnomaliesIgnoredPage,
		},
		{
			name: 'alertsSpread',
			path: '/alerts/spread/:id',
			page: AlertsSpreadPage,
		},
		{
			name: 'alertsSuperAnomaliesResourceOwners',
			path: '/alerts/superAnomalies/resourceOwners/:id',
			page: AlertsSuperAnomaliesResourceOwnersPage,
		},
		{
			name: 'padAnomalyThresholds',
			path: '/pad/anomaly/thresholds',
			page: PadAnomalyThresholdsPage,
		},
		{
			name: 'padNetflowThresholds',
			path: '/pad/netflow/thresholds',
			page: PadNetflowThresholdPage,
		},
		{
			name: 'padCatalogs',
			path: '/pad/catalogs',
			page: PadCatalogsPage,
		},
		{
			name: 'padNetworkService',
			path: '/pad/network-service',
			page: PadNetworkServicePage,
		},
		{
			name: 'padNetworkServiceDetails',
			path: '/pad/network-service/:id',
			page: PadNetworkServiceDetailsPage,
		},
		{
			name: 'padICMPCode',
			path: '/pad/padICMPCode',
			page: ICMPCodeTable,
		},
		{
			name: 'padAsGroupAsObjects',
			path: '/pad/asGroupAsObjects',
			page: AsGroupAsObjectsTable,
		},
		{
			name: 'padServiceProtocols',
			path: '/pad/serviceProtocols',
			page: ServiceProtocolsTable,
		},
		{
			name: 'padServiceProtocolsServiceProtocolGroup',
			path: '/pad/serviceProtocolsServiceProtocolGroup',
			page: ServiceProtocolsServiceProtocolGroupTable,
		},
		{
			name: 'padOperatorGroupOperators',
			path: '/pad/operatorGroupOperators',
			page: OperatorGroupOperatorsTable,
		},
		{
			name: 'padTask',
			path: '/pad/task',
			page: PadTaskPage,
		},
		{
			name: 'padTaskDetails',
			path: '/pad/task/:id',
			page: PadTaskDetailsPage,
		},
		{
			name: 'padSuperAnomaliesPriorityRule',
			path: '/pad/superAnomalies/priorityRule',
			page: PadSuperAnomalyPriorityRulePage,
		},
		{
			name: 'padSuperAnomaliesIssueRule',
			path: '/pad/superAnomalies/issueRule',
			page: PadSuperAnomalyIssueRulePage,
		},
		{
			name: 'padTrackingAs',
			path: '/pad/tracking/as',
			page: PadTrackingAsPage,
		},
		{
			name: 'padTrackingOperator',
			path: '/pad/tracking/operator',
			page: PadTrackingOperatorPage,
		},
		{
			name: 'padApiConfiguration',
			path: '/pad/api-configuration',
			page: PadApiConfigurationPage,
		},
		{name: 'surveySummary', path: '/survey', page: SurveySummaryPage},
		{name: 'surveySummaryViews', path: '/survey/views', page: View2Page},
		{name: 'SurveyReportTemplates', path: '/survey/report-templates', page: SurveyReportTemplatesPage},
		{name: 'SurveyReports', path: '/survey/reports', page: SurveyReportsPage},
		{name: 'surveyResourceOwners', path: '/survey/resourceOwners', page: SurveyResourceOwnersPage},
		{name: 'surveyResourceAs', path: '/survey/resourceAs', page: SurveyResourceAsPage},
		{name: 'surveySummaryCharts', path: '/survey/summary/charts', page: SurveySummaryChartsPage},
		{
			name: 'surveyTrafficExchangePoints',
			path: '/survey/traffic-exchange-points',
			page: SurveyTrafficExchangePointsPage,
		},
		{
			name: 'surveyTrafficExchangePointsDetails',
			path: '/survey/traffic-exchange-points/details',
			page: SurveyTrafficExchangePointsDetailsPage,
		},
		{
			name: 'surveyCrossBorderCommunicationLines',
			path: '/survey/cross-border-communication-lines',
			page: SurveyCrossBorderCommunicationLinesPage,
		},
		{name: 'incidents', path: '/incidents', page: IncidentsPage},
		{name: 'requests', path: '/incidents/request', page: RequestsPage},
		{name: 'settingsInterface', path: '/settings', page: SettingsInterfacePage},
		{name: 'settingsWssource', path: '/settings/wssource', page: SettingsWssourcePage},
		{name: 'settingsViews', path: '/settings/views', page: ViewListPage},
		{name: 'settingsViewsUpdate', path: '/settings/views/:id', page: ViewListUpdatePage},
		{name: 'settingsSchedule', path: '/settings/schedule', page: SettingsSchedulePage},
		{name: 'settingsReportTemplates', path: '/settings/report-templates', page: SettingsReportTemplatesPage},
		{
			name: 'settingsReportTemplatesEdit',
			path: '/settings/report-templates/:id',
			page: SettingsReportTemplatesEditPage,
		},
		{
			name: 'settingsAudit',
			path: '/settings/audit',
			page: SettingsAuditPage,
		},
		{name: 'settingsSavedItems', path: '/settings/saved-items', page: SettingsSavedItemsPage},
		{name: 'settingsFile', path: '/settings/file', page: SettingsFilesPage},
		{name: 'settingsRule', path: '/settings/rules/:id', page: SettingsRulePage},
		{name: 'settingsTokens', path: '/settings/tokens', page: SettingsTokensPage},
		{name: 'directiveContacts', path: '/directive/contacts', page: DirectiveContactsPage},
		{
			name: 'directiveOrganizations',
			path: '/directive/organizations',
			page: DirectiveOrganizationsPage,
		},
		{
			name: 'directiveOrganization',
			path: '/directive/organizations/:org',
			page: DirectiveOrganizationPage,
		},
		{
			name: 'directiveMailingGroups',
			path: '/directive/mailing-groups',
			page: DirectiveMailingGroupsPage,
		},
		{
			name: 'directiveUnconfirmedChanges',
			path: '/directive/unconfirmed-changes',
			page: DirectiveUnconfirmedChangesPage,
		},
		{
			name: 'directiveChangesHistory',
			path: '/directive/changes-history',
			page: DirectiveChangesHistoryPage,
		},
		{
			name: 'directiveDirectives',
			path: '/directive/directives',
			page: DirectiveDirectivesPage,
		},
		{
			name: 'directiveDirective',
			path: '/directive/directives/:directiveInstanceId',
			page: DirectiveDirectivePage,
		},
		{
			name: 'directiveDirectiveDrafts',
			path: '/directive/directive-drafts',
			page: DirectiveDirectiveDraftsPage,
		},
		{
			name: 'directiveDirectiveInstances',
			path: '/directive/directive-instances/:directiveId',
			page: DirectiveDirectiveInstancesPage,
		},

		{
			name: 'integrationConfigurationTable',
			path: '/settings/integrationConfiguration/table',
			page: IntegrationConfigurationTablePage,
		},
		{
			name: 'integrationCorrelatorConfiguration',
			path: '/settings/integrationCorrelatorConfiguration/:id',
			page: IntegrationCorrelatorConfigurationPage,
		},
		{
			name: 'integrationRulesEditor',
			path: '/settings/integrationRules/editor',
			page: IntegrationRulesEditorPage,
		},
		{name: 'integrationDataSource', path: '/settings/integrationDataSource', page: IntegrationDataSourcePage},
		{name: 'integrationModules', path: '/settings/integrationModules', page: IntegrationModulesPage},
		{
			name: 'integrationUniversalScriptTable',
			path: '/settings/integrationUniversalScript/table',
			page: IntegrationUniversalScriptTablePage,
		},
		{
			name: 'integrationUniversalScriptEditor',
			path: '/settings/integrationUniversalScript/editor',
			page: IntegrationUniversalScriptEditorPage,
		},
		{
			name: 'integrationUniversalScriptAdapter',
			path: '/settings/integrationUniversalScriptAdapter',
			page: IntegrationUniversalScriptAdapterPage,
		},
		{
			name: 'integrationTranslatorsScriptTable',
			path: '/settings/integrationTranslatorsScript/table',
			page: IntegrationTranslatorsScriptTablePage,
		},
		{
			name: 'integrationTranslatorRule',
			path: '/settings/integrationTranslatorRule',
			page: IntegrationTranslatorRulePage,
		},
		{
			name: 'integrationTranslatorsScriptEditor',
			path: '/settings/integrationTranslatorsScript/editor',
			page: IntegrationTranslatorsScriptEditorPage,
		},
		{name: 'routerConfiguration', path: '/settings/routerConfiguration', page: RouterConfigurationPage},
		{name: 'routerConfigurationRule', path: '/settings/routerConfiguration/:id', page: RouterConfigurationRulePage},
		{
			name: 'recipientGroup',
			path: '/settings/recipientGroup',
			page: RecipientGroupPage,
		},
		{
			name: 'recipientGroupRule',
			path: '/settings/recipientGroup/:id',
			page: RecipientGroupRulePage,
		},
		{
			name: 'recipientBind',
			path: '/settings/recipientBind',
			page: RecipientBindPage,
		},
		{
			name: 'integrationNotificationStatus',
			path: '/settings/integrationNotificationStatus',
			page: IntegrationNotificationStatusPage,
		},
		{
			name: 'integrationDetailedNotificationStatus',
			path: '/settings/integrationNotificationStatus/:id',
			page: IntegrationDetailedNotificationStatusPage,
		},
		{
			name: 'integrationNotificationsRecipients',
			path: '/settings/integrationNotificationsRecipients',
			page: IntegrationNotificationsRecipientsPage,
		},
		{name: 'filesConsole', path: '/information', page: FilesConsolePage},
		{name: 'fileConsole', path: '/information/file/:id', page: FileConsolePage},
		{name: 'filesControl', path: '/information/control', page: FilesControlPage},
		{name: 'filesControlByOrganization', path: '/information/control/:id', page: FilesControlByOrganizationPage},
		{name: 'informationContacts', path: '/information/contacts', page: InformationContactsPage},
		{name: 'informationAnomalies', path: '/information/anomalies', page: InformationAnomaliesPage},
		{name: 'informationApiAccess', path: '/information/api-access', page: InformationApiAccessPage},

		{name: 'resourceRegister', path: '/resourceRegister', page: ResourceRegisterPage},
		{name: 'resourceRegisterInstance', path: '/resourceRegister/:type/:id', page: ResourceRegisterInstancePage},
		{name: 'resourceAttachments', path: '/resourceAttachments/:id', page: ResourceAttachmentPage},
	];
	ssoRoutes = [
		{name: 'home', path: '/', page: HomePage},
		{name: 'authorizeByThirdPartyToken', path: '/sso2', page: AuthorizeByThirdPartyTokenPage},
		{name: 'authorizeByThirdPartyToken2', path: '/sso2/:token', page: AuthorizeByThirdPartyTokenPage},
	];
}

const routeAccessByRoles = {
	canViewSettingsTokens: ['settingsTokens'],
	canViewInfoTokens: ['informationApiAccess'],
	canViewAsLinks: ['asLinksTop', 'asLinksDetail', 'asLinksDetails', 'asLinksRegistries'],
	canViewNetflow: ['reportsTop', 'reportsTrafficAnalysis'],
	canViewSettings: [
		//'settingsInterface',
		//'settingsWssource',
		'settingsSchedule',
		'settingsReportTemplates',
		'settingsReportTemplatesEdit',
		'settingsFile',
		'settingsRule',
	],
	canViewSettingsAudit: ['settingsAudit'],
	canViewInformationSubmitting: ['filesConsole', 'fileConsole', 'informationContacts'],
	canViewInfoAnomalies: ['informationAnomalies'],
	canViewPupContact: ['informationContacts'],
	canViewPup: [
		'directiveContacts',
		'directiveMailingGroups',
		'directiveOrganizations',
		'directiveOrganization',
		'directiveDirectives',
		'directiveDirective',
		'directiveDirectiveDrafts',
		'directiveDirectiveInstances',
	],
	canManagePup: [
		'directiveContacts',
		'directiveMailingGroups',
		'directiveOrganizations',
		'directiveOrganization',
		'directiveUnconfirmedChanges',
		'directiveChangesHistory',
		'directiveDirectives',
		'directiveDirective',
		'directiveDirectiveDrafts',
		'directiveDirectiveInstances',
	],
	canViewSettingsIntegrationConfigurations: [
		'integrationConfigurationTable',
		'integrationCorrelatorConfiguration',
		'integrationRulesEditor',
	],
	canViewSettingsIntegrationDatasources: ['integrationDataSource'],
	canViewSettingsIntegrationModules: ['integrationModules'],
	canViewSettingsIntegrationIntegrationscripts: [
		'integrationUniversalScriptTable',
		'integrationUniversalScriptEditor',
		'integrationUniversalScriptAdapter',
	],
	canViewSettingsIntegrationTranslatorscripts: [
		'integrationTranslatorsScriptTable',
		'integrationTranslatorsScriptEditor',
	],
	canViewSettingsIntegrationTranslatorrules: ['integrationTranslatorRule'],
	canViewSettingsNotificationConfigurations: ['routerConfiguration', 'routerConfigurationRule'],
	canViewSettingsNotificationGroups: ['recipientGroup', 'recipientGroupRule'],
	canViewSettingsNotificationStatistics: ['integrationNotificationStatus', 'integrationDetailedNotificationStatus'],
	canViewSettingsNotificationBindings: ['recipientBind'],
	canViewSettingsNotificationRecipients: ['integrationNotificationsRecipients'],
	canViewInformationControl: ['filesControl', 'filesControlByOrganization'],
	canViewViews: [
		/*'surveySummary', */ 'surveyResourceOwners',
		'surveyResourceAs',
		'surveySummaryCharts',
		'surveySummaryViews',
		'SurveyReportTemplates',
		'SurveyReports',
		'view',
		'surveyTrafficExchangePoints',
		'surveyTrafficExchangePointsDetails',
		'surveyCrossBorderCommunicationLines',
	],
	canViewReports: ['settingsSavedItems'],
	canViewIssiesAlertsStatistic: ['alertsStatistic', 'alertsAnomalies'],
	canViewAnomalies: [
		'alertsSuperAnomaliesActive',
		'alertsSuperAnomaliesNested',
		'alertsSuperAnomaliesIgnored',
		'alertsSpread',
		'padSuperAnomaliesPriorityRule',
		'padSuperAnomaliesIssueRule',
		'alertsSuperAnomaliesResourceOwners',
	],
	canViewSettingsPadAnomalyThresholds: ['padAnomalyThresholds'],
	canViewSettingsPadNetflowThresholds: ['padNetflowThresholds'],
	canViewSettingsPadCatalogs: [
		'padCatalogs',
		'padNetworkService',
		'padNetworkServiceDetails',
		'padICMPCode',
		'padAsGroupAsObjects',
		'padServiceProtocols',
		'padServiceProtocolsServiceProtocolGroup',
		'padOperatorGroupOperators',
		'padTask',
		'padTaskDetails',
		'padTrackingAs',
		'padTrackingOperator',
		'padApiConfiguration',
	],
	canViewIssiesIncidentsIncidents: ['incidents'],
	canViewIssiesIncidentsIssues: ['requests'],
	canViewInformationReestr: ['resourceRegister', 'resourceRegisterInstance', 'resourceAttachments'],
	canViewSettingsDashboardsDashboardlist: ['settingsViews', 'settingsViewsUpdate'],
};

const Routes: React.FC = () => {
	if (!window.sessionTraceId) {
		window.sessionTraceId = makeId(5);
	}

	if (!window.currentRoute) {
		window.currentRoute = 'no-page';
	}

	const handleLoadPage = (routeName: string) => {
		window.currentRoute = routeName;
	};

	const {loading: authLoading, error: authError} = useAuth();
	const {openSessionPopup} = useSessionPopup();

	const handleRestoreSession = useCallback(() => {
		if (process.env.REACT_APP_CROP === 'true') {
			location.href = '/logout';
		} else {
			location.href = '/';
		}
	}, []);

	const user = useUser();

	const sso = location.pathname.startsWith('/sso2') && !user.login;
	const logout = location.pathname.startsWith('/logout');
	const crop = process.env.REACT_APP_CROP === 'true';

	const skip = authLoading || !!authError || sso || logout;

	const {data, loading} = useUserQuery({skip});
	const {setUser} = useContext(CommonContext);

	useEffect(() => {
		if (!skip) {
			if (!loading) {
				if (!data?.user.login) {
					if (!crop) makeCodeRedirect();
				} else {
					setUser(data.user);
				}
			}
		}
	}, [data, loading, setUser, authError, authLoading, skip, crop]);

	useEffect(() => {
		if (data?.user && data.user.login !== user.login) setUser(data.user);
	}, [data, user.login, setUser]);

	const [allowedRoutes, setAllowedRoutes] = useState<Route[]>([]);

	useEffect(() => {
		const routeNames: string[] = [];

		if (sso) return;
		if (!routeNames.includes('logout')) routeNames.push('logout');
		if (user.login) {
			user.permissions.forEach(role => {
				if (!routeAccessByRoles[role]) console.error('Unknown role in token: ' + role);
				else routeAccessByRoles[role].forEach(route => routeNames.push(route));
			});
			if (!routeNames.includes('home')) routeNames.push('home');
		}
		const routes: Route[] = [];
		Array.from(routeNames).forEach(route => {
			const routeItem = allRoutes.find(element => element.name === route);
			if (routeItem) routes.push(routeItem);
		});
		setAllowedRoutes(routes);
	}, [user, sso]);

	if (user.login && allowedRoutes.length === 0) return null;

	const routes = sso ? ssoRoutes : allowedRoutes ? allowedRoutes : [];
	const allowedRoutesNames = routes.map(route => route.name);
	writeSessionStorage('allowed-routes', allowedRoutesNames);

	return (
		<>
			{!user.login && !sso && !logout ? (
				<AuthorizePage />
			) : (
				<Router routes={routes} notFoundPage={<NotFoundPage />} onLoadPage={handleLoadPage} />
			)}
			{openSessionPopup && (
				<ModalPopup open={true} maxWidth={400} isModal>
					<h2>Ваша сессия в системе истекла.</h2>
					<p>Пожалуйста, войдите снова.</p>
					<Button onClick={handleRestoreSession}>Войти</Button>
				</ModalPopup>
			)}
		</>
	);
};

export default Routes;
