import * as React from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import {useCallback, useState} from 'react';
import {debounce} from '../../utils/debounce';
import css from './FilesControlPage.module.css';
import {DocumentIcon} from '../../components/SvgIcon';
import InputSearch from '../../components/pirsInputs/InputSearch/InputSearch';
import Button from '../../components/pirsInputs/Button/Button';

enum StatusOrder {
	SUCCESS = 'success',
	FAILURE = 'failure',
}

const FilesControlPage: React.FC = () => {
	const [filter, setFilter] = useState<string>();

	const handleChangeInputFilter = useCallback(
		debounce(value => {
			if (value.length >= 3) {
				setFilter(value);
			} else {
				setFilter(undefined);
			}
		}, 300),
		[],
	);

	const formatStatusOrder = useCallback(
		(submittedAt: string | undefined | null, status: StatusOrder | string | undefined | null): React.ReactNode => {
			if (submittedAt && status) {
				if (status === StatusOrder.FAILURE) {
					return <div className={css.statusFailure}>{submittedAt}</div>;
				}
				return submittedAt;
			} else {
				return '';
			}
		},
		[],
	);

	const {Table} = useFullTableWithRefetch('filesOrganizationStatus', {
		additionalFilters: <InputSearch onChange={handleChangeInputFilter} />,
		additionalControls: item => {
			return (
				<Button
					route="filesControlByOrganization"
					params={{id: item.id}}
					title={'Переход к загруженным файлам'}
					iconOnly
					action
				>
					<DocumentIcon />
				</Button>
			);
		},
		variables: {
			query: filter ? filter : undefined,
		},
		cellCallback: {
			ord_221_submitted: (value, {ord_221_status}) => {
				return formatStatusOrder(value, ord_221_status);
			},
			ord_222_submitted: (value, {ord_222_status}) => {
				return formatStatusOrder(value, ord_222_status);
			},
			ord_223_submitted: (value, {ord_223_status}) => {
				return formatStatusOrder(value, ord_223_status);
			},
			inc_submitted: (value, {inc_status}) => {
				return formatStatusOrder(value, inc_status);
			},
		},
		tableFields: [
			'inn',
			'submitted',
			'name_short',
			'ord_221_submitted',
			'ord_222_submitted',
			'ord_223_submitted',
			'inc_submitted',
		],
		detailsFields: ['ogrn', 'address_postal', 'name_full', 'address_legal', 'org_type'],
	});

	return (
		<MainLayout>
			<Table />
		</MainLayout>
	);
};

export default FilesControlPage;
