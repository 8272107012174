import {ConversationMessage} from '../../queries-generated/types';
import React from 'react';
import css from './Message.module.css';
import {format, parseIsoOrThrow} from 'ts-date/locale/ru';
import cls from '../../utils/cls';
import stripHtmlToText from '../../utils/stripHtmlToText';
import FilePreview from '../pirsInputs/DashboardInputFile/FilePreview';
import fileCss from '../pirsInputs/DashboardInputFile/DashboardInputFile.module.css';

type Props = {
	message: ConversationMessage;
};

const Message: React.FC<Props> = ({message}) => {
	return (
		<div className={cls(css.wrapper, message.isService ? css.service : css.interlocutor)}>
			<div
				className={css.text}
				dangerouslySetInnerHTML={{__html: stripHtmlToText(message.text).replace(/\n/g, '<br />')}}
			/>
			{message.files.length > 0 && (
				<div className={cls(css.filePreview, fileCss.preview)}>
					{message.files.map(file => (
						<FilePreview
							key={file.id}
							file={file}
							downloadUrl={`/services/conversation/messages/files?path=${file.path}`}
						/>
					))}
				</div>
			)}
			<div className={css.info}>
				{message.authorName} &bull; {format(parseIsoOrThrow(message.createdAt), 'DD MMMM YYYY, в HH:mm')}
			</div>
		</div>
	);
};

export default Message;
