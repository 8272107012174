import React from 'react';
import {useField, useFormikContext} from 'formik';
import {default as OriginalInput, Props} from './OldDictionarySelector';

function OldDictionarySelector<T>(
	props: Omit<Props<T>, 'onChange' | 'value'> & {name: string; onCustomChange?: (value: T | null) => void},
) {
	const [field, meta] = useField(props as any);
	const {setFieldValue} = useFormikContext();
	const {onCustomChange, ...fieldProps} = props;

	return (
		<OriginalInput
			{...field}
			{...(fieldProps as any)}
			error={meta.error}
			onChange={(value: T) => {
				setFieldValue(field.name as never, value);
				if (onCustomChange) onCustomChange(value);
			}}
		/>
	);
}

export default OldDictionarySelector;
