import {ActionsUnion, createAction} from '../../../redux/helper';

export enum UploadFileStatus {
	QUEUE = 'QUEUE',
	LOADING = 'LOADING',
	LOADED = 'LOADED',
	ERROR = 'ERROR',
	CANCELED = 'CANCELED',
}

export type UploadFileData = {
	pageId: string;
	url: string;
	file: File;
	numberOfChunks: number;
	uploadFileCallback?: () => void;
	status: UploadFileStatus;
	error?: string;
	loadedChunks: number;
	progressLoading: number;
};

export type State = {
	fileUploadQueueArray: UploadFileData[];
	completeCounter: number;
};

export const uploadFileInitialState: State = {
	fileUploadQueueArray: [],
	completeCounter: 0,
};

enum ActionType {
	ADD_FILES = 'ADD_FILES',
	SET_FILE_STATUS = 'SET_FILE_STATUS',
	REMOVE_FILES = 'REMOVE_FILES',
	CLEAR_FILES = 'CLEAR_FILES',
	SET_PROGRESS = 'SET_PROGRESS',
}

export const uploadFileActions = {
	addFiles: (
		newFile: {
			url: string;
			pageId: string;
			file: File;
			uploadFileCallback?: () => void;
		}[],
	) => createAction(ActionType.ADD_FILES, newFile),
	setFileStatus: (fileData: {url: string; fileStatus: UploadFileStatus; error?: string}) =>
		createAction(ActionType.SET_FILE_STATUS, fileData),
	removeFiles: (url: string[]) => createAction(ActionType.REMOVE_FILES, url),
	clearFiles: () => createAction(ActionType.CLEAR_FILES),
	setProgress: (fileInArray: {url: string; loadedChunks: number; percent: number}) =>
		createAction(ActionType.SET_PROGRESS, fileInArray),
};

export function uploadFileReducer(state: State, action: ActionsUnion<typeof uploadFileActions>): State {
	switch (action.type) {
		case ActionType.ADD_FILES:
			const newFiles = action.payload.map(fileObj => {
				return {
					...fileObj,
					numberOfChunks: Math.ceil(fileObj.file.size / Number(process.env.REACT_APP_CHUNK_LENGTH)),
					progressLoading: 0,
					error: undefined,
					status: UploadFileStatus.QUEUE,
					loadedChunks: 0,
				};
			});
			return {
				...state,
				fileUploadQueueArray: [...state.fileUploadQueueArray, ...newFiles],
			};
		case ActionType.REMOVE_FILES:
			const newFileList = state.fileUploadQueueArray.filter(fileObj => !action.payload.includes(fileObj.url));

			return {
				...state,
				fileUploadQueueArray: newFileList,
			};
		case ActionType.CLEAR_FILES:
			return {
				...state,
				fileUploadQueueArray: [],
			};
		case ActionType.SET_FILE_STATUS: {
			const newFileList = state.fileUploadQueueArray.map(obj => {
				if (obj.url === action.payload.url) {
					state.completeCounter += Number(
						[UploadFileStatus.LOADED, UploadFileStatus.ERROR].includes(action.payload.fileStatus),
					);
					return {...obj, status: action.payload.fileStatus, error: action.payload.error};
				}
				return obj;
			});

			return {
				...state,
				fileUploadQueueArray: newFileList,
			};
		}
		case ActionType.SET_PROGRESS: {
			const newFileList = state.fileUploadQueueArray.map(obj => {
				if (obj.url === action.payload.url) {
					const progressChunkBefore = ((action.payload.loadedChunks - 1) * 100) / obj.numberOfChunks;
					const progressChunkAfter = (action.payload.loadedChunks * 100) / obj.numberOfChunks;
					const progress = Math.round(
						progressChunkBefore +
							(action.payload.percent * (progressChunkAfter - progressChunkBefore)) / 100,
					);
					return {
						...obj,
						loadedChunks:
							action.payload.percent === 100
								? action.payload.loadedChunks
								: action.payload.loadedChunks - 1,
						progressLoading: progress,
					};
				}
				return {...obj};
			});

			return {
				...state,
				fileUploadQueueArray: newFileList,
			};
		}

		default:
			return state;
	}
}
