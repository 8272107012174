import React, {useContext, useMemo, useReducer, useCallback, useState, useEffect} from 'react';
import {ActionsUnion, createAction} from '../../../../../redux/helper';
import {useDataSourceQuery, Field} from '../../../../../queries-generated/types';
import useViewItemTree, {CommonFilterField} from '../../../../view/api/useViewItemTree';
import {guid} from '../../../../../utils/guid';
import {flattenDeep} from '../../../../../utils/array-utils';
import {flatViewItems, uniqueFieldKey} from '../../components/helper';

type State = {
	commonRootFilters: CommonFilterField[];
	openFilter?: CommonFilterField;
};

const initialState: State = {
	commonRootFilters: [],
};

export enum ActionType {
	SET_OPEN_FILTER = 'SET_OPEN_FILTER',
	ADD_FILTER = 'ADD_FILTER',
	EDIT_FILTER = 'EDIT_FILTER',
	DELETE_FILTER = 'DELETE_FILTER',
	CLOSE_FILTER = 'CLOSE_FILTER',
	SET_INITIAL_FILTERS = 'SET_INITIAL_FILTERS',
}

export const actions = {
	addFilter: (filter: Field) => createAction(ActionType.ADD_FILTER, filter),
	editFilter: (filter: CommonFilterField) => createAction(ActionType.EDIT_FILTER, filter),
	deleteFilter: (guid: string) => createAction(ActionType.DELETE_FILTER, guid),
	setInitialFilters: (filters: CommonFilterField[]) => createAction(ActionType.SET_INITIAL_FILTERS, filters),
};

export function reducer(state: State, action: ActionsUnion<typeof actions>): State {
	switch (action.type) {
		case ActionType.ADD_FILTER:
			return {
				...state,
				commonRootFilters: [...state.commonRootFilters, {guid: guid(), ...action.payload}],
			};
		case ActionType.EDIT_FILTER:
			return {
				...state,
				commonRootFilters: state.commonRootFilters.map(filter => {
					if (filter.guid === action.payload.guid) return action.payload;
					return filter;
				}),
			};
		case ActionType.DELETE_FILTER:
			return {
				...state,
				commonRootFilters: state.commonRootFilters.filter(filter => filter.guid !== action.payload),
			};
		case ActionType.SET_INITIAL_FILTERS:
			return {
				...state,
				commonRootFilters: action.payload,
			};
		default:
			return state;
	}
}

type CommonFiltersContextType = State &
	typeof actions & {
		possibleCommonRootFilters: Field[];
		getPossibleLinkedFilter: (field: Field | CommonFilterField) => CommonFilterField | undefined;
	};

const CommonFiltersContext = React.createContext<CommonFiltersContextType>(null as any);

export function CommonFiltersProvider({children}) {
	const [possibleCommonRootFilters, setPossibleCommonRootFilters] = useState<Field[]>([]);

	const [state, dispatch] = useReducer(reducer, initialState);
	const {root} = useViewItemTree();

	const actionBounds = useMemo(() => {
		const actionBounds = {};
		Object.keys(actions).forEach(actionKey => {
			const action = actions[actionKey];
			actionBounds[actionKey] = payload => {
				dispatch(action(payload));
			};
		});
		return actionBounds;
	}, [dispatch]);

	const currentDataSourcesIds = useMemo((): string[] => {
		const viewItems = root ? flatViewItems(root) : [];
		const ids: string[] = [];

		viewItems.forEach(item => {
			if (item.dataSource?.type && !ids.includes(item.dataSource.type)) ids.push(item.dataSource.type);
		});

		return ids;
	}, [root]);

	// Очищаем фильтры если были удалены все компоненты или отсутсвтуют датасорсы
	useEffect(() => {
		if (!currentDataSourcesIds.length) {
			setPossibleCommonRootFilters([]);
			dispatch(actions.setInitialFilters([]));
		}
	}, [currentDataSourcesIds, setPossibleCommonRootFilters]);

	const {loading} = useDataSourceQuery({
		variables: {ids: currentDataSourcesIds},
		fetchPolicy: 'cache-and-network',
		skip: !currentDataSourcesIds.length,
		onCompleted: data => {
			const uniqueFields = flattenDeep(data.dataSource.map(ds => ds.fields)).reduce(
				(accumulatedFields, field) => {
					const key = uniqueFieldKey(field);
					if (!accumulatedFields[key]) {
						accumulatedFields[key] = field;
					}
					return accumulatedFields;
				},
				{},
			);

			setPossibleCommonRootFilters(Object.values(uniqueFields));

			if (root.commonFilters?.length) {
				// Убираем сохраненные фильтры отсутствующие в новых филдах
				const availableFilters = root.commonFilters.filter(
					commonFilter => !!uniqueFields[uniqueFieldKey(commonFilter)],
				);

				dispatch(actions.setInitialFilters(availableFilters));
			}
		},
	});

	const getPossibleLinkedFilter = useCallback(
		(field: Field | CommonFilterField): CommonFilterField | undefined => {
			return state.commonRootFilters.find(rootFilter => uniqueFieldKey(field) === uniqueFieldKey(rootFilter));
		},
		[state.commonRootFilters],
	);

	const value = {
		...state,
		...actionBounds,
		possibleCommonRootFilters: loading ? [] : possibleCommonRootFilters,
		getPossibleLinkedFilter,
	};

	return <CommonFiltersContext.Provider value={value as any}>{children}</CommonFiltersContext.Provider>;
}

function useCommonFilters() {
	return useContext(CommonFiltersContext);
}

export default useCommonFilters;
