import {useCallback, useState} from 'react';

export function writeSessionStorage<T>(key: string, value: T) {
	sessionStorage.setItem(key, JSON.stringify({value: value}));
}

const useSessionStorage = <T>(key: string, initialValue?: T): [T, (value: T) => void] => {
	const [state] = useState<T>(() => {
		try {
			const sessionStorageValue = sessionStorage.getItem(key);
			if (sessionStorageValue === null) {
				sessionStorage.setItem(key, JSON.stringify({value: initialValue}));
				return initialValue;
			} else {
				return JSON.parse(sessionStorageValue).value;
			}
		} catch (e) {
			return initialValue;
		}
	});

	const writeStorage = useCallback(
		(value: T) => {
			writeSessionStorage(key, value);
		},
		[key],
	);

	return [state, writeStorage];
};

export default useSessionStorage;
