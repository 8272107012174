exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".RequestsPage-module__wrapper__2_Y-4 {\r\n\theight: 100vh;\r\n\tdisplay: flex;\r\n\tmin-height: 1px;\r\n\tflex-direction: column;\r\n}\r\n\r\n.RequestsPage-module__appBarInnerContainer__21DOc {\r\n\tdisplay: inline-flex;\r\n\talign-items: center;\r\n}\r\n\r\n.RequestsPage-module__appBar__2BKVG.RequestsPage-module__appBar__2BKVG {\r\n\tbox-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);\r\n}\r\n\r\n.RequestsPage-module__files__NiPne {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\RequestsPage\\RequestsPage.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,aAAa;CACb,eAAe;CACf,sBAAsB;AACvB;;AAEA;CACC,oBAAoB;CACpB,mBAAmB;AACpB;;AAEA;CACC,kHAAkH;AACnH;;AAEA;CACC,gBAAgB;CAChB,SAAS;CACT,UAAU;AACX","file":"RequestsPage.module.css","sourcesContent":[".wrapper {\r\n\theight: 100vh;\r\n\tdisplay: flex;\r\n\tmin-height: 1px;\r\n\tflex-direction: column;\r\n}\r\n\r\n.appBarInnerContainer {\r\n\tdisplay: inline-flex;\r\n\talign-items: center;\r\n}\r\n\r\n.appBar.appBar {\r\n\tbox-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);\r\n}\r\n\r\n.files {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"wrapper": "RequestsPage-module__wrapper__2_Y-4",
	"appBarInnerContainer": "RequestsPage-module__appBarInnerContainer__21DOc",
	"appBar": "RequestsPage-module__appBar__2BKVG",
	"files": "RequestsPage-module__files__NiPne"
};