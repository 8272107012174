import React, {useContext, useEffect, useState} from 'react';
import ProgressRing from '../../components/controlls/Loader/ProgressRing';
import {
	useAuthorizeByThirdPartyTokenMutation,
	UserDocument,
	UserQuery,
	UserQueryVariables,
} from '../../queries-generated/types';
import {useRouter} from 'react-named-hooks-router';
import {getApolloError, getQueryError} from '../../utils/error';
import CustomError from '../../components/controlls/CustomError/CustomError';
import {CommonContext} from '../../components/commonRedux/CommonProvider';
import {useApolloClient} from '@apollo/client';

const AuthorizeByThirdPartyTokenPage: React.FC = () => {
	const {routeParams, pushRoute} = useRouter<{token: string}>();
	const [makeQuery] = useAuthorizeByThirdPartyTokenMutation();
	const [error, setError] = useState<string>();
	const {setUser} = useContext(CommonContext);
	const client = useApolloClient();

	useEffect(() => {
		makeQuery({variables: {token: routeParams.token}})
			.then(result => {
				if (result && result.data) {
					client
						.query<UserQuery, UserQueryVariables>({query: UserDocument})
						.then(result => {
							if (result.data) {
								setUser(result.data.user);
								pushRoute('home');
							} else {
								window.location.href = '/logout';
							}
						})
						.catch(() => {
							window.location.href = '/logout';
						});
				} else if (result.errors) {
					setError(getQueryError(result.errors).message);
				}
			})
			.catch(error => {
				setError(getApolloError(error).message);
			});
	}, []);

	if (error || !routeParams.token) {
		return (
			<div style={{height: '100vh'}}>
				<CustomError message="Ошибка входа в ЦМУ ССОП" />
			</div>
		);
	}

	return <ProgressRing />;
};

export default AuthorizeByThirdPartyTokenPage;
