import React, {useMemo} from 'react';
import useCommonFilters from '../../redux/CommonFiltersProvider';
import {Field} from '../../../../../../queries-generated/types';
import {CommonFilterField} from '../../../../../view/api/useViewItemTree';
import css from './Common.module.css';
import Selector from '../../../../../pirsInputs/Selector/Selector.formik';

type Props = {
	field: Field & {name: string};
};

function itemToHtml(item: CommonFilterField | null) {
	return item ? (
		<div>
			<div className={css.title}>{item.title}</div>
			<div className={css.description}>{item.description}</div>
		</div>
	) : null;
}

const LinkedFiltersSelector: React.FC<Props> = ({field}) => {
	const {getPossibleLinkedFilter} = useCommonFilters();

	const possibleLinkedFilter = useMemo(() => {
		return getPossibleLinkedFilter(field);
	}, [getPossibleLinkedFilter, field]);

	return possibleLinkedFilter ? (
		<Selector<CommonFilterField>
			items={[possibleLinkedFilter]}
			name={`commonFilters.${field.id}`}
			itemToHtml={itemToHtml}
			itemToString={item => item?.title || ''}
			label={`Общий для (${field.title})`}
		/>
	) : null;
};

export default LinkedFiltersSelector;
