export default function getAllowedRoutes(): string[] | null {
	try {
		const sessionStorageValue = sessionStorage.getItem('allowed-routes');

		if (sessionStorageValue === null) {
			console.error(`allowed-routes is null`);
			return null;
		} else {
			return JSON.parse(sessionStorageValue).value;
		}
	} catch (e) {
		console.error(e);
		return null;
	}
}
