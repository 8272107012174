import React, {CSSProperties} from 'react';
import css from './SmallLoader.module.css';
import cls from '../../../utils/cls';

type Props = {
	theme?: 'button' | 'normal';
	size?: number;
	value?: number | null;
};

const SmallLoader: React.FC<Props> = ({theme, value, size}) => {
	const indeterminate = value == null;
	const radius = 20;
	const perimeter = radius * 2 * Math.PI;
	const style: CSSProperties = !indeterminate
		? {strokeDasharray: `${perimeter}, ${perimeter}`, strokeDashoffset: perimeter - (value! / 100) * perimeter}
		: {};
	return (
		<section className={css.loaderWrapper} style={{width: size || 24, height: size || 24}}>
			<svg
				className={cls(css.circular, indeterminate && css.progress)}
				viewBox="25 25 50 50"
				width={size || 24}
				height={size || 24}
			>
				<circle
					className={cls(css.path, theme === 'button' && css.buttonTheme)}
					cx="50"
					cy="50"
					r="20"
					fill="none"
					strokeWidth="3"
					strokeMiterlimit="10"
					style={style}
				/>
			</svg>
		</section>
	);
};

export default SmallLoader;
