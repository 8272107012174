import * as React from 'react';
import {useViewsPageQuery} from '../../queries-generated/types';
import {useMemo} from 'react';
import ProgressRing from '../controlls/Loader/ProgressRing';
import ErrorSnippet from '../ErrorSnippet/ErrorSnippet';
import ViewWrapper from './ViewWrapper';

type Props = {
	id: string;
	filters?: PlainObjectOf<any>;
};

const View: React.FC<Props> = ({id, filters}) => {
	const {data, loading, error, refetch} = useViewsPageQuery({
		variables: {id},
		notifyOnNetworkStatusChange: true,
	});

	const content = useMemo(() => {
		return data && !loading && JSON.parse(data.viewsPage.content);
	}, [data, loading]);

	return (
		<>
			{loading && <ProgressRing />}
			{error && <ErrorSnippet error={error} refetch={refetch} />}
			{data && !loading && (
				<ViewWrapper root={content} id={id} version={data.viewsPage.version} filters={filters} />
			)}
		</>
	);
};

export default View;
