import React, {useCallback} from 'react';
import Button, {ButtonProps} from '../pirsInputs/Button/Button';
import useDeleteConfirm from '../hooks/useDeleteConfirm/useDeleteConfirm';
import {notifyToastError} from '../toast/Toast';
import cls from '../../utils/cls';
import css from './DataGrid.module.css';
import {useGridActionMutation} from '../../queries-generated/types';
import {getError} from '../../utils/error';
import {notifyDataSource} from '../toast/DataSourceToast';

type Props = {
	/* Название экшена */
	actionName?: string;
	/* Id dataSource */
	dataSourceId: string;
	item?: any;
	selectedIds?: string[];
	/* Заголовок подтверждения */
	confirmTitle?: React.ReactNode;
	/* Текст подтверждения. Если не задано, то подтверждения не будет */
	confirmText?: React.ReactNode;
	reload?();
	onClick?();
	massAction: boolean;
};

const DataGridActionButton: React.FC<Props & ButtonProps> = ({
	actionName,
	item,
	selectedIds,
	dataSourceId,
	confirmText,
	confirmTitle,
	reload,
	children,
	onClick,
	massAction,
	...buttonProps
}) => {
	const [customAction, {loading}] = useGridActionMutation();

	const handleAction = useCallback(() => {
		if (onClick) onClick();
		else if (dataSourceId && actionName) {
			customAction({
				variables: {
					action: actionName,
					ids: massAction ? selectedIds || [] : item?.id ? [item.id] : [],
					dataSourceId,
				},
			})
				.then(({data, errors}) => {
					notifyDataSource(data?.gridAction.notifications);
					if (data?.gridAction) {
						if (reload) reload();
					} else {
						notifyToastError(getError(errors as any)?.message || 'Ошибка выполнения');
					}
				})
				.catch(errors => {
					notifyToastError(getError(errors as any)?.message || 'Ошибка выполнения');
				});
		}
	}, [dataSourceId, actionName, item, reload, onClick, massAction, selectedIds]);

	const {Confirm, open, openConfirm} = useDeleteConfirm(
		{
			callback: handleAction,
			header: confirmTitle,
			text: confirmText,
		},
		[selectedIds],
	);

	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();

			if (confirmText) {
				openConfirm();
			} else {
				handleAction();
			}
		},
		[confirmText, openConfirm, handleAction],
	);

	return (
		<>
			{Confirm}
			<Button
				{...buttonProps}
				disabled={massAction && !item && selectedIds?.length === 0}
				onClick={handleClick}
				loading={buttonProps.loading || loading}
				className={cls((loading || open) && css.active, buttonProps.className)}
			>
				{children}
			</Button>
		</>
	);
};

export default DataGridActionButton;
