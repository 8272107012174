exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".InputFile-module__file__3EnvJ {\r\n\tposition: relative;\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\toverflow: hidden;\r\n}\r\n\r\n\t.InputFile-module__file__3EnvJ * > {\r\n\t\tflex: 0;\r\n\t}\r\n\r\n\t.InputFile-module__text__2cvQa {\r\n\tmargin-left: 10px;\r\n\toverflow: hidden;\r\n\ttext-overflow: ellipsis;\r\n}\r\n\r\n\t.InputFile-module__button__2aZWx {\r\n\tz-index: 1;\r\n\tflex-shrink: 0;\r\n}\r\n\r\n\t.InputFile-module__fileInput__2eQII {\r\n\tz-index: 2;\r\n\tposition: absolute;\r\n\ttop: 0;\r\n\tleft: 0;\r\n\tright: 0;\r\n\tbottom: 0;\r\n\topacity: 0;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\pirsInputs\\InputFile\\InputFile.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,aAAa;CACb,mBAAmB;CACnB,gBAAgB;AAKjB;;CAHC;EACC,OAAO;CACR;;CAGD;CACC,iBAAiB;CACjB,gBAAgB;CAChB,uBAAuB;AACxB;;CAEA;CACC,UAAU;CACV,cAAc;AACf;;CAEA;CACC,UAAU;CACV,kBAAkB;CAClB,MAAM;CACN,OAAO;CACP,QAAQ;CACR,SAAS;CACT,UAAU;AACX","file":"InputFile.module.css","sourcesContent":[".file {\r\n\tposition: relative;\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\toverflow: hidden;\r\n\r\n\t* > {\r\n\t\tflex: 0;\r\n\t}\r\n}\r\n\r\n.text {\r\n\tmargin-left: 10px;\r\n\toverflow: hidden;\r\n\ttext-overflow: ellipsis;\r\n}\r\n\r\n.button {\r\n\tz-index: 1;\r\n\tflex-shrink: 0;\r\n}\r\n\r\n.fileInput {\r\n\tz-index: 2;\r\n\tposition: absolute;\r\n\ttop: 0;\r\n\tleft: 0;\r\n\tright: 0;\r\n\tbottom: 0;\r\n\topacity: 0;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"file": "InputFile-module__file__3EnvJ",
	"text": "InputFile-module__text__2cvQa",
	"button": "InputFile-module__button__2aZWx",
	"fileInput": "InputFile-module__fileInput__2eQII"
};