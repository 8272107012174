import {createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import React from 'react';

const theme = createMuiTheme({
	typography: {
		fontFamily: 'DINPro',
	},
	palette: {
		primary: {
			main: '#06508b',
		},
		secondary: {
			main: '#f2f2f2',
		},
		error: {
			main: '#f44336',
		},
	},
});

const ThemeProvider: React.FC = ({children}) => {
	return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
