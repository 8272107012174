exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-3-3!../Input/Input.module.css"), "");

// Module
exports.push([module.id, ".TextArea-module__input__2ALT1 {\r\n}\r\n\r\n\t.TextArea-module__input__2ALT1.TextArea-module__input__2ALT1 {\r\n\t\theight: auto;\r\n\t\toutline: none;\r\n\t\tresize: none;\r\n\t\tpadding: 6px 8px 6px 8px;\r\n\t}\r\n\r\n\t.TextArea-module__input__2ALT1.TextArea-module__input__2ALT1.TextArea-module__resizable__FGxst {\r\n\t\t\tresize: vertical;\r\n\t\t}\r\n\r\n\t.TextArea-module__hasFocus__3xaaC {\r\n\toutline: none;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\pirsInputs\\TextArea\\TextArea.module.css"],"names":[],"mappings":"AAAA;AAaA;;CAVC;EACC,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,wBAAwB;CAKzB;;CAHC;GACC,gBAAgB;EACjB;;CAIF;CACC,aAAa;AACd","file":"TextArea.module.css","sourcesContent":[".input {\r\n\tcomposes: input from \"../Input/Input.module.css\";\r\n\r\n\t&.input {\r\n\t\theight: auto;\r\n\t\toutline: none;\r\n\t\tresize: none;\r\n\t\tpadding: 6px 8px 6px 8px;\r\n\r\n\t\t&.resizable {\r\n\t\t\tresize: vertical;\r\n\t\t}\r\n\t}\r\n}\r\n\r\n.hasFocus {\r\n\toutline: none;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"input": "TextArea-module__input__2ALT1 " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-3-3!../Input/Input.module.css").locals["input"] + "",
	"resizable": "TextArea-module__resizable__FGxst",
	"hasFocus": "TextArea-module__hasFocus__3xaaC"
};