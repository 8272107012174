import React, {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import css from './List.module.css';
import cls from '../../utils/cls';
import CheckCircle from '../pirsInputs/CheckCircle/CheckCircle';
import List, {defaultItemToPrimaryKey} from './List';
import {ChevronDownIcon} from '../SvgIcon';
import Popover from '../controlls/PopoverButton/Popover';
import useOnClickOutside from '../hooks/useOnClickOutside';
import {Keys} from '../../utils/key-enum';
import usePrevious from '../hooks/usePrevious';
import Button, {ButtonProps} from '../pirsInputs/Button/Button';
import {isSafeRouteItem} from '../SafeLink/SafeLink';

export type MaybeNested<T> = T & {items: T[]};
export type ItemBase = {
	title?: string;
	icon?: ReactNode | string;
} & ButtonProps;

export type ItemOptions = {
	isSubItem: boolean;
	index: number;
	collapsed: boolean;
};

type Props<T extends ItemBase> = {
	item: MaybeNested<T>;
	index: number;
	length: number;
	onRender?(item: T, options?: ItemOptions): ReactNode | string | null;
	onClick?(item: T, index: number);
	selected: boolean;
	selectMode?: boolean | 'single' | 'multiple';
	className?: string;
	itemWrapperClassName?: string;
	horizontal?: boolean;
	listClassName?: string;
	selectItems?: T[];
	itemSelected?(item: T, options?: ItemOptions): boolean;
	itemOpen?(item: T, options?: ItemOptions): boolean;
	itemFocused?(item: T, options?: ItemOptions): boolean;
	itemToPrimaryKey?(item: T);
	_nestedLevel?: number;
	collapsed?: boolean;
	_isPopoverContent?: boolean;
	_parentRef?: React.RefObject<HTMLButtonElement>;
	getItemByIndex?: (index: number) => MaybeNested<T>;
};

function ListItem<T extends ItemBase>(props: Props<T>) {
	const {
		item,
		index,
		onRender: onRenderProps,
		onClick,
		length,
		selected,
		selectMode,
		className,
		listClassName,
		itemWrapperClassName,
		horizontal,
		itemToPrimaryKey: itemToPrimaryKeyProps,
		itemSelected,
		selectItems,
		_nestedLevel,
		collapsed,
		_isPopoverContent,
		itemOpen,
		_parentRef,
		getItemByIndex,
		itemFocused,
	} = props;

	const handleClick = useCallback(() => {
		if (item && (item as any).onClick) (item as any).onClick(item);
		if (onClick) onClick(item, index);
	}, [item, onClick, index]);

	const defaultOnRender = useCallback((item: T) => item.title, [item]);
	const onRender = onRenderProps || defaultOnRender;

	const hasChild = !!item?.items?.length;
	const isSubItem = (_nestedLevel || 0) > 0;

	const [open, setOpen] = useState(
		!collapsed && (itemOpen ? itemOpen(item, {index, isSubItem, collapsed: !!collapsed}) : false),
	);

	const handleClose = useCallback(() => setOpen(false), []);
	const handleOpen = useCallback(() => setOpen(true), []);
	const handleToggle = useCallback(() => setOpen(!open), [open]);

	const itemToPrimaryKey = itemToPrimaryKeyProps || defaultItemToPrimaryKey;

	const childSelected = useMemo(() => {
		if ((selectItems || itemSelected) && hasChild) {
			for (let itemIndex = 0; itemIndex < item.items.length; itemIndex++) {
				if (itemSelected) {
					if (itemSelected(item.items[itemIndex], {index, isSubItem, collapsed: !!collapsed})) {
						return true;
					}
				} else if (selectItems) {
					const key = itemToPrimaryKey(item.items[itemIndex]);
					for (let selectedItemIndex = 0; selectedItemIndex < selectItems?.length; selectedItemIndex++) {
						if (itemToPrimaryKey(selectItems[selectedItemIndex]) === key) return true;
					}
				}
			}
		}
		return false;
	}, [selectItems, itemSelected, index, isSubItem, collapsed, hasChild, itemToPrimaryKey, item]);

	const itemRef = useRef<HTMLButtonElement>(null);
	const popoverRef = useRef<HTMLDivElement>(null);

	const handleClosePopover = useCallback(() => {
		if (collapsed || horizontal) handleClose();
	}, [collapsed, horizontal, handleClose]);

	useOnClickOutside([itemRef, popoverRef], handleClosePopover);

	const renderedItem = onRender(item as any, {index, isSubItem, collapsed: !!collapsed});
	const childRef = useRef<HTMLDivElement>(null);

	const prevCollapsed = usePrevious(collapsed);
	useEffect(() => {
		if (prevCollapsed !== collapsed && collapsed) {
			handleClose();
		}
	}, [prevCollapsed, collapsed]);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
			const target = e.target as HTMLDivElement;
			if (target) {
				if (e.keyCode === Keys.UP_ARROW) {
					if (index === 0 && _parentRef) {
						if (_parentRef.current) {
							// @ts-ignore
							_parentRef.current.focus();
							handleClose();
						}
					} else {
						const prevItem = getItemByIndex && getItemByIndex(index);
						if (prevItem?.items && target.previousSibling?.previousSibling) {
							// @ts-ignore
							target.previousSibling.previousSibling.focus();
						} else {
							// @ts-ignore
							target.previousSibling?.focus();
						}
					}

					e.preventDefault();
				} else if (e.keyCode === Keys.DOWN_ARROW) {
					if (hasChild) {
						handleOpen();
						setTimeout(() => childRef.current && childRef.current.focus());
					} else if (index === length - 1 && _parentRef) {
						if (collapsed && _parentRef.current?.nextSibling) {
							// @ts-ignore
							_parentRef.current?.nextSibling?.focus();
						} else if (_parentRef.current?.nextSibling?.nextSibling) {
							// @ts-ignore
							_parentRef.current?.nextSibling?.nextSibling?.focus();
						}
						handleClose();
					} else {
						// @ts-ignore
						if (target.nextSibling) target.nextSibling.focus();
					}
					e.preventDefault();
				} else if (e.keyCode === Keys.SPACE || e.keyCode === Keys.ENTER) {
					const a = target.querySelector('a');
					hasChild ? handleToggle() : a ? a.click() : handleClick();
				}
			}
		},
		[hasChild, handleClose, getItemByIndex, handleToggle, handleClick, length, _parentRef],
	);

	const handleFocus = useCallback(
		(e: React.FocusEvent) => {
			e.stopPropagation();
		},
		[handleOpen],
	);

	if (!isSafeRouteItem(item)) return null;

	return (
		<>
			<Button
				route={item?.route}
				params={item?.params}
				href={item?.href}
				target={item?.target}
				download={item?.download}
				action
				onClick={hasChild ? handleToggle : handleClick}
				onKeyDown={e => handleKeyDown(e, index)}
				onFocus={handleFocus}
				checked={(selected && !horizontal) || ((collapsed || !open) && childSelected)}
				className={cls(
					css.itemWrapper,
					!horizontal ? css.vertical : css.horizontal,
					selectMode !== 'multiple' && css.single,
					open && css.open,
					hasChild && css.hasChild,
					(selected || ((collapsed || !open) && childSelected)) && css.selected,
					itemFocused && itemFocused(item, {collapsed: !!collapsed, isSubItem, index}) && css.focused,
					_nestedLevel && !_isPopoverContent && css[`nestedLevel_${_nestedLevel}`],
					collapsed && css.collapsed,
					itemWrapperClassName,
				)}
				role="option"
				aria-selected={selected}
				aria-posinset={index + 1}
				aria-setsize={length}
				setRef={itemRef}
				tabIndex={-1}
			>
				{selectMode === 'multiple' && (
					<CheckCircle onSelect={handleClick} selected={selected} className={css.checkCircle} />
				)}
				{item?.icon} <div className={cls(css.item, className)}>{renderedItem}</div>
				{hasChild && !collapsed && <ChevronDownIcon width={12} height={12} className={css.chevron} />}
			</Button>

			{hasChild && !collapsed && !horizontal && open && (
				<div className={cls(css.subList, open && css.open)}>
					<List
						items={item.items}
						onRender={onRenderProps}
						selectItems={selectItems}
						itemSelected={itemSelected}
						className={listClassName}
						selectMode={selectMode}
						onClick={onClick}
						horizontal={horizontal}
						itemClassName={className}
						itemToPrimaryKey={itemToPrimaryKey}
						itemWrapperClassName={itemWrapperClassName}
						_nestedLevel={(_nestedLevel || 0) + 1}
						_isPopoverContent={_isPopoverContent}
						collapsed={collapsed}
						forwardRef={childRef}
						_parentRef={itemRef}
					/>
				</div>
			)}

			{hasChild && (collapsed || horizontal) && (
				<Popover
					open={open}
					offset={!horizontal ? {left: 49, top: -34} : undefined}
					parentRef={itemRef}
					forwardRef={popoverRef}
					disableFocusLock
				>
					<List
						items={item.items}
						onRender={onRenderProps}
						selectItems={selectItems}
						itemSelected={itemSelected}
						className={listClassName}
						selectMode={selectMode}
						onClick={onClick}
						itemClassName={className}
						itemToPrimaryKey={itemToPrimaryKey}
						itemWrapperClassName={itemWrapperClassName}
						_nestedLevel={(_nestedLevel || 0) + 1}
						_isPopoverContent
						collapsed={collapsed}
						forwardRef={childRef}
						_parentRef={itemRef}
					/>
				</Popover>
			)}
		</>
	);
}

export default ListItem;
