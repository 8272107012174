import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Input from '../pirsInputs/Input/Input';
import css from './DataGridPagination.module.css';
import Button from '../pirsInputs/Button/Button';
import {ArrowPreviewsIcon, ArrowNextIcon, ChevronLeftIcon, ChevronRightIcon, CheckIcon} from '../SvgIcon';
import OverflowSet from '../OverflowSet/OverflowSet';
import Selector from '../pirsInputs/Selector/Selector';
import {onlyUnique} from '../../utils/array-utils';
import fieldCss from '../pirsInputs/Field/Field.module.css';
import cls from '../../utils/cls';
import {Keys} from '../../utils/key-enum';

type Props = {
	onChangePage: (page: number) => void;
	onChangeItemsPerPage: (itemsPerPage: number) => void;
	itemsPerPage: number;
	page: number;
	total: number;
};

const INIT_INPUT_PAGE_VALUE = '1';

const DataGridPagination: React.FC<Props> = ({onChangePage, onChangeItemsPerPage, itemsPerPage, total, page}) => {
	const totalPages = Math.ceil(total / itemsPerPage) || 1;
	const currentItemsFrom = Math.min(page * itemsPerPage + 1, total);
	const currentItemsTo = Math.min(page * itemsPerPage + itemsPerPage, total);

	const [inputValue, setInputValue] = useState<string>(INIT_INPUT_PAGE_VALUE);

	useEffect(() => {
		setInputValue((page + 1).toString());
	}, [page]);

	const handleChangeFirstPage = useCallback(() => {
		onChangePage(0);
		setInputValue(INIT_INPUT_PAGE_VALUE);
	}, [onChangePage, setInputValue]);

	const handleChangePrevPage = useCallback(() => {
		const newPage = page - 1 <= 0 ? 0 : page - 1;
		onChangePage(newPage);
		setInputValue((newPage + 1).toString());
	}, [onChangePage, page, setInputValue]);

	const handleChangeNextPage = useCallback(() => {
		const newPage = page + 1 >= totalPages ? totalPages : page + 1;
		const newInputPage = newPage === totalPages ? totalPages : newPage + 1;
		onChangePage(newPage);
		setInputValue(newInputPage.toString());
	}, [onChangePage, page, totalPages, setInputValue]);

	const handleChangeLastPage = useCallback(() => {
		onChangePage(totalPages - 1);
		setInputValue(totalPages.toString());
	}, [onChangePage, totalPages, setInputValue]);

	const handleChangeInput = useCallback(
		(value: string) => {
			const newPage = parseInt(value, 10);
			if (!isNaN(newPage) && newPage >= totalPages) {
				setInputValue(totalPages.toString());
			} else {
				setInputValue(value);
			}
		},
		[setInputValue, totalPages],
	);

	const handleConfirmChangePage = useCallback(() => {
		let newPage = parseInt(inputValue, 10);
		if (isNaN(newPage)) {
			onChangePage(0);
			setInputValue(INIT_INPUT_PAGE_VALUE);
			return;
		}
		newPage = newPage >= totalPages ? totalPages : newPage <= 1 ? 1 : newPage;
		onChangePage(newPage - 1);
		setInputValue(newPage.toString());
	}, [inputValue, setInputValue, onChangePage]);

	const handleChangeItemsPerPage = useCallback(
		value => {
			const intValue = parseInt(value, 10);
			const itemsPerPage = (!isNaN(intValue) && intValue > 0 && intValue) || 1;
			const totalPages = Math.ceil(total / itemsPerPage);
			if (page > totalPages) {
				onChangePage(totalPages - 1);
				setInputValue(totalPages.toString());
			}
			onChangeItemsPerPage(itemsPerPage);
		},
		[onChangeItemsPerPage, onChangePage, page, total, setInputValue],
	);

	// Должно запускаться только в первый раз, чтобы сохранить внутри себя defaultNumberOfRows
	const itemsPerPageValues = useMemo(() => {
		return [1, 5, 10, 20, 50, 100, 500, 1000, itemsPerPage]
			.filter(onlyUnique)
			.sort((a, b) => a - b)
			.map(value => value.toString());
	}, []);

	// Чтобы изначальный размер инпута подходил к самому длинному значению, в случаях если бэк пришлет значение больше 1000
	const magicSelectorStyle = useMemo(() => {
		const magicMultiplier = 1.15;
		const valueLength = itemsPerPageValues[itemsPerPageValues.length - 1].length;
		return {width: (valueLength > 4 ? valueLength : valueLength * magicMultiplier) + 'em'};
	}, [itemsPerPageValues]);

	const disableInputPage = parseInt(inputValue, 10) === page + 1;

	return (
		<>
			<OverflowSet
				className={css.wrapper}
				withBackdrop
				items={[
					<div className={css.navigation}>
						<Button iconOnly action onClick={handleChangeFirstPage} disabled={page === 0}>
							<ArrowPreviewsIcon />
						</Button>
						<Button iconOnly action onClick={handleChangePrevPage} disabled={page === 0}>
							<ChevronLeftIcon />
						</Button>
						<Input
							value={inputValue.toString()}
							underlined
							buttons={
								<Button
									action
									iconOnly
									className={cls(
										fieldCss.actionButton,
										css.checkButton,
										!disableInputPage && css.checkIcon,
									)}
									onClick={handleConfirmChangePage}
									disabled={disableInputPage}
								>
									<CheckIcon />
								</Button>
							}
							onChange={handleChangeInput}
							onBlur={handleConfirmChangePage}
							onKeyDown={event => {
								event.keyCode === Keys.ENTER && handleConfirmChangePage();
							}}
							className={css.input}
							disableClear
						/>
						из {totalPages}
						<Button iconOnly action onClick={handleChangeNextPage} disabled={page + 1 === totalPages}>
							<ChevronRightIcon />
						</Button>
						<Button iconOnly action onClick={handleChangeLastPage} disabled={page + 1 === totalPages}>
							<ArrowNextIcon />
						</Button>
					</div>,
					itemsPerPage > 0 && (
						<div className={css.itemsPerPageInfo}>
							Отображать
							<Selector
								items={itemsPerPageValues}
								value={itemsPerPage.toString()}
								onChange={handleChangeItemsPerPage}
								className={css.selector}
								underlined
								noButtons
								inputStyle={magicSelectorStyle}
								listClassName={css.listItems}
							/>
						</div>
					),
					itemsPerPage > 0 && (
						<div className={css.metaInfo}>
							Показаны {currentItemsFrom}–{currentItemsTo} из {total}
						</div>
					),
				]}
			/>
		</>
	);
};

export default DataGridPagination;
