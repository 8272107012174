import React from 'react';

export type Props = React.SVGProps<SVGSVGElement> & {title?: string};

export const WordIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 80v125c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h85l55 55Z"
			fill="#fff"
			stroke="#808080"
		/>
		<path d="M140 25v45c0 5.525 4.475 10 10 10h45l-55-55Z" fill="#2265BF" />
		<path
			d="M110 170H30c-5.525 0-10-4.475-10-10V80c0-5.525 4.475-10 10-10h80c5.525 0 10 4.475 10 10v80c0 5.525-4.475 10-10 10Z"
			fill="url(#word)"
		/>
		<path
			d="m92.015 95-7.73 36.32L75.72 95H64.785l-8.835 37.445L47.985 95h-9.78l11.72 50h11.76l8.565-38.445L78.82 145h11.255l11.72-50h-9.78Z"
			fill="#fff"
		/>
		<path d="M128 94h50v6h-50v-6Z" fill="#41A5EE" />
		<path d="M128 109h50v6h-50v-6Z" fill="#2B7CD3" />
		<path d="M128 126h50v6h-50v-6Z" fill="#185ABD" />
		<path d="M128 142h50v6h-50v-6Z" fill="#103F91" />
		<defs>
			<linearGradient id="word" x1="23.72" y1="73.72" x2="117.47" y2="167.465" gradientUnits="userSpaceOnUse">
				<stop stopColor="#256AC2" />
				<stop offset="1" stopColor="#1247AD" />
			</linearGradient>
		</defs>
	</svg>
);

export const ExcelIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 80v125c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h85l55 55Z"
			fill="#fff"
			stroke="#808080"
		/>
		<path d="M140 25v45c0 5.525 4.475 10 10 10h45l-55-55Z" fill="#11773F" />
		<path fill="#21A366" d="M128 98h22v10h-22z" />
		<path fill="#33C481" d="M156 98h22v10h-22z" />
		<path fill="#107C41" d="M128 116h22v10h-22z" />
		<path fill="#21A366" d="M156 116h22v10h-22z" />
		<path fill="#185C37" d="M128 133h22v10h-22z" />
		<path fill="#134A2C" d="M156 133h22v10h-22z" />
		<path
			d="M110 169H30c-5.525 0-10-4.475-10-10V79c0-5.525 4.475-10 10-10h80c5.525 0 10 4.475 10 10v80c0 5.525-4.475 10-10 10Z"
			fill="url(#excel)"
		/>
		<path
			d="M48.445 94H60.71l9.952 18.77L81.181 94h11.46l-15.788 25L93 144H80.914l-10.847-19.65L59.266 144H47l16.415-25.09L48.445 94Z"
			fill="#fff"
		/>
		<defs>
			<linearGradient id="excel" x1="23.625" y1="72.625" x2="115.275" y2="164.275" gradientUnits="userSpaceOnUse">
				<stop stopColor="#18884F" />
				<stop offset="1" stopColor="#0B6731" />
			</linearGradient>
		</defs>
	</svg>
);

export const FolderIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="m122.07 52.07-12.68-12.68A14.997 14.997 0 0 0 98.785 35H25c-5.525 0-10 4.475-10 10v150c0 5.525 4.475 10 10 10h190c5.525 0 10-4.475 10-10V65c0-5.525-4.475-10-10-10h-85.86c-2.65 0-5.195-1.055-7.07-2.93Z"
			fill="url(#folder1)"
		/>
		<path
			d="m107.93 72.07 16.34-16.34c.465-.465 1.1-.73 1.765-.73H215c5.525 0 10 4.475 10 10v130c0 5.525-4.475 10-10 10H25c-5.525 0-10-4.475-10-10V77.5a2.5 2.5 0 0 1 2.5-2.5h83.36c2.65 0 5.195-1.055 7.07-2.93Z"
			fill="url(#folder2)"
		/>
		<defs>
			<linearGradient id="folder1" x1="120" y1="33.54" x2="120" y2="74.885" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EBA600" />
				<stop offset="1" stopColor="#C28200" />
			</linearGradient>
			<linearGradient id="folder2" x1="120" y1="54.27" x2="120" y2="204.915" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FFD869" />
				<stop offset="1" stopColor="#FEC52B" />
			</linearGradient>
		</defs>
	</svg>
);

export const ImageIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 80v125c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h85l55 55Z"
			fill="#fff"
			stroke="#808080"
		/>
		<path d="M140 25v45c0 5.525 4.475 10 10 10h45l-55-55Z" fill="url(#image1)" />
		<path d="M155 130c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z" fill="#fff" />
		<path
			d="M159.111 101H80.889A4.888 4.888 0 0 0 76 105.889v78.222A4.888 4.888 0 0 0 80.889 189h78.222a4.888 4.888 0 0 0 4.889-4.889v-78.222a4.888 4.888 0 0 0-4.889-4.889Z"
			fill="url(#image3)"
		/>
		<path
			d="M139.576 143.128c-2.824-2.783-7.387-2.844-10.288-.138L105 165.666V189h54.083c2.717 0 4.917-2.168 4.917-4.845v-16.958l-24.424-24.069Z"
			fill="#436DCD"
		/>
		<path d="M135.5 130a8.5 8.5 0 1 0-.001-17.001A8.5 8.5 0 0 0 135.5 130Z" fill="#fff" />
		<path
			d="M80.913 189h78.616a4.905 4.905 0 0 0 4.471-2.874l-53.446-52.986c-2.823-2.799-7.383-2.86-10.282-.139L76 155.798v28.331c0 2.691 2.199 4.871 4.913 4.871Z"
			fill="url(#image2)"
		/>
		<defs>
			<linearGradient id="image1" x1="142.645" y1="77.36" x2="168" y2="52" gradientUnits="userSpaceOnUse">
				<stop stopColor="#3079D6" />
				<stop offset="1" stopColor="#297CD2" />
			</linearGradient>
			<linearGradient id="image2" x1="120" y1="130.87" x2="120" y2="190.011" gradientUnits="userSpaceOnUse">
				<stop stopColor="#124787" />
				<stop offset=".923" stopColor="#173B75" />
				<stop offset="1" stopColor="#173A73" />
			</linearGradient>
			<radialGradient
				id="image3"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(179.833 175.494) scale(56.0804)"
			>
				<stop stopColor="#BD8AF5" />
				<stop offset=".137" stopColor="#B88BF5" />
				<stop offset=".309" stopColor="#A88FF3" />
				<stop offset=".499" stopColor="#8F96F2" />
				<stop offset=".702" stopColor="#6B9EEF" />
				<stop offset=".913" stopColor="#3EAAEC" />
				<stop offset="1" stopColor="#29AFEA" />
			</radialGradient>
		</defs>
	</svg>
);

export const DocumentIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 80v125c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h85l15 40 40 15Z"
			fill="#fff"
			stroke="#808080"
		/>
		<path d="M140 25v45c0 5.525 4.475 10 10 10h45l-55-55Z" fill="url(#doc)" />
		<path
			d="M162.5 120h-85a2.5 2.5 0 0 1-2.5-2.5v-5a2.5 2.5 0 0 1 2.5-2.5h85a2.5 2.5 0 0 1 2.5 2.5v5a2.5 2.5 0 0 1-2.5 2.5ZM152.5 140h-75a2.5 2.5 0 0 1-2.5-2.5v-5a2.5 2.5 0 0 1 2.5-2.5h75a2.5 2.5 0 0 1 2.5 2.5v5a2.5 2.5 0 0 1-2.5 2.5ZM162.5 160h-85a2.5 2.5 0 0 1-2.5-2.5v-5a2.5 2.5 0 0 1 2.5-2.5h85a2.5 2.5 0 0 1 2.5 2.5v5a2.5 2.5 0 0 1-2.5 2.5ZM152.5 180h-75a2.5 2.5 0 0 1-2.5-2.5v-5a2.5 2.5 0 0 1 2.5-2.5h75a2.5 2.5 0 0 1 2.5 2.5v5a2.5 2.5 0 0 1-2.5 2.5Z"
			fill="#057093"
		/>
		<defs>
			<linearGradient id="doc" x1="142.645" y1="77.355" x2="168" y2="52" gradientUnits="userSpaceOnUse">
				<stop stopColor="#3079D6" />
				<stop offset="1" stopColor="#297CD2" />
			</linearGradient>
		</defs>
	</svg>
);

export const AcrobatIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 80v125c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h85l55 55Z"
			fill="#fff"
			stroke="#808080"
		/>
		<path
			d="M140 25v45c0 5.525 4.475 10 10 10h45l-55-55ZM108.889 169H31.111C24.975 169 20 164.025 20 157.889V80.111C20 73.975 24.975 69 31.111 69h77.778C115.025 69 120 73.975 120 80.111v77.778c0 6.136-4.975 11.111-11.111 11.111Z"
			fill="#B30B00"
		/>
		<path
			d="M101.623 126.888c-4.78-4.951-17.836-2.934-20.96-2.567-4.596-4.402-7.724-9.723-8.825-11.558 1.655-4.951 2.757-9.906 2.94-15.224 0-4.585-1.839-9.539-6.985-9.539-1.84 0-3.495 1.102-4.413 2.567-2.206 3.853-1.288 11.558 2.206 19.443-2.022 5.687-3.861 11.188-9.008 20.911-5.334 2.201-16.548 7.338-17.466 12.84-.368 1.651.183 3.302 1.471 4.585C41.87 149.45 43.521 150 45.177 150c6.802 0 13.42-9.356 18.02-17.242 3.861-1.285 9.929-3.119 15.997-4.218 7.17 6.236 13.42 7.152 16.731 7.152 4.413 0 6.068-1.835 6.619-3.486.918-1.832.367-3.85-.921-5.318Zm-4.596 3.117c-.183 1.285-1.839 2.567-4.78 1.835-3.494-.916-6.618-2.568-9.378-4.769 2.39-.366 7.723-.916 11.585-.183 1.472.366 2.94 1.282 2.573 3.117ZM66.321 92.219c.367-.55.918-.916 1.472-.916 1.655 0 2.022 2.018 2.022 3.67a40.187 40.187 0 0 1-2.206 11.373c-2.757-7.341-2.206-12.476-1.288-14.127Zm-.367 35.585c1.471-2.934 3.494-8.071 4.229-10.272 1.655 2.75 4.412 6.053 5.884 7.521 0 .184-5.7 1.286-10.113 2.751Zm-10.848 7.339c-4.229 6.969-8.641 11.374-11.031 11.374-.367 0-.735-.183-1.105-.367-.55-.366-.734-.916-.55-1.651.55-2.57 5.33-6.056 12.686-9.356Z"
			fill="#fff"
		/>
	</svg>
);

export const CodeIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 80v125c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h85l55 55Z"
			fill="#fff"
			stroke="#808080"
		/>
		<path d="M140 25v45c0 5.525 4.475 10 10 10h45l-55-55Z" fill="url(#a)" />
		<path
			d="M110.437 190.311c-1.249 0-2.495.009-3.743-.002-1.383-.013-2.042-.863-1.757-2.221a217188 217188 0 0 0 8.045-38.441l9.01-43.061c.502-2.399 1.002-4.8 1.502-7.199.309-1.484 1.007-2.064 2.504-2.068 2.374-.004 4.748-.004 7.122 0 1.565.002 2.229.836 1.906 2.368-2.929 13.973-5.855 27.948-8.782 41.924l-7.779 37.104c-.664 3.166-1.326 6.332-1.987 9.498-.318 1.523-1.003 2.092-2.54 2.096-1.168.004-2.334.002-3.501.002Z"
			fill="#0078D4"
		/>
		<path
			d="M81.942 143.641c3.655 3.653 10.777 10.908 14.43 14.563.727.727 1.162 1.559.752 2.597-.136.342-.388.657-.65.924a329.965 329.965 0 0 1-4.047 4.055c-1.01.993-2.233 1.027-3.2.065-3.806-3.786-12.556-12.586-16.35-16.383-.848-.849 6.012-5.058 9.065-5.821Z"
			fill="url(#b)"
		/>
		<path
			d="M167.534 149.175c-.132.149-5.682 5.565-5.823 5.707a21958.43 21958.43 0 0 1-10.792 10.79c-1.145 1.145-2.305 1.16-3.469.019-1.292-1.267-2.563-2.556-3.842-3.836-1.242-1.248-1.25-2.414-.009-3.657 5.114-5.122 0 0 15.442-15.32 1.743 2.645 5.811 4.594 8.493 6.297Z"
			fill="url(#c)"
		/>
		<path
			d="M73.26 149.843c-.573-.477-1.012-.954-2.005-1.908-.667-.668 1.111 1.05-2.42-2.386-1.176-1.145-1.049-2.29 0-3.339 4.485-4.485 20.082-20.064 20.53-20.477.782-.722 1.98-.744 2.74-.017a143.822 143.822 0 0 1 4.526 4.505c.867.907.848 2.058.006 2.985-.337.371-14.943 14.831-20.61 20.447-1.241 1.145-2.195.667-2.768.19Z"
			fill="url(#d)"
		/>
		<path
			d="M143.338 126.381c.477-.572.954-1.011 1.908-2.004.668-.667-1.05 1.111 2.385-2.419 1.145-1.177 2.29-1.05 3.34 0 4.485 4.484 20.064 20.081 20.477 20.528.722.783.744 1.981.017 2.741a143.479 143.479 0 0 1-4.505 4.526c-.907.867-2.058.848-2.985.006-.371-.337-14.831-14.943-20.447-20.611-1.145-1.24-.668-2.194-.19-2.767Z"
			fill="url(#e)"
		/>
		<path d="M73.383 149.973c.042.042.084.084.128.124-.044-.04-.086-.082-.128-.124Z" fill="#1B85C7" />
		<defs>
			<linearGradient id="a" x1="142.645" y1="77.36" x2="168" y2="52" gradientUnits="userSpaceOnUse">
				<stop stopColor="#3079D6" />
				<stop offset="1" stopColor="#297CD2" />
			</linearGradient>
			<linearGradient id="b" x1="78.507" y1="146.79" x2="94.633" y2="163.011" gradientUnits="userSpaceOnUse">
				<stop stopColor="#0E7FC6" />
				<stop offset="1" stopColor="#199AE0" />
			</linearGradient>
			<linearGradient id="c" x1="160.664" y1="146.504" x2="145.015" y2="162.534" gradientUnits="userSpaceOnUse">
				<stop stopColor="#0F81C8" />
				<stop offset="1" stopColor="#199AE0" />
			</linearGradient>
			<linearGradient id="d" x1="94.156" y1="123.794" x2="70.778" y2="146.695" gradientUnits="userSpaceOnUse">
				<stop stopColor="#1EA3E5" />
				<stop offset="1" stopColor="#32BDEF" />
			</linearGradient>
			<linearGradient id="e" x1="145.587" y1="124.271" x2="169.06" y2="147.553" gradientUnits="userSpaceOnUse">
				<stop stopColor="#1EA2E4" />
				<stop offset="1" stopColor="#32BDEF" />
			</linearGradient>
		</defs>
	</svg>
);

export const ZipIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 35v170c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h128c6.5 0 12 5 12 10Z"
			fill="url(#zip)"
			stroke="#808080"
		/>
		<path
			d="M127.88 25.002c1.345.629 1.822 1.704 1.737 3.152-.071 1.22-.004 2.451-.019 3.676-.017 1.497-1.051 2.557-2.563 2.619-.908.035-1.82.023-2.73.033-.07-.006-.142-.02-.209-.014-3.159.242-4.133-1.14-3.991-4.038.038-.768.017-1.543.004-2.314-.023-1.43.537-2.502 1.869-3.114 1.966-.002 3.934-.002 5.902 0Z"
			fill="#EA7E08"
		/>
		<path
			d="M124.305 95.89c.42.012.842.031 1.262.033 2.589.021 4.492 1.611 4.863 4.175 1.193 8.247 2.475 16.486 3.474 24.759.426 3.533.593 7.173.249 10.705-.528 5.414-3.638 9.188-8.691 11.297-.256.106-.488.264-.733.399-.564.118-1.127.237-1.689.357 0-2.939.008-5.878-.011-8.818-.002-.357-.156-.711-.238-1.068 3.119-2.346 2.508-6.261.01-7.948.081-.513.228-1.026.228-1.539.013-10.76.011-21.523.011-32.283l1.265-.07Z"
			fill="#40403F"
		/>
		<path
			d="M115.014 146.979c-5.669-2.257-9.185-8.254-9.008-14.316.321-10.906 2.044-21.638 3.896-32.357.509-2.95 2.371-4.472 5.112-4.347v15.91c0 6.639-.006 13.275.019 19.914.002.369.285.737.437 1.106l.032 1.66c-.163.42-.463.84-.467 1.263-.038 3.72-.021 7.443-.021 11.167Z"
			fill="#4A4949"
		/>
		<path
			d="M123.04 95.959c0 10.76.002 21.523-.011 32.283 0 .513-.149 1.026-.228 1.539-2.711-1.499-5.158-.783-6.978 2.046-.129-.388-.37-.775-.37-1.163-.019-11.567-.017-23.134-.017-34.703 2.534-.002 5.068-.002 7.604-.002Z"
			fill="#454444"
		/>
		<path
			d="M110.772 76.884c0-.704-.013-1.407.004-2.11.036-1.493 1.02-2.475 2.53-2.5 1.337-.023 2.673-.01 4.01-.004 1.721.008 2.66.644 2.823 2.318.162 1.662.122 3.366-.047 5.028-.141 1.372-1.074 2.084-2.455 2.135-1.44.052-2.885.05-4.325 0-1.543-.055-2.508-1.1-2.536-2.652-.017-.74-.006-1.476-.004-2.215ZM115.546 34.45c.634 0 1.265-.006 1.898 0 1.639.015 2.726.987 2.805 2.63a41.192 41.192 0 0 1-.003 4.113c-.088 1.708-1.119 2.683-2.852 2.747a55.85 55.85 0 0 1-3.902 0c-1.702-.057-2.714-1.128-2.724-2.815-.009-1.406-.019-2.812.004-4.219.025-1.492.961-2.417 2.452-2.45.774-.018 1.547-.005 2.322-.005ZM124.516 62.758c.84.008 1.681 0 2.521.03 1.563.056 2.542 1.042 2.559 2.582.015 1.435.019 2.873-.002 4.31-.023 1.57-.988 2.54-2.559 2.586-.84.025-1.681.023-2.521.036-.56-.009-1.121-.003-1.679-.032-1.472-.076-2.428-.78-2.576-2.242-.162-1.624-.19-3.286-.034-4.908.154-1.594 1.202-2.329 2.821-2.341.49-.005.98-.015 1.47-.021ZM115.369 62.776c-.703 0-1.409.017-2.112-.004-1.497-.042-2.46-1.024-2.483-2.548-.021-1.337-.008-2.676-.004-4.012.006-1.818 1.022-2.88 2.842-2.931 1.161-.032 2.323-.015 3.484-.004 1.951.017 2.868.665 3.064 2.584.162 1.592.093 3.229-.076 4.825-.139 1.32-1.164 2.055-2.5 2.095-.739.02-1.478.004-2.217.004.002-.002.002-.006.002-.008ZM124.301 81.708c.948.02 1.896.03 2.842.057 1.435.043 2.417 1.016 2.449 2.469.027 1.3-.074 2.605.025 3.9.15 1.97-1.07 3.07-3.095 3.112-.667.015-1.335.013-2.002.02-3.433.134-4.531-.637-4.413-4.48.021-.665-.002-1.33.004-1.995.019-2 1.048-3.026 3.039-3.038.382-.004.766-.03 1.151-.045Z"
			fill="#EA7E08"
		/>
		<path
			d="M124.514 43.874c.872.026 1.744.05 2.618.072 1.487.038 2.439.946 2.462 2.411.025 1.504.023 3.01.002 4.515-.021 1.459-.973 2.373-2.464 2.41-.944.022-1.886.041-2.829.065-.456-.017-.91-.047-1.367-.053-1.682-.021-2.751-1.02-2.814-2.728-.047-1.265-.019-2.532-.009-3.797.013-1.472 1.016-3.034 2.931-2.842.482.049.978-.032 1.47-.053Z"
			fill="#EA7D08"
		/>
		<path
			d="M115.014 146.979c0-3.722-.015-7.447.021-11.17.004-.422.304-.84.467-1.262.336.751.557 1.588 1.028 2.24 1.153 1.598 3.909 2.302 6.259.94.082.357.236.711.238 1.068.019 2.939.011 5.879.011 8.818-2.745.671-5.42.475-8.024-.634ZM115.436 95.961c0 11.567-.004 23.134.017 34.703 0 .388.241.775.37 1.163l-.353 1.062c-.152-.369-.435-.737-.437-1.106-.027-6.639-.019-13.275-.019-19.914v-15.91c.142 0 .281 0 .422.002Z"
			fill="#454544"
		/>
		<path
			d="m115.47 132.887.353-1.062c1.82-2.829 4.265-3.545 6.978-2.046 2.496 1.687 3.106 5.602-.01 7.948-2.35 1.364-5.106.659-6.259-.94-.471-.654-.692-1.488-1.028-2.24-.013-.553-.023-1.107-.034-1.66Z"
			fill="#FED604"
		/>
		<defs>
			<linearGradient id="zip" x1="45" y1="120" x2="195" y2="120" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FEDE00" />
				<stop offset="1" stopColor="#FFD000" />
			</linearGradient>
		</defs>
	</svg>
);

export const FileIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 80v125c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h85l15 40 40 15Z"
			fill="#fff"
			stroke="#808080"
		/>
		<path d="M140 25v45c0 5.525 4.475 10 10 10h45l-55-55Z" fill="url(#file)" />
		<defs>
			<linearGradient id="file" x1="142.645" y1="77.355" x2="168" y2="52" gradientUnits="userSpaceOnUse">
				<stop stopColor="#3079D6" />
				<stop offset="1" stopColor="#297CD2" />
			</linearGradient>
		</defs>
	</svg>
);

export const VideoIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 80v125c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h85l15 40 40 15Z"
			fill="#fff"
			stroke="#808080"
		/>
		<path d="M140 25v45c0 5.525 4.475 10 10 10h45l-55-55Z" fill="url(#video1)" />
		<path
			d="M165.229 183.521H75.771a4.707 4.707 0 0 1-4.709-4.709v-68.27a4.707 4.707 0 0 1 4.709-4.709h89.458a4.707 4.707 0 0 1 4.709 4.709v68.27a4.707 4.707 0 0 1-4.709 4.709Z"
			fill="url(#video3)"
		/>
		<path
			d="M110.412 138.121a7.373 7.373 0 0 0-10.426 0l-28.924 28.921v11.77a4.707 4.707 0 0 0 4.709 4.709h80.042l-45.401-45.4Z"
			fill="url(#video2)"
		/>
		<path
			d="M165.229 183.521a4.707 4.707 0 0 0 4.708-4.708v-2.355l-28.92-28.921a7.374 7.374 0 0 0-10.427 0l-35.986 35.984h70.625Z"
			fill="#436DCD"
		/>
		<path
			d="M160.521 124.667h-4.708a2.356 2.356 0 0 1-2.355-2.354v-4.709a2.356 2.356 0 0 1 2.355-2.354h4.708a2.355 2.355 0 0 1 2.354 2.354v4.709a2.355 2.355 0 0 1-2.354 2.354ZM160.521 141.146h-4.708a2.356 2.356 0 0 1-2.355-2.354v-4.709a2.356 2.356 0 0 1 2.355-2.354h4.708a2.355 2.355 0 0 1 2.354 2.354v4.709a2.355 2.355 0 0 1-2.354 2.354ZM160.521 157.625h-4.708a2.356 2.356 0 0 1-2.355-2.354v-4.709a2.356 2.356 0 0 1 2.355-2.354h4.708a2.355 2.355 0 0 1 2.354 2.354v4.709a2.355 2.355 0 0 1-2.354 2.354ZM160.521 174.104h-4.708a2.356 2.356 0 0 1-2.355-2.354v-4.708c0-1.3 1.055-2.354 2.355-2.354h4.708a2.355 2.355 0 0 1 2.354 2.354v4.708a2.355 2.355 0 0 1-2.354 2.354ZM85.188 124.667h-4.709a2.355 2.355 0 0 1-2.354-2.354v-4.709a2.355 2.355 0 0 1 2.354-2.354h4.709c1.299 0 2.354 1.055 2.354 2.354v4.709a2.355 2.355 0 0 1-2.355 2.354ZM85.188 141.146h-4.709a2.355 2.355 0 0 1-2.354-2.354v-4.709a2.355 2.355 0 0 1 2.354-2.354h4.709c1.299 0 2.354 1.055 2.354 2.354v4.709a2.355 2.355 0 0 1-2.355 2.354ZM85.188 157.625h-4.709a2.355 2.355 0 0 1-2.354-2.354v-4.709a2.355 2.355 0 0 1 2.354-2.354h4.709c1.299 0 2.354 1.055 2.354 2.354v4.709a2.355 2.355 0 0 1-2.355 2.354ZM85.188 174.104h-4.709a2.355 2.355 0 0 1-2.354-2.354v-4.708c0-1.3 1.055-2.354 2.354-2.354h4.709c1.299 0 2.354 1.054 2.354 2.354v4.708a2.355 2.355 0 0 1-2.355 2.354Z"
			fill="#fff"
		/>
		<defs>
			<linearGradient id="video1" x1="142.645" y1="77.355" x2="168" y2="52" gradientUnits="userSpaceOnUse">
				<stop stopColor="#3079D6" />
				<stop offset="1" stopColor="#297CD2" />
			</linearGradient>
			<linearGradient
				id="video2"
				x1="113.437"
				y1="135.856"
				x2="113.437"
				y2="184.349"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#124787" />
				<stop offset=".923" stopColor="#173B75" />
				<stop offset="1" stopColor="#173A73" />
			</linearGradient>
			<radialGradient
				id="video3"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="matrix(57.4614 0 0 59.7107 183.79 168.189)"
			>
				<stop stopColor="#BD8AF5" />
				<stop offset=".137" stopColor="#B88BF5" />
				<stop offset=".309" stopColor="#A88FF3" />
				<stop offset=".499" stopColor="#8F96F2" />
				<stop offset=".702" stopColor="#6B9EEF" />
				<stop offset=".913" stopColor="#3EAAEC" />
				<stop offset="1" stopColor="#29AFEA" />
			</radialGradient>
		</defs>
	</svg>
);

export const AudioIcon = (props: Props) => (
	<svg width="240" height="240" viewBox="0 0 240 240" fill="none" {...props}>
		<path
			d="M195 80v125c0 5.525-4.475 10-10 10H55c-5.525 0-10-4.475-10-10V35c0-5.525 4.475-10 10-10h85l15 40 40 15Z"
			fill="#fff"
			stroke="#808080"
		/>
		<path d="M140 25v45c0 5.525 4.475 10 10 10h45l-55-55Z" fill="url(#audio1)" />
		<path
			d="M111 144c-12.427 0-22.5 10.073-22.5 22.5S98.573 189 111 189s22.5-10.073 22.5-22.5S123.427 144 111 144Z"
			fill="#ED3675"
		/>
		<path d="M153.75 117H133.5V99h20.25a4.5 4.5 0 0 1 4.5 4.5v9a4.5 4.5 0 0 1-4.5 4.5Z" fill="url(#audio2)" />
		<path d="M133.5 99H129a9 9 0 0 0-9 9v58.5h13.5V99Z" fill="#ED3675" />
		<defs>
			<linearGradient id="audio1" x1="142.645" y1="77.355" x2="168" y2="52" gradientUnits="userSpaceOnUse">
				<stop stopColor="#ED3675" />
				<stop offset="1" stopColor="#CB1B4D" />
			</linearGradient>
			<linearGradient id="audio2" x1="133.5" y1="108" x2="158.25" y2="108" gradientUnits="userSpaceOnUse">
				<stop stopColor="#BD1949" />
				<stop offset=".108" stopColor="#C31A4B" />
				<stop offset=".38" stopColor="#CA1B4D" />
				<stop offset="1" stopColor="#CC1B4E" />
			</linearGradient>
		</defs>
	</svg>
);
