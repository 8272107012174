import * as React from 'react';
import css from './FileUploadSnippet.module.css';

type Props = {
	onCancelUpload: () => void;
	fileName: string;
};

const FileQueueSnippet: React.FC<Props> = ({onCancelUpload, fileName}) => {
	return (
		<div className={css.wrapper}>
			<div className={css.dataArea}>
				<ul className={css.dataList}>
					<li>Файл {fileName} в очереди на загрузку</li>
					<li>
						<span className={css.link} onClick={onCancelUpload}>
							Отменить
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default FileQueueSnippet;
