/**
 * Перемешивает массив
 *
 * @param {Array} array
 * @return {Array}
 */

export function shuffleArray<T>(array: T[]): T[] {
	let currentIndex = array.length;
	// While there remain elements to shuffle...
	while (currentIndex !== 0) {
		// Pick a remaining element...
		const randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;
		// And swap it with the current element.
		const temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	return array;
}

/**
 * Формирует массив состоящий из чисел от start до end
 * Если не указан end, то start является end
 *
 * arrayRange(3) => [0, 1, 2, 3]
 * arrayRange(3, 5) => [3, 4, 5]
 */
export function arrayRange(start: number, end?: number): number[] {
	if (end === undefined) {
		end = start;
		start = 0;
	}

	const array: number[] = [];
	for (let i = start; i <= end; i++) {
		array.push(i);
	}

	return array;
}

/**
 * Находит разницу между массивами
 * Например [1, 2, 3], [2, 3, 5] => [1, 5]
 * @param a1
 * @param a2
 */
export function arrayDiffSymmetric<T>(a1: T[], a2: T[]): T[] {
	return arrayDiff(a1, a2).concat(arrayDiff(a2, a1));
}

/**
 * Находит, что есть в первом массиве, чего нет во втором
 * Например [1, 2, 3], [2, 3, 5] => [1, 5]
 * @param a1
 * @param a2
 */
export function arrayDiff<T>(a1: T[], a2: T[]): T[] {
	const a2Set = new Set(a2);
	return a1.filter(x => !a2Set.has(x));
}

export function addOrDeleteToArray<T>(stored: T[], newItems: T[], callback: (item: T) => string | number | boolean) {
	return stored
		.filter(storedItem => !newItems.find(item => callback(storedItem) === callback(item)))
		.concat(newItems.filter(item => !stored.find(storedItem => callback(storedItem) === callback(item))));
}

/**
 * Выделяет пересечения между массивами
 *
 * arrayIntersect([1, 2, 3], [2, 4, 6]) => [2]
 */
export function arrayIntersect<T>(array1: T[] | null | undefined, array2: T[] | null | undefined): T[] {
	if (!array1 || !array2) return [];
	return array1.filter(item => array2.includes(item));
}

/**
 * Есть ли пересечение в массивах
 */
export function isArrayIntersect<T>(array1: T[] | undefined, array2: T[] | undefined): boolean {
	return !!array1 && !!array2 && array1.some(item => array2.includes(item));
}

/**
 * Группирует элементы в массиве
 */
export function groupArrayBy<T>(array: T[], keyFn: (item: T, index: number) => string | number): PlainObjectOf<T[]> {
	const result: PlainObjectOf<T[]> = {};
	for (let i = 0; i < array.length; i++) {
		const item = array[i];
		const key = keyFn(item, i);
		if (result[key]) {
			result[key].push(item);
		} else {
			result[key] = [item];
		}
	}
	return result;
}

export function indexBy<T, K extends keyof T>(
	array: T[],
	key: K,
): {[key in T[K] extends string | number ? T[K] : never]: T} {
	const result: any = {};
	for (let i = 0; i < array.length; i++) {
		const item = array[i];
		result[item[key] as any] = item;
	}
	return result;
}

/**
 * Поиск ближайшего
 */
export function closestByDistance<T>(array: T[], distanceFn: (item: T) => number | undefined | null): T | undefined {
	if (!array || !array.length) return;
	let minDistance = -1;
	let minItem;
	for (let i = 0; i < array.length; i++) {
		const item = array[i];
		const d = distanceFn(item);
		const distance = typeof d === 'number' ? Math.abs(d) : Infinity;
		if (distance === 0) return item;
		if (minDistance < 0 || distance < minDistance) {
			minDistance = distance;
			minItem = item;
		}
	}
	return minItem;
}

/**
 * Метод для преобразования к массиву
 */
export function ensureArray<T>(item: MaybeArray<T>): T[] {
	if ((item as any) == null) return [];
	if (Array.isArray(item)) return item;
	return [item];
}

/**
 * Метод для исключения полей из объекта по предикату
 */
export function omit<T extends {}>(obj: T, fn: <TKey extends keyof T>(key: TKey, value: T[TKey]) => boolean): T {
	const result = {} as any;
	for (const key in obj) {
		if (fn(key, obj[key])) {
			result[key] = obj[key];
		}
	}
	return result;
}

export function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

export function arraysEqual(a, b, compareFn?: (arg1, arg2) => boolean) {
	const defaultCompare = (a, b) => a === b;
	const compare = compareFn || defaultCompare;

	if (a === b) return true;
	if (a === null || b === null) return false;
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; ++i) {
		if (!compare(a[i], b[i])) return false;
	}
	return true;
}

export function removeArrayElementByIndex<T>(array: T[], index: number): T[] {
	return array.slice(0, index).concat(array.slice(index + 1));
}

export function removeArrayElement<T>(array: T[], item: T): T[] {
	const index = array.indexOf(item);
	if (index > -1) {
		return array.slice(0, index).concat(array.slice(index + 1));
	}
	return array;
}

export function removeArrayElements<T>(array: T[], callback: (value: T) => boolean): T[] {
	let i = 0;
	const newArray: T[] = [];
	while (i < array.length) {
		if (!callback(array[i])) {
			newArray.push(array[i]);
		}
		++i;
	}
	return newArray;
}

export function insertArrayElement<T>(arr: T[], index: number, el: T): T[] {
	return [...arr.slice(0, index), el, ...arr.slice(index)];
}

export function updateArrayElementByIndex<T>(arrayInit: T[], index: number, item: T): T[] {
	const array = [...arrayInit];
	if (array[index]) array[index] = item;
	else array.push(item);
	return array;
}

export function getFirstArrayItem<T>(array: MaybeArray<T>): T | null {
	array = ensureArray(array);
	return array.length ? array[0] : null;
}

export function flattenDeep(arr1) {
	return arr1.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val)), []);
}

export function objectValues(object) {
	return Object.keys(object).map(key => object[key]);
}

/**
 * Метод для формирования массива уникальных объектов по предикату
 *
 * uniqBy([{id: 1}, {id:1}, {id:2}], 'id') => [{id:1}, {id:2}]
 */
export function uniqBy<T>(arr: T[], predicate: ((item: T) => any) | string): T[] {
	const cb = typeof predicate === 'function' ? predicate : obj => obj[predicate];

	return [
		...arr
			.reduce((map, item) => {
				const key = cb(item);

				map.has(key) || map.set(key, item);

				return map;
			}, new Map())
			.values(),
	];
}

/**
 * Возвращает элементы массива "сначала", если индекс превышает длинну массив
 * @param array
 * @param index
 */
export function getArrayValueWithIndexExcess<T>(array: T[], index: number): T {
	return array[index % array.length];
}

export function keysToObj(arr: string[], value: (key: string) => any) {
	const obj: PlainObjectOf<any> = {};

	arr.forEach(item => {
		obj[item] = value(item);
	});

	return obj;
}
