import * as React from 'react';
import {ReactNode, useCallback, useRef, useState} from 'react';
import css from './PopoverButton.module.css';
import Popover, {PopoverProps} from './Popover';
import cls from '../../../utils/cls';
import Button from '../../pirsInputs/Button/Button';
import {ChevronDownIcon} from '../../SvgIcon';
import useOnClickOutside from 'components/hooks/useOnClickOutside';

type Props = {
	popover: ReactNode;
	disabled?: boolean;
	className?: string;
	openClassName?: string;
	popoverClassName?: string;
	checked?: boolean;
	hasChevron?: boolean;
	label?: string;
	error?: string;
	secondary?: boolean;
	action?: boolean;
	link?: boolean;
	iconOnly?: boolean;
	message?: boolean;
	paddingLess?: boolean;
	setRef?: React.RefObject<HTMLButtonElement | HTMLAnchorElement>;
	withBackdrop?: PopoverProps['withBackdrop'];
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
	React.AnchorHTMLAttributes<HTMLAnchorElement>;

const PopoverButton: React.FC<Props> = p => {
	const {
		children,
		label,
		link,
		action,
		iconOnly,
		secondary,
		popover,
		className,
		openClassName,
		popoverClassName,
		disabled,
		checked,
		hasChevron,
		error,
		setRef,
		message,
		paddingLess,
		withBackdrop,
		...props
	} = p;
	const [open, setOpen] = useState(false);

	const refTmp = useRef<HTMLButtonElement>(null);
	const buttonRef = setRef || refTmp;
	const popoverRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(
		open && !withBackdrop && [popoverRef, buttonRef],
		useCallback(() => setOpen(false), []),
	);

	const handleClick = useCallback(() => {
		const newOpen = !open;
		setOpen(newOpen);
	}, [open, setOpen]);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<>
			{label && <div className={cls(css.label, error && css.labelError)}>{label}</div>}
			<Button
				onClick={handleClick}
				setRef={buttonRef}
				{...(props as any)}
				className={cls(className, css.button, open && openClassName)}
				aria-expanded={open ? 'true' : 'false'}
				secondary={secondary}
				checked={checked || open}
				link={link}
				action={action}
				iconOnly={iconOnly}
			>
				{children}
				{hasChevron && (
					<ChevronDownIcon width={12} height={12} className={cls(css.chevron, open && css.openChevron)} />
				)}
			</Button>
			{!!error && <div className={css.error}>{error}</div>}

			<Popover
				parentRef={buttonRef}
				open={open}
				className={popoverClassName}
				forwardRef={popoverRef}
				message={message}
				paddingLess={paddingLess}
				onBlur={handleClose}
				onClose={handleClose}
				focusOnOpen
				returnFocusRef={buttonRef}
				withBackdrop={withBackdrop}
			>
				{popover}
			</Popover>
		</>
	);
};

export default PopoverButton;
