import React from 'react';
import {TableRowOptions} from './DataGridRow';
import DataGridRowShimmer from './DataGridRowShimmer';
import {DataGridAction} from '../settings/views/components/helper';

type Props = {
	options?: TableRowOptions;
	header?: boolean;
	actionButtons?: DataGridAction[];
};

const DataGridShimmer: React.FC<Props> = ({options, header, actionButtons}) => {
	const {visibleFields, detailsFields, selectable} = options || {visibleFields: [], detailsFields: []};
	return header ? (
		<>
			<DataGridRowShimmer header actionButtons={actionButtons} />
			<DataGridRowShimmer actionButtons={actionButtons} />
			<DataGridRowShimmer actionButtons={actionButtons} />
			<DataGridRowShimmer actionButtons={actionButtons} />
			<DataGridRowShimmer actionButtons={actionButtons} />
		</>
	) : (
		<>
			<DataGridRowShimmer options={{visibleFields, detailsFields, selectable}} actionButtons={actionButtons} />
			<DataGridRowShimmer options={{visibleFields, detailsFields, selectable}} actionButtons={actionButtons} />
			<DataGridRowShimmer options={{visibleFields, detailsFields, selectable}} actionButtons={actionButtons} />
			<DataGridRowShimmer options={{visibleFields, detailsFields, selectable}} actionButtons={actionButtons} />
		</>
	);
};

export default DataGridShimmer;
