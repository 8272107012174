import React from 'react';
import css from './Field.module.css';
import {CommonInputInterface} from '../CommonInputInterface';
import cls from '../../../utils/cls';
import PopoverButton from '../../controlls/PopoverButton/PopoverButton';
import {HelpIcon} from '../../SvgIcon';

type Props = {
	className?: string;
	/* показывать лебл вверху (меняется с анимацией) */
	topLabel?: boolean;
	/* флаг что поле открыто (для повышения z-index) */
	isOpen?: boolean;
	/* пропсы для лейбла */
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
	/* общие пропсы для контролов */
	commonProps: Pick<
		CommonInputInterface<any>,
		'label' | 'error' | 'helperText' | 'bottomText' | 'disabled' | 'noButtons'
	>;
	/* внутренняя ссылка, нужна для downshift, например */
	innerRef?: React.Ref<HTMLDivElement>;
	outerRef?: React.Ref<HTMLDivElement>;
	normalLabel?: boolean;
	buttons?: React.ReactNode;
	hasFocus?: boolean;
	underlined?: boolean;
	bordered?: boolean;
	inputId?: string;
};

const Field: React.FC<Props & React.InputHTMLAttributes<HTMLDivElement>> = props => {
	const {
		commonProps,
		className,
		normalLabel,
		labelProps,
		topLabel,
		children,
		innerRef,
		outerRef,
		isOpen,
		hasFocus,
		buttons,
		underlined,
		bordered,
		inputId,
		...divProps
	} = props;
	const {error, label, disabled, helperText, bottomText, noButtons} = commonProps;
	return (
		<div
			{...divProps}
			ref={outerRef}
			className={cls(className, css.field, !topLabel && css.labelToPlaceholder, isOpen && css.open)}
		>
			{(bordered || (!underlined && !bordered)) && !!label && (
				<label
					{...labelProps}
					id={inputId ? inputId + '-label' : labelProps?.id}
					htmlFor={inputId}
					className={cls(css.blockLabel, labelProps && labelProps.className)}
				>
					{label}
				</label>
			)}
			<div className={css.fieldWrapper}>
				<div
					className={cls(
						css.inputWrapper,
						underlined && css.underlined,
						bordered && css.bordered,
						disabled && css.disabled,
						!!error && error !== true && css.hasError,
						hasFocus && css.hasFocus,
					)}
					ref={innerRef}
				>
					<div className={css.input}>
						{!!label && !bordered && underlined && (
							<label
								{...labelProps}
								id={inputId ? inputId + '-label' : labelProps?.id}
								className={cls(
									!normalLabel && css.label,
									normalLabel && css.normalLabel,
									labelProps && labelProps.className,
								)}
							>
								{label}
							</label>
						)}
						{children}
					</div>
					{buttons && !noButtons && (
						<div className={cls(css.actionButtons, underlined && css.underlined)}>{buttons}</div>
					)}
				</div>

				{!!helperText && (
					<div className={css.noteWrapper}>
						<PopoverButton
							type={'button'}
							action
							iconOnly
							popover={<div className={css.note}>{helperText}</div>}
							className={css.noteButton}
							message
						>
							<HelpIcon />
						</PopoverButton>
					</div>
				)}
			</div>
			{!!error && error !== true && <div className={css.error}>{error}</div>}
			{!!bottomText && <div className={css.bottomText}>{bottomText}</div>}
		</div>
	);
};

export default Field;
