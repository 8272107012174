import {DataGridAction, resolveLinkByItemAndLinkOptions} from '../settings/views/components/helper';
import getIcon from '../../utils/getIcon';
import {ButtonProps} from '../pirsInputs/Button/Button';
import React from 'react';
import css from './DataGrid.module.css';
import {Field, GridForm} from '../../queries-generated/types';

function onGridActionRender(
	dataSourceId: string,
	fields: Field[] | undefined,
	forms: GridForm[] | undefined,
	reload: () => void,
) {
	return function(
		area: 'item' | 'mass' | 'head' | 'details',
		action: DataGridAction,
		item: any,
		selectedIds: string[],
		fullSize?: boolean,
	) {
		const workWithSelection = area !== 'item' && area !== 'details';
		const linkOptions = workWithSelection ? action.headAction : action.itemAction;

		if (action.details && area === 'mass') return null;

		if (!linkOptions && action.itemAction && area === 'mass')
			return <div className={css.actionButton} role="presentation" />;

		if (!linkOptions) return null;

		const content = (
			<>
				{getIcon(action.icon)}
				{fullSize && action.title}
			</>
		);

		const buttonProps: Partial<ButtonProps> = {};
		if (area === 'head') {
			buttonProps.secondary = true;
			if (!fullSize) buttonProps.iconOnly = true;
		} else if (area === 'details') {
			buttonProps.secondary = true;
		} else {
			buttonProps.iconOnly = true;
			buttonProps.action = true;
		}

		return (
			<div className={css.actionButton} role="presentation">
				{resolveLinkByItemAndLinkOptions(
					content,
					linkOptions,
					{
						item,
						selectedIds,
						dataSourceId,
						reload,
						massAction: area === 'mass' || (!!action.isMassAction && area === 'head'),
						fields,
						forms,
					},

					buttonProps,
				)}
			</div>
		);
	};
}

export default onGridActionRender;
