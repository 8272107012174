import css from './AuthLayout.module.css';
import React, {CSSProperties} from 'react';
import cls from '../../utils/cls';

type Props = {
	styles?: CSSProperties;
	className?: string;
};

const AuthLayout: React.FC<Props> = ({children, styles, className}) => {
	return (
		<div className={cls(css.wrapper, className)} style={styles}>
			{children}
		</div>
	);
};

export default AuthLayout;
