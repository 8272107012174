import isIP from 'is-ip';

export function isValidSubnet(subnet: string) {
	const ip = new Ip(subnet);
	return ip.isValidSubnetMask() && ip.isValid();
}

const max4 = 2n ** 32n - 1n;
const max6 = 2n ** 128n - 1n;

type Version = 4 | 6;

export class Ip {
	public ip: string;
	public version?: Version;
	public subnetMask: number | null;
	public number: bigint = 0n;
	public ipv4mapped?: boolean;
	public scopeid?: string;
	constructor(input: string) {
		const [ip, subnetMask] = input.split('/');
		this.ip = ip;
		this.subnetMask = subnetMask ? parseInt(subnetMask, 10) : null;
		this.parse();
	}

	public isValid() {
		return this.number > 0n && this.number <= (this.version === 4 ? max4 : max6);
	}

	public isValidSubnetMask() {
		return (
			this.subnetMask === null ||
			(this.subnetMask >= 0 && this.version === 4 && this.subnetMask <= 32) ||
			(this.version === 6 && this.subnetMask <= 128)
		);
	}

	private parse() {
		let ip = this.ip;
		this.version = isIP.version(ip);
		if (!this.version) return;

		let exp = 0n;

		if (this.version === 4) {
			for (const n of ip
				.split('.')
				.map(Number)
				.reverse()) {
				this.number += BigInt(n) * 2n ** BigInt(exp);
				exp += 8n;
			}
		} else if (this.version === 6) {
			if (ip.includes('.')) {
				this.ipv4mapped = true;
				ip = ip
					.split(':')
					.map(part => {
						if (part.includes('.')) {
							const digits = part.split('.').map(str =>
								Number(str)
									.toString(16)
									.padStart(2, '0'),
							);
							return `${digits[0]}${digits[1]}:${digits[2]}${digits[3]}`;
						} else {
							return part;
						}
					})
					.join(':');
			}

			if (ip.includes('%')) {
				// @ts-ignore
				[, ip, this.scopeid] = /(.+)%(.+)/.exec(ip);
			}

			const parts = ip.split(':');
			const index = parts.indexOf('');

			if (index !== -1) {
				while (parts.length < 8) {
					parts.splice(index, 0, '');
				}
			}

			for (const n of parts
				.map(part => (part ? `0x${part}` : `0`))
				.map(Number)
				.reverse()) {
				this.number += BigInt(n) * 2n ** BigInt(exp);
				exp += 16n;
			}
		}
	}

	stringifySubnetMask() {
		return this.subnetMask ? `/${this.subnetMask}` : '';
	}

	stringify() {
		if (!this.isValid() || !this.isValidSubnetMask()) return 'Error';

		let step = this.version === 4 ? 24n : 112n;
		let remain = this.number;
		const parts: bigint[] = [];

		while (step > 0n) {
			const divisor = 2n ** BigInt(step);
			parts.push(remain / divisor);
			remain = this.number % divisor;
			step -= BigInt(this.version === 4 ? 8 : 16);
		}
		parts.push(remain);

		if (this.version === 4) {
			return parts.map(Number).join('.') + this.stringifySubnetMask();
		} else {
			let ip = '';
			if (this.ipv4mapped) {
				// eslint-disable-next-line prefer-const
				for (let [index, num] of Object.entries(parts.map(Number))) {
					const numIndex = Number(index);
					if (numIndex < 6) {
						ip += `${num.toString(16)}:`;
					} else {
						ip += `${String(num >> 8)}.${String(num & 255)}${numIndex === 6 ? '.' : ''}`;
					}
				}
			} else {
				ip = parts.map(n => Number(n).toString(16)).join(':');
			}

			if (this.scopeid) {
				ip = `${ip}%${this.scopeid}`;
			}

			return ip.replace(/\b:?(?:0+:?){2,}/, '::') + this.stringifySubnetMask();
		}
	}
}
