import {useContext} from 'react';
import {CommonContext} from '../commonRedux/CommonProvider';

const useUser = () => {
	const {state} = useContext(CommonContext);
	return state.user;
};

export const usePermissions = () => {
	const {state} = useContext(CommonContext);
	return state.user.permissions;
};

export const useRoles = () => {
	const {state} = useContext(CommonContext);
	return state.user.roles;
};

export const useSessionPopup = () => {
	const {setSessionPopup, state} = useContext(CommonContext);
	return {
		setSessionPopup,
		openSessionPopup: state.openSessionPopup,
	};
};

export default useUser;
