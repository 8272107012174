import React, {useCallback, useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {format, parseIso} from 'ts-date/locale/ru';
import css from './FullTableRow.module.css';
import {
	ModelItemsDocument,
	ModelItemsQuery,
	ModelItemsQueryVariables,
	TableColumn,
	TableColumnType,
	TableData,
	TableRelation,
} from '../../queries-generated/types';
import cls from '../../utils/cls';
import {useApolloClient} from '@apollo/client';
import {formatBytes} from '../../utils/number-utils';
import CheckCircle from '../pirsInputs/CheckCircle/CheckCircle';
import {DeleteIcon, EditIcon} from '../SvgIcon';
import ToggleModelItem from './ToggleModelItem';
import Button from '../pirsInputs/Button/Button';

type Props = {
	model: string;
	item: {[key: string]: any};
	visibleColumns: TableColumn[];
	detailsColumns?: TableColumn[];
	noWrapCells?: boolean;
	editable?: boolean;
	deletable?: boolean;
	selectable?: boolean;
	onSelect?: () => void;
	selected?: boolean;
	showEditButtonCallback?: (item: {[key: string]: any}) => boolean;
	showDeleteButtonCallback?: (item: {[key: string]: any}) => boolean;
	onStartEdit?: (id: string) => void;
	onStartDelete?: (id: string) => void;
	cellCallback?: {[row: string]: (value: any, item: {[key: string]: any}) => React.ReactNode};
	detailCellCallback?: {[row: string]: (value: any, item: {[key: string]: any}) => React.ReactNode};
	excludeDetailsId?: string[];
	additionalControls?: (item: {[key: string]: any}) => React.ReactNode;
};

function castToType(model: string, item: any, column: TableColumn): React.ReactNode {
	const value: any = item[column.id];

	if (column.relation?.model === 'schedule') {
		return value?.name;
	} else if (column.isArray) {
		if (value) {
			if (column.maxShownItems && value.length > column.maxShownItems) {
				return value.slice(0, column.maxShownItems).join(', ') + '...';
			}
			return value.join(', ');
		}
	} else
		switch (column.type) {
			case TableColumnType.Boolean:
				return value ? 'Да' : 'Нет';
			case TableColumnType.Toggle:
				return <ToggleModelItem model={model} itemId={item.id} keyField={column.id} value={!!value} />;
			case TableColumnType.Number: {
				const result = parseInt(value + '', 10);
				return isNaN(result) ? '' : result;
			}
			case TableColumnType.Float: {
				const result = parseFloat(value + '');
				return isNaN(result) ? '' : result;
			}
			case TableColumnType.String:
				return value ? value + '' : '';
			case TableColumnType.DateTime:
				return format(parseIso(value + ''), column.formatTemplate || 'DD MMM YYYY, HH:mm');
			case TableColumnType.FileSize:
				return formatBytes(value);
			case TableColumnType.ModelNavigationUrl: {
				if (!value) return;

				const {url, title, target} = value;

				return url ? (
					<a href={url} target={target || '_self'}>
						{title}
					</a>
				) : null;
			}
			default:
				return value ? value.toString() : value;
		}
}

function getRelationVariables(relation: TableRelation, item: any) {
	return {filter: `(${relation.foreignKey} eq ${item.id})`};
}

function getRelationData(relations: Relation[], column: string, foreignKey: any) {
	const relation = relations.find(relation => relation.column === column && relation.foreignKey == foreignKey);
	if (relation) return relation.data.items;
	return null;
}

type Relation = {
	data: TableData;
	source: TableRelation;
	column: string;
	foreignKey: string;
};

const FullTableRow: React.FC<Props> = ({
	model,
	cellCallback,
	detailCellCallback,
	item,
	visibleColumns,
	detailsColumns,
	editable,
	deletable,
	selectable,
	selected,
	onSelect,
	showEditButtonCallback,
	showDeleteButtonCallback,
	onStartDelete,
	onStartEdit,
	additionalControls,
}) => {
	const [showDetail, setShowDetails] = useState(false);
	const [hover, setHover] = useState(false);
	const [relations, setRelations] = useState<Relation[]>([]);
	const apolloClient = useApolloClient();
	const lazyLoadingColumns = [
		...visibleColumns.filter(column => column.relation?.lazyLoading),
		...(detailsColumns?.filter(detail => detail.relation?.lazyLoading) || []),
	];
	const eagerLoadingColumns = [
		...visibleColumns.filter(column => column.relation && !column.relation.lazyLoading),
		...(detailsColumns?.filter(column => column.relation && !column.relation.lazyLoading) || []),
	];

	const hasDetails = !!visibleColumns.filter(column => !column.relation && detailsColumns?.length).length;
	const addRelation = useCallback(
		(data: TableData, relation: TableRelation, column: string) => {
			if (
				!relations.find(savedRelation => savedRelation.column === column) &&
				data.items &&
				data.items[0] &&
				relation.foreignKey
			) {
				setRelations(currentRelations => [
					...currentRelations,
					{data, foreignKey: data.items[0][relation.foreignKey as string], source: relation, column: column},
				]);
			}
		},
		[relations],
	);

	const handleClick = useCallback(() => {
		if (!hasDetails && selectable) {
			if (onSelect) onSelect();
			return;
		}
		if (!showDetail && lazyLoadingColumns.length) {
			lazyLoadingColumns.forEach(column => {
				const {relation} = column;
				// TODO: Только для hasMany пока
				apolloClient
					.query<ModelItemsQuery, ModelItemsQueryVariables>({
						query: ModelItemsDocument,
						variables: {
							model: (relation as TableRelation).model,
							variables: getRelationVariables(relation as TableRelation, item),
						},
						fetchPolicy: 'network-only',
					})
					.then(result => {
						if (result.data.modelItems) {
							addRelation(result.data.modelItems, relation as TableRelation, column.id);
						}
					});
			});
		}

		if (!showDetail && eagerLoadingColumns.length) {
			eagerLoadingColumns.forEach(column => {
				const {relation} = column;
				addRelation(
					{items: item[column.id], columns: [], meta: {total: 0}},
					relation as TableRelation,
					column.id,
				);
			});
		}

		setShowDetails(!showDetail);
	}, [addRelation, selectable, apolloClient, eagerLoadingColumns, item, lazyLoadingColumns, showDetail]);

	const handleMouseOver = useCallback(() => {
		setHover(true);
	}, []);

	const handleMouseOut = useCallback(() => {
		setHover(false);
	}, []);

	const handleEdit = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			onStartEdit && onStartEdit(item.id);
		},
		[item.id, onStartEdit],
	);

	const handleDelete = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			onStartDelete && onStartDelete(item.id);
		},
		[onStartDelete, item.id],
	);

	return (
		<>
			<TableRow
				onClick={handleClick}
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
				className={cls(css.row, hover && css.hover, selected && css.selected)}
			>
				{(editable || deletable || additionalControls || selectable) && (
					<TableCell padding={'checkbox'} style={{padding: '0 0 0 16px'}}>
						<div className={css.controls}>
							{editable && (showEditButtonCallback ? showEditButtonCallback(item) : true) && (
								<div className={css.control}>
									<Button onClick={handleEdit} iconOnly action>
										<EditIcon />
									</Button>
								</div>
							)}
							{deletable && (showDeleteButtonCallback ? showDeleteButtonCallback(item) : true) && (
								<div className={css.control}>
									<Button onClick={handleDelete} iconOnly action>
										<DeleteIcon />
									</Button>
								</div>
							)}
							{additionalControls && <div className={css.control}>{additionalControls(item)}</div>}
							{selectable && onSelect && (
								<div className={css.select}>
									<CheckCircle onSelect={onSelect} selected={selected} />
								</div>
							)}
						</div>
					</TableCell>
				)}
				{visibleColumns.map(column => {
					const dictionaryValue =
						column.dictionary || column.oldDictionary ? item[`${column.id}.dictionary`] : undefined;

					const formattedValue = dictionaryValue ? dictionaryValue : castToType(model, item, column);

					return (
						<TableCell key={column.id}>
							{cellCallback && cellCallback[column.id]
								? cellCallback[column.id](item[column.id], item)
								: formattedValue}
						</TableCell>
					);
				})}
			</TableRow>
			{showDetail && (hasDetails || relations.length > 1) && (
				<TableRow>
					<TableCell colSpan={visibleColumns.length + (editable || deletable || additionalControls ? 1 : 0)}>
						<ul className={css.details}>
							{detailsColumns &&
								detailsColumns.map(detail => {
									const value = item[`${detail.id}.dictionary`] || item[detail.id];
									return value ||
										relations.find(relation => {
											return relation.column === detail.id && relation.foreignKey === item.id;
										}) ? (
										<li key={detail.id}>
											<span className={css.title}>{detail.title}</span>
											{detailCellCallback && detailCellCallback[detail.id] ? (
												detailCellCallback[detail.id](
													relations.length
														? getRelationData(relations, detail.id, item.id)
														: value,
													detail,
												)
											) : (
												<span className={css.value}>{castToType(model, item, detail)}</span>
											)}
										</li>
									) : (
										undefined
									);
								})}
						</ul>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};

export default FullTableRow;
