import React, {useCallback, useRef, useState} from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import {useFocus} from '../inputHooks';
import Field from '../Field/Field';
import css from './InputFile.module.css';
import Button from '../Button/Button';
import uid from '../../../utils/uid';
import {AttachFileIcon} from '../../SvgIcon';

interface InputInterface extends CommonInputInterface<File> {
	forwardRef?: React.RefObject<HTMLInputElement>;
}

export type InputProps = InputInterface & {
	link?: boolean;
	iconOnly?: boolean;
	action?: boolean;
	preview?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const InputFile: React.FC<InputProps> = props => {
	const {
		error,
		disabled,
		className,
		children,
		helperText,
		bottomText,
		label,
		onChange,
		value,
		link,
		action,
		iconOnly,
		preview,
		forwardRef: propsForwardRef,
		...inputProps
	} = props;
	const {onBlur, onFocus} = useFocus(props);
	const [file, setFile] = useState<File>();
	const ref = useRef<HTMLInputElement>(null);
	const forwardRef = propsForwardRef || ref;

	const id = props.id || 'Input-' + uid();

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.currentTarget && e.currentTarget.files && e.currentTarget.files.length > 0) {
				onChange(e.currentTarget.files.item(0) as File);
				setFile(e.currentTarget.files.item(0) as File);
			}
		},
		[onChange],
	);

	const handleClick = useCallback(() => {
		if (ref?.current) ref.current.click();
	}, []);

	return (
		<Field commonProps={props} normalLabel topLabel={true} className={className}>
			<div className={css.file}>
				<Button
					type="button"
					secondary
					action={action}
					link={link}
					iconOnly={iconOnly}
					onClick={handleClick}
					disabled={disabled}
					className={css.button}
				>
					{children || (
						<>
							<AttachFileIcon />
							Выберите файл
						</>
					)}
				</Button>
				{preview && <div className={css.text}>{file && file.name}</div>}

				<input
					id={id}
					disabled={disabled}
					{...(inputProps as any)}
					className={css.fileInput}
					type={'file'}
					onChange={handleChange}
					onBlur={onBlur}
					onFocus={onFocus}
					ref={forwardRef}
					multiple={false}
					tabIndex={-1}
				/>
			</div>
		</Field>
	);
};

export default InputFile;
