import React, {useCallback, useMemo, useState} from 'react';
import css from './DataGrid.module.css';
import DataGridCell from './DataGridCell';
import cls from '../../utils/cls';
import {ChevronRightIcon} from '../SvgIcon';
import {Field} from '../../queries-generated/types';
import Button from '../pirsInputs/Button/Button';
import {defaultOnCellRender} from './onCellRender';
import * as SvgIcon from '../SvgIcon';
import {DataGridAction, getItemByField} from '../settings/views/components/helper';
import Checkbox from '../pirsInputs/Checkbox/Checkbox';

export type TableRowOptions = {
	detailsFields?: Field[];
	visibleFields: Field[];
	selectable?: boolean;
	selectedIds?: string[];
	partialSelectedIds?: string[];
	onSelect?: (ids: string[]) => void;
	checkColor?: string;
	groupPart?: boolean;
	isGroupHeader?: boolean;
	groupOpen?: boolean;
	fakeDetailsButton?: boolean;
	onGroupToggle?();
	onMouseEnter?(item: any);
	onMouseLeave?(item: any);
	onCellRender?: (item: any, field: Field, details: boolean, index: number) => React.ReactNode;
	onActionRender?: (
		area: 'item' | 'details',
		action: DataGridAction,
		item: any,
		selectedIds: string[],
		fullSize: boolean,
	) => React.ReactNode;
};

type Props = {
	item: PlainObjectOf<any>;
	actionButtons?: DataGridAction[];
	renderedVisibleItem: PlainObjectOf<React.ReactNode | string>;
	options: TableRowOptions;
	index: number;
};

const DataGridRow: React.FC<Props> = ({item, actionButtons, renderedVisibleItem, options, index}) => {
	const {
		detailsFields,
		visibleFields,
		selectable,
		onSelect,
		selectedIds,
		partialSelectedIds,
		checkColor,
		groupPart,
		isGroupHeader,
		groupOpen,
		fakeDetailsButton,
		onGroupToggle,
		onMouseEnter,
		onMouseLeave,
		onCellRender,
		onActionRender,
	} = options;
	const [openDetails, setOpenDetails] = useState<boolean>(false);

	const handleSelect = useCallback(() => {
		if (onSelect && item.id) {
			onSelect([item.id]);
		}
	}, [onSelect, item]);

	const handleToggleGroup = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			if (onGroupToggle) onGroupToggle();
		},
		[onGroupToggle],
	);

	const handleClick = useCallback((): void => {
		if (selectable) handleSelect();
		else if (onGroupToggle) onGroupToggle();
		else if (detailsFields && detailsFields.length) setOpenDetails(!openDetails);
	}, [openDetails, setOpenDetails, detailsFields, selectable, handleSelect, onGroupToggle]);

	const toggleDetails = (event: React.MouseEvent) => {
		event.stopPropagation();
		if (detailsFields && detailsFields.length) setOpenDetails(!openDetails);
	};

	const handleMouseEnter = useCallback(() => {
		if (onMouseEnter) onMouseEnter(item);
	}, [item]);

	const handleMouseLeave = useCallback(() => {
		if (onMouseLeave) onMouseLeave(item);
	}, [item]);

	const isSelected = useMemo(() => selectedIds && item.id && selectedIds.includes(item.id), [selectedIds, item.id]);
	const isPartialSelected = useMemo(() => partialSelectedIds && item.id && partialSelectedIds.includes(item.id), [
		partialSelectedIds,
		item.id,
	]);
	const clickable = !!(selectable || detailsFields?.length || groupPart || isGroupHeader);

	const renderedActionButtons = useMemo(() => {
		if (actionButtons?.length && onActionRender) {
			return actionButtons
				.filter(actionButton => !actionButton.details)
				.map(actionButton => (
					<React.Fragment key={actionButton.id}>
						{onActionRender('item', actionButton, item, [], false)}
					</React.Fragment>
				));
		}
	}, [actionButtons, selectedIds, item]);

	return (
		<>
			<div
				className={cls(
					css.row,
					groupOpen && css.groupHeaderOpen,
					(isSelected || isPartialSelected) && css.selected,
					clickable && css.clickable,
					openDetails && detailsFields && css.detailsOpen,
				)}
				onClick={handleClick}
				role="row"
				aria-rowindex={index + 2}
				aria-selected={isSelected ? 'true' : 'false'}
				aria-level={groupPart ? 2 : 1}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{(selectable || (actionButtons && actionButtons.length > 0) || groupPart || isGroupHeader) && (
					<div className={cls(css.actions, css.cell)}>
						{selectable && (
							<div className={cls(css.selectCircle)} role="presentation">
								<Checkbox
									checked={isSelected || isPartialSelected}
									indeterminate={isPartialSelected}
									onChange={handleSelect}
									style={isSelected ? {backgroundColor: checkColor} : undefined}
								/>
							</div>
						)}
						{actionButtons && actionButtons.length > 0 && (
							<div className={cls(css.actionButtons)} role="presentation">
								{renderedActionButtons}
							</div>
						)}
						{(groupPart || isGroupHeader) && (
							<div
								className={cls(css.groupCollapse, isGroupHeader && css.groupHeaderButton)}
								role="presentation"
							>
								{isGroupHeader && (
									<Button iconOnly action onClick={handleToggleGroup}>
										<ChevronRightIcon className={cls(groupOpen && css.groupOpenIcon)} />
									</Button>
								)}
							</div>
						)}
					</div>
				)}
				{visibleFields.map((field, index) => (
					<DataGridCell key={index} item={renderedVisibleItem} field={field} index={index} />
				))}
				{detailsFields && detailsFields?.length > 0 && (
					<div className={cls(css.cell, css.detailsToggle)}>
						<Button
							action
							iconOnly
							className={css.detailsToggleButton}
							onClick={toggleDetails}
							title={'Дополнительные поля'}
						>
							<SvgIcon.ChevronDownIcon width={14} height={14} />
						</Button>
					</div>
				)}
				{fakeDetailsButton && <div className={cls(css.cell, css.detailsToggle)} />}
				{openDetails && detailsFields && (
					<div
						className={cls(css.detailRows, isSelected && css.selected)}
						role="presentation"
						onClick={e => e.stopPropagation()}
					>
						{detailsFields.map((field, fieldIndex) => {
							const value = getItemByField(field, item);
							return !value ? null : (
								<React.Fragment key={fieldIndex}>
									<div className={css.detailTitle} role="presentation">
										{field.title}
									</div>
									<div className={css.detailCell}>
										{onCellRender
											? onCellRender(item, field, true, index)
											: defaultOnCellRender(item, field, true)}
									</div>
								</React.Fragment>
							);
						})}
						{actionButtons && onActionRender && (
							<div className={css.detailsButtons}>
								{actionButtons
									.filter(actionButton => actionButton.details)
									.map(actionButton => (
										<React.Fragment key={actionButton.id}>
											{onActionRender('details', actionButton, item, [], true)}
										</React.Fragment>
									))}
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default DataGridRow;
