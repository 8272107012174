import {ResourceRegisterUploadFileContextType} from './ResourceRegistryUploadFilesProvider';
import {ResourceRegisterUploadFileStatus} from './ResourceRegistryUploadFilesReducer';
import {tryParse} from '../../hooks/useLocalStorage';

export const useResourceRegisterUploadFileQueue = state => {
	const {
		setLoadingProgress,
		setFileStatus,
		removeFiles,
		setRequestInstance,
	} = state as ResourceRegisterUploadFileContextType;

	function uploadFile(id: string, file: File, url: string) {
		const request = new XMLHttpRequest();
		setRequestInstance({id, instance: request});

		request.open('POST', url, true);
		request.onabort = function() {
			removeFiles([id]);
		};
		request.upload.onprogress = function(event) {
			setLoadingProgress({id: id, loadingProgress: parseInt(((event.loaded / event.total) * 100).toFixed(0))});
		};
		request.onload = function() {
			const responseBody = tryParse(request.responseText);
			const errorText =
				typeof responseBody === 'object' && responseBody?.error ? responseBody.error : request.statusText;
			if (request.status == 201) {
				setFileStatus({id: id, fileStatus: ResourceRegisterUploadFileStatus.LOADED});
				removeFiles([id]);
			} else {
				setFileStatus({
					id: id,
					fileStatus: ResourceRegisterUploadFileStatus.ERROR,
					error: errorText,
				});
			}
		};
		request.onerror = function() {
			setFileStatus({
				id: id,
				fileStatus: ResourceRegisterUploadFileStatus.ERROR,
				error: request.statusText,
			});
		};

		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onloadend = function() {
			request.setRequestHeader('Content-Type', 'application/json');
			request.withCredentials;

			const base64data = reader.result?.toString() || '';
			const content = base64data.substring(base64data.indexOf(',') + 1);

			request.send(JSON.stringify({content, fileName: file.name}));
		};
	}

	return {uploadFile};
};
