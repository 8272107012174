import {useCallback, useEffect, useState} from 'react';
import {
	PublishFileResult,
	useCreateFileMutation,
	useDeleteBatchMutation,
	useFileStatusQuery,
	usePublishFileMutation,
} from '../../queries-generated/types';
import {getApolloError, getQueryError} from '../../utils/error';
import {useUploadFiles} from '../../components/commonRedux/UploadFileStore/UploadFileQueueProvider';
import {UploadFileStatus} from '../../components/commonRedux/UploadFileStore/UploadFileQueueReducer';

type Props = {
	sectionId: string;
	filesListRefetch: () => void;
	setTempFileData: (undefinedKey: undefined) => void;
	tempFileData?: {fileName: string; fileSize: string; progress: 0};
};

export enum LocalFileStatus {
	created = 'created',
	inProgress = 'in_progress',
	finished = 'finished',
	failed = 'failed',
}

export enum FileChunksUploadErrorType {
	QUERYERROR = 'QUERYERROR',
	APOLLOERROR = 'APOLLOERROR',
	CUSTOMERROR = 'CUSTOMERROR',
}

type FileChunksUploadError = {
	type: FileChunksUploadErrorType;
	message: string;
};

const chunkLength = Number(process.env.REACT_APP_CHUNK_LENGTH);

function useFileChunkUpload({sectionId, filesListRefetch, setTempFileData, tempFileData}: Props) {
	const {data: fileStatusData, loading: fileStatusLoading, refetch: fileStatusRefetch} = useFileStatusQuery({
		variables: {
			input: {
				sectionId,
			},
		},
	});

	const localFile = fileStatusData?.fileStatus;

	const pageId = `information_sectionId=${sectionId}`;
	const {addFiles, removeFiles, files} = useUploadFiles(pageId);
	const currentUploadFile = files[0];

	const [createFileMutation] = useCreateFileMutation();
	const [deleteBatchMutation] = useDeleteBatchMutation();
	const [publishFileMutation] = usePublishFileMutation();

	const [error, setError] = useState<FileChunksUploadError>();
	const [loading, setLoading] = useState(false);
	const [publishStatus, setPublishStatus] = useState<PublishFileResult>();

	useEffect(() => {
		setLoading(true);
		fileStatusRefetch().then(result => {
			// Если с другого браузера удалили batch в текущей секции, в то время как у текущего пользователя файл в очереди на загрузку
			if (!result.data.fileStatus.status && currentUploadFile?.status === UploadFileStatus.QUEUE) {
				setError({
					type: FileChunksUploadErrorType.CUSTOMERROR,
					message: `Ошибка загрузки файла ${currentUploadFile?.file.name}`,
				});
				removeFiles([currentUploadFile?.url]);
			}
			/*
			Если пользователь вернулся в секцию а тут ошибка загрузки файла, то не убирать лоадер.
			Дальнейшая логика работы переходит в следующий useEffect
			*/
			if (currentUploadFile?.status !== UploadFileStatus.ERROR) {
				setLoading(false);
			}
		});
		filesListRefetch();
	}, []);

	useEffect(() => {
		if (
			currentUploadFile?.status === UploadFileStatus.QUEUE ||
			currentUploadFile?.status === UploadFileStatus.LOADING ||
			tempFileData
		) {
			setError(undefined);
		} else if (currentUploadFile?.status === UploadFileStatus.LOADED) {
			fileStatusRefetch().then(() => {
				removeFiles([currentUploadFile?.url]);
			});
		} else if (currentUploadFile?.status === UploadFileStatus.ERROR) {
			setLoading(true);
			fileStatusRefetch()
				.then(result => {
					if (
						result.data.fileStatus.status === LocalFileStatus.created ||
						result.data.fileStatus.status === LocalFileStatus.inProgress
					) {
						deleteBatchMutation({
							variables: {
								input: {
									sectionId,
								},
							},
						})
							.then(() => {
								setError({
									type: FileChunksUploadErrorType.CUSTOMERROR,
									message: `Ошибка загрузки файла ${currentUploadFile?.file.name}`,
								});
							})
							.catch(() => {
								setError({
									type: FileChunksUploadErrorType.CUSTOMERROR,
									message: `Произошла ошибка загрузки файла, пожалуйста удалите файл`,
								});
							})
							.finally(() => {
								setLoading(true);
								fileStatusRefetch().finally(() => {
									removeFiles([currentUploadFile?.url]);
									setLoading(false);
								});
							});
					} else {
						setError({
							type: FileChunksUploadErrorType.CUSTOMERROR,
							message: `Ошибка загрузки файла ${currentUploadFile?.file.name}`,
						});
						removeFiles([currentUploadFile?.url]);
						setLoading(false);
					}
				})
				.catch(() => {
					setLoading(false);
				});
		}
	}, [currentUploadFile?.status, tempFileData]);

	const uploadFile = useCallback(
		(file: File) => {
			setPublishStatus(undefined);
			const numberOfChunks = Math.ceil(file.size / chunkLength);
			createFileMutation({
				variables: {
					input: {
						size: file.size.toString(),
						numberOfChunks: numberOfChunks.toString(),
						name: file.name,
						sectionId: sectionId,
					},
				},
			})
				.then(result => {
					if (result && result.data) {
						addFiles([
							{
								url: `/services/uploadChunk?id=${result.data.createFile}&sectionId=${sectionId}`,
								pageId,
								file,
							},
						]);
					} else {
						setError({
							type: FileChunksUploadErrorType.QUERYERROR,
							message: getQueryError(result.errors).message,
						});
					}
					setTempFileData(undefined);
				})
				.catch(error => {
					setTempFileData(undefined);
					setError({type: FileChunksUploadErrorType.APOLLOERROR, message: getApolloError(error).message});
				});
		},
		[createFileMutation, sectionId, addFiles],
	);

	const cancelUpload = useCallback(() => {
		// TODO: maybe cancel mutations
		setLoading(true);
		deleteBatchMutation({
			variables: {
				input: {
					sectionId,
				},
			},
		})
			.then(() => {
				try {
					fileStatusRefetch().then(() => {
						setLoading(false);
						removeFiles([currentUploadFile?.url]);
					});
					setError(undefined);
				} catch {
					// заглушка
				}
			})
			.catch(() => {
				try {
					fileStatusRefetch().then(() => {
						setLoading(false);
					});
					setError({type: FileChunksUploadErrorType.CUSTOMERROR, message: 'Не удалось удалить файл'});
				} catch {
					// заглушка
				}
			});
	}, [deleteBatchMutation, sectionId, fileStatusRefetch, currentUploadFile?.url]);

	const publishFile = useCallback(() => {
		setLoading(true);
		publishFileMutation({
			variables: {
				input: {
					sectionId,
				},
			},
		})
			.then(result => {
				if (result?.data?.publishFile.status === 'finished') {
					setPublishStatus(result.data.publishFile);
					setError(undefined);
				} else {
					setError({type: FileChunksUploadErrorType.CUSTOMERROR, message: 'Не удалось внести файл'});
				}
				Promise.all([fileStatusRefetch(), filesListRefetch()])
					.then(() => {
						setLoading(false);
					})
					.catch(e => {
						setLoading(false);
						setError({
							type: FileChunksUploadErrorType.CUSTOMERROR,
							message: 'Ошибка получения данных после публикации файла',
						});
						console.error(e);
					});
			})
			.catch(e => {
				const errorMessage = getApolloError(e).message;
				if (errorMessage) {
					setError({
						type: FileChunksUploadErrorType.APOLLOERROR,
						message: errorMessage,
					});
				} else {
					setError({type: FileChunksUploadErrorType.CUSTOMERROR, message: 'Не удалось внести файл'});
				}
				setLoading(false);
			});
	}, [fileStatusRefetch, filesListRefetch, publishFileMutation, sectionId]);

	return {
		localFile:
			currentUploadFile?.status === UploadFileStatus.LOADING ||
			(currentUploadFile?.status === UploadFileStatus.LOADED && localFile?.status === LocalFileStatus.inProgress)
				? undefined
				: localFile,
		uploadFile,
		cancelUpload,
		publishFile,
		loading: loading || fileStatusLoading,
		currentLoadingFile: currentUploadFile,
		fileQueue: currentUploadFile?.status === UploadFileStatus.QUEUE,
		fileLoading:
			currentUploadFile?.status === UploadFileStatus.LOADING ||
			currentUploadFile?.status === UploadFileStatus.LOADED,
		progress: currentUploadFile?.status === UploadFileStatus.LOADED ? 100 : currentUploadFile?.progressLoading,
		error,
		publishStatus,
	};
}

export default useFileChunkUpload;
