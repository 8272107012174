import React from 'react';
import {useField, useFormikContext} from 'formik';
import {default as OriginalInput} from '../../pirsInputs/Combobox/Combobox.formik';
import {useApolloClient} from '@apollo/client';
import {
	DictionaryItem,
	RecipientDictionaryDocument,
	RecipientDictionaryQuery,
	RecipientDictionaryQueryVariables,
} from '../../../queries-generated/types';

function RecipientCombobox(props: {
	name: string;
	onCustomChange?: (value: string | null) => void;
	/* Тип получателя */
	type: string;
	/* param получателя */
	param: string;
	label?: string | React.ReactNode;
	disabled?: boolean;
}) {
	const [field, meta] = useField<{key: string; value: string}>(props as any);
	const {setFieldValue} = useFormikContext();
	const {onCustomChange, ...fieldProps} = props;

	const itemToString = value => value?.value || '';

	const client = useApolloClient();

	return (
		<OriginalInput<DictionaryItem>
			{...field}
			{...(fieldProps as any)}
			error={meta.error}
			value={field.value}
			getItems={value => {
				if (!value) return null;
				return client
					.query<RecipientDictionaryQuery, RecipientDictionaryQueryVariables>({
						query: RecipientDictionaryDocument,
						variables: {
							type: props.type,
							param: props.param,
							suggest: value,
						},
					})
					.then(result => result.data.recipientDictionary);
			}}
			itemToString={itemToString}
			primaryKey={value => value.key}
			onChange={value => {
				setFieldValue(field.name, value);
				if (onCustomChange) onCustomChange(value);
			}}
		/>
	);
}

export default RecipientCombobox;
