import React, {useCallback, useMemo} from 'react';
import css from './DataGrid.module.css';
import cls from '../../utils/cls';
import {ArrowDownIcon, ArrowUpIcon, ChevronRightIcon} from '../SvgIcon';
import {Field, FieldShownType} from '../../queries-generated/types';
import Button from '../pirsInputs/Button/Button';
import {DataGridAction} from '../settings/views/components/helper';
import Checkbox from '../pirsInputs/Checkbox/Checkbox';

export type TableHeadOptions = {
	visibleFields: Field[];
	hasDetails: boolean;
	onChangeSort?: (columnId: string) => void;
	sort?: string | null;
	sortOrder?: boolean;
	selectable?: boolean;
	isSelectedAll?: boolean;
	isPartialSelect?: boolean;
	onSelectAll?: () => void;
	selectedIds: string[];
	hasGroupFields?: boolean;
	openAllGroups?: boolean;
	onToggleGroups?();
	onActionRender?: (
		area: 'mass',
		action: DataGridAction,
		item: any,
		selectedIds: string[],
		fullSize: boolean,
	) => React.ReactNode;
};

type Props = {
	options: TableHeadOptions;
	actionButtons?: DataGridAction[];
};

const DataGridHead: React.FC<Props> = ({options, actionButtons}) => {
	const {
		visibleFields,
		hasDetails,
		sort,
		sortOrder,
		onChangeSort,
		isSelectedAll,
		isPartialSelect,
		onSelectAll,
		selectedIds,
		selectable,
		hasGroupFields,
		openAllGroups,
		onToggleGroups,
		onActionRender,
	} = options;

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>, field: Field) => {
			const target = e.target;
			if (target) {
				if (e.keyCode === 37) {
					// @ts-ignore
					target?.previousSibling?.focus();
					e.preventDefault();
				} else if (e.keyCode === 39) {
					// @ts-ignore
					target?.nextSibling?.focus();
					e.preventDefault();
				} else if (e.keyCode === 13) {
					field.canSort && onChangeSort && onChangeSort(field.id);
				}
			}
		},
		[onChangeSort],
	);

	const renderedActionButtons = useMemo(() => {
		if (actionButtons?.length && onActionRender) {
			return actionButtons.map(actionButton => (
				<React.Fragment key={actionButton.id}>
					{onActionRender('mass', actionButton, null, selectedIds, false)}
				</React.Fragment>
			));
		}
	}, [actionButtons, selectedIds]);

	return (
		<div
			className={cls(css.headers, (isSelectedAll || isPartialSelect) && css.selected)}
			role="row"
			aria-rowindex={1}
		>
			{(selectable || (actionButtons && actionButtons.length > 0) || hasGroupFields) && (
				<div className={cls(css.actions, css.column)}>
					{selectable && (
						<div className={css.selectCircle} role="columnheader">
							<Checkbox
								checked={isSelectedAll || isPartialSelect}
								indeterminate={isPartialSelect}
								onChange={onSelectAll as any}
							/>
						</div>
					)}
					{actionButtons && actionButtons.length > 0 && (
						<div className={css.actionButtons} role="presentation">
							{renderedActionButtons}
						</div>
					)}
					{hasGroupFields && (
						<div className={cls(css.groupCollapse, css.groupHeaderButton)} role="columnheader">
							<Button aria-hidden="true" onClick={onToggleGroups} iconOnly action>
								<ChevronRightIcon className={cls(openAllGroups && css.groupOpenIcon)} />
							</Button>
						</div>
					)}
				</div>
			)}
			{visibleFields.map((field, index) => (
				<div
					key={field.id}
					className={cls(css.column, field.canSort && css.orderedColumn)}
					onClick={() => field.canSort && onChangeSort && onChangeSort(field.id)}
					onKeyDown={e => handleKeyDown(e, field)}
					role="columnheader"
					aria-sort={sortOrder ? 'descending' : 'ascending'}
					aria-colindex={index + 1}
					tabIndex={field.canSort ? (field.id === sort ? 0 : -1) : undefined}
					title={field.title}
				>
					<div className={css.header}>
						{field.showType !== FieldShownType.Icon && (
							<>
								<div className={css.columnTitle}>{field.title}</div>
								{field.canSort && field.id === sort && (
									<div>{sortOrder ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
								)}
							</>
						)}
					</div>
				</div>
			))}
			{hasDetails && <div className={css.column} />}
		</div>
	);
};

export default DataGridHead;
