import React from 'react';
import Shimmer, {ShimmerText} from '../Shimmer/Shimmer';

const InputShimmer: React.FC = () => {
	return (
		<>
			<ShimmerText lines={1} fontSize={'small'} styles={{width: 100}} rootStyles={{marginBottom: 8}} />
			<Shimmer>
				<div style={{height: 32, width: '100%', borderRadius: 6}} />
			</Shimmer>
		</>
	);
};

export default InputShimmer;
