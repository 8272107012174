import React, {useCallback} from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import {useRouter} from 'react-named-hooks-router';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import ModalPopup from '../../components/controlls/ModalPopup/ModalPopup';
import AssignAdapterForm from './AssignAdapterForm';
import Button from '../../components/pirsInputs/Button/Button';
import {useWithdrawAdapterMutation} from '../../queries-generated/types';
import WithdrawAdapterButton from './WithdrawAdapterButton';
import {notifyToastError} from '../../components/toast/Toast';
import {useBoolean} from '../../components/hooks/useBoolean';

const IntegrationUniversalScriptAdapterPage: React.FC = () => {
	const {routeParams} = useRouter<{id: string}>();

	const [isOpenAssignForm, {setTrue, setFalse}] = useBoolean(false);

	const [withdrawAdapter] = useWithdrawAdapterMutation();

	const handleWithdrawAdapter = useCallback(
		(adapterId: string, scriptId: string) => {
			withdrawAdapter({
				variables: {
					adapterId,
					scriptId,
				},
			})
				.then(() => refresh())
				.catch(error => notifyToastError(error));
		},
		[withdrawAdapter],
	);

	const {Table, refresh} = useFullTableWithRefetch('integrationUniversalScriptAdapter', {
		tableFields: ['name', 'description', 'uuid', 'enabled'],
		detailsFields: ['info'],
		variables: {
			filter: `(script_id eq ${routeParams.id})`,
		},
		additionalFilters: (
			<>
				<Button onClick={setTrue}>Назначить</Button>
				<Button route={'integrationUniversalScriptTable'} secondary>
					Вернуться
				</Button>
			</>
		),
		additionalControls: item => {
			return (
				<WithdrawAdapterButton
					scriptId={routeParams.id}
					adapterId={item.id}
					withdrawAdapterMutation={handleWithdrawAdapter}
				/>
			);
		},
	});

	return (
		<MainLayout>
			<ModalPopup maxWidth={400} open={isOpenAssignForm} header={'Назначить адаптер'} onClose={setFalse}>
				<AssignAdapterForm id={routeParams.id} onClose={setFalse} reFetchTable={refresh} />
			</ModalPopup>
			<Table />
		</MainLayout>
	);
};

export default IntegrationUniversalScriptAdapterPage;
