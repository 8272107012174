import React from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {Diagram} from '../../../../../queries-generated/types';
import ChartWrapperWithData from '../ChartsBase/ChartWrapperWithData';
import {ChartTypes} from '../ChartsBase/ChartWrapper';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	pollInterval?: number;
};

export const defaultData: Diagram = {
	series: [
		{
			name: 'Chrome',
			points: [{y: 10.41}, {y: 40.41}, {y: 61.41}],
		},
		{
			name: 'Internet Explorer',
			points: [{y: 90.84}, {y: 70.84}, {y: 11.84}],
		},
		{
			name: 'Firefox',
			points: [{y: 30.85}, {y: 10.85}],
		},
		{
			name: 'Edge',
			points: [{y: 10.85}, {y: 20.85}, {y: 4.67}],
		},
		{
			name: 'Safari',
			points: [{y: 4.18}],
		},
	],
};

const StackedLineChart: React.FC<Props> = ({viewItem, skipQuery, pollInterval}) => {
	return (
		<ChartWrapperWithData
			pollInterval={pollInterval}
			viewItem={viewItem}
			skipQuery={skipQuery}
			defaultData={defaultData}
			mapper={diagram => diagram}
			type={ChartTypes.StackedLine}
		/>
	);
};

export default StackedLineChart;
