import React, {useCallback, useContext, useMemo, useState} from 'react';

export type inspectorComment = {comment: string; path: string[]};

type ProviderProps = {
	inspectorComments?: inspectorComment[];
};

type ContextProps = {
	handleShowComments: () => void;
	showComments: boolean;
	getInspectorCommentByPath: (path: string) => string | undefined;
	inspectorCommentsPathList: string[]; // Полные пути к атрибутам строкой
} & ProviderProps;

export const InspectorCommentsContext = React.createContext<ContextProps>(null as any);

export const InspectorCommentsProvider: React.FC<ProviderProps> = ({inspectorComments, children}) => {
	const [showComments, setShowComments] = useState<boolean>(true);

	const handleShowComments = useCallback(() => {
		setShowComments(!showComments);
	}, [showComments, setShowComments]);

	const inspectorCommentsPathList = useMemo(() => {
		if (inspectorComments?.length && showComments) {
			return inspectorComments.reduce((previousValue, currentValue) => {
				previousValue.push(currentValue.path.join('.'));
				return previousValue;
			}, [] as string[]);
		} else {
			return [];
		}
	}, [inspectorComments, showComments]);

	const getInspectorCommentByPath = useCallback(
		(path: string): string | undefined => {
			return inspectorComments?.find(value => value.path.join('.') === path)?.comment;
		},
		[inspectorComments],
	);

	return (
		<InspectorCommentsContext.Provider
			value={{
				inspectorComments,
				handleShowComments,
				showComments,
				inspectorCommentsPathList,
				getInspectorCommentByPath,
			}}
		>
			{children}
		</InspectorCommentsContext.Provider>
	);
};

function useInspectorComments() {
	return useContext(InspectorCommentsContext);
}

export default useInspectorComments;
