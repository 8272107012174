import React, {useCallback} from 'react';
import css from './ChoiceGroup.module.css';
import RadioButtonGroup from '../../pirsInputs/RadioButton/RadioButtonGroup';
import useMetadata, {Entity} from './useMetadata';
import EntityItem from './EntityItem';

type Props = {
	entities: Entity[];
	name?: string;
	deep?: number;
	errors?: any;
	required: boolean;
	id?: string;
	type: string;
};

const ChoiceGroup: React.FC<Props> = ({entities, required, name, errors, deep, id, type}) => {
	const choiceGroup = entities[0].choiceGroup as string;
	const {state, setSelectedChoiceGroup} = useMetadata();
	const {selectedChoiceGroup} = state;
	const selected = selectedChoiceGroup[choiceGroup] || entities[0].keyword;

	const handleChange = useCallback(
		keyword => {
			setSelectedChoiceGroup({choiceGroup, keyword});
		},
		[choiceGroup, setSelectedChoiceGroup],
	);

	const currentEntity = entities.find(entity => entity.keyword === selected);
	return (
		<>
			{!deep ? <h1>{entities[0].choiceGroup}</h1> : <h3>{entities[0].choiceGroup}</h3>}
			<p>Сделайте выбор:</p>
			<div className={css.wrapper}>
				<div className={css.buttons}>
					<RadioButtonGroup
						buttons={entities.map(entity => ({
							name: entity.choiceGroup,
							value: entity.keyword,
							label: entity.caption,
						}))}
						value={selected}
						onChange={handleChange}
					/>
				</div>
				<div className={css.form}>
					{currentEntity && (
						<EntityItem
							entity={currentEntity}
							name={name ? name + '.' + currentEntity.keyword : currentEntity.keyword}
							deep={(deep || 0) + 1}
							errors={errors && errors[currentEntity.keyword]}
							required={required}
							id={id}
							type={type}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default ChoiceGroup;
