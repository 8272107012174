import React, {useContext, useEffect, useMemo} from 'react';
import {defaultTableData, TableComponentProps} from '../GridComponent/GridComponent';
import {DataSourceContext} from '../../../../view/ViewWrapper';
import usePrevious from '../../../../hooks/usePrevious';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import useDataGridOptions from '../GridComponent/useDataGridOptions';
import ErrorSnippet from '../../../../ErrorSnippet/ErrorSnippet';
import {NetworkStatus} from '@apollo/client';
import dataGridCss from '../../../../DataGrid/DataGrid.module.css';
import {defaultOnCellRender} from '../../../../DataGrid/onCellRender';
import {ShimmerText} from '../../../../Shimmer/Shimmer';
import cls from '../../../../../utils/cls';
import {useGridQuery} from '../../../../../queries-generated/types';
import gridListCss from './GridListComponent.module.css';
import {useComponentFilters} from '../../../../view/api/useUserFilters';
import {filtersToDataSourceParams} from '../helper';

const GridListComponent: React.FC<TableComponentProps> = ({viewItem, skipQuery, theme, height, pollInterval}) => {
	const {reloadCounter} = useContext(DataSourceContext);
	const prevReloadCounter = usePrevious(reloadCounter);

	const {
		dataSource,
		component: {props},
	} = viewItem;

	const {filters, waiting} = useComponentFilters(viewItem);
	const {setData} = useComponentsStoredData(viewItem.id);

	const {setGridData, fields: storedFields} = useDataGridOptions({
		defaultNumberOfRows: props.defaultNumberOfRows,
		viewItemId: viewItem.id,
	});

	const {data, loading, refetch, error, networkStatus} = useGridQuery({
		variables: {
			dataSourceId: dataSource?.type || '',
			filters: filtersToDataSourceParams(filters),
		},
		skip: skipQuery || waiting,
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		notifyOnNetworkStatusChange: true,
		pollInterval,
		onCompleted: result => {
			const dataSourceContentTable = result?.grid;
			if (dataSourceContentTable) {
				setGridData({fields: dataSourceContentTable.fields, options: dataSourceContentTable.options});
			}
		},
	});

	useEffect(() => {
		const table = skipQuery ? defaultTableData : data?.grid;
		setData({...viewItem.dataSource, data: table});
	}, [defaultTableData, data, skipQuery, viewItem]);

	const table = skipQuery ? defaultTableData : data?.grid;

	const fields = storedFields || table?.fields || [];

	const filteredFields = useMemo(() => {
		return [...fields]
			.filter(field => field.visible || field.detail)
			.sort((field11, field2) => (field11.order || 0) - (field2.order || 0));
	}, [fields]);

	useEffect(() => {
		if (prevReloadCounter !== undefined && reloadCounter !== prevReloadCounter && !skipQuery) {
			refetch();
		}
	}, [reloadCounter, skipQuery, prevReloadCounter]);

	return (
		<div className={dataGridCss.wrapper} style={{height}}>
			{error ? (
				<ErrorSnippet error={error} errorHeader={'Ошибка загрузки данных'} refetch={refetch} />
			) : (
				<div
					role="grid"
					aria-rowcount={filteredFields.length}
					aria-colcount={props.columnsCount || 1}
					aria-readonly="true"
					className={cls(
						dataGridCss.grid,
						dataGridCss[`${theme || 'body'}Theme`],
						!loading && filteredFields.length === 0 && dataGridCss.noItems,
					)}
				>
					{loading && networkStatus !== NetworkStatus.poll ? (
						<ShimmerText lines={filteredFields.length || 3} styles={{width: '100%'}} />
					) : filteredFields.length === 0 || !table?.items[0] ? (
						<div className={dataGridCss.message}>Пока тут ничего нет</div>
					) : (
						<div style={{columnCount: props.columnsCount || 1, width: 'fit-content'}}>
							{filteredFields.map((field, fieldIndex) => {
								const item = table.items[0];

								if (!item) {
									return null;
								}

								return (
									<div
										key={fieldIndex}
										role="row"
										aria-rowindex={fieldIndex + 1}
										className={gridListCss.rows}
									>
										<div className={dataGridCss.detailTitle}>{field.title}</div>
										<div className={dataGridCss.detailCell}>
											{defaultOnCellRender(item, field, false)}
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default GridListComponent;
