import React from 'react';
import useToggle from '../../hooks/useToggle';
import ModalPopup from '../../controlls/ModalPopup/ModalPopup';
import Button from '../../pirsInputs/Button/Button';
import {usePirsInstanceDetailsQuery} from '../../../queries-generated/types';
import SmallLoader from '../../controlls/SmallLoader/SmallLoader';
import ErrorSnippet from '../../ErrorSnippet/ErrorSnippet';
import {CrossIcon, NestingIcon} from '../../SvgIcon';
import {Entity} from './useMetadata';
import EntitiesListOnlyValues from '../details/EntitiesListOnlyValues';

type Props = {
	type: string;
	id: string;
	attributePath: string;
};

const MoreButton: React.FC<Props> = ({id, type, attributePath}) => {
	const [open, toggle] = useToggle(false);
	const {data, loading, error, refetch} = usePirsInstanceDetailsQuery({
		variables: {
			id,
			type,
			attributePath,
		},
		skip: !open,
	});

	const {value, attribute} = (data?.pirsInstanceDetails || {}) as {value: any; attribute: Entity};

	return (
		<>
			<ModalPopup open={open} onClose={toggle}>
				{loading ? (
					<SmallLoader />
				) : error ? (
					<ErrorSnippet error={error} refetch={refetch} />
				) : (
					<>
						<EntitiesListOnlyValues entities={[attribute]} values={value} />
					</>
				)}
				<div className={'modalPopupButtons'}>
					<Button type="button" secondary onClick={toggle}>
						<CrossIcon />
						Закрыть
					</Button>
				</div>
			</ModalPopup>
			<Button secondary onClick={toggle}>
				<NestingIcon /> Посмотреть вложенные элементы
			</Button>
		</>
	);
};

export default MoreButton;
