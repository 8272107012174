import {ActionsUnion, createAction} from '../../../redux/helper';
import React, {useContext, useEffect, useMemo, useReducer} from 'react';
import {CommonFilterField, ViewItem} from './useViewItemTree';
import {
	defaultFormEmptyValues,
	getLinkedFiltersValues,
	resolveFiltersFromRouter,
} from '../../settings/views/components/helper';
import {useApolloClient} from '@apollo/client';
import {useRouter} from 'react-named-hooks-router';
import dequal from 'dequal';
import {filterObject, mapObject} from '../../../utils/object-utils';
import isWholeValue from '../../../utils/isWholeValue';
import {Field} from '../../../queries-generated/types';

type State = {
	open: boolean;
	openComponent?: string;
	filters: PlainObjectOf<PlainObjectOf<any>>; // {componentId: {filterId: 'val', filterId2: 'val'}}
	initFilters: PlainObjectOf<PlainObjectOf<any>>;
	// Флаг ожидания разрешения фильтров из урла
	waiting: boolean;
	id?: string;
};

const initialState: State = {
	open: false,
	filters: {},
	initFilters: {},
	waiting: false,
};

enum ActionType {
	SET_OPEN_COMPONENT = 'SET_OPEN_COMPONENT',
	SET_WAITING = 'SET_WAITING',
	CLOSE = 'CLOSE',
	SET_COMPONENT_FILTERS = 'SET_COMPONENT_FILTERS',
	SET_INIT_COMPONENT_FILTERS = 'SET_INIT_COMPONENT_FILTERS',
}

const actions = {
	setOpenComponent: (id: string) => createAction(ActionType.SET_OPEN_COMPONENT, id),
	setWaiting: (flag: boolean) => createAction(ActionType.SET_WAITING, flag),
	close: () => createAction(ActionType.CLOSE),
	setComponentFilters: (payload: {id: string; filters: PlainObjectOf<any>; waiting: boolean}) =>
		createAction(ActionType.SET_COMPONENT_FILTERS, payload),
	setInitComponentFilters: (payload: {id: string; filters: PlainObjectOf<any>}) =>
		createAction(ActionType.SET_INIT_COMPONENT_FILTERS, payload),
};

export function reducer(state: State, action: ActionsUnion<typeof actions>): State {
	switch (action.type) {
		case ActionType.SET_OPEN_COMPONENT:
			return {
				...state,
				open: true,
				openComponent: action.payload,
			};
		case ActionType.CLOSE:
			return {
				...state,
				open: false,
				openComponent: undefined,
			};
		case ActionType.SET_COMPONENT_FILTERS:
			return {
				...state,
				filters: {
					...state.filters,
					[action.payload.id]: action.payload.filters,
				},
				waiting: action.payload.waiting,
			};
		case ActionType.SET_INIT_COMPONENT_FILTERS:
			return {
				...state,
				initFilters: {...state.initFilters, [action.payload.id]: action.payload.filters},
			};
		case ActionType.SET_WAITING:
			return {
				...state,
				waiting: action.payload,
			};
		default:
			return state;
	}
}

type UserFiltersProviderProps = {
	commonFiltersFields: CommonFilterField[];
	filters?: PlainObjectOf<any>;
	children: React.ReactNode;
	id?: string;
};

type UserFiltersContextType = State &
	typeof actions & {
		matchInitFilters: (viewItem: ViewItem, fields: Field[]) => boolean;
		getViewItemInitFilters: (viewItem: ViewItem, fields: Field[]) => PlainObjectOf<any>;
	};

export const UserFiltersContext = React.createContext<UserFiltersContextType>(null as any);

export function UserFiltersProvider({children, filters, id, commonFiltersFields}: UserFiltersProviderProps) {
	const apolloClient = useApolloClient();
	const router = useRouter();

	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		waiting: true,
		id,
	});

	useEffect(() => {
		async function resolveFilters() {
			const routerFiltersValues = await resolveFiltersFromRouter(
				commonFiltersFields,
				router.params,
				apolloClient,
			);

			const commonFiltersValues = commonFiltersFields.reduce((commonFiltersValues, commonFilterField) => {
				const priorityValue =
					(filters && filters[commonFilterField.id]) ??
					routerFiltersValues[commonFilterField.id] ??
					commonFilterField.defaultValue;

				commonFiltersValues[commonFilterField.guid] = {
					...(commonFiltersValues[commonFilterField.guid] || {}),
					[commonFilterField.id]: priorityValue,
				};

				return commonFiltersValues;
			}, {});

			dispatch({
				type: ActionType.SET_INIT_COMPONENT_FILTERS,
				payload: {id: 'root', filters: commonFiltersValues},
			});

			dispatch({
				type: ActionType.SET_COMPONENT_FILTERS,
				payload: {
					id: 'root',
					filters: commonFiltersValues,
					waiting: false,
				},
			});
		}

		if (filters) {
			dispatch({
				type: ActionType.SET_INIT_COMPONENT_FILTERS,
				payload: {id: '__hardcoded__', filters},
			});
			dispatch({
				type: ActionType.SET_COMPONENT_FILTERS,
				payload: {id: '__hardcoded__', filters, waiting: false},
			});
		}

		resolveFilters();
	}, []);

	function getViewItemInitFilters(viewItem: ViewItem, fields: Field[]): PlainObjectOf<any> {
		/*if (viewItem.id === 'root') {
			return state.initFilters['root'];
		}*/

		const filtersFromFields = defaultFormEmptyValues(fields);
		const viewItemDataSourceFilters = viewItem.dataSource?.filters;
		const linkedFilters = getLinkedFiltersValues(state.filters['root'], viewItem);
		const hardcodedFilters = state.filters['__hardcoded__'];

		return {
			// defaultValue
			...filtersFromFields,
			// установленные администратором в редакторе
			...viewItemDataSourceFilters,
			// захардкоженные для View
			...hardcodedFilters,
			// общие фильтры, примененные к этому viewItem
			...linkedFilters,
		};
	}

	function matchInitFilters(viewItem: ViewItem, fields: Field[]) {
		if (viewItem.id === 'root') {
			const [filters, initFilters] = [state.filters['root'], state.initFilters['root']].map(filters =>
				filterObject(
					mapObject(filters, value => filterObject(value, isWholeValue)),
					isWholeValue,
				),
			);
			return dequal(filters, initFilters);
		}
		// Проверяем только когда пользователь сделал изменения в форме фильтров компонента
		if (state.filters[viewItem.id]) {
			const [filters, initFilters] = [
				state.filters[viewItem.id],
				getViewItemInitFilters(viewItem, fields),
			].map(filters => filterObject(filters, isWholeValue));

			return dequal(filters, initFilters);
		}
		return true;
	}

	const actionBounds = useMemo(() => {
		const actionBounds = {};
		Object.keys(actions).forEach(actionKey => {
			const action = actions[actionKey];
			actionBounds[actionKey] = payload => {
				dispatch(action(payload));
			};
		});
		return actionBounds;
	}, [dispatch]);

	const value = {
		...state,
		...actionBounds,
		matchInitFilters,
		getViewItemInitFilters,
	};

	return <UserFiltersContext.Provider value={value as any}>{children}</UserFiltersContext.Provider>;
}

function useUserFilters() {
	return useContext(UserFiltersContext);
}

export function useComponentFilters(viewItem: ViewItem) {
	const userFilters = useUserFilters();

	const viewItemFilters = userFilters && viewItem.id !== 'root' && userFilters.filters[viewItem.id];
	const filters = viewItem.dataSource?.filters || {};

	return {
		waiting: userFilters ? userFilters.waiting : false,
		filters: userFilters ? {...userFilters.getViewItemInitFilters(viewItem, []), ...viewItemFilters} : filters,
	};
}

export default useUserFilters;
