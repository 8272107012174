import React from 'react';
import {useField, useFormikContext} from 'formik';
import {default as OriginalInput, InputProps} from './DashboardInputFiles';
import {FileValue} from '../../../queries-generated/types';

const DashboardInputFiles: React.FC<Omit<InputProps, 'onChange'> & {
	name: string;
	onCustomChange?: (value: FileValue[]) => void;
}> = props => {
	// @ts-ignore
	const [field, meta] = useField(props);
	const {setFieldValue} = useFormikContext();
	const {onCustomChange, ...fieldProps} = props;
	return (
		<OriginalInput
			{...field}
			{...(fieldProps as any)}
			error={meta.touched && meta.error}
			onChange={value => {
				setFieldValue(field.name as never, value);
				if (onCustomChange) onCustomChange(value);
			}}
		/>
	);
};

export default DashboardInputFiles;
