import css from './NavigatePopup.module.css';
import Button from '../../pirsInputs/Button/Button';
import ModalPopup from '../ModalPopup/ModalPopup';
import * as React from 'react';
import {useEffect, useRef} from 'react';

type Props = {
	navigateCallback: (() => void) | undefined;
	handleCloseModalPopup: () => void;
	handleCloseModalPopupAndNavigate: () => void;
	text?: string;
};

const NavigatePopup: React.FC<Props> = ({
	navigateCallback,
	handleCloseModalPopup,
	handleCloseModalPopupAndNavigate,
	text,
}) => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	useEffect(() => {
		if (buttonRef.current) {
			buttonRef.current.focus();
		}
	}, [navigateCallback]);

	return (
		<ModalPopup maxWidth={400} open={!!navigateCallback} onClose={handleCloseModalPopup}>
			<div className={css.message}>
				{text ? text : <>Вы действительно хотите уйти со страницы? Все ваши текущие действия удалятся</>}
			</div>
			<ul className={css.buttons}>
				<li>
					<Button onClick={handleCloseModalPopupAndNavigate}>Перейти</Button>
				</li>
				<li>
					<Button setRef={buttonRef} onClick={handleCloseModalPopup} secondary>
						Остаться
					</Button>
				</li>
			</ul>
		</ModalPopup>
	);
};

export default NavigatePopup;
