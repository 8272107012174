import React from 'react';
import css from './DashboardFields.module.css';
import InputShimmer from '../../../../../pirsInputs/InputShimmer';
import ButtonShimmer from '../../../../../pirsInputs/ButtonShimmer';

type Props = {
	count: number;
	buttons?: boolean;
};

const FormShimmer: React.FC<Props> = ({count, buttons}) => {
	return (
		<>
			<ul className={css.form}>
				{new Array(count).fill(undefined).map((_x, index) => (
					<li key={index}>
						<InputShimmer />
					</li>
				))}
			</ul>
			{buttons && (
				<div className={css.buttons}>
					<ButtonShimmer />
				</div>
			)}
		</>
	);
};

export default FormShimmer;
