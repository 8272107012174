import Button, {ButtonProps} from '../pirsInputs/Button/Button';
import React from 'react';
import {useRouter} from 'react-named-hooks-router';

const DataGridLink: React.FC<ButtonProps> = props => {
	const {route: currentRoute, params: currentParams} = useRouter();

	if (props.route || props.params) {
		const buttonRoute = props.route || currentRoute;
		const buttonRouteParams = props.route ? props.params : {...currentParams, ...props.params};

		return (
			<Button {...props} route={buttonRoute} params={buttonRouteParams}>
				{props.children}
			</Button>
		);
	}

	return <Button {...props}>{props.children}</Button>;
};

export default DataGridLink;
