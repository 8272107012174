import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AnimationOptions, Edge, Network, Node} from 'vis-network/standalone';
import css from './Graph.module.css';
import './vis-network.notmodule.css';
import {graphOptions} from './graphOptions';
import useDeepCompareEffect from '../../../../hooks/useDeepCompareEffect';
import ProgressRing from '../../../../controlls/Loader/ProgressRing';
import {Graph} from '../../../../../queries-generated/types';
import usePrevious from '../../../../hooks/usePrevious';
import {arrayDiff} from '../../../../../utils/array-utils';
import DatesRadioSlider, {Value} from '../../../../controlls/DatesRadioSlider/DatesRadioSlider';
import Popover, {Coords} from '../../../../controlls/PopoverButton/Popover';
import {highchartColors} from '../ChartsBase/highchartColors';
import PopoverContent from './PopoverContent';
import CheckboxArray from '../../../../pirsInputs/Checkbox/CheckboxArray';
import Checkbox from 'components/pirsInputs/Checkbox/Checkbox';
import {
	directions,
	edgeAppendedProps,
	edgeDeletedProps,
	edgeNormalProps,
	getAddedEdgesIdsByHistory,
	getCurrentLayerEdgesIds,
	getDateFromValue,
	getEdgeLayers,
	getEdgeRangeByDate,
	getEdgesByNodeAndLayers,
	getNodeRangeByDate,
	getRemovedEdgesIdsByHistory,
	getRemovedEdgesIdsByLayers,
	getRemovedNodesIdsByHistory,
	getRemovedNodesIdsByLayers,
	nodeNormalProps,
	nodeRemovedProps,
	placeNodes,
	resetGraphOptions,
	toVisEdge,
	toVisNode,
} from './graphHelper';
import {mergeDeep} from '../../../../../utils/object-utils';
import {
	DirectionIcon,
	FitIcon,
	LayerIcon,
	PauseIcon,
	PlayIcon,
	TimeLineIcon,
	ZoomInIcon,
	ZoomOutIcon,
} from '../../../../SvgIcon';
import useToggle from '../../../../hooks/useToggle';
import Button from '../../../../pirsInputs/Button/Button';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import {CommonButtons, renderCommonButtons} from '../../../../view/useComponentCommonButtons';
import viewComponentCss from '../../../../view/ViewComponent.module.css';
import cls from '../../../../../utils/cls';
import PopoverButton from '../../../../controlls/PopoverButton/PopoverButton';
import List from '../../../../List/List';
const directionsItems = {
	topdown: 'Сверху-вниз',
	upwards: 'Снизу-вверх',
	rightleft: 'Справа-налево',
	leftright: 'Слева-направо',
	star: 'Звезда',
};

export type Props = {
	viewItemId: string;
	data: Graph;
	direction: 'topdown' | 'upwards' | 'rightleft' | 'leftright' | 'star';
	showDirectionControl?: boolean;
	hideDeleted: boolean;
	hasHistory: boolean;
	hasHistoryInitialMoment: boolean;
	hasHistoryPresentMoment: boolean;
	historyDates: string[];
	firstProgressionItem?: number;
	progressionStep?: number;
	onChangeHistoryDate?: (date: string) => void;
	showReplay: boolean;
	replayIntervalInSeconds: number;
	onReady?(graph: Network);
	onChangeReplay?(active: boolean);
	height?: number | string;
	commonButtons?: CommonButtons;
};

const SCALE_STEP = 0.1;
const SCALE_ANIMATION: AnimationOptions = {duration: 200, easingFunction: 'easeInCubic'};

const GraphBase: React.FC<Props> = ({
	viewItemId,
	data,
	direction: directionProps,
	hideDeleted,
	hasHistory,
	hasHistoryInitialMoment,
	hasHistoryPresentMoment,
	historyDates,
	firstProgressionItem,
	progressionStep,
	onChangeHistoryDate,
	showDirectionControl,
	showReplay,
	replayIntervalInSeconds,
	onReady,
	onChangeReplay,
	height,
	commonButtons,
}) => {
	const {setProp} = useComponentsStoredData(viewItemId);
	const [loading, setLoading] = useState(true);
	const graphRef = useRef<Network>();
	const readyHandler = useCallback(() => {
		setLoading(false);
		if (onReady) onReady(graphRef.current!);
	}, [onReady]);
	const element = useRef<HTMLDivElement>(null);

	const [showTimeLine, toggleTimeLine] = useToggle(false);

	const layersColors = useMemo(() => {
		const layers = {};
		data.options?.layers &&
			data.options.layers.forEach((layer, index) => {
				layers[layer] = highchartColors[index];
			});
		return layers;
	}, [data.options?.layers]);

	const [layers, setLayers] = useState<string[]>(data.options.layers || []);
	const [showNoLayersNode, setShowNoLayersNode] = useState(true);
	const [direction, setDirection] = useState(directionProps as string);
	const handleChangeDirection = useCallback(value => {
		setDirection(value || 'star');
		setProp('direction', value || 'star');
	}, []);

	const allLayersChecked = useMemo(() => layers.length === (data.options.layers || []).length && showNoLayersNode, [
		layers.length,
		data.options,
		showNoLayersNode,
	]);
	const allLayersIndeterminate = useMemo(() => (layers.length > 0 || showNoLayersNode) && !allLayersChecked, [
		allLayersChecked,
		showNoLayersNode,
		layers.length,
	]);
	const handleChangeAllLayers = useCallback(() => {
		if (!allLayersChecked) {
			setLayers(data.options.layers || []);
			setShowNoLayersNode(true);
			setProp('showNoLayersNode', true);
			setProp('layers', data.options.layers || []);
		} else {
			setLayers([]);
			setShowNoLayersNode(false);
			setProp('showNoLayersNode', false);
			setProp('layers', []);
		}
	}, [allLayersChecked, data.options]);

	const handleChangeLayers = useCallback(layers => {
		setLayers(layers);
		setProp('layers', layers);
	}, []);

	const handleChangeShowNoLayersNode = useCallback(showNoLayersNode => {
		setShowNoLayersNode(showNoLayersNode);
		setProp('showNoLayersNode', showNoLayersNode);
	}, []);

	const handleFit = useCallback(() => {
		if (graphRef.current) {
			graphRef.current?.fit();
		}
	}, []);

	const handleZoomIn = useCallback(() => {
		if (graphRef.current) {
			graphRef.current?.moveTo({scale: graphRef.current?.getScale() + SCALE_STEP, animation: SCALE_ANIMATION});
		}
	}, []);

	const handleZoomOut = useCallback(() => {
		if (graphRef.current) {
			graphRef.current?.moveTo({scale: graphRef.current?.getScale() - SCALE_STEP, animation: SCALE_ANIMATION});
		}
	}, []);

	const [popoverContent, setPopoverContent] = useState<{
		label: string;
		content?: React.ReactNode;
		coords: Coords;
	}>();

	const handleUnselect = useCallback(() => {
		setPopoverContent(undefined);
	}, []);

	const [historyDateIso, setHistoryDateIso] = useState<Value | undefined>(data.options.initDate);
	const prevHistoryDateIso = usePrevious(historyDateIso);
	const handleChangeHistoryDate = useCallback(
		(date: Value) => {
			setHistoryDateIso(date);
			if (onChangeHistoryDate) onChangeHistoryDate(date);
			setProp('historyDate', date);
			setProp('prevHistoryDate', historyDateIso);
		},
		[onChangeHistoryDate, historyDateIso],
	);

	const [replayInProgress, toggleReplayInProgress] = useToggle(false);

	const handleChangeHistoryDateFromControl = useCallback(
		(data: Value) => {
			if (!replayInProgress) handleChangeHistoryDate(data);
		},
		[handleChangeHistoryDate, replayInProgress],
	);

	const nextReplayDate = useMemo(() => {
		if (!historyDates || !historyDateIso) return;
		const currentIndex = historyDates.indexOf(historyDateIso);
		if (currentIndex !== -1 && currentIndex !== historyDates.length - 1) {
			return historyDates[currentIndex + 1];
		} else if (historyDateIso === 'initial' && historyDates.length > 0) {
			return historyDates[0];
		} else if (hasHistoryPresentMoment && (currentIndex !== -1 || historyDateIso === 'initial')) {
			return 'present';
		} else {
			return null;
		}
	}, [historyDateIso, historyDates, hasHistoryPresentMoment]);

	const handleNextReplayDate = useCallback(() => {
		if (nextReplayDate) {
			handleChangeHistoryDate(nextReplayDate);
		}
	}, [nextReplayDate]);

	const timeoutRef = useRef<any>(0);

	useEffect(() => {
		if (replayInProgress && prevHistoryDateIso !== historyDateIso) {
			if (nextReplayDate) {
				timeoutRef.current = setTimeout(handleNextReplayDate, replayIntervalInSeconds * 1000);
			} else {
				toggleReplayInProgress();
				if (onChangeReplay) onChangeReplay(!replayInProgress);
			}
		}
	}, [
		nextReplayDate,
		replayInProgress,
		replayIntervalInSeconds,
		handleNextReplayDate,
		historyDateIso,
		prevHistoryDateIso,
		onChangeReplay,
	]);

	const handleToggleReplay = useCallback(() => {
		if (nextReplayDate) {
			toggleReplayInProgress();
			if (onChangeReplay) onChangeReplay(!replayInProgress);
			if (!replayInProgress) {
				timeoutRef.current = setTimeout(handleNextReplayDate, replayIntervalInSeconds * 1000);
			} else {
				clearTimeout(timeoutRef.current);
			}
		}
	}, [replayIntervalInSeconds, handleNextReplayDate, nextReplayDate, replayInProgress, onChangeReplay]);

	const edgesWithCurrentOptionsById = useMemo(() => {
		const historyDate = historyDateIso ? getDateFromValue(historyDateIso) : null;
		const edges: PlainObjectOf<Edge> = {};
		data.edges.forEach(edge => {
			const range = historyDate && getEdgeRangeByDate(edge, historyDate);
			edges[edge.id] = toVisEdge(
				{...edge, label: range?.label || edge.label, options: {...edge.options, ...range?.options}},
				data.options,
			);
		});
		return edges;
	}, [data, historyDateIso]);

	const nodesWithCurrentOptionsById = useMemo(() => {
		const historyDate = historyDateIso ? getDateFromValue(historyDateIso) : null;
		const nodes: PlainObjectOf<Node> = {};
		data.nodes.forEach(node => {
			const range = historyDate && getNodeRangeByDate(node, historyDate);
			nodes[node.id] = toVisNode(
				{...node, label: range?.label || node.label, options: {...node.options, ...range?.options}},
				data.options,
				layersColors,
			);
		});
		return nodes;
	}, [data, historyDateIso, layersColors]);

	useDeepCompareEffect(() => {
		if (!element || !element.current) return;

		const nodes = Object.values(nodesWithCurrentOptionsById);
		const edges = Object.values(edgesWithCurrentOptionsById);

		if (direction === 'star') {
			graphRef.current = new Network(element.current, {edges, nodes}, graphOptions);
		} else {
			graphRef.current = new Network(
				element.current,
				{
					edges,
					nodes,
				},
				{
					...graphOptions,
					physics: {
						...graphOptions.physics,
						hierarchicalRepulsion: {
							nodeDistance: 250,
							springLength: 800,
							avoidOverlap: 1,
						},
						solver: 'hierarchicalRepulsion',
					},
					nodes: {
						...graphOptions.nodes,
						widthConstraint: ['leftright', 'rightleft'].includes(direction)
							? false
							: {minimum: 30, maximum: 70},
					},
					layout: {
						...graphOptions.layout,
						hierarchical: {
							enabled: true,
							direction: directions[direction],
							treeSpacing: 250,
							levelSeparation: ['leftright', 'rightleft'].includes(direction) ? 200 : 150,
						},
					},
				},
			);
		}

		const graph = graphRef.current;

		if (edges.length === 0 && nodes.length === 0) {
			readyHandler();
		} else if (data.options.hasImportant && direction === 'star') {
			(graph as any).body.data.nodes.update(
				placeNodes(data.nodes, firstProgressionItem || 1, progressionStep || 5),
			);
			graph?.fit();
			readyHandler();
		} else {
			graph.stabilize(250);
			graph.on('stabilizationIterationsDone', readyHandler);
		}

		return () => {
			graph.setOptions(resetGraphOptions);
			graph.off('stabilizationIterationsDone', readyHandler);
			graph.destroy();
			graphRef.current = undefined;
		};
	}, [data, direction, readyHandler]);

	useDeepCompareEffect(() => {
		const graph = graphRef.current;
		if (!graph) return;
		const historyDate = historyDateIso ? getDateFromValue(historyDateIso) : null;
		graph.on('selectNode', params => {
			if (params.nodes[0] && element.current) {
				let node = data.nodes.find(node => node.id === params.nodes[0])!;
				if (node) {
					const range = historyDate && getNodeRangeByDate(node, historyDate);
					node = {...node, options: {...node.options, ...range?.options}};
					if (node.options?.popoverContent) {
						const boundary = element.current.getBoundingClientRect();
						const coords: Coords = {
							left: params.pointer.DOM.x + boundary.left,
							top: params.pointer.DOM.y + boundary.top,
							height: 30,
						};
						setPopoverContent({
							label: node.label || '',
							content: (
								<PopoverContent
									popoverContent={node.options.popoverContent}
									nodeFromId={node.id}
									nodeToId={node.id}
									currentHistoryDate={historyDateIso}
								/>
							),
							coords,
						});
					}
				}
			}
		});

		graph.on('deselectNode', handleUnselect);

		graph.on('selectEdge', params => {
			if (params.edges[0] && element.current) {
				let edge = data.edges.find(edge => edge.id === params.edges[0])!;
				if (edge) {
					const range = historyDate && getEdgeRangeByDate(edge, historyDate);
					edge = {...edge, options: {...edge.options, ...range?.options}};
					const connectedNodesLabel = data.nodes
						.filter(node => node.id === edge.from || node.id === edge.to)
						.map(node => node.label)
						.join(' - ');

					const boundary = element.current.getBoundingClientRect();
					const coords: Coords = {
						left: params.pointer.DOM.x + boundary.left,
						top: params.pointer.DOM.y + boundary.top,
						height: 30,
					};

					const layers = getEdgeLayers(edge, historyDate);
					const layersContent = layers.length > 0 && (
						<>
							<div className={css.layersTitle}>
								<b>Слои:</b>
							</div>
							{layers.map(layer => {
								return (
									<div key={layer} className={css.popoverLayer}>
										<div
											style={{background: layersColors[layer]}}
											className={css.popoverLayerColor}
										/>
										{layer}
									</div>
								);
							})}
						</>
					);
					setPopoverContent({
						label: connectedNodesLabel,
						content: (
							<>
								{layersContent}
								<PopoverContent
									popoverContent={edge.options?.popoverContent}
									nodeToId={edge.to}
									nodeFromId={edge.from}
									currentHistoryDate={historyDateIso}
								/>
							</>
						),
						coords,
					});
				}
			}
		});

		graph.on('deselectEdge', handleUnselect);

		if (data.options?.layers) {
			graph.on('hoverEdge', params => {
				const edgeId = params.edge;

				const edge = data.edges.find(edge => edge.id === edgeId);
				const thickness = edge?.options?.thickness || 100;
				(graph as any).body.data.edges.update([{id: edgeId, width: thickness / 10}]);
			});

			graph.on('blurEdge', params => {
				const edgeId = params.edge;

				const edge = data.edges.find(edge => edge.id === edgeId);
				const thickness = edge?.options?.thickness || 100;
				(graph as any).body.data.edges.update([{id: edgeId, width: thickness / 100}]);
			});

			graph.on('hoverNode', params => {
				(graph as any).body.data.edges.update(
					getEdgesByNodeAndLayers(params.node, layers, data.nodes, data.edges).map(edge => ({
						id: edge.id,
						width: (edge.options?.thickness || 100) / 10,
					})),
				);
			});

			graph.on('blurNode', params => {
				(graph as any).body.data.edges.update(
					getEdgesByNodeAndLayers(params.node, layers, data.nodes, data.edges).map(edge => ({
						id: edge.id,
						width: (edge.options?.thickness || 100) / 100,
					})),
				);
			});
		}

		return () => {
			graph.off('selectNode');
			graph.off('deselectNode');
			graph.off('selectEdge');
			graph.off('deselectEdge');
			graph.off('hoverEdge');
			graph.off('blurEdge');
			graph.off('hoverNode');
			graph.off('blurNode');
		};
	}, [historyDateIso, data, direction]);

	const prevHideDeleted = usePrevious(hideDeleted);
	const prevDirection = usePrevious(direction);
	const prevLayers = usePrevious(layers);
	const prevShowNoLayersNode = usePrevious(showNoLayersNode);

	useEffect(() => {
		const graph = graphRef.current;
		if (!graph) return;

		const noChangeHistory =
			!historyDateIso ||
			(prevHistoryDateIso !== historyDateIso && prevHideDeleted !== hideDeleted && direction === prevDirection);

		const noChangeLayers =
			!data.options?.layers ||
			(prevLayers?.length === layers.length && prevShowNoLayersNode === showNoLayersNode);

		if (noChangeHistory && noChangeLayers) return;

		let removedEdgesIdsByHistory: string[] = [];
		let addedEdgesIdsByHistory: string[] = [];
		let removedNodesIdsByHistory: string[] = [];

		const nodesIds = Object.keys(nodesWithCurrentOptionsById);
		const edgesIds = Object.keys(edgesWithCurrentOptionsById);

		const historyDate = historyDateIso ? getDateFromValue(historyDateIso) : null;

		if (historyDate) {
			const prevHistoryDate = prevHistoryDateIso ? getDateFromValue(prevHistoryDateIso) : null;

			removedEdgesIdsByHistory = getRemovedEdgesIdsByHistory(data.edges, historyDate);
			addedEdgesIdsByHistory = prevHistoryDate
				? getAddedEdgesIdsByHistory(data.edges, historyDate, prevHistoryDate)
				: [];
			removedNodesIdsByHistory = getRemovedNodesIdsByHistory(data.nodes, graph, removedEdgesIdsByHistory);
		}

		const nodesToDelete = [
			...getRemovedNodesIdsByLayers(data.nodes, layers, showNoLayersNode),
			...(hideDeleted ? removedNodesIdsByHistory : []),
		];
		const nodesToHide = hideDeleted ? [] : removedNodesIdsByHistory;
		const nodesToNormal = arrayDiff(nodesIds, [...nodesToHide, ...nodesToDelete]);

		const edgesToDelete = [
			...getRemovedEdgesIdsByLayers(data.edges, layers, showNoLayersNode, historyDate),
			...(hideDeleted ? removedEdgesIdsByHistory : []),
		];
		const edgesToHide = hideDeleted ? [] : removedEdgesIdsByHistory;
		const edgesToAdd = arrayDiff(addedEdgesIdsByHistory, edgesToDelete);
		const edgesToCurrentColor =
			layers.length === 1
				? arrayDiff(getCurrentLayerEdgesIds(data.edges, layers[0], historyDate), [
						...edgesToDelete,
						...edgesToHide,
						...edgesToAdd,
				  ])
				: [];
		const edgesToNormal = arrayDiff(edgesIds, [
			...edgesToDelete,
			...edgesToHide,
			...edgesToAdd,
			...edgesToCurrentColor,
		]);

		(graph as any).body.data.edges.update([
			...edgesToCurrentColor.map(id => ({
				id,
				...edgesWithCurrentOptionsById[id],
				hidden: false,
				color: layersColors[layers[0]],
			})),
			...edgesToDelete.map(id => ({...edgesWithCurrentOptionsById[id], hidden: true})),
			...edgesToAdd.map(id => ({...mergeDeep(edgesWithCurrentOptionsById[id], edgeAppendedProps)})),
			...edgesToHide.map(id => ({...mergeDeep(edgesWithCurrentOptionsById[id], edgeDeletedProps)})),
			...edgesToNormal.map(id => ({...mergeDeep(edgesWithCurrentOptionsById[id], edgeNormalProps)})),
		]);

		(graph as any).body.data.nodes.update([
			...nodesToDelete.map(id => ({...nodesWithCurrentOptionsById[id], hidden: true})),
			...nodesToHide.map(id => ({...mergeDeep(nodesWithCurrentOptionsById[id], nodeRemovedProps)})),
			...nodesToNormal.map(id => ({...mergeDeep(nodesWithCurrentOptionsById[id], nodeNormalProps)})),
		]);
	}, [
		edgesWithCurrentOptionsById,
		nodesWithCurrentOptionsById,
		data.edges,
		hideDeleted,
		historyDateIso,
		prevHideDeleted,
		prevHistoryDateIso,
		direction,
		prevDirection,
		layers,
		prevLayers,
		showNoLayersNode,
		prevShowNoLayersNode,
	]);

	return (
		<div className={css.wrapper} style={{height}}>
			<div className={cls(viewComponentCss.filters, css.filters)}>
				<Button iconOnly action onClick={handleFit}>
					<FitIcon />
				</Button>
				<Button iconOnly action onClick={handleZoomOut}>
					<ZoomOutIcon />
				</Button>
				<Button iconOnly action onClick={handleZoomIn}>
					<ZoomInIcon />
				</Button>
				{showDirectionControl && (
					<PopoverButton
						action
						hasChevron
						popover={
							<List
								items={Object.keys(directionsItems).map(item => ({
									title: directionsItems[item],
									onClick: () => handleChangeDirection(item),
								}))}
								selectMode
							/>
						}
					>
						<DirectionIcon /> {directionsItems[direction]}
					</PopoverButton>
				)}
				{data.options?.layers && (
					<PopoverButton
						message
						popover={
							<div className={css.layers}>
								<div>{data.options?.layersCardTitle || 'Слои'}</div>
								<div>
									<Checkbox
										onChange={handleChangeAllLayers}
										indeterminate={allLayersIndeterminate}
										checked={allLayersChecked}
										label={'Все'}
									/>
								</div>
								{data.options.layers.map(layer => {
									return (
										<div key={layer}>
											<CheckboxArray
												arrayValue={layer}
												onChange={handleChangeLayers}
												value={layers}
												label={<span style={{color: layersColors[layer]}}>{layer}</span>}
											/>
										</div>
									);
								})}
								<div>
									<Checkbox
										onChange={handleChangeShowNoLayersNode}
										checked={showNoLayersNode}
										label="Не указано"
									/>
								</div>
							</div>
						}
						hasChevron
						action
					>
						<LayerIcon />
					</PopoverButton>
				)}
				{hasHistory && (
					<Button action iconOnly onClick={toggleTimeLine} checked={showTimeLine}>
						<TimeLineIcon />
					</Button>
				)}
				{renderCommonButtons(commonButtons)}
			</div>
			{loading && <ProgressRing />}
			{hasHistory && showTimeLine && (
				<div className={css.cards}>
					<div className={css.dateLabelRange}>
						{showReplay && (
							<Button
								type={'button'}
								onClick={handleToggleReplay}
								iconOnly
								disabled={!nextReplayDate}
								className={css.replayButton}
							>
								{replayInProgress ? <PauseIcon /> : <PlayIcon />}
							</Button>
						)}
						<DatesRadioSlider
							values={historyDates}
							value={historyDateIso}
							onChange={handleChangeHistoryDateFromControl}
							hasInitialMoment={hasHistoryInitialMoment}
							hasPresentMoment={hasHistoryPresentMoment}
						/>
					</div>
				</div>
			)}

			<div ref={element} className={css.graph} />
			<Popover
				className={css.popover}
				open={!!popoverContent}
				coords={popoverContent?.coords}
				focusOnOpen
				onClose={handleUnselect}
			>
				{popoverContent && popoverContent.label && <h4 className={css.popoverLabel}>{popoverContent.label}</h4>}
				{popoverContent?.content}
			</Popover>
		</div>
	);
};

export default GraphBase;
