import React, {Dispatch, ReducerAction, ReducerState, useMemo, useReducer} from 'react';
import {actions, initialState, reducer} from './reducer';

export type CommonContextType = {
	state: ReducerState<typeof reducer>;
	dispatch: Dispatch<ReducerAction<typeof reducer>>;
} & typeof actions;
export const CommonContext = React.createContext<CommonContextType>(null as any);

function CommonProvider({children}) {
	const redux = useReducer(reducer, initialState);
	const [state, dispatch] = redux;

	const actionBounds = useMemo(() => {
		const actionBounds = {};
		Object.keys(actions).forEach(actionKey => {
			const action = actions[actionKey];
			actionBounds[actionKey] = payload => dispatch(action(payload));
		});
		return actionBounds;
	}, [dispatch]);

	const value = {
		state,
		dispatch,
		...actionBounds,
	};

	return <CommonContext.Provider value={value as any}>{children}</CommonContext.Provider>;
}

export default CommonProvider;
