import React from 'react';
import {Field} from '../../../../../../queries-generated/types';
import DashboardField from './DashboardField';
import {ChevronRightIcon} from '../../../../../SvgIcon';
import css from './DashboardFields.module.css';
import useToggle from '../../../../../hooks/useToggle';
import cls from '../../../../../../utils/cls';
import {FieldsProps} from '../../../components/helper';

type Props = {
	fields: Array<Field & {name: string}>;
	groupTitle: string;
	userFilters?: boolean;
	linkedFilters?: boolean;
	fieldsProps?: FieldsProps;
};

const DashboardFieldsGroup: React.FC<Props> = ({fields, groupTitle, userFilters, linkedFilters, fieldsProps}) => {
	const [open, toggleOpen] = useToggle(false);
	return (
		<div className={css.groupWrapper}>
			<div className={cls(css.groupTitle, open && css.open)} onClick={toggleOpen}>
				<ChevronRightIcon /> {groupTitle}
			</div>
			{open && (
				<div className={css.group}>
					{fields.map(field => (
						<DashboardField
							key={field.id}
							field={field}
							linkedFilters={linkedFilters}
							userFilters={userFilters}
							fieldProps={fieldsProps && fieldsProps[field.id]}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default DashboardFieldsGroup;
