import React, {useCallback, useMemo, useState} from 'react';
import css from './RecipientBindPage.module.css';
import MainLayout from '../../components/layouts/MainLayout';
import Selector from '../../components/pirsInputs/Selector/Selector';
import RecipientBindingTypeTable from './RecipientBindingTypeTable';
import {RouterConfigurationRoutes} from 'pages/RouterConfigurationPage/RouterConfigurationPage';
import RecipientBindingUserTable from './RecipientBindingUserTable';
import RecipientBindingOrgTable from './RecipientBindingOrgTable';
import {useRouter} from 'react-named-hooks-router';
import {SortOrder} from '../../queries-generated/types';

enum Modules {
	RECIPIENT_TYPE = 'Тип получателя',
	EXTERNAL_USERS = 'Внешние пользователи',
	ORGANIZATIONS = 'Организации',
}

const items = Object.values(Modules);

const RecipientBindPage: React.FC = () => {
	const [moduleItem, setModuleItem] = useState<Modules>(Modules.RECIPIENT_TYPE);
	const {pushRoute, routeName} = useRouter<{
		page?: string;
		itemsPerPage?: string;
		sort?: SortOrder;
		sortBy?: string;
	}>();

	const handleResetRouterParams = useCallback(async () => {
		return new Promise<void>(resolve => {
			pushRoute(routeName, {page: undefined, itemsPerPage: undefined, sort: undefined, sortBy: undefined});
			resolve();
		});
	}, [pushRoute, routeName]);

	const handleChangeModule = useCallback(
		item => {
			handleResetRouterParams().then(() => {
				setModuleItem(item);
			});
		},
		[moduleItem, setModuleItem, handleResetRouterParams],
	);

	const currentTable = useMemo(() => {
		if (moduleItem === Modules.RECIPIENT_TYPE) return <RecipientBindingTypeTable />;
		if (moduleItem === Modules.EXTERNAL_USERS) return <RecipientBindingUserTable />;
		if (moduleItem === Modules.ORGANIZATIONS) return <RecipientBindingOrgTable />;
	}, [moduleItem]);

	return (
		<MainLayout routes={RouterConfigurationRoutes}>
			<div className={css.selectorBlock}>
				<Selector
					className={css.selector}
					items={items}
					onChange={handleChangeModule}
					value={moduleItem}
					itemToString={item => item || ''}
				/>
				<h3 className={css.helpText}>
					Выберите для просмотра настроенных привязок типов уведомлений к получателям или их группам
				</h3>
			</div>
			{currentTable}
		</MainLayout>
	);
};

export default RecipientBindPage;
