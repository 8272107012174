import React, {ReactNode, useRef} from 'react';
import {useBoolean} from '../../hooks/useBoolean';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import cls from '../../../utils/cls';
import css from '../PopoverButton/PopoverButton.module.css';
import Button from '../../pirsInputs/Button/Button';
import RightPanel from '../../RightPanel/RightPanel';

type Props = {
	panelContent: ReactNode;
	panelHeader?: string;
	disabled?: boolean;
	className?: string;
	secondary?: boolean;
	action?: boolean;
	link?: boolean;
	iconOnly?: boolean;
};

const RightPanelButton: React.FC<Props> = props => {
	const {children, link, action, iconOnly, secondary, className, disabled, panelHeader, panelContent} = props;

	const [open, {setFalse, toggle}] = useBoolean(false);

	const buttonRef = useRef<HTMLButtonElement>(null);
	const rightPanelRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(open && [rightPanelRef, buttonRef], setFalse);

	return (
		<>
			<Button
				onClick={toggle}
				setRef={buttonRef}
				className={cls(className, css.button)}
				aria-expanded={open ? 'true' : 'false'}
				secondary={secondary}
				link={link}
				action={action}
				iconOnly={iconOnly}
				checked={open}
				disabled={disabled}
			>
				{children}
			</Button>
			<RightPanel
				open={open}
				onClose={setFalse}
				header={panelHeader}
				forwardRef={rightPanelRef}
				returnFocusRef={buttonRef}
			>
				{panelContent}
			</RightPanel>
		</>
	);
};

export default RightPanelButton;
