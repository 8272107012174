import React, {useCallback} from 'react';
import Field from '../Field/Field';
import * as SvgIcon from '../../SvgIcon';
import useDropDown, {ComboboxInterfaceCommon} from '../Combobox/useDropDown';
import fieldCss from '../Field/Field.module.css';
import Button from '../Button/Button';

export type Props<T> = Omit<
	ComboboxInterfaceCommon<T, T> & Omit<React.HTMLAttributes<HTMLDivElement>, keyof ComboboxInterfaceCommon<T, T>>,
	'multiple' | 'itemsToHtml' | 'onChange' | 'value' | 'itemToString'
> & {
	onChange: (value: string | null, originalValue?: any) => void;
	value: string | null;
	itemToString: (value: T) => string;
};

/**
 * Выбор из списка
 * Список формируется на основе ввода
 */
function AutoComplete<T>(props: Props<T>) {
	const {
		label,
		itemToHtml,
		onChange,
		error,
		disabled,
		placeholder,
		defaultValue,
		itemToString,
		value,
		getItems,
		onToggle,
		helperText,
		inputClassName,
		noButtons,
		itemToPrimaryKey,
		onKeyDown,
		underlined,
		...divProps
	} = props;

	const handleChange = useCallback(
		(values: T, originalValue: any) => {
			onChange(itemToString(values), originalValue);
		},
		[onChange],
	);

	const handleChangeInput = useCallback(
		(value: string) => {
			onChange(value);
		},
		[onChange],
	);

	const {
		PopoverContent,
		setInputValue,
		fieldRef,
		close,
		onChangeInput,
		inputProps,
		inputValue,
		isOpen,
		id,
		items,
	} = useDropDown({
		...props,
		multiple: false,
		onChange: handleChange,
		onChangeInput: handleChangeInput,
		inputValue: value,
	} as any);

	const handleClearValue = useCallback(() => {
		props.onChange(null);
		setInputValue('');
		close();
	}, [props.onChange]);

	const topLabel = !!label && (!!props.placeholder || (isOpen && !!inputValue) || props.value != null);

	return (
		<Field
			commonProps={props}
			isOpen={isOpen}
			hasFocus={isOpen}
			topLabel={topLabel}
			bordered={!underlined}
			innerRef={fieldRef}
			inputId={id}
			{...divProps}
			buttons={
				<>
					{!!inputValue && !disabled && (
						<Button
							action
							iconOnly
							onClick={handleClearValue}
							className={fieldCss.actionButton}
							tabIndex={-1}
						>
							<SvgIcon.CrossIcon width={14} height={14} />
						</Button>
					)}
				</>
			}
		>
			<input {...inputProps} placeholder={placeholder} onChange={onChangeInput} value={value || inputValue} />

			{items && items.length > 0 && PopoverContent}
		</Field>
	);
}

export default AutoComplete;
