import ContentLoader from 'react-content-loader';
import * as React from 'react';

const DonutChartLoader = () => {
	return (
		<ContentLoader width={550} height={470}>
			<circle cx="85" cy="230" r="75" />
			<rect x="167" y="64" width="346" height="11" />
			<rect x="167" y="31" width="346" height="11" />
			<rect x="167" y="428" width="346" height="11" />
			<rect x="167" y="394" width="346" height="11" />
			<rect x="167" y="361" width="346" height="11" />
			<rect x="167" y="328" width="346" height="11" />
			<rect x="167" y="296" width="346" height="11" />
			<rect x="167" y="262" width="346" height="11" />
			<rect x="167" y="230" width="346" height="11" />
			<rect x="167" y="197" width="346" height="11" />
			<rect x="167" y="164" width="346" height="11" />
			<rect x="167" y="130" width="346" height="11" />
			<rect x="167" y="98" width="346" height="11" />
		</ContentLoader>
	);
};

export default DonutChartLoader;
