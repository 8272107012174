export function humanNumber(number: number | string, integer: boolean = false): string {
	if (typeof number === 'string') number = parseInt(number, 10);

	if (integer) {
		number = Math.round(number);
	}
	return number.toLocaleString('ru-RU');
}

/**
 *
 * @param bytes Размер в байтах
 * @param decimals Точность
 */
export function formatBytes(bytes: number | string, decimals: number = 2) {
	if (typeof bytes === 'string') bytes = parseInt(bytes, 10);
	const negative = bytes < 0;

	if (!bytes || bytes === 0) return '0 байт';
	const k = 1024;
	const dm = decimals <= 0 ? 0 : decimals || 2;
	const sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ', 'ЗБ', 'ЙБ'];
	const i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(k));
	return (negative ? '-' : '') + parseFloat((Math.abs(bytes) / Math.pow(k, i)).toFixed(dm)) + '\u00A0' + sizes[i];
}

/**
 *
 * @param bites Размер в битах
 * @param decimals Точность
 */
export function formatBites(bites: number | string, decimals: number = 2) {
	if (typeof bites === 'string') bites = parseInt(bites, 10);
	const negative = bites < 0;

	if (!bites || bites === 0) return '0 бит';
	const k = 1000;
	const dm = decimals <= 0 ? 0 : decimals || 2;
	const sizes = ['бит', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Эб', 'Зб', 'Йб'];
	const i = Math.floor(Math.log(Math.abs(bites)) / Math.log(k));
	return (negative ? '-' : '') + parseFloat((Math.abs(bites) / Math.pow(k, i)).toFixed(dm)) + '\u00A0' + sizes[i];
}

/**
 *
 * @param bites Размер в битах
 * @param decimals Точность
 */
export function formatBitesPerSeconds(bites: number | string, decimals: number = 2) {
	if (typeof bites === 'string') bites = parseInt(bites, 10);
	const negative = bites < 0;

	if (bites === 0) return '0 бит';
	const k = 1024;
	const dm = decimals <= 0 ? 0 : decimals || 2;
	const sizes = ['бит', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Эб', 'Зб', 'Йб'];
	const i = Math.floor(Math.log(Math.abs(bites)) / Math.log(k));
	return (
		(negative ? '-' : '') + parseFloat((Math.abs(bites) / Math.pow(k, i)).toFixed(dm)) + '\u00A0' + sizes[i] + '/c'
	);
}

/**
 *
 * @param num Число в штуках
 * @param decimals Точность
 */
export function abbreviateNumber(num: number, decimals: number = 2) {
	if (num === 0) return '0 байт';
	const k = 1000;
	const dm = decimals <= 0 ? 0 : decimals || 2;
	const sizes = ['', 'тыс', 'млн', 'млрд', 'трлн', 'квадрлн', 'квинтлн', 'сикстлн', 'септлн'];
	const i = Math.floor(Math.log(num) / Math.log(k));
	return parseFloat((num / Math.pow(k, i)).toFixed(dm)) + '\u00A0' + sizes[i];
}

/**
 *
 * @param num Число в штуках
 * @param decimals Точность
 * вывод в экспоненциальном представлении (степени десяти по 3)
 */
export function abbreviateNumberExp(num: number | string, decimals: number = 2) {
	if (typeof num === 'string') num = parseInt(num, 10);

	if (num === 0) return '0 байт';
	const k = 1000;
	const dm = decimals <= 0 ? 0 : decimals || 2;
	const sizes = [
		'',
		'*10<sup>3</sup>',
		'*10<sup>6</sup>',
		'*10<sup>9</sup>',
		'*10<sup>12</sup>',
		'*10<sup>15</sup>',
		'*10<sup>18</sup>',
		'*10<sup>21</sup>',
		'*10<sup>24</sup>',
	];
	const i = Math.floor(Math.log(num) / Math.log(k));
	return parseFloat((num / Math.pow(k, i)).toFixed(dm)) + '\u00A0' + sizes[i];
}

export function between(number: number, a: number, b: number, inclusive?: boolean): boolean {
	const min = Math.min(a, b);
	const max = Math.max(a, b);

	return inclusive ? number >= min && number <= max : number > min && number < max;
}

export function precision(number: number | null) {
	if (!number) return 0;
	const [, f] = number.toString().split('.');
	return f ? f.length : 0;
}

export function isMultiple(x: number, y: number) {
	return Math.round(x / y) / (1 / y) === x;
}

export const MAX_SAFE_INT32 = Math.pow(2, 31) - 1;

export function isMaxSafeINT32(num: unknown): boolean {
	return Number.isInteger(num) && Math.abs(num as number) <= MAX_SAFE_INT32;
}
