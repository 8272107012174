import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, PropTypes} from '@material-ui/core';
import css from './IncidentFile.module.css';
import {useUploadFiles} from '../commonRedux/UploadFileStore/UploadFileQueueProvider';
import {useCreateModelMutation, useDeleteModelMutation, useModelItemsQuery} from '../../queries-generated/types';
import {UploadFileData, UploadFileStatus} from '../commonRedux/UploadFileStore/UploadFileQueueReducer';
import ProgressRing from '../controlls/Loader/ProgressRing';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import usePrevious from '../hooks/usePrevious';

type Props = {
	pageId: string;
	setUploadedFiles?: (id: string) => void;
	setRemovedFiles?: (id: string) => void;
	setFiles?: (files: UploadFileData[]) => void;
	incidentId?: string;
	buttonTitle?: string;
	buttonColor?: PropTypes.Color;
};

const IncidentFile: React.FC<Props> = ({
	pageId,
	setUploadedFiles,
	setRemovedFiles,
	setFiles,
	incidentId,
	buttonTitle,
	buttonColor,
}) => {
	const {addFiles, files, removeFiles, clearFiles} = useUploadFiles(pageId);
	const [removedStoredFiles, setRemovedStoredFiles] = useState<string[]>([]);
	const {data, loading} = useModelItemsQuery({
		variables: {
			model: 'incidentsFile',
			variables: {filter: `(issue_id eq ${incidentId})`},
		},
		fetchPolicy: 'network-only',
		skip: !incidentId,
	});
	const [createModel] = useCreateModelMutation();
	const [removeModel] = useDeleteModelMutation();
	const handleRemoveFile = useCallback(
		(file: UploadFileData) => {
			removeModel({variables: {model: 'incidentsFile', ids: [file.url.replace(/^.*?\?id=(.*)$/, '$1')]}}).then(
				result => {
					if (setRemovedFiles) setRemovedFiles(result?.data?.deleteModel[0] || '');
				},
			);
			removeFiles([file.url]);
		},
		[removeFiles, removeModel, setRemovedFiles],
	);

	const handleRemoveStoredFile = useCallback(
		(id: string) => {
			setRemovedStoredFiles([...removedStoredFiles, id]);
			removeModel({variables: {model: 'incidentsFile', ids: [id]}});
		},
		[removeModel, removedStoredFiles],
	);

	const refFiles = useRef<UploadFileData[]>([]);
	refFiles.current = files;
	useEffect(
		() => () => {
			refFiles.current
				.filter(file => file.status !== UploadFileStatus.LOADED)
				.forEach(file => {
					handleRemoveFile(file);
				});
			clearFiles();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const prevFiles = usePrevious(files);
	useEffect(() => {
		if (setFiles) {
			const changed =
				!prevFiles ||
				prevFiles.length !== files.length ||
				files.some((file, index) => file.status !== prevFiles[index].status);
			if (changed) setFiles(refFiles.current);
		}
	}, [setFiles, files, prevFiles]);

	const handleChangeFile = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const files = event.target.files;
			if (files) {
				const file = files[0];

				if (!file) return;
				createModel({
					variables: {
						model: 'incidentsFile',
						items: [
							{
								size: file.size,
								chunks: Math.ceil(file.size / Number(process.env.REACT_APP_CHUNK_LENGTH)),
								name: file.name,
								issue_id: incidentId ? parseInt(incidentId, 10) : null,
							},
						],
					},
				}).then(result => {
					if (result.data?.createModel) {
						addFiles([
							{
								file,
								pageId,
								url: `/services/preparedIssueFilesUploadChunk?id=${result.data.createModel[0].id}`,
								uploadFileCallback: () => {
									if (setUploadedFiles) setUploadedFiles(result?.data?.createModel[0].id || '');
								},
							},
						]);
					}
				});
			}
			event.target.value = '';
		},
		[addFiles, createModel, incidentId, pageId, setUploadedFiles],
	);

	return (
		<div className={css.wrapper}>
			<div className={css.file}>
				<div>
					<Button
						variant={'contained'}
						color={buttonColor || 'primary'}
						className={css.button}
						component="label"
					>
						{buttonTitle || 'Файл'}
						<input type="file" style={{display: 'none'}} onChange={handleChangeFile} />
					</Button>
				</div>
			</div>
			<ul className={css.fileList}>
				{loading && <ProgressRing />}
				{data &&
					data.modelItems.items
						.filter(item => !removedStoredFiles.includes(item.id))
						.map(item => {
							return (
								<li key={item.id} className={css.file}>
									<IconButton size="small" onClick={() => handleRemoveStoredFile(item.id)}>
										<CancelIcon />
									</IconButton>
									<div>{item.name}</div>
								</li>
							);
						})}
				{!!files.length && <li>Подготовлено к загрузке:</li>}
				{files.map(file => {
					const info =
						file.status === UploadFileStatus.ERROR ? (
							<span className={css.error}>ошибка... </span>
						) : file.status === UploadFileStatus.QUEUE ? (
							<span className={css.wait}>ожидание... </span>
						) : (
							''
						);
					return (
						<li key={file.url} className={css.file}>
							<IconButton size="small" onClick={() => handleRemoveFile(file)}>
								<CancelIcon
									color={
										file.status === UploadFileStatus.ERROR
											? 'error'
											: file.status === UploadFileStatus.QUEUE
											? 'disabled'
											: 'action'
									}
								/>
							</IconButton>
							<div>
								{info}
								{file.file.name}
								{file.status === UploadFileStatus.LOADING && (
									<div className={css.progress}>
										<progress max={100} value={file.progressLoading} />
									</div>
								)}
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default IncidentFile;
