exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".ResourceAttachmentPage-module__header__wuxfv {\r\n\tborder-bottom: calc(var(--stroke-width) * 1px) solid var(--neutral-stroke-rest);\r\n\tmargin: calc(var(--design-unit) * 2px);\r\n}\r\n\r\n.ResourceAttachmentPage-module__headerInner__3wniT {\r\n\tmargin: calc(var(--design-unit) * 2px) auto;\r\n}\r\n\r\n.ResourceAttachmentPage-module__resourceNameBlock__lCO3n {\r\n\tdisplay: flex;\r\n\talign-items: baseline;\r\n}\r\n\r\n.ResourceAttachmentPage-module__mainLayout__mUFeq {\r\n\tdisplay: flex;\r\n\twidth: 100%;\r\n\theight: 100%;\r\n\toverflow-y: auto;\r\n\toverflow-x: hidden;\r\n}\r\n\r\n.ResourceAttachmentPage-module__resourceName__tq0uz {\r\n\tmargin-left: calc(var(--design-unit) * 2px);\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\ResourceAttachmentPage\\ResourceAttachmentPage.module.css"],"names":[],"mappings":"AAAA;CACC,+EAA+E;CAC/E,sCAAsC;AACvC;;AAEA;CACC,2CAA2C;AAC5C;;AAEA;CACC,aAAa;CACb,qBAAqB;AACtB;;AAEA;CACC,aAAa;CACb,WAAW;CACX,YAAY;CACZ,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,2CAA2C;AAC5C","file":"ResourceAttachmentPage.module.css","sourcesContent":[".header {\r\n\tborder-bottom: calc(var(--stroke-width) * 1px) solid var(--neutral-stroke-rest);\r\n\tmargin: calc(var(--design-unit) * 2px);\r\n}\r\n\r\n.headerInner {\r\n\tmargin: calc(var(--design-unit) * 2px) auto;\r\n}\r\n\r\n.resourceNameBlock {\r\n\tdisplay: flex;\r\n\talign-items: baseline;\r\n}\r\n\r\n.mainLayout {\r\n\tdisplay: flex;\r\n\twidth: 100%;\r\n\theight: 100%;\r\n\toverflow-y: auto;\r\n\toverflow-x: hidden;\r\n}\r\n\r\n.resourceName {\r\n\tmargin-left: calc(var(--design-unit) * 2px);\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"header": "ResourceAttachmentPage-module__header__wuxfv",
	"headerInner": "ResourceAttachmentPage-module__headerInner__3wniT",
	"resourceNameBlock": "ResourceAttachmentPage-module__resourceNameBlock__lCO3n",
	"mainLayout": "ResourceAttachmentPage-module__mainLayout__mUFeq",
	"resourceName": "ResourceAttachmentPage-module__resourceName__tq0uz"
};