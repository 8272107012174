import React from 'react';
import css from '../form/Entity.module.css';
import cls from '../../../utils/cls';
import {Entity} from '../form/useMetadata';
import EntitiesListOnlyValues from './EntitiesListOnlyValues';
import EntitiesCollectionOnlyValues from './EntitiesCollectionOnlyValues';
import EntityValue from './EntityValue';
import {isDirtyValues} from '../form/helper';

type Props = {
	entity: Entity;
	deep?: number;
	values: any;
};

const EntityItemOnlyValues: React.FC<Props> = ({entity, deep, values}) => {
	return isDirtyValues(values) ? (
		<div key={entity.attributePath}>
			{entity.childAttributes && entity.childAttributes.length > 0 ? (
				<div className={cls(!deep ? css.mainWrapper : css.wrapper)}>
					{!!deep && <div>{entity.caption}:</div>}
					<div className={cls(!!deep && css.childrenWrapper)}>
						{!deep && <h1 id={entity.attributePath}>{entity.caption}</h1>}
						{entity.__typename === 'ObjectCollectionDescriptor' ? (
							<EntitiesCollectionOnlyValues entity={entity} deep={deep} values={values} />
						) : (
							<EntitiesListOnlyValues
								deep={(deep || 0) + 1}
								values={values}
								entities={entity.childAttributes}
							/>
						)}
					</div>
				</div>
			) : entity.__typename === 'ScalarCollectionDescriptor' ? (
				<div className={cls(!deep ? css.mainWrapper : css.wrapper)}>
					{!!deep && <div>{entity.caption}:</div>}
					{!deep && <h1 id={entity.attributePath}>{entity.caption}</h1>}
					<EntitiesCollectionOnlyValues entity={entity} deep={deep} values={values} />
				</div>
			) : (
				<div className={cls(!deep && css.mainWrapper)}>
					{!deep && <h1 id={entity.attributePath}>{entity.caption}</h1>}
					<EntityValue entity={entity} value={values} />
				</div>
			)}
		</div>
	) : null;
};

export default EntityItemOnlyValues;
