import React, {useRef, useState} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import EntitiesList from './EntitiesList';
import Button from '../../pirsInputs/Button/Button';
import css from './EntitiesForm.module.css';
import {usePirsCreateMutation, usePirsUpdateMutation} from '../../../queries-generated/types';
import useMetadata, {Entity} from './useMetadata';
import {clearValues, getErrors} from './helper';
import CustomErrorPopup from '../../controlls/CustomErrorPopup/CustomErrorPopup';
import EntitiesMenu from './EntitiesMenu';
import {format, parseIso} from 'ts-date/locale/ru';
import {useRouter} from 'react-named-hooks-router';
import cls from '../../../utils/cls';
import useInspectorComments from './useInspectorComments';
import {SaveIcon} from '../../SvgIcon';
import {notifyToastSuccess} from 'components/toast/Toast';

type Props = {
	values: any;
	entities: Entity[];
	id?: string;
	type: string;
	instanceVersion?: string;
	caption: string;
	subCaptionPath: string;
	updatedAt: Date | null;
};

type ValidationError = {
	path: string[];
	userFriendlyMessage: string;
};

const EntitiesForm: React.FC<Props> = ({
	values,
	updatedAt: initUpdatedAt,
	entities,
	caption,
	subCaptionPath,
	instanceVersion: initInstanceVersion,
	id,
	type,
}) => {
	const [error, setError] = useState<any>();
	const [create, {loading: createLoading}] = usePirsCreateMutation();
	const [update, {loading: updateLoading}] = usePirsUpdateMutation();
	const {state: metadataState} = useMetadata();
	const {selectedChoiceGroup} = metadataState;
	const {inspectorComments, handleShowComments, showComments} = useInspectorComments();
	const headerRef = useRef<HTMLDivElement>(null);
	const parentRef = useRef<HTMLDivElement>(null);
	const {pushRoute} = useRouter();
	const [updatedAt, setUpdatedAt] = useState(initUpdatedAt);
	const [instanceVersion, setInstanceVersion] = useState(initInstanceVersion);

	const formikBag = useFormik({
		initialValues: values,

		onSubmit: values => {
			const doc = clearValues(entities, values, selectedChoiceGroup) as any;

			const mutation =
				id && instanceVersion
					? update({variables: {id, instanceVersion, doc, type}})
					: create({variables: {doc, type}});
			(mutation as any)
				.then(result => {
					const data = id ? result.data.pirsUpdate : result.data.pirsCreate;
					if (data.errors) {
						(data.errors as ValidationError[]).forEach(error => {
							if (error.path) {
								formikBag.setFieldError(error.path.join('.'), error.userFriendlyMessage);
							} else {
								setError({message: error.userFriendlyMessage});
							}
						});
						if (!data.errors.length) {
							setError({message: 'Неизвестная ошибка'});
						}
					} else {
						if (!id) {
							pushRoute('resourceRegisterInstance', {type, id: data.id});
						}
						setUpdatedAt(parseIso(data.lastDocChangeInstant));
						setInstanceVersion(data.instanceVersion);

						notifyToastSuccess('Сохранение прошло успешно');
					}
				})
				.catch(error => setError(error));
		},
		validate: values => {
			return getErrors(entities, values, selectedChoiceGroup);
		},
	});

	const validateErrorsCount = Object.keys(formikBag.errors).length;

	return (
		<FormikProvider value={formikBag}>
			<Form>
				{error && <CustomErrorPopup error={error} />}
				<div className={css.wrapper} ref={parentRef}>
					<div className={css.sticky} ref={headerRef}>
						<div className={css.head}>
							<div className={css.upHeadPart}>
								<h1>Детальные сведения ресурса</h1>
								<div>{updatedAt && <>Обновлено: {format(updatedAt, 'D MMMM YYYY в HH:mm')}</>}</div>
							</div>
							<div className={css.bottomHeadPart}>
								<h2 className={css.caption}>
									{caption}: {formikBag.getFieldMeta(subCaptionPath).value}
								</h2>
								<div
									className={cls(
										css.infoBlock,
										(inspectorComments?.length || validateErrorsCount) && css.error,
									)}
								>
									{validateErrorsCount
										? `Присутствуют ошибки валидации (${validateErrorsCount})`
										: inspectorComments && inspectorComments.length > 0
										? `Присутствуют замечания инспектора ЦМУ ССОП (${inspectorComments.length})`
										: 'Отсутствуют ошибки валидации и замечания инспектора'}
								</div>
								<Button type={'submit'} loading={createLoading || updateLoading} className={css.submit}>
									<SaveIcon />
									Сохранить
								</Button>
								{inspectorComments && inspectorComments.length > 0 && (
									<Button
										type={'button'}
										className={css.showInspectorCommentsButton}
										onClick={handleShowComments}
										secondary
									>
										{showComments ? 'Скрыть замечания' : 'Показать замечания'}
									</Button>
								)}
							</div>
						</div>
						<div className={css.menu}>
							<div className={css.label}>Быстрый переход к разделу:</div>
							<EntitiesMenu
								entities={entities}
								errors={formikBag.errors}
								headerRef={headerRef}
								parentRef={parentRef}
							/>
						</div>
					</div>
					<EntitiesList entities={entities} errors={formikBag.errors} required={true} type={type} id={id} />
				</div>
			</Form>
		</FormikProvider>
	);
};

export default EntitiesForm;
