import React from 'react';
import css from './InformationMessage.module.css';
import {useInformationMessageQuery} from '../../queries-generated/types';
import Card, {CardItem} from '../../components/controlls/Card/Card';
import {WarningIcon} from '../../components/SvgIcon';

const InformationMessage: React.FC = () => {
	const {data} = useInformationMessageQuery({variables: {key: 'homepage'}});

	return (
		<>
			{data?.informationMessage.value && (
				<Card>
					<CardItem header className={css.informationMessage}>
						<div>
							<WarningIcon className={css.warningIcon} />
						</div>
						<div dangerouslySetInnerHTML={{__html: data.informationMessage.value}} />
					</CardItem>
				</Card>
			)}
		</>
	);
};

export default InformationMessage;
