import * as React from 'react';
import {Logo} from '../../components/SvgIcon';
import css from './HomePage.module.css';
import cls from '../../utils/cls';
import {HomeButtonType, useHomeButtonsQuery} from '../../queries-generated/types';
import MainLayout from '../../components/layouts/MainLayout';
import ProgressRing from '../../components/controlls/Loader/ProgressRing';
import Card, {CardItem} from '../../components/controlls/Card/Card';
import InformationMessage from './InformationMessage';
import SafeLink from '../../components/SafeLink/SafeLink';

const HomePage: React.FC = () => {
	const {data, loading} = useHomeButtonsQuery();

	return (
		<MainLayout padding>
			<InformationMessage />
			<div className={css.wrapperMain}>
				<div className={cls(css.wrapperWelcome)}>Единый портал пользователей ЦМУ ССОП</div>

				{loading ? (
					<ProgressRing />
				) : (
					<div className={css.wrapperSubSys}>
						{data?.homeButtons.map(button => {
							const content = (
								<div className={css.eventsystemlogo}>
									<Logo className={css.eagleimg} />
									<div className={css.textLogo}>{button.title}</div>
								</div>
							);
							let dataRoute = {route: 'home', params: {}};

							try {
								dataRoute = JSON.parse(button.data || '');
							} catch (_e) {
								//nothing
							}
							return (
								<Card key={button.id} className={css.itemExtSubSys}>
									<CardItem>
										{button.type === HomeButtonType.Static ? (
											<a target="_blank" href={button.data}>
												{content}
											</a>
										) : button.type === HomeButtonType.Dynamic ? (
											<a target="_blank" href={`/services/dynamic-link/${button.id}`}>
												{content}
											</a>
										) : (
											<SafeLink route={dataRoute.route} params={dataRoute.params}>
												{content}
											</SafeLink>
										)}
									</CardItem>
								</Card>
							);
						})}
					</div>
				)}
			</div>
		</MainLayout>
	);
};

export default HomePage;
