exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".FileUploadSnippet-module__wrapper__1rGSf {\r\n\tdisplay: flex;\r\n}\r\n\r\n.FileUploadSnippet-module__buttonArea__39hdG {\r\n\tflex: 1;\r\n}\r\n\r\n.FileUploadSnippet-module__dataArea__16efO {\r\n\tflex: 3;\r\n\tmargin-left: 20px;\r\n}\r\n\r\n.FileUploadSnippet-module__dataList__1ND6C {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\n\r\n.FileUploadSnippet-module__dataList__1ND6C > li {\r\n\t\tmargin: 15px 0;\r\n\t}\r\n\r\n.FileUploadSnippet-module__dataList__1ND6C > li:first-child {\r\n\t\t\tmargin-top: 0;\r\n\t\t}\r\n\r\n.FileUploadSnippet-module__link__LzlUZ {\r\n\tcolor: var(--accent-fill-rest);\r\n\tcursor: pointer;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\FileConsolePage\\FileUploadSnippet.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,OAAO;AACR;;AAEA;CACC,OAAO;CACP,iBAAiB;AAClB;;AAEA;CACC,gBAAgB;CAChB,SAAS;CACT,UAAU;AASX;;AAPC;EACC,cAAc;CAKf;;AAHC;GACC,aAAa;EACd;;AAIF;CACC,8BAA8B;CAC9B,eAAe;AAChB","file":"FileUploadSnippet.module.css","sourcesContent":[".wrapper {\r\n\tdisplay: flex;\r\n}\r\n\r\n.buttonArea {\r\n\tflex: 1;\r\n}\r\n\r\n.dataArea {\r\n\tflex: 3;\r\n\tmargin-left: 20px;\r\n}\r\n\r\n.dataList {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\r\n\t> li {\r\n\t\tmargin: 15px 0;\r\n\r\n\t\t&:first-child {\r\n\t\t\tmargin-top: 0;\r\n\t\t}\r\n\t}\r\n}\r\n\r\n.link {\r\n\tcolor: var(--accent-fill-rest);\r\n\tcursor: pointer;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"wrapper": "FileUploadSnippet-module__wrapper__1rGSf",
	"buttonArea": "FileUploadSnippet-module__buttonArea__39hdG",
	"dataArea": "FileUploadSnippet-module__dataArea__16efO",
	"dataList": "FileUploadSnippet-module__dataList__1ND6C",
	"link": "FileUploadSnippet-module__link__LzlUZ"
};