import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import css from './DatesRadioSlider.module.css';
import usePrevious from '../../../components/hooks/usePrevious';
import DatesRadioSliderPoint from './DatesRadioSliderPoint';
import {between} from '../../../utils/number-utils';
import {getDateFromValue} from '../../settings/views/components/Graph/graphHelper';

export type Value = DateIso | 'initial' | 'present';

type Props = {
	values: Value[];
	value?: Value;
	onChange: (value: Value) => void;
	hasInitialMoment: boolean;
	hasPresentMoment: boolean;
};

const DatesRadioSlider: React.FC<Props> = ({
	values: propsValues,
	value,
	onChange,
	hasInitialMoment,
	hasPresentMoment,
}) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const prevValue = usePrevious(value);

	const values = useMemo(() => {
		const values = [...propsValues];
		if (hasInitialMoment) values.unshift('initial');
		if (hasPresentMoment) values.push('present');
		return values;
	}, [propsValues, hasInitialMoment, hasPresentMoment]);

	useEffect(() => {
		if (value !== prevValue && value && wrapperRef.current) {
			const index = values.indexOf(value);
			const item = wrapperRef.current.children.item(index) as HTMLDivElement;
			if (item) {
				const parent = wrapperRef.current.parentNode as HTMLDivElement;
				parent.scrollTop = item.offsetTop - parent.clientHeight / 2;
			}
		}
	}, [prevValue, value, values]);

	const handleChange = useCallback(
		value => {
			onChange(value);
		},
		[onChange],
	);

	return (
		<div className={css.wrapper}>
			<div className={css.scrollWrapper} ref={wrapperRef}>
				{values.map((v, index) => {
					return (
						<DatesRadioSliderPoint
							key={v}
							value={v}
							prevValue={values[index - 1]}
							currentValue={value}
							onChange={handleChange}
							isDiffPoint={
								!!prevValue &&
								!!value &&
								between(
									getDateFromValue(v).getTime(),
									getDateFromValue(prevValue).getTime(),
									getDateFromValue(value).getTime(),
									true,
								)
							}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default DatesRadioSlider;
