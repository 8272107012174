import React, {Dispatch, ReducerAction, ReducerState, useContext, useEffect, useMemo, useReducer, useRef} from 'react';
import {ActionsUnion} from '../../../redux/helper';
import {
	State,
	UploadFileStatus,
	uploadFileActions,
	uploadFileInitialState,
	uploadFileReducer,
	UploadFileData,
} from './UploadFileQueueReducer';
import {useUploadFileQueue} from '../../hooks/useUploadFileQueue';
import {reduxLogger} from '../helpers/reduxLogger';
import {useBeforeUnload} from '../../hooks/useBeforeUnload';

export type UploadFileContextType = {
	state: ReducerState<typeof uploadFileReducer>;
	dispatch: Dispatch<ReducerAction<typeof uploadFileReducer>>;
} & typeof uploadFileActions;
export const UploadFileContext = React.createContext<UploadFileContextType>(null as any);

const numberOfActiveUploads = Number(process.env.REACT_APP_ACTIVE_UPLOADS);

function UploadFileProvider({children}) {
	const [state, dispatch] = useReducer(
		reduxLogger<State, ActionsUnion<typeof uploadFileActions>>(uploadFileReducer),
		uploadFileInitialState,
	);

	const actionBounds = useMemo(() => {
		const actionBounds = {};
		Object.keys(uploadFileActions).forEach(actionKey => {
			const action = uploadFileActions[actionKey];
			actionBounds[actionKey] = payload => dispatch(action(payload));
		});
		return actionBounds;
	}, [dispatch]);
	const value = {
		state,
		dispatch,
		...actionBounds,
	};

	const refFileUploadQueueArray = useRef<UploadFileData[]>([]);
	refFileUploadQueueArray.current = state.fileUploadQueueArray;

	const {uploadFileChunks} = useUploadFileQueue(value);
	useEffect(() => {
		const activeUploads = refFileUploadQueueArray.current.reduce(
			(counter, file) => counter + Number(file.status === UploadFileStatus.LOADING),
			0,
		);
		if (activeUploads < numberOfActiveUploads) {
			const queueUploads = refFileUploadQueueArray.current.filter(
				fileObj => fileObj.status === UploadFileStatus.QUEUE,
			);
			if (queueUploads.length) {
				for (let i = 0; i < numberOfActiveUploads - activeUploads; i++) {
					const file = queueUploads[i];
					if (file) {
						dispatch(
							uploadFileActions.setFileStatus({url: file.url, fileStatus: UploadFileStatus.LOADING}),
						);
						uploadFileChunks(
							file.file,
							file.numberOfChunks,
							file.url,
							refFileUploadQueueArray,
							file.uploadFileCallback,
						);
					}
				}
			}
		}
	}, [state.completeCounter, state.fileUploadQueueArray.length, uploadFileChunks]);

	useBeforeUnload(
		Boolean(state.fileUploadQueueArray.filter(fileObj => fileObj.status === UploadFileStatus.LOADING).length),
	);

	return <UploadFileContext.Provider value={value as any}>{children}</UploadFileContext.Provider>;
}

export function useUploadFiles(id?: string) {
	const {
		addFiles,
		removeFiles,
		clearFiles,
		state: {fileUploadQueueArray},
	} = useContext(UploadFileContext);

	const files = id ? fileUploadQueueArray.filter(fileObj => fileObj.pageId === id) : fileUploadQueueArray;

	return {addFiles, removeFiles, clearFiles, files};
}

export default UploadFileProvider;
