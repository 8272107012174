import React from 'react';
import {TableRowOptions} from './DataGridRow';
import cls from '../../utils/cls';
import css from './DataGrid.module.css';
import Shimmer, {ShimmerText} from '../Shimmer/Shimmer';
import {DataGridAction} from '../settings/views/components/helper';

type Props = {
	options?: TableRowOptions;
	header?: boolean;
	actionButtons?: DataGridAction[];
};

const DataGridRowShimmer: React.FC<Props> = ({options, header, actionButtons}) => {
	const {visibleFields, detailsFields, selectable, isGroupHeader, groupPart} = options || {};
	const fields = visibleFields || Array(3).fill(true);
	return (
		<>
			<div className={cls(header ? css.headers : css.row)} role="presentation">
				{(selectable || (actionButtons && actionButtons.length > 0) || groupPart || isGroupHeader) && (
					<div className={cls(css.actions, css.cell)}>
						{selectable && (
							<div className={css.selectCircle} role="presentation">
								<Shimmer>
									<div style={{width: 20, height: 20, borderRadius: 6}} />
								</Shimmer>
							</div>
						)}
						<div className={css.actionButtons} role="presentation">
							{actionButtons?.map(itemAction => (
								<div key={itemAction.id} className={css.actionButton} role="presentation" />
							))}
						</div>
						{(groupPart || isGroupHeader) && (
							<div
								className={cls(css.groupCollapse, isGroupHeader && css.groupHeaderButton)}
								role="presentation"
							/>
						)}
					</div>
				)}
				{fields.map((_field, index) =>
					header ? (
						<div className={css.column} key={index}>
							<div className={css.header}>
								<ShimmerText lines={1} styles={{width: header ? '40%' : '100%'}} />
							</div>
						</div>
					) : (
						<div className={cls(css.cell, css.visibleCell)} key={index}>
							<ShimmerText lines={1} styles={{width: header ? '40%' : '100%'}} />
						</div>
					),
				)}
				{detailsFields && detailsFields?.length > 0 && <div className={cls(css.cell, css.detailsToggle)} />}
			</div>
		</>
	);
};

export default DataGridRowShimmer;
