import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {
	AnomaliesQuantityQuery,
	AnomaliesByFilterQuery,
	PeriodDetail,
	AnomaliesBySourceQuery,
} from '../../queries-generated/types';
import DataChart from './DataChart';
import {toggleFullscreen as switchFullscreen} from './../../utils/fullscreen';
import {ApolloError} from '@apollo/client';
import {getApolloError} from '../../utils/error';
import {AxisLabelsFormatterContextObject, Chart} from 'highcharts';
import {format} from 'ts-date/esm';
import css from './AlertsSnippet.module.css';
import {CustomContextMenuActions} from './exporting';
import {ChartType} from './HiChart';
import Snippet from '../settings/views/components/Snippet/Snippet';

type DataProvider = {
	loading: boolean;
	data?: AnomaliesQuantityQuery | AnomaliesBySourceQuery | AnomaliesByFilterQuery;
	error: ApolloError | undefined;
	refetch: () => void;
};

type Props = {
	title: string;
	yAxisTitle: string;
	formFactor?: number;
	periodDetail?: PeriodDetail;
	chartType?: ChartType;
	dataProvider: (detail: PeriodDetail, otherProviderInputs?: {[key: string]: any}, skip?: boolean) => DataProvider;
	otherProviderInputs?: {[key: string]: any};
	skip?: boolean;
	onLoading?: (loading: boolean) => void;
	onSetRefetch?: (onRefetch: () => void) => void;
};

function chartShortMonth(num: number, chart: Chart) {
	const shortMonths = chart.options?.lang?.shortMonths;
	return shortMonths && shortMonths[num] ? shortMonths[num] : num.toString().padStart(2, '0');
}

enum DataStatus {
	empty = 0,
	needRefetch = 1,
	cached = 2,
}

type DataStatuses = {
	[key in PeriodDetail]?: DataStatus;
};

const AlertsSnippet: React.FC<Props> = ({
	title,
	formFactor,
	periodDetail,
	chartType,
	dataProvider,
	yAxisTitle,
	otherProviderInputs,
	skip,
	onLoading,
	onSetRefetch,
}) => {
	const [detail, setDetail] = useState<PeriodDetail>(periodDetail ? periodDetail : PeriodDetail.Hour);
	const {loading, data, error, refetch} = dataProvider(detail, otherProviderInputs, skip);
	if (onLoading) onLoading(loading);

	const dataStatusesRef = useRef<DataStatuses>({});
	if (dataStatusesRef.current[detail] === undefined) dataStatusesRef.current[detail] = DataStatus.empty;

	if (data && !loading && dataStatusesRef.current[detail] !== DataStatus.needRefetch)
		dataStatusesRef.current[detail] = DataStatus.cached;
	if (dataStatusesRef.current[detail] === DataStatus.needRefetch) {
		dataStatusesRef.current[detail] = DataStatus.empty;
		refetch();
	}

	const menuItemHtmlWrapper = useCallback(
		(forDetail: PeriodDetail) => {
			const selectedPrefix =
				forDetail === detail
					? '<div style="font-weight: bold; position: absolute; left: 30px;">&#10003</div><div style="font-weight: bold;">'
					: '<div>';
			const selectedSuffix = forDetail === detail ? '</div>' : '</div>';
			switch (forDetail) {
				case PeriodDetail.Minute:
					return selectedPrefix + 'Детализация: минута' + selectedSuffix;
				case PeriodDetail.Hour:
				default:
					return selectedPrefix + 'Детализация: час' + selectedSuffix;
				case PeriodDetail.Day:
					return selectedPrefix + 'Детализация: сутки' + selectedSuffix;
			}
		},
		[detail],
	);

	const refetcher = useCallback(() => {
		if (!loading) {
			Object.keys(dataStatusesRef.current).forEach(key => {
				if (dataStatusesRef.current[key] === DataStatus.cached) {
					if (key !== detail) dataStatusesRef.current[key] = DataStatus.needRefetch;
					else dataStatusesRef.current[key] = DataStatus.empty;
				}
			});
			refetch();
		}
	}, [refetch, detail, loading]);

	if (onSetRefetch) onSetRefetch(refetcher);

	const getCustomContextMenuActions: () => CustomContextMenuActions = useCallback(() => {
		return [
			{
				text: 'Обновить',
				onclick: () => {
					refetcher();
				},
			},
			'separator',
			{
				text: menuItemHtmlWrapper(PeriodDetail.Minute),
				onclick: () => {
					if (detail !== PeriodDetail.Minute) setDetail(PeriodDetail.Minute);
				},
			},
			{
				text: menuItemHtmlWrapper(PeriodDetail.Hour),
				onclick: () => {
					if (detail !== PeriodDetail.Hour) setDetail(PeriodDetail.Hour);
				},
			},
			{
				text: menuItemHtmlWrapper(PeriodDetail.Day),
				onclick: () => {
					if (detail !== PeriodDetail.Day) setDetail(PeriodDetail.Day);
				},
			},
		];
	}, [menuItemHtmlWrapper, detail, refetcher]);

	const snippetDivRef = useRef<HTMLDivElement | null>();
	const frameRef = useRef<HTMLIFrameElement>(null);
	const resizeTimerRef = useRef(0);
	const chartRef = useRef<HTMLDivElement>(null);

	const [fullscreen, setFullscreen] = useState<boolean>(false);
	const nextFullscreenState = useRef<boolean>(false);

	const [needRedraw, setNeedRedraw] = useState<boolean>(false);

	useEffect(() => {
		const reflowChart = () => {
			// ignore safari resize events while fullscreen change
			if (nextFullscreenState.current !== fullscreen) return;
			if (resizeTimerRef.current) {
				clearTimeout(resizeTimerRef.current);
				resizeTimerRef.current = 0;
			}
			resizeTimerRef.current = setTimeout(() => {
				resizeTimerRef.current = 0;
				setNeedRedraw(true);
			}, 100);
		};

		const clearResizeTimer = () => {
			if (resizeTimerRef.current) {
				clearTimeout(resizeTimerRef.current);
				resizeTimerRef.current = 0;
			}
		};

		const frameContentWindow = frameRef.current?.contentWindow;
		if (!frameContentWindow) return;

		window.addEventListener('resize', clearResizeTimer);
		frameContentWindow.addEventListener('resize', reflowChart);

		return () => {
			window.removeEventListener('resize', clearResizeTimer);
			frameContentWindow.removeEventListener('resize', reflowChart);
		};
	}, [frameRef, snippetDivRef, formFactor, fullscreen]);

	const beginFullscreen = useCallback(() => {
		!fullscreen && setFullscreen((nextFullscreenState.current = true));
	}, [fullscreen]);

	const endFullscreen = useCallback(() => {
		fullscreen && setFullscreen((nextFullscreenState.current = false));
	}, [fullscreen]);

	const shippetCallbackRefCreate = useCallback(
		elem => {
			if (elem) {
				elem.addEventListener('beginfullscreen', beginFullscreen);
				document.addEventListener('endfullscreen', endFullscreen);
			} else {
				snippetDivRef.current?.removeEventListener('beginfullscreen', beginFullscreen);
				document.removeEventListener('endfullscreen', endFullscreen);
			}
			snippetDivRef.current = elem;
		},
		[formFactor, beginFullscreen, endFullscreen],
	);

	const toggleFullscreen = useCallback(() => {
		switchFullscreen(chartRef.current, !fullscreen);
	}, [fullscreen]);

	const xAxisTimeFormatter = useCallback(
		(params: AxisLabelsFormatterContextObject) => {
			const dt = new Date(params.value);

			let dateFormat: string | undefined = undefined;
			switch (detail) {
				case PeriodDetail.Minute:
					if (dt.getSeconds() !== 0) break;
					else if (dt.getHours() === 0) dateFormat = `DD.${chartShortMonth(dt.getMonth(), params.chart)}`;
					else dateFormat = 'HH:mm';
					break;
				case PeriodDetail.Hour:
					if (dt.getSeconds() !== 0 || dt.getMinutes() !== 0) break;
					if (dt.getHours() === 0) dateFormat = `DD.${chartShortMonth(dt.getMonth(), params.chart)}`;
					else dateFormat = 'HH';
					break;
				case PeriodDetail.Day:
					if (dt.getSeconds() !== 0 || dt.getMinutes() !== 0 || dt.getHours() !== 0) dateFormat = '';
					else dateFormat = `DD.${chartShortMonth(dt.getMonth(), params.chart)}`;
					break;
			}
			return dateFormat ? format(dt, dateFormat) : '';
		},
		[detail],
	);

	return (
		<div ref={shippetCallbackRefCreate} className={css.wrapper}>
			<iframe className={css.frame} ref={frameRef} />
			<Snippet title={title} className={css.snippet} snippetBodyClassName={css.snippetContent}>
				<div className={css.diagramWrapper} ref={chartRef}>
					<DataChart
						chartType={chartType}
						data={data}
						yAxisTitle={yAxisTitle}
						customContextMenuActions={getCustomContextMenuActions()}
						toggleFullscreen={toggleFullscreen}
						fullscreen={fullscreen}
						xAxisFormatter={xAxisTimeFormatter}
						title={fullscreen ? title : undefined}
						loading={loading}
						error={error ? `Произошла ошибка: ${getApolloError(error).message}` : undefined}
						needRedraw={needRedraw}
						setNeedRedraw={setNeedRedraw}
					/>
				</div>
			</Snippet>
		</div>
	);
};

export default React.memo(AlertsSnippet);
