exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".AlertsSnippet-module__diagramWrapper__2S323 {\r\n\theight: 100%;\r\n\twidth: 100%;\r\n}\r\n\r\n.AlertsSnippet-module__snippet__18ls4 {\r\n\theight: 100%;\r\n}\r\n\r\n.AlertsSnippet-module__snippetContent__1Z883 {\r\n\theight: calc(100% - 74px);\r\n}\r\n\r\n.AlertsSnippet-module__frame__1GenO {\r\n\tposition: absolute;\r\n\tz-index: -1;\r\n\ttop: 0;\r\n\tleft: 0;\r\n\twidth: calc(100% - 4px);\r\n\theight: 1px;\r\n}\r\n\r\n.AlertsSnippet-module__wrapper__3C5LT {\r\n\tposition: relative;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\hiChart\\AlertsSnippet.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,WAAW;AACZ;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,kBAAkB;CAClB,WAAW;CACX,MAAM;CACN,OAAO;CACP,uBAAuB;CACvB,WAAW;AACZ;;AAEA;CACC,kBAAkB;AACnB","file":"AlertsSnippet.module.css","sourcesContent":[".diagramWrapper {\r\n\theight: 100%;\r\n\twidth: 100%;\r\n}\r\n\r\n.snippet {\r\n\theight: 100%;\r\n}\r\n\r\n.snippetContent {\r\n\theight: calc(100% - 74px);\r\n}\r\n\r\n.frame {\r\n\tposition: absolute;\r\n\tz-index: -1;\r\n\ttop: 0;\r\n\tleft: 0;\r\n\twidth: calc(100% - 4px);\r\n\theight: 1px;\r\n}\r\n\r\n.wrapper {\r\n\tposition: relative;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"diagramWrapper": "AlertsSnippet-module__diagramWrapper__2S323",
	"snippet": "AlertsSnippet-module__snippet__18ls4",
	"snippetContent": "AlertsSnippet-module__snippetContent__1Z883",
	"frame": "AlertsSnippet-module__frame__1GenO",
	"wrapper": "AlertsSnippet-module__wrapper__3C5LT"
};