import React, {useCallback} from 'react';
import {ApolloError} from '@apollo/client';
import {getApolloError} from '../../utils/error';
import css from './Toast.module.css';
import toast, {ErrorIcon, CheckmarkIcon} from 'react-hot-toast';
import {Toast, ToastOptions} from 'react-hot-toast/dist/core/types';
import Button from '../pirsInputs/Button/Button';
import {CrossIcon} from '../SvgIcon';
import ReadMore from 'components/ReadMore/ReadMore';

const ToastWrapper: React.FC<{toastArgs: Toast}> = ({toastArgs, children}) => {
	const handleDismissToast = useCallback(() => {
		toast.dismiss(toastArgs.id);
	}, [toastArgs.id]);

	return (
		<div className={css.toastWrapper}>
			{children}
			<Button action iconOnly onClick={handleDismissToast}>
				<CrossIcon />
			</Button>
		</div>
	);
};

const Error: React.FC<{error: ApolloError | string}> = ({error}) => {
	const apolloError = typeof error === 'object' ? getApolloError(error) : undefined;
	const customErrorMessage = typeof error === 'string' ? error : undefined;

	return (
		<div>
			<div>
				<ReadMore text={apolloError?.message || customErrorMessage} />
			</div>
			{apolloError && <div className={css.errorCode}>Код ошибки: {apolloError.code}</div>}
		</div>
	);
};

// Возвращает id тоста
export const notifyToastError = (error: ApolloError | string, toastOptions?: ToastOptions): string => {
	return toast.error(
		args => {
			return (
				<ToastWrapper toastArgs={args}>
					<Error error={error} />
				</ToastWrapper>
			);
		},
		{
			icon: (
				<div className={css.toastIconWrapper}>
					<ErrorIcon />
				</div>
			),
			...toastOptions,
		},
	);
};

// Возвращает id тоста
export const notifyToastSuccess = (children: React.ReactNode, toastOptions?: ToastOptions): string => {
	return toast.success(
		args => {
			return <ToastWrapper toastArgs={args}>{children}</ToastWrapper>;
		},
		{
			icon: (
				<div className={css.toastIconWrapper}>
					<CheckmarkIcon />
				</div>
			),
			...toastOptions,
		},
	);
};
