import * as React from 'react';
import {useCallback, useState, useEffect} from 'react';
import {useFormik, FormikProvider, Form} from 'formik';
import {FormikErrors} from 'formik/dist/types';
import MainLayout from '../../components/layouts/MainLayout';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import css from './SettingsFilesPage.module.css';
import {useRouter} from 'react-named-hooks-router';
import NavigatePopup from '../../components/controlls/NavigatePopup/NavigatePopup';
import {useUploadFiles} from '../../components/commonRedux/UploadFileStore/UploadFileQueueProvider';
import {UploadFileStatus} from '../../components/commonRedux/UploadFileStore/UploadFileQueueReducer';
import {useUploadSettingsFileMutation} from '../../queries-generated/types';
import {getApolloError, getQueryError} from '../../utils/error';
import {formatBytes} from '../../utils/number-utils';
import TempFileDataSnippet from './TempFileDataSnippet';
import LoadingFileSnippet from './LoadingFileSnippet';
import LoadedFileSnippet from './LoadedFileSnippet';
import ErrorFileSnippet from './ErrorFileSnippet';
import useSessionStorage from '../../components/hooks/useSessionStorage';
import InputFile from '../../components/pirsInputs/InputFile/InputFile.formik';
import InputSearch from '../../components/pirsInputs/InputSearch/InputSearch';

export type Values = {
	file: File | undefined;
};

enum SettingsFileStatus {
	created = 'created',
	inProgress = 'in_progress',
	finished = 'finished',
	failed = 'failed',
}

function translateSettingsFileStatus(status: SettingsFileStatus | string): string {
	switch (status) {
		case SettingsFileStatus.created:
			return 'Создан';
		case SettingsFileStatus.inProgress:
			return 'Загружается';
		case SettingsFileStatus.finished:
			return 'Загружен успешно';
		case SettingsFileStatus.failed:
			return 'Ошибка при загрузке';
		default:
			return status;
	}
}

const SettingsFilesPage: React.FC = () => {
	const pageId = 'settingsFile';
	const {addFiles, removeFiles, files} = useUploadFiles(pageId);
	const currentFile = files[0];
	const [tempFileData, setTempFileData] = useState<{fileName: string; fileSize: string; progress: 0}>();

	useEffect(() => {
		if (currentFile?.status === UploadFileStatus.LOADED || currentFile?.status === UploadFileStatus.ERROR) {
			refresh();
		}
	}, [currentFile?.status]);

	const [createFileMutation] = useUploadSettingsFileMutation();
	const [mutationError, setMutationError] = useState<{message: string; file: File}>();

	const [filter, setFilter] = useState<string>();
	const {Table, refresh} = useFullTableWithRefetch('settingsFiles', {
		tableFields: ['tag', 'name', 'description', 'size', 'date_created', 'date_uploaded', 'status'],
		deletable: true,
		editable: true,
		variables: {
			filter: filter
				? `(name like ${filter}) or (description like ${filter}) or (tag like ${filter})`
				: undefined,
		},
		cellCallback: {
			name: (value, item) => {
				if (item.status === 'finished' && item.date_uploaded) {
					return (
						<a className={css.tableCellName} download href={`/services/settingsDownloadFile/${item.id}`}>
							{value}
						</a>
					);
				}
				return value;
			},
			status: value => {
				return translateSettingsFileStatus(value);
			},
		},
		showEditButtonCallback: item => {
			if (item.status) {
				return item.status === 'finished';
			}
			return false;
		},
		showDeleteButtonCallback: item => {
			if (currentFile) {
				return !currentFile.url.includes(item.id);
			}
			return true;
		},
	});

	const uploadFile = useCallback(
		(values: Values) => {
			const {file} = values;
			if (!file) return;
			setTempFileData({fileName: file.name, fileSize: formatBytes(file.size), progress: 0});
			createFileMutation({
				variables: {
					input: {
						size: file.size.toString(),
						chunks: Math.ceil(file.size / Number(process.env.REACT_APP_CHUNK_LENGTH)).toString(),
						name: file.name,
					},
				},
			})
				.then(result => {
					if (result && result.data) {
						addFiles([
							{
								file,
								pageId,
								url: `/services/settingsFilesUploadChunk?id=${result.data.uploadSettingsFile}`,
							},
						]);
					} else {
						setMutationError({message: getQueryError(result.errors).message, file});
					}
					setTempFileData(undefined);
				})
				.catch(error => {
					setTempFileData(undefined);
					setMutationError({message: getApolloError(error).message, file});
				});
		},
		[createFileMutation],
	);

	const [isModalShow, setIsModalShow] = useSessionStorage('isModalShow(SettingFiles)', false);

	const [navigateCallback, setNavigateCallback] = useState<() => void | undefined>();
	const handleNavigate = useCallback(
		navigate => {
			if (currentFile?.status === UploadFileStatus.LOADING && !isModalShow) {
				setNavigateCallback(() => navigate);
				setIsModalShow(true);
			} else {
				navigate('navigate');
			}
		},
		[currentFile?.status, isModalShow],
	);
	useRouter(handleNavigate);

	const formikBag = useFormik<Values>({
		initialValues: {
			file: undefined,
		},
		onSubmit: values => {
			uploadFile(values);
		},
		validate: values => {
			const errors: FormikErrors<Values> = {};
			if (!values.file || values.file.size === 0) errors.file = 'Ваш файл пустой, укажите корректный файл';
			return errors;
		},
		enableReinitialize: true,
		validateOnBlur: false,
	});

	const handleSubmit = () => {
		setTimeout(() => {
			formikBag.submitForm();
		});
	};

	const handleResetErrorAndLoadState = useCallback(() => {
		if (mutationError) {
			setMutationError(undefined);
		} else if (currentFile?.status === UploadFileStatus.LOADED || currentFile?.status === UploadFileStatus.ERROR) {
			removeFiles([currentFile.url]);
		}
	}, [mutationError, setMutationError, currentFile?.url, currentFile?.status]);

	const fileStatus = useCallback(() => {
		if ((!formikBag.errors.file && currentFile?.error) || mutationError?.message) {
			return (
				<ErrorFileSnippet
					removeFile={handleResetErrorAndLoadState}
					loadingFileErrorData={currentFile?.file}
					mutationErrorData={mutationError}
				/>
			);
		}
		if (currentFile?.status === UploadFileStatus.LOADED) {
			return (
				<LoadedFileSnippet
					fileName={currentFile?.file.name}
					fileSize={currentFile?.file.size}
					removeFile={handleResetErrorAndLoadState}
				/>
			);
		}
		if (currentFile?.status === UploadFileStatus.LOADING) {
			return (
				<LoadingFileSnippet
					fileName={currentFile?.file.name}
					fileSize={currentFile?.file.size}
					progress={currentFile?.progressLoading}
				/>
			);
		}
		if (currentFile?.status === UploadFileStatus.QUEUE) {
			return (
				<div className={css.messageWrapper}>
					<div className={css.messageText}>
						<span>Файл в очереди на загрузку </span>
						<b title={currentFile?.file.name}>
							[{formatBytes(currentFile?.file.size)}] {currentFile?.file.name}
						</b>
					</div>
				</div>
			);
		}
		if (tempFileData) {
			return (
				<TempFileDataSnippet
					fileName={tempFileData.fileName}
					fileSize={tempFileData.fileSize}
					progress={tempFileData.progress}
				/>
			);
		}
		return null;
	}, [
		mutationError,
		currentFile?.error,
		currentFile?.status,
		currentFile?.progressLoading,
		currentFile?.url,
		tempFileData,
	]);

	const handleCloseModalPopup = useCallback(() => {
		setNavigateCallback(undefined);
	}, []);

	const handleCloseModalPopupAndNavigate = useCallback(() => {
		if (navigateCallback) navigateCallback();
		setNavigateCallback(undefined);
	}, [navigateCallback]);

	return (
		<MainLayout>
			<NavigatePopup
				navigateCallback={navigateCallback}
				handleCloseModalPopup={handleCloseModalPopup}
				handleCloseModalPopupAndNavigate={handleCloseModalPopupAndNavigate}
				text={'Вы уходите со страницы, загрузка файла продолжится в фоновом режиме'}
			/>
			<div className={css.container}>
				<FormikProvider value={formikBag}>
					<Form>
						<div onClick={handleResetErrorAndLoadState}>
							<InputFile
								disabled={Boolean(tempFileData) || currentFile?.status === UploadFileStatus.LOADING}
								/*short*/
								name="file"
								onCustomChange={handleSubmit}
							/>
						</div>
					</Form>
				</FormikProvider>

				<div>{fileStatus()}</div>
				<InputSearch onChange={setFilter} />
			</div>
			<Table />
		</MainLayout>
	);
};

export default SettingsFilesPage;
