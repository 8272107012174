import React, {useCallback, useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import styles from './FilesListRow.module.css';
import {format} from 'ts-date/esm/locale/ru';
import {formatBytes} from '../../utils/number-utils';
import {RemoteFile} from '../../queries-generated/types';
import {tryParse} from '../../components/hooks/useLocalStorage';

type Props = {
	file: RemoteFile;
	colSpan: number;
};

enum RemoteFileStatus {
	OPEN = 'OPEN',
	FILE_TRANSPORT_COMPLETE = 'FILE_TRANSPORT_COMPLETE',
	REJECTED = 'REJECTED',
	ACCEPTED = 'ACCEPTED',
	PENDING = 'PENDING',
}

export function translateRemoteFileStatus(status: RemoteFileStatus | string): string {
	switch (status) {
		case RemoteFileStatus.OPEN:
		case RemoteFileStatus.PENDING:
			return 'Загружается';
		case RemoteFileStatus.FILE_TRANSPORT_COMPLETE:
			return 'Загружено';
		case RemoteFileStatus.REJECTED:
			return 'Отклонено';
		case RemoteFileStatus.ACCEPTED:
			return 'Принято';
		default:
			return status;
	}
}

const FilesListRow: React.FC<Props> = ({file, colSpan}) => {
	const [showDetail, setShowDetail] = useState(false);

	const handleClick = useCallback(() => {
		setShowDetail(!showDetail);
	}, [showDetail]);

	const statusMessage = tryParse(file.statusMessage);

	return (
		<>
			<TableRow onClick={handleClick} className={styles.tableRow} hover key={file.id}>
				<TableCell>{file.regNumber}</TableCell>
				<TableCell>
					<a download href={`/services/filesConsole/${file.id}`}>
						{file.fileName}
					</a>
				</TableCell>
				<TableCell>{format(new Date(file.submittedAt), 'DD.MM.YYYY HH:mm')}</TableCell>
				<TableCell>{formatBytes(file.fileSize)}</TableCell>
				<TableCell>{translateRemoteFileStatus(file.status)}</TableCell>
			</TableRow>
			{showDetail && (
				<TableRow>
					<TableCell colSpan={colSpan}>
						<ul className={styles.details}>
							<li>
								<span className={styles.title}>Сведения о проверке</span>
								{statusMessage && statusMessage.message ? (
									<span dangerouslySetInnerHTML={{__html: statusMessage.message}} />
								) : (
									<span dangerouslySetInnerHTML={{__html: statusMessage}} />
								)}
								{statusMessage.errors &&
								statusMessage.errors !== 'null' &&
								statusMessage.errors.length > 0 ? (
									<ul className={styles.errors}>
										{statusMessage.errors.map((message, index) => (
											<li key={index}>{message}</li>
										))}
									</ul>
								) : null}
							</li>
						</ul>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};

export default FilesListRow;
