import React from 'react';
import ChoiceGroup from './ChoiceGroup';
import {Entity} from './useMetadata';
import EntityItem from './EntityItem';
import {useFormikContext} from 'formik';
import {isDirtyValues} from './helper';
import useInspectorComments from './useInspectorComments';
import css from './EntitiesList.module.css';
import PopoverButton from '../../controlls/PopoverButton/PopoverButton';
import {WarningIcon} from '../../SvgIcon';

type Props = {
	entities: Entity[];
	deep?: number;
	name?: string;
	errors?: any;
	required: boolean;
	id?: string;
	type: string;
};

const EntitiesList: React.FC<Props> = ({entities, required: propsRequired, deep, name, errors, id, type}) => {
	const choiceGroup: Entity[] = [];
	const formikBag = useFormikContext();
	const {inspectorCommentsPathList, getInspectorCommentByPath} = useInspectorComments();

	return (
		<>
			{name && inspectorCommentsPathList.includes(name) ? (
				<div className={css.commentButtonWrapper}>
					<PopoverButton
						popover={<div className={css.comment}>{getInspectorCommentByPath(name)}</div>}
						className={css.commentButton}
					>
						<WarningIcon className={css.warningIcon} />
					</PopoverButton>
				</div>
			) : null}
			{entities.map((entity, index) => {
				const newName = name ? name + '.' + entity.keyword : entity.keyword;
				const fieldMeta = name && formikBag.getFieldMeta(name);
				const value = fieldMeta && fieldMeta.value;
				const required = propsRequired ? entity.required : isDirtyValues(value) && entity.required;

				// NOTE: Choice group идут подряд в JSON. Оптимизация, O(n). Перебираем их, пока они не закончатся, сохраняя в отдельный объект
				if (entity.choiceGroup && entities.length === index + 1) {
					choiceGroup.push(entity);
					return (
						<ChoiceGroup
							key={entity.attributePath}
							entities={choiceGroup}
							name={name}
							deep={deep}
							errors={errors}
							required={required}
							id={id}
							type={type}
						/>
					);
				} else if (entity.choiceGroup) {
					choiceGroup.push(entity);
					return null;
					// NOTE: Тут они закончились и мы редерим наконец
				} else if (choiceGroup.length) {
					return (
						<ChoiceGroup
							key={entity.attributePath}
							entities={choiceGroup}
							name={name}
							deep={deep}
							errors={errors}
							required={required}
							id={id}
							type={type}
						/>
					);
				} else {
					return (
						<EntityItem
							key={entity.attributePath}
							entity={entity}
							name={newName}
							deep={deep}
							errors={errors}
							required={required}
							id={id}
							type={type}
						/>
					);
				}
			})}
		</>
	);
};

export default EntitiesList;
