import React, {useContext, useEffect} from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import GraphBase from './GraphBase';
import {DataSourceNotification, Graph, useGraphQuery} from '../../../../../queries-generated/types';
import usePrevious from '../../../../hooks/usePrevious';
import css from './Graph.module.css';
import {getError} from '../../../../../utils/error';
import ProgressRing from '../../../../controlls/Loader/ProgressRing';
import {useComponentFilters} from '../../../../view/api/useUserFilters';
import {isNotifiedDataSource, filtersToDataSourceParams} from '../helper';
import {notifyDataSource} from '../../../../toast/DataSourceToast';
import {DataSourceContext} from '../../../../view/ViewWrapper';
import {NetworkStatus} from '@apollo/client';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import useComponentCommonButtons from '../../../../view/useComponentCommonButtons';
import viewComponentCss from '../../../../view/ViewComponent.module.css';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	pollInterval?: number;
	height?: number | string;
};

const defaultData: Graph = {
	nodes: [
		{id: '1', label: '1 узел'},
		{id: '2', label: '2 узел'},
		{id: '3', label: '3 узел'},
		{id: '4', label: '4 узел'},
	],
	edges: [
		{
			id: '1-2',
			from: '1',
			to: '2',
		},
		{
			id: '1-3',
			from: '1',
			to: '3',
		},
		{
			id: '1-4',
			from: '1',
			to: '4',
		},
	],
	options: {},
};

const GraphComponent: React.FC<Props> = ({viewItem, skipQuery, pollInterval, height}) => {
	const {reloadCounter} = useContext(DataSourceContext);
	const prevReloadCounter = usePrevious(reloadCounter);
	const {filters, waiting} = useComponentFilters(viewItem);
	const {setData} = useComponentsStoredData(viewItem.id);

	const {data, loading, refetch, error, networkStatus} = useGraphQuery({
		variables: {dataSourceId: viewItem.dataSource?.type || '', filters: filtersToDataSourceParams(filters)},
		skip: skipQuery || waiting,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		pollInterval,
		onCompleted: result => {
			if (isNotifiedDataSource(viewItem.actions))
				notifyDataSource(result.graph.notifications as DataSourceNotification[]);
		},
	});

	useEffect(() => {
		if (prevReloadCounter !== undefined && reloadCounter !== prevReloadCounter && !skipQuery) {
			refetch();
		}
	}, [reloadCounter, skipQuery, prevReloadCounter]);

	useEffect(() => {
		const graphData = skipQuery ? defaultData : data?.graph;
		setData({...viewItem.dataSource, data: graphData});
	}, [defaultData, data, skipQuery, viewItem]);

	const commonButtons = useComponentCommonButtons(viewItem);

	const graphData = skipQuery ? defaultData : data?.graph;

	return (
		<>
			{(error || (loading && networkStatus !== NetworkStatus.poll) || !graphData) && (
				<div className={viewComponentCss.filters}>
					{commonButtons?.RefreshAction}
					{commonButtons?.FilterAction}
				</div>
			)}

			{error ? (
				<div className={css.error}>{getError(error)?.message || 'Произошла ошибка'}</div>
			) : (loading && networkStatus !== NetworkStatus.poll) || !graphData ? (
				<div className={css.error}>
					<ProgressRing />
				</div>
			) : (
				<GraphBase
					viewItemId={viewItem.id}
					data={graphData}
					direction={viewItem.component.props.alignment?.id || 'star'}
					hideDeleted={viewItem.component.props.hide_deleted}
					hasHistory={!!graphData.options.hasHistory}
					hasHistoryInitialMoment={!!graphData.options.hasHistoryInitialMoment}
					hasHistoryPresentMoment={!!graphData.options.hasHistoryPresentMoment}
					historyDates={graphData.options.historyDates || []}
					firstProgressionItem={viewItem.component.props.firstProgressionItem}
					progressionStep={viewItem.component.props.progressionStep}
					showDirectionControl={viewItem.component.props.showDirectionControl}
					showReplay={viewItem.component.props.showReplay || false}
					replayIntervalInSeconds={viewItem.component.props.replayIntervalInSeconds || 10}
					height={height}
					commonButtons={commonButtons}
				/>
			)}
		</>
	);
};

export default GraphComponent;
