import {useCallback, useEffect, useState} from 'react';
import {AnomalyOpenState, Filters} from './AlertsAnomaliesPage';
import {
	GetConfigurationGroupDocument,
	GetConfigurationGroupQuery,
	GetConfigurationGroupQueryVariables,
	OldDictionaryDocument,
	OldDictionaryQuery,
	OldDictionaryQueryVariables,
} from '../../queries-generated/types';
import {ApolloClient, useApolloClient} from '@apollo/client';
import {PredefinedPeriod} from '../../other/PredefinedPeriod';
import {getComboboxItems} from '../../components/Comboboxes/Comboboxes';
import {AsObject, BgpAgent, BgpOperator} from '../../components/asLinks/AsLinksFilters/AsLinksFilters';

export enum LoadFiltersByRoute {
	NoRouteFilters = 'NoRouteFilters',
	InProgress = 'InProgress',
	Completed = 'Completed',
}

export type RouteToAlertAnomaliesParams = Pick<Filters, 'anomalySource' | 'anomalyOpenState'> &
	Partial<
		Pick<Filters, 'periodOpened' | 'openedFromDt' | 'openedToDt' | 'periodClosed' | 'closedFromDt' | 'closedToDt'>
	> &
	Partial<
		Pick<
			{
				bgpOperators: string | string[];
				bgpAgents: string | string[];
				anomalyTypes_pasmon: string | string[];
				anomalyTypes_ranr: string | string[];
				anomalyTypes_asbi: string | string[];
				anomalyTypes_nsdi: string | string[];
				asSrc: string | string[];
				asDst: string | string[];
			},
			| 'bgpOperators'
			| 'bgpAgents'
			| 'anomalyTypes_pasmon'
			| 'anomalyTypes_ranr'
			| 'anomalyTypes_asbi'
			| 'anomalyTypes_nsdi'
			| 'asSrc'
			| 'asDst'
		>
	>;

export function useRouteVariables(
	routeFilterVariables: RouteToAlertAnomaliesParams | undefined,
	formikBag,
): LoadFiltersByRoute[] {
	const apolloClient = useApolloClient();

	const anomalySourcePromise = getConfigurationGroup(
		apolloClient,
		routeFilterVariables?.anomalySource ? decodeURIComponent(routeFilterVariables.anomalySource) : undefined,
	);

	const bgpOperatorsPromises: any[] = [];
	if (routeFilterVariables?.bgpOperators) {
		const operators = Array.isArray(routeFilterVariables.bgpOperators)
			? routeFilterVariables.bgpOperators
			: routeFilterVariables.bgpOperators.split(',');
		operators.forEach(bgpOperator => {
			bgpOperatorsPromises.push(
				getComboboxItems('bgpOperator', bgpOperator || '', apolloClient, bgpOperator ? 0 : 4096),
			);
		});
	}

	const bgpAgentsPromises: any[] = [];
	const bgpAgentsUsedNamesInInn: {[key: string]: string[]} = {};
	if (routeFilterVariables?.bgpAgents) {
		const agents = Array.isArray(routeFilterVariables.bgpAgents)
			? routeFilterVariables.bgpAgents
			: routeFilterVariables.bgpAgents.split(',');
		agents.forEach(bgpAgent => {
			const bgpAgentName = bgpAgent.split(/:(.+)$/)[1];
			const bgpAgentInn = bgpAgent.split(/^([^:]+)/)[1];
			if (bgpAgentInn && bgpAgentName) {
				if (!bgpAgentsUsedNamesInInn[bgpAgentInn]) bgpAgentsUsedNamesInInn[bgpAgentInn] = [];
				bgpAgentsUsedNamesInInn[bgpAgentInn].push(bgpAgentName);
			}
			bgpAgentsPromises.push(
				getComboboxItems('bgpAgent', bgpAgentInn || '', apolloClient, bgpAgentInn ? 0 : 4096),
			);
		});
	}

	let anomalyTypesPasmonPromise;
	if (routeFilterVariables?.anomalyTypes_pasmon) {
		const anomalyTypes = Array.isArray(routeFilterVariables.anomalyTypes_pasmon)
			? routeFilterVariables.anomalyTypes_pasmon.map(item => decodeURIComponent(item))
			: routeFilterVariables?.anomalyTypes_pasmon.split(',').map(item => decodeURIComponent(item));
		anomalyTypesPasmonPromise = getOldDictionary(apolloClient, 'anomaly_pasmon', anomalyTypes);
	}

	let anomalyTypesRanrPromise;
	if (routeFilterVariables?.anomalyTypes_ranr) {
		const anomalyTypes = Array.isArray(routeFilterVariables.anomalyTypes_ranr)
			? routeFilterVariables.anomalyTypes_ranr.map(item => decodeURIComponent(item))
			: routeFilterVariables?.anomalyTypes_ranr.split(',').map(item => decodeURIComponent(item));
		anomalyTypesRanrPromise = getOldDictionary(apolloClient, 'anomaly_ranr', anomalyTypes);
	}

	let anomalyTypesAsbiPromise;
	if (routeFilterVariables?.anomalyTypes_asbi) {
		const anomalyTypes = Array.isArray(routeFilterVariables.anomalyTypes_asbi)
			? routeFilterVariables.anomalyTypes_asbi.map(item => decodeURIComponent(item))
			: routeFilterVariables?.anomalyTypes_asbi.split(',').map(item => decodeURIComponent(item));
		anomalyTypesAsbiPromise = getOldDictionary(apolloClient, 'anomaly_asbi', anomalyTypes);
	}

	let anomalyTypesNsdiPromise;
	if (routeFilterVariables?.anomalyTypes_nsdi) {
		const anomalyTypes = Array.isArray(routeFilterVariables.anomalyTypes_nsdi)
			? routeFilterVariables.anomalyTypes_nsdi.map(item => decodeURIComponent(item))
			: routeFilterVariables?.anomalyTypes_nsdi.split(',').map(item => decodeURIComponent(item));
		anomalyTypesNsdiPromise = getOldDictionary(apolloClient, 'anomaly_nsdi', anomalyTypes);
	}

	const asSrcPromises: any[] = [];
	if (routeFilterVariables?.asSrc) {
		const ass = Array.isArray(routeFilterVariables.asSrc)
			? routeFilterVariables.asSrc
			: routeFilterVariables.asSrc.split(',');
		ass.forEach(as => {
			asSrcPromises.push(getComboboxItems('objectsAs', as || '', apolloClient, as ? 0 : 4096));
		});
	}

	const asDstPromises: any[] = [];
	if (routeFilterVariables?.asDst) {
		const ass = Array.isArray(routeFilterVariables.asDst)
			? routeFilterVariables.asDst
			: routeFilterVariables.asDst.split(',');
		ass.forEach(as => {
			asDstPromises.push(getComboboxItems('objectsAs', as || '', apolloClient, as ? 0 : 4096));
		});
	}

	const [loading, setLoading] = useState(true);

	const capitalize = useCallback((val: string | undefined) => {
		if (!val) return '';
		return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
	}, []);

	useEffect(() => {
		if (formikBag && routeFilterVariables) {
			Promise.all([
				anomalySourcePromise(),
				...bgpOperatorsPromises.map(promise => promise()),
				...bgpAgentsPromises.map(promise => promise()),
				anomalyTypesPasmonPromise ? anomalyTypesPasmonPromise() : undefined,
				anomalyTypesRanrPromise ? anomalyTypesRanrPromise() : undefined,
				anomalyTypesAsbiPromise ? anomalyTypesAsbiPromise() : undefined,
				anomalyTypesNsdiPromise ? anomalyTypesNsdiPromise() : undefined,
				...asSrcPromises.map(promise => promise()),
				...asDstPromises.map(promise => promise()),
			]).then(result => {
				setLoading(false);

				formikBag.setFieldValue('anomalySource', result[0]?.key || '');

				let startIndex = 1;
				let stopIndex = startIndex + bgpOperatorsPromises.length;
				const tmpBgpOpers: BgpOperator[] = [];
				for (let i = startIndex; i < stopIndex; i++) {
					if (result[i] && result[i][0]) tmpBgpOpers.push(result[i][0]);
				}
				if (tmpBgpOpers.length) formikBag.setFieldValue('bgpOperators', tmpBgpOpers);

				startIndex = stopIndex;
				stopIndex = startIndex + bgpAgentsPromises.length;
				const tmpBgpAgents: BgpAgent[] = [];
				for (let i = startIndex; i < stopIndex; i++) {
					const filtered = result[i].filter(
						item =>
							item?.org_inn &&
							item?.name &&
							bgpAgentsUsedNamesInInn[item.org_inn] &&
							bgpAgentsUsedNamesInInn[item.org_inn].includes(item.name),
					);
					if (filtered.length) tmpBgpAgents.push(...filtered);
				}
				if (tmpBgpAgents.length) formikBag.setFieldValue('bgpAgents', tmpBgpAgents);

				startIndex = stopIndex;
				if (anomalyTypesPasmonPromise) formikBag.setFieldValue('anomalyTypes_pasmon', result[startIndex]);
				startIndex++;
				if (anomalyTypesRanrPromise) formikBag.setFieldValue('anomalyTypes_ranr', result[startIndex]);
				startIndex++;
				if (anomalyTypesAsbiPromise) formikBag.setFieldValue('anomalyTypes_asbi', result[startIndex]);
				startIndex++;
				if (anomalyTypesNsdiPromise) formikBag.setFieldValue('anomalyTypes_nsdi', result[startIndex]);

				startIndex++;
				stopIndex = startIndex + asSrcPromises.length;
				const tmpAsSrc: AsObject[] = [];
				for (let i = startIndex; i < stopIndex; i++) {
					if (result[i] && result[i][0]) tmpAsSrc.push(result[i][0]);
				}
				if (tmpAsSrc.length) formikBag.setFieldValue('asSrc', tmpAsSrc);

				startIndex = stopIndex;
				stopIndex = startIndex + asDstPromises.length;
				const tmpAsDst: AsObject[] = [];
				for (let i = startIndex; i < stopIndex; i++) {
					if (result[i] && result[i][0]) tmpAsDst.push(result[i][0]);
				}
				if (tmpAsDst.length) formikBag.setFieldValue('asDst', tmpAsDst);

				if (routeFilterVariables.anomalyOpenState) {
					formikBag.setFieldValue(
						'anomalyOpenState',
						routeFilterVariables.anomalyOpenState.toLowerCase().indexOf('open') !== -1
							? AnomalyOpenState.Opened
							: routeFilterVariables.anomalyOpenState.toLowerCase().indexOf('close') !== -1
							? AnomalyOpenState.Closed
							: '',
					);
				} else formikBag.setFieldValue('anomalyOpenState', '');

				if (
					routeFilterVariables.periodOpened &&
					Object.keys(PredefinedPeriod).includes(capitalize(routeFilterVariables.periodOpened))
				) {
					setTimeout(() => {
						// через setTimeout - иначе может быть установлено другое значение при срабатывании эффекта на изменение anomalyOpenState
						formikBag.setFieldValue('periodOpened', capitalize(routeFilterVariables.periodOpened));
					}, 0);
				} else {
					setTimeout(() => {
						// через setTimeout - иначе может быть установлено другое значение при срабатывании эффекта на изменение anomalyOpenState
						formikBag.setFieldValue('periodOpened', 'Notset');
					}, 0);
				}

				if (
					routeFilterVariables.periodClosed &&
					Object.keys(PredefinedPeriod).includes(capitalize(routeFilterVariables.periodClosed))
				) {
					formikBag.setFieldValue('periodClosed', capitalize(routeFilterVariables.periodClosed));
				} else {
					formikBag.setFieldValue('periodClosed', 'Notset');
				}

				if (routeFilterVariables.openedFromDt)
					formikBag.setFieldValue('openedFromDt', decodeURIComponent(routeFilterVariables.openedFromDt));
				if (routeFilterVariables.openedToDt)
					formikBag.setFieldValue('openedToDt', decodeURIComponent(routeFilterVariables.openedToDt));
				if (routeFilterVariables.closedFromDt)
					formikBag.setFieldValue('closedFromDt', decodeURIComponent(routeFilterVariables.closedFromDt));
				if (routeFilterVariables.closedToDt)
					formikBag.setFieldValue('closedToDt', decodeURIComponent(routeFilterVariables.closedToDt));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!formikBag || !routeFilterVariables) return [LoadFiltersByRoute.NoRouteFilters];

	return [loading ? LoadFiltersByRoute.InProgress : LoadFiltersByRoute.Completed];
}

function getConfigurationGroup(
	apolloClient: ApolloClient<object>,
	keyOrValue?: string,
): () => Promise<{key: string; value: string} | undefined> {
	return () => {
		return new Promise<{key: string; value: string} | undefined>(resolve => {
			if (keyOrValue) {
				apolloClient
					.query<GetConfigurationGroupQuery, GetConfigurationGroupQueryVariables>({
						query: GetConfigurationGroupDocument,
						variables: {},
					})
					.then(result => {
						const foundItem = result.data.getConfigurationGroup.filter(
							item => item.key === keyOrValue || item.value.toLowerCase() === keyOrValue.toLowerCase(),
						);
						resolve(foundItem.length ? foundItem[0] : undefined);
					});
			} else {
				resolve(undefined);
			}
		});
	};
}

export function getOldDictionary(
	apolloClient: ApolloClient<object>,
	type: string,
	keyOrValueSet?: string[],
): () => Promise<{id: string; key: string; value: string}[] | undefined> {
	return () => {
		return new Promise<{id: string; key: string; value: string}[] | undefined>(resolve => {
			if (keyOrValueSet) {
				apolloClient
					.query<OldDictionaryQuery, OldDictionaryQueryVariables>({
						query: OldDictionaryDocument,
						variables: {
							id: `events.${type}`,
						},
					})
					.then(result => {
						const keyOrValueSetLo = keyOrValueSet.map(item => item.toLowerCase());
						const foundItem = result.data.oldDictionary
							.filter(
								item =>
									keyOrValueSetLo.includes(item.key.toString()) ||
									keyOrValueSetLo.includes(item.value.toLowerCase()),
							)
							.map(item => ({id: item.key.toString(), key: item.key.toString(), value: item.value}));
						resolve(foundItem.length ? foundItem : undefined);
					});
			} else {
				resolve(undefined);
			}
		});
	};
}
