import React, {useCallback, useMemo} from 'react';
import TimeListWrapper from './TimeListWrapper';
import css from './Calendar.module.module.css';
import {useConst} from '../../hooks/useConst';
import {resetDate} from 'ts-date/locale/ru';

type Props = {
	value: Date | null;
	onChange: (value: Date) => void;
	seconds?: boolean;
	height: number;
	scrollbarWidth: number;
	minTime?: Date;
	maxTime?: Date;
};

type TimeLimit = {
	hours?: number;
	minutes?: number;
	seconds?: number;
};

const TimeCalendar: React.FC<Props> = ({value, onChange, seconds, scrollbarWidth, height, minTime, maxTime}) => {
	const listOfHours = useConst(() => Array.from({length: 24}, (_v, i) => i));
	const listOfMinutes = useConst(() => Array.from({length: 60}, (_v, i) => i));
	const currentTime = value || new Date();

	const {min, max} = useMemo(() => {
		const currentDate = value && resetDate(value);

		if (!currentDate) return {};

		const minDate = minTime && resetDate(minTime);
		const maxDate = maxTime && resetDate(maxTime);

		if (
			(minDate && currentDate.getTime() !== minDate.getTime()) ||
			(maxDate && currentDate.getTime() !== maxDate.getTime())
		)
			return {};

		const min: TimeLimit = {};
		const max: TimeLimit = {};
		if (minTime) {
			min.hours = minTime.getHours();
			if (currentTime.getHours() === min.hours) {
				min.minutes = minTime.getMinutes();
				if (currentTime.getMinutes() === min.minutes) {
					min.seconds = minTime.getSeconds();
				}
			}
		}

		if (maxTime) {
			max.hours = maxTime.getHours();
			if (currentTime.getHours() === max.hours) {
				max.minutes = maxTime.getMinutes();
				if (currentTime.getMinutes() === max.minutes) {
					max.seconds = maxTime.getSeconds();
				}
			}
		}
		return {min, max};
	}, [currentTime, minTime, maxTime]);

	const handleChangeHours = useCallback(
		(num: number) => {
			let newDate = new Date(currentTime);
			newDate.setHours(num);
			if (minTime && newDate < minTime) newDate = minTime;
			if (maxTime && newDate > maxTime) newDate = maxTime;
			onChange(newDate);
		},
		[currentTime, minTime, maxTime],
	);

	const handleChangeMinutes = useCallback(
		(num: number) => {
			let newDate = new Date(currentTime);
			newDate.setMinutes(num);
			if (minTime && newDate < minTime) newDate = minTime;
			if (maxTime && newDate > maxTime) newDate = maxTime;
			onChange(newDate);
		},
		[currentTime, minTime, maxTime],
	);

	const handleChangeSeconds = useCallback(
		(num: number) => {
			let newDate = new Date(currentTime);
			newDate.setSeconds(num);
			if (minTime && newDate < minTime) newDate = minTime;
			if (maxTime && newDate > maxTime) newDate = maxTime;
			onChange(newDate);
		},
		[currentTime, minTime, maxTime],
	);

	return (
		<div className={css.timeWrapper}>
			<div style={{height: height / 7}} className={css.timeHeader}>
				Время
			</div>
			<div className={css.timeWrapperOfLists} style={{height}}>
				<TimeListWrapper
					rowHeight={height / 7}
					scrollbarWidth={scrollbarWidth}
					selected={currentTime.getHours()}
					onChange={handleChangeHours}
					values={listOfHours}
					min={min?.hours}
					max={max?.hours}
				/>
				<div className={css.timeSeparator}>:</div>
				<TimeListWrapper
					rowHeight={height / 7}
					scrollbarWidth={scrollbarWidth}
					selected={currentTime.getMinutes()}
					onChange={handleChangeMinutes}
					values={listOfMinutes}
					min={min?.minutes}
					max={max?.minutes}
				/>
				{seconds && (
					<>
						<div className={css.timeSeparator}>:</div>
						<TimeListWrapper
							rowHeight={height / 7}
							selected={currentTime.getSeconds()}
							scrollbarWidth={scrollbarWidth}
							onChange={handleChangeSeconds}
							values={listOfMinutes}
							min={min?.seconds}
							max={max?.seconds}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default TimeCalendar;
