import React from 'react';
import {default as OriginalMarkdownToJSX, MarkdownToJSX} from 'markdown-to-jsx';

type Props = {
	markdown: string;
	options?: MarkdownToJSX.Options;
};

const Markdown: React.FC<Props> = ({markdown, options}) => {
	return <OriginalMarkdownToJSX options={options}>{markdown}</OriginalMarkdownToJSX>;
};

export default Markdown;
