import React, {useCallback, useState} from 'react';
import css from './ArrayInput.module.css';
import fieldCss from '../Field/Field.module.css';
import {CommonInputInterface} from '../CommonInputInterface';
import cls from '../../../utils/cls';
import Button from '../Button/Button';
import {CrossIcon} from 'components/SvgIcon';
import {removeArrayElementByIndex} from '../../../utils/array-utils';
import {TableColumnType, TableColumnValidation} from '../../../queries-generated/types';
import validateColumn from '../../FullTable/validateColumn';
import Input from '../Input/Input';

interface InputInterface extends CommonInputInterface<string[]> {
	setRef?: React.RefObject<HTMLInputElement>;
	validation?: TableColumnValidation;
	columnType: TableColumnType;
}

export type InputProps = InputInterface & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const ArrayInput: React.FC<InputProps> = props => {
	const {
		error: propsError,
		disabled,
		validation,
		className,
		bottomText,
		helperText,
		columnType,
		setRef,
		value,
		onChange,
		...inputProps
	} = props;
	const [input, setInput] = useState('');
	const [error, setError] = useState<string>();

	const handleChange = useCallback(
		(value: string) => {
			setInput(value.trim());
			if (validation && validateColumn(value.trim(), columnType, validation.regExp)) {
				setError(undefined);
			} else if (validation) {
				setError(validation.errorText);
			}
		},
		[onChange],
	);

	const handleSubmit = useCallback(() => {
		if (input && ((validation && validateColumn(input, columnType, validation.regExp)) || !validation)) {
			onChange([...((value as string[]) || []), input]);
			setInput('');
			setError(undefined);
		}
	}, [input, onChange]);

	const handleDelete = useCallback(
		index => {
			if (value) onChange(removeArrayElementByIndex(value as string[], index));
		},
		[value, onChange],
	);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter') {
				e.preventDefault();
				handleSubmit();
			}
		},
		[handleSubmit],
	);

	return (
		<div className={cls(className, css.wrapper)}>
			<ul className={css.items}>
				{value &&
					(value as string[]).map((item, index) => {
						return (
							<li key={item + index}>
								{item}
								<Button type={'button'} action iconOnly onClick={() => handleDelete(index)}>
									<CrossIcon />
								</Button>
							</li>
						);
					})}
				{(!value || !(value as string[]).length) && (
					<p>Тут пока ничего нет. Введите что-нибудь в строку ниже, что бы добавить запись.</p>
				)}
			</ul>
			<div className={css.inputWrapper}>
				<Input
					disabled={disabled}
					{...(inputProps as any)}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					ref={setRef}
					value={input || ''}
					autoComplete={'off'}
					underlined
				/>
				<Button type={'button'} onClick={handleSubmit} secondary>
					Добавить
				</Button>
			</div>
			{!!error && <div className={fieldCss.error}>{error}</div>}
		</div>
	);
};

export default ArrayInput;
