import React from 'react';
import DashboardField from './DashboardField';
import {groupArrayBy} from '../../../../../../utils/array-utils';
import DashboardFieldsGroup from './DashboardFieldsGroup';
import {Field} from '../../../../../../queries-generated/types';
import {FieldsProps} from '../../../components/helper';

type Props = {
	fields: Array<Field & {name: string}>;
	userFilters?: boolean;
	linkedFilters?: boolean;
	fieldsProps?: FieldsProps;
	disableGroups?: boolean;
};

const DashboardFields: React.FC<Props> = ({fields, userFilters, linkedFilters, fieldsProps, disableGroups}) => {
	const groups = !disableGroups && groupArrayBy(fields, field => field.groupTitle || 'noGroup');

	return (
		<>
			{((!disableGroups && groups ? groups?.noGroup : fields) || []).map(field => (
				<DashboardField
					key={field.id}
					field={field}
					linkedFilters={linkedFilters}
					userFilters={userFilters}
					fieldProps={fieldsProps && fieldsProps[field.id]}
				/>
			))}

			{!disableGroups &&
				Object.keys(groups).map(groupTitle => {
					if (groupTitle === 'noGroup') return null;

					return (
						<DashboardFieldsGroup
							key={groupTitle}
							fields={groups[groupTitle]}
							groupTitle={groupTitle}
							userFilters={userFilters}
							linkedFilters={linkedFilters}
							fieldsProps={fieldsProps}
						/>
					);
				})}
		</>
	);
};

export default DashboardFields;
