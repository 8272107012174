import React from 'react';
import {Table} from '@material-ui/core';
import css from './ResourceAttachmentsTable.module.css';
import ResourceAttachmentsTableBody from './ResourceAttachmentsTableBody';
import ResourceAttachmentsTableHead from './ResourceAttachmentsTableHead';
import {ApolloError} from '@apollo/client';
import {ResourceAttachmentsTable as ResourceAttachmentsTableType} from '../../../queries-generated/types';
import ProgressRing from '../../../components/controlls/Loader/ProgressRing';
import ErrorSnippet from '../../../components/ErrorSnippet/ErrorSnippet';

type Props = {
	onDelete: (id: any) => Promise<void>;
	refetch: () => void;
	tableData?: ResourceAttachmentsTableType;
	error?: ApolloError;
	loading?: boolean;
	pageId?: string;
};

const ResourceAttachmentsTable: React.FC<Props> = ({tableData, error, refetch, loading, onDelete, pageId}) => {
	return (
		<div className={css.tableContainer}>
			{error && <ErrorSnippet error={error} refetch={refetch} />}
			{loading && (
				<div className={css.loadingBlock}>
					<ProgressRing />
				</div>
			)}
			<Table size={'small'} stickyHeader>
				{!loading && !error && tableData?.columns && tableData.columns.length > 0 && (
					<>
						<ResourceAttachmentsTableHead tableHead={tableData.columns} />
						<ResourceAttachmentsTableBody
							pageId={pageId}
							onDelete={onDelete}
							tableItems={tableData.items}
							columnsLength={tableData.columns.length + 1}
						/>
					</>
				)}
			</Table>
		</div>
	);
};

export default ResourceAttachmentsTable;
