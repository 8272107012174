import React, {ReactNode, useCallback, useState} from 'react';
import css from './PopoverContent.css';
import {
	RefGraphPopoverValueDocument,
	RefGraphPopoverValueQuery,
	RefGraphPopoverValueQueryVariables,
} from '../../../../../queries-generated/types';
import SafeLink from '../../../../SafeLink/SafeLink';
import {GraphPopoverContent, PopoverContentType} from './graphHelper';
import {useApolloClient} from '@apollo/client';
import {RefreshIcon} from '../../../../SvgIcon';
import cls from '../../../../../utils/cls';
import CustomErrorPopup from '../../../../controlls/CustomErrorPopup/CustomErrorPopup';
import CreateModelForm from './CreateModelForm';

type Props = {
	popoverContent?: GraphPopoverContent[];
	nodeFromId: string;
	nodeToId: string;
	currentHistoryDate?: string;
};

const resolveType = (item: GraphPopoverContent): ReactNode => {
	switch (item.type) {
		case PopoverContentType.STRING:
			return item.title ? item.title + '' : '';
		case PopoverContentType.NUMBER:
			return parseInt(item + '', 10);
		case PopoverContentType.NAVIGATION_ROUTE: {
			const route = item.httpLink?.route;
			const params = item.httpLink?.params;
			const target = item.httpLink?.target ?? '_self';
			return route ? (
				<SafeLink route={route} params={params} target={target}>
					{item.title}
				</SafeLink>
			) : null;
		}
		case PopoverContentType.NAVIGATION_URL: {
			const route = item.httpLink?.route;
			const target = item.httpLink?.target ?? '_self';
			return route ? (
				<a href={route} target={target}>
					{item.title}
				</a>
			) : null;
		}
		case PopoverContentType.CREATE_MODEL_POPOVER: {
			if (!item.createModelPopover?.model) return null;
			return <CreateModelForm content={item} />;
		}
		default:
			return item.toString();
	}
};

const PopoverContent: React.FC<Props> = ({popoverContent, currentHistoryDate, nodeFromId, nodeToId}) => {
	return (
		<div className={css.container}>
			{popoverContent && (
				<ul className={css.list}>
					{popoverContent.map((item, index) => {
						return (
							<li key={index}>
								{item.ref ? (
									<ItemWithRef
										item={item}
										nodeFromId={nodeFromId}
										nodeToId={nodeToId}
										currentHistoryDate={currentHistoryDate}
									/>
								) : item.key ? (
									`${item.key}: ${resolveType(item)}`
								) : (
									resolveType(item)
								)}
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

type ItemWithRefProps = {
	item: GraphPopoverContent;
	nodeFromId: string;
	nodeToId: string;
	currentHistoryDate?: string;
};

const ItemWithRef: React.FC<ItemWithRefProps> = ({item, currentHistoryDate, nodeFromId, nodeToId}) => {
	const apolloClient = useApolloClient();
	const [currentValue, setCurrentValue] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<any>();

	const handleSync = useCallback(async () => {
		setLoading(true);
		try {
			const {data, error} = await apolloClient.query<
				RefGraphPopoverValueQuery,
				RefGraphPopoverValueQueryVariables
			>({
				query: RefGraphPopoverValueDocument,
				variables: {refType: item.ref!, nodeToId, nodeFromId, currentHistoryDate},
			});
			if (error) {
				setError(error);
			} else {
				setCurrentValue(data.refGraphPopoverValue);
			}
		} catch (e) {
			setError(e);
		}
		setLoading(false);
	}, []);

	const result = apolloClient.readQuery<RefGraphPopoverValueQuery, RefGraphPopoverValueQueryVariables>({
		query: RefGraphPopoverValueDocument,
		variables: {refType: item.ref!, nodeToId, nodeFromId, currentHistoryDate},
	});

	const value = (result && result.refGraphPopoverValue) || currentValue || item.title;
	return (
		<>
			<CustomErrorPopup error={error} />
			{item.key}: <RefreshIcon onClick={handleSync} className={cls(css.sync, loading && css.syncLoading)} />{' '}
			{resolveType({...item, title: value})}
		</>
	);
};

export default PopoverContent;
