import React from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {viewItemToField} from '../FormComponent/FormComponent';
import DashboardField from '../../ViewEdit/constructor/form/DashboardField';

type Props = {
	viewItem: ViewItem;
};

const InputComponent: React.FC<Props> = ({viewItem}) => {
	const field = viewItemToField(viewItem);
	return field ? <DashboardField field={{...field, name: field.id}} /> : null;
};

export default InputComponent;
