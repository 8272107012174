import React from 'react';
import {TableCell, TableHead, TableRow} from '@material-ui/core';
import {ResourceAttachmentsTableColumns} from '../../../queries-generated/types';

type Props = {
	tableHead: ResourceAttachmentsTableColumns[];
};

const ResourceAttachmentsTableHead: React.FC<Props> = ({tableHead}) => {
	return (
		<TableHead>
			<TableRow>
				{[...tableHead]
					.sort((a, b) => a.order - b.order)
					.map(header => (
						<TableCell key={header.name}>{header.name}</TableCell>
					))}
				<TableCell>Действия</TableCell>
			</TableRow>
		</TableHead>
	);
};

export default ResourceAttachmentsTableHead;
