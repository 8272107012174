import React from 'react';

export const useBeforeUnload = (trigger: boolean | null | undefined) => {
	const cb = React.useRef(e => {
		e.preventDefault();
		e.returnValue = '';
	});

	React.useEffect(() => {
		const onUnload = cb.current;
		if (trigger) {
			window.addEventListener('beforeunload', onUnload);
		} else {
			window.removeEventListener('beforeunload', onUnload);
		}
		return () => window.removeEventListener('beforeunload', onUnload);
	}, [trigger]);
};
