import React from 'react';
import css from './SettingsFilesPage.module.css';
import {formatBytes} from '../../utils/number-utils';

type Props = {
	removeFile: () => void;
	mutationErrorData?: {message: string; file: File};
	loadingFileErrorData?: File;
};

const ErrorFileSnippet: React.FC<Props> = ({removeFile, mutationErrorData, loadingFileErrorData}) => {
	return (
		<div onClick={removeFile} className={css.messageWrapper}>
			<div className={css.messageText}>
				<span className={css.errorMessage}>Ошибка при загрузке файла </span>
				<b title={loadingFileErrorData?.name || mutationErrorData?.file.name}>
					[
					{mutationErrorData
						? formatBytes(mutationErrorData?.file.size || 0)
						: formatBytes(loadingFileErrorData?.size || 0)}
					] {loadingFileErrorData?.name || mutationErrorData?.file.name}
				</b>
			</div>
			<div className={css.close} />
		</div>
	);
};

export default ErrorFileSnippet;
