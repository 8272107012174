import React from 'react';
import {useContextHelpQuery} from '../../queries-generated/types';
import Markdown from '../Markdown/Markdown';
import ErrorSnippet from '../ErrorSnippet/ErrorSnippet';
import ProgressRing from '../controlls/Loader/ProgressRing';

type Props = {
	contextHelpKey?: string;
};

const ContextHelp: React.FC<Props> = ({contextHelpKey}) => {
	const {data, loading, error, refetch} = useContextHelpQuery({
		variables: {key: contextHelpKey || ''},
		skip: !contextHelpKey,
		notifyOnNetworkStatusChange: true,
	});

	return (
		<>
			{loading ? (
				<ProgressRing />
			) : error ? (
				<ErrorSnippet error={error} errorHeader={'Ошибка загрузки справочной информации'} refetch={refetch} />
			) : data?.contextHelp.value ? (
				<Markdown markdown={data.contextHelp.value} />
			) : (
				'Справочные данные отсутствуют'
			)}
		</>
	);
};

export default ContextHelp;
