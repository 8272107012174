import React, {useEffect, useRef, useState} from 'react';
import ModalPopup from '../../controlls/ModalPopup/ModalPopup';
import Button from '../../pirsInputs/Button/Button';
import css from './useConfirm.module.css';

type HookProps = {
	header?: string;
	message?: string;
	cancelButtonTitle?: string;
	confirmButtonTitle?: string;
	onCancel?: () => void;
	onConfirm?: () => void;
	focus?: 'confirm' | 'cancel';
	modalContent?: React.ReactNode;
};

type ComponentProps = HookProps & {
	open: boolean;
};

const Confirm: React.FC<ComponentProps> = ({
	header,
	message,
	cancelButtonTitle,
	confirmButtonTitle,
	onConfirm,
	onCancel,
	focus,
	open,
	modalContent,
}) => {
	const refConfirmButton = useRef<HTMLButtonElement>(null);
	const refCancelButton = useRef<HTMLButtonElement>(null);
	useEffect(() => {
		if (open) {
			if (focus === 'confirm') refConfirmButton.current?.focus();
			/* default: focus === 'cancel' or undefined */ else refCancelButton.current?.focus();
		}
	}, [open, focus]);
	return (
		<ModalPopup open={open} header={header} onClose={onCancel} maxWidth={480}>
			{message && <div className={css.message}>{message}</div>}
			{modalContent && <div className={css.content}>{modalContent}</div>}
			<ul className={css.buttons}>
				<li>
					<Button onClick={onConfirm} setRef={refConfirmButton}>
						{confirmButtonTitle || 'Продолжить'}
					</Button>
				</li>
				<li>
					<Button onClick={onCancel} setRef={refCancelButton} secondary>
						{cancelButtonTitle || 'Отмена'}
					</Button>
				</li>
			</ul>
		</ModalPopup>
	);
};

const useConfirm = ({
	header,
	message,
	onConfirm,
	onCancel,
	cancelButtonTitle,
	confirmButtonTitle,
	focus,
	modalContent,
}: HookProps) => {
	const [open, setOpen] = useState(false);
	return {
		showConfirm: () => setOpen(true),
		hideConfirm: () => setOpen(false),
		openConfirm: (open: boolean) => setOpen(open),
		ConfirmComponent: (
			<Confirm
				open={open}
				header={header}
				message={message}
				cancelButtonTitle={cancelButtonTitle}
				confirmButtonTitle={confirmButtonTitle}
				onConfirm={() => {
					setOpen(false);
					if (onConfirm) onConfirm();
				}}
				onCancel={() => {
					setOpen(false);
					if (onCancel) onCancel();
				}}
				focus={focus}
				modalContent={modalContent}
			/>
		),
	};
};

export default useConfirm;
