import React, {useMemo} from 'react';
import {
	ConversationsDocument,
	ConversationsQueryResult,
	useAddConversationMessageMutation,
	useConversationsQuery,
} from '../../queries-generated/types';
import ErrorSnippet from '../ErrorSnippet/ErrorSnippet';
import Message from './Message';
import {Form, FormikErrors, FormikProvider, useFormik} from 'formik';
import MessageTextArea, {Values} from './MessageTextArea';
import css from './Conversations.modules.css';
import MessageShimmer from './MessageShimmer';
import {RefreshIcon, SendIcon} from '../SvgIcon';
import Button from '../pirsInputs/Button/Button';

type ConversationsValue = {
	size: string;
	conversationId: string;
};

type Props = {
	value: ConversationsValue;
	dataSourceId: string;
	itemId: string;
	fieldId: string;
};

const Conversations: React.FC<Props> = ({fieldId, itemId, dataSourceId, value}) => {
	const {conversationId} = value;
	const {data, loading, error, refetch} = useConversationsQuery({
		variables: {
			conversationId,
			itemId,
			dataSourceId,
			fieldId,
		},
		notifyOnNetworkStatusChange: true,
	});

	const [addMessage, {loading: addLoading, error: addError}] = useAddConversationMessageMutation();

	const formikBag = useFormik<Values>({
		initialValues: {
			text: '',
		},
		validate: value => {
			const errors: FormikErrors<Values> = {};
			if (!value.text.trim()) errors.text = 'Сначала напишите текст';
			return errors;
		},
		onSubmit: async values => {
			await addMessage({
				variables: {conversationId, itemId, dataSourceId, fieldId, text: values.text},
				update: (cache, {data: newData}) => {
					const data = cache.readQuery({
						query: ConversationsDocument,
						variables: {
							conversationId,
							itemId,
							dataSourceId,
							fieldId,
						},
					}) as ConversationsQueryResult['data'];
					if (data?.conversations) {
						cache.writeQuery({
							query: ConversationsDocument,
							data: {
								...data,
								conversations: [newData?.addConversationMessage, ...data.conversations],
							},
							variables: {
								conversationId,
								itemId,
								dataSourceId,
								fieldId,
							},
						});
					}
				},
			});
			formikBag.resetForm();
		},
	});

	const messages = useMemo(() => {
		return [...(data?.conversations || [])].sort((m1, m2) => (m1.createdAt > m2.createdAt ? -1 : 1));
	}, [data?.conversations]);

	return (
		<div className={css.messages}>
			<FormikProvider value={formikBag}>
				<Form>
					<MessageTextArea formikBag={formikBag} error={addError} />
					<div className={css.buttons}>
						<Button type="button" action iconOnly disabled={loading} onClick={refetch} title={'Обновить'}>
							<RefreshIcon />
						</Button>
						<div className={css.separator} />
						<Button
							action
							iconOnly
							type={'submit'}
							loading={addLoading}
							disabled={!!formikBag.errors.text || !formikBag.values.text}
							title={'Отправить (Enter)'}
						>
							<SendIcon />
						</Button>
					</div>
				</Form>
			</FormikProvider>
			{error ? (
				<ErrorSnippet error={error} errorHeader={'Ошибка загрузки данных'} refetch={refetch} />
			) : loading ? (
				<MessageShimmer />
			) : (
				messages.map(message => <Message message={message} key={message.id} />)
			)}
		</div>
	);
};

export default Conversations;
