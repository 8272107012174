import {ActionsUnion, createAction} from '../../../redux/helper';

export enum ResourceRegisterUploadFileStatus {
	QUEUE = 'QUEUE',
	LOADING = 'LOADING',
	LOADED = 'LOADED',
	ERROR = 'ERROR',
}

export type ResourceRegisterUploadFileData = {
	id: string;
	pageId: string;
	url: string;
	file: File;
	status: ResourceRegisterUploadFileStatus;
	error?: string;
	loadingProgress?: number;
	requestInstance?: XMLHttpRequest;
};

export type ResourceRegisterUploadFileState = {
	fileUploadQueueArray: ResourceRegisterUploadFileData[];
};

export const resourceRegisterUploadFileInitialState: ResourceRegisterUploadFileState = {
	fileUploadQueueArray: [],
};

enum ResourceRegisterActionType {
	ADD_FILES = 'ADD_FILES',
	SET_FILE_STATUS = 'SET_FILE_STATUS',
	REMOVE_FILES = 'REMOVE_FILES',
	SET_LOADING_PROGRESS = 'SET_LOADING_PROGRESS',
	SET_REQUEST_INSTANCE = 'SET_REQUEST_INSTANCE',
}

export const resourceRegisterUploadFileActions = {
	addFiles: (
		newFile: {
			url: string;
			pageId: string;
			file: File;
		}[],
	) => createAction(ResourceRegisterActionType.ADD_FILES, newFile),
	setFileStatus: (fileData: {id: string; fileStatus: ResourceRegisterUploadFileStatus; error?: string}) =>
		createAction(ResourceRegisterActionType.SET_FILE_STATUS, fileData),
	removeFiles: (id: string[]) => createAction(ResourceRegisterActionType.REMOVE_FILES, id),
	setLoadingProgress: (fileInArray: {id: string; loadingProgress: number}) =>
		createAction(ResourceRegisterActionType.SET_LOADING_PROGRESS, fileInArray),
	setRequestInstance: (data: {id: string; instance: XMLHttpRequest}) =>
		createAction(ResourceRegisterActionType.SET_REQUEST_INSTANCE, data),
};

export function resourceRegisterUploadFileReducer(
	state: ResourceRegisterUploadFileState,
	action: ActionsUnion<typeof resourceRegisterUploadFileActions>,
): ResourceRegisterUploadFileState {
	switch (action.type) {
		case ResourceRegisterActionType.ADD_FILES:
			const newFiles = action.payload.map(fileObj => {
				return {
					...fileObj,
					id: `f${(~~(Math.random() * 1e8)).toString(16)}`,
					error: undefined,
					status: ResourceRegisterUploadFileStatus.QUEUE,
				};
			});
			return {
				...state,
				fileUploadQueueArray: [...state.fileUploadQueueArray, ...newFiles],
			};
		case ResourceRegisterActionType.REMOVE_FILES:
			const newFileList = state.fileUploadQueueArray.filter(fileObj => !action.payload.includes(fileObj.id));

			return {
				...state,
				fileUploadQueueArray: newFileList,
			};
		case ResourceRegisterActionType.SET_FILE_STATUS: {
			const newFileList = state.fileUploadQueueArray.map(obj => {
				if (obj.id === action.payload.id) {
					return {...obj, status: action.payload.fileStatus, error: action.payload.error};
				}
				return obj;
			});

			return {
				...state,
				fileUploadQueueArray: newFileList,
			};
		}
		case ResourceRegisterActionType.SET_LOADING_PROGRESS: {
			const newFileList = state.fileUploadQueueArray.map(obj => {
				if (obj.id === action.payload.id) {
					return {...obj, loadingProgress: action.payload.loadingProgress};
				}
				return {...obj};
			});

			return {
				...state,
				fileUploadQueueArray: newFileList,
			};
		}
		case ResourceRegisterActionType.SET_REQUEST_INSTANCE: {
			const newFileList = state.fileUploadQueueArray.map(obj => {
				if (obj.id === action.payload.id) {
					return {...obj, requestInstance: action.payload.instance};
				}
				return {...obj};
			});

			return {
				...state,
				fileUploadQueueArray: newFileList,
			};
		}
		default:
			return state;
	}
}
