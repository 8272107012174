import React from 'react';
import {TableCell, TableHead, TableRow, TableSortLabel} from '@material-ui/core';
import {SortOrder} from '../../queries-generated/types';
import {ResourceRegisterTableColumns} from '../../queries-generated/types';
import css from './ResourceTableHead.module.css';

type Props = {
	tableHead: ResourceRegisterTableColumns[][];
	sort?: SortOrder;
	sortBy?: string;
	handleChangeSort: (order: string) => void;
};

const ResourceTableHead: React.FC<Props> = ({tableHead, sort, sortBy, handleChangeSort}) => {
	return (
		<TableHead className={css.tableHead}>
			{tableHead.map((row, index) => (
				<TableRow key={index}>
					{row.map(col => (
						<TableCell
							style={{border: '1px solid #e0e0e0'}}
							key={col.attributePath}
							rowSpan={col.rowspan}
							colSpan={col.colspan}
						>
							{!col.isHeader ? (
								<TableSortLabel
									active={sortBy === col.attributePath}
									direction={sort === SortOrder.Asc ? 'asc' : 'desc'}
									onClick={() => handleChangeSort(col.attributePath)}
								>
									{col.caption}
								</TableSortLabel>
							) : (
								col.caption
							)}
						</TableCell>
					))}
					{index === 0 && (
						<TableCell style={{border: '1px solid #e0e0e0'}} rowSpan={tableHead.length}>
							Действия
						</TableCell>
					)}
				</TableRow>
			))}
		</TableHead>
	);
};

export default ResourceTableHead;
