import React, {useCallback} from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import Field from '../Field/Field';
import Button from '../Button/Button';
import {ContactIcon} from '../../SvgIcon';
import useToggle from '../../hooks/useToggle';
import css from './InputContacts.module.css';
import ContactPicker from './ContactPicker';
import {DataGridTree} from '../../DataGrid/useDataGridSelection';
import SelectedContactsCount from './SelectedContactsCount';

export type InputContactsValue = {
	total?: number;
	totalSelected?: number;
	tree?: DataGridTree;
};

export type InputProps = {directiveId?: string; excludeDirectoryId?: string} & CommonInputInterface<InputContactsValue>;

const InputContacts: React.FC<InputProps> = props => {
	const [open, toggleOpen] = useToggle(false);
	const {children, disabled, value, onChange, directiveId, excludeDirectoryId} = props;

	const {tree} = value || {};

	const handleChange = useCallback(
		(tree: DataGridTree) => {
			onChange({tree});
			toggleOpen();
		},
		[onChange],
	);

	return (
		<Field commonProps={props} normalLabel topLabel={true}>
			{open && (
				<ContactPicker
					tree={tree || {id: 'root', include: [], totalSelected: 0}}
					onChange={handleChange}
					onClose={toggleOpen}
					excludeDirectoryId={excludeDirectoryId}
					directiveId={directiveId}
				/>
			)}
			<div className={css.wrapper}>
				<Button type="button" secondary onClick={toggleOpen} disabled={disabled}>
					{children || (
						<>
							<ContactIcon /> Выбор
						</>
					)}
				</Button>
				<SelectedContactsCount value={value} />
			</div>
		</Field>
	);
};

export default InputContacts;
