import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
	AxisLabelsFormatterContextObject,
	chart,
	Chart as ChartClass,
	HTMLDOMElement,
	SeriesOptionsType,
} from 'highcharts';
import {columnHighchartOptions, initHighcharts, lineHighchartOptions} from './HighcharstInit';
import {addPrintListeners, getLastPreparedForPrint, removePrintListeners} from '../../utils/print';
import usePrevious from '../hooks/usePrevious';
import ProgressRing from '../controlls/Loader/ProgressRing';
import css from './HiChart.module.css';
import {CustomContextMenuActions, getExporting} from './exporting';
import {preparePrint, restoreAfterPrint} from './printTools';

export enum ChartType {
	Lines = 'Lines',
	Columns = 'Columns',
}

export type ExtSeriesOptionsType = SeriesOptionsType & {color: string};

type Props = {
	chartType?: ChartType;
	series?: Array<ExtSeriesOptionsType>;
	yAxisTitle?: string;
	onSetRedrawCallback?: (callback: () => void) => void;
	customContextMenuActions?: CustomContextMenuActions;
	toggleFullscreen?: () => void;
	fullscreen?: boolean;
	xAxisFormatter?: (params: AxisLabelsFormatterContextObject) => string | null;
	title?: string;
	subtitle?: string;
	loading?: boolean;
	error?: string;
	needRedraw?: boolean;
	setNeedRedraw?: (needRedraw: boolean) => void;
};

const HiChart: React.FC<Props> = ({
	chartType,
	series,
	yAxisTitle,
	customContextMenuActions,
	fullscreen,
	toggleFullscreen,
	xAxisFormatter,
	title,
	subtitle,
	loading,
	error,
	needRedraw,
	setNeedRedraw,
}) => {
	const divRef = useRef<HTMLDivElement>(null);
	const chartRef = useRef<ChartClass>();

	const prevFullscreen = usePrevious(fullscreen);

	useState(() => {
		initHighcharts();
	});

	useEffect(() => {
		const beforePrintHandler = () => {
			if (getLastPreparedForPrint() !== divRef.current) return;
			chartRef.current?.update({exporting: {enabled: false}});
			preparePrint(chartRef.current);
		};

		const afterPrintHandler = () => {
			if (getLastPreparedForPrint() !== divRef.current) return;
			restoreAfterPrint(chartRef.current, divRef.current || undefined);
			chartRef.current?.update({exporting: {enabled: true}});
		};

		addPrintListeners(beforePrintHandler, afterPrintHandler);

		return () => {
			removePrintListeners(beforePrintHandler, afterPrintHandler);
		};
	}, []);

	const chartCreate = useCallback(() => {
		chartRef.current = chart(
			divRef.current as HTMLDOMElement,
			chartType === ChartType.Lines
				? lineHighchartOptions({
						exporting: getExporting(divRef.current, customContextMenuActions, fullscreen, toggleFullscreen),
						yAxisTitle,
						series: series,
						xAxisFormatter,
						title,
						subtitle,
				  })
				: columnHighchartOptions({
						yAxisTitle,
						exporting: getExporting(divRef.current, customContextMenuActions, fullscreen, toggleFullscreen),
						series: series,
						xAxisFormatter,
						title,
						subtitle,
				  }),
		);
	}, [
		customContextMenuActions,
		fullscreen,
		series,
		subtitle,
		title,
		toggleFullscreen,
		xAxisFormatter,
		yAxisTitle,
		chartType,
	]);

	useEffect(() => {
		if (prevFullscreen === undefined || prevFullscreen === fullscreen) {
			if (series) chartCreate();
		} else {
			// we want animation when fullscreen changed
			chartRef.current?.destroy();
			chartRef.current = undefined;
			setTimeout(() => {
				chartCreate();
			}, 500);
		}
	}, [chartCreate, fullscreen, prevFullscreen, series]);

	useEffect(() => {
		if (needRedraw && setNeedRedraw) {
			chartRef.current?.reflow();
			setNeedRedraw(false);
		}
	}, [needRedraw, setNeedRedraw]);

	return (
		<>
			{error ? (
				<div className={css.error}>{error}</div>
			) : loading ? (
				<ProgressRing />
			) : (
				<div
					className={css.wrapper}
					ref={divRef}
					onDoubleClick={() => {
						chartRef.current?.zoomOut();
					}}
				/>
			)}
		</>
	);
};

export default HiChart;
