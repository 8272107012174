import React from 'react';

function humanJoin(arr: string[] | React.ReactNode[]) {
	if (arr.length === 0) return '';
	else if (arr.length === 1) return arr[0];
	else
		return (
			<div style={{display: 'flex', flexWrap: 'wrap'}}>
				{arr.slice(0, arr.length - 1).map((item, index) => (
					<React.Fragment key={index}>
						{item}
						{index !== arr.length - 2 && <>,&nbsp;</>}
					</React.Fragment>
				))}
				&nbsp;и&nbsp;{arr[arr.length - 1]}
			</div>
		);
}

export default humanJoin;
