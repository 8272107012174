import React, {useCallback, useState} from 'react';
import {useUpdateModelMutation} from '../../queries-generated/types';
import Toggle from '../pirsInputs/Toggle/Toggle';
import usePrevious from '../hooks/usePrevious';
import {notifyToastError} from '../toast/Toast';

type Props = {
	model: string;
	itemId: string;
	keyField: string;
	value: boolean;
};

const ToggleModelItem: React.FC<Props> = ({model, itemId, keyField, value}) => {
	const [enabled, setEnabled] = useState<boolean>(value);
	const previousEnabled = usePrevious(enabled);

	const [updateModel, {loading}] = useUpdateModelMutation();

	const handleToggle = useCallback((): void => {
		setEnabled(!enabled);
		updateModel({
			variables: {
				model,
				items: [
					{
						id: itemId,
						[keyField]: !enabled,
					},
				],
			},
		})
			.then(res => {
				if (
					!res?.data?.updateModel[0] ||
					(res?.data?.updateModel[0] && res.data.updateModel[0][keyField] === previousEnabled)
				) {
					notifyToastError('Не удалось обновить элемент');
					setEnabled(previousEnabled);
				}
			})
			.catch(() => {
				notifyToastError('Не удалось обновить элемент');
				setEnabled(previousEnabled);
			});
	}, [enabled, setEnabled, updateModel, itemId, model, keyField, previousEnabled]);

	return <Toggle label={''} checked={enabled} onChange={handleToggle} disabled={loading} />;
};

export default ToggleModelItem;
