import React, {useCallback, useEffect, useRef} from 'react';
import css from './TextArea.module.css';
import {CommonInputInterface} from '../CommonInputInterface';
import {useValue, useFocus} from '../inputHooks';
import Field from '../Field/Field';
import uid from '../../../utils/uid';
import cls from '../../../utils/cls';

interface InputInterface extends CommonInputInterface<string> {
	setRef?: React.RefObject<HTMLTextAreaElement>;
	resizable?: boolean;
	autoHeightAdjustable?: boolean;
}

export type InputProps = Omit<
	InputInterface & Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, keyof InputInterface>,
	'underlined'
>;

const TextArea: React.FC<InputProps> = props => {
	const {
		error,
		disabled,
		resizable,
		className,
		bottomText,
		autoFocus,
		label,
		helperText,
		autoHeightAdjustable,
		onChange: _,
		setRef,
		...inputProps
	} = props;
	const {hasFocus, onBlur, onFocus} = useFocus(props);
	const {value, onChange} = useValue(props);

	const id = props.id || 'Input-' + uid();

	const tmpRef = useRef<HTMLTextAreaElement>(null);
	const inputRef = setRef || tmpRef;

	const updateHeight = useCallback(() => {
		if (autoHeightAdjustable) {
			const input = inputRef.current;
			setTimeout(() => {
				if (input) {
					input.style.height = '5px';
					input.style.height = input.scrollHeight + 'px';
				}
			});
		}
	}, [autoHeightAdjustable]);

	useEffect(() => {
		updateHeight();
	}, [updateHeight]);

	useEffect(() => {
		if (autoFocus && inputRef && inputRef.current) {
			inputRef.current.focus();
			inputRef.current.selectionStart = inputRef.current.value.length;
		}
	}, [autoFocus]);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
			if (props.onKeyDown) props.onKeyDown(e);
			updateHeight();
		},
		[props.onKeyDown],
	);

	return (
		<Field
			commonProps={props}
			hasFocus={hasFocus}
			bordered
			topLabel={!!value || hasFocus}
			className={className}
			normalLabel
		>
			<textarea
				id={id}
				disabled={disabled}
				className={cls(css.input, hasFocus && css.hasFocus, resizable && css.resizable)}
				{...(inputProps as any)}
				onChange={onChange}
				onKeyDown={handleKeyDown}
				onBlur={onBlur}
				onFocus={onFocus}
				ref={inputRef}
				value={value || ''}
			/>
		</Field>
	);
};

export default TextArea;
