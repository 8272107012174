import {Options} from 'vis-network/standalone';

export const accentColor = '#06508b';
export const accentColorLight = '#009ee2';
export const accentColorLighter = '#b9e3fd';

export const defaultNodeColor = {
	background: accentColorLighter,
	//border: accentColor,
	hover: accentColorLight,
	highlight: accentColor,
};
export const defaultEdgeColor = {
	color: accentColorLight,
	hover: accentColor,
};

export const graphOptions: Options = {
	nodes: {
		shape: 'dot',
		shadow: true,
		scaling: {
			min: 10,
			max: 30,
			label: {
				min: 55,
				max: 100,
				drawThreshold: 6,
				maxVisible: 50,
			},
		},
		font: {
			size: 30,
			face: 'Tahoma',
		},
		color: defaultNodeColor,
	},
	edges: {
		smooth: true,
		font: {
			size: 30,
			align: 'middle',
		},
		hoverWidth: 5,
		color: defaultEdgeColor,
	},
	physics: {
		enabled: false,
		forceAtlas2Based: {
			gravitationalConstant: -2500,
			centralGravity: 0.05,
			springLength: 150,
			springConstant: 0.18,
		},
		maxVelocity: 120,
		solver: 'forceAtlas2Based',
		timestep: 0.35,
		stabilization: {
			iterations: 50,
			updateInterval: 10,
		},
	},
	interaction: {
		navigationButtons: false,
		keyboard: false,
		tooltipDelay: 200,
		hideEdgesOnDrag: true,
		hover: true,
		multiselect: true,
		// Чтобы не вызывалось событие selectEdge при клике на ноду
		selectConnectedEdges: false,
		// hideNodesOnDrag: true
	},
	layout: {
		randomSeed: 1,
		improvedLayout: false,
	},
};
