export const highchartColors = [
	'#0063B1',
	'#E81123',
	'#00B7C3',
	'#FF8C00',
	'#00CC6A',
	'#8E8CD8',
	'#4C4A48',
	'#10893E',
	'#F7630C',
	'#68768A',
	'#69797E',
	'#CA5010',
	'#C30052',
	'#6B69D6',
	'#038387',
	'#515C6B',
	'#4A5459',
	'#DA3B01',
	'#E3008C',
	'#8764B8',
	'#00B294',
	'#567C73',
	'#647C64',
	'#EF6950',
	'#BF0077',
	'#744DA9',
	'#018574',
	'#486860',
	'#525E54',
	'#D13438',
	'#EA005E',
	'#C239B3',
	'#2D7D9A',
	'#B146C2',
	'#498205',
	'#847545',
	'#5D5A58',
	'#FF4343',
	'#9A0089',
	'#881798',
	'#107C10',
	'#7E735F',
];

export const baseLineOptions = {
	color: '#cccccc',
	type: 'line',
	dashStyle: 'Dash',
	marker: {
		enabled: false,
	},
	showInLegend: false,
	enableMouseTracking: false,
};
