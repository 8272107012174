import React from 'react';
import css from './ResourceTableRow.module.css';
import {TableCell, TableRow} from '@material-ui/core';
import useDeleteConfirm from '../hooks/useDeleteConfirm/useDeleteConfirm';
import HighLightText from '../HighLightText/HighLightText';
import {ResourceRegisterTableItem} from '../../queries-generated/types';
import cls from '../../utils/cls';
import Button from '../pirsInputs/Button/Button';
import {AttachFileIcon, DeleteIcon, EditIcon} from '../SvgIcon';

type Props = {
	tableItem: ResourceRegisterTableItem;
	onDelete?: (id: any) => void;
	keyword?: string;
};
enum resourceStatus {
	ACCEPTED = 'ACCEPTED',
	REJECTED = 'REJECTED',
	OPEN = 'OPEN',
}
function setStatusColor(status?: string): string | undefined {
	if (!status) return;

	let className = '';

	switch (status) {
		case resourceStatus.ACCEPTED: {
			className = css.accepted;
			break;
		}
		case resourceStatus.REJECTED: {
			className = css.rejected;
			break;
		}
		case resourceStatus.OPEN: {
			className = css.open;
			break;
		}
		default: {
			return undefined;
		}
	}

	return className;
}

const ResourceTableRow: React.FC<Props> = ({tableItem, onDelete, keyword}) => {
	const {Confirm, openConfirm} = useDeleteConfirm({
		callback: () => {
			onDelete && onDelete(tableItem.id);
		},
		text: `Вывести объект из эксплуатации`,
	});

	return (
		<>
			{Confirm}
			<TableRow className={cls(setStatusColor(tableItem.reconciliationStatus), css.hoverProcessedStatus)}>
				{tableItem.values.map(cell => {
					return (
						<TableCell key={cell.attributePath}>
							<HighLightText matches={cell.matches} value={cell.value} />
						</TableCell>
					);
				})}
				<TableCell className={css.controlsCell}>
					<div className={css.controls}>
						<>
							<Button
								iconOnly
								action
								title={'Перейти к детальным сведениям ресурса'}
								route={'resourceRegisterInstance'}
								params={{type: keyword, id: tableItem.id}}
								target={'_blank'}
							>
								<EditIcon />
							</Button>

							<Button
								iconOnly
								action
								title={'Перейти к вложениям ресурса'}
								route={'resourceAttachments'}
								params={{
									keyword: keyword,
									id: tableItem.id,
									resourceName: tableItem.values[0].value,
								}}
							>
								<AttachFileIcon />
							</Button>
							{onDelete && (
								<Button
									iconOnly
									action
									title={'Удалить или вывести из эксплуатации ресурс'}
									onClick={openConfirm}
								>
									<DeleteIcon />
								</Button>
							)}
						</>
					</div>
				</TableCell>
			</TableRow>
		</>
	);
};

export default ResourceTableRow;
