import React from 'react';
import {UploadedFile} from './DashboardInputFiles';
import {getFileType, getFileTypeIcon} from '../../../utils/files';
import Progress from '../../controlls/Progress/Progress';
import css from './FilePreview.module.css';
import {CrossIcon} from '../../SvgIcon';
import Button from '../Button/Button';
import cls from '../../../utils/cls';

type Props = {
	file: Partial<UploadedFile>;
	onDelete?: (serverId: string) => void;
	downloadUrl?: string;
};

const FilePreview: React.FC<Props> = ({file, onDelete, downloadUrl}) => {
	const handleRemove = () => {
		if (onDelete && file.id) onDelete(file.id!);
	};

	const content = (
		<>
			{onDelete && (
				<Button action iconOnly className={css.delete} onClick={handleRemove}>
					<CrossIcon />
				</Button>
			)}
			<div className={css.preview}>
				{file.imageDataUrl ? (
					<img src={file.imageDataUrl} alt={file.title} />
				) : (
					getFileTypeIcon(getFileType(file.title!), 100, 100)
				)}
			</div>
			<div className={css.title}>{file.title}</div>
			{file.file && !file.uploaded && <Progress value={file.progress === 100 ? undefined : file.progress} />}
		</>
	);

	return downloadUrl ? (
		<a href={downloadUrl} download className={cls(css.wrapper, css.downloadable)}>
			{content}
		</a>
	) : (
		<div className={css.wrapper}> {content}</div>
	);
};

export default FilePreview;
