import React, {useCallback, useMemo, useState} from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import {PadRoutes} from '../PadAnomalyThresholdsPage/PadAnomalyThresholdsPage';
import {useRouter} from 'react-named-hooks-router';
import {SortOrder} from '../../queries-generated/types';
import Selector from '../../components/pirsInputs/Selector/Selector';
import css from './PadCatalogsPage.module.css';
import AsGroupsTable from './PadCatalogTables/AsGroupsTable';
import ProtocolTable from './PadCatalogTables/ProtocolTable';
import ICMPTypeTable from './PadCatalogTables/ICMPTypeTable';
import {getEnumKeyByEnumValue} from '../../utils/typeScriptUtils';
import ServiceTable from './PadCatalogTables/ServiceTable';
import ServiceProtocolGroupsTable from './PadCatalogTables/ServiceProtocolGroupsTable';
import OperatorGroupsTable from './PadCatalogTables/OperatorGroupsTable';
import PrefixGroupsTable from './PadCatalogTables/PrefixGroupsTable';
import BaseLineProfilesTable from './PadCatalogTables/BaseLineProfilesTable';

export enum Catalogs {
	AS_GROUPS = 'Группы АС',
	PROTOCOLS = 'Протоколы',
	ICMP_MESSAGE_TYPES = 'Типы ICMP-сообщений',
	TCP_UDP_PORT_SERVICES = 'Службы TCP/UDP-портов',
	SERVICE_GROUPS = 'Группы служб',
	PREFIX_GROUPS = 'Группы сетей',
	OPERATOR_GROUPS = 'Группы операторов',
	BASE_LINE_PROFILES = 'Профили базовых линий',
}

const catalogs: string[] = Object.values(Catalogs);

const PadCatalogsPage: React.FC = () => {
	const {pushRoute, routeName, routeParams} = useRouter<{
		page?: string;
		itemsPerPage?: string;
		sort?: SortOrder;
		sortBy?: string;
		catalog: string;
	}>();

	const [catalog, setCatalog] = useState<Catalogs>(Catalogs[routeParams.catalog] || Catalogs.AS_GROUPS);

	const handleResetRouterParams = useCallback(
		async (catalog: Catalogs): Promise<void> => {
			return new Promise<void>(resolve => {
				pushRoute(routeName, {
					page: undefined,
					itemsPerPage: undefined,
					sort: undefined,
					sortBy: undefined,
					catalog: getEnumKeyByEnumValue(Catalogs, catalog),
				});
				resolve();
			});
		},
		[pushRoute, routeName],
	);

	const handleChangeCatalog = useCallback(
		(item): void => {
			handleResetRouterParams(item).then(() => {
				setCatalog(item);
			});
		},
		[setCatalog, handleResetRouterParams],
	);

	const currentTable = useMemo((): {tableComponent: React.ReactNode; contextHelpKey: string} => {
		switch (catalog) {
			case Catalogs.AS_GROUPS:
				return {tableComponent: <AsGroupsTable />, contextHelpKey: 'helpPadDictionaryAsGroups'};
			case Catalogs.PROTOCOLS:
				return {tableComponent: <ProtocolTable />, contextHelpKey: 'helpPadDictionaryProtocols'};
			case Catalogs.ICMP_MESSAGE_TYPES:
				return {tableComponent: <ICMPTypeTable />, contextHelpKey: 'helpPadDictionaryIcmpTypes'};
			case Catalogs.TCP_UDP_PORT_SERVICES:
				return {tableComponent: <ServiceTable />, contextHelpKey: 'helpPadDictionaryPorts'};
			case Catalogs.SERVICE_GROUPS:
				return {
					tableComponent: <ServiceProtocolGroupsTable />,
					contextHelpKey: 'helpPadDictionaryServiceGroups',
				};
			case Catalogs.PREFIX_GROUPS:
				return {tableComponent: <PrefixGroupsTable />, contextHelpKey: 'helpPadDictionaryNetworkGroups'};
			case Catalogs.OPERATOR_GROUPS:
				return {tableComponent: <OperatorGroupsTable />, contextHelpKey: 'helpPadDictionaryOperatorGroups'};
			case Catalogs.BASE_LINE_PROFILES:
				return {tableComponent: <BaseLineProfilesTable />, contextHelpKey: 'helpPadDictionaryBaseline'};
			default:
				return {tableComponent: null, contextHelpKey: ''};
		}
	}, [catalog]);

	return (
		<MainLayout routes={PadRoutes} contextHelpKey={currentTable.contextHelpKey}>
			<div className={css.selectorBlock}>
				<div className={css.catalogSelector}>
					<Selector
						items={catalogs}
						onChange={handleChangeCatalog}
						value={catalog}
						itemToString={item => item || ''}
						label={'Список каталогов'}
					/>
				</div>
			</div>
			{currentTable.tableComponent}
		</MainLayout>
	);
};

export default PadCatalogsPage;
