import React, {useCallback} from 'react';
import {Toast} from 'react-hot-toast/dist/core/types';
import toast from 'react-hot-toast';
import {getContrastYIQ} from '../../utils/css-utils';
import {colors} from '../settings/views/components/helper';
import css from './DataSourceToast.module.css';
import Button from '../pirsInputs/Button/Button';
import {CrossIcon} from '../SvgIcon';
import {DataSourceNotification} from '../../queries-generated/types';
import ReadMore from '../ReadMore/ReadMore';

const DataSourceToastWrapper: React.FC<{toastArgs: Toast; title: string; color: number}> = ({
	toastArgs,
	children,
	color,
	title,
}) => {
	const handleDismissToast = useCallback(() => {
		toast.dismiss(toastArgs.id);
	}, [toastArgs.id]);

	const fontColor = colors[color] && getContrastYIQ(colors[color]);

	return (
		<div className={css.dataSourceToastWrapper}>
			<div className={css.toastHeader} style={{backgroundColor: colors[color]}}>
				<div className={css.toastTitle} style={{color: fontColor}}>
					{title}
				</div>
				<Button action iconOnly onClick={handleDismissToast}>
					<CrossIcon fill={fontColor} style={{fill: fontColor}} />
				</Button>
			</div>
			<div className={css.toastContent}>{children}</div>
		</div>
	);
};

export const notifyDataSource = (notifications: DataSourceNotification[] | undefined): void => {
	if (!notifications || !notifications.length) return;
	notifications.forEach(notification => {
		toast(
			args => (
				<DataSourceToastWrapper toastArgs={args} title={notification.title} color={notification.color}>
					<ReadMore text={notification.content} visibleTextLength={50} />
				</DataSourceToastWrapper>
			),
			{
				className: css.dataSourceToastBase,
				duration: 5000,
			},
		);
	});
};
