import {diffDate} from 'ts-date';

export function setDate(sourceDate: Date, year: number, month?: number, day?: number) {
	const newDate = new Date(sourceDate);
	let newDay = day;
	if (newDay === undefined && newDate.getDate() === daysInMonth(newDate.getMonth(), newDate.getFullYear())) {
		newDay = daysInMonth(month === undefined ? newDate.getMonth() : month, year);
		newDate.setDate(1);
	}
	newDate.setFullYear(year);
	if (month !== undefined) {
		newDate.setMonth(month);
	}
	if (newDay !== undefined) {
		newDate.setDate(newDay);
	}

	return newDate;
}

export function diffWeeks(dt2: Date, dt1: Date) {
	return Math.round(diffDate(dt1, dt2)! / 7);
}

export function daysInMonth(month: number, year: number) {
	return new Date(year, month + 1, 0).getDate();
}

export function getDateOfCrossWeek(week: number, fromDate: Date) {
	const tmpDate = new Date(
		Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + (week - 1) * 7),
	);
	const now = tmpDate.getDay();
	const ISOWeekStart = tmpDate;
	if (now <= 4) ISOWeekStart.setDate(tmpDate.getDate() - tmpDate.getDay() + 1);
	else ISOWeekStart.setDate(tmpDate.getDate() + 8 - tmpDate.getDay());
	return ISOWeekStart;
}

export function getDateOfCrossMonthLine(monthLineIndex: number, fromDate: Date) {
	return new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth() + monthLineIndex * 4, 2));
}

export function getDateOfCrossYearsLine(yearLineIndex: number, fromDate: Date) {
	return new Date(Date.UTC(fromDate.getFullYear() + yearLineIndex * 4, 1, 2));
}

export const weekdays2char = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

export const getScrollbarWidth = function() {
	let div;
	let width = 0;
	div = document.createElement('div');
	div.innerHTML =
		'<div style="width:50px;height:50px;position:absolute;left:-50px;top:-50px;overflow:auto;"><div style="width:1px;height:100px;"></div></div>';
	div = div.firstChild;
	if (div) {
		document.body.appendChild(div);
		// @ts-ignore
		width = div.offsetWidth - div.clientWidth;
		document.body.removeChild(div);
	}
	return width;
};
