import React from 'react';
import css from './FallbackComponent.module.css';
import Button from '../pirsInputs/Button/Button';

const FallbackComponent: React.FC = () => {
	return (
		<div className={css.container}>
			<h1>Кажется, на этой странице что-то пошло не так</h1>
			<h2>Попробуйте перезагрузить страницу или нажмите кнопку возврата на домашнюю страницу</h2>
			<Button
				onClick={() => {
					window.location.href = '/';
				}}
			>
				На домашнюю страницу
			</Button>
		</div>
	);
};

export default FallbackComponent;
