import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import css from './Calendar.module.module.css';
import TimeList from './TimeList';
import usePrevious from '../../hooks/usePrevious';

type Props = {
	selected: number;
	onChange: (value: number) => void;
	values: number[];
	rowHeight: number;
	scrollbarWidth: number;
	min?: number;
	max?: number;
};

function doScrolling(element: HTMLElement, elementY: number, duration: number) {
	return new Promise<void>(resolve => {
		const startingY = element.scrollTop;
		const diff = elementY - startingY;
		let start = 0;

		window.requestAnimationFrame(function step(timestamp) {
			if (!start) start = timestamp;
			const time = timestamp - start;
			const percent = Math.min(time / duration, 1);

			element.scrollTop = startingY + diff * percent;

			if (time < duration) {
				window.requestAnimationFrame(step);
			} else {
				resolve();
			}
		});
	});
}

const TimeListWrapper: React.FC<Props> = props => {
	const container = useRef<HTMLDivElement>(null);
	const before = useRef<HTMLDivElement>(null);
	const current = useRef<HTMLDivElement>(null);
	const after = useRef<HTMLDivElement>(null);
	const scrolling = useRef(false);

	const currentTimeListStart = useMemo(() => props.rowHeight * (props.values.length - 3), [
		props.rowHeight,
		props.values,
	]);
	const currentTimeListEnd = useMemo(() => currentTimeListStart + props.rowHeight * props.values.length, [
		currentTimeListStart,
	]);

	const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
		if (scrolling.current) return;
		const currentScrollTop = event.currentTarget.scrollTop;
		if (currentScrollTop <= currentTimeListStart) container.current!.scrollTop = currentTimeListEnd;
		if (currentScrollTop >= currentTimeListEnd) container.current!.scrollTop = currentTimeListStart;
	}, []);
	const getCenterScrollTo = useCallback(
		(selected?: number) => {
			if (container.current) {
				const value = selected === undefined ? props.selected : selected;
				return props.rowHeight * props.values.length + props.rowHeight * value - (6 / 2) * props.rowHeight;
			}
			return 0;
		},
		[props.selected, props.rowHeight],
	);

	useEffect(() => {
		if (container.current) {
			container.current.scrollTop = getCenterScrollTo();
		}
	}, []);

	const prevSelected = usePrevious(props.selected);
	useEffect(() => {
		if (prevSelected && prevSelected !== props.selected) {
			scrolling.current = true;
			doScrolling(container.current!, getCenterScrollTo(props.selected), 200).then(() => {
				scrolling.current = false;
			});
		}
	}, [props.selected]);

	const handleChange = useCallback(
		(value: number) => {
			props.onChange(value);
		},
		[getCenterScrollTo, props.onChange],
	);

	return (
		<div className={css.timeListWrapper} style={{width: props.rowHeight}}>
			<div
				className={css.timeListsWrapper}
				onScroll={handleScroll}
				style={{width: props.rowHeight + props.scrollbarWidth}}
				ref={container}
			>
				<TimeList {...props} onChange={handleChange} useRef={before} />
				<TimeList {...props} onChange={handleChange} useRef={current} />
				<TimeList {...props} onChange={handleChange} useRef={after} />
			</div>
		</div>
	);
};

export default TimeListWrapper;
