import React, {useCallback, useEffect, useRef} from 'react';
import * as highcharts from 'highcharts';
import {Diagram, SeriesMeasure} from '../../../../../queries-generated/types';
import HighchartsExporting from 'highcharts/modules/exporting';
import useViewItemTree, {ViewItem} from '../../../../view/api/useViewItemTree';
import {format} from 'ts-date/locale/ru';
import {formatBites, formatBytes, humanNumber} from '../../../../../utils/number-utils';
import {baseLineOptions, highchartColors} from './highchartColors';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import {getArrayValueWithIndexExcess} from '../../../../../utils/array-utils';

export enum ChartTypes {
	Pie = 'pie',
	StackedColumn = 'stackedColumn',
	Column = 'column',
	Line = 'line',
	StackedLine = 'stackedLine',
	StackedColumnBar = 'stackedColumnBar',
}

type Props = {
	viewItem: ViewItem;
	type: ChartTypes;
	title: string;
	data: Diagram;
	width?: number | string;
	height?: number | string;
	reload?: () => void;
	additionButtons?: highcharts.ExportingMenuObject[];
	options?: PlainObjectOf<any>;
	selectedSeries?: string[];
	hideLegend?: boolean;
	onPointOver?: highcharts.PointMouseOverCallbackFunction;
	onPointOut?: highcharts.PointMouseOutCallbackFunction;
	reloadCounter?: number;
};

function formatYTooltip(measure: SeriesMeasure, value: number) {
	switch (measure) {
		case SeriesMeasure.Bite:
			return formatBites(value);
		case SeriesMeasure.Byte:
			return formatBytes(value);
		case SeriesMeasure.Number:
			return humanNumber(value);
		default:
			return value;
	}
}

HighchartsExporting(highcharts);
highcharts.setOptions({
	lang: {
		noData: 'За указанный период данные отсутствуют',
		loading: 'Загрузка...',
		months: [
			'Январь',
			'Февраль',
			'Март',
			'Апрель',
			'Май',
			'Июнь',
			'Июль',
			'Август',
			'Сентябрь',
			'Октябрь',
			'Ноябрь',
			'Декабрь',
		],
		weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
		shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
		rangeSelectorFrom: 'С',
		rangeSelectorTo: 'По',
		rangeSelectorZoom: 'Период',
		downloadPNG: 'Скачать PNG',
		downloadJPEG: 'Скачать JPEG',
		downloadPDF: 'Скачать PDF',
		downloadSVG: 'Скачать SVG',
		printChart: 'Распечатать',
		resetZoom: 'Сбросить масштаб',
		viewFullscreen: 'На весь экран',
	},
	tooltip: {
		dateTimeLabelFormats: {
			minute: '%B, %e-е (%A), %H:%M',
			hour: '%B, %e-е (%A), %kч',
			day: '%B, %e-е (%A)',
		},
	},
	time: {useUTC: false},
});

const ChartWrapper: React.FC<Props> = ({
	viewItem,
	type,
	title,
	data,
	width,
	height,
	reload,
	additionButtons,
	selectedSeries,
	onPointOut,
	onPointOver,
	reloadCounter,
	hideLegend,
	options,
}) => {
	const container = useRef<HTMLDivElement>(null);
	const chartRef = useRef<highcharts.Chart>(null);
	const viewItemTree = useViewItemTree();
	const redraw = viewItemTree?.root?.redraw;
	const {setProp} = useComponentsStoredData(viewItem.id);

	const toggleSeries = useCallback(event => {
		setProp(
			'selectedSeries',
			event.target?.chart.series.filter(series => series.options.visible).map(series => series.options.id),
		);
	}, []);

	const resizeHandler = () => {
		const pxByPoint = 30;
		const width = chartRef?.current?.chartWidth;
		let maxPoints = 1;
		data.series.forEach(series => {
			series.points.length > maxPoints && (maxPoints = series.points.length);
		});
		const markerOptions = {
			marker: {
				enabled: (width || 0) / maxPoints > pxByPoint,
			},
		};
		chartRef?.current?.update(
			{
				plotOptions: {
					line: {
						...markerOptions,
					},
					area: {
						...markerOptions,
					},
				},
			},
			true,
		);
	};

	const init = useCallback(() => {
		if (!container.current) return;

		let resultType = '';
		switch (type) {
			case ChartTypes.StackedColumn:
			case ChartTypes.StackedColumnBar:
			case ChartTypes.Column:
				resultType = 'column';
				break;
			case ChartTypes.StackedLine:
				resultType = 'area';
				break;
			default:
				resultType = type;
		}

		let seriesIndex = 0;
		const series = data.series.map((serie, index) => {
			let baseSeries: PlainObjectOf<any> = {
				data: serie.points.map(point => ({
					y: point.y,
					x: point.x ? new Date(point.x).getTime() : undefined,
					name: point.name,
				})),
				visible: (selectedSeries && selectedSeries.includes(serie.id || index.toString())) || true,
				name: serie.name,
				stack: serie.groupName || undefined,
				id: serie.id || index.toString(),
				yMeasure: serie.yMeasure || SeriesMeasure.Number,
				// @ts-ignore
				color: serie.color || getArrayValueWithIndexExcess(highchartColors, seriesIndex),
			};

			if (!serie.baseLine) seriesIndex++;

			if (serie.baseLine) {
				baseSeries = {...baseSeries, ...baseLineOptions, linkedTo: serie.baseLine};
			}

			return baseSeries;
		});

		const hasName = !!series[0]?.data[0]?.name;
		const showDataLabels = options?.showValuesOnPieces?.id;
		const dataLabels =
			showDataLabels === 'values'
				? {
						enabled: true,
						format: '<b>{point.name}</b>: {point.y}',
				  }
				: showDataLabels === 'percent'
				? {
						enabled: true,
						format: '<b>{point.name}</b>: {point.percentage:.1f} %',
				  }
				: {enabled: false};

		// @ts-ignore
		chartRef.current = highcharts.chart(container.current, {
			credits: {
				enabled: false,
			},
			chart: {
				type: resultType,
				plotShadow: false,
				width,
				height: height || width,
				backgroundColor: 'transparent',
				panning: {
					enabled: true,
					type: 'xy',
				},
				zoomType: 'xy',
				panKey: 'shift',
				pinchType: 'xy',
				resetZoomButton: {
					theme: {
						display: 'none',
					},
				},
				events: {
					load: function() {
						// @ts-ignore
						this.clickedOnce = false;
					},
					click: function() {
						// @ts-ignore
						if (this.clickedOnce) {
							this.zoomOut();
							// @ts-ignore
							this.clickedOnce = false;
						} else {
							// @ts-ignore
							this.clickedOnce = true;
						}
						setTimeout(() => {
							// @ts-ignore
							this.clickedOnce = false;
						}, 500);
					},
				},
			},
			title: {
				text: title,
			},
			xAxis: {
				type: resultType === 'column' && hasName ? 'category' : 'datetime',
			},
			yAxis: {
				title: {
					text: 'Значения',
				},
			},
			accessibility: {
				point: {
					valueSuffix: '%',
				},
			},
			tooltip: {
				formatter:
					type === ChartTypes.Line || type == ChartTypes.StackedLine
						? function() {
								return `${format(new Date(this.x), 'DD MMMM (dddd), HH:mm')}<br/><span style="color:${
									this.point.color
								}">\u25CF</span> ${this.point.series.name}: <b>${formatYTooltip(
									(this.point.series.options as any).yMeasure,
									this.point.y || 0,
								)}</b>`;
						  }
						: undefined,
			},
			legend: {
				align: 'left',
				verticalAlign: 'top',
				borderWidth: 0,
				itemStyle: {fontSize: '11px'},
			},
			plotOptions: {
				pie: {
					allowPointSelect: false,
					size: '100%',
					slicedOffset: 0,
					dataLabels,
					showInLegend: !hideLegend,
				},
				column: {
					stacking:
						type === ChartTypes.StackedColumn ||
						(type === ChartTypes.StackedColumnBar && options?.scale_type?.id === 'percentage')
							? 'percent'
							: type === ChartTypes.StackedColumnBar && options?.scale_type?.id === 'absolute'
							? 'normal'
							: undefined,
					showInLegend: !hideLegend,
					dataLabels,
					pointWidth: 10,
				},
				line: {
					showInLegend: !hideLegend,
					dataLabels,
				},
				area: {
					stacking: 'normal',
					showInLegend: !hideLegend,
					dataLabels,
				},
				series: {
					point: {
						events: {
							mouseOver: onPointOver,
							mouseOut: onPointOut,
						},
					},
					events: {
						hide: toggleSeries,
						show: toggleSeries,
					},
				},
			},
			colors: highchartColors,
			series,

			exporting: {
				enabled: true,
				buttons: {
					contextButton: {
						menuItems: [
							{text: 'Обновить', onclick: reload},
							'separator',
							...(additionButtons?.length ? [...additionButtons, 'separator'] : []),
							'viewFullscreen',
							'printChart',
							'separator',
							'downloadPNG',
							'downloadJPEG',
							'downloadPDF',
							'downloadSVG',
						],
					},
				},
			},
		} as highcharts.Options);

		resizeHandler();
	}, [width, height, title, selectedSeries]);

	useEffect(() => {
		chartRef?.current?.setSize(width as any, height as any);
	}, [width, height, data.series, reloadCounter]);

	useEffect(() => {
		window.addEventListener('resize', resizeHandler, true);

		return () => window.removeEventListener('resize', resizeHandler, true);
	}, []);

	useEffect(() => {
		chartRef?.current?.setTitle({text: title});
	}, [title]);

	useEffect(() => {
		setProp('selectedSeries', selectedSeries);
		chartRef?.current?.update({
			series: data.series.map((series, index) => {
				const id = series.id || index.toString();
				if (series.baseLine) {
					return {
						visible: (selectedSeries && selectedSeries.includes(series.baseLine)) || !selectedSeries,
					};
				} else {
					return {
						visible: (selectedSeries && selectedSeries.includes(id)) || !selectedSeries,
					};
				}
			}),
		} as highcharts.Options);
	}, [selectedSeries]);

	useEffect(() => {
		init();
	}, [redraw]);

	return <div ref={container} style={{width: '100%', height: '100%'}} />;
};

export default ChartWrapper;
