import React from 'react';
import css from './ErrorSnippet.module.css';
import {ApolloError} from '@apollo/client';
import {getApolloError} from '../../utils/error';
import {RefreshIcon} from '../SvgIcon';
import Button from '../pirsInputs/Button/Button';

type Props = {
	error: ApolloError | string;
	errorHeader?: React.ReactNode;
	refetch?: () => void;
};

const ErrorSnippet: React.FC<Props> = ({error, refetch, errorHeader}) => {
	const apolloError = typeof error === 'object' ? getApolloError(error) : undefined;
	const customErrorMessage = typeof error === 'string' && error;

	return (
		<div className={css.container}>
			<h3>{errorHeader ? errorHeader : 'Ошибка'}</h3>
			<div className={css.errorMessage}>{apolloError?.message || customErrorMessage}</div>
			{apolloError?.code && <div className={css.errorCode}>Код ошибки: {apolloError.code}</div>}
			{apolloError?.traceId && <div className={css.errorCode}>Trace id: {apolloError.traceId}</div>}
			{refetch && (
				<>
					<h4>Пожалуйста, повторите попытку.</h4>
					<Button iconOnly action onClick={refetch}>
						<RefreshIcon />
					</Button>
				</>
			)}
		</div>
	);
};

export default ErrorSnippet;
