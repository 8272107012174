import * as React from 'react';
import css from './FileUploadSnippet.module.css';
import InputFile from '../../components/pirsInputs/InputFile/InputFile';

type Props = {
	onCancelUpload: () => void;
	text: string;
};

const FileUploadSnippetFail: React.FC<Props> = ({onCancelUpload, text}) => {
	return (
		<div className={css.wrapper}>
			<div className={css.buttonArea}>
				<InputFile onChange={() => {}} disabled />
			</div>
			<div className={css.dataArea}>
				<ul className={css.dataList}>
					<li>{text}</li>
					<li>
						<a className={css.link} onClick={onCancelUpload}>
							Удалить
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default FileUploadSnippetFail;
