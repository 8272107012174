import {ActionsUnion, createAction} from '../../../redux/helper';
import {insertArrayElement, removeArrayElementByIndex} from '../../../utils/array-utils';
import React, {useCallback, useContext, useMemo, useReducer} from 'react';
import {Field} from '../../../queries-generated/types';

export type Action = {
	type: string;
	props: PlainObjectOf<any>;
};

export type CommonFilterField = Field & {
	guid: string;
};

export type RequestedColumn = {
	id: string;
	title: string;
	order: number;
	visible: boolean;
	details?: boolean;
	defaultSort?: boolean;
};

export type ViewItem = {
	id: string;
	parentId?: string;
	component: {
		type: string;
		props: PlainObjectOf<any>;
		title?: string;
	};
	dataSource?: {
		type: string;
		filters: PlainObjectOf<any>;
		filteredByUser?: string[];
		commonFilters?: string[];
		columns?: RequestedColumn[];
	};
	actions?: Action[];
	children: ViewItem[];
	commonFilters?: CommonFilterField[];
	redraw?: number;
};

const initialState: ViewItem = {
	id: 'root',
	component: {
		type: 'Dashboard',
		props: {},
	},
	actions: [],
	children: [],
	redraw: 0,
};

enum ActionType {
	SET = 'SET',
	ADD = 'ADD',
	MOVE = 'MOVE',
	REMOVE = 'REMOVE',
	UPDATE = 'UPDATE',
}

const actions = {
	set: (viewItem?: ViewItem) => createAction(ActionType.SET, viewItem),
	add: (payload: {parentId?: string; viewItem: ViewItem; index?: number}) => createAction(ActionType.ADD, payload),
	move: (payload: {parentId?: string; viewItem: ViewItem; index?: number}) => createAction(ActionType.MOVE, payload),
	remove: (id: string) => createAction(ActionType.REMOVE, id),
	update: (viewItem: ViewItem) => createAction(ActionType.UPDATE, viewItem),
};

export function reducer(rootViewItem: ViewItem, action: ActionsUnion<typeof actions>): ViewItem {
	switch (action.type) {
		case ActionType.SET:
			return action.payload
				? {...action.payload, redraw: (rootViewItem.redraw || 0) + 1}
				: {...rootViewItem, redraw: (rootViewItem.redraw || 0) + 1};
		case ActionType.ADD:
			return {
				...addChildToViewItem(
					[rootViewItem],
					action.payload.parentId || 'root',
					action.payload.viewItem,
					action.payload.index,
				)[0],
				redraw: (rootViewItem.redraw || 0) + 1,
			};
		case ActionType.MOVE:
			return {
				...addChildToViewItem(
					removeViewItem([rootViewItem], action.payload.viewItem.id),
					action.payload.parentId || 'root',
					action.payload.viewItem,
					action.payload.index,
				)[0],
				redraw: (rootViewItem.redraw || 0) + 1,
			};
		case ActionType.REMOVE:
			return {...removeViewItem([rootViewItem], action.payload)[0], redraw: (rootViewItem.redraw || 0) + 1};
		case ActionType.UPDATE:
			return {...updateViewItem([rootViewItem], action.payload)[0], redraw: (rootViewItem.redraw || 0) + 1};

		default:
			return rootViewItem;
	}
}

function addChildToViewItem(viewItems: ViewItem[], parentId: string, newViewItem: ViewItem, index?: number) {
	return viewItems.map(viewItem => ({
		...viewItem,
		children:
			viewItem.id === parentId
				? insertArrayElement(
						viewItem.children,
						index !== undefined ? index : viewItem.children.length,
						newViewItem,
				  )
				: addChildToViewItem(viewItem.children, parentId, newViewItem, index),
	}));
}

function removeViewItem(viewItems: ViewItem[], id: string) {
	return viewItems.map(viewItem => {
		const index = viewItem.children.findIndex(item => item.id === id);
		return {
			...viewItem,
			children:
				index > -1
					? removeArrayElementByIndex(viewItem.children, index)
					: removeViewItem(viewItem.children, id),
		};
	});
}

function updateViewItem(viewItems: ViewItem[], newViewItem: ViewItem) {
	return viewItems.map(viewItem => {
		if (newViewItem.id === 'root') {
			return {...viewItem, ...newViewItem};
		} else {
			const index = viewItem.children.findIndex(item => item.id === newViewItem.id);
			let children = [...viewItem.children];
			if (index > -1) {
				children[index] = newViewItem;
			} else {
				children = updateViewItem(children, newViewItem);
			}
			return {
				...viewItem,
				children,
			};
		}
	});
}

function getViewItem(viewItem: ViewItem, id: string): ViewItem | undefined {
	if (viewItem.id === id) return viewItem;
	for (let i = 0, length = viewItem.children.length; i < length; i++) {
		const result = getViewItem(viewItem.children[i], id);
		if (result) return result;
	}
}

function getIndexViewItem(viewItemRoot: ViewItem, viewItem: ViewItem): number {
	const parent = viewItem.parentId ? getViewItem(viewItemRoot, viewItem.parentId) || viewItemRoot : viewItemRoot;

	return parent.children.findIndex(item => item.id === viewItem.id);
}

type ViewItemContextType = {
	root: ViewItem;
	get: (id: string) => ViewItem | undefined;
	getIndex: (viewItem: ViewItem) => number;
} & typeof actions;

export const ViewItemContext = React.createContext<ViewItemContextType>(null as any);

export function ViewItemProvider({children}) {
	const [root, dispatch] = useReducer(reducer, initialState);

	const actionBounds = useMemo(() => {
		const actionBounds = {};
		Object.keys(actions).forEach(actionKey => {
			const action = actions[actionKey];
			actionBounds[actionKey] = payload => {
				dispatch(action(payload));
			};
		});
		return actionBounds;
	}, [dispatch]);

	const value = {
		root,
		get: useCallback((id: string) => getViewItem(root, id), [root]),
		getIndex: useCallback((viewItem: ViewItem) => getIndexViewItem(root, viewItem), [root]),
		...actionBounds,
	};

	return <ViewItemContext.Provider value={value as any}>{children}</ViewItemContext.Provider>;
}

function useViewItemTree() {
	return useContext(ViewItemContext);
}

export default useViewItemTree;
