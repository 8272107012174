import React, {useCallback, useMemo, useState} from 'react';
import Tabs, {Tab} from '../../controlls/tabs/Tabs';
import {getValueByPath} from '../../../utils/object-utils';
import css from './Entity.module.css';
import Button from '../../pirsInputs/Button/Button';
import EntitiesList from './EntitiesList';
import {useFormikContext} from 'formik';
import EntityComponent from './EntityComponent';
import {createEmptyValue, isDirtyValues} from './helper';
import {Entity} from './useMetadata';
import {removeArrayElementByIndex} from '../../../utils/array-utils';
import uid from '../../../utils/uid';
import cls from '../../../utils/cls';
import {CreateIcon, CrossIcon, RefreshIcon} from '../../SvgIcon';
import MoreButton from './MoreButton';
import useDeleteConfirm from '../../hooks/useDeleteConfirm/useDeleteConfirm';

type Props = {
	entity: Entity;
	name: string;
	deep?: number;
	errors?: any;
	required: boolean;
	id?: string;
	type: string;
};

const EntitiesCollection: React.FC<Props> = ({name, required, entity, errors, deep, id, type}) => {
	const formik = useFormikContext<any>();
	const baseValues = useMemo(() => {
		return getValueByPath(formik.values, name);
	}, [name, formik.values]);
	const [disabled, setDisabled] = useState(!!entity.infinityList && isDirtyValues(baseValues));
	const values: any[] = useMemo(() => {
		return disabled ? [createEmptyValue(entity.childAttributes)] : baseValues;
	}, [disabled, baseValues, entity]);
	const [tab, setTab] = useState(0);

	const handleCreate = useCallback(() => {
		if (!values || !values.length) return;
		formik.setFieldValue(
			`${name}.${values.length}`,
			entity.__typename === 'ScalarCollectionDescriptor' ? null : createEmptyValue(entity.childAttributes),
		);
		setTab(values.length);
	}, [entity, formik, name, values]);

	const handleReset = useCallback(() => {
		formik.setFieldValue(
			`${name}.0`,
			entity.__typename === 'ScalarCollectionDescriptor' ? null : createEmptyValue(entity.childAttributes),
		);
	}, [entity, formik, name]);

	const handleRemove = useCallback(() => {
		const newValues = removeArrayElementByIndex(values, tab);
		formik.setFieldValue(name, newValues);
		setTab(tab - 1 >= 0 ? tab - 1 : 0);
	}, [formik, name, tab, values]);

	const handleChangeTab = useCallback(index => {
		setTab(index);
	}, []);

	const handleUnblock = useCallback(() => {
		handleReset();
		setDisabled(false);
	}, [handleReset]);

	const {Confirm, openConfirm} = useDeleteConfirm({
		callback: handleUnblock,
		header: 'Вы уверены?',
		text: `Это действие приведет к удалению всех элементов "${entity.caption}". Продолжить?`,
	});

	if (!values) return null;

	return (
		<div>
			<div className={css.wrapper}>
				{disabled && <div className={css.backdrop} />}
				<Tabs vertical selected={tab} onChange={handleChangeTab}>
					{values.map((_item, index) => {
						return (
							<Tab
								header={`${index + 1}`}
								key={uid()}
								headerClassName={cls(errors && errors[index] && css.tabError)}
							>
								{entity.__typename === 'ScalarCollectionDescriptor' ? (
									<EntityComponent entity={entity} name={`${name}.${index}`} />
								) : (
									<EntitiesList
										deep={(deep || 0) + 1}
										entities={entity.childAttributes}
										name={`${name}.${index}`}
										errors={errors ? errors[index] : undefined}
										required={required}
										id={id}
										type={type}
									/>
								)}
							</Tab>
						);
					})}
				</Tabs>
			</div>
			<div className={css.collectionActions}>
				{!disabled ? (
					<>
						<Button type={'button'} secondary onClick={handleCreate}>
							<CreateIcon /> Добавить
						</Button>
						{values.length > 1 && (
							<Button type={'button'} secondary onClick={handleRemove}>
								<CrossIcon /> Удалить
							</Button>
						)}
						{values.length === 1 && (
							<Button type={'button'} secondary onClick={handleReset}>
								<CrossIcon /> Очистить
							</Button>
						)}
					</>
				) : (
					<>
						{id && <MoreButton id={id} attributePath={entity.attributePath} type={type} />}
						{Confirm}
						<Button onClick={openConfirm} secondary>
							<RefreshIcon /> Создать новый список
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default EntitiesCollection;
