import React, {CSSProperties} from 'react';
import css from './Card.module.css';
import cls from '../../../utils/cls';

type Props = {
	className?: string;
	style?: CSSProperties;
};

type CardItemProps = {
	fill?: boolean;
	more?: boolean;
	className?: string;
	style?: CSSProperties;
	header?: React.ReactNode | string;
};

type CardImageProps = {
	className?: string;
	style?: CSSProperties;
};

export const CardItem: React.FC<CardItemProps> = ({style, children, className, header, fill, more}) => {
	return (
		<div
			className={cls(css.item, fill && css.fill, header && css.header, more && css.more, className)}
			style={style}
		>
			{children || <h3>{header}</h3>}
		</div>
	);
};

export const CardImage: React.FC<CardImageProps> = ({style, children, className}) => {
	return (
		<div className={cls(css.item, css.image, css.fill, className)} style={style}>
			{children}
		</div>
	);
};

const Card: React.FC<Props> = ({className, style, children}) => {
	return (
		<div className={css.cardWrapper}>
			<div className={cls(css.card, className)} style={style}>
				{children}
			</div>
		</div>
	);
};

export default Card;
