import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import {CalendarIcon} from '../../SvgIcon';
import {format, formatDateIso, parse} from 'ts-date/locale/ru';
import Calendar, {DurationItem, MAGIC_CUSTOM_DURATION, Value as CalendarValue} from '../Calendar/Calendar';
import Popover from '../../controlls/PopoverButton/Popover';
import MaskedInput from '../MaskedInput';
import useOnClickOutside from 'components/hooks/useOnClickOutside';
import usePrevious from '../../hooks/usePrevious';
import {DATE_MASK, DATE_PLACEHOLDER, DATE_TEMPLATE} from '../InputDateTime/InputDateTime';
import {dateTimeToDateIso} from '../../../utils/date-utils';
import fieldCss from '../Field/Field.module.css';
import Button from '../Button/Button';
import {addDuration} from '../../../utils/duration-iso-utils';

export type Value = {
	from: string | null;
	to: string | null;
	duration: DurationIso | null;
};

interface InputInterface extends CommonInputInterface<Value | null> {
	setRef?: React.RefObject<HTMLInputElement>;
	value: Value | null;
	durations?: DurationItem[];
}

export type InputProps = InputInterface & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const SEPARATOR = ' - ';

function toFieldValue(from: Date | null, to: Date | null) {
	return (from ? format(from, DATE_TEMPLATE)! : '') + SEPARATOR + (to ? format(to, DATE_TEMPLATE)! : '');
}

const InputDateRange: React.FC<InputProps> = props => {
	const {error, durations, disabled, className, helperText, onChange: _, setRef, size, ...inputProps} = props;
	const {onChange, value} = props;
	const prevValue = usePrevious(value);
	const minDate = (inputProps.min && new Date(inputProps.min as string)) as Date;
	const maxDate = (inputProps.max && new Date(inputProps.max as string)) as Date;

	const dateFrom = value?.from ? new Date(value.from) : null;
	const dateTo = value?.to ? new Date(value.to) : null;

	const inputRef = useRef<HTMLInputElement>(null);
	const popoverRef = useRef<HTMLDivElement>(null);
	const iconRef = useRef<HTMLButtonElement>(null);

	const [fieldValue, setFieldValue] = useState(toFieldValue(dateFrom, dateTo));

	useEffect(() => {
		if (value !== prevValue) {
			setFieldValue(toFieldValue(dateFrom, dateTo));
		}
	}, [value, prevValue, dateTo, dateFrom]);

	const handleFieldChange = useCallback(
		(field: string, duration?: DurationIso | null) => {
			if (!field) {
				onChange(null);
				setFieldValue('');
				return;
			}
			const hasRange = !duration || duration === MAGIC_CUSTOM_DURATION;
			const parts = field
				.split(SEPARATOR)
				.map(part => parse(part, DATE_TEMPLATE))
				.filter(Boolean);
			if (parts.length > 1) {
				const dateFrom = parts[0];
				const dateTo = parts[1];
				if (dateTo && dateFrom && dateTo < dateFrom) {
					onChange({from: formatDateIso(dateTo), to: formatDateIso(dateFrom), duration: duration || null});
					setFieldValue(toFieldValue(dateTo, dateFrom));
				} else {
					onChange({from: formatDateIso(dateFrom), to: formatDateIso(dateTo), duration: duration || null});
					setFieldValue(toFieldValue(dateFrom, dateTo));
				}
			} else if (!hasRange && duration && parts[0]) {
				const dateFrom = parts[0];
				const dateTo = addDuration(dateFrom, duration);
				onChange({from: formatDateIso(dateFrom), to: formatDateIso(dateTo), duration});
				setFieldValue(toFieldValue(dateFrom, dateTo));
			} else {
				setFieldValue(field);
			}
		},
		[onChange],
	);

	const [openCalendar, setOpenCalendar] = useState(false);

	const handleOpenCalendar = useCallback(() => {
		setOpenCalendar(true);
		setTimeout(() => {
			if (inputRef.current && popoverRef.current && process.browser) {
				document.body.style.pointerEvents = 'none';
				inputRef.current.style.pointerEvents = 'all';
			}
		});
	}, [setOpenCalendar, inputRef]);

	const handleCloseCalendar = useCallback(() => {
		setOpenCalendar(false);
		if (process.browser) {
			document.body.style.pointerEvents = '';
		}
	}, [setOpenCalendar]);

	useEffect(() => {
		return () => {
			if (process.browser) {
				document.body.style.pointerEvents = '';
			}
		};
	}, []);

	const handleToggleCalendar = useCallback(() => {
		openCalendar ? handleCloseCalendar() : handleOpenCalendar();
	}, [handleOpenCalendar, handleCloseCalendar, openCalendar]);

	useOnClickOutside([popoverRef, inputRef, iconRef], handleCloseCalendar);

	const handleCalendarChange = useCallback(
		(range: CalendarValue) => {
			const hasRange = !range.duration || range.duration === MAGIC_CUSTOM_DURATION;
			onChange({
				from: formatDateIso(range.from),
				to: range.to && hasRange ? dateTimeToDateIso(range.to) : null,
				duration: range.duration,
			});
			handleFieldChange(toFieldValue(range.from, hasRange ? range.to : null), range.duration);
		},
		[onChange, dateTo],
	);

	const handleBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement, Element>) => {
			if (!e.relatedTarget || !popoverRef.current || !popoverRef.current.contains(e.relatedTarget)) {
				setFieldValue(toFieldValue(dateFrom, dateTo));
				handleCloseCalendar();
			}
		},
		[dateFrom, dateTo, handleCloseCalendar],
	);

	return (
		<>
			<MaskedInput
				autoComplete={'off'}
				{...(inputProps as any)}
				onChange={handleFieldChange}
				setRef={inputRef}
				value={fieldValue}
				onFocus={handleOpenCalendar}
				onBlur={handleBlur}
				mask={[...DATE_MASK, ...SEPARATOR.split(''), ...DATE_MASK]}
				maskPlaceholder={DATE_PLACEHOLDER + SEPARATOR + DATE_PLACEHOLDER}
				bottomText={undefined}
				buttons={
					<Button
						action
						iconOnly
						onClick={handleToggleCalendar}
						setRef={iconRef}
						checked={openCalendar}
						className={fieldCss.actionButton}
					>
						<CalendarIcon width={14} height={14} />
					</Button>
				}
			/>
			<Popover
				open={openCalendar}
				parentRef={inputRef}
				forwardRef={popoverRef}
				focusOnOpen
				returnFocusRef={inputRef}
				onClose={handleCloseCalendar}
			>
				<Calendar
					value={{from: dateFrom, to: dateTo, duration: value?.duration || null}}
					onChange={handleCalendarChange}
					minDate={minDate}
					maxDate={maxDate}
					time={false}
					width={250}
					range={!value?.duration || value.duration === MAGIC_CUSTOM_DURATION}
					durations={durations}
					selectDurations={!!durations?.length}
				/>
			</Popover>
		</>
	);
};

export default InputDateRange;
