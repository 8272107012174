exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".FilesListRow-module__tableRow__2nOwK {\r\n\tcursor: pointer;\r\n}\r\n\r\n.FilesListRow-module__details__3iry5 {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\n\r\n.FilesListRow-module__errors__3_Gt9 li {\r\n\t\tlist-style-type: disc;\r\n\t}\r\n\r\n.FilesListRow-module__title__3Pn81 {\r\n\tfont-weight: bold;\r\n}\r\n\r\n.FilesListRow-module__title__3Pn81:after {\r\n\t\tcontent: \": \";\r\n\t\tdisplay: inline;\r\n\t}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\FileConsolePage\\FilesListRow.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,gBAAgB;CAChB,SAAS;CACT,UAAU;AACX;;AAGC;EACC,qBAAqB;CACtB;;AAGD;CACC,iBAAiB;AAMlB;;AAJC;EACC,aAAa;EACb,eAAe;CAChB","file":"FilesListRow.module.css","sourcesContent":[".tableRow {\r\n\tcursor: pointer;\r\n}\r\n\r\n.details {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\n\r\n.errors {\r\n\tli {\r\n\t\tlist-style-type: disc;\r\n\t}\r\n}\r\n\r\n.title {\r\n\tfont-weight: bold;\r\n\r\n\t&:after {\r\n\t\tcontent: \": \";\r\n\t\tdisplay: inline;\r\n\t}\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"tableRow": "FilesListRow-module__tableRow__2nOwK",
	"details": "FilesListRow-module__details__3iry5",
	"errors": "FilesListRow-module__errors__3_Gt9",
	"title": "FilesListRow-module__title__3Pn81"
};