function cls(
	...args: Array<
		string | number | boolean | null | undefined | {[key: string]: string | number | boolean | null | undefined}
	>
) {
	const res: any[] = [];
	for (let i = 0; i < args.length; i++) {
		const arg = args[i];
		if (!arg) continue;
		if (typeof arg === 'string') {
			res.push(arg);
		} else {
			Object.keys(arg).forEach(key => {
				if (arg[key]) res.push(key);
			});
		}
	}
	return res.join(' ');
}

export default cls;
