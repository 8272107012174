import {DataSourceNotification, Diagram, useDiagramQuery} from '../../../../../queries-generated/types';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import React, {useContext, useEffect} from 'react';
import usePrevious from '../../../../hooks/usePrevious';
import {getError} from '../../../../../utils/error';
import ProgressRing from '../../../../controlls/Loader/ProgressRing';
import ChartWrapper, {ChartTypes} from './ChartWrapper';
import css from './ChartWrapperWithData.module.css';
import {useComponentFilters} from '../../../../view/api/useUserFilters';
import {isNotifiedDataSource, filtersToDataSourceParams} from '../helper';
import {NetworkStatus} from '@apollo/client';
import {notifyDataSource} from '../../../../toast/DataSourceToast';
import {DataSourceContext} from '../../../../view/ViewWrapper';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import useComponentCommonButtons from '../../../../view/useComponentCommonButtons';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	defaultData: Diagram;
	mapper: (data: Diagram) => Diagram;
	type: ChartTypes;
	pollInterval?: number;
};

const ChartWrapperWithData: React.FC<Props> = ({viewItem, type, skipQuery, defaultData, mapper, pollInterval}) => {
	const {reloadCounter} = useContext(DataSourceContext);

	const prevReloadCounter = usePrevious(reloadCounter);

	const {setData} = useComponentsStoredData(viewItem.id);

	const {filters, waiting} = useComponentFilters(viewItem);

	const {data, loading, refetch, error, networkStatus} = useDiagramQuery({
		variables: {dataSourceId: viewItem.dataSource?.type || '', filters: filtersToDataSourceParams(filters)},
		skip: skipQuery || waiting,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		pollInterval,
		onCompleted: result => {
			if (isNotifiedDataSource(viewItem.actions))
				notifyDataSource(result.diagram.notifications as DataSourceNotification[]);
		},
	});

	useEffect(() => {
		if (prevReloadCounter !== undefined && reloadCounter !== prevReloadCounter && !skipQuery) {
			refetch();
		}
	}, [reloadCounter, skipQuery, prevReloadCounter]);

	useEffect(() => {
		const diagram = skipQuery ? defaultData : data?.diagram;
		setData({...viewItem.dataSource, data: diagram});
	}, [defaultData, data, skipQuery, viewItem]);

	const commonButtons = useComponentCommonButtons(viewItem);

	if (error) return <div className={css.error}>{getError(error)?.message || 'Произошла ошибка'}</div>;

	const diagram = skipQuery ? defaultData : data?.diagram;

	if ((loading && networkStatus !== NetworkStatus.poll) || !diagram)
		return (
			<div className={css.error}>
				<ProgressRing />
			</div>
		);
	if (!diagram.series.length) return <div className={css.error}>За указанный период данные отсутствуют</div>;

	return (
		<>
			<div className={css.filters}>
				{commonButtons?.RefreshAction}
				{commonButtons?.FilterAction}
			</div>
			<ChartWrapper
				viewItem={viewItem}
				data={mapper(diagram)}
				type={type}
				title={viewItem.component?.props?.title}
				width={viewItem.component?.props?.width}
				height={viewItem.component?.props?.height}
				reload={refetch}
				options={viewItem.component?.props}
			/>
		</>
	);
};

export default ChartWrapperWithData;
