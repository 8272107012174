import * as React from 'react';
import Button from '../../components/pirsInputs/Button/Button';
import {useFileSectionsQuery, useHelpFilesListQuery, useUserQuery} from '../../queries-generated/types';
import ProgressRing from '../../components/controlls/Loader/ProgressRing';
import cssHome from './../HomePage/HomePage.module.css';
import css from './FilesConsolePage.module.css';
import {useRouter} from 'react-named-hooks-router';
import MainLayout from '../../components/layouts/MainLayout';
import {LogoWhite} from '../../components/SvgIcon';
import HelpFileList from '../../components/fileConsole/HelpFilesList/HelpFilesList';
import cls from '../../utils/cls';
import Card, {CardItem} from '../../components/controlls/Card/Card';

const FilesConsolePage: React.FC = () => {
	const {data: userData, loading: userLoading} = useUserQuery();
	const {data: sectionsData, loading: sectionsLoading} = useFileSectionsQuery();
	const {data: helpFilesData, loading: helpFilesLoading} = useHelpFilesListQuery({
		variables: {
			input: {},
		},
	});
	const helpFiles = helpFilesData?.helpFilesList;
	const {pushRoute} = useRouter();

	return (
		<MainLayout>
			<div className={css.wrapper}>
				<div className={css.mainWrapper}>
					<div className={cssHome.wrapperWelcome}>
						Представление информации согласно приказам Роскомнадзора
					</div>

					{!userLoading && (
						<div className={cls(css.header)}>
							{userData?.user.shortOrganizationName || userData?.user.name ? (
								<>
									<p>
										Вы вошли, как представитель владельца ресурсов связи "
										{userData.user.shortOrganizationName || userData.user.name}"
									</p>
									<p>
										Полный комплект справочной документации доступен{' '}
										<a download href={`/services/downloadFile/ALL_DOCS`}>
											по ссылке
										</a>
									</p>
								</>
							) : (
								<p>Вы вошли без указания организации</p>
							)}
						</div>
					)}

					{sectionsLoading || (userLoading && <ProgressRing />)}

					{sectionsData && sectionsData.fileSections.length > 0 && (
						<div className={css.buttons}>
							{sectionsData.fileSections.map(section => (
								<Card key={section.id} className={cls(css.paper)}>
									<CardItem>
										<Button
											disabled={!section.enabled}
											className={css.button}
											onClick={() => pushRoute('fileConsole', {id: section.id})}
										>
											<LogoWhite />
											{section.title}
										</Button>
										{helpFilesLoading ? (
											<ProgressRing />
										) : (
											<HelpFileList
												helpFiles={
													helpFiles?.filter(file => file.sectionId === section.id) || []
												}
											/>
										)}
									</CardItem>
								</Card>
							))}
						</div>
					)}
				</div>

				<div className={cls(css.paper, css.additionalInfo)}>
					В случае необходимости уточнения порядка представления информации в соответствии с приказами
					Роскомнадзора просим обращаться по адресу: <a href="mailto:ndr@noc.gov.ru">ndr@noc.gov.ru</a>.
				</div>
			</div>
		</MainLayout>
	);
};

export default FilesConsolePage;
