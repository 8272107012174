import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {List, ListRowProps, ScrollParams} from 'react-virtualized';
import {diffWeeks, getDateOfCrossWeek, setDate, weekdays2char} from './helper';
import {addDate, addMonth, format, resetDate, resetMonth} from 'ts-date/locale/ru';
import css from './Calendar.module.module.css';
import {ArrowDownIcon, ArrowUpIcon, GoTodayIcon} from '../../SvgIcon';
import {END_DATE, FROM_DATE, stopPropagation, Value} from './Calendar';
import cls from '../../../utils/cls';
import usePrevious from '../../hooks/usePrevious';
import toUpperFirst from '../../../utils/toUpperFirst';
import Button from '../Button/Button';

type Props = {
	value: Value | null;
	onChange: (value: Value) => void;
	minDate?: Date;
	maxDate?: Date;
	width: number;
	height: number;
	rowHeight: number;
	scrollbarWidth: number;
	switchToMonths: (e: React.MouseEvent) => void;
	range?: boolean;
	dateCursor: Date;
	onChangeDateCursor: (date: Date) => void;
};

const DaysCalendar: React.FC<Props> = ({
	value,
	onChange,
	width,
	scrollbarWidth,
	height,
	rowHeight,
	switchToMonths,
	range,
	dateCursor,
	onChangeDateCursor,
	...props
}) => {
	const fromDate = useMemo(() => (props.minDate ? addMonth(props.minDate, -1) : FROM_DATE), [props.minDate]);
	const endDate = useMemo(() => (props.maxDate ? addMonth(props.maxDate, 1) : END_DATE), [props.maxDate]);

	const [mode, setMode] = useState<1 | 2>(1);

	const prevRange = usePrevious(range);

	useEffect(() => {
		if (prevRange !== range) setMode(1);
	}, [prevRange, range]);

	const handleChange = useCallback(
		(newValue: Date) => {
			if (props.minDate && newValue < props.minDate) newValue = props.minDate;
			if (props.maxDate && newValue > props.maxDate) newValue = props.maxDate;

			if (mode === 1) {
				onChange({from: newValue, to: null, duration: value?.duration || null});
			} else {
				if (value?.from && newValue < value.from) {
					onChange({from: newValue, to: value?.from || null, duration: value?.duration || null});
				} else {
					onChange({from: value?.from || null, to: newValue, duration: value?.duration || null});
				}
			}
			if (range) {
				setMode(mode === 1 ? 2 : 1);
			}
		},
		[range, value, mode, onChange, props.minDate, props.maxDate],
	);

	const list = useRef<List>(null);

	useEffect(() => {
		if (list.current) {
			list.current.scrollToPosition(diffWeeks(fromDate, resetMonth(dateCursor)) * rowHeight);
		}
	}, []);

	const currentMonth = useMemo(() => {
		let currentMonth: Date = null as any;
		if (!dateCursor) return resetMonth(new Date());
		if (dateCursor.getDate() > 13) {
			currentMonth = new Date(dateCursor.getFullYear(), dateCursor.getMonth() + 1, 1);
		} else {
			currentMonth = new Date(dateCursor.getFullYear(), dateCursor.getMonth(), 1);
		}
		return currentMonth;
	}, [dateCursor.getTime()]);

	const weeksCount = useMemo(() => diffWeeks(fromDate, endDate) + 1, [fromDate, endDate]);

	const Week = ({index, key, style}: ListRowProps) => {
		const day = getDateOfCrossWeek(index, fromDate);
		return (
			<div key={key} style={{...style}} className={css.line}>
				{Array(7)
					.fill(undefined)
					.map((_x, dayIndex) => {
						const date = addDate(day, dayIndex);
						const currentDate = resetDate(date);
						const selectedDates = [resetDate(value?.from || null), resetDate(value?.to || null)].filter(
							Boolean,
						);
						const minDate = props.minDate && resetDate(props.minDate);
						const maxDate = props.maxDate && resetDate(props.maxDate);
						const isInCurrentMonth = date.getMonth() === currentMonth.getMonth();

						const nowDate = resetDate(new Date());
						const isToday = currentDate.getTime() === nowDate.getTime();

						const isSelectedFrom =
							selectedDates.length > 0 && selectedDates[0]?.getTime() === currentDate.getTime();
						const isSelectedTo =
							selectedDates.length > 0 && selectedDates[1]?.getTime() === currentDate.getTime();

						const isDisabled = (minDate && currentDate < minDate) || (maxDate && currentDate > maxDate);
						const isPrev =
							selectedDates.length === 2 &&
							currentDate > selectedDates[0]! &&
							currentDate < selectedDates[1]!;
						const isNext =
							selectedDates.length === 2 &&
							currentDate < selectedDates[1]! &&
							currentDate > selectedDates[0]!;

						const valueByMode = mode === 1 ? value?.from : value?.to;
						const changeDate = setDate(
							valueByMode || date,
							date.getFullYear(),
							date.getMonth(),
							date.getDate(),
						);
						if (date >= fromDate && date <= endDate) {
							return (
								<div
									key={key + '-' + dayIndex}
									className={cls(
										css.item,
										!isInCurrentMonth && css.otherMonth,
										isToday && css.today,
										(isSelectedFrom || isSelectedTo) && css.selected,
										isSelectedFrom && css.selectedFrom,
										isSelectedTo && css.selectedTo,
										isDisabled && css.disabled,
										isNext && css.range,
										isPrev && css.range,
									)}
									onClickCapture={e => {
										e.stopPropagation();
										if (!isDisabled) {
											handleChange(changeDate);
										}
									}}
									onMouseDown={stopPropagation}
									role={'button'}
									tabIndex={isDisabled ? undefined : -1}
									aria-disabled={isDisabled}
									aria-checked={isSelectedFrom || isSelectedTo}
									aria-hidden={!isInCurrentMonth}
									aria-label={`Установка даты ${changeDate.toUTCString()}`}
								>
									{format(date, 'D')}
								</div>
							);
						}
						return (
							<div
								key={key + '-' + dayIndex}
								aria-disabled={isDisabled}
								className={cls(css.item, css.disabled)}
							>
								{format(date, 'D')}
							</div>
						);
					})}
			</div>
		);
	};

	const handleScroll = useCallback(
		({scrollTop}: ScrollParams) => {
			const index = Math.ceil(scrollTop / rowHeight);
			onChangeDateCursor(getDateOfCrossWeek(index, fromDate));
		},
		[rowHeight],
	);

	const prevMonthDisabled = useMemo(() => {
		return resetMonth(fromDate) >= resetMonth(addMonth(currentMonth, -1));
	}, [fromDate, currentMonth]);

	const nextMonthDisabled = useMemo(() => {
		return resetMonth(endDate) <= resetMonth(addMonth(currentMonth, 1));
	}, [endDate, currentMonth]);

	const todayDisabled = useMemo(() => {
		return resetMonth(currentMonth).getTime() === resetMonth(new Date()).getTime();
	}, [currentMonth]);

	const handleSetPrevMonth = useCallback(
		e => {
			e.stopPropagation();
			if (prevMonthDisabled) return;
			const newDate = addMonth(currentMonth, -1);
			newDate.setDate(1);
			const index = diffWeeks(fromDate, newDate);
			list.current?.scrollToPosition((index * height) / 6);
			onChangeDateCursor(newDate);
		},
		[fromDate, currentMonth],
	);

	const handleSetNextMonth = useCallback(
		e => {
			e.stopPropagation();
			if (nextMonthDisabled) return;
			const newDate = addMonth(currentMonth, 1);
			newDate.setDate(1);
			const index = diffWeeks(fromDate, newDate);
			list.current?.scrollToPosition((index * height) / 6);
			onChangeDateCursor(newDate);
		},
		[fromDate, currentMonth],
	);

	const handleToday = useCallback(e => {
		e.stopPropagation();
		const now = new Date();
		const newDate = new Date(now.getFullYear(), now.getMonth(), 1);
		const index = diffWeeks(fromDate, newDate);
		list.current?.scrollToPosition((index * height) / 6);
		onChangeDateCursor(newDate);
	}, []);

	const hasDuration = useMemo(() => {
		const selectedDates = [value?.from || null, value?.to || null].filter(Boolean);

		return selectedDates.length === 2;
	}, [value]);

	return (
		<div style={{width: (height / 6) * 7}} className={cls(css.wrapper, hasDuration && css.hasRange)}>
			<div className={css.header} style={{height: rowHeight}}>
				<div className={css.title} onClick={switchToMonths} onMouseDown={stopPropagation}>
					{toUpperFirst(format(currentMonth, 'MMMM YYYY')!)}
				</div>
				<div className={css.actions}>
					<Button
						type={'button'}
						action
						iconOnly
						disabled={prevMonthDisabled}
						onClick={handleSetPrevMonth}
						onMouseDown={stopPropagation}
						title={'Предыдущий месяц'}
					>
						<ArrowUpIcon />
					</Button>
					<Button
						type={'button'}
						action
						iconOnly
						disabled={nextMonthDisabled}
						onClick={handleSetNextMonth}
						onMouseDown={stopPropagation}
						title={'Следующий месяц'}
					>
						<ArrowDownIcon />
					</Button>
					<Button
						type={'button'}
						disabled={todayDisabled}
						action
						iconOnly
						onClick={handleToday}
						onMouseDown={stopPropagation}
						title={'Сегодня'}
					>
						<GoTodayIcon />
					</Button>
				</div>
			</div>
			<div className={css.weekDays} style={{height: rowHeight}}>
				{weekdays2char.map(day => (
					<div key={day}>{day}</div>
				))}
			</div>
			<List
				onScroll={handleScroll}
				rowCount={weeksCount}
				rowHeight={rowHeight}
				height={height}
				tabIndex={null}
				width={width + scrollbarWidth}
				rowRenderer={Week}
				ref={list}
			/>
		</div>
	);
};

export default DaysCalendar;
