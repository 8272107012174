import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import css from './DataGrid.module.css';
import DataGridHead, {TableHeadOptions} from './DataGridHead';
import DataGridRow from './DataGridRow';
import {Field, Grid} from '../../queries-generated/types';
import cls from '../../utils/cls';
import DataGridRowGroup from './DataGridRowGroup';
import {calculateTableWidths, DataGridAction, flatFields, getItemByField} from '../settings/views/components/helper';
import DataGridShimmer from './DataGridShimmer';
import {defaultOnCellRender} from './onCellRender';
import useDataGridSelection, {DataGridTree} from './useDataGridSelection';
import {Breadcrumb} from '../Breadcrumbs/Breadcrumbs';

export const MIN_CELL_WIDTH = 60;

export type DataGridOptions = {
	fields?: Field[];
	onChangeSort?(fieldId: string);
	sort?: string | null;
	sortOrder?: boolean;
	selectable?: boolean;
	fullSelectable?: boolean;
	selectedIds?: string[];
	selectedDirectories?: DataGridTree;
	onSelect?: (ids: string[]) => void;
	onSelectDirectories?: (ids: DataGridTree) => void;
	checkColors?: {[key: string]: string};
	theme?: 'body' | 'neutral';
	onRowMouseEnter?(item: any);
	onRowMouseLeave?(item: any);
	onCellRender?: (item: any, field: Field, details: boolean, index: number) => React.ReactNode;
	onActionRender?: (
		area: 'item' | 'mass' | 'details',
		action: DataGridAction,
		item: any,
		selectedIds: string[],
		fullSize: boolean,
	) => React.ReactNode;
	directoryId?: string;
	path?: Breadcrumb[];
	total?: number;
};

type Props = {
	options: DataGridOptions;
	actionButtons?: DataGridAction[];
	items?: Grid['items'];
	loading?: boolean;
	className?: string;
};

const DataGrid: React.FC<Props> = ({items, options, actionButtons, loading, className}) => {
	const {
		sort,
		sortOrder,
		onChangeSort,
		fields: propsFields,
		selectable,
		checkColors,
		onRowMouseEnter,
		onRowMouseLeave,
		onCellRender,
		onActionRender,
		theme,
	} = options;

	const {
		selectedPageIds,
		partialSelectedPageIds,
		handleSelectAllPage,
		handleSelect,
		SelectAllPanel,
	} = useDataGridSelection(items, options);
	const [fields, setFields] = useState<Field[]>([]);

	const visibleFields = useMemo((): Field[] => {
		return flatFields(fields, true);
	}, [fields]);

	const detailsFields = useMemo((): Field[] => {
		return flatFields(fields, false);
	}, [fields]);

	const renderedVisibleItems = useMemo(() => {
		const renderedItems: Array<PlainObjectOf<React.ReactNode>> = [];
		if (!items) return renderedItems;

		for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
			const item = items[itemIndex];

			for (let fieldIndex = 0; fieldIndex < visibleFields.length; fieldIndex++) {
				const field = visibleFields[fieldIndex];
				if (!renderedItems[itemIndex]) renderedItems[itemIndex] = {};
				renderedItems[itemIndex][field.id] = onCellRender
					? onCellRender(item, field, false, itemIndex)
					: defaultOnCellRender(item, field, false);
			}
		}

		return renderedItems;
	}, [items, visibleFields, onCellRender]);

	const groupFields = useMemo(() => {
		return visibleFields.filter(field => field.isGroupField);
	}, [visibleFields]);

	const groupFieldsIds = useMemo(() => {
		return groupFields.map(field => field.id);
	}, [groupFields]);

	const groupKey = useCallback(
		item => groupFields.map(field => JSON.stringify(getItemByField(field, item))).join(','),
		[groupFields],
	);

	const groups = useMemo(() => {
		if (!groupFieldsIds.length || !items) return null;

		const result: PlainObjectOf<{
			items: Array<PlainObjectOf<any>>;
			renderedVisibleItems: Array<PlainObjectOf<React.ReactNode>>;
		}> = {};
		for (let i = 0; i < items.length; i++) {
			const item = items[i];

			const key = groupKey(item);
			if (result[key]) {
				result[key].items.push(item);
				result[key].renderedVisibleItems.push(renderedVisibleItems[i]);
			} else {
				result[key] = {items: [item], renderedVisibleItems: [renderedVisibleItems[i]]};
			}
		}

		return result;
	}, [renderedVisibleItems, items, groupFieldsIds]);

	useEffect(() => {
		if (propsFields && propsFields.length > 0 && fields.length === 0) {
			setFields([...propsFields].sort((field11, field2) => (field11.order || 0) - (field2.order || 0)));
		}
	}, [propsFields, fields]);

	const gridRef = useRef<HTMLDivElement>(null);

	const [columnsWidth, setColumnsWidth] = useState<Map<string, string>>();

	useEffect(() => {
		const columnsWidth = calculateTableWidths(visibleFields, {
			groupFieldsIds,
			selectable,
			actionButtons: actionButtons?.filter(actionButton => actionButton.itemAction && !actionButton.details),
			hasDetails: detailsFields.length > 0,
		});

		if (columnsWidth) setColumnsWidth(columnsWidth);
	}, [visibleFields, detailsFields]);

	let groupItemsCounter = 0;

	const [openGroups, setOpenGroups] = useState<string[]>([]);

	const openAllGroups = useMemo(() => {
		if (!groups) return false;
		const groupsIds = Object.keys(groups);
		return groupsIds.length === openGroups.length;
	}, [groups, openGroups]);

	const handleToggleGroups = useCallback(() => {
		if (!groups) return;
		const groupsIds = Object.keys(groups);
		if (groupsIds.length !== openGroups.length) setOpenGroups(groupsIds);
		else setOpenGroups([]);
	}, [openGroups, setOpenGroups, groups]);

	const headOptions: TableHeadOptions = useMemo(() => {
		const isSelectedAll = items && items.length > 0 && selectedPageIds && selectedPageIds.length === items.length;
		return {
			visibleFields,
			hasDetails: detailsFields.length > 0,
			onChangeSort,
			sort,
			sortOrder,
			selectable,
			isSelectedAll,
			isPartialSelect: items && items.length > 0 && !isSelectedAll && selectedPageIds.length > 0,
			onSelectAll: handleSelectAllPage,
			selectedIds: selectedPageIds || [],
			hasGroupFields: groupFieldsIds.length > 0,
			onToggleGroups: handleToggleGroups,
			openAllGroups,
			onActionRender,
		};
	}, [
		groupFieldsIds,
		handleToggleGroups,
		openAllGroups,
		visibleFields,
		onChangeSort,
		sort,
		sortOrder,
		selectable,
		selectedPageIds,
		handleSelectAllPage,
		columnsWidth,
		detailsFields,
	]);

	return (
		<>
			<div className={cls(css.wrapper, className)} ref={gridRef}>
				<div
					role="grid"
					aria-rowcount={items?.length || 0}
					aria-colcount={visibleFields.length}
					aria-readonly="true"
					className={cls(
						css.grid,
						css[`${theme || 'body'}Theme`],
						!loading && (items?.length === 0 || visibleFields.length === 0) && css.noItems,
					)}
					style={{
						gridTemplateColumns: columnsWidth ? Array.from(columnsWidth.values()).join(' ') : '1fr 1fr 1fr',
					}}
				>
					{visibleFields.length === 0 && loading ? (
						<DataGridShimmer header />
					) : visibleFields.length === 0 ? (
						<div className={css.message}>Пока тут ничего нет</div>
					) : (
						<>
							<DataGridHead options={headOptions} actionButtons={actionButtons} />
							{SelectAllPanel}

							<div className={css.body}>
								{loading || !items || !columnsWidth ? (
									<DataGridShimmer
										options={{visibleFields, selectable, detailsFields}}
										actionButtons={actionButtons}
									/>
								) : items.length === 0 ? (
									<div className={css.message}>Пока тут ничего нет</div>
								) : groupFieldsIds.length > 0 && groups ? (
									Object.keys(groups).map(groupId => {
										const groupItems = groups[groupId];
										const group = (
											<DataGridRowGroup
												key={groupId}
												group={groupItems}
												rowOptions={{
													detailsFields,
													visibleFields,
													selectable,
													onSelect: handleSelect,
													selectedIds: selectedPageIds,
													partialSelectedIds: partialSelectedPageIds,
													onMouseEnter: onRowMouseEnter,
													onMouseLeave: onRowMouseLeave,
													onCellRender,
													onActionRender,
												}}
												actionButtons={actionButtons}
												options={{
													groupId,
													groupFieldsIds,
													checkColors,
													openGroups,
													onToggle: setOpenGroups,
												}}
												index={groupItemsCounter}
											/>
										);

										groupItemsCounter += groupItems.items.length + 1;

										return group;
									})
								) : (
									items.map((item, index) => (
										<DataGridRow
											key={index}
											actionButtons={actionButtons}
											item={item}
											renderedVisibleItem={renderedVisibleItems[index]}
											index={index}
											options={{
												detailsFields,
												visibleFields,
												selectable,
												onSelect: handleSelect,
												selectedIds: selectedPageIds,
												partialSelectedIds: partialSelectedPageIds,
												checkColor: checkColors && item.id && checkColors[item.id],
												onMouseEnter: onRowMouseEnter,
												onMouseLeave: onRowMouseLeave,
												onCellRender,
												onActionRender,
											}}
										/>
									))
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default DataGrid;
