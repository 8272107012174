import Selector from '../../pirsInputs/Selector/Selector.formik';
import * as React from 'react';
import css from './AsLinksFilters.module.css';
import {FormikProps} from 'formik';
import InputDateTime from '../../pirsInputs/InputDateTime/InputDateTime.formik';
import {duration} from '../../../utils/date-utils';
import {
	asItemToHtml,
	AsLinksFilterMultiCombobox,
	bgpAgentToHtml,
	bgpOperatorToHtml,
	countryToHtml,
	irrToHtml,
	useComboboxItems,
} from '../../Comboboxes/Comboboxes';
import Combobox from '../../pirsInputs/Combobox/Combobox.formik';
import InputNumber from '../../pirsInputs/InputNumber/InputNumber.formik';
import cls from '../../../utils/cls';
import {useCallback} from 'react';
import Button from '../../pirsInputs/Button/Button';
import Slider from '../../pirsInputs/Slider/Slider.formik';

export enum Views {
	neighbors = 'neighbors',
	connections = 'connections',
	network = 'network',
	neighborsAbroad = 'neighborsAbroad',
}

export type Filters = {
	view: Views;
	asIds: AsObject[];
	hops: number;
	hopCount: number;
	linkCount: number;
	asInclude: AsObject[];
	asExclude: AsObject[];
	countryInclude: Country[];
	countryExclude: Country[];
	registryInclude: Irr[];
	registryExclude: Irr[];
	asAId: AsObject | null;
	asBIds: AsObject[];
	countries: Country[];
	registries: Irr[];
	duration: number | '';
	startAt?: string;
	endAt?: string;
	firstHop?: number;
	nextHops?: number;
	bgpAgents: BgpAgent[];
	bgpOperator: BgpOperator | null;
};

export const initFilters: Filters = {
	view: Views.connections,
	asIds: [],
	hops: 1,
	hopCount: 2,
	linkCount: 10,
	asInclude: [],
	asExclude: [],
	countryInclude: [],
	countryExclude: [],
	registryInclude: [],
	registryExclude: [],
	asAId: null,
	asBIds: [],
	countries: [],
	registries: [],
	duration: '',
	bgpAgents: [],
	bgpOperator: null,
	firstHop: 10,
	nextHops: 2,
	endAt: '',
	startAt: '',
};

export type Props = {
	formikBag: FormikProps<Filters>;
	onChangeView: () => void;
	expanded: boolean;
	toggleExpanded: () => void;
};

export const viewItems = {
	[Views.connections]: 'Связи',
	[Views.neighborsAbroad]: 'Пересечения',
	[Views.neighbors]: 'Соседи',
	[Views.network]: 'Топология',
};

export type AsObject = {
	id: string;
	name: string;
	number: number;
	cc: string;
};

export type BgpOperator = {
	id: string;
	org_inn: string;
	org_short_name: string;
};

export type BgpAgent = {
	id: string;
	org_inn: string;
	name: string;
};

export type Country = {
	id: string;
	name: string;
	a2_code: string;
};

export type Irr = {
	id: string;
	name: string;
	code: string;
};

const AsLinksFilters: React.FC<Props> = ({formikBag, onChangeView, expanded, toggleExpanded}) => {
	const {values, setFieldValue} = formikBag;

	const handleChangeBgpOperator = useCallback(
		_ => {
			setFieldValue('bgpAgents', []);
		},
		[setFieldValue],
	);

	const getAsItems = useComboboxItems<AsObject>('objectsAs');
	const getBgpOperator = useComboboxItems<BgpOperator>('bgpOperator', 0);

	const expandButton = (
		<Button secondary type={'button'} onClick={toggleExpanded}>
			{expanded ? 'Меньше' : 'Больше'}
		</Button>
	);

	const ViewFilter = (
		<Selector
			name={'view'}
			items={Object.keys(viewItems)}
			itemToString={item => item && viewItems[item]}
			label={'Представление'}
			onCustomChange={onChangeView}
		/>
	);

	const AsIdsFilter = (
		<AsLinksFilterMultiCombobox<AsObject>
			name={'asIds'}
			itemToHtml={asItemToHtml}
			label={'Номера АС'}
			itemToString={value => value?.name || ''}
			model={'objectsAs'}
		/>
	);

	const HopsFilter = <Slider name={'hops'} min={1} max={5} label={'Глубина связей'} />;
	const FirstHopFilter = <InputNumber name={'firstHop'} min={0} label="Связей 1-го уровня" />;
	const NextHopsFilter = <InputNumber name={'nextHops'} min={0} label="Связей последующих уровней" />;
	const AsIncludeFilter = (
		<AsLinksFilterMultiCombobox<AsObject>
			name={'asInclude'}
			itemToHtml={asItemToHtml}
			label={'Включить только АС'}
			itemToString={value => value?.name || ''}
			model={'objectsAs'}
		/>
	);
	const AsExcludeFilter = (
		<AsLinksFilterMultiCombobox<AsObject>
			name={'asExclude'}
			itemToHtml={asItemToHtml}
			label={'Исключить АС'}
			itemToString={value => value?.name || ''}
			model={'objectsAs'}
		/>
	);
	const CountryIncludeFilter = (
		<AsLinksFilterMultiCombobox<Country>
			name={'countryInclude'}
			itemToHtml={countryToHtml}
			label={'Включить только страны'}
			itemToString={value => value?.name || ''}
			model={'country'}
			minLength={2}
		/>
	);
	const CountryExcludeFilter = (
		<AsLinksFilterMultiCombobox<Country>
			name={'countryExclude'}
			itemToHtml={countryToHtml}
			label={'Исключить страны'}
			itemToString={value => value?.name || ''}
			model={'country'}
			minLength={2}
		/>
	);

	const RegistryIncludeFilter = (
		<AsLinksFilterMultiCombobox<Irr>
			name={'registryInclude'}
			itemToHtml={irrToHtml}
			label={'Включить только регистраторы'}
			itemToString={value => value?.name || ''}
			model={'irr'}
		/>
	);

	const RegistryExcludeFilter = (
		<AsLinksFilterMultiCombobox<Irr>
			name={'registryExclude'}
			itemToHtml={irrToHtml}
			label={'Исключить регистраторы'}
			itemToString={value => value?.name || ''}
			model={'irr'}
		/>
	);

	const DurationFilter = (
		<Selector
			name={'duration'}
			items={[
				'',
				60 * 60,
				60 * 60 * 2,
				60 * 60 * 3,
				60 * 60 * 5,
				60 * 60 * 12,
				60 * 60 * 24,
				60 * 60 * 24 * 2,
				60 * 60 * 24 * 3,
				60 * 60 * 24 * 7,
				60 * 60 * 24 * 14,
				60 * 60 * 24 * 30,
				0,
			]}
			itemToString={item => (item === '' ? 'Не выбран' : item === 0 ? 'Выбор' : duration(item as number))}
			label={'Период'}
			onCustomChange={onChangeView}
		/>
	);

	const StartEndAtFilter = values.duration === 0 && (
		<>
			<div className={css.filter}>
				<InputDateTime name={'startAt'} label={'От даты'} />
			</div>
			<div className={css.filter}>
				<InputDateTime name={'endAt'} label={'До даты'} />
			</div>
		</>
	);

	const HopCountFilter = <InputNumber name={'hopCount'} label="Количество узлов" min={0} />;
	const LinkCountFilter = <InputNumber name={'linkCount'} label="Количество связей" min={0} />;

	const AsAIdFilter = (
		<Combobox<AsObject>
			name={'asAId'}
			getItems={getAsItems}
			itemToHtml={asItemToHtml}
			label={'Номер АС А'}
			itemToString={value => value?.name || ''}
		/>
	);
	const AsBIdsFilter = (
		<AsLinksFilterMultiCombobox<AsObject>
			name={'asBIds'}
			itemToHtml={asItemToHtml}
			label={'Номера АС B'}
			itemToString={value => value?.name || ''}
			model={'objectsAs'}
			key={'objectsAs'}
		/>
	);
	const CountriesFilter = (
		<AsLinksFilterMultiCombobox<Country>
			name={'countries'}
			itemToHtml={countryToHtml}
			label={'Страны'}
			itemToString={value => value?.name || ''}
			model={'country'}
			minLength={2}
			key={'countries'}
		/>
	);
	const RegistriesFilter = (
		<AsLinksFilterMultiCombobox<Irr>
			name={'registries'}
			itemToHtml={irrToHtml}
			label={'Регистраторы'}
			itemToString={value => value?.name || ''}
			model={'irr'}
			key={'registries'}
		/>
	);

	const BgpOperatorFilter = (
		<Combobox<BgpOperator>
			name={'bgpOperator'}
			getItems={getBgpOperator}
			itemToHtml={bgpOperatorToHtml}
			label={'Оператор'}
			itemToString={value => value?.org_short_name || ''}
			onCustomChange={handleChangeBgpOperator}
		/>
	);

	const BgpAgentsFilter = (
		<AsLinksFilterMultiCombobox<BgpAgent>
			name={'bgpAgents'}
			itemToHtml={bgpAgentToHtml}
			label={'BGP-агенты'}
			itemToString={value => value?.name || ''}
			model={'bgpAgent'}
			minLength={0}
			key={'bgpAgents'}
			variables={values.bgpOperator ? {filter: `(org_inn eq ${values.bgpOperator?.org_inn})`} : undefined}
		/>
	);

	const NeighborsFilters = (
		<>
			<div className={css.mainFilter}>
				<div className={css.filter}>{ViewFilter}</div>
				<div className={css.filter}>{AsIdsFilter}</div>
				<div className={css.filter}>{HopsFilter}</div>
				<div className={css.expand}>{expandButton}</div>
			</div>
			{expanded && (
				<>
					<div>
						<div className={css.filter}>{BgpOperatorFilter}</div>
						<div className={css.filter}>{BgpAgentsFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{FirstHopFilter}</div>
						<div className={css.filter}>{NextHopsFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{AsIncludeFilter}</div>
						<div className={css.filter}>{AsExcludeFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{CountryIncludeFilter}</div>
						<div className={css.filter}>{CountryExcludeFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{RegistryIncludeFilter}</div>
						<div className={css.filter}>{RegistryExcludeFilter}</div>
					</div>
				</>
			)}
		</>
	);

	const NeighborsAbroadFilters = (
		<>
			<div className={css.mainFilter}>
				<div className={css.filter}>{ViewFilter}</div>
				<div className={css.filter}>{AsIdsFilter}</div>
				<div className={css.expand}>{expandButton}</div>
			</div>
			{expanded && (
				<>
					<div>
						<div className={css.filter}>{DurationFilter}</div>
						<div>{StartEndAtFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{BgpOperatorFilter}</div>
						<div className={css.filter}>{BgpAgentsFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{HopCountFilter}</div>
						<div className={css.filter}>{LinkCountFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{AsIncludeFilter}</div>
						<div className={css.filter}>{AsExcludeFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{CountryIncludeFilter}</div>
						<div className={css.filter}>{CountryExcludeFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{RegistryIncludeFilter}</div>
						<div className={css.filter}>{RegistryExcludeFilter}</div>
					</div>
				</>
			)}
		</>
	);

	const ConnectionsFilters = (
		<>
			<div className={css.mainFilter}>
				<div className={css.filter}>{ViewFilter}</div>
				<div className={css.filter}>{AsAIdFilter}</div>
				<div className={css.filter}>{AsBIdsFilter}</div>
				<div className={css.expand}>{expandButton}</div>
			</div>
			{expanded && (
				<>
					<div>
						<div className={css.filter}>{DurationFilter}</div>
						<div>{StartEndAtFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{BgpOperatorFilter}</div>
						<div className={css.filter}>{BgpAgentsFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{HopCountFilter}</div>
						<div className={css.filter}>{LinkCountFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{AsIncludeFilter}</div>
						<div className={css.filter}>{AsExcludeFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{CountryIncludeFilter}</div>
						<div className={css.filter}>{CountryExcludeFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{RegistryIncludeFilter}</div>
						<div className={css.filter}>{RegistryExcludeFilter}</div>
					</div>
				</>
			)}
		</>
	);

	const NetworkFilters = (
		<>
			<div className={css.mainFilter}>
				<div className={css.filter}>{ViewFilter}</div>
				<div className={css.filter}>{CountriesFilter}</div>
				<div className={css.filter}>{AsIdsFilter}</div>
				<div className={css.expand}>{expandButton}</div>
			</div>
			{expanded && (
				<>
					<div>
						<div className={css.filter}>{RegistriesFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{BgpOperatorFilter}</div>
						<div className={css.filter}>{BgpAgentsFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{AsIncludeFilter}</div>
						<div className={css.filter}>{AsExcludeFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{CountryIncludeFilter}</div>
						<div className={css.filter}>{CountryExcludeFilter}</div>
					</div>
					<div>
						<div className={css.filter}>{RegistryIncludeFilter}</div>
						<div className={css.filter}>{RegistryExcludeFilter}</div>
					</div>
				</>
			)}
		</>
	);

	return (
		<div className={cls(css.filters, expanded && css.expanded)}>
			{values.view === Views.connections
				? ConnectionsFilters
				: values.view === Views.neighbors
				? NeighborsFilters
				: values.view === Views.neighborsAbroad
				? NeighborsAbroadFilters
				: NetworkFilters}
		</div>
	);
};

export default AsLinksFilters;
