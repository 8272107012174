import React, {useCallback, useState} from 'react';
import formCss from '../../../components/FullTable/FullTableForm.module.css';
import css from './SettingsReportLaunchForm.module.css';
import {Form, FormikErrors, FormikProvider, useFormik} from 'formik';
import Button from '../../../components/pirsInputs/Button/Button';
import Selector from '../../../components/pirsInputs/Selector/Selector.formik';
import {InputLabel} from '../../../components/FullTable/FullTableFormHelpers';
import {
	Field,
	TableColumnType,
	useModelItemsQuery,
	useReportConfigurationQuery,
} from '../../../queries-generated/types';
import ArrayInput from '../../../components/pirsInputs/ArrayInput/ArrayInput.formik';
import Checkbox from '../../../components/pirsInputs/Checkbox/Checkbox.formik';
import ErrorSnippet from '../../../components/ErrorSnippet/ErrorSnippet';
import DashboardFields from '../../../components/settings/views/ViewEdit/constructor/form/DashboardFields';
import ProgressRing from '../../../components/controlls/Loader/ProgressRing';
import {filterObject, JSONSafeParse, setValueByPath} from '../../../utils/object-utils';
import {
	filtersToDataSourceParams,
	validateFields,
	VALIDATION_REQUIRED_TEXT,
} from '../../../components/settings/views/components/helper';

enum ScheduleType {
	ONE_TIME = 'Однократный',
	SCHEDULE = 'По расписанию',
}

enum ExportFormat {
	PDF = 'pdf',
	EXCEL = 'excel',
	WORD = 'word',
}

enum PaperSize {
	A3 = 'A3',
	A4 = 'A4',
	A5 = 'A5',
}

enum PageOrientation {
	PORTRAIT = 'portrait', // книжная
	LANDSCAPE = 'landscape', // альбомная
}

enum MailingType {
	NOT_SET = 'Не задан',
	NOTIFICATION_GROUP = 'Группа рассылки',
	MAIL_LIST = 'Список адресов',
}

const scheduleTypeItems = Object.values(ScheduleType);
const exportFormatItems = Object.values(ExportFormat);
const mailingTypeItems = Object.values(MailingType);
const paperSizeItems = Object.values(PaperSize);
const pageOrientationItems = Object.values(PageOrientation);

type Values = {
	report: {id: string};
	export_format: ExportFormat;
	schedule_type: ScheduleType; // Локальный ключ для определения обязательности schedule
	mailing_type: MailingType; // Локальный ключ для определения списка рассылки
	send_as_link: boolean;
	filters: PlainObjectOf<any>; // Общие фильтры из конфигурации дашборда на основе template id
	schedule?: PlainObjectOf<any> | null;
	notification_group?: PlainObjectOf<any> | null;
	notification_list?: string[] | null;
	documentProperties?: {size: PaperSize; orientation: PageOrientation};
};

type Props = {
	templateId: string;
	onSubmit: (values: any) => void;
	submitLoading: boolean;
	onClose: () => void;
};

const DEFAULT_EXPORT_FORMAT = ExportFormat.EXCEL;

const SettingsReportLaunchForm: React.FC<Props> = ({onSubmit, submitLoading, onClose, templateId}) => {
	const {data: scheduleItems} = useModelItemsQuery({variables: {model: 'schedule'}, fetchPolicy: 'network-only'});
	const {data: recipientGroupItems} = useModelItemsQuery({
		variables: {model: 'recipientGroup'},
		fetchPolicy: 'network-only',
	});

	const [reportConfigurationFields, setReportConfigurationFields] = useState<Array<Field & {name: string}>>([]);

	const formikBag = useFormik<Values>({
		initialValues: {
			report: {id: templateId},
			export_format: DEFAULT_EXPORT_FORMAT,
			schedule_type: ScheduleType.ONE_TIME,
			mailing_type: MailingType.NOT_SET,
			send_as_link: false,
			filters: {},
			schedule: null,
			notification_group: null,
			notification_list: null,
			documentProperties:
				DEFAULT_EXPORT_FORMAT === ExportFormat.EXCEL
					? {size: PaperSize.A4, orientation: PageOrientation.PORTRAIT}
					: undefined,
		},
		onSubmit: (values: Values) => {
			onSubmit({
				...filterObject(values, value => value !== null), // не отправлять бэку null
				filters: filtersToDataSourceParams(values.filters),
				schedule_type: undefined,
				mailing_type: undefined,
			});
		},
		validate: values => {
			const errors: FormikErrors<Values> = {};

			if (!values.export_format) {
				errors.export_format = VALIDATION_REQUIRED_TEXT;
			}

			if (values.export_format === ExportFormat.PDF || values.export_format === ExportFormat.WORD) {
				if (!values.documentProperties?.size) {
					setValueByPath(errors, 'documentProperties.size', VALIDATION_REQUIRED_TEXT);
				}
				if (!values.documentProperties?.orientation) {
					setValueByPath(errors, 'documentProperties.orientation', VALIDATION_REQUIRED_TEXT);
				}
			}

			if (!values.schedule_type) {
				errors.schedule_type = VALIDATION_REQUIRED_TEXT;
			}

			if (!values.mailing_type) {
				errors.mailing_type = VALIDATION_REQUIRED_TEXT;
			}

			if (values.mailing_type === MailingType.NOTIFICATION_GROUP && !values.notification_group) {
				errors.notification_group = VALIDATION_REQUIRED_TEXT;
			}

			if (
				values.mailing_type === MailingType.MAIL_LIST &&
				(!values.notification_list ||
					(Array.isArray(values.notification_list) && !values.notification_list.length))
			) {
				errors.notification_list = VALIDATION_REQUIRED_TEXT;
			}

			if (reportConfigurationFields.length > 0) {
				const filtersErrors = validateFields(reportConfigurationFields, values.filters);

				if (Object.keys(filtersErrors).length) errors.filters = filtersErrors;
			}

			return errors;
		},
	});
	const {documentProperties, export_format, mailing_type, schedule_type} = formikBag.values;

	const {
		loading: reportConfigurationLoading,
		error: reportConfigurationError,
		refetch: reportConfigurationRefetch,
	} = useReportConfigurationQuery({
		variables: {id: templateId},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'network-only',
		onCompleted: result => {
			const content = result.reportConfiguration.content;
			if (content) {
				const parsedData = JSONSafeParse(content);
				if (parsedData && typeof parsedData === 'object' && parsedData.commonFilters) {
					setReportConfigurationFields(parsedData.commonFilters);

					const filters = parsedData.commonFilters.reduce((filtersResult, commonFilter) => {
						filtersResult[commonFilter.id] = commonFilter.defaultValue ?? '';
						return filtersResult;
					}, {});
					formikBag.setFieldValue('filters', filters);
				}
			}
		},
	});

	const handleChangeScheduleType = useCallback(scheduleType => {
		if (scheduleType === ScheduleType.ONE_TIME) {
			formikBag.setFieldValue('schedule', null);
		}
	}, []);

	const handleChangeMailingType = useCallback((mailingType: MailingType): void => {
		switch (mailingType) {
			case MailingType.NOT_SET:
				formikBag.setFieldValue('notification_group', null);
				formikBag.setFieldValue('notification_list', null);
				break;
			case MailingType.NOTIFICATION_GROUP:
				formikBag.setFieldValue('notification_list', null);
				break;
			case MailingType.MAIL_LIST:
				formikBag.setFieldValue('notification_group', null);
				break;
		}
	}, []);

	const handleChangeExportFormat = useCallback(
		(exportFormat: ExportFormat) => {
			if (exportFormat === ExportFormat.PDF || exportFormat === ExportFormat.WORD) {
				if (!documentProperties?.size) {
					formikBag.setFieldValue('documentProperties.size', PaperSize.A4);
				}
				if (!documentProperties?.orientation) {
					formikBag.setFieldValue('documentProperties.orientation', PageOrientation.PORTRAIT);
				}
			} else {
				formikBag.setFieldValue('documentProperties', undefined);
			}
		},
		[documentProperties?.size, documentProperties?.orientation],
	);

	return (
		<>
			<FormikProvider value={formikBag}>
				<Form>
					<div className={css.mainBlock}>
						<ul className={formCss.fields}>
							<li>
								<Selector<any>
									label={<InputLabel title={'Формат'} required={true} />}
									items={exportFormatItems}
									name={'export_format'}
									onCustomChange={handleChangeExportFormat}
								/>
							</li>
							{(export_format === ExportFormat.PDF || export_format === ExportFormat.WORD) && (
								<>
									<li>
										<Selector<any>
											label={<InputLabel title={'Размер страницы'} required={true} />}
											items={paperSizeItems}
											name={'documentProperties.size'}
										/>
									</li>
									<li>
										<Selector<any>
											label={<InputLabel title={'Ориентация страницы'} required={true} />}
											items={pageOrientationItems}
											name={'documentProperties.orientation'}
											itemToString={item =>
												item === PageOrientation.PORTRAIT
													? 'книжная'
													: item === PageOrientation.LANDSCAPE
													? 'альбомная'
													: ''
											}
										/>
									</li>
								</>
							)}
							<li>
								<Selector<any>
									label={<InputLabel title={'Тип выполнения'} required={true} />}
									items={scheduleTypeItems}
									name={'schedule_type'}
									onCustomChange={handleChangeScheduleType}
								/>
							</li>
							{schedule_type === ScheduleType.SCHEDULE && (
								<li>
									<Selector<any>
										label={'Расписание'}
										items={scheduleItems?.modelItems.items || []}
										name={'schedule'}
										itemToString={item => item?.name || ''}
									/>
								</li>
							)}
							<li>
								<Checkbox name={'send_as_link'} label={'Отправить ссылку вместо файла'} />
							</li>
						</ul>
						<ul className={formCss.fields}>
							<li>
								<Selector<any>
									label={<InputLabel title={'Список рассылки'} required={true} />}
									items={mailingTypeItems}
									name={'mailing_type'}
									onCustomChange={handleChangeMailingType}
								/>
							</li>
							{mailing_type === MailingType.NOTIFICATION_GROUP && (
								<li>
									<Selector<any>
										label={<InputLabel title={'Группа рассылки'} required={true} />}
										items={recipientGroupItems?.modelItems.items || []}
										name={'notification_group'}
										itemToString={item => item?.name || ''}
									/>
								</li>
							)}
							{mailing_type === MailingType.MAIL_LIST && (
								<li>
									<ArrayInput
										label={<InputLabel title={'Адрес для рассылки'} required={true} />}
										columnType={TableColumnType.String}
										name={'notification_list'}
									/>
								</li>
							)}
						</ul>
					</div>
					<div>
						<div className={css.reportHeader}>Параметры отчета</div>
						{reportConfigurationLoading ? (
							<ProgressRing />
						) : reportConfigurationError ? (
							<ErrorSnippet
								error={reportConfigurationError}
								refetch={reportConfigurationRefetch}
								errorHeader={'Ошибка загрузки параметров отчета'}
							/>
						) : reportConfigurationFields.length > 0 ? (
							<ul className={formCss.fields}>
								<DashboardFields
									fields={reportConfigurationFields.map(field => ({
										...field,
										name: `filters.${field.id}`,
									}))}
								/>
							</ul>
						) : (
							'Параметры отчета отсутствуют'
						)}
					</div>
					<ul className={formCss.buttons}>
						<li>
							<Button
								type={'submit'}
								disabled={submitLoading || reportConfigurationLoading || !!reportConfigurationError}
								loading={submitLoading}
							>
								Выполнить
							</Button>
						</li>
						<li>
							<Button onClick={onClose} secondary>
								Отмена
							</Button>
						</li>
					</ul>
				</Form>
			</FormikProvider>
		</>
	);
};

export default SettingsReportLaunchForm;
