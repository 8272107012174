import React from 'react';
import css from './Button.module.css';
import cls from '../../../utils/cls';
import {isSafeRoute} from '../../SafeLink/SafeLink';
import SmallLoader from '../../controlls/SmallLoader/SmallLoader';
import {Link} from 'react-named-hooks-router';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
	React.AnchorHTMLAttributes<HTMLAnchorElement> & {
		secondary?: boolean;
		action?: boolean;
		link?: boolean;
		iconOnly?: boolean;
		checked?: boolean;
		route?: string;
		target?: HTMLAnchorElement['target'];
		href?: HTMLAnchorElement['href'];
		download?: boolean | string;
		params?: {[key: string]: string | undefined};
		disabled?: boolean;
		subText?: string | JSX.Element;
		onClick?: (event: React.MouseEvent) => void;
		className?: string;
		loading?: boolean;
		setRef?: React.RefObject<HTMLButtonElement | HTMLAnchorElement>;
		visibleFocus?: boolean;
	};

const Button: React.FC<ButtonProps> = p => {
	const {
		route,
		params,
		target,
		href,
		download,
		children,
		subText,
		onClick,
		secondary,
		action,
		link,
		iconOnly,
		checked,
		className,
		loading,
		setRef,
		visibleFocus,
		type = 'button',
		title,
		...props
	} = p;

	const clsName = cls(
		css.button,
		visibleFocus && css.visibleFocus,
		secondary ? css.secondary : action ? css.textButton : link ? css.linkButton : css.primary,
		checked && css.checked,
		props.disabled && css.disabled,
		iconOnly && css.iconButton,
		className && !!css[className] ? css[className] : className,
		loading && css.loading,
	);

	const content = (
		<>
			<div className={css.primaryFont}>{children}</div>
			{subText && <div className={css.secondaryFont}>{subText}</div>}
		</>
	);

	if (loading) {
		return (
			<div className={clsName}>
				<div className={css.loader}>
					<SmallLoader theme={action || secondary ? 'normal' : 'button'} />
				</div>
				<div className={css.loadedContent}>{content}</div>
			</div>
		);
	}
	if (route && !props.disabled) {
		const buttonProps = {subText, secondary, iconOnly, action, link, checked};

		return isSafeRoute(route) ? (
			<Link
				route={route}
				title={title}
				params={params}
				target={target}
				className={clsName}
				tabIndex={props.tabIndex}
				onClick={onClick as any}
				onKeyDown={props.onKeyDown}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
			>
				<span ref={setRef}>{content}</span>
			</Link>
		) : (
			<Button {...props} {...buttonProps} className={css.unsafeRoute} title={'Ссылка недоступна'} disabled>
				{content}
			</Button>
		);
	}

	if (href) {
		return (
			<a
				href={href}
				download={download}
				target={target}
				className={clsName}
				onClick={onClick as any}
				tabIndex={props.tabIndex}
				onKeyDown={props.onKeyDown}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
			>
				<span ref={setRef}>{content}</span>
			</a>
		);
	}

	return (
		<button
			{...props}
			title={title}
			type={type}
			className={clsName}
			onClick={onClick}
			ref={setRef as React.RefObject<HTMLButtonElement>}
		>
			{content}
		</button>
	);
};

export default Button;
