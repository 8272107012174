import React, {useCallback} from 'react';
import {CommonInputInterface} from '../../pirsInputs/CommonInputInterface';
import Selector from '../../pirsInputs/Selector/Selector';
import {useOldDictionaryQuery} from '../../../queries-generated/types';
import ProgressRing from '../Loader/ProgressRing';
import Field from '../../pirsInputs/Field/Field';

export interface Props<T> extends CommonInputInterface<T | T[] | null> {
	id?: string;
	/* текущее значение */
	value: T | null;
	/* Название класса */
	className?: string;
	/* Название словаря */
	dictionary: string;
	/* Доступные ключи */
	dictionaryKeys?: string[];
	/* Доступные значения */
	dictionaryValues?: string[];
	/* выбранное значение в селекте */
	onValueChange?: (value: string | null) => void;
}

function OldDictionarySelector<T>(props: Props<T>) {
	const {onChange, onValueChange, dictionary, dictionaryKeys, dictionaryValues, ...otherProps} = props;
	const {error, loading, data} = useOldDictionaryQuery({
		variables: {
			id: dictionary,
		},
	});

	const handleChange = useCallback(
		(val: T | T[] | null) => {
			if (onChange) onChange(val);
			if (onValueChange) {
				const findKey = Array.isArray(val) ? val[0] : val || null;
				const value =
					(findKey &&
						typeof findKey === 'string' &&
						data?.oldDictionary.find(item => item.key === findKey)?.value) ||
					null;
				onValueChange(value);
			}
		},
		[onChange, onValueChange, data],
	);

	if (error) return <>Error</>;

	const items = data?.oldDictionary
		.filter(item => (dictionaryKeys && dictionaryKeys.includes(item.key)) || !dictionaryKeys)
		.filter(item => (dictionaryValues && dictionaryValues.includes(item.value)) || !dictionaryValues)
		.map(item => item.key);
	const itemToHtml = key => {
		const item = data?.oldDictionary.find(item => item.key === key);
		if (item) return item.value;
		else return key;
	};

	return loading ? (
		<Field commonProps={otherProps} topLabel={true} style={{height: 30, marginTop: 29}}>
			<ProgressRing />
		</Field>
	) : (
		<Selector onChange={handleChange} {...otherProps} items={(items as any) || []} itemToString={itemToHtml} />
	);
}

export default OldDictionarySelector;
