import React, {useCallback, useEffect, useRef} from 'react';
import {Keys} from '../../utils/key-enum';

export type FocusLockProps = {
	onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
	focusOnOpen?: boolean;
	onEscClick?(): void;
	// Отключает только возврат фокуса
	disabled?: boolean;
	// При событии onBlur или onEscClick фокус вернется на указанный элемент
	returnFocusRef?: React.RefObject<HTMLElement>;
};

const FocusLock: React.FC<FocusLockProps> = ({children, focusOnOpen, onEscClick, onBlur, returnFocusRef, disabled}) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const refForStartFocus = useRef<HTMLDivElement>(null);
	const lastFocusableElementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (
			focusOnOpen &&
			refForStartFocus.current &&
			wrapperRef.current &&
			// Для случаев когда фокус устанавливается за ранее внутри вложенных элементов
			!wrapperRef.current.contains(document.activeElement)
		) {
			refForStartFocus.current.focus();
		}
	}, [focusOnOpen]);

	const handleFocus = useCallback(
		(divPosition: 'top' | 'bottom') => {
			return (event: React.FocusEvent<HTMLDivElement>) => {
				if (disabled) return;
				if (
					divPosition === 'top' &&
					// Для того чтобы фокус не перепрыгнул на последний элемент во время начальной установки фокуса
					wrapperRef.current?.contains(event.relatedTarget) &&
					event.relatedTarget !== lastFocusableElementRef.current
				) {
					lastFocusableElementRef.current?.focus();
				}
				if (divPosition === 'bottom' && event.relatedTarget !== refForStartFocus.current) {
					refForStartFocus.current?.focus();
				}
			};
		},
		[disabled],
	);

	const handleBlur = useCallback(
		(event: React.FocusEvent<HTMLDivElement>) => {
			returnFocusRef?.current?.focus();
			onBlur && onBlur(event);
		},
		[onBlur],
	);

	return (
		<div
			onKeyDown={event => {
				if (onEscClick && event.keyCode === Keys.ESCAPE) {
					onEscClick();
					returnFocusRef?.current?.focus();
					event.stopPropagation();
				}
			}}
			ref={wrapperRef}
		>
			{/* NOTE: Уход из поповера назадвижении назад */}
			<div tabIndex={0} onBlur={handleBlur} />
			<div tabIndex={0} onFocus={handleFocus('top')} ref={refForStartFocus} />
			{children}
			{/* NOTE: Что бы отловить уход из popup */}
			<div tabIndex={0} onBlur={handleBlur} />
			<div tabIndex={0} onFocus={handleFocus('bottom')} ref={lastFocusableElementRef} />
		</div>
	);
};

export default FocusLock;
