import {useCallback, useState} from 'react';
import {createTraceId} from './createTraceId';

export const downloadFile = async (
	url: string,
	fileName?: string,
	method: string = 'GET',
	body?: PlainObjectOf<any>,
) => {
	return new Promise<void>((resolve, reject) => {
		let fileNameServer = '';
		fetch(url, {
			method,
			headers: {'Content-Type': 'application/json', 'x-traceid': createTraceId('downloadFile')},
			body: body ? JSON.stringify(body) : undefined,
		})
			.then(resp => {
				const header = resp.headers.get('Content-Disposition');
				const parts = header!.split(';');
				fileNameServer = parts[1].split('=')[1];
				if (fileNameServer) {
					fileNameServer = fileNameServer.replace(/"/g, '');
				}
				return resp.blob();
			})
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				a.download = fileName || fileNameServer;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				resolve();
			})
			.catch(reject);
	});
};

type Props = {
	method?: string;
};

export const useDownloadFile = (
	props?: Props,
): [(url: string, fileName?: string, body?: PlainObjectOf<any>) => void, {loading: boolean; error: any}] => {
	const method = props?.method || 'GET';
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const downloadFileCb = useCallback((url: string, fileName?: string, body?: PlainObjectOf<any>) => {
		setLoading(true);
		downloadFile(url, fileName, method, body)
			.then(() => {
				setLoading(false);
			})
			.catch(e => {
				setLoading(false);
				setError(e);
			});
	}, []);

	return [downloadFileCb, {loading, error}];
};
