const parseHeaders = (rawHeaders: any) => {
	const headers = new Headers();
	// Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
	// https://tools.ietf.org/html/rfc7230#section-3.2
	const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
	preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
		const parts = line.split(':');
		const key = parts.shift().trim();
		if (key) {
			const value = parts.join(':').trim();
			headers.append(key, value);
		}
	});
	return headers;
};

const fetchWithProgress = (
	url: string,
	options: RequestInit & {
		onAbortPossible?: (abortCallback: () => void) => void;
		onProgress?: (e: ProgressEvent) => void;
	},
): Promise<Response> => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();

		xhr.addEventListener('error', reject, false);
		xhr.addEventListener('timeout', reject, false);

		xhr.onload = () => {
			const opts: any = {
				status: xhr.status,
				statusText: xhr.statusText,
				headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
			};
			opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
			const body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
			resolve(new Response(body, opts));
		};

		xhr.open(options.method || 'GET', url, true);
		if (options.headers)
			Object.keys(options.headers).forEach(key => {
				xhr.setRequestHeader(key, (options.headers as any)[key]);
			});

		if (xhr.upload && options.onProgress) {
			xhr.upload.onprogress = options.onProgress;
		}
		if (options.onAbortPossible)
			options.onAbortPossible(() => {
				xhr.abort();
			});
		xhr.send(options.body as any);
	});
};

export default fetchWithProgress;
