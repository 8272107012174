import React from 'react';
import {useField, useFormikContext} from 'formik';
import {default as OriginalInput, ComboboxInterface} from './MultiCombobox';

type Props<T> = Omit<ComboboxInterface<T>, 'onChange' | 'value'> & {
	name: string;
	onCustomChange?: (values: T[]) => void;
};

function MultiCombobox<T>(props: Props<T>) {
	const [field, meta] = useField(props as any);
	const {setFieldValue} = useFormikContext();
	const {onCustomChange, ...fieldProps} = props;
	return (
		<OriginalInput
			{...field}
			{...(fieldProps as any)}
			error={meta.touched && meta.error}
			onChange={value => {
				setFieldValue(field.name as never, value);
				if (onCustomChange) onCustomChange(value as T[]);
			}}
		/>
	);
}

export default MultiCombobox;
