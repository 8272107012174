import React, {useCallback, useMemo, useState} from 'react';
import css from './AlertsSuperAnomaliesActivePage.module.css';
import MainLayout from '../../components/layouts/MainLayout';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import {useRouter} from 'react-named-hooks-router';
import AlertsSuperAnomaliesActiveControls from './AlertsSuperAnomaliesActiveControls';
import {DashboardIcon, FilterIcon, NestingIcon} from '../../components/SvgIcon';
import SuperAnomalyFiltersWrapper from './Forms/SuperAnomalyFiltersWrapper';
import {useDataSourceQuery} from '../../queries-generated/types';
import {useApolloClient} from '@apollo/client';
import {resolveFiltersFromRouter} from '../../components/settings/views/components/helper';
import AlertsSuperAnomaliesActiveForm from './AlertsSuperAnomaliesActiveForm';
import Button from '../../components/pirsInputs/Button/Button';
import ModalPopup from 'components/controlls/ModalPopup/ModalPopup';
import {getResourceAsLink, getSeverity} from '../../components/FullTable/CellCallbackHelpers';
import InputSearch from '../../components/pirsInputs/InputSearch/InputSearch';
import RightPanel from '../../components/RightPanel/RightPanel';
import useToggle from '../../components/hooks/useToggle';

export enum SuperAnomalyActionType {
	IGNORED = 'IGNORED',
	CHANGE_PRIORITY = 'CHANGE_PRIORITY',
	CREATE_ISSUE = 'CREATE_ISSUE',
}

export const AnomaliesRoutes = {
	['Аномалии ПАД']: 'alertsSuperAnomaliesActive',
	['Игнорируемые Аномалии ПАД']: 'alertsSuperAnomaliesIgnored',
	['Приоритет аномалий']: 'padSuperAnomaliesPriorityRule',
	['Инциденты аномалий']: 'padSuperAnomaliesIssueRule',
};

// типы аномалий для которых доступен просмотр распространения
export const ALLOWED_ANOMALY_TYPE_IDS_FOR_ALERTS_SPREAD = [
	'0', // Петля через границу по данным BGP
	'4', // Утечка маршрута (route leak)
	'5', // Захват маршрута (hijack)
];

const ALLOWED_ANOMALY_TYPE_IDS_FOR_PAD_TASK = [
	'37', // Пониженный трафик на входящем направлении
	'38', // Повышенный трафик на входящем направлении
	'39', // Пониженный трафик на исходящем направлении
	'40', // Повышенный трафик на исходящем направлении
	'41', // Пониженный трафик на входящем направлении (baseline)
	'42', // Повышенный трафик на входящем направлении (baseline)
	'43', // Пониженный трафик на исходящем направлении (baseline)
	'44', // Повышенный трафик на исходящем направлении (baseline)
	'48', // Недоступна AS критического сервиса
	'47', // Недоступна одна из сетей критического сервиса
];

const ALLOWED_ANOMALY_TYPE_IDS_FOR_OPERATOR_INFO = [
	'45', // Отсутствует анонс от отслеживаемого оператора
	'46', // Недоступна сеть отслеживаемого оператора
];

export function getAnomalyDetailRoute(
	anomalyTypeId: string,
): 'alertsSpread' | 'padTaskDetails' | 'alertsSuperAnomaliesResourceOwners' | undefined {
	if (ALLOWED_ANOMALY_TYPE_IDS_FOR_ALERTS_SPREAD.includes(anomalyTypeId)) {
		return 'alertsSpread';
	}
	if (ALLOWED_ANOMALY_TYPE_IDS_FOR_PAD_TASK.includes(anomalyTypeId)) {
		return 'padTaskDetails';
	}
	if (ALLOWED_ANOMALY_TYPE_IDS_FOR_OPERATOR_INFO.includes(anomalyTypeId)) {
		return 'alertsSuperAnomaliesResourceOwners';
	}
	return;
}

export const DETAILS_DISABLE_TEXT = 'Для данного типа аномалии просмотр детализации недоступен';

export function formatFilter(values: PlainObjectOf<any>, additionalFields?: PlainObjectOf<any>[]): string {
	const filter: {boolean: string; set: any[]} = {
		boolean: 'and',
		set: additionalFields ? [...additionalFields] : [],
	};

	Object.keys(values).forEach(key => {
		// todo хардкод для определения ключа для объекта value, убрать когда придумаем нормальное решение
		const filterKey = ['severity', 'status'].includes(key) ? 'val' : 'id';

		if (Array.isArray(values[key]) && values[key].length > 1) {
			filter.set.push({
				boolean: 'or',
				set: values[key].map(value => ({
					field: key,
					operator: '@>',
					type: 'jsonb',
					value: {[filterKey]: value.id},
				})),
			});
		} else {
			const value = Array.isArray(values[key]) ? values[key][0].id : values[key];
			filter.set.push({field: key, operator: '@>', type: 'jsonb', value: {[filterKey]: value}});
		}
	});
	return JSON.stringify(filter);
}

const AlertsSuperAnomaliesActivePage: React.FC = () => {
	const {routeParams, pushRoute, route} = useRouter<{id?: string; filterAsAnomalies?: string}>();
	const skipResolveFiltersFromRouter =
		routeParams.filterAsAnomalies || routeParams.id || Object.entries(routeParams).length === 0;

	const client = useApolloClient();

	const [query, setQuery] = useState<string>();

	const [openFilter, handleToggleFilter] = useToggle();
	const [fastFilterValues, setFastFilterValues] = useState<PlainObjectOf<any>>();

	const [chosenSuperAnomalyActionType, setChosenSuperAnomalyActionType] = useState<SuperAnomalyActionType>();
	const [chosenItems, setChosenItems] = useState<PlainObjectOf<any>[]>([]);

	// Устанавливается в true, когда пользователь применяет фильтры из модального окна
	const [skipInitialFilters, setSkipInitialFilters] = useState<boolean>(false);

	const {loading: resolveFiltersFromRouterLoading} = useDataSourceQuery({
		variables: {ids: ['c6276f52-b2aa-415f-a31d-7a3316f8d524']},
		onCompleted: data => {
			resolveFiltersFromRouter(data.dataSource[0].fields, routeParams, client).then(result => {
				setFastFilterValues(prevState => ({...prevState, ...result}));
			});
		},
		skip: !!skipResolveFiltersFromRouter,
	});

	const confirmMessage = useMemo((): string => {
		switch (chosenSuperAnomalyActionType) {
			case SuperAnomalyActionType.IGNORED:
				return `Вы хотите игнорировать ${
					chosenItems.length > 1 ? `выбранные аномалии (${chosenItems.length}шт.)` : 'выбранную аномалию'
				}?`;
			case SuperAnomalyActionType.CHANGE_PRIORITY:
				return `Вы хотите изменить приоритет ${
					chosenItems.length > 1 ? `выбранных аномалии (${chosenItems.length}шт.)` : 'выбранной аномалии'
				}?`;
			case SuperAnomalyActionType.CREATE_ISSUE:
				return `Вы хотите создать инцидент на основе ${
					chosenItems.length > 1 ? `выбранных аномалии (${chosenItems.length}шт.)` : 'выбранной аномалии'
				}?`;
			default:
				return 'Подтвердите выбранное действие';
		}
	}, [chosenSuperAnomalyActionType, chosenItems]);

	/*
	 * initialFilter - фильтры, которые отправляются на бэк при переходе с других страниц сайта,
	 * их не нужно резолвить в модальное окно с фильтрами, и они должны игнорироваться
	 * если пользователь зашел в модальное окно с фильтрами, выбрал значения и применил их.
	 * */
	const initialFilter = useMemo((): PlainObjectOf<any> | undefined => {
		if (routeParams.id) {
			return {
				field: 'id',
				operator: '=',
				type: 'int',
				value: parseInt(routeParams.id, 10),
			};
		}
		if (routeParams.filterAsAnomalies) {
			return {
				boolean: 'OR',
				set: [
					{
						field: 'as_from',
						operator: '@>',
						type: 'jsonb',
						value: {
							id: routeParams.filterAsAnomalies,
						},
					},
					{
						field: 'as_to',
						operator: '@>',
						type: 'jsonb',
						value: {
							id: routeParams.filterAsAnomalies,
						},
					},
				],
			};
		}
	}, [routeParams]);

	const handleResetInitialRouteParams = useCallback(() => {
		pushRoute(route, {...routeParams, id: undefined, filterAsAnomalies: undefined});
	}, [pushRoute, route, routeParams]);

	const handleSetFastFilterValues = useCallback(
		(values: PlainObjectOf<any>): void => {
			setFastFilterValues(values);
			setSkipInitialFilters(true);
			handleResetInitialRouteParams();
		},
		[setFastFilterValues, setSkipInitialFilters, handleResetInitialRouteParams],
	);

	const handleChoseSuperAnomalyAction = useCallback(
		(superAnomalyType: SuperAnomalyActionType, items): void => {
			setChosenSuperAnomalyActionType(superAnomalyType);
			setChosenItems(items);
		},
		[setChosenSuperAnomalyActionType, setChosenItems],
	);

	const handleClearSuperAnomalyActionType = useCallback((): void => {
		setChosenSuperAnomalyActionType(undefined);
		setChosenItems([]);
	}, [setChosenSuperAnomalyActionType, setChosenItems]);

	const {Table, refresh} = useFullTableWithRefetch('superAnomalyActive', {
		tableFields: [
			'severity',
			'anomaly_type',
			'as_from',
			'as_to',
			'open_date',
			'update_date',
			'close_date',
			'status',
			'incident',
		],
		detailsFields: ['anomaly_family', 'change_date'],
		selectable: true,
		cellCallback: {
			severity: value => getSeverity(value),
			as_from: value => getResourceAsLink(value),
			as_to: value => getResourceAsLink(value),
			status: value => value?.status,
			anomaly_type: value => value?.name,
		},
		detailCellCallback: {
			anomaly_family: value => value?.name,
		},
		additionalControls: item => {
			const detailsRoute = getAnomalyDetailRoute(item.anomaly_type?.id);
			const isPadTaskDetail = detailsRoute === 'padTaskDetails';
			const disabled = !detailsRoute;
			const padTaskDisabledText = `Просмотр детализации недоступен, так как у аномалии "${item.id}" отсутствует task_id`;

			return (
				<>
					<Button
						route={detailsRoute}
						params={{id: isPadTaskDetail ? item.task_id : item.id}}
						title={
							disabled
								? DETAILS_DISABLE_TEXT
								: isPadTaskDetail && !item.task_id
								? padTaskDisabledText
								: 'Детализация'
						}
						disabled={disabled || (isPadTaskDetail && !item.task_id)}
						iconOnly
						action
					>
						<DashboardIcon />
					</Button>
					<Button
						route={'alertsSuperAnomaliesNested'}
						params={{id: item.id}}
						title={'Вложенные аномалии'}
						iconOnly
						action
					>
						<NestingIcon />
					</Button>
					<AlertsSuperAnomaliesActiveControls
						items={[item]}
						onChoseAnomalyAction={handleChoseSuperAnomalyAction}
					/>
				</>
			);
		},
		additionalFilters: (
			<div className={css.filters}>
				<InputSearch value={query} onChange={setQuery} />
				<Button onClick={handleToggleFilter} iconOnly action>
					<FilterIcon />
				</Button>
			</div>
		),
		actionTableHeadCellCallback: selectedItems => {
			return (
				<AlertsSuperAnomaliesActiveControls
					items={selectedItems}
					onChoseAnomalyAction={handleChoseSuperAnomalyAction}
					isHeadCell
				/>
			);
		},
		variables: {
			filter:
				!skipInitialFilters && initialFilter
					? JSON.stringify(initialFilter)
					: fastFilterValues && Object.keys(fastFilterValues).length
					? formatFilter(fastFilterValues)
					: undefined,
			query,
		},
		skip: resolveFiltersFromRouterLoading,
	});

	return (
		<MainLayout routes={AnomaliesRoutes} contextHelpKey={'helpPadAnomaliesActive'}>
			<ModalPopup
				maxWidth={400}
				open={!!chosenSuperAnomalyActionType}
				header={confirmMessage}
				onClose={handleClearSuperAnomalyActionType}
			>
				<AlertsSuperAnomaliesActiveForm
					superAnomalyActionType={chosenSuperAnomalyActionType}
					reFetchTable={refresh}
					items={chosenItems}
					onClose={handleClearSuperAnomalyActionType}
				/>
			</ModalPopup>
			<RightPanel open={openFilter} onClose={handleToggleFilter}>
				<SuperAnomalyFiltersWrapper
					fastFilterDataSourceId={'c6276f52-b2aa-415f-a31d-7a3316f8d524'}
					onSetFastFilterValues={handleSetFastFilterValues}
					fastFilterValues={fastFilterValues}
				/>
			</RightPanel>
			<Table />
		</MainLayout>
	);
};

export default AlertsSuperAnomaliesActivePage;
