import React, {ChangeEvent, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {wordByCount} from '../../../utils/word-by-count';
import uid from '../../../utils/uid';
import cls from '../../../utils/cls';
import css from './Dropdown.modules.css';
import Popover from '../../controlls/PopoverButton/Popover';
import {CommonInputInterface} from '../CommonInputInterface';
import {removeArrayElementByIndex} from '../../../utils/array-utils';
import List from '../../List/List';
import useDropDownControl from './useDropDownControl';

export type ComboboxInterfaceCommon<T, U> = {
	getItems?(input: string): Promise<T[] | null | undefined> | T[] | null | undefined;
	items?: T[];
	value?: U | null;
	name?: string;
	onChange(value: U, originalValue: any);
	onClear?();
	/* значение для одного элемента инпута */
	itemToString?(item: T | null): string;
	/* значение для li */
	itemToHtml?(item: T | null): string | React.ReactNode;
	/* разметка в случае когда инпут не в фокусе */
	itemsToHtml?(value: U | null): React.ReactNode;
	/* Ключ для T */
	itemToPrimaryKey?(value: T): string | number;
	/* Ref для инпута */
	forwardRef?: React.RefObject<HTMLElement> | ((element: HTMLElement | null) => void);
	/* коллбек на открытие/закрытие */
	onToggle?(open: boolean): void;
	/* класс для выпадайки */
	popoverClassName?: string;
	/* класс для инпута */
	inputClassName?: string;
	/* класс для списка элементов */
	listClassName?: string;
	queryable?: boolean;
	searchable?: boolean;
	minLengthQuery?: number;
	inputValue?: string;
	underlined?: boolean;
	/* Стили для инпута */
	inputStyle?: React.CSSProperties;
} & CommonInputInterface<U | null>;

export type ComboboxInterface<T> =
	| ({multiple: true} & ComboboxInterfaceCommon<T, T[]>)
	| ({multiple?: false} & ComboboxInterfaceCommon<T, T>);

type Props<T> = ComboboxInterface<T> &
	Omit<React.HTMLAttributes<HTMLDivElement>, keyof ComboboxInterface<T>> & {onChangeInput?(value: string)};

function useDropDown<T>(props: Props<T>) {
	const {
		itemToHtml: itemToHtmlProps,
		itemToString: itemToStringProps,
		itemToPrimaryKey: itemToPrimaryKeyProps,
		itemsToHtml: itemsToHtmlProps,
		getItems: getItemsProps,
		items: itemsProps,
		minLengthQuery,
		onToggle: onToggleProps,
		queryable,
		disabled,
		forwardRef: forwardRefProps,
		inputClassName,
		listClassName,
		onChangeInput,
		inputValue: inputValueProps,
		underlined,
	} = props;

	const [inputValue, setInputValue] = useState(inputValueProps || '');

	useEffect(() => {
		if (inputValueProps != null && inputValueProps !== inputValue) setInputValue(inputValueProps);
	}, [inputValueProps, inputValue]);

	const fieldRef = useRef<HTMLDivElement>(null);

	const tmpInputRef = useRef<HTMLInputElement>(null);
	const forwardRef = forwardRefProps || tmpInputRef;
	const popoverRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<HTMLDivElement>(null);
	const [items, setItems] = useState<T[]>([]);

	const itemToString = itemToStringProps || (item => (typeof item === 'string' ? item : JSON.stringify(item)));
	const itemToHtml = itemToHtmlProps || itemToString;

	const defaultItemToPrimaryKey = useCallback((value: T) => {
		if (!value) return null;

		if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
			return value;
		}
		if (!('id' in value) && !('code' in value)) {
			throw new Error('У значения нет id. Необходимо указать метод itemToPrimaryKey!');
		}
		return (value as any).id || (value as any).code;
	}, []);
	const itemToPrimaryKey = itemToPrimaryKeyProps || defaultItemToPrimaryKey;

	const itemsToHtmlDefault = useCallback(
		(value: T | T[] | null) => {
			if (props.multiple) {
				if (!value || !(value as T[]).length) {
					return null;
				}
				if ((value as T[]).length === 1) return itemToString(value[0]);
				return (
					<>
						{itemToString((value as T[])[0])} и еще{' '}
						{wordByCount((value as T[]).length - 1, ['значение', 'значения', 'значений'], true)}
					</>
				);
			}
			return itemToString(value as T);
		},
		[props.multiple, itemToString],
	);

	const itemsToHtml = itemsToHtmlProps || itemsToHtmlDefault;

	const handleChange = useCallback(
		(newValue: T) => {
			if (props.multiple) {
				const index =
					props.value &&
					props.value.findIndex(value => itemToPrimaryKey(value) === itemToPrimaryKey(newValue));
				if (typeof index === 'number' && index !== -1 && props.value) {
					props.onChange(removeArrayElementByIndex(props.value, index), newValue);
				} else {
					props.onChange([...(props.value || []), newValue], newValue);
				}
				if ('current' in forwardRef) {
					forwardRef.current?.focus();
				}
			} else {
				props.onChange(newValue, newValue);
				setInputValue('');
				close();
			}
		},
		[props.value, props.multiple, props.onChange, itemToPrimaryKey],
	);

	const handleChangeByIndex = useCallback(
		(index: number) => {
			handleChange(items[index]);
		},
		[items, handleChange],
	);

	const {selectedIndex, hasFocus, onBlur, close, onFocus, onKeyDown, open, onToggle, isOpen} = useDropDownControl({
		listRef,
		fieldRef,
		popoverRef,
		listLength: items.length,
		onOpen: () => getItems(inputValue),
		onToggle: onToggleProps,
		onChangeByIndex: handleChangeByIndex,
	});

	const [loading, setLoading] = useState(false);

	const lastDoneStartTimeRef = useRef(0);

	const search = useCallback(
		(input: string): Promise<T[] | null | undefined> => {
			if (input.length < (minLengthQuery || 0)) return Promise.resolve(null);
			const itemsRaw = getItemsProps ? getItemsProps(input) : itemsProps;
			const isPromise = itemsRaw instanceof Promise;
			if (!itemsRaw) return Promise.resolve(null);
			if (isPromise) setLoading(true);
			// используем время клиента чтобы пропустить результат если он отправился раньше чем уже обработанный
			const startTime = Date.now();
			return Promise.resolve(itemsRaw).then(items => {
				if (isPromise) setLoading(false);
				if (startTime < lastDoneStartTimeRef.current) {
					return;
				}
				lastDoneStartTimeRef.current = startTime;
				if (items == null) return;
				return items;
			});
		},
		[getItemsProps, minLengthQuery, itemsProps],
	);

	const getItems = useCallback(
		async (input: string) => {
			const items = await search(input);
			if (items) setItems(items);
		},
		[search],
	);

	const shownItems: T[] = useMemo(() => {
		if (!props.value || (props.multiple && !props.value.length)) return items;
		if (!queryable) return items;

		const checkedItems = props.multiple ? props.value || [] : props.value ? [props.value] : [];

		const otherItems = items.filter(item => {
			return !checkedItems.some(checkedItem => itemToPrimaryKey(item) === itemToPrimaryKey(checkedItem));
		});

		return [...checkedItems, ...otherItems];
	}, [queryable, items, itemToPrimaryKey, props.value, props.multiple]);

	const handleChangeInput = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const value = (e && e.target && e.target.value) ?? '';
			setInputValue(value);
			getItems(value);
			/*setSelectedIndex(null);
			setOpen(true);*/
			if (onChangeInput) onChangeInput(value);
		},
		[getItems, setInputValue, onChangeInput],
	);

	const id = props.id || 'Input-' + uid();

	const inputProps: HTMLAttributes<HTMLInputElement | HTMLButtonElement> = {
		className: cls(css.inputAsButton, !underlined && css.bordered, underlined && css.underlined, inputClassName),
		disabled: !!disabled,
		onClick: open,
		onFocus,
		onBlur,
		autoCapitalize: 'off',
		'aria-autocomplete': 'both',
		'aria-expanded': isOpen,
		role: 'combobox',
		'aria-owns': id + '-list',
		'aria-labelledby': id + '-label',
		'aria-multiselectable': props.multiple,
		onKeyDown,
		ref: forwardRef,
	} as any;

	const PopoverContent = isOpen && (
		<Popover
			open={true}
			parentRef={fieldRef}
			forwardRef={popoverRef}
			useMaxParentWidth
			className={css.combobox}
			paddingLess
			classNamePositionTop={css.comboboxPositionTop}
			disableFocusLock
		>
			{loading && <div className={css.message}>Загрузка...</div>}
			{shownItems.length === 0 && inputValue.length < (minLengthQuery || 0) && (
				<div className={css.message}>
					Поиск начнется после ввода{' '}
					{wordByCount(minLengthQuery || 0, ['символ', 'символа', 'символов'], true)}
				</div>
			)}
			{!loading && shownItems.length === 0 && <div className={css.message}>Ничего не выбрано</div>}
			{!loading && shownItems.length > 0 && (
				<List<any>
					items={shownItems}
					onRender={itemToHtml as any}
					forwardRef={listRef}
					onClick={handleChange}
					selectItems={props.multiple ? (props.value as T[]) : props.value !== undefined ? [props.value] : []}
					itemFocused={(_item, options) => !!(options && options.index === selectedIndex)}
					selectMode={props.multiple ? 'multiple' : 'single'}
					className={cls(css.list, listClassName)}
				/>
			)}
		</Popover>
	);

	return {
		PopoverContent,
		itemToHtml,
		itemToPrimaryKey,
		itemsToHtml,
		loading,
		fieldRef,
		forwardRef,
		isOpen,
		id,
		hasFocus,
		inputValue,
		close,
		onBlur,
		onToggle,
		setInputValue,
		inputProps,
		onChangeInput: handleChangeInput,
		items,
	};
}

export default useDropDown;
