import * as md5 from 'js-md5';
import {UploadFileContextType} from '../commonRedux/UploadFileStore/UploadFileQueueProvider';
import {UploadFileData, UploadFileStatus} from '../commonRedux/UploadFileStore/UploadFileQueueReducer';
import fetchWithProgress from '../../utils/fetchWithProgress';
import {useCallback} from 'react';
import {createTraceId} from '../../utils/createTraceId';

const chunkLength = Number(process.env.REACT_APP_CHUNK_LENGTH);

export const useUploadFileQueue = state => {
	const {setProgress, setFileStatus} = state as UploadFileContextType;

	/**
	 * url это ручка по которой загружается файл плюс id файла как query параметр.
	 * Например '/services/settingsFilesUploadChunk?id=4j5hkljafdkjhsjhsle'
	 * */

	const readPartAsBase64Encode = useCallback(part => {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const base64data = reader.result?.toString() || '';
				const content = base64data.substring(base64data.indexOf(',') + 1);
				resolve(content);
			};
			reader.onerror = reject;
			reader.readAsDataURL(part);
		});
	}, []);

	const fetchWithReattempt = useCallback(
		async (content: string, size: number, index: number, url: string) => {
			for (let numAttempt = 0; ; numAttempt++) {
				try {
					const response = await fetchWithProgress(url, {
						method: 'POST',
						credentials: 'same-origin',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
							'x-traceid': createTraceId('useUploadFileQueue'),
						},
						body: JSON.stringify({
							content,
							size,
							checkSum: md5(content),
							index,
						}),
						onProgress: e => {
							setProgress({url, loadedChunks: index + 1, percent: (e.loaded * 100) / e.total});
						},
					});
					const jsonResponse = await response.json();
					return Promise.resolve(jsonResponse);
				} catch (e) {
					if (numAttempt === 2) return Promise.reject();
				}
			}
		},
		[setProgress],
	);

	const uploadFileChunks = useCallback(
		async (
			file: File,
			numberOfChunks,
			url: string,
			refFileUploadQueueArray: React.MutableRefObject<UploadFileData[]>,
			uploadFileCallback?: () => void,
		) => {
			for (let chunkNumber = 0; chunkNumber < numberOfChunks; chunkNumber++) {
				const urlStillInQueue = refFileUploadQueueArray.current.some(item => item.url === url);
				if (!urlStillInQueue) return Promise.reject({status: UploadFileStatus.CANCELED, id: url});

				const lastChunk = chunkNumber === numberOfChunks - 1;
				const chunkSize = lastChunk ? file.size - chunkNumber * chunkLength : chunkLength;
				const start = chunkNumber * chunkLength;
				const part = file.slice(start, start + chunkLength);
				try {
					const content = await readPartAsBase64Encode(part);
					await fetchWithReattempt(content, chunkSize, chunkNumber, url);
					setProgress({url, loadedChunks: chunkNumber + 1, percent: 100});
					if (lastChunk) {
						setFileStatus({url, fileStatus: UploadFileStatus.LOADED});
						uploadFileCallback && uploadFileCallback();
						return Promise.resolve({status: UploadFileStatus.LOADED, id: url});
					}
				} catch (error) {
					setFileStatus({
						url,
						fileStatus: UploadFileStatus.ERROR,
						error: 'Ошибка при загрузке чанка!',
					});
					return Promise.reject({status: UploadFileStatus.ERROR, id: url});
				}
			}
		},
		[fetchWithReattempt, readPartAsBase64Encode, setFileStatus, setProgress],
	);

	return {uploadFileChunks};
};
