import {ViewItem} from './api/useViewItemTree';
import React, {useCallback, useContext, useMemo, useRef} from 'react';
import css from './ViewWrapper.module.css';
import ViewComponent from './ViewComponent';
import {flatViewItems, getPollInterval, useRefreshIntervalFromLocalStorage} from '../settings/views/components/helper';
import Filters from './Filters';
import {isMaxSafeINT32} from '../../utils/number-utils';

export enum VirtualDashboardIds {
	REPORT_TRAFFIC_ANALYSIS = 'reportsTrafficAnalysis',
}

type VirtualDashboard = {
	id: string;
	version: number;
};

type VirtualDashboards = {[key: VirtualDashboardIds | string]: VirtualDashboard};

const virtualDashboards: VirtualDashboards = {
	[VirtualDashboardIds.REPORT_TRAFFIC_ANALYSIS]: {
		id: '1',
		version: 1,
	},
};

type Props = {
	root: ViewItem;
	id?: string;
	version?: number;
	virtualDashboardId?: VirtualDashboardIds;
	setReloadCounter: (c: number) => void;
	reloadCounter;
};

type ComponentPropsContextType = {
	setProp: null | ((viewItemId: string, prop: string, value: any) => void);
	setData: null | ((viewItemId: string, data: any) => void);
};
export const ComponentPropsContext = React.createContext<ComponentPropsContextType>({setProp: null, setData: null});

export function useComponentsStoredData(viewItemId?: string) {
	const {setProp, setData} = useContext(ComponentPropsContext);

	const setCurrentProp = useCallback(
		(prop: string, value: any) => {
			setProp && viewItemId && setProp(viewItemId, prop, value);
		},
		[setProp],
	);
	const setCurrentData = useCallback(
		(data: any) => {
			setData && viewItemId && setData(viewItemId, data);
		},
		[setProp],
	);

	return {setProp: setCurrentProp, setData: setCurrentData};
}

const ViewWrapperWithContext: React.FC<Props> = ({
	id,
	root,
	version,
	virtualDashboardId,
	reloadCounter,
	setReloadCounter,
}) => {
	const virtualDashBoard = virtualDashboardId && virtualDashboards[virtualDashboardId];

	const {getRefreshInterval} = useRefreshIntervalFromLocalStorage();

	const componentsProps = useRef<PlainObjectOf<PlainObjectOf<any>>>({});
	const componentsData = useRef<PlainObjectOf<PlainObjectOf<any>>>({});
	const handleSetComponentProp = useCallback((viewItemId: string, prop: string, value: any) => {
		componentsProps.current = {
			...componentsProps.current,
			[viewItemId]: {
				...componentsProps.current[viewItemId],
				[prop]: value,
			},
		};
	}, []);

	const handleSetComponentData = useCallback((viewItemId: string, data: any) => {
		componentsData.current = {
			...componentsData.current,
			[viewItemId]: data,
		};
	}, []);

	const handleGetPollInterval = useCallback(
		(viewItem: ViewItem) => {
			const pollInterval = getPollInterval(
				viewItem,
				root,
				getRefreshInterval(id, version, viewItem.id),
				getRefreshInterval(id, version, 'root'),
			);

			// Если pollInterval > MAX_SAFE_INT32, то запросы зацикливаются
			return isMaxSafeINT32(pollInterval) ? pollInterval : 0;
		},
		[root, id, version, getRefreshInterval],
	);

	const viewItems = useMemo(() => {
		return root ? flatViewItems(root) : [];
	}, [root]);

	return (
		<div className={css.wrapper}>
			<ComponentPropsContext.Provider value={{setProp: handleSetComponentProp, setData: handleSetComponentData}}>
				<ViewComponent
					viewItem={root}
					getPollInterval={handleGetPollInterval}
					componentCommonButtonsProps={{
						viewId: id,
						reloadCounter,
						setReloadCounter,
						componentsData,
						componentsProps,
					}}
				/>
			</ComponentPropsContext.Provider>
			<Filters
				viewItems={viewItems}
				root={root}
				version={virtualDashBoard?.version || version}
				id={virtualDashBoard?.id || id}
			/>
		</div>
	);
};

export default ViewWrapperWithContext;
