export function throttle(func, delay) {
	let timer: number = 0;
	return function(...args) {
		clearTimeout(timer);
		// @ts-ignore
		timer = setTimeout(() => func.apply(this, args), delay);
	};
}

export function rafThrottle(func) {
	// takes a function as parameter
	let busy = false;
	return function(...args) {
		// returning function (a closure)
		if (busy) return; // busy? go away!
		busy = true; // hanging "busy" plate on the door
		// @ts-ignore
		func.call(this, ...args); // calling function
		// using rAF to remove the "busy" plate, when browser is ready
		requestAnimationFrame(function() {
			busy = false;
		});
	};
}
