import React, {useCallback} from 'react';
import formCss from '../../components/FullTable/FullTableForm.module.css';
import {useCreateModelMutation, useModelItemsQuery} from '../../queries-generated/types';
import {Form, FormikErrors, FormikProvider, useFormik} from 'formik';
import TextArea from '../../components/pirsInputs/TextArea/TextArea.formik';
import OldDictionarySelector from '../../components/controlls/OldDictionarySelector/OldDictionarySelector.formik';
import Button from '../../components/pirsInputs/Button/Button';
import {CrossIcon, SaveIcon} from '../../components/SvgIcon';
import Selector from '../../components/pirsInputs/Selector/Selector.formik';
import {notifyToastError} from '../../components/toast/Toast';
import {SuperAnomalyActionType} from './AlertsSuperAnomaliesActivePage';
import AutoComplete from '../../components/pirsInputs/AutoComplete/AutoComplete.formik';
import useRefData from '../../components/settings/views/ViewEdit/constructor/form/useRefData';
import {
	possibleValueToHtml,
	possibleValueToString,
} from '../../components/settings/views/ViewEdit/constructor/form/DashboardField';
import Checkbox from '../../components/pirsInputs/Checkbox/Checkbox.formik';

enum Actions {
	CREATE_TEMPLATE = 'Создать шаблон',
	TAKE_ACTION = 'Выполнить действие',
}

const actionValues = Object.values(Actions);

type SubmitValues = {
	reason: string | null;
	priority?: string;
	anomaly_type?: PlainObjectOf<any>;
	as_from?: string;
	as_to?: string;
	is_create_issue?: boolean;
};

type Values = {
	action?: Actions;
	values: SubmitValues;
};

type Props = {
	items: PlainObjectOf<any>[];
	onClose: () => void;
	reFetchTable: () => void;
	superAnomalyActionType?: SuperAnomalyActionType;
};

const notifyError = (chosenAnomalyActionType: SuperAnomalyActionType | undefined, itemsLength: number): void => {
	switch (chosenAnomalyActionType) {
		case SuperAnomalyActionType.IGNORED:
			notifyToastError(`Ошибка игнорирования ${itemsLength > 1 ? 'выбранных аномалий' : 'выбранной аномалии'}`);
			break;
		case SuperAnomalyActionType.CHANGE_PRIORITY:
			notifyToastError(
				`Ошибка изменения приоритета ${itemsLength > 1 ? 'выбранных аномалий' : 'выбранной аномалии'}`,
			);
			break;
		case SuperAnomalyActionType.CREATE_ISSUE:
			notifyToastError(
				`Ошибка перевода в инцидент ${itemsLength > 1 ? 'выбранных аномалий' : 'выбранной аномалии'}`,
			);
			break;
		default:
			notifyToastError('Ошибка выполения запроса');
	}
};

const VALIDATION_REQUIRED_TEXT = 'Поле обязательно для заполнения';
const DEFAULT_PRIORITY_VALUE = '7';
const AUTOCOMPLETE_MIN_LENGTH = 3;
const AUTOCOMPLETE_HELPER_TEXT = 'Значением может быть * или действительный номер Ас';

const AlertsSuperAnomaliesActiveForm: React.FC<Props> = ({superAnomalyActionType, onClose, items, reFetchTable}) => {
	const [createModel, {loading}] = useCreateModelMutation();

	const getAsItems = useRefData();

	const {data: anomalyTypeData} = useModelItemsQuery({
		variables: {model: 'anomalyType'},
	});
	const anomalyTypeItems = anomalyTypeData?.modelItems.items || [];

	const handleSubmitSuperAnomaly = useCallback(
		(values: SubmitValues): void => {
			const newItems = items.map(item => ({...item, ...values}));
			let model: string = '';

			switch (superAnomalyActionType) {
				case SuperAnomalyActionType.IGNORED: {
					model = 'superAnomalyIgnored';
					break;
				}
				case SuperAnomalyActionType.CREATE_ISSUE:
					model = 'padSuperAnomalyIssueRule';
					break;
				case SuperAnomalyActionType.CHANGE_PRIORITY:
					model = 'padSuperAnomalyPriorityRule';
					break;
			}

			createModel({
				variables: {
					model,
					items: newItems,
				},
			})
				.then(() => {
					onClose();
					reFetchTable();
				})
				.catch(() => {
					notifyError(superAnomalyActionType, items.length);
				});
		},
		[superAnomalyActionType, createModel, items, reFetchTable, onClose],
	);

	const formikBag = useFormik<Values>({
		enableReinitialize: true,
		initialValues: {
			action: superAnomalyActionType === SuperAnomalyActionType.IGNORED ? undefined : Actions.TAKE_ACTION,
			values: {
				reason: null,
				priority:
					superAnomalyActionType === SuperAnomalyActionType.CHANGE_PRIORITY
						? DEFAULT_PRIORITY_VALUE
						: undefined,
				is_create_issue: superAnomalyActionType === SuperAnomalyActionType.CREATE_ISSUE ? true : undefined,
			},
		},
		onSubmit: (values: Values) => {
			handleSubmitSuperAnomaly(values.values);
		},
		validate: values => {
			const errors: FormikErrors<Values> = {};

			if (superAnomalyActionType !== SuperAnomalyActionType.IGNORED && !values.action) {
				errors.action = VALIDATION_REQUIRED_TEXT;
			}

			if (superAnomalyActionType === SuperAnomalyActionType.CHANGE_PRIORITY && !values.values.priority) {
				errors.values = {...errors.values, priority: VALIDATION_REQUIRED_TEXT};
			}
			if (
				values.action === Actions.CREATE_TEMPLATE &&
				superAnomalyActionType !== SuperAnomalyActionType.IGNORED
			) {
				if (!values.values.anomaly_type) {
					errors.values = {...errors.values, anomaly_type: VALIDATION_REQUIRED_TEXT};
				}
				if (!values.values.as_from) {
					errors.values = {...errors.values, as_from: VALIDATION_REQUIRED_TEXT};
				}
				if (!values.values.as_to) {
					errors.values = {...errors.values, as_to: VALIDATION_REQUIRED_TEXT};
				}
			}

			return errors;
		},
	});

	const handleCustomChangeAction = useCallback(
		(value: Actions) => {
			if (value === Actions.CREATE_TEMPLATE) {
				formikBag.setFieldValue('values.anomaly_type', null);
				formikBag.setFieldValue('values.as_from', null);
				formikBag.setFieldValue('values.as_to', null);
				if (superAnomalyActionType === SuperAnomalyActionType.CREATE_ISSUE) {
					formikBag.setFieldValue('values.is_create_issue', undefined);
				}
			}
			if (value === Actions.TAKE_ACTION) {
				formikBag.setFieldValue('values.anomaly_type', undefined);
				formikBag.setFieldValue('values.as_from', undefined);
				formikBag.setFieldValue('values.as_to', undefined);
				if (superAnomalyActionType === SuperAnomalyActionType.CREATE_ISSUE) {
					formikBag.setFieldValue('values.is_create_issue', true);
				}
			}
		},
		[formikBag, superAnomalyActionType],
	);

	return (
		<FormikProvider value={formikBag}>
			<Form>
				<ul className={formCss.fields}>
					{superAnomalyActionType !== SuperAnomalyActionType.IGNORED && (
						<li>
							<Selector<Actions>
								items={actionValues}
								name={'action'}
								label={'Тип действия'}
								onCustomChange={handleCustomChangeAction}
							/>
						</li>
					)}
					{superAnomalyActionType === SuperAnomalyActionType.CREATE_ISSUE &&
						formikBag.values.action === Actions.TAKE_ACTION && (
							<li>
								<Checkbox name={'values.is_create_issue'} label={'Создать сейчас'} />
							</li>
						)}
					<li>
						<TextArea name={'values.reason'} label={'Причина'} />
					</li>
					{superAnomalyActionType === SuperAnomalyActionType.CHANGE_PRIORITY && (
						<li>
							<OldDictionarySelector
								dictionary={'events.severity'}
								name={'values.priority'}
								label={'Изменить приоритет на'}
							/>
						</li>
					)}
					{formikBag.values.action === Actions.CREATE_TEMPLATE &&
						superAnomalyActionType !== SuperAnomalyActionType.IGNORED && (
							<>
								<li>
									<Selector<any>
										items={anomalyTypeItems}
										name={'values.anomaly_type'}
										label={'Тип аномалии'}
										itemToString={item => item?.name || ''}
										itemToHtml={item => item?.name || ''}
									/>
								</li>
								<li>
									<AutoComplete<any>
										getItems={getAsItems({type: 'as', minLength: AUTOCOMPLETE_MIN_LENGTH})}
										name={'values.as_from'}
										label={'От Ас'}
										itemToHtml={possibleValueToHtml}
										itemToString={possibleValueToString}
										onCustomChange={(value, originalValue) => {
											if (
												originalValue &&
												typeof originalValue === 'object' &&
												originalValue.code
											) {
												formikBag.setFieldValue('values.as_from', originalValue.code);
											} else {
												formikBag.setFieldValue('values.as_from', value);
											}
										}}
										helperText={AUTOCOMPLETE_HELPER_TEXT}
									/>
								</li>
								<li>
									<AutoComplete<any>
										getItems={getAsItems({type: 'as', minLength: AUTOCOMPLETE_MIN_LENGTH})}
										name={'values.as_to'}
										label={'К Ас'}
										itemToHtml={possibleValueToHtml}
										itemToString={possibleValueToString}
										onCustomChange={(value, originalValue) => {
											if (
												originalValue &&
												typeof originalValue === 'object' &&
												originalValue.code
											) {
												formikBag.setFieldValue('values.as_to', originalValue.code);
											} else {
												formikBag.setFieldValue('values.as_to', value);
											}
										}}
										helperText={AUTOCOMPLETE_HELPER_TEXT}
									/>
								</li>
							</>
						)}
				</ul>
				<ul className={formCss.buttons}>
					<li>
						<Button type={'submit'} disabled={loading} loading={loading}>
							<SaveIcon />
							Подтвердить
						</Button>
					</li>
					<li>
						<Button onClick={onClose} secondary>
							<CrossIcon />
							Отмена
						</Button>
					</li>
				</ul>
			</Form>
		</FormikProvider>
	);
};

export default AlertsSuperAnomaliesActiveForm;
