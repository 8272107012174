import React, {useCallback, useEffect, useState} from 'react';
import {useFocus} from '../inputHooks';
import {Keys} from '../../../utils/key-enum';

type Props = {
	fieldRef: React.RefObject<HTMLDivElement>;
	popoverRef: React.RefObject<HTMLDivElement>;
	listRef: React.RefObject<HTMLDivElement>;
	listLength: number;
	onOpen: () => void;
	onToggle?: (open: boolean) => void;
	onChangeByIndex: (index: number) => void;
};

function useDropDownControl({onOpen, onToggle, fieldRef, popoverRef, listRef, listLength, onChangeByIndex}: Props) {
	const [isOpen, setOpen] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
	const {hasFocus, onFocus, onBlur} = useFocus<React.HTMLAttributes<HTMLInputElement>>({});

	const open = useCallback(() => {
		setOpen(true);
		onOpen();
		setSelectedIndex(null);
		setTimeout(() => {
			if (fieldRef.current && popoverRef.current && process.browser) {
				document.body.style.pointerEvents = 'none';
				fieldRef.current.style.pointerEvents = 'all';
			}
		});
		if (onToggle) onToggle(true);
	}, [onOpen, onToggle, setOpen, setSelectedIndex]);

	const close = useCallback(() => {
		setOpen(false);
		if (process.browser) {
			document.body.style.pointerEvents = '';
		}
		if (onToggle) onToggle(false);
	}, [onToggle]);

	useEffect(() => {
		return () => {
			if (process.browser) {
				document.body.style.pointerEvents = '';
			}
		};
	}, []);

	const handleBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement, Element>) => {
			if (!e.relatedTarget || !popoverRef.current || !popoverRef.current.contains(e.relatedTarget)) {
				close();
			}

			if (onBlur) onBlur(e);
		},
		[onBlur, close],
	);

	const handleFocus = useCallback(
		e => {
			open();
			if (onFocus) onFocus(e);
		},
		[onFocus, open],
	);

	const handleToggle = useCallback(() => {
		isOpen ? close() : open();
	}, [isOpen, close, open]);

	const scrollToItem = useCallback((index: number, list: HTMLDivElement) => {
		const item = list.children.item(index) as HTMLDivElement;
		if (item) {
			list.scrollTop = item.offsetTop - list.clientHeight / 2;
		}
	}, []);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLElement>) => {
			const target = e.target;
			if (target) {
				if (e.keyCode === Keys.DOWN_ARROW) {
					const index = selectedIndex === null ? 0 : selectedIndex + 2 > listLength ? 0 : selectedIndex + 1;
					setSelectedIndex(index);
					if (listRef.current) scrollToItem(index, listRef.current);
					e.preventDefault();
				} else if (e.keyCode === Keys.UP_ARROW) {
					const index = selectedIndex === 0 || selectedIndex === null ? listLength - 1 : selectedIndex - 1;
					setSelectedIndex(index);
					if (listRef.current) scrollToItem(index, listRef.current);
					e.preventDefault();
				} else if (e.keyCode === Keys.ENTER) {
					if (isOpen && selectedIndex !== null) onChangeByIndex(selectedIndex);
					else open();
					e.preventDefault();
				} else if (e.keyCode === Keys.ESCAPE) {
					if (isOpen) {
						close();
						e.preventDefault();
						e.stopPropagation();
					}
				}
			}
		},
		[selectedIndex, listLength, isOpen, open, onChangeByIndex, close],
	);

	return {
		onKeyDown: handleKeyDown,
		onToggle: handleToggle,
		onFocus: handleFocus,
		onBlur: handleBlur,
		open,
		close,
		hasFocus,
		isOpen,
		selectedIndex,
	};
}

export default useDropDownControl;
