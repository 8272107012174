import {useCallback, useEffect, useState} from 'react';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import {Field, GridOptions} from '../../../../../queries-generated/types';

type Props = {
	defaultNumberOfRows?: number;
	viewItemId?: string;
};

export type GridDataOptions = {
	sort: string | null;
	firstDefaultSort: string | null;
	sortOrder: boolean;
	itemsPerPage: number;
	firstItemsPerPage?: number;
	page: number;
	total: number;
	onChangeSort: (columnId: string) => void;
	onChangePage: (page: number) => void;
	onChangeItemsPerPage: (itemsPerPage: number) => void;
	setGridData: (data: {fields: Field[]; options?: GridOptions}) => void;
	fields?: Field[];
	options?: GridOptions;
};

function useDataGridOptions(props: Props): GridDataOptions {
	const {setProp} = useComponentsStoredData(props.viewItemId);
	/**
	 * Чтобы при первой загрузке контента визуально была установлена
	 * стрелка сортировки, но не было повторного запроса на сервер,
	 * так как сервер уже при первой загрузке сортирует по дефолтной колонке
	 * */
	const [firstDefaultSort, setFirstDefaultSort] = useState<string | null>(null);

	const [gridData, setGridData] = useState<{fields: Field[]; options?: GridOptions}>();

	const [sort, setSort] = useState<string | null>(null);
	const [sortOrder, setSortOrder] = useState<boolean>(true);
	const [page, setPage] = useState<number>(0);
	const [itemsPerPage, setItemsPerPage] = useState<number>(props.defaultNumberOfRows || 10);
	const [total, setTotal] = useState<number>(0);

	const handleChangeSort = useCallback(
		(columnId: string): void => {
			if (sort === columnId) {
				setSortOrder(!sortOrder);
				setProp('sortOrder', !sortOrder);
			} else {
				setProp('sortOrder', sortOrder);
			}
			setSort(columnId);
			setProp('sort', columnId);
		},
		[sort, setSort, sortOrder, setSortOrder],
	);

	const handleChangePage = useCallback(
		(page: number): void => {
			setPage(page);
			setProp('page', page);
		},
		[setPage],
	);

	const handleChangeItemsPerPage = useCallback(
		(itemsPerPage: number): void => {
			setItemsPerPage(itemsPerPage);
			setProp('itemsPerPage', itemsPerPage);
		},
		[setItemsPerPage],
	);

	useEffect(() => {
		if (gridData?.options) {
			setTotal(gridData.options.total);
			const maxPage = (Math.ceil(gridData.options.total / itemsPerPage) || 1) - 1;
			if (page > maxPage) {
				setPage(maxPage);
			}
		}

		if (!itemsPerPage && gridData?.options?.defaultNumItems) {
			setItemsPerPage(gridData.options.defaultNumItems);
		}
	}, [gridData]);

	useEffect(() => {
		if (!firstDefaultSort && gridData?.fields) {
			gridData.fields.forEach(column => {
				if (column.defaultSort) {
					setFirstDefaultSort(column.id);
				}
			});
		}
	}, [gridData, firstDefaultSort, setFirstDefaultSort]);

	return {
		sort,
		sortOrder,
		itemsPerPage,
		firstItemsPerPage: gridData?.options?.defaultNumItems,
		page,
		firstDefaultSort,
		total,
		onChangeSort: handleChangeSort,
		onChangePage: handleChangePage,
		onChangeItemsPerPage: handleChangeItemsPerPage,
		setGridData,
		fields: gridData?.fields,
		options: gridData?.options,
	};
}

export default useDataGridOptions;
