import FetchError from '../hooks/fetch/FetchError';
import {Edge, Node} from '../controlls/Graph/Graph';
import {
	useAsConnectionsQuery,
	useAsLinksAbroadQuery,
	useAsLinksQuery,
	useAsNetworkQuery,
} from '../../queries-generated/types';
import {Filters, Views} from './AsLinksFilters/AsLinksFilters';
import {graphDataFromAs} from './graphHelper';

type Response = {
	loading: boolean;
	error?: FetchError;
	edges: Edge[];
	nodes: Node[];
	dates: number[];
	hasData: boolean;
	hasInitialMoment: boolean;
	hasPresentMoment: boolean;
};

function useEdgesAndNodes(filters: Filters, canMakeQuery: boolean): Response {
	const {view} = filters;

	const asLinksQuery = useAsLinksQuery({
		variables: {
			input: {
				ids: filters.asIds.map(as => as.number),
				hops: filters.hops,
				filters: {
					firstHop: filters.firstHop,
					nextHops: filters.nextHops,
					asInclude: filters.asInclude.map(as => as.number),
					asExclude: filters.asExclude.map(as => as.number),
					countryInclude: filters.countryInclude.map(country => country.a2_code),
					countryExclude: filters.countryExclude.map(country => country.a2_code),
					registryInclude: filters.registryInclude.map(reg => reg.id),
					registryExclude: filters.registryExclude.map(reg => reg.id),
					operatorInn: filters.bgpOperator?.org_inn,
					bgpAgents: filters.bgpAgents.map(agent => agent.id),
				},
			},
		},
		skip: view !== Views.neighbors || !canMakeQuery,
		notifyOnNetworkStatusChange: true,
	});

	const asConnectionQuery = useAsConnectionsQuery({
		variables: {
			input: {
				asAId: filters.asAId?.number || NaN,
				asBIds: filters.asBIds.map(as => as.number),
				hopCount: filters.hopCount,
				linkCount: filters.linkCount,
				filters: {
					asInclude: filters.asInclude.map(as => as.number),
					asExclude: filters.asExclude.map(as => as.number),
					countryInclude: filters.countryInclude.map(country => country.a2_code),
					countryExclude: filters.countryExclude.map(country => country.a2_code),
					registryInclude: filters.registryInclude.map(reg => reg.id),
					registryExclude: filters.registryExclude.map(reg => reg.id),
					duration: filters.duration === '' ? undefined : filters.duration,
					startAt: filters.startAt,
					endAt: filters.endAt,
					operatorInn: filters.bgpOperator?.org_inn,
					bgpAgents: filters.bgpAgents.map(agent => agent.id),
				},
			},
		},
		skip: view !== Views.connections || !canMakeQuery,
		notifyOnNetworkStatusChange: true,
	});

	const asNetworkQuery = useAsNetworkQuery({
		variables: {
			input: {
				ids: filters.asIds.map(as => as.number),
				countries: filters.countries.map(country => country.a2_code) || [],
				registries: filters.registries.map(reg => reg.id) || [],
				filters: {
					operatorInn: filters.bgpOperator?.org_inn,
					bgpAgents: filters.bgpAgents.map(agent => agent.id),
					asInclude: filters.asInclude.map(as => as.number),
					asExclude: filters.asExclude.map(as => as.number),
					countryInclude: filters.countryInclude.map(country => country.a2_code),
					countryExclude: filters.countryExclude.map(country => country.a2_code),
					registryInclude: filters.registryInclude.map(reg => reg.id),
					registryExclude: filters.registryExclude.map(reg => reg.id),
				},
			},
		},
		skip: view !== Views.network || !canMakeQuery,
		notifyOnNetworkStatusChange: true,
	});

	const asLinksAbroadQuery = useAsLinksAbroadQuery({
		variables: {
			input: {
				ids: filters.asIds.map(as => as.number),
				hopCount: filters.hopCount,
				linkCount: filters.linkCount,
				filters: {
					asInclude: filters.asInclude.map(as => as.number),
					asExclude: filters.asExclude.map(as => as.number),
					countryInclude: filters.countryInclude.map(country => country.a2_code),
					countryExclude: filters.countryExclude.map(country => country.a2_code),
					registryInclude: filters.registryInclude.map(reg => reg.id),
					registryExclude: filters.registryExclude.map(reg => reg.id),
					duration: filters.duration === '' ? undefined : filters.duration,
					startAt: filters.startAt,
					endAt: filters.endAt,
					operatorInn: filters.bgpOperator?.org_inn,
					bgpAgents: filters.bgpAgents.map(agent => agent.id),
				},
			},
		},
		skip: view !== Views.neighborsAbroad || !canMakeQuery,
		notifyOnNetworkStatusChange: true,
	});

	let mainNodes: number[] = [];
	let query;
	let data;
	switch (filters.view) {
		case Views.neighbors:
			query = asLinksQuery;
			data = asLinksQuery.data ? asLinksQuery.data.asLinks : [];
			mainNodes = filters.asIds.map(as => as.number);
			break;
		case Views.connections:
			query = asConnectionQuery;
			data = asConnectionQuery.data ? asConnectionQuery.data.asConnections : [];
			mainNodes = filters.asBIds.map(as => as.number);
			if (filters.asAId) mainNodes.push(filters.asAId.number);
			break;
		case Views.network:
			query = asNetworkQuery;
			data = asNetworkQuery.data ? asNetworkQuery.data.asNetwork : [];
			mainNodes = filters.asIds.map(as => as.number);
			break;
		case Views.neighborsAbroad:
			query = asLinksAbroadQuery;
			data = asLinksAbroadQuery.data ? asLinksAbroadQuery.data.asLinksAbroad : [];
			mainNodes = filters.asIds.map(as => as.number);
			break;
	}

	return {
		loading: query ? query.loading : false,
		error: query && !query.loading ? query.error : undefined,
		...graphDataFromAs(data, mainNodes),
	};
}

export default useEdgesAndNodes;
