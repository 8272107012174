import React, {useContext, useMemo} from 'react';
import {Action, ViewItem} from './api/useViewItemTree';
import {useActionsQuery} from '../../queries-generated/types';
import {FormikProvider, useFormik, Form as FormikForm} from 'formik';
import commonFormCss from '../settings/views/ViewEdit/constructor/form/Form.module.css';
import css from './RefreshActionForm.module.css';
import ProgressRing from '../controlls/Loader/ProgressRing';
import DashboardFields from '../settings/views/ViewEdit/constructor/form/DashboardFields';
import Button from '../pirsInputs/Button/Button';
import {RefreshType, useRefreshIntervalFromLocalStorage} from '../settings/views/components/helper';
import {ViewOptionsContext} from './ViewWrapper';

type Props = {
	viewItem: ViewItem;
	viewItemRefreshAction: Action;
};

type Values = {
	refreshInterval?: number;
};

const RefreshActionForm: React.FC<Props> = ({viewItem, viewItemRefreshAction}) => {
	const {root, version, dashboardId} = useContext(ViewOptionsContext);

	const {
		rootRefreshInterval: localStorageRootRefreshInterval,
		setRefreshInterval,
		viewItemRefreshInterval,
	} = useRefreshIntervalFromLocalStorage(dashboardId, version, viewItem);

	const {data, loading: refreshActionControlsLoading} = useActionsQuery({
		variables: {ids: [viewItemRefreshAction.type]},
		skip: !viewItemRefreshAction.type,
	});

	const refreshActionConfig = useMemo(() => {
		if (data?.actions && data.actions.length) {
			return data.actions[0];
		}
	}, [data]);

	const defaultRefreshIntervalFromField = useMemo(() => {
		const refreshIntervalField = refreshActionConfig?.fields.find(value => value.id === 'refreshInterval')
			?.defaultValue;
		return typeof refreshIntervalField === 'number' ? refreshIntervalField : 0;
	}, [refreshActionConfig]);

	const rootRefreshInterval = useMemo((): number => {
		if (root && root.actions && root.actions.length) {
			return typeof localStorageRootRefreshInterval === 'number'
				? localStorageRootRefreshInterval
				: root.actions.find(action => action.props?.refreshInterval)?.props?.refreshInterval || 0;
		}

		return 0;
	}, [root, localStorageRootRefreshInterval]);

	const formikBag = useFormik<Values>({
		enableReinitialize: true,
		initialValues: {
			refreshInterval:
				typeof viewItemRefreshInterval === 'number'
					? viewItemRefreshInterval
					: viewItemRefreshAction.props.refreshInterval || defaultRefreshIntervalFromField || 0,
		},

		onSubmit: (values, {resetForm}) => {
			const refreshInterval = values.refreshInterval || 0;
			setRefreshInterval(refreshInterval);

			// Чтобы сбросить formikBag.dirty
			resetForm({values: {refreshInterval}});
		},
	});

	return (
		<FormikProvider value={formikBag}>
			<FormikForm className={css.container}>
				{viewItemRefreshAction.props.refreshType.id === RefreshType.DASHBOARD && viewItem.id !== 'root' ? (
					<>
						{rootRefreshInterval
							? `Интервал обновления установлен дашбордом - ${rootRefreshInterval}мс`
							: `Установите интервал обновления дашборда, чтобы компонент обновлялся в соответствии с заданным ему значением`}
					</>
				) : viewItemRefreshAction.props.refreshType.id === RefreshType.NONE && viewItem.id !== 'root' ? (
					'Обновление в ручном режиме'
				) : refreshActionControlsLoading ? (
					<ProgressRing />
				) : (
					refreshActionConfig && (
						<div>
							<div>
								{refreshActionConfig.fields.length > 0 && (
									<ul className={commonFormCss.form}>
										<DashboardFields
											fields={refreshActionConfig.fields
												.filter(field => field.id === 'refreshInterval')
												.map(field => ({
													...field,
													name: `${field.id}`,
												}))}
										/>
									</ul>
								)}
							</div>
							<div className={commonFormCss.buttons}>
								<Button type="submit" disabled={!formikBag.dirty}>
									Сохранить
								</Button>
							</div>
						</div>
					)
				)}
			</FormikForm>
		</FormikProvider>
	);
};

export default RefreshActionForm;
