import InputFile from '../../components/pirsInputs/InputFile/InputFile.formik';
import {Form} from 'formik';
import * as React from 'react';
import css from './FileUploadSnippet.module.css';
import {format} from 'ts-date/esm/locale/ru';

type Props = {
	onSubmitForm: () => void;
	onCancelUpload: () => void;
	uploadInProgress: boolean;
	uploadProgress: number;
	fileName?: string;
	publishAt?: Date;
};

const FileUploadSnippet: React.FC<Props> = ({
	onCancelUpload,
	fileName,
	onSubmitForm,
	uploadInProgress,
	uploadProgress,
	publishAt,
}) => {
	return (
		<Form>
			<div className={css.wrapper}>
				<div className={css.buttonArea}>
					<InputFile disabled={uploadInProgress} name="file" onCustomChange={onSubmitForm} />
				</div>
				<div className={css.dataArea}>
					<ul className={css.dataList}>
						{publishAt && !fileName && (
							<li>Информация представлена {format(publishAt, 'DD.MM.YYYY HH:mm')}</li>
						)}
						<li>
							{fileName && <>Файл {fileName}</>}
							{uploadInProgress && <span> загружается... {uploadProgress}%</span>}
						</li>
						{uploadInProgress && (
							<li>
								<span className={css.link} onClick={onCancelUpload}>
									Отменить
								</span>
							</li>
						)}
					</ul>
				</div>
			</div>
		</Form>
	);
};

export default FileUploadSnippet;
