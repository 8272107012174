import React, {useCallback} from 'react';
import css from './DataGrid.module.css';
import {Field, GridForm} from '../../queries-generated/types';
import {useBoolean} from '../hooks/useBoolean';
import Button, {ButtonProps} from '../pirsInputs/Button/Button';
import cls from '../../utils/cls';
import DataGridForm from './DataGridForm';

type Props = {
	dataSourceId: string;
	reload?();
	id?: string;
	selectedIds?: string[];
	fields?: Field[];
	massAction: boolean;
	form: GridForm;
};

const GridActionButtonWithForm: React.FC<Props & Omit<ButtonProps, 'form'>> = ({
	dataSourceId,
	reload,
	id,
	selectedIds,
	fields,
	form,
	children,
	massAction,
	...buttonProps
}) => {
	const [open, {setFalse, setTrue}] = useBoolean(false);

	const handleSuccess = useCallback(() => {
		if (reload) reload();
		setFalse();
	}, [reload]);

	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setTrue();
		},
		[setTrue],
	);

	return (
		<>
			{open && (
				<DataGridForm
					fields={fields || []}
					open={open}
					onClose={setFalse}
					onSuccess={handleSuccess}
					id={id}
					selectedIds={selectedIds}
					dataSourceId={dataSourceId}
					form={form}
				/>
			)}
			<Button
				{...buttonProps}
				disabled={massAction && !id && selectedIds?.length === 0}
				onClick={handleClick}
				className={cls(open && css.active)}
			>
				{children}
			</Button>
		</>
	);
};

export default GridActionButtonWithForm;
