import React from 'react';
import * as SvgIcon from '../components/SvgIcon';

export default function getIcon(icon?: string | React.ReactNode | null) {
	if (typeof icon === 'string' && SvgIcon[icon + 'Icon']) {
		return SvgIcon[icon + 'Icon']();
	}

	return icon;
}
