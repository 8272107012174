import {default as React, useCallback} from 'react';
import css from './FullTable.module.css';
import FullTableForm from './FullTableForm';
import {CustomFormProps} from './FullTableFormHelpers';
import FullTableHead from './FullTableHead';
import FullTableRow from './FullTableRow';
import FullTablePagination from './FullTablePagination';
import ProgressRing from '../controlls/Loader/ProgressRing';
import {Paper, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import ModalPopup from '../controlls/ModalPopup/ModalPopup';
import Button from '../pirsInputs/Button/Button';
import cls from '../../utils/cls';
import {FullTableBagType, FullTableQueryBag} from './useFullTableHook';
import FullTableDeleteModal from './FullTableDeleteModal';
import {FormikErrors} from 'formik';
import {removeArrayElementByIndex} from '../../utils/array-utils';
import {CreateIcon} from '../SvgIcon';
import ErrorSnippet from '../ErrorSnippet/ErrorSnippet';

type Props = {
	model: string;
	queryBag: FullTableQueryBag;
	fullTableBag: FullTableBagType;
	editable?: boolean;
	creatable?: boolean;
	deletable?: boolean;
	massDeletable?: boolean;
	selectable?: boolean;
	editTitle?: string;
	createTitle?: string;
	cellCallback?: {[row: string]: (value: any, item: {[key: string]: any}) => React.ReactNode};
	detailCellCallback?: {[row: string]: (value: any, item: {[key: string]: any}) => React.ReactNode};
	showEditButtonCallback?: (item: {[key: string]: any}) => boolean;
	showDeleteButtonCallback?: (item: {[key: string]: any}) => boolean;
	additionalControls?: (item: {
		[key: string]: any;
	}) => React.ReactNode | ((selectedItems: PlainObjectOf<any>[]) => React.ReactNode);
	additionalFilters?:
		| React.ReactNode
		| ((selectedItems: PlainObjectOf<any>[], items: PlainObjectOf<any>[] | undefined) => React.ReactNode);
	updateForm?: React.FC<CustomFormProps>;
	createForm?: React.FC<CustomFormProps>;
	validateFn?: (values: any) => FormikErrors<any>;
	submitFn?: (values: any) => any;
	actionTableHeadCellCallback?: (selectedItems: PlainObjectOf<any>[]) => React.ReactNode;
};

const FullTableWithHook: React.FC<Props> = ({
	model,
	fullTableBag,
	queryBag,
	cellCallback,
	detailCellCallback,
	editable,
	creatable,
	deletable,
	massDeletable,
	selectable,
	editTitle,
	showDeleteButtonCallback,
	showEditButtonCallback,
	createTitle,
	additionalControls,
	additionalFilters,
	createForm,
	updateForm,
	validateFn,
	submitFn,
	actionTableHeadCellCallback,
}) => {
	selectable = selectable || massDeletable;
	const {loading, data, modelItemsError, submitFormLoading} = queryBag;
	const {
		onChangePage,
		onChangeItemsPerPage,
		onChangeSort,
		onChange,
		onClose,
		onDelete,
		onMassDelete,
		reload,
		onUserWantsCreate,
		onUserWantsUpdate,
		onUserWantsDelete,
		sortBy,
		sort,
		itemsPerPage,
		page,
		allColumns,
		visibleColumns,
		detailsColumns,
		meta,
		editableId,
		deletableId,
		getItemById,
		selectedItems,
		onSelect,
	} = fullTableBag;

	const items = data?.modelItems.items;

	const handleSelectAll = useCallback(() => {
		if (onSelect && items) {
			const selected = selectedItems && items.length && selectedItems.length !== items.length ? items : [];
			onSelect(selected);
		}
	}, [selectedItems, onSelect, items]);

	const handleSelect = useCallback(
		(item: PlainObjectOf<any>) => {
			if (!selectedItems || !onSelect) return;
			const index = selectedItems.findIndex(value => value.id === item.id);

			onSelect(index === -1 ? [...selectedItems, item] : removeArrayElementByIndex(selectedItems, index));
		},
		[selectedItems, onSelect],
	);

	return (
		<Paper className={css.wrapper}>
			<ModalPopup
				maxWidth={400}
				open={!!editableId}
				header={editableId === true ? createTitle || 'Создать' : editTitle || 'Редактировать'}
				onClose={onClose}
			>
				<FullTableForm
					allColumns={allColumns.filter(column => column.canEdit || column.canCreate)}
					id={editableId === true ? undefined : (editableId as string)}
					getItemById={getItemById}
					onClose={onClose}
					onChange={onChange}
					createForm={createForm}
					updateForm={updateForm}
					validateFn={validateFn}
					submitFn={submitFn}
					submitFormLoading={submitFormLoading}
				/>
			</ModalPopup>
			<ModalPopup
				maxWidth={400}
				open={!!deletableId}
				header={'Вы действительно хотите удалить выбранный объект?'}
				onClose={onClose}
			>
				<FullTableDeleteModal id={deletableId} onDelete={onDelete} onClose={onClose} />
			</ModalPopup>
			{(creatable || additionalFilters) && (
				<div className={css.headerFiltersContainer}>
					{creatable && (
						<div className={css.mainFilters}>
							{creatable && (
								<Button onClick={onUserWantsCreate} disabled={!meta}>
									<CreateIcon />
									Добавить
								</Button>
							)}
						</div>
					)}
					{additionalFilters && (
						<div className={css.additionalFilters}>
							{typeof additionalFilters === 'function'
								? additionalFilters(selectedItems, items)
								: additionalFilters}
						</div>
					)}
				</div>
			)}
			<div className={css.tableWrapper}>
				<Table
					stickyHeader
					aria-label="sticky table"
					size="small"
					className={cls((loading || modelItemsError || items?.length === 0) && css.fullHeight)}
				>
					<FullTableHead
						orderBy={sortBy}
						order={sort}
						visibleColumns={visibleColumns}
						onChange={onChangeSort}
						selectable={selectable}
						massDeletable={massDeletable}
						onMassDelete={onMassDelete}
						selectedItems={selectedItems}
						onSelect={handleSelectAll}
						selected={selectedItems && items?.length === selectedItems.length}
						hasActionButtons={
							deletable || editable || !!additionalControls || selectable || !!actionTableHeadCellCallback
						}
						actionTableHeadCellCallback={actionTableHeadCellCallback}
					/>
					{modelItemsError ? (
						<TableBody className={css.fullHeight}>
							<TableRow className={css.fullHeight}>
								<TableCell
									colSpan={
										deletable || editable || Boolean(additionalControls)
											? visibleColumns.length + 1
											: visibleColumns.length
									}
								>
									<ErrorSnippet error={modelItemsError} refetch={reload} />
								</TableCell>
							</TableRow>
						</TableBody>
					) : loading ? (
						<TableBody className={css.fullHeight}>
							<TableRow className={css.fullHeight}>
								<TableCell
									colSpan={
										deletable || editable || Boolean(additionalControls)
											? visibleColumns.length + 1
											: visibleColumns.length
									}
								>
									<ProgressRing />
								</TableCell>
							</TableRow>
						</TableBody>
					) : items?.length === 0 ? (
						<TableBody className={css.fullHeight}>
							<TableRow className={css.fullHeight}>
								<TableCell
									colSpan={
										deletable || editable || Boolean(additionalControls)
											? visibleColumns.length + 1
											: visibleColumns.length
									}
								>
									<h2 className={css.emptyListMessage}>Записей нет</h2>
								</TableCell>
							</TableRow>
						</TableBody>
					) : (
						<TableBody>
							{items?.map(item => (
								<FullTableRow
									model={model}
									cellCallback={cellCallback}
									detailCellCallback={detailCellCallback}
									item={item}
									key={item.id}
									visibleColumns={visibleColumns}
									detailsColumns={detailsColumns}
									noWrapCells={true}
									editable={editable}
									deletable={deletable}
									selectable={selectable}
									onSelect={() => handleSelect(item)}
									selected={
										selectedItems &&
										!!selectedItems.find(selectedItem => selectedItem.id === item.id)
									}
									showEditButtonCallback={showEditButtonCallback}
									showDeleteButtonCallback={showDeleteButtonCallback}
									onStartEdit={onUserWantsUpdate}
									onStartDelete={onUserWantsDelete}
									additionalControls={additionalControls}
								/>
							))}
						</TableBody>
					)}
				</Table>
			</div>
			<FullTablePagination
				onChangePage={onChangePage}
				page={page}
				total={meta?.total || 0}
				itemsPerPage={itemsPerPage}
				onChangeItemsPerPage={onChangeItemsPerPage}
				onReload={reload}
			/>
		</Paper>
	);
};

export default FullTableWithHook;
