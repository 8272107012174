import React, {useCallback, useEffect, useRef, useState} from 'react';
import css from './ResourceRegisterTableContainer.module.css';
import Checkbox from '../../../components/pirsInputs/Checkbox/Checkbox';
import {useRouter} from 'react-named-hooks-router';
import Button from '../../../components/pirsInputs/Button/Button';
import {debounce} from '../../../utils/debounce';
import {SortOrder, usePirsDeleteMutation, usePirsListQuery} from '../../../queries-generated/types';
import ResourceTable from '../../../components/ResourceRegisterTable/ResourceTable';
import NoKeyWordSnippet from './NoKeyWordSnippet';
import ResourceRegisterTablePagination from './ResourceRegisterTablePagination';
import {ApolloError} from '@apollo/client';
import CustomErrorPopup from '../../../components/controlls/CustomErrorPopup/CustomErrorPopup';
import {CreateIcon} from '../../../components/SvgIcon';
import InputSearch from '../../../components/pirsInputs/InputSearch/InputSearch';

type Props = {
	reFetchSession: () => void;
};

const DEFAULT_ITEMS_PER_PAGE = 25;

const ResourceRegisterTableContainer: React.FC<Props> = ({reFetchSession}) => {
	const {pushRoute, routeName, routeParams} = useRouter<{
		keyword?: string;
		hideRejected?: boolean;
		hideOpen?: boolean;
		hideDisused?: boolean;
		hideWithoutInspectorComments?: boolean;
		after?: string;
		itemsPerPage?: string;
		sort?: SortOrder;
		sortBy?: string;
	}>();

	const isFirstRun = useRef(true);

	const [keyword, setKeyword] = useState(routeParams?.keyword);
	const [sortBy, setSortBy] = useState<string | undefined>(routeParams?.sortBy);
	const [sort, setSort] = useState<SortOrder | undefined>(routeParams?.sort);
	const [page, setPage] = useState<number>(0);
	const [after, setAfter] = useState<number | undefined>();
	const [itemsPerPage, setItemsPerPage] = useState<number>(DEFAULT_ITEMS_PER_PAGE);
	const [hideRejected, setHideRejected] = useState<boolean>(false);
	const [hideOpen, setHideOpen] = useState<boolean>(false);
	const [hideDisused, setHideDisused] = useState<boolean>(true);
	const [hideWithoutInspectorComments, setHideWithoutInspectorComments] = useState<boolean>(false);
	const [filter, setFilter] = useState<string | undefined>(routeParams?.filter);

	const handleResetQueryParams = useCallback(() => {
		pushRoute(routeName, {
			...routeParams,
			sort: undefined,
			sortBy: undefined,
			after: undefined,
			itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
			hideRejected: false,
			hideOpen: false,
			hideDisused: true,
			hideWithoutInspectorComments: false,
		});
		setSortBy(undefined);
		setSort(undefined);
		setPage(0);
		setAfter(undefined);
		setItemsPerPage(DEFAULT_ITEMS_PER_PAGE);
		setFilter(undefined);
		setHideRejected(false);
		setHideOpen(false);
		setHideDisused(true);
		setHideWithoutInspectorComments(false);
	}, [
		routeName,
		routeParams,
		pushRoute,
		setSort,
		setSortBy,
		setPage,
		setAfter,
		setItemsPerPage,
		setFilter,
		setHideRejected,
		setHideOpen,
		setHideDisused,
		setHideWithoutInspectorComments,
	]);

	const handleChangeSort = useCallback(
		field => {
			const isDesc = sortBy === field && sort === SortOrder.Desc;
			const sortOrder = isDesc ? SortOrder.Asc : SortOrder.Desc;
			setSort(sortOrder);
			setSortBy(field);
			pushRoute(routeName, {...routeParams, sort: sortOrder, sortBy: field});
		},
		[pushRoute, routeName, routeParams, sort, sortBy],
	);

	const handleChangePage = useCallback(
		page => {
			const after = page * itemsPerPage;
			setAfter(after);
			setPage(page);
		},
		[setPage, setAfter, pushRoute, routeParams, routeName],
	);

	const handleChangeItemsPerPage = useCallback(
		itemsPerPage => {
			setItemsPerPage(itemsPerPage);
			pushRoute(routeName, {...routeParams, itemsPerPage});
		},
		[pushRoute, routeName, routeParams, setItemsPerPage],
	);

	const handleChangeInputFilter = useCallback(
		debounce(value => {
			setFilter(value ? value : undefined);
		}, 500),
		[setFilter],
	);

	const handleChangeRejected = useCallback(() => {
		pushRoute(routeName, {...routeParams, hideRejected: !hideRejected});
		setHideRejected(!hideRejected);
	}, [pushRoute, routeName, routeParams, setHideRejected, hideRejected]);

	const handleChangeOpen = useCallback(() => {
		pushRoute(routeName, {...routeParams, hideOpen: !hideOpen});
		setHideOpen(!hideOpen);
	}, [pushRoute, routeName, routeParams, setHideOpen, hideOpen]);

	const handleChangeDisused = useCallback(() => {
		pushRoute(routeName, {...routeParams, hideDisused: !hideDisused});
		setHideDisused(!hideDisused);
	}, [pushRoute, routeName, routeParams, setHideDisused, hideDisused]);

	const handleChangeHideWithoutInspectorComments = useCallback(() => {
		pushRoute(routeName, {...routeParams, hideWithoutInspectorComments: !hideWithoutInspectorComments});
		setHideWithoutInspectorComments(!hideWithoutInspectorComments);
	}, [pushRoute, routeName, routeParams, setHideWithoutInspectorComments, hideWithoutInspectorComments]);

	const {data, error, loading, refetch} = usePirsListQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			input: {
				type: keyword || '',
				textSearchFilter: filter ? filter : undefined,
				first: itemsPerPage,
				hideRejected,
				hideOpen,
				hideDisused,
				after,
				sort,
				sortBy,
				hideWithoutInspectorComments,
			},
		},
		notifyOnNetworkStatusChange: true,
		skip: !keyword,
	});

	const [deleteMutationError, setDeleteMutationError] = useState<ApolloError>();
	const [deleteMutation] = usePirsDeleteMutation();

	const handleDelete = useCallback(
		async id => {
			setDeleteMutationError(undefined);
			if (keyword) {
				deleteMutation({variables: {id, type: keyword}})
					.then(() => {
						reFetchSession();
						refetch();
					})
					.catch(error => setDeleteMutationError(error));
			}
		},
		[reFetchSession, refetch, keyword, setDeleteMutationError, deleteMutation],
	);

	/* Для сброса фильтров после смены сущности */
	useEffect(() => {
		if (isFirstRun.current) {
			handleResetQueryParams();
			isFirstRun.current = false;
			return;
		}
		setKeyword(routeParams.keyword);
		handleResetQueryParams();
	}, [routeParams.keyword]);

	return (
		<div className={css.container}>
			{deleteMutationError && <CustomErrorPopup error={deleteMutationError} maxWidth={500} />}
			{!routeParams.keyword ? (
				<NoKeyWordSnippet />
			) : (
				<>
					<div className={css.filters}>
						<div>
							<InputSearch className={css.searchInput} onChange={handleChangeInputFilter} />
						</div>
						<div>
							<Checkbox label={'Не принятые'} checked={!hideRejected} onChange={handleChangeRejected} />
						</div>
						<div>
							<Checkbox label={'Не обработанные'} checked={!hideOpen} onChange={handleChangeOpen} />
						</div>
						<div>
							<Checkbox
								label={'Выведенные из эксплуатации'}
								checked={!hideDisused}
								onChange={handleChangeDisused}
							/>
						</div>
						<div>
							<Checkbox
								label={'С замечаниями'}
								checked={hideWithoutInspectorComments}
								onChange={handleChangeHideWithoutInspectorComments}
							/>
						</div>
					</div>
					<ResourceTable
						keyword={keyword}
						tableData={data?.pirsList}
						sortBy={sortBy}
						sort={sort}
						handleChangeSort={handleChangeSort}
						refetch={refetch}
						onDelete={data?.pirsList.canDelete ? handleDelete : undefined}
						error={error}
						loading={loading}
					/>
					<div className={css.footer}>
						<ResourceRegisterTablePagination
							onChangePage={handleChangePage}
							onChangeItemsPerPage={handleChangeItemsPerPage}
							onReload={refetch}
							itemsPerPage={itemsPerPage}
							page={page}
							hasNext={!!data?.pirsList.hasNextPage}
							hasPerv={page > 0}
						/>
						<Button
							route={'resourceRegisterInstance'}
							params={{type: keyword || '', id: 'create'}}
							target={'_blank'}
							secondary
						>
							<CreateIcon />
							Добавить новую запись о ресурсе
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

export default ResourceRegisterTableContainer;
