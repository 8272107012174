import {Field, FieldShownType, FieldType, GridForm} from '../../queries-generated/types';
import {getItemByField, getShowType, resolveTypesInItem} from '../settings/views/components/helper';
import React from 'react';
import DataGridToggle from './DataGridToggle';
import css from './DataGrid.module.css';
import Conversations from '../Conversations/Conversations';
import {wordByCount} from '../../utils/word-by-count';

export const directoryKey = 'isDirectory';

export function defaultOnCellRender(item: PlainObjectOf<any>, field: Field, details: boolean) {
	const showType = getShowType(field);
	return resolveTypesInItem(getItemByField(field, item), field, showType, item, details, '', () => {});
}

export function onCellRenderWithDataSource(
	dataSourceId: string,
	fields: Field[] | undefined,
	forms: GridForm[] | undefined,
	reload: () => void,
	onChangeDirectory?: (directoryId: string, directoryName: string) => void,
) {
	return function(item: PlainObjectOf<any>, field: Field, details: boolean) {
		const value = getItemByField(field, item);
		const handleChangeDirectory = (event: React.MouseEvent) => {
			event.stopPropagation();
			onChangeDirectory && onChangeDirectory(item.id, value);
		};

		const showType = getShowType(field);

		if (field.type === FieldType.Relation) {
			return null;
		}

		if (showType === FieldShownType.Toggle) {
			return <DataGridToggle dataSourceId={dataSourceId} fieldId={field.id} value={!!value} id={item.id} />;
		}

		if (showType === FieldShownType.Conversations) {
			if (details) {
				return <Conversations value={value} dataSourceId={dataSourceId} itemId={item.id} fieldId={field.id} />;
			}
			return wordByCount(value?.size || 0, ['сообщение', 'сообщения', 'сообщений'], true);
		}

		const rendered = resolveTypesInItem(value, field, showType, item, details, dataSourceId, reload, fields, forms);

		if (field.isGeneral && item[directoryKey] && onChangeDirectory)
			return (
				<span className={css.directory} onClick={handleChangeDirectory}>
					{rendered}
				</span>
			);

		return rendered;
	};
}
