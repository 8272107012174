/**
 * Склонение существительных после числительных
 * @param {number} count
 * @param {string[]} variants
 * @param {string|*} showCount
 * @returns {string}
 */
export function wordByCount(count: number | string, variants: string[], showCount?: any) {
	const cases = [2, 0, 1, 1, 1, 2];
	let pre = '';
	if (typeof showCount === 'string') {
		pre = count + showCount;
	} else if (showCount) {
		pre = count + ' ';
	}
	count = parseInt(count as string, 10) || 0;
	return (
		pre +
		variants[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]].replace(
			'{d}',
			count.toString(),
		)
	);
}
