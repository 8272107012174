import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {List, ListRowProps, ScrollParams} from 'react-virtualized';
import {getDateOfCrossYearsLine, setDate} from './helper';
import {addYear, diffYear, format, resetYear} from 'ts-date/locale/ru';
import css from './Calendar.module.module.css';
import Button from '../Button/Button';
import {END_DATE, FROM_DATE, stopPropagation, Value} from './Calendar';
import {GoTodayIcon} from '../../SvgIcon';
import cls from '../../../utils/cls';
import {onlyUnique} from '../../../utils/array-utils';

type Props = {
	value: Value | null;
	minDate?: Date;
	maxDate?: Date;
	width: number;
	height: number;
	rowHeight: number;
	scrollbarWidth: number;
	switchToMonths: (e: React.MouseEvent) => void;
	switchToDays: (e: React.MouseEvent) => void;
	dateCursor: Date;
	onChangeDateCursor: (date: Date) => void;
	range?: boolean;
};

const YearsCalendar: React.FC<Props> = ({
	value,
	width,
	scrollbarWidth,
	height,
	rowHeight,
	switchToDays,
	switchToMonths,
	range,
	dateCursor,
	onChangeDateCursor,
	...props
}) => {
	const yearsInLine = 4;
	const fromDate = useMemo(() => (props.minDate ? addYear(props.minDate, -(yearsInLine * 6)) : FROM_DATE), [
		props.minDate,
	]);
	const endDate = useMemo(() => (props.maxDate ? addYear(props.maxDate, yearsInLine * 6) : END_DATE), [
		props.maxDate,
	]);

	const list = useRef<List>(null);
	useEffect(() => {
		if (list.current) {
			list.current.scrollToPosition(
				(diffYear(resetYear(dateCursor), fromDate)! / yearsInLine) * rowHeight - rowHeight,
			);
		}
	}, []);

	const yearsLinesCount = useMemo(() => diffYear(endDate, fromDate)! / yearsInLine, [fromDate, endDate]);

	const YearsLine = ({index, key, style}: ListRowProps) => {
		const day = getDateOfCrossYearsLine(index, fromDate);
		return (
			<div key={key} style={{...style}} className={css.line}>
				{Array(yearsInLine)
					.fill(undefined)
					.map((_x, yearIndex) => {
						const date = addYear(day, yearIndex);
						const currentDate = resetYear(date);
						const selectedDates = [resetYear(value?.from || null), resetYear(value?.to || null)].filter(
							Boolean,
						);
						const minDate = props.minDate && resetYear(props.minDate);
						const maxDate = props.maxDate && resetYear(props.maxDate);
						const isInCurrentYearPeriod =
							date.getFullYear() >= dateCursor.getFullYear() &&
							date.getFullYear() <= dateCursor.getFullYear() + (yearsInLine * 3 - 1);

						const nowDate = resetYear(new Date());
						const isToday = currentDate.getTime() === nowDate.getTime();

						const isSelectedFrom =
							selectedDates.length > 0 && selectedDates[0]?.getTime() === currentDate.getTime();
						const isSelectedTo =
							selectedDates.length > 0 && selectedDates[1]?.getTime() === currentDate.getTime();

						const isDisabled = (minDate && currentDate < minDate) || (maxDate && currentDate > maxDate);

						const changeDate = setDate(value?.from || date, date.getFullYear());
						if (date >= fromDate && date <= endDate) {
							return (
								<div
									key={key + '-' + yearIndex}
									className={cls(
										css.item,
										css.year,
										!isInCurrentYearPeriod && css.otherMonth,
										isToday && css.today,
										(isSelectedFrom || isSelectedTo) && css.selected,
										isSelectedFrom && css.selectedFrom,
										isSelectedTo && css.selectedTo,
										isDisabled && css.disabled,
									)}
									onClick={e => {
										if (!isDisabled) {
											onChangeDateCursor(changeDate);
											switchToMonths(e);
										}
									}}
									onMouseDown={stopPropagation}
									role={'button'}
									tabIndex={isDisabled ? undefined : -1}
									aria-disabled={isDisabled}
									aria-checked={isSelectedFrom || isSelectedTo}
									aria-label={`Установка даты ${changeDate.toUTCString()}`}
								>
									{format(date, 'YYYY')}
								</div>
							);
						}
						return (
							<div
								key={key + '-' + yearIndex}
								aria-disabled={true}
								className={cls(css.item, css.year, css.disabled)}
							>
								{format(date, 'YYYY')}
							</div>
						);
					})}
			</div>
		);
	};

	const handleScroll = useCallback(
		({scrollTop}: ScrollParams) => {
			const index = Math.ceil(scrollTop / rowHeight);
			onChangeDateCursor(getDateOfCrossYearsLine(index, fromDate));
		},
		[rowHeight],
	);

	const handleToday = useCallback(() => {
		const now = new Date();
		const newDate = new Date(now.getFullYear(), 0, 1);
		const index = diffYear(newDate, fromDate)! / yearsInLine;
		list.current?.scrollToPosition(index * rowHeight - rowHeight);
		onChangeDateCursor(newDate);
	}, []);

	const hasDuration = useMemo(() => {
		const selectedDates = [value?.from ? resetYear(value.from) : null, value?.to ? resetYear(value.to) : null]
			.filter(onlyUnique)
			.filter(Boolean);

		return selectedDates.length === 2;
	}, [value]);

	return (
		<div style={{width: width}} className={cls(css.wrapper, hasDuration && css.hasRange)}>
			<div className={css.header} style={{height: height / 6}}>
				<div className={css.title} onClick={switchToDays} onMouseDown={stopPropagation}>
					{dateCursor.getFullYear()}–{dateCursor.getFullYear() + yearsInLine * 3 - 1}
				</div>
				<div className={css.actions}>
					<Button
						type={'button'}
						action
						iconOnly
						onMouseDown={stopPropagation}
						onClick={handleToday}
						title={'Сегодня'}
					>
						<GoTodayIcon />
					</Button>
				</div>
			</div>

			<List
				onScroll={handleScroll}
				rowCount={yearsLinesCount}
				rowHeight={rowHeight}
				height={(height / 6) * 7}
				tabIndex={null}
				width={width + scrollbarWidth}
				rowRenderer={YearsLine}
				ref={list}
			/>
		</div>
	);
};

export default YearsCalendar;
