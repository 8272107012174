import React from 'react';
import css from './ResourceAttachmentsTableRow.module.css';
import {TableCell, TableRow} from '@material-ui/core';
import useDeleteConfirm from '../../../components/hooks/useDeleteConfirm/useDeleteConfirm';
import {ResourceAttachmentsTableItem} from '../../../queries-generated/types';
import {format, parseIso} from 'ts-date/locale/ru';
import {formatBytes} from '../../../utils/number-utils';
import {DeleteIcon, DownloadIcon} from '../../../components/SvgIcon';
import Button from '../../../components/pirsInputs/Button/Button';

type Props = {
	tableItem: ResourceAttachmentsTableItem;
	onDelete: (id: any) => Promise<void>;
};

const ResourceAttachmentsTableRow: React.FC<Props> = ({tableItem, onDelete}) => {
	const {Confirm, openConfirm} = useDeleteConfirm({
		callback: () => {
			onDelete && onDelete(tableItem.fileId);
		},
		text: `Удалить файл`,
	});

	return (
		<>
			{Confirm}
			<TableRow hover>
				<TableCell>{tableItem.fileName}</TableCell>
				<TableCell>{formatBytes(tableItem.fileSize)}</TableCell>
				<TableCell>{format(parseIso(tableItem.uploadAt), 'DD MMM YYYY, HH:mm')}</TableCell>
				<TableCell className={css.controlsCell}>
					<div className={css.controls}>
						<>
							<a download href={`/services/downloadPirsAttachment?attachmentId=${tableItem.fileId}`}>
								<Button iconOnly action title={'Скачать файл'}>
									<DownloadIcon />
								</Button>
							</a>
							<Button iconOnly action title={'Удалить файл'} onClick={openConfirm}>
								<DeleteIcon />
							</Button>
						</>
					</div>
				</TableCell>
			</TableRow>
		</>
	);
};

export default ResourceAttachmentsTableRow;
