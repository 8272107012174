import React from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {Diagram} from '../../../../../queries-generated/types';
import ChartWrapperWithData from '../ChartsBase/ChartWrapperWithData';
import {ChartTypes} from '../ChartsBase/ChartWrapper';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	pollInterval?: number;
};

const defaultData: Diagram = {
	series: [
		{
			name: 'Chrome',
			points: [
				{name: 'Russia', y: 61.41},
				{name: 'USA', y: 70.41},
			],
		},
		{
			name: 'Internet Explorer',
			points: [
				{name: 'Russia', y: 11.84},
				{name: 'USA', y: 6},
			],
		},
		{
			name: 'Firefox',
			points: [
				{name: 'Russia', y: 10.85},
				{name: 'USA', y: 12},
			],
		},
		{
			name: 'Edge',
			points: [
				{name: 'Russia', y: 4.67},
				{name: 'USA', y: 6},
			],
		},
		{
			name: 'Safari',
			points: [
				{name: 'Russia', y: 4.18},
				{name: 'USA', y: 5},
			],
		},
	],
};

const StackedColumnChartBar: React.FC<Props> = ({viewItem, skipQuery, pollInterval}) => {
	return (
		<ChartWrapperWithData
			pollInterval={pollInterval}
			viewItem={viewItem}
			skipQuery={skipQuery}
			defaultData={defaultData}
			mapper={diagram => diagram}
			type={ChartTypes.StackedColumnBar}
		/>
	);
};

export default StackedColumnChartBar;
