import React, {useMemo} from 'react';
import css from './Diff.module.css';
import {wordByCount} from '../../../../../../utils/word-by-count';
import cls from '../../../../../../utils/cls';

export type DiffValue = {
	id: string;
	title: string;
	old?: string;
	new?: string;
};

type Props = {
	value: DiffValue | DiffValue[];
	isArray: boolean;
	details: boolean;
};

type BaseDiffProps = {
	value: DiffValue;
};

function hasDiff(diffValue: DiffValue) {
	return diffValue.old?.toString().trim() !== diffValue.new?.toString().trim();
}

const BaseDiff: React.FC<BaseDiffProps> = ({value}) => {
	const hasDiv = hasDiff(value);

	return (
		<dl className={css.diff}>
			<dt>Измененное поле:</dt>
			<dd>{value.title}</dd>

			<dt>Старое значение:</dt>
			<span className={cls(hasDiv && css.oldValue)}>{value.old?.toString() ?? '<NULL>'}</span>

			<dt>Новое значение:</dt>
			<dd className={cls(hasDiv && css.newValue)}>{value.new?.toString() ?? '<NULL>'}</dd>
		</dl>
	);
};

const Diff: React.FC<Props> = ({value, details, isArray}) => {
	const diffCount = useMemo(() => {
		if (isArray && Array.isArray(value)) {
			return value.filter(hasDiff).length;
		}
	}, [value, isArray]);

	if (details) {
		if (isArray) {
			return (
				<div className={css.block}>
					{(value as DiffValue[]).map(diff => (
						<BaseDiff value={diff} key={diff.id} />
					))}
				</div>
			);
		} else {
			return (
				<div className={css.block}>
					<BaseDiff value={value as DiffValue} />
				</div>
			);
		}
	} else {
		if (isArray) return <>{wordByCount(diffCount || 0, ['изменение', 'изменения', 'изменений'], true)}</>;
		else return <>{(value as DiffValue).title}</>;
	}
};

export default Diff;
