import React, {useCallback, useRef, useState} from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import {useFocus} from '../inputHooks';
import Field from '../Field/Field';
import css from './InputFile.module.css';
import Button from '../Button/Button';
import uid from '../../../utils/uid';
import {wordByCount} from '../../../utils/word-by-count';
import {AttachFileIcon} from '../../SvgIcon';

interface InputInterface extends CommonInputInterface<FileList> {
	forwardRef?: React.RefObject<HTMLInputElement>;
}

export type InputProps = InputInterface & {
	action?: boolean;
	link?: boolean;
	iconOnly?: boolean;
	preview?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const InputFiles: React.FC<InputProps> = props => {
	const {
		error,
		disabled,
		className,
		children,
		helperText,
		bottomText,
		onChange,
		label,
		value,
		action,
		link,
		iconOnly,
		forwardRef: propsForwardRef,
		preview,
		...inputProps
	} = props;
	const {onBlur, onFocus} = useFocus(props);
	const [files, setFiles] = useState<FileList>();
	const ref = useRef<HTMLInputElement>(null);
	const forwardRef = propsForwardRef || ref;

	const id = props.id || 'Input-' + uid();

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.currentTarget && e.currentTarget.files && e.currentTarget.files.length > 0) {
				onChange(e.currentTarget.files);
				setFiles(e.currentTarget.files);
			}
		},
		[onChange],
	);

	const handleClick = useCallback(() => {
		if (ref?.current) ref.current.click();
	}, []);

	return (
		<Field commonProps={props} normalLabel topLabel={true} className={className}>
			<div className={css.file}>
				<Button
					type="button"
					secondary
					action={action}
					link={link}
					iconOnly={iconOnly}
					onClick={handleClick}
					className={css.button}
					disabled={disabled}
				>
					{children || (
						<>
							<AttachFileIcon />
							Выберите файлы
						</>
					)}
				</Button>
				{preview && (
					<div className={css.text}>
						{files &&
							(files.length > 1 ? (
								<>Выбрано {wordByCount(files.length, ['файл', 'файла', 'файлов'], true)}</>
							) : (
								files.item(0)?.name
							))}
					</div>
				)}

				<input
					id={id}
					disabled={disabled}
					{...(inputProps as any)}
					className={css.fileInput}
					type={'file'}
					onChange={handleChange}
					onBlur={onBlur}
					onFocus={onFocus}
					ref={forwardRef}
					multiple
					tabIndex={-1}
				/>
			</div>
		</Field>
	);
};

export default InputFiles;
