import {accentColorLighter, defaultEdgeColor, defaultNodeColor, graphOptions} from './graphOptions';
import {Value} from '../../../../controlls/DatesRadioSlider/DatesRadioSlider';
import {parseIsoOrThrow} from 'ts-date/locale/ru';
import {GraphEdge, GraphNode, TableInterfaceHttpLink} from '../../../../../queries-generated/types';
import {Edge, Network, Node} from 'vis-network/standalone';
import {between} from '../../../../../utils/number-utils';
import {colors} from '../helper';

export enum PopoverContentType {
	STRING = 'String',
	NUMBER = 'Number',
	// Навигация внутри приложения
	NAVIGATION_ROUTE = 'NavigationRoute',
	// Навигация для внешних ссылок
	NAVIGATION_URL = 'NavigationUrl',
	// Модальное окно с формой createModel
	CREATE_MODEL_POPOVER = 'CreateModelPopover',
}

type CreateModelPopover = {
	model: string;
	initialValues: PlainObjectOf<any>;
};

export type GraphPopoverContent = {
	key?: string;
	type: PopoverContentType;
	title: string;
	order: number;
	ref?: string;
	httpLink?: TableInterfaceHttpLink;
	createModelPopover?: CreateModelPopover;
};

export const directions = {
	topdown: 'UD',
	upwards: 'DU',
	leftright: 'LR',
	rightleft: 'RL',
	star: 'default',
};

export const edgeDeletedProps = {
	hidden: false,
	color: '#a80000',
};

export const edgeAppendedProps = {
	hidden: false,
	color: 'green',
};

export const edgeNormalProps = {
	hidden: false,
	color: defaultEdgeColor,
};

export const nodeRemovedProps = {
	hidden: false,
	color: {border: '#fc9393', background: '#f8b5b5'},
};

export const nodeNormalProps = {
	hidden: false,
	color: defaultNodeColor,
};

export const resetGraphOptions = {
	...graphOptions,
	physics: {...graphOptions.physics},
	nodes: {
		...graphOptions.nodes,
		widthConstraint: false,
	},
	layout: {
		...graphOptions.layout,
		hierarchical: false,
	},
};

export const MAX_DATE = new Date(8640000000000000);
export const MIN_DATE = new Date(-8640000000000000);

export const getDateFromValue = (value?: Value | null) => {
	if (!value || value === 'initial') return MIN_DATE;
	else if (value === 'present') return MAX_DATE;
	else return parseIsoOrThrow(value);
};

const lineSmooth = {
	straight: undefined,
	curved: 0.4,
};

const images = {
	RU: `<svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 20H30V30H0V20Z" fill="#FF3D00"/><path d="M0 10H30V20H0V10Z" fill="#3F51B5"/><path d="M0 0H30V10H0V0Z" fill="#E0E1E2"/></svg>`,
};

function getSectorPath(x: number, y: number, diameter: number, a1: number, a2: number) {
	const degToRad = Math.PI / 180;
	const cr = diameter / 2 - 5;
	const cx1 = Math.cos(degToRad * a2) * cr + x;
	const cy1 = -Math.sin(degToRad * a2) * cr + y;
	const cx2 = Math.cos(degToRad * a1) * cr + x;
	const cy2 = -Math.sin(degToRad * a1) * cr + y;

	return 'M' + x + ' ' + y + ' ' + cx1 + ' ' + cy1 + ' A' + cr + ' ' + cr + ' 0 0 1 ' + cx2 + ' ' + cy2 + 'Z';
}

const circleWithSectors = (diameter: number, numberOfSectors: number, colors: string[], img?: string) => {
	let circle = `<circle cx="${diameter / 2}" cy="${diameter / 2}" r="${diameter *
		0.3}" fill="${accentColorLighter}"  />`;
	if (img) {
		let imageSvg = images[img] as string;
		if (imageSvg) {
			imageSvg = imageSvg.replace(/viewBox/, `width="${diameter}" height="${diameter}" viewBox`);
			circle = `
            <defs>
               <clipPath id="cut-circle">
                  <circle cx="${diameter / 2}" cy="${diameter / 2}" r="${diameter * 0.3}"  />
                </clipPath>
            </defs>

            <g clip-path="url(#cut-circle)">
                <g transform="translate(${diameter * 0.2}, ${diameter * 0.2})">
                    <g transform="scale(0.6)">
                        ${imageSvg}
                    </g>
                </g>
            </g>`;
		}
	}

	const svg = `<svg width="${diameter}" height="${diameter}" viewBox="0 0 ${diameter} ${diameter}" xmlns="http://www.w3.org/2000/svg">
        ${
			numberOfSectors > 1
				? Array(numberOfSectors)
						.fill(undefined)
						.map((_x, index) => {
							const angle = 360 / numberOfSectors;
							return `<path
                        d="${getSectorPath(diameter / 2, diameter / 2, diameter, angle * index, angle * index + angle)}"
                        fill="${colors[index]}"
                    />`;
						})
						.join('')
				: `<circle cx="${diameter / 2}" cy="${diameter / 2}" r="${diameter / 2}" fill="${colors[0]}"  />`
		}
        ${circle}
    </svg>`;
	return svg;
};

const svgToUrl = (svg: string) => {
	return 'data:image/svg+xml;utf-8,' + encodeURIComponent(svg).replace(' ', '');
};

export const toVisEdge = (edge: GraphEdge, graphOptions: PlainObjectOf<any>): Edge => {
	const data: Edge = {
		from: edge.from,
		to: edge.to,
		id: edge.id,
		label: edge.label,
		chosen: graphOptions?.layers ? false : undefined,
	};
	if (edge.options) {
		const background: PlainObjectOf<string | number> = {};
		if (edge.options.arrows) {
			data.arrows = {};
			if (edge.options.arrows.from)
				data.arrows.from = {
					enabled: true,
					type: edge.options.arrows.from,
				};
			if (edge.options.arrows.to)
				data.arrows.to = {
					enabled: true,
					type: edge.options.arrows.to,
				};
			if (edge.options.arrows.middle)
				data.arrows.middle = {
					enabled: true,
					type: edge.options.arrows.middle,
				};
		}

		if (edge.options.color && colors[edge.options.color]) {
			background.color = colors[edge.options.color];
		}

		if (edge.options.line && lineSmooth[edge.options.line]) {
			data.smooth = {
				enabled: true,
				type: 'dynamic',
				roundness: lineSmooth[edge.options.line],
			};
		}

		if (edge.options.thickness) {
			data.width = edge.options.thickness / 100;
			background.size = edge.options.thickness / 25;
		}

		if (Object.keys(background) && edge.options.color && colors[edge.options.color]) {
			const thickness = edge.options.thickness || 100;
			// @ts-ignore
			data.background = {...background, enabled: true, dashes: [thickness / 10, thickness / 20]};
		}
	}
	return data;
};

const shapeStyles = (style: string) => {
	switch (style) {
		case 'square':
		case 'diamond':
		case 'triangle':
			return style;
		default:
			return 'dot';
	}
};

export const toVisNode = (
	node: GraphNode,
	graphOptions: PlainObjectOf<any>,
	layersColors: PlainObjectOf<string>,
): Node => {
	const data: Node = {
		id: node.id,
		label: node.label,
		chosen: graphOptions?.layers ? false : undefined,
	};
	if (node.options) {
		if (node.options.borderColor && colors[node.options.borderColor]) {
			data.color = {border: colors[node.options.borderColor]};
		}
		if (node.options.borderThickness) {
			data.borderWidth = node.options.borderThickness / 100;
		} else {
			data.borderWidth = 10;
		}
		if (node.options.style) {
			data.shape = shapeStyles(node.options.style);
		}

		if (node.options.image && images[node.options.image]) {
			data.shape = 'circularImage';
			data.image = svgToUrl(images[node.options.image]);
		}

		if (node.layers) {
			data.shape = 'circularImage';
			data.image = svgToUrl(
				circleWithSectors(
					100,
					node.layers.length,
					node.layers.map(layer => layersColors[layer]),
					node.options.image,
				),
			);
		}

		if (graphOptions.hasRank) {
			data.level = node.options.rank || graphOptions.maxRank || 0;
		}
	}
	return data;
};

function getNumberOfOrbits(nodes: GraphNode[], firstProgressionItem: number, progressionStep: number) {
	let n = 0;
	let orbit = 0;
	while (n < nodes.length) {
		orbit++;
		n += getNodesByOrbit(orbit, firstProgressionItem, progressionStep);
	}
	return orbit;
	//return Math.ceil((nodes.length - firstProgressionItem) / progressionStep + 1);
}

function getNodesByOrbit(orbit: number, firstProgressionItem: number, progressionStep: number) {
	if (orbit === 1 && firstProgressionItem === 1) return 1;
	return firstProgressionItem + (orbit - 1) * progressionStep;
}

function getRadiusByOrbit(orbit: number, firstProgressionItem: number) {
	if (firstProgressionItem === 1 && orbit === 1) return 0;
	return firstProgressionItem === 1 ? (orbit - 1) * 200 : orbit * 200;
}

function getSliceByOrbit(
	orbit: number,
	nodes: GraphNode[],
	sliceDiff: number,
	firstProgressionItem: number,
	progressionStep: number,
) {
	const currentCount = getNodesByOrbit(orbit, firstProgressionItem, progressionStep);

	let beforeCount = -sliceDiff;
	for (let i = 1; i <= orbit - 1; i++) {
		beforeCount += getNodesByOrbit(i, firstProgressionItem, progressionStep);
	}
	let end = beforeCount + currentCount;
	end = end > nodes.length + 1 ? nodes.length + 1 : end;
	return [beforeCount, end];
}

type NodeWithCoord = {
	id: string;
	x: number;
	y: number;
};

function placePartOfNodes(
	nodes: GraphNode[],
	numberOfOrbit: number,
	startFrom: number,
	firstProgressionItem: number,
	progressionStep: number,
) {
	let data: NodeWithCoord[] = [];
	let breakNodes = 0;
	for (let i = 1; i <= startFrom - 1; i++) {
		breakNodes += getNodesByOrbit(i, firstProgressionItem, progressionStep);
	}
	for (let orbit = startFrom; orbit <= numberOfOrbit; orbit++) {
		const radius = getRadiusByOrbit(orbit, firstProgressionItem);
		const currentNodes = nodes.slice(
			...getSliceByOrbit(orbit, nodes, breakNodes, firstProgressionItem, progressionStep),
		);

		const step = (2 * Math.PI) / currentNodes.length;
		data = data.concat(
			currentNodes.map((node, index) => {
				return {
					id: node.id,
					x: radius * Math.sin(index * step),
					y: radius * Math.cos(index * step),
				};
			}),
		);
	}

	return data;
}

export const getEdgeRangeByDate = (edge: GraphEdge, historyDate: Date) => {
	if (!edge.ranges || !edge.ranges.length) return null;
	return (
		edge.ranges.find(range => {
			return between(
				historyDate.getTime(),
				getDateFromValue(range.startAt).getTime(),
				getDateFromValue(range.endAt).getTime(),
				true,
			);
		}) || null
	);
};

export const getNodeRangeByDate = (node: GraphNode, historyDate: Date) => {
	if (!node.ranges || !node.ranges.length) return null;
	return (
		node.ranges.find(range =>
			between(
				historyDate.getTime(),
				getDateFromValue(range.startAt).getTime(),
				getDateFromValue(range.endAt).getTime(),
				true,
			),
		) || null
	);
};

const getEdgeRangeByDateAndPrevDate = (edge: GraphEdge, historyDate: Date, prevHistoryDate: Date) => {
	if (!edge.ranges || !edge.ranges.length) return null;
	return (
		edge.ranges.find(range => {
			const startAt = getDateFromValue(range.startAt);
			const endAt = getDateFromValue(range.endAt);
			return (
				between(historyDate.getTime(), startAt.getTime(), endAt.getTime(), true) &&
				!between(prevHistoryDate.getTime(), startAt.getTime(), endAt.getTime(), true)
			);
		}) || null
	);
};

export const getEdgeLayers = (edge: GraphEdge, historyDate: Date | null) => {
	if (historyDate) return getEdgeRangeByDate(edge, historyDate)?.layers || [];
	return edge.layers || [];
};

export const getCurrentLayerEdgesIds = (edges: GraphEdge[], layer: string, historyDate: Date | null) => {
	return edges.filter(edge => getEdgeLayers(edge, historyDate).includes(layer)).map(edge => edge.id);
};

export const getRemovedNodesIdsByLayers = (nodes: GraphNode[], layers: string[], showNoLayersNode: boolean) => {
	return nodes
		.filter(node => {
			if (!node.layers?.length) return !showNoLayersNode;
			return !layers.some(layer => node.layers && node.layers.includes(layer));
		})
		.map(node => node.id);
};

export const getRemovedEdgesIdsByLayers = (
	edges: GraphEdge[],
	layers: string[],
	showNoLayersNode: boolean,
	historyDate: Date | null,
) => {
	return edges
		.filter(edge => {
			const edgeLayers = getEdgeLayers(edge, historyDate);
			if (!edgeLayers.length) return !showNoLayersNode;

			return !layers.some(layer => edgeLayers.includes(layer));
		})
		.map(edge => edge.id);
};

export const getRemovedEdgesIdsByHistory = (edges: GraphEdge[], historyDate: Date) => {
	return edges.filter(edge => !getEdgeRangeByDate(edge, historyDate)).map(edge => edge.id);
};

export const getAddedEdgesIdsByHistory = (edges: GraphEdge[], historyDate: Date, prevHistoryDate: Date) => {
	return edges.filter(edge => getEdgeRangeByDateAndPrevDate(edge, historyDate, prevHistoryDate)).map(edge => edge.id);
};

export const getRemovedNodesIdsByHistory = (nodes: GraphNode[], graph: Network, removedEdgesIds: string[]) => {
	return nodes
		.filter(node => {
			const nodeEdgesIds = graph.getConnectedEdges(node.id as string) as string[];
			return nodeEdgesIds.every(edgeId => removedEdgesIds.includes(edgeId));
		})
		.map(node => node.id);
};

export function placeNodes(nodes: GraphNode[], firstProgressionItem: number, progressionStep: number) {
	const important = nodes.filter(node => node.options?.isImportant);
	const other = nodes.filter(node => !node.options?.isImportant);
	const importantOrbits = getNumberOfOrbits(important, firstProgressionItem, progressionStep);
	return [
		...placePartOfNodes(important, importantOrbits, 1, firstProgressionItem, progressionStep),
		...placePartOfNodes(
			other,
			importantOrbits + getNumberOfOrbits(other, firstProgressionItem, progressionStep),
			importantOrbits + 1,
			firstProgressionItem,
			progressionStep,
		),
	];
}

export function getEdgesByNodeAndLayers(nodeId: string, layers: string[], nodes: GraphNode[], edges: GraphEdge[]) {
	const node = nodes.find(node => node.id === nodeId);
	if (!node) return [];
	const paths = node.options?.path;

	let edgesIds: string[] = [];
	for (const layer in paths) {
		if (layers.includes(layer)) edgesIds = [...edgesIds, ...paths[layer]];
	}
	return edges.filter(edge => edgesIds.includes(edge.id));
}
