import React, {useCallback, useState} from 'react';
import css from './NotificationsMessage.module.css';
import cls from '../../utils/cls';
import {useReadNotificationsMutation, Notification} from '../../queries-generated/types';
import {format} from 'ts-date/locale/ru';
import {FormatFn} from 'ts-date/src/utils/basic-types';
import {ApolloError} from '@apollo/client';

type Props = {
	notification: Notification;
	newMessage?: boolean;
};

const HUMAN_DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';

function humanDateFormat(date: string): FormatFn<Date> {
	return format(new Date(date), HUMAN_DATE_FORMAT);
}

const NotificationsMessage: React.FC<Props> = ({notification, newMessage}) => {
	const {id, message, createdAt, checked} = notification;
	const [open, setOpen] = useState<boolean>(false);
	const [readNotificationsError, setReadNotificationsError] = useState<ApolloError>();

	const [readNotificationsMutation, {client}] = useReadNotificationsMutation();

	const handleReadMessage = useCallback(() => {
		setOpen(true);
		if (!checked) {
			setReadNotificationsError(undefined);
			readNotificationsMutation({
				variables: {notificationsIds: [id]},
				optimisticResponse: {
					readNotifications: [{...notification, checked: true}],
				},
			})
				.then(() => {
					client.cache.modify({
						fields: {
							notificationsCount: value => value - 1,
						},
					});
				})
				.catch(error => {
					setReadNotificationsError(error);
				});
		}
	}, [client, checked, readNotificationsMutation, setReadNotificationsError, setOpen]);

	const handleCloseMessage = useCallback(() => {
		setOpen(false);
		setReadNotificationsError(undefined);
	}, [setOpen, setReadNotificationsError]);

	return (
		<li
			onClick={open ? handleCloseMessage : handleReadMessage}
			className={cls(
				css.container,
				!checked && !readNotificationsError && css.unread,
				open && readNotificationsError && css.readError,
				newMessage && css.newMessage,
			)}
		>
			<div className={cls(css.message, open && css.messageOpen)} dangerouslySetInnerHTML={{__html: message}} />
			<div className={css.date}>{humanDateFormat(createdAt)}</div>
			{readNotificationsError && (
				<div className={css.errorMessage}>Ошибка отправки данных о прочтении сообщения</div>
			)}
		</li>
	);
};

export default React.memo(NotificationsMessage);
