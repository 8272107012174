import React from 'react';
import css from './ResourceRegisterSidebar.module.css';
import {usePirsSidebarQuery} from '../../../queries-generated/types';
import ProgressRing from '../../../components/controlls/Loader/ProgressRing';
import List from '@material-ui/core/List/List';
import {getError} from '../../../utils/error';
import OrderList from './ResourceRegisterOrderList';
import Button from '../../../components/pirsInputs/Button/Button';
import {RefreshIcon} from '../../../components/SvgIcon';

// TODO Добавить подсказку для каждой сущности

const ResourceRegisterSideBar: React.FC = () => {
	const {data, loading, error, refetch} = usePirsSidebarQuery({
		notifyOnNetworkStatusChange: true,
	});

	return (
		<div className={css.container}>
			{error && (
				<div className={css.sideBarError}>
					<h3 className={css.errorMessage}>{getError(error)?.message}</h3>
					<h4 className={css.errorMessage}>Пожалуйста, повторите попытку</h4>
					<div>
						<Button action onClick={refetch}>
							<RefreshIcon />
						</Button>
					</div>
				</div>
			)}

			{loading && <ProgressRing />}

			{!loading && !error && !data?.pirsSidebar.ordersName.length && (
				<div className={css.sideBarError}>
					<h4 className={css.errorMessage}>Список приказов пуст</h4>
					<div>
						<Button action onClick={refetch}>
							<RefreshIcon />
						</Button>
					</div>
				</div>
			)}

			{data && (
				<List component="nav" style={{padding: 0}}>
					{data.pirsSidebar.ordersName.map(key => (
						<OrderList key={key} header={key} items={data.pirsSidebar.orders[key]} />
					))}
				</List>
			)}
		</div>
	);
};

export default ResourceRegisterSideBar;
