import React from 'react';
import css from './Progress.module.css';
import cls from '../../../utils/cls';

type Props = {
	label?: string;
	helperText?: string;
	value?: number | null;
};

const Progress: React.FC<Props> = ({value, label, helperText}) => {
	return (
		<div className={css.progressContainer}>
			{label && <div className={css.label}>{label}</div>}
			<div className={css.progress}>
				{value !== null && value !== undefined ? (
					<>
						<div className={css.track} />
						<div
							className={css.bar}
							role="progressbar"
							aria-valuemin={0}
							aria-valuemax={100}
							aria-valuenow={value}
							style={{width: `${value}%`}}
						/>
					</>
				) : (
					<div className={cls(css.bar, css.indeterminate)} role="progressbar" />
				)}
			</div>
			{helperText && <div className={css.note}>{helperText}</div>}
		</div>
	);
};

export default Progress;
