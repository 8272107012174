import React, {useCallback, useMemo, useRef} from 'react';
import css from './DataGrid.module.css';
import Button from '../pirsInputs/Button/Button';
import {RefreshIcon} from '../SvgIcon';
import {CommonButtons} from '../view/useComponentCommonButtons';
import useObserver from '../hooks/useObserver';
import InputSearch from '../pirsInputs/InputSearch/InputSearch';
import {DataGridAction} from '../settings/views/components/helper';

type Props = {
	actionButtons?: DataGridAction[];
	searchable?: boolean;
	reLoad?: () => void;
	loading?: boolean;
	commonButtons?: CommonButtons;
	onChangeSearchQuery?: (text: string) => void;
	minLengthSearchQuery?: number;
	onActionButtonRender: (
		area: 'head',
		action: DataGridAction,
		item: any,
		selectedIds: string[],
		fullSize: boolean,
	) => React.ReactNode;
	selectedIds: string[];
};

const MIN_WIDTH = 500;

const DataGridHeadPanel: React.FC<Props> = ({
	commonButtons,
	loading,
	actionButtons,
	onChangeSearchQuery,
	reLoad,
	searchable,
	minLengthSearchQuery,
	onActionButtonRender,
	selectedIds,
}) => {
	const headRef = useRef<HTMLDivElement>(null);
	const {width} = useObserver(headRef);
	const handleChangeSearchQuery = useCallback(
		(text: string) => {
			if (onChangeSearchQuery) {
				if ((minLengthSearchQuery && text.length > minLengthSearchQuery) || minLengthSearchQuery == null) {
					onChangeSearchQuery(text);
				} else {
					onChangeSearchQuery('');
				}
			}
		},
		[minLengthSearchQuery],
	);
	const fullSize = width > MIN_WIDTH;

	const renderedActionButtons = useMemo(() => {
		if (actionButtons?.length) {
			return actionButtons.map(actionButton => (
				<React.Fragment key={actionButton.id}>
					{onActionButtonRender('head', actionButton, null, selectedIds, fullSize)}
				</React.Fragment>
			));
		}
		return null;
	}, [actionButtons, fullSize, onActionButtonRender, selectedIds]);

	return (
		<div className={css.headActions} ref={headRef}>
			<div className={css.leftActions}>{renderedActionButtons}</div>
			<div className={css.rightActions}>
				{reLoad && (
					<Button iconOnly action onClick={reLoad} disabled={loading}>
						<RefreshIcon />
					</Button>
				)}

				{commonButtons?.RefreshAction}

				{searchable && <InputSearch onChange={handleChangeSearchQuery} full={fullSize} />}

				{commonButtons?.FilterAction}
			</div>
		</div>
	);
};

export default DataGridHeadPanel;
