import css from './MainMenu.module.css';
import React, {useCallback} from 'react';
import cls from '../../../utils/cls';
import useLocalStorage from '../../hooks/useLocalStorage';
import {useLogoutMutation} from '../../../queries-generated/types';
import {
	DiagramIcon,
	EyeOpenIcon,
	GraphIcon,
	HomeIcon,
	ImportantIcon,
	InformationIcon,
	NavigationIcon,
	SettingsIcon,
	SignOutIcon,
	TaskListIcon,
	WarningIcon,
} from '../../SvgIcon';
import Button from '../../pirsInputs/Button/Button';
import List from '../../List/List';
import {RouteParams, useRouter} from 'react-named-hooks-router';
import {useRefreshIntervalFromLocalStorage} from '../../settings/views/components/helper';

export type MenuItem = {
	title: string;
	route?: string;
	icon?: React.ReactNode;
	childRoutes?: string[];
	params?: RouteParams;
	onClick?: () => void;
	items?: MenuItem[];
};

function isActiveBase(item: MenuItem, route: string): boolean {
	return !!(item.route === route || item.childRoutes?.includes(route));
}

export function isActive(item: MenuItem, route: string): boolean {
	let isActive = isActiveBase(item, route);
	if (isActive) return true;

	if (item && item.items) isActive = item.items.some(item => isActiveBase(item, route));

	return isActive;
}

const MainMenu: React.FC = () => {
	const [mainExpanded, setMainExpanded] = useLocalStorage('expandedMenu', true);
	const {clearRefreshIntervals} = useRefreshIntervalFromLocalStorage();

	const toggleExpandedHandler = useCallback(() => {
		setMainExpanded(!mainExpanded);
	}, [mainExpanded, setMainExpanded]);
	const [makeLogout] = useLogoutMutation();

	const handleLogoutRequest = useCallback(() => {
		if (process.env.REACT_APP_CROP === 'true') {
			// pushRoute('logout');
			location.href = '/logout';
		} else {
			location.href = '/';
		}
	}, []);

	const handleLogout = useCallback(() => {
		clearRefreshIntervals();
		makeLogout().finally(handleLogoutRequest);
	}, [handleLogoutRequest, makeLogout, clearRefreshIntervals]);

	const settingsItems: MenuItem[] = [
		{
			title: 'Аудит',
			route: 'settingsAudit',
			childRoutes: ['settingsAudit'],
		},
		{
			title: 'Интерфейс',
			route: 'settingsInterface',
			childRoutes: ['settingsInterface'],
		},
		{
			title: 'Источники данных',
			route: 'settingsWssource',
			childRoutes: ['settingsWssource'],
		},
		{
			title: 'Отчёты',
			route: 'settingsReportTemplates',
			childRoutes: [
				'settingsReportTemplates',
				'settingsReportTemplatesEdit',
				'settingsSavedItems',
				'settingsSchedule',
			],
		},
		{
			title: 'Файлы',
			route: 'settingsFile',
			childRoutes: ['settingsFile'],
		},
		{
			title: 'Интеграция',
			route: 'integrationConfigurationTable',
			childRoutes: [
				'integrationConfigurationTable',
				'integrationCorrelatorConfiguration',
				'integrationDataSource',
				'integrationModules',
				'integrationRulesEditor',
				'integrationUniversalScriptTable',
				'integrationUniversalScriptEditor',
				'integrationUniversalScriptAdapter',
				'integrationTranslatorsScriptTable',
				'integrationTranslatorsScriptEditor',
				'integrationTranslatorRule',
			],
		},
		{
			title: 'Уведомления',
			route: 'routerConfiguration',
			childRoutes: [
				'routerConfiguration',
				'routerConfigurationRule',
				'recipientGroup',
				'recipientGroupRule',
				'recipientBind',
				'integrationNotificationStatus',
				'integrationDetailedNotificationStatus',
			],
		},
		{
			title: 'Дашборды',
			route: 'settingsViews',
			childRoutes: ['settingsViews', 'settingsViewsUpdate'],
		},
		{
			title: 'ПАД',
			route: 'padAnomalyThresholds',
			childRoutes: [
				'padAnomalyThresholds',
				'padNetflowThresholds',
				'padCatalogs',
				'padICMPCode',
				'padAsGroupAsObjects',
				'padServiceProtocols',
				'padServiceProtocolsServiceProtocolGroup',
				'padOperatorGroupOperators',
				'padNetworkService',
				'padTask',
				'padTaskDetails',
				'padTrackingAs',
				'padTrackingOperator',
				'padApiConfiguration',
			],
		},
		{
			title: 'Токены',
			route: 'settingsTokens',
			childRoutes: ['settingsTokens'],
		},
	];

	const menuItems: MenuItem[] = [
		{title: 'Домашняя', route: 'home', icon: <HomeIcon />},
		{
			title: 'Связи АС',
			route: 'asLinksTop',
			childRoutes: ['asLinksTop', 'asLinksDetail', 'asLinksRegistries'],
			icon: <GraphIcon />,
		},
		{
			title: 'NetFlow',
			route: 'reportsTop',
			childRoutes: ['reportsTop', 'reportsTrafficAnalysis'],
			icon: <DiagramIcon />,
		},
		{
			title: 'Аномалии',
			route: 'alertsSuperAnomaliesActive',
			childRoutes: [
				'alertsSuperAnomaliesActive',
				'alertsSpread',
				'alertsSuperAnomaliesNested',
				'alertsSuperAnomaliesIgnored',
				'padSuperAnomaliesPriorityRule',
				'padSuperAnomaliesIssueRule',
			],
			icon: <WarningIcon />,
		},
		{
			title: 'Информация',
			icon: <InformationIcon />,
			items: [
				{
					title: 'Представление',
					route: 'filesConsole',
					childRoutes: ['filesConsole', 'fileConsole'],
				},
				{
					title: 'Реестр ресурсов',
					route: 'resourceRegister',
					childRoutes: ['resourceRegister', 'resourceAttachments', 'resourceRegisterInstance'],
				},
				{
					title: 'Контроль',
					route: 'filesControl',
					childRoutes: ['filesControl', 'filesControlByOrganization'],
				},
				{
					title: 'Контакты',
					route: 'informationContacts',
					childRoutes: ['informationContacts'],
				},
				{
					title: 'Аномалии',
					route: 'informationAnomalies',
					childRoutes: ['informationAnomalies'],
				},
				{
					title: 'Доступ к API',
					route: 'informationApiAccess',
					childRoutes: ['informationApiAccess'],
				},
			],
		},
		{
			title: 'Заявки',
			icon: <ImportantIcon />,
			items: [
				{
					title: 'Аномалии',
					route: 'alertsStatistic',
					childRoutes: ['alertsStatistic', 'alertsAnomalies'],
				},
				{
					title: 'Инциденты',
					route: 'incidents',
					childRoutes: ['incidents', 'requests'],
				},
			],
		},
		{
			title: 'Представления',
			icon: <EyeOpenIcon />,
			items: [
				{
					title: 'Мониторинг',
					route: 'surveySummaryCharts',
					childRoutes: ['surveySummaryCharts', 'surveyResourceOwners', 'surveyResourceAs', 'surveySummary'],
				},
				{
					title: 'Пользовательские',
					route: 'surveySummaryViews',
					childRoutes: ['surveySummaryViews', 'SurveyReportTemplates', 'SurveyReports'],
				},
				{
					title: 'Интерфейсы на ТОТ',
					route: 'surveyTrafficExchangePoints',
					childRoutes: ['surveyTrafficExchangePointsDetails'],
				},
				{
					title: 'Интерфейсы на ТГЛС',
					route: 'surveyCrossBorderCommunicationLines',
				},
			],
		},
		{
			title: 'Поручения',
			route: 'directiveContacts',
			childRoutes: [
				'directiveContacts',
				'directiveOrganizations',
				'directiveMailingGroups',
				'directiveUnconfirmedChanges',
				'directiveChangesHistory',
				'directiveDirectives',
				'directiveDirectiveDrafts',
			],
			icon: <TaskListIcon />,
		},
		{
			title: 'Настройки',
			icon: <SettingsIcon />,
			items: settingsItems,
		},
		{title: 'Выход', icon: <SignOutIcon />, onClick: handleLogout},
	];

	const {routeName} = useRouter();

	return (
		<div className={cls(css.wrapper, !mainExpanded && css.short)}>
			<Button action iconOnly onClick={toggleExpandedHandler} className={css.hamburger} tabIndex={0}>
				<NavigationIcon />
			</Button>
			<List
				items={menuItems}
				itemSelected={item =>
					!!(item.route && (item.route === routeName || item.childRoutes?.includes(routeName)))
				}
				itemOpen={item => isActive(item, routeName)}
				collapsed={!mainExpanded}
				tabIndex={0}
				itemWrapperClassName={css.itemWrapper}
			/>
		</div>
	);
};

export default MainMenu;
