import React, {useCallback} from 'react';
import css from './DatesRadioSlider.module.css';
import cls from '../../../utils/cls';
import {format, formatDateTimeIso} from 'ts-date/locale/ru';
import {Value} from './DatesRadioSlider';
import {getDateFromValue} from '../../settings/views/components/Graph/graphHelper';

type Props = {
	value: Value;
	currentValue?: Value;
	prevValue?: Value;
	isDiffPoint: boolean;
	onChange: (value: Value) => void;
};

const markToHtml = (value: Value, prevValue?: Value) => {
	if (value === 'initial') return 'Начальный момент';
	if (value === 'present') return 'Настоящий момент';

	const date = getDateFromValue(value);
	const prevDate = prevValue && getDateFromValue(prevValue);
	const dateFormat =
		prevDate && formatDateTimeIso(date)!.substring(0, 10) === formatDateTimeIso(prevDate)!.substring(0, 10)
			? 'HH:mm'
			: 'DD MMM YYYY HH:mm';
	return <span title={formatDateTimeIso(date) as string}>{format(date, dateFormat)}</span>;
};

const DatesRadioSliderPoint: React.FC<Props> = ({value, currentValue, isDiffPoint, prevValue, onChange}) => {
	const handleChange = useCallback(() => {
		onChange(value);
	}, [value, onChange]);

	return (
		<div key={value} onClick={handleChange} className={css.pointWrapper}>
			<div className={cls(css.point, currentValue === value && css.currentPoint, isDiffPoint && css.diffPoint)} />

			<div className={css.label}>{markToHtml(value, prevValue)}</div>
		</div>
	);
};

export default DatesRadioSliderPoint;
