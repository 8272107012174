import * as React from 'react';
import MainLayout from '../../../components/layouts/MainLayout';
import {useModelItemQuery} from '../../../queries-generated/types';
import {useRouter} from 'react-named-hooks-router';
import ModifyRule, {Rule} from '../../../components/settings/rule/ModifyRule';
import css from './SettingsRulePage.module.css';
import ProgressRing from '../../../components/controlls/Loader/ProgressRing';

const SettingsRulePage: React.FC = () => {
	const {routeParams} = useRouter<{id: string}>();
	const {data} = useModelItemQuery({
		variables: {
			model: 'integrationRule',
			id: routeParams.id,
		},
	});

	return (
		<MainLayout>
			{data?.modelItem ? (
				<div className={css.wrapper}>
					<ModifyRule rule={data?.modelItem.item as Rule} />
				</div>
			) : (
				<ProgressRing />
			)}
		</MainLayout>
	);
};

export default SettingsRulePage;
