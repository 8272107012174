import React from 'react';
import css from './Message.module.css';
import cls from '../../utils/cls';
import Shimmer from '../Shimmer/Shimmer';

const MessageShimmer: React.FC = () => {
	return (
		<>
			<Shimmer>
				<div className={cls(css.wrapper, css.interlocutor)} style={{height: 50}} />
			</Shimmer>

			<Shimmer>
				<div className={cls(css.wrapper, css.myself)} style={{height: 50}} />
			</Shimmer>
		</>
	);
};

export default MessageShimmer;
