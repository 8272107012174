import React from 'react';
import Tabs, {Tab} from '../../../components/controlls/tabs/Tabs';
import SuperAnomalyFastFilter from './SuperAnomalyFastFilter';

type Props = {
	fastFilterDataSourceId: string;
	onSetFastFilterValues: (values: any) => void;
	fastFilterValues?: PlainObjectOf<any>;
};

const SuperAnomalyFiltersWrapper: React.FC<Props> = ({
	fastFilterDataSourceId,
	onSetFastFilterValues,
	fastFilterValues,
}) => {
	return (
		<Tabs>
			<Tab header={'Быстрые'}>
				<SuperAnomalyFastFilter
					fastFilterDataSourceId={fastFilterDataSourceId}
					onSubmitFilter={onSetFastFilterValues}
					filterValues={fastFilterValues}
				/>
			</Tab>
		</Tabs>
	);
};

export default SuperAnomalyFiltersWrapper;
