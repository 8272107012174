import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {
	arrayDiff,
	arrayIntersect,
	isArrayIntersect,
	onlyUnique,
	removeArrayElement,
	removeArrayElementByIndex,
} from '../../utils/array-utils';
import {DataGridOptions} from './DataGrid';
import {Grid} from '../../queries-generated/types';
import Button from '../pirsInputs/Button/Button';
import {CrossIcon} from '../SvgIcon';
import css from './DataGrid.module.css';
import {useBoolean} from '../hooks/useBoolean';
import cls from '../../utils/cls';
import flatTree from '../../utils/flatTree';
import {cloneDeep} from '@apollo/client/utilities';

export type DataGridTree = {
	totalSelected: number;
	total?: number;
	id: string;
	exclude?: string[];
	include?: string[];
	children?: DataGridTree[];
	parentId?: string;
};

function selectionDiff(selection: string[], ids: string[]): string[] {
	let newSelection: string[] = [...selection];
	// Если добавляется 1 строка, то тоглим ее в exclude
	if (ids.length === 1) {
		const id = ids[0];
		const index = newSelection.indexOf(id);
		if (index > -1) newSelection = removeArrayElementByIndex(newSelection, index);
		else newSelection.push(id);
		// Иначе мы работаем с группой. Ее добавляем/убираем целиком
	} else {
		// Если пересекаются, значит надо убрать все
		if (isArrayIntersect(ids, newSelection)) {
			newSelection = arrayDiff(newSelection, ids);
			// Иначе добавить все
		} else {
			newSelection = [...newSelection, ...ids].filter(onlyUnique);
		}
	}

	return newSelection;
}

function getPartialSelection(children: DataGridTree[]) {
	return (
		children
			.filter(
				child =>
					(child.totalSelected !== child.total && child.totalSelected > 0) ||
					(child.children && getPartialSelection(child.children).length > 0),
			)
			.map(child => child.id) || []
	);
}

function useDataGridSelection(pageItems: Grid['items'] | undefined, options: DataGridOptions) {
	const {
		selectable,
		fullSelectable,
		selectedIds,
		selectedDirectories: selectedDirectoriesProps,
		onSelect,
		onSelectDirectories,
		total = 0,
		directoryId,
		path,
	} = options;

	const selectedDirectories = useMemo(() => cloneDeep(selectedDirectoriesProps), [selectedDirectoriesProps]);
	const flattenTree = useMemo(() => selectedDirectories && flatTree(selectedDirectories), [selectedDirectories]);

	const pageIds = useMemo(() => (pageItems && pageItems.map(item => item.id as string)) || [], [pageItems]);

	const directoryIds = useRef<PlainObjectOf<string[]>>({});
	const pathIds = useMemo(() => ['root', ...(path || []).map(item => item.id)], [path]);

	const isDirectorySelectedByAncestor = (targetDirectoryId: string) => {
		if (pathIds.length < 2) return null;
		let pathCursor = pathIds.length - 2;
		let ancestor: DataGridTree | null = null;
		let ancestorChildId: string = targetDirectoryId;
		while (pathCursor >= 0) {
			ancestorChildId = pathIds[pathCursor + 1];
			ancestor = (flattenTree && flattenTree[pathIds[pathCursor]]) || null;
			pathCursor--;
		}

		return (
			ancestor &&
			((ancestor.exclude && !ancestor.exclude.includes(ancestorChildId)) ||
				(ancestor.include && ancestor.include.includes(ancestorChildId)))
		);
	};

	const getSelectedPageIds = (directoryId: string, directory?: DataGridTree | null) => {
		let ids: string[] = [];

		// NOTE: Что-то включено или просто режим id
		if (directory?.include || selectedIds) {
			ids = arrayIntersect(directory?.include || selectedIds, pageIds);
			// NOTE: Исключено
		} else if (directory?.exclude) {
			ids = arrayDiff(pageIds, directory.exclude || []);
			// NOTE: Выбрано предком
		} else if (directoryId && isDirectorySelectedByAncestor(directoryId)) {
			ids = pageIds;
		} else {
			ids = [];
		}

		// NOTE: Включаем детей, у которых все выбрано (по идее созданные без total (когда мы в папку не заходили) выбраны в лучшем случае частично
		const fullSelected = directory?.children?.filter(
			child => child.total != null && child.totalSelected === child.total,
		);
		if (fullSelected) ids = [...ids, ...fullSelected.map(child => child.id)].filter(onlyUnique);

		return ids;
	};

	// Создает недостающих предков
	const createAncestors = useCallback(
		(newDirectory: DataGridTree) => {
			if (!flattenTree) return null;

			let distantAncestor: DataGridTree | null = null;
			let pathCursor = pathIds.length - 1;

			// NOTE: Идем вглубь пути. Если находим папку из структуры, значит это ближайший предок
			while (distantAncestor == null && pathCursor >= 0) {
				if (flattenTree[pathIds[pathCursor]]) distantAncestor = flattenTree[pathIds[pathCursor]];
				else pathCursor--;
			}

			if (distantAncestor) {
				// NOTE: Если ближайший предок - не текущая папка, добавляем у него ребенка (из нашего пути) в исключение
				if (distantAncestor.id !== directoryId) {
					const childOfDistantAncestorId = pathIds[pathCursor + 1] || newDirectory.id;
					if (distantAncestor.exclude) {
						distantAncestor.exclude.push(childOfDistantAncestorId);
						distantAncestor.totalSelected = distantAncestor.totalSelected - distantAncestor.exclude.length;
					} else if (distantAncestor.include) {
						distantAncestor.include = removeArrayElement(distantAncestor.include, childOfDistantAncestorId);
						distantAncestor.totalSelected = distantAncestor.include.length;
					}
				}

				let parent = distantAncestor;
				// NOTE: От ближайшего предка идем вверх, к текущей папке. Создаем элементы с пустым выбором, добавляя их в цепочку
				for (let i = pathCursor + 1; i <= pathIds.length - 1; i++) {
					const directoryId = pathIds[i];

					let child: DataGridTree = {
						id: directoryId,
						parentId: parent.id,
						include: [],
						totalSelected: 0,
					};
					if (directoryId === newDirectory.id) {
						child = newDirectory;
						child.parentId = parent.id;
					}
					parent.children = parent.children ? [...parent.children, child] : [child];
					parent = child;
				}
			}
		},
		[flattenTree, pathIds, directoryId],
	);

	/**
	 * Кейсы:
	 * Выбрали 1 контакт (добавляем в include или убираем в exclude)
	 * Отменили 1 контакт (убираем из include или добавляем в include)
	 * Выбрали 1 папку (создаем ребенка, добавляем в include или убираем в exclude)
	 * Отменили 1 папку (создаем ребенка, добавляем в include или убираем в exclude)
	 */
	const modifyDirectoryTree = useCallback(
		(ids: string[], all?: boolean) => {
			if (!directoryId || !flattenTree) return;

			const directory: DataGridTree = flattenTree[directoryId] || {
				id: directoryId,
				totalSelected: 0,
			};

			const directorySelectedByAncestor = isDirectorySelectedByAncestor(directoryId);

			createAncestors(directory);

			if (all) {
				directory.exclude = [];
				directory.totalSelected = total;
				delete directory.include;
				delete directory.children;
			} else if (directorySelectedByAncestor) {
				directory.exclude = ids;
				directory.totalSelected = total - ids.length;
			} else if (directory.exclude) {
				const excluded = selectionDiff(directory.exclude, ids);
				if (excluded.length === total) {
					directory.include = [];
					delete directory.exclude;
				} else {
					directory.exclude = excluded;
					directory.children = directory.children?.filter(child => directory.exclude?.includes(child.id));
				}
				directory.totalSelected = total - excluded.length;
			} else {
				directory.include = selectionDiff(directory.include || [], ids);
				directory.totalSelected = directory.include.length;
				directory.children = directory.children?.filter(child => !directory.include?.includes(child.id));
			}
			directory.total = total;

			if (onSelectDirectories && selectedDirectories) {
				onSelectDirectories(selectedDirectories);
			}
		},
		[directoryId, flattenTree, total, createAncestors],
	);

	useEffect(() => {
		if (directoryId && pageIds) {
			directoryIds.current[directoryId] = [...(directoryIds.current[directoryId] || []), ...pageIds].filter(
				onlyUnique,
			);
		}

		closeSelectPanel();
	}, [directoryId, pageIds]);

	const selectedPageIds = useMemo(() => {
		const directory = flattenTree && directoryId ? flattenTree[directoryId] : null;
		return getSelectedPageIds(directoryId!, directory);
	}, [pageIds, selectedIds, flattenTree, directoryId]);

	const partialSelectedPageIds = useMemo(() => {
		const directory = flattenTree && directoryId ? flattenTree[directoryId] : null;
		if (directory?.children) {
			return getPartialSelection(directory.children);
		}
		return [];
	}, [flattenTree, directoryId]);

	const [isOpenSelectPanel, {setTrue: openSelectPanel, setFalse: closeSelectPanel}] = useBoolean(false);

	const handleSelectAll = useCallback(() => {
		modifyDirectoryTree([], true);

		closeSelectPanel();
	}, [modifyDirectoryTree]);

	const SelectAllPanel =
		isOpenSelectPanel && fullSelectable ? (
			<section className={cls(css.selectAllPanel, css.open)}>
				<Button secondary onClick={handleSelectAll} className={css.selectAllButton}>
					Выделить контакты и группы на всех страницах
				</Button>
				<Button action iconOnly onClick={closeSelectPanel} className={css.closeSelectAllPanel}>
					<CrossIcon />
				</Button>
			</section>
		) : null;

	const handleSelectAllPage = useCallback(() => {
		if (!selectable || !pageIds) return;

		if (flattenTree && directoryId) {
			const directory = flattenTree[directoryId];

			closeSelectPanel();

			modifyDirectoryTree(pageIds);
			const selectedIds = getSelectedPageIds(directoryId, directory);

			// NOTE: Директория мутировала. Открываем, если там есть выбранные элементы после мутации
			if (selectedIds.length === pageIds.length) openSelectPanel();
		} else if (selectedIds && onSelect) {
			const isAllPageSelected = selectedPageIds.length === pageIds.length;
			onSelect(
				isAllPageSelected ? arrayDiff(selectedIds, pageIds) : [...selectedIds, ...pageIds].filter(onlyUnique),
			);
		}
	}, [
		flattenTree,
		pageIds,
		onSelect,
		selectable,
		selectedIds,
		selectedPageIds,
		directoryId,
		fullSelectable,
		modifyDirectoryTree,
	]);

	const handleSelectOne = useCallback(
		(idsToSelect: string[]) => {
			if (!selectable) return;

			if (directoryId) {
				closeSelectPanel();

				modifyDirectoryTree(idsToSelect);
			} else if (selectedIds && onSelect) {
				onSelect(selectionDiff(selectedIds, idsToSelect));
			}
		},
		[selectable, onSelect, selectedIds, directoryId, modifyDirectoryTree],
	);

	return {
		pathIds,
		flattenTree,
		selectedPageIds,
		partialSelectedPageIds,
		handleSelectAllPage,
		handleSelect: handleSelectOne,
		SelectAllPanel,
	};
}

export default useDataGridSelection;
