import React from 'react';
import css from './RightPanel.module.css';
import Modal from '../Modal';
import {CrossIcon} from '../SvgIcon';
import Button from '../pirsInputs/Button/Button';
import cls from '../../utils/cls';
import FocusLock from '../FocusLock/FocusLock';

type Props = {
	open: boolean;
	onClose: () => void;
	forwardRef?: React.RefObject<HTMLDivElement>;
	header?: string;
	className?: string;
	topStickyElements?: React.ReactNode;
	returnFocusRef?: React.RefObject<HTMLElement>;
};

const RightPanel: React.FC<Props> = ({
	open,
	onClose,
	children,
	header,
	forwardRef,
	className,
	topStickyElements,
	returnFocusRef,
}) => {
	if (!open) return null;
	return (
		<Modal>
			<FocusLock focusOnOpen onEscClick={onClose} returnFocusRef={returnFocusRef}>
				<div className={css.wrapper}>
					<div className={cls(css.backdrop)} onClick={onClose} />

					<div className={cls(css.container, className)} ref={forwardRef}>
						<div className={css.sticky}>
							<div className={css.header}>
								<div className={css.title}>{header}</div>
								<Button iconOnly action onClick={onClose}>
									<CrossIcon />
								</Button>
							</div>
							{topStickyElements && topStickyElements}
						</div>
						{children}
					</div>
				</div>
			</FocusLock>
		</Modal>
	);
};

export default RightPanel;
