import React, {useCallback, useEffect, useState} from 'react';
import ModalPopup from '../ModalPopup/ModalPopup';
import {getError, NormalizedError} from '../../../utils/error';
import {ApolloError} from '@apollo/client';
import Button from '../../pirsInputs/Button/Button';
import css from './CustomErrorPopup.module.css';

type Props = {
	error?: ApolloError;
	reFetch?: () => void;
	maxWidth?: number;
};
const CustomErrorPopup: React.FC<Props> = ({error: propsError, reFetch, maxWidth}) => {
	const [error, setError] = useState<NormalizedError | null>(getError(propsError));
	const [suppressedErrors, setSuppressedErrors] = useState<string[]>([]);

	useEffect(() => {
		const newError = getError(propsError);
		if (!error || (newError && newError?.code !== error?.code)) {
			setError(newError);
		}
	}, [error, propsError]);

	const handleClose = useCallback(() => {
		if (!suppressedErrors.includes(error?.code || ''))
			setSuppressedErrors([...suppressedErrors, error?.code || '']);
	}, [error, suppressedErrors]);

	const handleReFetch = useCallback(() => {
		handleClose();
		reFetch && reFetch();
	}, [handleClose, reFetch]);

	return (
		<ModalPopup
			maxWidth={maxWidth}
			open={!!error && !suppressedErrors.includes(error.code)}
			onClose={handleClose}
			header={'Произошла ошибка'}
		>
			<div className={css.message}>{error?.message}</div>

			<div className={css.code}>{error?.code}</div>

			<div className={css.buttons}>
				{reFetch && <Button onClick={handleReFetch}>Попробовать снова</Button>}
				<Button onClick={handleClose} secondary={!!reFetch}>
					Ок
				</Button>
			</div>
		</ModalPopup>
	);
};

export default CustomErrorPopup;
