exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".SettingsFilesPage-module__container__1anTK {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tpadding: calc(var(--design-unit) * 4px);\r\n\tgap: calc(var(--design-unit) * 4px);\r\n}\r\n\r\n.SettingsFilesPage-module__messageWrapper__BP4k8 {\r\n\tdisplay: flex;\r\n\tflex-wrap: nowrap;\r\n\twidth: 100%;\r\n}\r\n\r\n.SettingsFilesPage-module__messageWrapper__BP4k8 .SettingsFilesPage-module__messageText__INpfZ {\r\n\t\twhite-space: nowrap;\r\n\t\tmax-width: 700px;\r\n\t\toverflow: hidden;\r\n\t\ttext-overflow: ellipsis;\r\n\t}\r\n\r\n.SettingsFilesPage-module__messageWrapper__BP4k8 .SettingsFilesPage-module__messageText__INpfZ .SettingsFilesPage-module__errorMessage__2dRqE {\r\n\t\t\tcolor: var(--error-foreground);\r\n\t\t}\r\n\r\n.SettingsFilesPage-module__close__22bjF {\r\n\tcursor: pointer;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\SettingsFilesPage\\SettingsFilesPage.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,uCAAuC;CACvC,mCAAmC;AACpC;;AAEA;CACC,aAAa;CACb,iBAAiB;CACjB,WAAW;AAYZ;;AAVC;EACC,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;CAKxB;;AAHC;GACC,8BAA8B;EAC/B;;AAIF;CACC,eAAe;AAChB","file":"SettingsFilesPage.module.css","sourcesContent":[".container {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tpadding: calc(var(--design-unit) * 4px);\r\n\tgap: calc(var(--design-unit) * 4px);\r\n}\r\n\r\n.messageWrapper {\r\n\tdisplay: flex;\r\n\tflex-wrap: nowrap;\r\n\twidth: 100%;\r\n\r\n\t.messageText {\r\n\t\twhite-space: nowrap;\r\n\t\tmax-width: 700px;\r\n\t\toverflow: hidden;\r\n\t\ttext-overflow: ellipsis;\r\n\r\n\t\t.errorMessage {\r\n\t\t\tcolor: var(--error-foreground);\r\n\t\t}\r\n\t}\r\n}\r\n\r\n.close {\r\n\tcursor: pointer;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"container": "SettingsFilesPage-module__container__1anTK",
	"messageWrapper": "SettingsFilesPage-module__messageWrapper__BP4k8",
	"messageText": "SettingsFilesPage-module__messageText__INpfZ",
	"errorMessage": "SettingsFilesPage-module__errorMessage__2dRqE",
	"close": "SettingsFilesPage-module__close__22bjF"
};