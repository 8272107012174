exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".PopoverContent__list__hBxd0 {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\n\r\n.PopoverContent__sync__3MJDK {\r\n\tcursor: pointer;\r\n\tfill: var(--accent-fill-rest);\r\n\tvertical-align: middle;\r\n}\r\n\r\n.PopoverContent__sync__3MJDK.PopoverContent__syncLoading__Ra6zG {\r\n\t\tanimation: PopoverContent__rotating__3dPHP 2s linear infinite;\r\n\t}\r\n\r\n@keyframes PopoverContent__rotating__3dPHP {\r\n\tfrom {\r\n\t\ttransform: rotate(360deg);\r\n\t}\r\n\r\n\tto {\r\n\t\ttransform: rotate(0deg);\r\n\t}\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\settings\\views\\components\\Graph\\PopoverContent.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,SAAS;CACT,UAAU;AACX;;AAEA;CACC,eAAe;CACf,6BAA6B;CAC7B,sBAAsB;AAKvB;;AAHC;EACC,6DAAsC;CACvC;;AAGD;CACC;EACC,yBAAyB;CAC1B;;CAEA;EACC,uBAAuB;CACxB;AACD","file":"PopoverContent.css","sourcesContent":[".list {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\n\r\n.sync {\r\n\tcursor: pointer;\r\n\tfill: var(--accent-fill-rest);\r\n\tvertical-align: middle;\r\n\r\n\t&.syncLoading {\r\n\t\tanimation: rotating 2s linear infinite;\r\n\t}\r\n}\r\n\r\n@keyframes rotating {\r\n\tfrom {\r\n\t\ttransform: rotate(360deg);\r\n\t}\r\n\r\n\tto {\r\n\t\ttransform: rotate(0deg);\r\n\t}\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"list": "PopoverContent__list__hBxd0",
	"sync": "PopoverContent__sync__3MJDK",
	"syncLoading": "PopoverContent__syncLoading__Ra6zG",
	"rotating": "PopoverContent__rotating__3dPHP"
};