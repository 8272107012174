import {wordByCount} from '../../../utils/word-by-count';
import {Entity} from './useMetadata';

const hasDirtyFields = (values: {[key: string]: number | string}): boolean => {
	return Object.values(values).some(
		value =>
			!(value === '' || value === null || (typeof value === 'number' && isNaN(value)) || value === undefined),
	);
};

const isObject = (value: any): boolean => {
	return typeof value === 'object' && value !== null;
};

const isDirtyValue = (value: any): boolean => {
	return !(value === '' || value === null || (typeof value === 'number' && isNaN(value)) || value === undefined);
};

export const isDirtyValues = (values: any): boolean => {
	if (isObject(values)) {
		return Object.values(values).some(value => {
			if (isObject(value)) {
				return isDirtyValues(value);
			} else {
				return isDirtyValue(value);
			}
		});
	} else {
		return isDirtyValue(values);
	}
};

const hasNestedFields = (entities: Entity[]) => {
	return entities.some(entity => entity.childAttributes && entity.childAttributes.length);
};

export const clearValues = (entities: Entity[], values: any, selectedChoiceGroup: PlainObjectOf<string>) => {
	if (!values) return undefined;

	const newValues = {};
	entities.forEach(entity => {
		if (entity.choiceGroup) {
			if (selectedChoiceGroup[entity.choiceGroup] && selectedChoiceGroup[entity.choiceGroup] === entity.keyword) {
				if (entity.__typename === 'ObjectCollectionDescriptor') {
					const newCollection = values[entity.keyword]
						.map(value => {
							return clearValues(entity.childAttributes, value, selectedChoiceGroup);
						})
						.filter(value => value && Object.keys(value).length);
					if (newCollection.length) newValues[entity.keyword] = newCollection;
				} else {
					const value = clearValues(entity.childAttributes, values[entity.keyword], selectedChoiceGroup);
					if (value) newValues[entity.keyword] = value;
				}
			}
		} else if (entity.__typename === 'ObjectCollectionDescriptor' && entity.childAttributes) {
			const newCollection = values[entity.keyword]
				.map(value => {
					return clearValues(entity.childAttributes, value, selectedChoiceGroup);
				})
				.filter(value => value && Object.keys(value).length);
			if (newCollection.length) newValues[entity.keyword] = newCollection;
			else if (entity.required) newValues[entity.keyword] = [];
		} else if (entity.__typename === 'ScalarCollectionDescriptor') {
			const collection = (values[entity.keyword] as string[]).filter(Boolean);
			if (collection.length) {
				newValues[entity.keyword] = collection;
			} else if (entity.required) {
				newValues[entity.keyword] = [];
			}
		} else if (entity.childAttributes && hasNestedFields(entity.childAttributes)) {
			const childValue = clearValues(entity.childAttributes, values[entity.keyword], selectedChoiceGroup);
			if (childValue) newValues[entity.keyword] = childValue;
		} else if (values[entity.keyword] && entity.childAttributes) {
			if (hasDirtyFields(values[entity.keyword]))
				newValues[entity.keyword] = clearValues(
					entity.childAttributes,
					values[entity.keyword],
					selectedChoiceGroup,
				);
		} else {
			const value = values[entity.keyword];
			if (
				entity?.scalarDataType &&
				['integer', 'nonNegativeInteger', 'positiveInteger', 'decimal'].includes(
					entity.scalarDataType.valueType,
				)
			) {
				if (value !== null && value !== undefined) newValues[entity.keyword] = value;
			} else if (entity?.scalarDataType && ['boolean'].includes(entity.scalarDataType.valueType)) {
				if (value !== null) newValues[entity.keyword] = value;
			} else if (value) {
				newValues[entity.keyword] = value;
			}
		}
	});
	return Object.keys(newValues).length ? newValues : null;
};

const RequiredFieldMessage = 'Поле обязательно для заполнения';

const getErrorsByField = (entity: Entity, value: any) => {
	const errors = {};
	if (!entity.scalarDataType) return;
	const {valueType, maxLength, minLength} = entity.scalarDataType;

	if (entity.required && !entity.childAttributes) {
		if (['integer', 'nonNegativeInteger', 'positiveInteger', 'decimal'].includes(valueType)) {
			if (value === null || value === undefined || isNaN(value)) {
				errors[entity.keyword] = RequiredFieldMessage;
			}
		} else if (valueType === 'boolean') {
			if (value == null) errors[entity.keyword] = RequiredFieldMessage;
		} else if (!value || !value.toString().trim()) {
			errors[entity.keyword] = RequiredFieldMessage;
		}
	}

	if (value && (valueType === 'nonNegativeInteger' || valueType === 'positiveInteger') && value < 0) {
		errors[entity.keyword] = 'Требуются только положительные значения';
	}

	if (value && minLength && value.length < minLength) {
		errors[entity.keyword] = `Необходимо ввести хотя бы ${wordByCount(
			minLength,
			['символ', 'символа', 'символов'],
			true,
		)}`;
	}

	if (value && maxLength && value.length > maxLength) {
		errors[entity.keyword] = `Максимальная длина не должна превышать ${wordByCount(
			maxLength,
			['символ', 'символа', 'символов'],
			true,
		)}`;
	}

	return errors;
};

export const getErrors = (entities: Entity[], values: any, selectedChoiceGroup: PlainObjectOf<string>) => {
	let errors = {};
	entities.forEach(entity => {
		if (entity.required || (values && isDirtyValues(values[entity.keyword]))) {
			if (
				entity.choiceGroup &&
				!(selectedChoiceGroup[entity.choiceGroup] && selectedChoiceGroup[entity.choiceGroup] === entity.keyword)
			)
				return;
			if (entity.__typename === 'ObjectCollectionDescriptor' && entity.childAttributes) {
				const currentErrors = (values[entity.keyword] as any[]).map(value => {
					return getErrors(entity.childAttributes, value, selectedChoiceGroup);
				});
				if (currentErrors.filter(error => error && Object.keys(error).length).length) {
					errors[entity.keyword] = currentErrors;
				}
			} else if (entity.__typename === 'ScalarCollectionDescriptor' && values[entity.keyword]) {
				const currentErrors = (values[entity.keyword] as any[]).map(value => {
					const error = getErrorsByField(entity, value);
					if (error) return error[entity.keyword];
				});
				if (currentErrors.filter(error => error && Object.keys(error).length).length) {
					errors[entity.keyword] = currentErrors;
				}
			} else if (entity.childAttributes) {
				const currentErrors = getErrors(
					entity.childAttributes,
					values ? values[entity.keyword] : null,
					selectedChoiceGroup,
				);
				if (currentErrors && Object.keys(currentErrors).length) {
					errors[entity.keyword] = currentErrors;
				} else if (!isDirtyValues(values[entity.keyword])) {
					errors[entity.keyword] = RequiredFieldMessage;
				}
			} else {
				const currentErrors = getErrorsByField(entity, values ? values[entity.keyword] : null);
				if (!currentErrors) return;
				else {
					errors = {...errors, ...currentErrors};
				}
			}
		}
	});
	return Object.keys(errors).length ? errors : undefined;
};

export const createEmptyValue = (entities: Entity[]) => {
	const value = {};
	Object.values(entities).forEach(entity => {
		if (entity.childAttributes && entity.__typename === 'ObjectCollectionDescriptor') {
			value[entity.keyword] = [createEmptyValue(entity.childAttributes)];
		} else if (entity.__typename === 'ScalarCollectionDescriptor') {
			value[entity.keyword] = [''];
		} else if (entity.scalarDataType?.possibleValueList) {
			value[entity.keyword] = '';
		} else if (entity.childAttributes) {
			value[entity.keyword] = createEmptyValue(entity.childAttributes);
		} else value[entity.keyword] = null;
	});
	return value;
};

export const castToType = (value: any, entity: Entity) => {
	let result: any = null;
	if (entity.scalarDataType) {
		const {valueType, possibleValueList} = entity.scalarDataType;
		if (['integer', 'nonNegativeInteger', 'positiveInteger', 'decimal'].includes(valueType)) {
			result = parseInt(value);
		} else if (valueType === 'boolean') {
			result = value ? 'Да' : 'Нет';
		} else if (possibleValueList) {
			result = possibleValueList.find(item => item.value === value)?.label || value;
		} else {
			result = value;
		}
	}
	return result;
};

export const initSelectedChoiceGroup = (entities: Entity[], values: any) => {
	let selectedChoiceGroup: PlainObjectOf<string> = {};
	let choiceGroupName: string | undefined = undefined;
	let firstChoiceGroupKeyword: string | undefined = undefined;
	entities.map((entity, index) => {
		if (entity.choiceGroup && (!choiceGroupName || choiceGroupName !== entity.choiceGroup)) {
			choiceGroupName = entity.choiceGroup;
			firstChoiceGroupKeyword = entity.keyword;
		}

		if (entity.choiceGroup && isDirtyValues(values[entity.keyword])) {
			selectedChoiceGroup[entity.choiceGroup] = entity.keyword;
		}
		if (entity.childAttributes) {
			if (values) {
				selectedChoiceGroup = {
					...selectedChoiceGroup,
					...initSelectedChoiceGroup(entity.childAttributes, values[entity.keyword]),
				};
			}
		}
		if (
			entities.length === index + 1 &&
			choiceGroupName &&
			firstChoiceGroupKeyword &&
			!selectedChoiceGroup[choiceGroupName]
		) {
			selectedChoiceGroup[choiceGroupName] = firstChoiceGroupKeyword;
		}
	});

	return selectedChoiceGroup;
};
