import React, {useEffect, useRef, useState} from 'react';
import Input from './Input/Input';
import {CommonInputInterface} from './CommonInputInterface';
import usePrevious from '../hooks/usePrevious';
import {Keys} from '../../utils/key-enum';

interface ExternalProps extends CommonInputInterface<string> {
	error?: string;
	className?: string;
	mask: Array<string | RegExp>;
	maskPlaceholder: string;
	defaultValue?: string;
	blurValue?: (realValue: string) => string;
	setRef?: React.RefObject<HTMLInputElement>;
}

export type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof ExternalProps> & ExternalProps;

type PropsToGetValue = {
	maskPlaceholder: string;
	blurValue?: (realValue: string) => string;
};

function getInputValue(realValue: string, {maskPlaceholder, blurValue}: PropsToGetValue, hasFocus: boolean) {
	const userInput = hasFocus || !blurValue ? realValue : blurValue(realValue);
	const tail = userInput === realValue ? maskPlaceholder.substring(realValue.length) : '';
	return [userInput, tail];
}

const MaskedInput: React.FC<Props> = props => {
	const {
		value,
		error,
		defaultValue,
		mask,
		maskPlaceholder,
		placeholder,
		className,
		blurValue,
		onChange,
		onFocus,
		onBlur,
		onKeyDown,
		setRef,
		...inputProps
	} = props;
	const [realValue, setRealValue] = useState<string>(value || defaultValue || '');
	const [hasFocus, setFocus] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	// позиция первого символа не маски
	const firstSymbolIndex = mask.findIndex(el => typeof el !== 'string');
	const [selectionPosition, setSelectionPosition] = useState<number>(firstSymbolIndex);
	const [handleUseEffect, setHandleUseEffect] = useState<boolean>(false);
	const [keyCode, setKeyCode] = useState<number>(0);
	const inputRef = useRef<HTMLInputElement | null>(null);
	if (setRef) (setRef as any).current = inputRef.current;

	const setSelectionPos = (selectionPos: any) => {
		if (inputRef && inputRef.current) {
			inputRef.current.setSelectionRange(selectionPos, selectionPos);
		}
	};

	const prevValue = usePrevious(value);

	useEffect(() => {
		if ((prevValue && prevValue !== value) || value || defaultValue) {
			const {value: maskedValue} = applyMask(value || defaultValue || '', 0, 0);
			const [userInput, tail] = getInputValue(maskedValue, {maskPlaceholder, blurValue}, hasFocus);
			const inputValue = userInput + tail;
			setRealValue(maskedValue);
			setInputValue(inputValue);
		}
	}, [value]);

	useEffect(() => {
		if (hasFocus) setSelectionPos(selectionPosition.toString());
	}, [handleUseEffect, hasFocus, selectionPosition]);

	const handleChange = (value: string | null) => {
		// @ts-ignore
		const {selectionStart} = inputRef.current;
		const {value: maskedValue, selectionPos} = applyMask(value || '', selectionStart, keyCode);
		const [userInput, tail] = getInputValue(maskedValue, {maskPlaceholder, blurValue}, hasFocus);
		const inputValue = userInput + tail;
		const selectionNextPos =
			selectionPos !== null && selectionPos > firstSymbolIndex
				? Math.min(userInput.length, selectionPos)
				: firstSymbolIndex;
		setRealValue(maskedValue);
		setInputValue(inputValue);
		setSelectionPosition(selectionNextPos);
		if (onChange) onChange(maskedValue);
		setTimeout(() => setHandleUseEffect(!handleUseEffect));
	};

	const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		const hasFocus = e.type === 'focus';
		const [userInput, tail] = getInputValue(realValue, {maskPlaceholder, blurValue}, hasFocus);
		const inputValue = userInput + tail;
		const input = e.target;
		setInputValue(inputValue);
		setFocus(hasFocus);
		if (hasFocus) {
			setTimeout(() => {
				const {selectionStart} = input;
				if (selectionStart !== null && selectionStart > userInput.length) {
					const selectionPos = Math.max(userInput.length, firstSymbolIndex);
					setSelectionPosition(selectionPos);
					setHandleUseEffect(!handleUseEffect);
				}
			}, 15);
		}

		if (hasFocus && onFocus) onFocus(e);
		if (!hasFocus && onBlur) onBlur(e);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		setKeyCode(e.keyCode);
		if (onKeyDown) onKeyDown(e);
	};

	const applyMask = (value: string, selectionPos: number | null, keyCode: number) => {
		const letters: string[] = [];
		let valueIndex = 0;
		for (let i = 0; i < mask.length; i++) {
			const maskItem = mask[i];
			let letter = value[valueIndex];
			/* если в маске константа - добавим символ из маски */
			if (typeof maskItem === 'string') {
				if (keyCode === Keys.BACKSPACE && selectionPos && selectionPos <= i) {
					continue;
				}
				letters.push(maskItem);
				if (letter === maskItem) {
					valueIndex++;
				} else if (selectionPos !== null && selectionPos >= i) {
					selectionPos++;
				}
				continue;
			}
			/* пропускаем все символы которые не подошли по маске */
			while (letter && !maskItem.test(letter)) {
				valueIndex++;
				letter = value[valueIndex];
			}

			/* если символ подходит - записываем */
			if (maskItem.test(letter)) {
				letters.push(letter);
				valueIndex++;
			} else {
				break;
			}
		}
		return {value: letters.join(''), selectionPos};
	};
	return (
		<Input
			{...inputProps}
			className={className}
			value={inputValue !== placeholder ? inputValue : ''}
			error={error}
			setRef={inputRef}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleFocus}
			onKeyDown={handleKeyDown}
		/>
	);
};

export default MaskedInput;
