export type FlatTree<T> = {[key: string]: T};

function flatTree<T extends {id: string; children?: T[]}>(tree: T): FlatTree<T> {
	let children: FlatTree<T> = {};
	if (tree.children) {
		tree.children.forEach(node => {
			const child = flatTree(node);
			children = {...children, ...child};
		});
	}

	return {[tree.id]: tree, ...children};
}

export default flatTree;
