import React, {useCallback, useMemo, useState} from 'react';
import css from './IntegrationNotificationsRecipientsPage.module.css';
import MainLayout from '../../components/layouts/MainLayout';
import Selector from '../../components/pirsInputs/Selector/Selector';
import RecipientUserTable from './RecipientUserTable';
import RecipientOrgTable from './RecipientOrgTable';
import {RouterConfigurationRoutes} from 'pages/RouterConfigurationPage/RouterConfigurationPage';
import {useRouter} from 'react-named-hooks-router';
import {SortOrder} from '../../queries-generated/types';

enum Modules {
	USERS = 'Пользователи',
	ORGS = 'Организации',
}

const items = Object.values(Modules);

const IntegrationNotificationsRecipientsPage: React.FC = () => {
	const [moduleItem, setModuleItem] = useState<Modules>(Modules.USERS);
	const {pushRoute, routeName} = useRouter<{
		page?: string;
		itemsPerPage?: string;
		sort?: SortOrder;
		sortBy?: string;
	}>();

	const handleResetRouterParams = useCallback(async () => {
		return new Promise<void>(resolve => {
			pushRoute(routeName, {page: undefined, itemsPerPage: undefined, sort: undefined, sortBy: undefined});
			resolve();
		});
	}, [pushRoute, routeName]);

	const handleChangeModule = useCallback(
		item => {
			handleResetRouterParams().then(() => {
				setModuleItem(item);
			});
		},
		[moduleItem, setModuleItem, handleResetRouterParams],
	);

	const currentTable = useMemo(() => {
		if (moduleItem === Modules.USERS) return <RecipientUserTable />;
		if (moduleItem === Modules.ORGS) return <RecipientOrgTable />;
	}, [moduleItem]);

	return (
		<MainLayout routes={RouterConfigurationRoutes}>
			<div className={css.selectorBlock}>
				<Selector
					className={css.selector}
					items={items}
					onChange={handleChangeModule}
					value={moduleItem}
					itemToString={item => item || ''}
				/>
				<h3 className={css.helpText}>Выберите для просмотра информации по получателям оповещений</h3>
			</div>
			{currentTable}
		</MainLayout>
	);
};

export default IntegrationNotificationsRecipientsPage;
