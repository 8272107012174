import {ActionsUnion, createAction} from '../../redux/helper';
import {User} from '../../queries-generated/types';

export type State = {
	user: User;
	openSessionPopup: boolean;
};

export const initialState: State = {
	user: {login: '', shortUserName: '', permissions: [], roles: []},
	openSessionPopup: false,
};

export enum ActionType {
	SET_USER = 'SET_USER',
	SET_SESSION_POPUP = 'SET_SESSION_POPUP',
}

export const actions = {
	setUser: (user: User) => createAction(ActionType.SET_USER, user),
	setSessionPopup: (open: boolean) => createAction(ActionType.SET_SESSION_POPUP, open),
};

export function reducer(state: State, action: ActionsUnion<typeof actions>): State {
	switch (action.type) {
		case ActionType.SET_USER:
			if (action.payload) {
				return {
					...state,
					user: {...action.payload},
				};
			} else {
				return state;
			}
		case ActionType.SET_SESSION_POPUP:
			return {
				...state,
				openSessionPopup: action.payload,
			};

		default:
			return state;
	}
}
