import {useCallback, useMemo, useState} from 'react';
import {removeArrayElementByIndex} from '../../../utils/array-utils';
import {FileValue, useDataSourceUploadChunkMutation} from '../../../queries-generated/types';
import {readPartAsBase64Encode} from '../../../utils/files';
import {UploadedFile} from './DashboardInputFiles';

const chunkLength = Number(process.env.REACT_APP_CHUNK_LENGTH);

function useUploadFiles(initValue: FileValue[]) {
	const [value, setValue] = useState(initValue);

	const [files, setFiles] = useState<PlainObjectOf<UploadedFile>>({});

	const handleUpdateProgress = useCallback((id: string, loadedChunk: number, currentProgress: number) => {
		setFiles(files => {
			const chunks = files[id].chunks || 1;
			const progressChunkBefore = (loadedChunk * 100) / chunks;
			const progressChunkAfter = ((loadedChunk + 1) * 100) / chunks;
			const progress = Math.round(
				progressChunkBefore + (currentProgress * (progressChunkAfter - progressChunkBefore)) / 100,
			);

			return {...files, [id]: {...files[id], progress}};
		});
	}, []);

	const handleDelete = useCallback((id: string) => {
		setValue(value => {
			if (!value) return value;
			const index = value.findIndex(file => file.id === id);
			if (index > -1) {
				return removeArrayElementByIndex(value, index);
			}
			return value;
		});
	}, []);

	const [uploadChunk] = useDataSourceUploadChunkMutation();

	const handleUploadFile = useCallback(async (file: File, id: string) => {
		const chunks = Math.ceil(file.size / Number(chunkLength));
		let serverId: string | undefined = undefined;

		for (let chunk = 0; chunk < chunks; chunk++) {
			const lastChunk = chunk === chunks - 1;
			const start = chunk * chunkLength;
			const part = file.slice(start, start + chunkLength);

			if (chunk > 0 && !serverId) return;

			try {
				const content = await readPartAsBase64Encode(part);
				const {data} = await uploadChunk({
					variables: {
						id: serverId,
						chunk,
						chunks,
						content,
						size: file.size,
						title: file.name,
					},
					context: {
						fetchOptions: {
							onProgress: progress =>
								handleUpdateProgress(id, chunk, (progress.loaded * 100) / progress.total),
						},
					},
				});
				if (data?.dataSourceUploadChunk.id) serverId = data.dataSourceUploadChunk.id;

				if (lastChunk) {
					handleUpdateProgress(id, chunk, 100);
					setFiles(files => ({
						...files,
						[id]: {...files[id], uploaded: true},
					}));
					setValue(value => [
						...(value || []),
						{
							id: serverId!,
							location: data?.dataSourceUploadChunk.location,
							title: file.name,
							size: file.size,
						},
					]);
				} else {
					handleUpdateProgress(id, chunk + 1, 0);
				}
			} catch (error) {
				///
			}
		}
	}, []);

	const uploadingFiles = useMemo(() => Object.values(files).filter(file => !file.uploaded), [files]);

	return {value, setValue, uploadingFiles, setFiles, handleDelete, handleUploadFile};
}

export default useUploadFiles;
