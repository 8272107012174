import React, {useCallback, useState} from 'react';
import {Form, FormikErrors, FormikProvider, useFormik} from 'formik';
import {TableColumn, useCreateModelMutation, useUpdateModelMutation} from '../../queries-generated/types';
import IncidentForm from './IncidentForm';
import ModalPopup from '../controlls/ModalPopup/ModalPopup';
import {UploadFileData} from '../commonRedux/UploadFileStore/UploadFileQueueReducer';
import useConfirm from '../hooks/useConfirm/useConfirm';

type Props = {
	columns: TableColumn[];
	incident?: any;
	reload: () => void;
	onClose: () => void;
};

export type Values = any;

const ModifyIncident: React.FC<Props> = ({columns, onClose, reload, incident}) => {
	const [createModel, {loading: createLoading}] = useCreateModelMutation();
	const [updateModel, {loading: updateLoading}] = useUpdateModelMutation();

	const [preparedFilesIds, setPreparedFilesIds] = useState<string[]>([]);
	const [beRemoveAttachedFileIds, setBeRemoveAttachedFileIds] = useState<string[]>([]);
	const [preparedFiles, setPreparedFiles] = useState<UploadFileData[]>([]);

	const [selectedCategoryValue, setSelectedCategoryValue] = useState<string | null>(null);

	const handleCloseAndReload = useCallback(
		(needReload?: boolean) => {
			if (preparedFilesIds.length > 0 || beRemoveAttachedFileIds.length > 0 || needReload) reload();
			onClose();
		},
		[onClose, reload, beRemoveAttachedFileIds.length, preparedFilesIds.length],
	);
	const handleUpdateModel = useCallback(
		(values: any) => {
			updateModel({
				variables: {
					model: 'incident',
					items: [{...values, id: values.id.toString()}],
				},
			}).then(() => handleCloseAndReload(true));
		},
		[handleCloseAndReload, updateModel],
	);

	const formikBag = useFormik<Values>({
		initialValues: incident || {
			severity: '',
			issue_source: '',
			operator: '',
			ssop_type: '',
			ssop_service: '',
			type: '',
			occured: '',
			detected: '',
			category: '',
			description: '',
			asbi_event_type: '',
		},
		onSubmit: values => {
			if (values.id) {
				const newValues = {};
				Object.keys(values).forEach(key => {
					if (values[key] !== formikBag.initialValues[key]) newValues[key] = values[key];
				});
				if (preparedFilesIds.length) newValues['fileIds'] = preparedFilesIds;
				if (beRemoveAttachedFileIds.length) newValues['removeFileIds'] = beRemoveAttachedFileIds;
				if (Object.keys(newValues).length) handleUpdateModel({...newValues, id: values.id});
				else handleCloseAndReload();
			} else {
				createModel({
					variables: {model: 'incident', items: [{...values, fileIds: preparedFilesIds}]},
				}).then(() => handleCloseAndReload(true));
			}
		},
		validate: values => {
			const errors: FormikErrors<any> = {};
			const requiredFields =
				selectedCategoryValue === 'Инцидент ССОП'
					? ['severity', 'issue_source', 'operator', 'ssop_type', 'ssop_service', 'description']
					: selectedCategoryValue === 'АСБИ инфраструктура'
					? ['type', 'asbi_event_type', 'occured', 'detected', 'description']
					: [];
			requiredFields.forEach(field => {
				if (!values[field]) errors[field] = 'Поле обязательно для заполнения';
			});
			return errors;
		},
	});

	const smartDirty = useCallback(() => {
		return (
			!!preparedFiles.length ||
			!!beRemoveAttachedFileIds.length ||
			Object.keys(formikBag.values).some(key => {
				if (!incident && key === 'category') return false;
				return formikBag.values[key]?.toString() !== formikBag.initialValues[key]?.toString();
			})
		);
	}, [formikBag.initialValues, formikBag.values, incident, beRemoveAttachedFileIds.length, preparedFiles.length]);

	const {showConfirm, ConfirmComponent} = useConfirm({
		header: 'В инцидент внесены изменения',
		message: 'Если закрыть форму, изменения будут потеряны',
		confirmButtonTitle: 'Всё равно закрыть',
		cancelButtonTitle: 'Редактировать далее',
		onConfirm: onClose,
	});

	const silentClose = useCallback(() => {
		if (!smartDirty()) onClose();
		else showConfirm();
	}, [onClose, smartDirty, showConfirm]);

	const onSubmit = useCallback(() => {
		formikBag.submitForm();
	}, [formikBag]);

	return (
		<ModalPopup maxWidth={400} open={true} onClose={silentClose}>
			<FormikProvider value={formikBag}>
				{ConfirmComponent}
				<Form>
					<IncidentForm
						allColumns={columns}
						onClose={silentClose}
						onSubmit={onSubmit}
						loading={updateLoading || createLoading}
						incident={incident}
						beRemoveAttachedFileIds={beRemoveAttachedFileIds}
						setBeRemoveAttachedFileIds={setBeRemoveAttachedFileIds}
						setPreparedFilesIds={setPreparedFilesIds}
						preparedFiles={preparedFiles}
						setPreparedFiles={setPreparedFiles}
						selectedCategoryValue={selectedCategoryValue}
						setSelectedCategoryValue={setSelectedCategoryValue}
					/>
				</Form>
			</FormikProvider>
		</ModalPopup>
	);
};

export default ModifyIncident;
