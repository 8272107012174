import React, {useCallback, useEffect, useState} from 'react';
import {Form, FormikErrors, FormikProvider, useFormik} from 'formik';
import {TableColumn} from '../../queries-generated/types';
import Button from '../pirsInputs/Button/Button';
import css from './FullTableForm.module.css';
import ProgressRing from '../controlls/Loader/ProgressRing';
import validateColumn from './validateColumn';
import {CrossIcon, SaveIcon} from '../SvgIcon';
import ErrorSnippet from '../ErrorSnippet/ErrorSnippet';
import {ApolloError} from '@apollo/client';
import {
	CustomFormProps,
	getDefaultValues,
	getFilteredColumns,
	patchValuesForCombobox,
	patchValuesForSubmit,
	promiseFileReader,
} from './FullTableFormHelpers';
import FullTableFormFields from './FullTableFormFields';

type Props = {
	submitFormLoading: boolean;
	onClose: () => void;
	allColumns: TableColumn[];
	onChange: (item: any, id?: string) => void;
	id?: string;
	getItemById?: (id: string) => Promise<any>;
	updateForm?: React.FC<CustomFormProps>;
	createForm?: React.FC<CustomFormProps>;
	validateFn?: (values: any) => FormikErrors<any>;
	submitFn?: (values: any) => any;
	initialValues?: PlainObjectOf<any>;
};

const FullTableForm: React.FC<Props> = ({
	allColumns,
	id,
	getItemById,
	onChange,
	onClose,
	createForm,
	updateForm,
	validateFn,
	submitFn,
	submitFormLoading,
	initialValues,
}) => {
	const editMode = !!id;
	const [loading, setLoading] = useState(editMode);
	const [getItemError, setGetItemError] = useState<ApolloError>();

	const filteredColumns = getFilteredColumns(allColumns, editMode);
	const defaultValues = getDefaultValues(filteredColumns);

	const formikBag = useFormik({
		initialValues: patchValuesForCombobox({...defaultValues, ...initialValues}, filteredColumns),
		onSubmit: (values: any) => {
			const newValues = patchValuesForSubmit(values, filteredColumns);
			if (newValues.files) {
				const file = promiseFileReader(newValues.files);
				file.then(result => {
					newValues.files.title = values.files.name;
					newValues.files.filename = values.files.name;
					newValues.files.file = result;
					onChange(newValues, id);
				});
			} else {
				onChange(submitFn ? submitFn(newValues) : newValues, id);
			}
		},
		validate: values => {
			if (validateFn) return validateFn(values);

			const errors: {[key: string]: any} = {};
			allColumns?.forEach(column => {
				if (
					column.required &&
					((!column.isArray && (values[column.id] === null || values[column.id] === '')) ||
						(column.isArray && values[column.id].length === 0))
				) {
					errors[column.id] = 'Поле обязательно для заполнения';
				}

				if (
					!column.isArray &&
					column.validation &&
					column.type &&
					!validateColumn(values[column.id], column.type, column.validation.regExp)
				) {
					errors[column.id] = column.validation.errorText;
				}
			});
			return errors;
		},
	});

	const getItemCallback = useCallback(() => {
		if (id && getItemById) {
			setGetItemError(undefined);
			setLoading(true);
			getItemById(id.toString())
				.then(item => {
					formikBag.resetForm({
						values: patchValuesForCombobox(
							{
								...defaultValues,
								...item,
							},
							filteredColumns,
						),
						touched: {},
						errors: {},
						isSubmitting: false,
						isValidating: false,
					});
				})
				.catch(error => {
					setGetItemError(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [id, getItemById, setGetItemError, setLoading, formikBag, defaultValues, filteredColumns]);

	useEffect(() => {
		getItemCallback();
	}, []);

	if (loading) return <ProgressRing />;
	if (getItemError)
		return <ErrorSnippet errorHeader={'Ошибка получения данных'} error={getItemError} refetch={getItemCallback} />;

	return (
		<FormikProvider value={formikBag}>
			<Form>
				{editMode && updateForm ? (
					React.createElement(updateForm, {columns: allColumns, onClose, editMode, submitFormLoading})
				) : !editMode && createForm ? (
					React.createElement(createForm, {columns: allColumns, onClose, editMode, submitFormLoading})
				) : (
					<>
						<ul className={css.fields}>
							<FullTableFormFields filteredColumns={filteredColumns} />
						</ul>
						<ul className={css.buttons}>
							<li>
								<Button type={'submit'} loading={submitFormLoading} disabled={submitFormLoading}>
									<SaveIcon />
									{editMode ? 'Редактировать' : 'Добавить'}
								</Button>
							</li>
							<li>
								<Button onClick={onClose} secondary>
									<CrossIcon />
									Отмена
								</Button>
							</li>
						</ul>
					</>
				)}
			</Form>
		</FormikProvider>
	);
};

export default FullTableForm;
