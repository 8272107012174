import React, {useEffect} from 'react';
import NotificationsMessageContainer from './NotificationsMessageContainer';
import {useNotificationsCountQuery} from '../../queries-generated/types';
import {getError} from '../../utils/error';
import PopoverButton from '../controlls/PopoverButton/PopoverButton';
import {NotificationIcon} from '../SvgIcon';
import Badge from '../Badge/Badge';

function playSound() {
	const sound = new Audio('./sounds/notificationSound.mp3');

	sound.oncanplaythrough = _ => {
		const playedPromise = sound.play();
		if (playedPromise) {
			playedPromise.catch(() => {
				// Заглушка для того чтобы не выскакивал exception воспроизведения при первой загрузке сайта
			});
		}
	};
}

const NotificationsComponent: React.FC = () => {
	const {
		data: notificationsCountData,
		previousData: previousNotificationsCountData,
		error: notificationsCountError,
	} = useNotificationsCountQuery({
		fetchPolicy: 'cache-and-network',
		pollInterval: 10000,
		// Чтобы с каждым polling запросом обновлялся prev результат
		notifyOnNetworkStatusChange: true,
		// Чтобы не было запроса к серверу после обновления кэша вручную
		nextFetchPolicy: 'cache-only',
	});

	// TODO В будущем будет добавлен сервис обработки клиентских ошибок
	if (notificationsCountError)
		console.error(
			`Ошибка загрузки количества непрочитанных уведомлений ${getError(notificationsCountError)?.message}`,
		);

	const notificationsCount = notificationsCountData?.notificationsCount;
	const previousNotificationsCount = previousNotificationsCountData?.notificationsCount;

	useEffect(() => {
		if (
			notificationsCount !== undefined &&
			previousNotificationsCount !== undefined &&
			notificationsCount > previousNotificationsCount
		) {
			playSound();
		}
	}, [notificationsCount, previousNotificationsCount]);

	return (
		<Badge color={'secondary'} max={99} badgeContent={notificationsCount}>
			<PopoverButton
				iconOnly
				action
				popover={
					<NotificationsMessageContainer
						notificationsCount={notificationsCount}
						previousNotificationsCount={previousNotificationsCount}
					/>
				}
			>
				<NotificationIcon />
			</PopoverButton>
		</Badge>
	);
};

export default NotificationsComponent;
