import React, {ChangeEvent, useCallback, useEffect, useRef} from 'react';
import css from './Input.module.css';
import {CommonInputInterface} from '../CommonInputInterface';
import {useValue, useFocus} from '../inputHooks';
import Field from '../Field/Field';
import * as SvgIcon from '../../SvgIcon';
import uid from '../../../utils/uid';
import cls from '../../../utils/cls';
import Button from '../Button/Button';
import fieldCss from '../Field/Field.module.css';

interface InputInterface extends CommonInputInterface<string> {
	setRef?: React.RefObject<HTMLInputElement>;
}

export type InputProps = InputInterface & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const Input: React.FC<InputProps> = props => {
	const {
		error,
		disabled,
		noButtons,
		buttons,
		className,
		bottomText,
		helperText,
		onChange: _,
		setRef,
		underlined,
		label,
		disableClear,
		...inputProps
	} = props;
	const {hasFocus, onBlur, onFocus} = useFocus(props);
	const {value, onChange} = useValue(props);
	const handleClearValue = useCallback(() => {
		onChange({} as ChangeEvent<HTMLInputElement>);
	}, [onChange]);

	const id = props.id || 'Input-' + uid();
	const hasError = props.error && props.error !== true;

	const defaultRef = useRef<HTMLInputElement>(null);
	const inputRef = setRef || defaultRef;

	useEffect(() => {
		if (inputRef.current && inputProps.autoFocus) inputRef.current.focus();
	}, [inputProps.autoFocus]);

	return (
		<Field
			commonProps={props}
			hasFocus={hasFocus}
			underlined={underlined}
			bordered={!underlined}
			inputId={id}
			buttons={
				<>
					{value && !disabled && !disableClear && (
						<Button action iconOnly onClick={handleClearValue} className={fieldCss.actionButton}>
							<SvgIcon.CrossIcon width={14} height={14} />
						</Button>
					)}
					{buttons}
				</>
			}
			topLabel={!!value || hasFocus}
			className={className}
		>
			<input
				id={id}
				disabled={disabled}
				className={cls(
					css.input,
					!underlined && css.bordered,
					hasError && css.hasError,
					hasFocus && css.hasFocus,
					disabled && css.disabled,
				)}
				{...(inputProps as any)}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				ref={inputRef}
				value={value || ''}
			/>
		</Field>
	);
};

export default Input;
