import React from 'react';
import Dropdown from '../Combobox/Dropdown';
import {CommonInputInterface} from '../CommonInputInterface';

export type Props = CommonInputInterface<boolean | null> & {
	name?: string;
};

function itemToString(value: null | boolean) {
	return value == null ? 'Не выбрано' : value ? 'Да' : 'Нет';
}

const NullableBoolean: React.FC<Props> = ({onChange, value, ...props}) => {
	return (
		<Dropdown<boolean | null>
			onChange={onChange}
			value={value}
			items={[null, true, false]}
			itemToString={itemToString}
			{...props}
		/>
	);
};

export default NullableBoolean;
