import React from 'react';
import {CommonInputInterface} from '../CommonInputInterface';

import Dropdown from '../Combobox/Dropdown';

export interface ComboboxInterface<T> extends CommonInputInterface<T[] | null> {
	/* список значений */
	getItems(input: string): Promise<T[] | null | undefined> | T[] | null | undefined;
	/* текущее значение */
	value: T[] | null;
	/* значение для инпута */
	itemToString?: (item: T | null) => string;
	/* значение для li */
	itemToHtml?: (item: T | null) => string | React.ReactNode;
	/* плейсхолдер */
	placeholder?: string;
	/* разметка в случае когда инпут не в фокусе */
	blurHtml?(value: T[] | null): React.ReactNode;
	/* то же что defaultValue, но по этому значению будет поиск, применяется только если нет value и defaultValue */
	defaultValueSearch?: string;
	/* коллбек на открытие/закрытие */
	onToggle?(open: boolean): void;
	/* класс для выпадайки */
	popdownClassName?: string;
	/* автовыделение */
	autoSelect?: boolean;
	/* класс для инпута */
	inputClassName?: string;
	/* ref для инпута */
	setRef?: React.RefObject<HTMLElement> | ((element: HTMLElement | null) => void);
	/* коллбэк для события клавиатуры*/
	onKeyDown?(e: React.KeyboardEvent): void;
	primaryKey?: (value: T) => string | number;
	minLengthQuery?: number;
}

export type Props<T> = ComboboxInterface<T> & Omit<React.HTMLAttributes<HTMLDivElement>, keyof ComboboxInterface<T>>;

/**
 * Выбор из списка
 * Список формируется на основе ввода
 */
function MultiCombobox<T>(props: Props<T>) {
	return (
		<Dropdown
			{...props}
			forwardRef={props.setRef}
			itemToPrimaryKey={props.primaryKey}
			popoverClassName={props.popdownClassName}
			itemsToHtml={props.blurHtml}
			queryable
			searchable
			multiple
		/>
	);
}

/**
 * Возвращает метод, который фильтрует по введённой подстроке
 * Используется для getItems
 */
export function getItemsByInput<T>(items: T[], itemToString: Props<T>['itemToString']) {
	return (inputValue: string) => {
		if (!itemToString || !inputValue) return items;
		const val = inputValue.toLowerCase();
		return (items || []).filter(item => {
			const itemString = itemToString(item).toLowerCase();
			return itemString.indexOf(val) === 0;
		});
	};
}

export default MultiCombobox;
