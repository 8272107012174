exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".RecipientGroupRuleForm-module__inputList__1A3_S {\r\n\tlist-style: none;\r\n\tpadding: 10px;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tgap: 10px;\r\n\tborder: 1px solid var(--neutral-stroke-rest);\r\n\tborder-radius: 5px;\r\n}\r\n\r\n.RecipientGroupRuleForm-module__addButton__1flDG {\r\n\tmargin-top: 10px;\r\n}\r\n\r\n.RecipientGroupRuleForm-module__deleteButton__2Xai6 {\r\n\tdisplay: flex;\r\n\tjustify-content: flex-end;\r\n\tmargin-top: 15px;\r\n}\r\n\r\n.RecipientGroupRuleForm-module__helpText__2yQ4m {\r\n\tmargin-top: 10px;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\RecipientGroupRulePage\\RecipientGroupRuleForm\\RecipientGroupRuleForm.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,aAAa;CACb,aAAa;CACb,sBAAsB;CACtB,SAAS;CACT,4CAA4C;CAC5C,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,yBAAyB;CACzB,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;AACjB","file":"RecipientGroupRuleForm.module.css","sourcesContent":[".inputList {\r\n\tlist-style: none;\r\n\tpadding: 10px;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tgap: 10px;\r\n\tborder: 1px solid var(--neutral-stroke-rest);\r\n\tborder-radius: 5px;\r\n}\r\n\r\n.addButton {\r\n\tmargin-top: 10px;\r\n}\r\n\r\n.deleteButton {\r\n\tdisplay: flex;\r\n\tjustify-content: flex-end;\r\n\tmargin-top: 15px;\r\n}\r\n\r\n.helpText {\r\n\tmargin-top: 10px;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"inputList": "RecipientGroupRuleForm-module__inputList__1A3_S",
	"addButton": "RecipientGroupRuleForm-module__addButton__1flDG",
	"deleteButton": "RecipientGroupRuleForm-module__deleteButton__2Xai6",
	"helpText": "RecipientGroupRuleForm-module__helpText__2yQ4m"
};