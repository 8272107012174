import React, {useCallback, useState} from 'react';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import css from './IntegrationNotificationsRecipientsPage.module.css';
import cls from '../../utils/cls';
import {JSONSafeParse} from '../../utils/object-utils';
import InputSearch from '../../components/pirsInputs/InputSearch/InputSearch';

function formatParamsType(type: string): string {
	switch (type) {
		case 'orgEmails':
			return 'Электронная почта';
		case 'orgPhones':
			return 'Номера телефонов';
		case 'pvvs':
			return 'Внешние системы';
		default:
			return type;
	}
}

const RecipientOrgTable: React.FC = () => {
	const [filter, setFilter] = useState<string>();

	const formatOrgColumns = useCallback((data: PlainObjectOf<any>, keyField: string): string => {
		if (data[keyField] && Array.isArray(data[keyField]) && data[keyField].length > 0) {
			return 'Установлены';
		} else {
			return 'Не установлены';
		}
	}, []);

	const formatParamsColumn = useCallback((data: PlainObjectOf<any>) => {
		const dataKeys = Object.keys(data);

		if (dataKeys.length > 0) {
			return (
				<ul className={css.list}>
					{Object.keys(data).map(key => {
						if (data[key] !== null && Array.isArray(data[key]) && data[key].length > 0) {
							return (
								<li key={key}>
									{formatParamsType(key)}:{' '}
									{data[key].map((value, index, array) => {
										if (typeof value.value === 'string') {
											return (
												<span key={index} className={cls(value.verified && css.verified)}>
													{value.value}
													{array.length - 1 === index ? '' : ', '}
												</span>
											);
										} else if (typeof value.value === 'object') {
											return Object.keys(value.value).map((key, index, array) => {
												return (
													<span key={key}>
														{key}: {value.value[key] ? value.value[key] : 'не установлен'}
														{array.length - 1 === index ? '' : ', '}
													</span>
												);
											});
										} else {
											return <span key={index}>{JSON.stringify(value.value)}</span>;
										}
									})}
								</li>
							);
						} else {
							return <li key={key}>{formatParamsType(key)}: не установлены</li>;
						}
					})}
				</ul>
			);
		} else {
			return '';
		}
	}, []);

	const formatCell = useCallback(
		(item: PlainObjectOf<any>, paramsKeyField: string) => {
			const parsedValue = JSONSafeParse(item.params);
			if (parsedValue && typeof parsedValue === 'object') {
				return formatOrgColumns(parsedValue, paramsKeyField);
			}
			return '';
		},
		[formatOrgColumns],
	);

	const {Table} = useFullTableWithRefetch('integrationRecipientOrg', {
		tableFields: ['inn', 'short_name', 'orgEmails', 'orgPhones', 'pvvs'],
		detailsFields: ['name', 'realm', 'params'],
		variables: {
			filter: filter ? `(short_name like ${filter}) or (inn like ${filter}) or (name like ${filter})` : undefined,
		},
		cellCallback: {
			orgEmails: (_value, item) => formatCell(item, 'orgEmails'),
			orgPhones: (_value, item) => formatCell(item, 'orgPhones'),
			pvvs: (_value, item) => formatCell(item, 'pvvs'),
		},
		detailCellCallback: {
			params: value => {
				const parsedValue = JSONSafeParse(value);
				if (parsedValue && typeof parsedValue === 'object') {
					return formatParamsColumn(parsedValue);
				}
				return value;
			},
		},
		additionalFilters: <InputSearch onChange={setFilter} value={filter} />,
	});
	return <Table />;
};

export default RecipientOrgTable;
