import React, {useCallback, useMemo, useState} from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import IntegrationUniversalAdapterTable from './IntegrationUniversalAdapterTable';
import {IntegrationRulesRoutes} from '../IntegrationRulesTablePage/IntegrationConfigurationTablePage';
import Selector from '../../components/pirsInputs/Selector/Selector';
import IntegrationRouterAdapterTable from './IntegrationRouterAdapterTable';
import IntegrationCorrelatorTable from './IntegrationCorrelatorTable';
import css from '../IntegrationNotificationsRecipientsPage/IntegrationNotificationsRecipientsPage.module.css';
import {useRouter} from 'react-named-hooks-router';
import {SortOrder} from '../../queries-generated/types';

enum Modules {
	INTEGRATION_ADAPTER = 'Интеграционный адаптер',
	EVENT_ROUTER = 'Маршрутизатор событий',
	EVENT_HANDLER = 'Обработчик событий',
}

const items = Object.values(Modules);

const IntegrationModulesPage: React.FC = () => {
	const [moduleItem, setModuleItem] = useState<Modules>(Modules.INTEGRATION_ADAPTER);
	const {pushRoute, routeName} = useRouter<{
		page?: string;
		itemsPerPage?: string;
		sort?: SortOrder;
		sortBy?: string;
	}>();

	const handleResetRouterParams = useCallback(async () => {
		return new Promise<void>(resolve => {
			pushRoute(routeName, {page: undefined, itemsPerPage: undefined, sort: undefined, sortBy: undefined});
			resolve();
		});
	}, [pushRoute, routeName]);

	const handleChangeModule = useCallback(
		item => {
			handleResetRouterParams().then(() => {
				setModuleItem(item);
			});
		},
		[moduleItem, setModuleItem, handleResetRouterParams],
	);

	const currentTable = useMemo(() => {
		if (moduleItem === Modules.INTEGRATION_ADAPTER) return <IntegrationUniversalAdapterTable />;
		if (moduleItem === Modules.EVENT_ROUTER) return <IntegrationRouterAdapterTable />;
		if (moduleItem === Modules.EVENT_HANDLER) return <IntegrationCorrelatorTable />;
	}, [moduleItem]);

	return (
		<MainLayout routes={IntegrationRulesRoutes}>
			<div className={css.selectorBlock}>
				<Selector
					className={css.selector}
					items={items}
					onChange={handleChangeModule}
					value={moduleItem}
					itemToString={item => item || ''}
				/>
			</div>
			{currentTable}
		</MainLayout>
	);
};

export default IntegrationModulesPage;
