import {Options} from 'vis-network/standalone';

export const graphOptions: Options = {
	nodes: {
		shape: 'dot',
		shadow: true,
		/*chosen: {
                label: true,
                //node: this.changeNodeWhenSelected,
            },*/
		scaling: {
			min: 10,
			max: 30,
			label: {
				min: 55,
				max: 100,
				drawThreshold: 6,
				maxVisible: 50,
			},
		},
		font: {
			size: 14,
			face: 'Tahoma',
		},
	},
	edges: {
		/*chosen: {
            label: true,
            //edge: this.changeEdgeWhenSelected,
        },*/
		width: 0.15,
		smooth: false,
	},
	physics: {
		enabled: false,
		forceAtlas2Based: {
			gravitationalConstant: -2500,
			centralGravity: 0.05,
			springLength: 150,
			springConstant: 0.18,
		},
		maxVelocity: 120,
		solver: 'forceAtlas2Based',
		timestep: 0.35,
		stabilization: {
			iterations: 50,
			updateInterval: 10,
		},
	},
	interaction: {
		navigationButtons: true,
		keyboard: false,
		tooltipDelay: 200,
		hideEdgesOnDrag: true,
		hover: true,
		multiselect: true,
		// hideNodesOnDrag: true
	},
	layout: {
		randomSeed: 1,
		improvedLayout: false,
	},
};
