import * as React from 'react';
import AuthLayout from '../../components/layouts/AuthLayount';
import Card, {CardItem} from '../../components/controlls/Card/Card';

const LogoutPage: React.FC = () => {
	return (
		<AuthLayout>
			<Card>
				<CardItem
					header={
						'Вы вышли из ЦМУ ССОП. Для входа, воспользуйтесь «Порталом Заявителей» Федеральной службы по надзору в сфере связи, информационных технологий и массовых коммуникаций'
					}
				/>
			</Card>
		</AuthLayout>
	);
};

export default LogoutPage;
