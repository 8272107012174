import React from 'react';
import EntityValue from './EntityValue';
import EntitiesListOnlyValues from './EntitiesListOnlyValues';
import {Entity} from '../form/useMetadata';
import {isDirtyValues} from '../form/helper';

type Props = {
	entity: Entity;
	deep?: number;
	values: any;
};

const EntitiesCollectionOnlyValues: React.FC<Props> = ({entity, deep, values}) => {
	if (!values) return null;

	return (
		<div>
			{values.map((_item, index) => {
				return (
					<React.Fragment key={index}>
						{isDirtyValues(values[index]) && (
							<>
								<b>Пункт списка: {index + 1}</b>
								{entity.__typename === 'ScalarCollectionDescriptor' ? (
									<EntityValue entity={entity} value={values[index]} />
								) : (
									<EntitiesListOnlyValues
										values={values[index]}
										deep={(deep || 0) + 1}
										entities={entity.childAttributes}
									/>
								)}
							</>
						)}
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default EntitiesCollectionOnlyValues;
