import * as React from 'react';
import {useCallback} from 'react';
import BoxChartSnippet from '../../components/charts/ChartSnippets/BoxChartSnippet';
import {AsTrafficResult, Direction, GroupBy, Metric, Order, useAsTrafficQuery} from '../../queries-generated/types';
import {formatDateIsoAsUtcTimeOrThrow} from '../../utils/date-utils';
import usePrevious from '../../components/hooks/usePrevious';

export enum LoadingComplete {
	complete,
	loading,
	error,
}

type Props = {
	title: string;
	groupBy: GroupBy[];
	filters: {
		directions: Direction[];
	};
	stopTime: Date;
	onLoading?: (loading: LoadingComplete) => void;
	fakeLoading?: boolean;
	wide?: boolean;
};

export const pollInterval = 1000 /*ms*/ * 60 /*sec*/ * 5 /*min*/;

const BoxChartAsTraffic: React.FC<Props> = ({title, groupBy, filters, stopTime, onLoading, fakeLoading, wide}) => {
	const ago60min = useCallback((date: Date) => {
		const ret = new Date(date);
		ret.setMinutes(ret.getMinutes() - 60);
		return ret;
	}, []);

	const {loading, error, data, variables} = useAsTrafficQuery({
		variables: {
			input: {
				order: Order.Top,
				limit: 10,
				groupBy: groupBy,
				filters: filters,
				metric: Metric.Bytes,
				dateFrom: formatDateIsoAsUtcTimeOrThrow(ago60min(stopTime)),
				dateTo: formatDateIsoAsUtcTimeOrThrow(stopTime),
			},
		},
		notifyOnNetworkStatusChange: true,
		pollInterval: pollInterval,
	});

	const prevLoading = usePrevious<boolean>(loading);
	if (onLoading && prevLoading !== loading && !(prevLoading === undefined && loading === false))
		onLoading(error ? LoadingComplete.error : loading ? LoadingComplete.loading : LoadingComplete.complete);

	return (
		<BoxChartSnippet
			title={title}
			loading={loading || !!fakeLoading}
			error={error}
			data={data ? (data.asTraffic as AsTrafficResult) : undefined}
			variables={variables?.input}
			wide={wide}
		/>
	);
};

export default BoxChartAsTraffic;
