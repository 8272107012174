import React, {useContext, useMemo, useReducer} from 'react';
import {ActionsUnion, createAction} from '../../../redux/helper';
import {initSelectedChoiceGroup} from './helper';
export type Entity = {
	__typename: string;
	infinityList: boolean;
	keyword: string;
	attributePath: string;
	caption: string;
	description: string;
	required: boolean;
	choiceGroup?: string;
	elementCaption?: string;
	scalarDataType?: {
		valueType: string;
		minLength: number;
		maxLength: number;
		possibleValueList: Array<{value: string; label: string}>;
		refType: null | string;
	};
	childAttributes: Entity[];
};

type State = {
	selectedChoiceGroup: {[key: string]: string};
};

enum ActionType {
	SET_SELECTED_CHOICE_GROUP = 'SET_SELECTED_CHOICE_GROUP',
}

const actions = {
	setSelectedChoiceGroup: (payload: {choiceGroup: string; keyword: string}) =>
		createAction(ActionType.SET_SELECTED_CHOICE_GROUP, payload),
};

export function reducer(state: State, action: ActionsUnion<typeof actions>): State {
	switch (action.type) {
		case ActionType.SET_SELECTED_CHOICE_GROUP:
			return {
				selectedChoiceGroup: {
					...state.selectedChoiceGroup,
					[action.payload.choiceGroup]: action.payload.keyword,
				},
			};

		default:
			return state;
	}
}

export const MetadataContext = React.createContext<{state: State} & typeof actions>(null as any);

export function MetadataProvider({children, entities, values}) {
	const [state, dispatch] = useReducer(reducer, {selectedChoiceGroup: initSelectedChoiceGroup(entities, values)});

	const actionBounds = useMemo(() => {
		const actionBounds = {};
		Object.keys(actions).forEach(actionKey => {
			const action = actions[actionKey];
			actionBounds[actionKey] = payload => dispatch(action(payload));
		});
		return actionBounds;
	}, [dispatch]);

	const value = {
		state,
		...actionBounds,
	};

	return <MetadataContext.Provider value={value as any}>{children}</MetadataContext.Provider>;
}

function useMetadata() {
	return useContext(MetadataContext);
}

export default useMetadata;
