import React from 'react';
import css from './CustomError.module.css';
import cls from '../../../utils/cls';

type Props = {
	message: string | React.ReactNode;
	className?: string;
};

const CustomError: React.FC<Props> = ({message, className}) => {
	return (
		<div className={cls(css.wrapper, className)}>
			<div>{message}</div>
		</div>
	);
};

export default CustomError;
