import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import css from './NotificationsMessageContainer.module.css';
import {
	NewUnreadNotificationsDocument,
	NotificationListDocument,
	useNotificationListQuery,
	useReadNotificationsMutation,
} from '../../queries-generated/types';
import NotificationsMessage from './NotificationsMessage';
import ProgressRing from '../controlls/Loader/ProgressRing';
import Button from '../pirsInputs/Button/Button';
import {ArrowLeftIcon} from '../SvgIcon';
import {NetworkStatus, useApolloClient, useLazyQuery} from '@apollo/client';
import Tabs, {Tab} from '../controlls/tabs/Tabs';
import {ApolloError} from '@apollo/client';
import ErrorSnippet from '../ErrorSnippet/ErrorSnippet';
import Badge from '../Badge/Badge';

type Props = {
	notificationsCount?: number;
	previousNotificationsCount?: number;
};

enum TabState {
	UNREAD,
	READ,
}

const NotificationsMessageContainer: React.FC<Props> = ({notificationsCount, previousNotificationsCount}) => {
	const [tab, setTab] = useState<TabState>(TabState.UNREAD);
	const [notificationsFetchMoreError, setNotificationsFetchMoreError] = useState<ApolloError>();
	const [readNotificationsMutationError, setReadNotificationsMutationError] = useState<ApolloError>();
	const [lastUnreadNotificationId, setLastUnreadNotificationId] = useState<string>();

	const client = useApolloClient();

	const handleUpdateCache = useCallback(() => {
		const currentReadNotifications = [
			...(client
				.readQuery({
					query: NotificationListDocument,
					variables: {checked: false},
				})
				?.notificationList?.notifications.filter(notification => notification.checked) || []),
			...(client
				.readQuery({query: NewUnreadNotificationsDocument})
				?.newUnreadNotifications?.filter(notification => notification.checked) || []),
		];

		client.cache.modify({
			fields: {
				notificationList: (data, {toReference, readField, storeFieldName}) => {
					if (storeFieldName === 'notificationList:{"checked":false}') {
						return {
							...data,
							notifications: data.notifications.filter(
								notification => !readField('checked', notification),
							),
						};
					} else if (storeFieldName === 'notificationList:{"checked":true}') {
						const refs = currentReadNotifications.map(notification => toReference(notification));
						return {
							...data,
							notifications: [...data.notifications, ...refs],
						};
					} else {
						return data;
					}
				},
				newUnreadNotifications: () => [],
			},
		});
	}, [client]);

	useEffect(() => {
		return () => {
			handleUpdateCache();
		};
	}, [handleUpdateCache]);

	const {
		data: notificationsData,
		error: notificationsError,
		loading: notificationsLoading,
		refetch: notificationsReFetch,
		fetchMore: notificationsFetchMore,
		networkStatus: notificationsNetworkStatus,
	} = useNotificationListQuery({
		variables: {checked: tab === TabState.READ},
		fetchPolicy: 'cache-and-network',
		// Чтобы не было лоадера загрузки при пагинации
		nextFetchPolicy: 'cache-first',
		// Чтобы работал onCompleted
		notifyOnNetworkStatusChange: true,
		onCompleted: result => {
			if (result?.notificationList?.notifications[0]?.id && tab === TabState.UNREAD) {
				setLastUnreadNotificationId(result.notificationList.notifications[0].id);
			}
		},
	});

	const skipNewUnreadNotifications =
		tab === TabState.READ ||
		notificationsFetchMoreError ||
		readNotificationsMutationError ||
		notificationsError ||
		notificationsNetworkStatus === NetworkStatus.loading ||
		notificationsNetworkStatus === NetworkStatus.setVariables ||
		notificationsNetworkStatus === NetworkStatus.refetch ||
		notificationsNetworkStatus === NetworkStatus.error ||
		(notificationsData &&
			notificationsData?.notificationList?.notifications?.length > 0 &&
			!lastUnreadNotificationId);

	const [
		getNewUnreadNotifications,
		{data: newUnreadNotificationsData, error: newUnreadNotificationsError},
	] = useLazyQuery(NewUnreadNotificationsDocument, {fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-only'});

	useEffect(() => {
		if (
			!skipNewUnreadNotifications &&
			notificationsCount !== undefined &&
			previousNotificationsCount !== undefined &&
			notificationsCount > previousNotificationsCount
		) {
			getNewUnreadNotifications({
				variables: {
					afterId: lastUnreadNotificationId,
				},
			});
		}
	}, [
		notificationsCount,
		previousNotificationsCount,
		skipNewUnreadNotifications,
		getNewUnreadNotifications,
		lastUnreadNotificationId,
	]);

	/*
	Эту ошибку будет лучше обрабатывать сервисов для логирования клиентских ошибок,
    так как это повторяющийся запрос новых непрочитанных,
    когда открыто окошко, и показывать ошибку при каждом интервале запроса будет не корректно,
    да и не вижу я способов для красивого вывода данной ошибки
	*/
	// TODO Добавить обработку ошибки запроса новых непрочитанных сообщений
	if (newUnreadNotificationsError) console.error(newUnreadNotificationsError);

	const notifications = notificationsData?.notificationList?.notifications;
	const newUnreadNotifications = newUnreadNotificationsData?.newUnreadNotifications;

	// Для пагинации после после последнего уведомления в списке
	const lastNotificationId = useMemo((): string | undefined => {
		if (notifications && notifications.length > 0) {
			return notifications[notifications.length - 1]?.id;
		}
	}, [notifications]);

	const [readNotificationsMutation, {loading: readNotificationsMutationLoading}] = useReadNotificationsMutation({
		notifyOnNetworkStatusChange: true,
	});

	const handleChangeTab = useCallback(
		(newTab: TabState) => {
			if (tab === TabState.UNREAD) {
				handleUpdateCache();
			}
			setLastUnreadNotificationId(undefined);
			setNotificationsFetchMoreError(undefined);
			setTab(newTab);
		},
		[client, tab, setTab, setLastUnreadNotificationId, setNotificationsFetchMoreError, handleUpdateCache],
	);

	const handleResetFetchMoreNotifications = useCallback(() => {
		client.cache.modify({
			fields: {
				newUnreadNotifications: () => [],
			},
		});
		setNotificationsFetchMoreError(undefined);
		notificationsReFetch();
	}, [client, setNotificationsFetchMoreError, notificationsReFetch]);

	const handleBackToNotificationList = useCallback(() => {
		client.cache.modify({
			fields: {
				newUnreadNotifications: () => [],
			},
		});
		setReadNotificationsMutationError(undefined);
		notificationsReFetch();
	}, [client, setReadNotificationsMutationError, notificationsReFetch]);

	const handleReadAllMessages = useCallback(() => {
		if (notifications?.length || newUnreadNotifications?.length) {
			const notificationsIds = (notifications || []).map(notification => notification!.id);
			const newUnreadNotificationsIds = (newUnreadNotifications || []).map(notification => notification!.id);

			readNotificationsMutation({
				variables: {
					notificationsIds: [...notificationsIds, ...newUnreadNotificationsIds],
				},
				update: (cache, mutationResult) => {
					cache.modify({
						fields: {
							notificationList: (data, {storeFieldName, toReference}) => {
								if (storeFieldName === 'notificationList:{"checked":false}') {
									return {
										...data,
										notifications: [],
										hasNext: undefined,
									};
								} else if (storeFieldName === 'notificationList:{"checked":true}') {
									if (
										mutationResult.data?.readNotifications &&
										mutationResult.data.readNotifications.length > 0
									) {
										const refs = mutationResult.data.readNotifications.map(notification =>
											toReference(notification),
										);
										return {...data, notifications: [...data.notifications, ...refs]};
									}
									return data;
								} else {
									return data;
								}
							},
							newUnreadNotifications: () => {
								return [];
							},
							notificationsCount: value => {
								const result = mutationResult.data?.readNotifications;
								if (result && result.length > 0) {
									return value - result.length;
								}
							},
						},
					});
				},
			})
				.then(() => {
					setReadNotificationsMutationError(undefined);
					setLastUnreadNotificationId(undefined);
					notificationsReFetch();
				})
				.catch(error => {
					setReadNotificationsMutationError(error);
				});
		}
	}, [
		readNotificationsMutation,
		notificationsReFetch,
		setReadNotificationsMutationError,
		setLastUnreadNotificationId,
		notifications,
		newUnreadNotifications,
	]);

	/*
	При прокрутке списка уведомлений до конца вниз,
	вызывается событие,которое загружает дополнительные уведомления
	*/
	const observer = useRef<IntersectionObserver | null>(null);
	const lastNotificationElementRef = useCallback(
		node => {
			if (notificationsLoading || !divRef || !divRef.current) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver(
				entries => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							notificationsFetchMore({
								variables: {
									before: lastNotificationId,
								},
							}).catch(error => {
								setNotificationsFetchMoreError(error);
							});
						}
					});
				},
				{root: divRef.current},
			);
			if (node) observer.current.observe(node);
		},
		[notificationsLoading, lastNotificationId, setNotificationsFetchMoreError, notificationsFetchMore],
	);

	const errorCondition =
		(notificationsError || notificationsFetchMoreError || readNotificationsMutationError) &&
		!readNotificationsMutationLoading &&
		!notificationsLoading;

	const loaderCondition =
		(notificationsLoading || readNotificationsMutationLoading) &&
		notificationsNetworkStatus !== NetworkStatus.fetchMore;

	const emptyNotificationsCondition =
		!errorCondition && !loaderCondition && !notifications?.length && !newUnreadNotifications?.length;

	const notificationListCondition = !errorCondition && !loaderCondition && !emptyNotificationsCondition;

	const divRef = useRef<HTMLDivElement>(null);

	return (
		<div ref={divRef} className={css.container}>
			<Tabs selected={tab} onChange={handleChangeTab}>
				<Tab
					header={
						<Badge badgeContent={notificationsCount} max={999} style={{top: '-8px', right: '-30px'}}>
							Не прочитанные
						</Badge>
					}
				/>
				<Tab header={'Прочитанные'} />
			</Tabs>
			{errorCondition && (
				<div className={css.error}>
					{readNotificationsMutationError && (
						<Button onClick={handleBackToNotificationList} secondary iconOnly>
							<ArrowLeftIcon />
							вернуться к списку уведомлений
						</Button>
					)}
					<ErrorSnippet
						error={
							notificationsError
								? 'Ошибка загрузки списка уведомлений'
								: notificationsFetchMoreError
								? 'Ошибка загрузки дополнительных данных'
								: readNotificationsMutationError
								? 'Ошибка прочтения всех уведомлений'
								: ''
						}
						errorHeader={''}
						refetch={
							notificationsError
								? notificationsReFetch
								: notificationsFetchMoreError
								? handleResetFetchMoreNotifications
								: readNotificationsMutationError
								? handleReadAllMessages
								: undefined
						}
					/>
				</div>
			)}

			{loaderCondition && (
				<div className={css.loading}>
					<ProgressRing />
				</div>
			)}
			{emptyNotificationsCondition && (
				<div className={css.emptyNotifications}>
					<h3>Уведомлений нет</h3>
				</div>
			)}

			{notificationListCondition && (
				<>
					{tab === TabState.UNREAD && (
						<Button onClick={handleReadAllMessages} iconOnly action>
							Пометить прочитанным все сообщения в списке
						</Button>
					)}
					<ul className={css.list}>
						{tab === TabState.UNREAD &&
							newUnreadNotifications &&
							newUnreadNotifications.length > 0 &&
							newUnreadNotifications.map(notification => {
								return (
									<NotificationsMessage
										key={notification!.id}
										notification={notification!}
										newMessage={true}
									/>
								);
							})}
						{notifications && notifications?.length > 0 && (
							<>
								{notifications.map(notification => {
									return <NotificationsMessage key={notification!.id} notification={notification!} />;
								})}
								{notificationsData?.notificationList.hasNext && (
									<li ref={lastNotificationElementRef} className={css.loader}>
										<ProgressRing />
									</li>
								)}
							</>
						)}
					</ul>
				</>
			)}
		</div>
	);
};

export default NotificationsMessageContainer;
