exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".ResourceTableRow-module__accepted__1Hk0E {\r\n\tbackground-color: var(--success-fill);\r\n}\r\n\r\n.ResourceTableRow-module__rejected__2bfKh {\r\n\tbackground-color: var(--error-fill);\r\n}\r\n\r\n.ResourceTableRow-module__open__MuSlT {\r\n\tbackground-color: var(--neutral-fill-secondary-rest);\r\n}\r\n\r\n.ResourceTableRow-module__hoverProcessedStatus__1JOIb {\r\n\ttransition-duration: 150ms;\r\n\ttransition-property: filter;\r\n}\r\n\r\n.ResourceTableRow-module__hoverProcessedStatus__1JOIb:hover {\r\n\t\tfilter: brightness(95%);\r\n\t}\r\n\r\n.ResourceTableRow-module__controlsCell__1LFvH {\r\n\twidth: 100px;\r\n}\r\n\r\n.ResourceTableRow-module__controls__2dmlA {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\ResourceRegisterTable\\ResourceTableRow.module.css"],"names":[],"mappings":"AAAA;CACC,qCAAqC;AACtC;;AAEA;CACC,mCAAmC;AACpC;;AAEA;CACC,oDAAoD;AACrD;;AAEA;CACC,0BAA0B;CAC1B,2BAA2B;AAI5B;;AAHC;EACC,uBAAuB;CACxB;;AAGD;CACC,YAAY;AACb;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B","file":"ResourceTableRow.module.css","sourcesContent":[".accepted {\r\n\tbackground-color: var(--success-fill);\r\n}\r\n\r\n.rejected {\r\n\tbackground-color: var(--error-fill);\r\n}\r\n\r\n.open {\r\n\tbackground-color: var(--neutral-fill-secondary-rest);\r\n}\r\n\r\n.hoverProcessedStatus {\r\n\ttransition-duration: 150ms;\r\n\ttransition-property: filter;\r\n\t&:hover {\r\n\t\tfilter: brightness(95%);\r\n\t}\r\n}\r\n\r\n.controlsCell {\r\n\twidth: 100px;\r\n}\r\n\r\n.controls {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"accepted": "ResourceTableRow-module__accepted__1Hk0E",
	"rejected": "ResourceTableRow-module__rejected__2bfKh",
	"open": "ResourceTableRow-module__open__MuSlT",
	"hoverProcessedStatus": "ResourceTableRow-module__hoverProcessedStatus__1JOIb",
	"controlsCell": "ResourceTableRow-module__controlsCell__1LFvH",
	"controls": "ResourceTableRow-module__controls__2dmlA"
};