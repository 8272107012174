import React, {useContext, useMemo} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {Field, useCustomFormMutation} from '../../../../../queries-generated/types';
import {defaultFormEmptyValues, validateFields} from '../helper';
import css from './FormComponent.module.css';
import ProgressRing from '../../../../controlls/Loader/ProgressRing';
import {DataSourceContext} from '../../../../view/ViewWrapper';
import {notifyToastError, notifyToastSuccess} from '../../../../toast/Toast';
import {getError} from '../../../../../utils/error';

type Props = {
	viewItem: ViewItem;
};

function getInputs(viewItems: ViewItem[]) {
	let inputViewItems: ViewItem[] = [];
	viewItems.forEach(viewItem => {
		if (viewItem.component.type === 'Input') inputViewItems.push(viewItem);

		if (viewItem.children) {
			inputViewItems = [...inputViewItems, ...getInputs(viewItem.children)];
		}
	});

	return inputViewItems;
}

export function viewItemToField(viewItem: ViewItem): Field | null {
	if (viewItem.component.type !== 'Input') return null;

	return {
		...(viewItem.component.props as Field),
	};
}

const FormComponent: React.FC<Props> = ({children, viewItem}) => {
	const dataSourceContext = useContext(DataSourceContext);
	const {reloadCounter, setReloadCounter} = dataSourceContext || {};
	const inputViewItems = useMemo(() => getInputs(viewItem.children), [viewItem]);
	const fields = useMemo(() => inputViewItems.map(viewItemToField).filter(Boolean) as Field[], [inputViewItems]);

	const initialValues = useMemo(() => defaultFormEmptyValues(fields), [fields]);

	const [submit, {loading}] = useCustomFormMutation();

	const formik = useFormik({
		initialValues,
		validate: values => {
			if (fields) {
				const errors = validateFields(fields, values);
				if (Object.keys(errors).length) {
					return errors;
				}
			}
		},
		onSubmit: values => {
			submit({
				variables: {
					formId: viewItem.component.props.formId,
					values,
				},
			})
				.then(({errors}) => {
					if (errors) {
						notifyToastError(`Не удалось сохранить: ${getError(errors as any)?.message}`);
					} else {
						setReloadCounter((reloadCounter || 0) + 1);
						notifyToastSuccess('Форма успешно сохранена');
					}
				})
				.catch(reason => {
					notifyToastError(`Не удалось сохранить: ${getError(reason)?.message}`);
				});
		},
	});
	return (
		<div className={css.wrapper}>
			{loading && (
				<div className={css.loader}>
					<ProgressRing />
				</div>
			)}
			<FormikProvider value={formik}>
				<Form>{children}</Form>
			</FormikProvider>
		</div>
	);
};

export default FormComponent;
