import React from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import ResourceRegisterSideBar from './ResourceRegisterSideBar/ResourceRegisterSideBar';
import ResourceRegisterTableContainer from './ResourceRegisterTable/ResourceRegisterTableContainer';
import style from './ResourceRegisterPage.module.css';
import useAccumulatedChangesWithPresentData from '../../components/ResourceRegisterAccumulatedChanges/ResourceRegisterAccumulatedChanges';

const ResourceRegisterPage = () => {
	const {reFetchSession, AccumulatedChanges} = useAccumulatedChangesWithPresentData();

	return (
		<MainLayout>
			<div className={style.container}>
				<div className={style.fixedHeader}>
					<div className={style.innerHeader}>
						<h2 className={style.header}>Реестр ресурсов</h2>
						{AccumulatedChanges}
					</div>
				</div>
				<div className={style.mainLayout}>
					<ResourceRegisterSideBar />
					<ResourceRegisterTableContainer reFetchSession={reFetchSession} />
				</div>
			</div>
		</MainLayout>
	);
};

export default ResourceRegisterPage;
