/* Для корректной работы надо использовать в useCallback
 * const handleChangeInput = useCallback(
 *    debounce(value => setValue(value), 1000),
 *    [],
 * );
 *
 *
 *  */

export function debounce(fn, wait) {
	let timeout;
	return function(...args) {
		const later = () => {
			clearTimeout(timeout);
			// eslint-disable-next-line no-invalid-this
			// @ts-ignore
			fn.apply(this, args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}
