import React from 'react';
import css from './DataGrid.module.css';
import cls from '../../utils/cls';
import {Field} from '../../queries-generated/types';

type Props = {
	item: PlainObjectOf<any>;
	field: Field;
	index: number;
};

const DataGridCell: React.FC<Props> = ({field, item, index}) => {
	return (
		<div className={cls(css.cell, css.visibleCell)} role="gridcell" aria-readonly="true" aria-colindex={index + 1}>
			{item[field.id]}
		</div>
	);
};

export default DataGridCell;
