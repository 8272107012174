import React from 'react';
import css from './Toggle.module.css';
import Field from '../Field/Field';
import cls from '../../../utils/cls';
import {CommonInputInterface} from '../CommonInputInterface';
import uid from '../../../utils/uid';

interface Props extends CommonInputInterface<boolean> {
	offMessage?: JSX.Element | string;
	onMessage?: JSX.Element | string;
}

export type ToggleProps = Omit<
	Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof Props> & Props,
	'underlined' | 'value'
>;

const Toggle: React.FC<ToggleProps> = ({offMessage, onMessage, checked, onChange, disabled, className, ...props}) => {
	function handleChange(event: React.MouseEvent) {
		event.stopPropagation();
		if (disabled) return;
		if (onChange) onChange(!checked);
	}

	const id = props.id || 'Input-' + uid();

	return (
		<Field className={cls(className)} commonProps={props} inputId={id}>
			<div
				id={id}
				className={cls(css.wrapper, disabled && css.disabled, checked && css.checked)}
				onClick={handleChange}
				role="switch"
				aria-checked={checked ? 'true' : 'false'}
			>
				<button className={css.switch} disabled={disabled}>
					<span className={css.thumb} />
				</button>
				<div className={css.label}>{checked ? onMessage : offMessage}</div>
			</div>
		</Field>
	);
};

export default Toggle;
