import React from 'react';
import useMetadata, {Entity} from '../form/useMetadata';
import EntityItemOnlyValues from './EntityItemOnlyValues';
import {isDirtyValues} from '../form/helper';

type Props = {
	entities: Entity[];
	deep?: number;
	values: any;
};

const ChoiceGroupOnlyValues: React.FC<Props> = ({entities, deep, values}) => {
	const choiceGroup = entities[0].choiceGroup as string;
	const {state} = useMetadata();
	const {selectedChoiceGroup} = state;
	const selected = selectedChoiceGroup[choiceGroup] || entities[0].keyword;

	const currentEntity = entities.find(entity => entity.keyword === selected);
	return currentEntity && isDirtyValues(values[currentEntity.keyword]) ? (
		<>
			{!deep ? <h1>{entities[0].choiceGroup}</h1> : <h3>{entities[0].choiceGroup}</h3>}
			{currentEntity && (
				<EntityItemOnlyValues
					values={values[currentEntity.keyword]}
					entity={currentEntity}
					deep={(deep || 0) + 1}
				/>
			)}
		</>
	) : null;
};

export default ChoiceGroupOnlyValues;
