import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import {AlertsRoutes} from '../AlertsStatisticPage/AlertsStatisticPage';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import {
	OldDictionaryDocument,
	OldDictionaryQuery,
	OldDictionaryQueryVariables,
	useAnomaliesModelNamesQuery,
	useGetConfigurationGroupQuery,
} from '../../queries-generated/types';
import {tryParse} from '../../components/hooks/useLocalStorage';
import Button from '../../components/pirsInputs/Button/Button';
import cls from '../../utils/cls';
import {AsObject, BgpAgent, BgpOperator, Views} from '../../components/asLinks/AsLinksFilters/AsLinksFilters';
import {Form, FormikProvider, useFormik} from 'formik';
import {useApolloClient} from '@apollo/client';
import {PredefinedPeriod, PredefinedPeriodValues} from '../../other/PredefinedPeriod';
import css from './AlertsAnomaliesPage.module.css';
import filterCss from './Filter.module.css';
import {toQueryVariables, validate} from './AlertAnomaliesQueryVaribles';
import AlertsAnomaliesFilters from './AlertsAnomaliesFilters';
import {useRouter} from 'react-named-hooks-router';
import SafeLink from '../../components/SafeLink/SafeLink';
import {LoadFiltersByRoute, RouteToAlertAnomaliesParams, useRouteVariables} from './AlertAnomaliesRoutes';
import {JSONSafeParse} from '../../utils/object-utils';
import {CheckIcon} from '../../components/SvgIcon';

export type AdvPredefinedPeriodKeys = keyof typeof PredefinedPeriod | 'Notset';
export type AdvPredefinedPeriodValues = {
	[key in AdvPredefinedPeriodKeys]: string;
};
export const advPredefinedPeriodValues: AdvPredefinedPeriodValues = Object.assign(
	{
		Notset: 'Не выбран',
	},
	PredefinedPeriodValues,
);

export type KeyValue = {
	id: string;
	key: string;
	value: string;
};

export type AnomalySources = {
	[key: string]: string;
};

export enum AnomalyOpenState {
	Opened = 'Opened',
	Closed = 'Closed',
}

export const routeToAlertAnomaliesEmptyParams: RouteToAlertAnomaliesParams = {
	anomalySource: '',
	anomalyOpenState: AnomalyOpenState.Opened,
	periodOpened: 'Notset',
	openedFromDt: null,
	openedToDt: null,
	periodClosed: 'Notset',
	closedFromDt: null,
	closedToDt: null,
	bgpOperators: [],
	bgpAgents: [],
	anomalyTypes_pasmon: [],
	anomalyTypes_ranr: [],
	anomalyTypes_asbi: [],
	anomalyTypes_nsdi: [],
};

export type Filters = {
	anomalySource: string;
	anomalyOpenState: AnomalyOpenState;
	periodOpened: AdvPredefinedPeriodKeys;
	openedFromDt: string | null;
	openedToDt: string | null;
	periodClosed: AdvPredefinedPeriodKeys;
	closedFromDt: string | null;
	closedToDt: string | null;
	bgpOperators: BgpOperator[];
	bgpAgents: BgpAgent[];
	anomalyTypes_pasmon: KeyValue[];
	anomalyTypes_ranr: KeyValue[];
	anomalyTypes_asbi: KeyValue[];
	anomalyTypes_nsdi: KeyValue[];
	asSrc: AsObject[];
	asDst: AsObject[];
	asOrigin: AsObject[];
	severity: KeyValue[];
	prefix: string;
	service: string;
	description: string;
	registrant: string;
	subnet: string;
	anomalyKey: string;
};

const AlertsAnomaliesPage: React.FC = () => {
	const {routeParams} = useRouter();

	const [usingRouteVariables] = useState(
		Object.keys(routeToAlertAnomaliesEmptyParams).filter(value => Object.keys(routeParams).includes(value)).length >
			0,
	);

	const [anomalySources, setAnomalySources] = useState<AnomalySources>({});

	const [queryVariables, setQueryVariables] = useState<PlainObjectOf<any>>();
	const [modelName, setModelName] = useState<string>();

	const [expanded, setExpanded] = useState(false);

	useGetConfigurationGroupQuery({
		onCompleted: data => {
			// TODO: временный хардкод - ограничение источников аномалий, выдаваемых сервисом
			const newAnomalySources: AnomalySources = {};
			data.getConfigurationGroup
				.filter(item => ['ПАС Мониторинг', 'РАНР', 'АСБИ', 'СМУ НСДИ', 'НСДИ'].includes(item.value))
				.forEach(item => {
					newAnomalySources[item.key.toString()] = item.value;
				});
			setAnomalySources(newAnomalySources);
			if (Object.keys(newAnomalySources).length && !usingRouteVariables)
				formikBag.setFieldValue('anomalySource', Object.keys(newAnomalySources)[0]);
		},
	});

	const formikBag = useFormik<Filters>({
		initialValues: {
			anomalySource: '',
			anomalyOpenState: AnomalyOpenState.Opened,
			periodOpened: PredefinedPeriod.H6,
			openedFromDt: null,
			openedToDt: null,
			periodClosed: PredefinedPeriod.H6,
			closedFromDt: null,
			closedToDt: null,
			bgpOperators: [],
			bgpAgents: [],
			anomalyTypes_pasmon: [],
			anomalyTypes_ranr: [],
			anomalyTypes_asbi: [],
			anomalyTypes_nsdi: [],
			asSrc: [],
			asDst: [],
			asOrigin: [],
			severity: [],
			prefix: '',
			service: '',
			description: '',
			registrant: '',
			subnet: '',
			anomalyKey: '',
		},
		onSubmit: filters => {
			setQueryVariables(toQueryVariables(filters, anomalySources));
			if (expanded) setExpanded(false);
			const modelNames = modelNameData?.anomaliesModelNames && modelNameData.anomaliesModelNames[0];
			const newModelName = modelNames && modelNames[formikBag.values.anomalyOpenState.toLocaleLowerCase()];
			if (newModelName !== modelName) setModelName(newModelName);
		},
		validate: validate,
	});

	const [loadFiltersByRoute] = useRouteVariables(usingRouteVariables ? (routeParams as any) : undefined, formikBag);

	const anomalySourceByKey = useCallback(
		(key: string): string => (Object.keys(anomalySources).length && anomalySources[key] ? anomalySources[key] : ''),
		[anomalySources],
	);

	const {data: modelNameData} = useAnomaliesModelNamesQuery({
		variables: {
			input: anomalySourceByKey(formikBag.values.anomalySource),
		},
		skip: !anomalySourceByKey(formikBag.values.anomalySource).length,
	});

	useEffect(() => {
		if (loadFiltersByRoute === LoadFiltersByRoute.Completed && modelNameData) {
			formikBag.submitForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadFiltersByRoute, modelNameData]);

	const resolveDescriptionValue = useCallback((key: string, value: any): React.ReactNode => {
		if (['operator_inn'].includes(key)) {
			return (
				<SafeLink
					route={'surveyResourceOwners'}
					params={{tin: typeof value === 'number' ? value : value.match(/^\d+/gi)[0]}}
					target={'_blank'}
				>
					{value}
				</SafeLink>
			);
		}

		if (['as_from', 'as_to', 'origin'].includes(key)) {
			return (
				<SafeLink
					route={'surveyResourceAs'}
					params={{asId: typeof value === 'number' ? value : value.match(/^\d+/gi)[0]}}
					target={'_blank'}
				>
					{value}
				</SafeLink>
			);
		}

		if (value === null) return '';

		return <span dangerouslySetInnerHTML={{__html: value}} />;
	}, []);

	const formatBodyDetails = useCallback(
		(detail: string): React.ReactNode => {
			if (!detail) return '';
			const data = tryParse(detail);
			if (typeof data === 'object') {
				return (
					<ul className={css.list}>
						{Object.keys(data).map(key => {
							return (
								<li key={key}>
									{key}: {resolveDescriptionValue(key, data[key])}
								</li>
							);
						})}
					</ul>
				);
			}
			return detail;
		},
		[resolveDescriptionValue],
	);

	const createTypeLink = useCallback((type: string, details: any): React.ReactNode => {
		const parseDetails = JSONSafeParse(details);
		if (
			typeof parseDetails === 'object' &&
			parseDetails.agent_type &&
			parseDetails.agent_type.match(/^\d+/gi)[0] === '0'
		) {
			const {as_from, as_to, agent, operator_inn} = parseDetails;
			return (
				<SafeLink
					route={'asLinksDetail'}
					params={{
						view: Views.connections,
						asAId: typeof as_from === 'number' ? as_from : as_from.match(/^\d+/gi)[0],
						asBIds: typeof as_to === 'number' ? as_to : as_to.match(/^\d+/gi)[0],
						bgpOperator: operator_inn.match(/^\d+/gi)[0],
						bgpAgents: agent.match(/<a[^>]*>(.*?)<\/a>/)[1],
						hopCount: 10,
						linkCount: 10,
					}}
					target={'_blank'}
				>
					{type}
				</SafeLink>
			);
		}
		return type;
	}, []);

	const {Table} = useFullTableWithRefetch(modelName || '', {
		skip: !queryVariables || !modelName,
		tableFields: ['severity', 'type', 'open_date', 'close_date', 'close_type'],
		detailsFields: ['body'],
		cellCallback: {
			type: (value, item) => createTypeLink(value, item.body),
		},
		detailCellCallback: {
			body: value => formatBodyDetails(value),
		},
		additionalFilters: (
			<div className={css.filterGround}>
				<FormikProvider value={formikBag}>
					<Form className={cls(filterCss.outerFilterContainer, expanded && css.spacer)}>
						<AlertsAnomaliesFilters
							expanded={expanded}
							setExpanded={setExpanded}
							anomalySourceByKey={anomalySourceByKey}
							anomalySources={anomalySources}
							loading={loadFiltersByRoute === LoadFiltersByRoute.InProgress}
						/>
						<div className={filterCss.buttonApplyContainer}>
							<Button type="submit" className={filterCss.filterButton}>
								<CheckIcon />
								Построить
							</Button>
						</div>
					</Form>
				</FormikProvider>
			</div>
		),
		variables: {
			...queryVariables,
		},
	});

	return (
		<MainLayout routes={AlertsRoutes}>
			<Table />
		</MainLayout>
	);
};

export function useOldDictionary(type: string): () => Promise<KeyValue[]> {
	const client = useApolloClient();

	return useCallback(() => {
		return new Promise(resolve => {
			client
				.query<OldDictionaryQuery, OldDictionaryQueryVariables>({
					query: OldDictionaryDocument,
					variables: {
						id: `events.${type}`,
					},
				})
				.then(result => {
					resolve(
						result.data.oldDictionary.map(item => {
							return {
								id: item.key.toString(),
								key: item.key.toString(),
								value: item.value,
							};
						}),
					);
				});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);
}

export const selectedInOldDictionary = (item: KeyValue | KeyValue[] | null) => {
	if (!item) return '';
	const digTest = /^\d+$/;
	if (Array.isArray(item)) {
		return item
			.sort((a, b) => {
				if (digTest.test(a.key) && digTest.test(b.key)) return parseInt(a.key, 10) - parseInt(b.key, 10);
				else return a.value.localeCompare(b.value);
			})
			.map(el => el.value)
			.join(', ');
	}
	return item.value;
};

export default AlertsAnomaliesPage;
