import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {
	DataSourceNotification,
	GraphTimeLineGrid,
	FieldType,
	useGraphTimeLineGridQuery,
} from '../../../../../queries-generated/types';
import {DataSourceContext} from '../../../../view/ViewWrapper';
import usePrevious from '../../../../hooks/usePrevious';
import {isNotifiedDataSource, filtersToDataSourceParams} from '../helper';
import {useComponentFilters} from '../../../../view/api/useUserFilters';
import {notifyDataSource} from '../../../../toast/DataSourceToast';
import ErrorSnippet from '../../../../ErrorSnippet/ErrorSnippet';
import {NetworkStatus} from '@apollo/client';
import cssError from '../ChartsBase/ChartWrapperWithData.module.css';
import ProgressRing from '../../../../controlls/Loader/ProgressRing';
import css from './GraphTimeLineGridComponent.module.css';
import DataGrid, {DataGridOptions} from '../../../../DataGrid/DataGrid';
import cls from '../../../../../utils/cls';
import GraphBase from '../Graph/GraphBase';
import useDataGridOptions from '../GridComponent/useDataGridOptions';
import useSplitScreen from '../../splitScreen/useSplitScreen';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import viewComponentCss from '../../../../view/ViewComponent.module.css';
import useComponentCommonButtons, {ComponentCommonButtonsProps} from '../../../../view/useComponentCommonButtons';
import useTableItemMouseOver from './useTableItemMouseOver';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	height: number | string;
	pollInterval?: number;
	componentCommonButtonsProps?: ComponentCommonButtonsProps;
};

const defaultData: GraphTimeLineGrid = {
	nodes: [
		{id: '1', label: '1 узел'},
		{id: '2', label: '2 узел'},
		{id: '3', label: '3 узел'},
		{id: '4', label: '4 узел'},
	],
	edges: [
		{
			id: '1-2',
			from: '1',
			to: '2',
			ranges: [
				{
					startAt: '2020-01-05T10:00:00Z',
					endAt: '2020-01-06T10:00:00Z',
				},
			],
		},
		{
			id: '1-3',
			from: '1',
			to: '3',
		},
		{
			id: '1-4',
			from: '1',
			to: '4',
		},
	],
	fields: [
		{id: '1', title: 'Колонка 1', type: FieldType.String, visible: true, isGroupField: true},
		{
			id: '2',
			title: 'Колонка 2',
			type: FieldType.String,
			visible: true,
			defaultSort: true,
			isGroupField: true,
		},
		{id: '3', title: 'Колонка 3', type: FieldType.String, visible: true},
	],
	itemsByTime: [
		{
			time: '2020-01-05T10:00:00Z',
			items: [
				{id: '1', '1': 'Общий параметр 1-1', '2': 'Общий параметр 1-2', '3': 'Запись 3'},
				{id: '2', '1': 'Общий параметр 1-1', '2': 'Общий параметр 1-2', '3': 'Запись 6'},
				{id: '3', '1': 'Общий параметр 1-1', '2': 'Общий параметр 1-2', '3': 'Запись 9'},
			],
		},
		{
			time: '2020-10-05T10:00:00Z',
			items: [
				{id: '4', '1': 'Общий параметр 2-1', '2': 'Общий параметр 2-2', '3': 'Запись 3-1'},
				{id: '5', '1': 'Общий параметр 2-1', '2': 'Общий параметр 2-2', '3': 'Запись 6-1'},
				{id: '6', '1': 'Общий параметр 2-1', '2': 'Общий параметр 2-2', '3': 'Запись 9-1'},
			],
		},
		{
			time: '2020-01-08T10:00:00Z',
			items: [
				{id: '7', '1': 'Общий параметр 3-1', '2': 'Общий параметр 3-2', '3': 'Запись 3-2'},
				{id: '8', '1': 'Общий параметр 3-1', '2': 'Общий параметр 3-2', '3': 'Запись 6-2'},
				{id: '9', '1': 'Общий параметр 3-1', '2': 'Общий параметр 3-2', '3': 'Запись 9-2'},
			],
		},
		{
			time: 'present',
			items: [
				{
					id: '10',
					'1': 'Общий параметр 4-1',
					'2': 'Общий параметр 4-2',
					'3': 'Запись 3-3',
					linkedEdges: ['1-2'],
					linkedNodes: ['1', '2'],
				},
				{
					id: '11',
					'1': 'Общий параметр 4-1',
					'2': 'Общий параметр 4-2',
					'3': 'Запись 6-3',
					linkedEdges: ['1-3', '1-4'],
					linkedNodes: ['1', '3', '4'],
				},
				{id: '12', '1': 'Общий параметр 4-1', '2': 'Общий параметр 4-2', '3': 'Запись 9-3'},
				{id: '13', '1': 'Общий параметр 4-3', '2': 'Общий параметр 4-2', '3': 'Запись 6-3'},
				{id: '14', '1': 'Общий параметр 4-3', '2': 'Общий параметр 4-2', '3': 'Запись 9-3'},
				{id: '15', '1': 'Общий параметр 4-3', '2': 'Общий параметр 4-2', '3': 'Запись 6-3'},
				{id: '16', '1': 'Общий параметр 4-3', '2': 'Общий параметр 4-2', '3': 'Запись 9-3'},
			],
		},
	],
	options: {
		hasHistory: true,
		hasHistoryPresentMoment: true,
		historyDates: ['2020-01-05T10:00:00Z', '2020-10-05T10:00:00Z', '2020-01-08T10:00:00Z'],
		initDate: 'present',
		highlightOptions: {
			node: {
				borderColor: 0,
				borderThickness: 80,
			},
			edge: {
				thickness: 80,
				color: 3,
			},
		},
	},
};

const GraphTimeLineGridComponent: React.FC<Props> = ({
	viewItem,
	skipQuery,
	height,
	pollInterval,
	componentCommonButtonsProps,
}) => {
	const {reloadCounter} = useContext(DataSourceContext);
	const prevReloadCounter = usePrevious(reloadCounter);
	const graph = useRef<any>(null);
	const setGraph = useCallback(graphObj => {
		graph.current = graphObj;
	}, []);

	const align = viewItem.component.props?.tableAlignment?.id || 'bottom';

	const {filters, waiting} = useComponentFilters(viewItem);
	const {setData} = useComponentsStoredData(viewItem.id);

	const {firstDefaultSort, sortOrder, sort, onChangeSort, setGridData} = useDataGridOptions({
		defaultNumberOfRows: viewItem.component.props.defaultNumberOfRows,
		viewItemId: viewItem.id,
	});

	if (filters) {
		if (sort) filters.sort = `${sortOrder ? '+' : '-'}${sort}`;
	}

	const {data, loading, refetch, error, networkStatus} = useGraphTimeLineGridQuery({
		variables: {
			dataSourceId: viewItem.dataSource?.type || '',
			filters: filtersToDataSourceParams(filters, viewItem.dataSource?.columns),
		},
		skip: skipQuery || waiting,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		pollInterval,
		onCompleted: result => {
			const dataSourceContentGraphTimeLineTable = result.graphTimeLineGrid;
			setGridData({
				fields: dataSourceContentGraphTimeLineTable.fields,
			});
			if (dataSourceContentGraphTimeLineTable) {
				if (isNotifiedDataSource(viewItem.actions))
					notifyDataSource(result.graphTimeLineGrid.notifications as DataSourceNotification[]);
			}
		},
	});

	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const graph = skipQuery ? defaultData : data?.graphTimeLineGrid;
		setData({...viewItem.dataSource, data: graph});
	}, [defaultData, data, skipQuery, viewItem]);

	const {firstHeight, secondHeight, firstWidth, secondWidth, ResizeControl, secondCss, firstCss} = useSplitScreen({
		align,
		width: '100%',
		height,
		parentRef: wrapperRef,
	});

	useEffect(() => {
		if (prevReloadCounter !== undefined && reloadCounter !== prevReloadCounter && !skipQuery) {
			refetch();
		}
	}, [reloadCounter, skipQuery, prevReloadCounter]);

	const tableRef = useRef<HTMLDivElement>(null);

	const tableGraph = skipQuery ? defaultData : data?.graphTimeLineGrid;

	const [historyDate, setHistoryDate] = useState<string | null>(null);

	useEffect(() => {
		if (!historyDate && tableGraph?.options.initDate) {
			setHistoryDate(tableGraph.options.initDate);
		}
	}, [tableGraph, historyDate]);

	const [replayInProgress, setReplayInProgress] = useState(false);
	const {handleMouseLeave, handleMouseEnter} = useTableItemMouseOver(tableGraph!, graph, replayInProgress);

	const tableOptions: DataGridOptions = useMemo(() => {
		return {
			sort: sort || firstDefaultSort,
			onChangeSort: onChangeSort,
			sortOrder: sortOrder,
			fields: tableGraph?.fields,
			onRowMouseEnter: handleMouseEnter,
			onRowMouseLeave: handleMouseLeave,
		};
	}, [sort, firstDefaultSort, onChangeSort, tableGraph?.fields, handleMouseEnter, handleMouseLeave]);

	const commonButtons = useComponentCommonButtons(viewItem, componentCommonButtonsProps);

	if (error)
		return <ErrorSnippet error={error} errorHeader={'Ошибка загрузки данных для таблицы'} refetch={refetch} />;

	if ((loading && networkStatus !== NetworkStatus.poll) || !tableGraph)
		return (
			<div className={cssError.error}>
				<ProgressRing />
			</div>
		);

	const Graph = (
		<div className={cls(css.chart, firstCss)} style={{height: firstHeight, width: firstWidth}}>
			{tableGraph.nodes.length ? (
				<GraphBase
					viewItemId={viewItem.id}
					data={tableGraph}
					direction={viewItem.component.props.alignment?.id || 'star'}
					hideDeleted={viewItem.component.props.hide_deleted}
					hasHistory={!!tableGraph.options.hasHistory}
					hasHistoryInitialMoment={!!tableGraph.options.hasHistoryInitialMoment}
					hasHistoryPresentMoment={!!tableGraph.options.hasHistoryPresentMoment}
					historyDates={tableGraph.options.historyDates || []}
					firstProgressionItem={viewItem.component.props.firstProgressionItem}
					progressionStep={viewItem.component.props.progressionStep}
					onChangeHistoryDate={setHistoryDate}
					showReplay={viewItem.component.props.showReplay || false}
					replayIntervalInSeconds={viewItem.component.props.replayIntervalInSeconds || 10}
					onReady={setGraph}
					onChangeReplay={setReplayInProgress}
					commonButtons={['bottom', 'left'].includes(align) ? commonButtons : undefined}
				/>
			) : (
				<p style={{textAlign: 'center'}}>Нет данных</p>
			)}
		</div>
	);

	const tableItems = (historyDate && tableGraph.itemsByTime.find(i => i.time === historyDate)?.items) || undefined;

	const Table = (
		<div className={cls(css.table, secondCss)} ref={tableRef} style={{height: secondHeight, width: secondWidth}}>
			{['top', 'right'].includes(align) && (
				<div className={viewComponentCss.filters}>
					{commonButtons?.RefreshAction}
					{commonButtons?.FilterAction}
				</div>
			)}
			{tableItems && tableItems.length > 0 ? (
				<DataGrid options={tableOptions} items={tableItems} />
			) : (
				<p style={{textAlign: 'center'}}>
					<b>Записей нет</b>
				</p>
			)}
		</div>
	);

	const Content = ['top', 'left'].includes(align) ? (
		<>
			{Table}
			{Graph}
		</>
	) : (
		<>
			{Graph}
			{Table}
		</>
	);

	return (
		<div className={cls(css.wrapper, css[align])} style={{height}} ref={wrapperRef}>
			{Content}
			{ResizeControl}
		</div>
	);
};

export default GraphTimeLineGridComponent;
