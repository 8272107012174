import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import css from './DashboardInputFile.module.css';
import uid from '../../../utils/uid';
import FilePreview from './FilePreview';
import InputFiles from '../InputFile/InputFiles';
import getImageDataUrl from '../../../utils/getImageDataUrl';
import {getFileExtension, getInputFilesSize, getFileType, imagesTypes} from '../../../utils/files';
import {FileValue, FieldValidation} from '../../../queries-generated/types';
import cls from '../../../utils/cls';
import usePrevious from '../../hooks/usePrevious';
import {formatBytes} from '../../../utils/number-utils';
import {notifyToastError} from '../../toast/Toast';
import {wordByCount} from '../../../utils/word-by-count';
import useUploadFiles from './useUploadFiles';

export type UploadedFile = {
	id: string;
	type: string;
	progress?: number;
	uploaded?: boolean;
	imageDataUrl?: string;
	file: File;
	chunks?: number;
	title: string;
	size: number;
};

interface InputInterface extends CommonInputInterface<FileValue[]> {
	forwardRef?: React.RefObject<HTMLInputElement>;
	validation?: FieldValidation;
}

export type InputProps = InputInterface & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const chunkLength = Number(process.env.REACT_APP_CHUNK_LENGTH);

const DashboardInputFiles: React.FC<InputProps> = props => {
	const {
		error,
		disabled,
		className,
		children,
		helperText,
		onChange,
		value: propsValue,
		defaultValue,
		forwardRef: propsForwardRef,
		validation,
		max,
		...inputProps
	} = props;

	const {setFiles, handleUploadFile, uploadingFiles, handleDelete, value, setValue} = useUploadFiles(
		propsValue || defaultValue || [],
	);

	const prevPropsValue = usePrevious(propsValue);

	useEffect(() => {
		const effectPropsValue = propsValue || [];
		const effectPrevPropsValue = prevPropsValue || [];

		if (effectPropsValue.length !== value.length) {
			// Поменялись props
			if (effectPropsValue.length !== effectPrevPropsValue.length) {
				setValue(effectPropsValue);
				// Поменялся state
			} else {
				onChange(value);
			}
		}
	}, [value, propsValue, prevPropsValue]);

	const filesLen = useMemo(() => uploadingFiles.length + (value?.length || 0), [uploadingFiles, value?.length]);
	const filesSize = useMemo(() => {
		const uploadingFilesSize = uploadingFiles.reduce((filesSize, file) => (filesSize += file.size), 0);
		const uploadedFilesSize = value.reduce((filesSize, file) => (filesSize += file.size), 0);

		return uploadingFilesSize + uploadedFilesSize;
	}, [uploadingFiles, value]);

	const handleUploadFiles = useCallback(
		async (files: FileList) => {
			if (max != null && files.length + filesLen > max) {
				notifyToastError(`Можно загружать не более ${wordByCount(max, ['файла', 'файлов', 'файлов'], true)}`);
				return;
			}

			if (validation?.maxFilesSize && filesSize + getInputFilesSize(files) > validation.maxFilesSize) {
				notifyToastError(
					`Общий размер загружаемых файлов не должен превышать ${formatBytes(validation.maxFilesSize)}`,
				);
				return;
			}

			// @ts-ignore
			for (const file of files) {
				if (validation?.maxFileSize && file.size > validation.maxFileSize) {
					notifyToastError(`Размер файла ${file.name} превышает ${formatBytes(validation.maxFileSize)}`);
					continue;
				}

				const ext = getFileExtension(file.name);
				if (validation?.allowedExtensions && !validation.allowedExtensions.includes(ext)) {
					notifyToastError(
						`Для загрузки доступны файлы следующих расширений: ${validation.allowedExtensions.join(', ')}`,
					);
					continue;
				}

				const id = uid() + '';
				let imageDataUrl: string | undefined;
				if (imagesTypes.includes(file.type)) {
					imageDataUrl = await getImageDataUrl(file);
				}

				setFiles(current => ({
					...current,
					[id]: {
						id,
						type: getFileType(file.name),
						imageDataUrl,
						file,
						title: file.name,
						size: file.size,
						chunks: Math.ceil(file.size / Number(chunkLength)),
					},
				}));

				handleUploadFile(file, id);
			}
		},
		[handleUploadFile, filesLen, max, validation, setFiles, filesSize],
	);

	const [dropHighlight, setDropHighlight] = useState(false);

	const handleDragEnter = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		setDropHighlight(true);
	}, []);
	const handleDragOver = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		setDropHighlight(true);
	}, []);

	const handleDragLeave = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		setDropHighlight(false);
	}, []);

	const handleDrop = useCallback(
		(event: React.DragEvent) => {
			if (event.dataTransfer.files) {
				handleUploadFiles(event.dataTransfer.files);
			}
			event.preventDefault();
			setDropHighlight(false);
		},
		[handleUploadFiles],
	);

	return (
		<div
			className={cls(css.wrapper, dropHighlight && css.dropHighlight)}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			<InputFiles {...inputProps} onChange={handleUploadFiles} />
			<div className={css.preview}>
				{value?.map(file => (
					<FilePreview key={file.id} file={file} onDelete={handleDelete} />
				))}

				{uploadingFiles.map(uploadedFile => (
					<FilePreview key={uploadedFile.id} file={uploadedFile} />
				))}
			</div>
			<div className={css.dragEnterPlaceholder}>Перетащите файлы в эту область для загрузки</div>
		</div>
	);
};

export default DashboardInputFiles;
