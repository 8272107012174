import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {
	DataSourceNotification,
	FieldShownType,
	FieldType,
	Grid,
	useGridQuery,
} from '../../../../../queries-generated/types';
import css from './GridComponent.module.css';
import usePrevious from '../../../../hooks/usePrevious';
import {useComponentFilters} from '../../../../view/api/useUserFilters';
import {filtersToDataSourceParams, isNotifiedDataSource} from '../helper';
import DataGridPagination from '../../../../DataGrid/DataGridPagination';
import ErrorSnippet from '../../../../ErrorSnippet/ErrorSnippet';
import {NetworkStatus} from '@apollo/client';
import DataGrid, {DataGridOptions} from '../../../../DataGrid/DataGrid';
import {notifyDataSource} from '../../../../toast/DataSourceToast';
import {DataSourceContext} from 'components/view/ViewWrapper';
import useDataGridOptions from './useDataGridOptions';
import {useComponentsStoredData} from '../../../../view/ViewWrapperWithContext';
import useComponentCommonButtons from '../../../../view/useComponentCommonButtons';
import DataGridHeadPanel from '../../../../DataGrid/DataGridHeadPanel';
import {defaultOnCellRender, onCellRenderWithDataSource} from '../../../../DataGrid/onCellRender';
import onGridActionRender from '../../../../DataGrid/onGridActionRender';

export type TableComponentProps = {
	viewItem: ViewItem;
	skipQuery: boolean;
	title: string;
	height?: number;
	pollInterval?: number;
	theme?: 'body' | 'neutral';
};

export const defaultTableData: Grid = {
	fields: [
		{id: 'id', title: 'id', type: FieldType.String},
		{
			id: '1',
			title: 'Колонка 1',
			type: FieldType.String,
			linkOptions: {route: 'view', params: {id: '{id}'}},
			visible: true,
			minWidth: 70,
			maxWidth: 100,
		},
		{
			id: 'enabled',
			title: 'Включено?',
			visible: true,
			type: FieldType.Boolean,
			showType: FieldShownType.Toggle,
			width: 100,
		},
		{
			id: '2',
			title: 'Колонка 2',
			type: FieldType.String,
			visible: true,
			defaultSort: true,
			isGroupField: true,
			maxWidth: 150,
		},
		{
			id: '3',
			title: 'Колонка 3',
			type: FieldType.Structure,
			showType: FieldShownType.Link,
			visible: true,
		},
		{
			id: 'detail',
			title: 'Колонка detail',
			type: FieldType.String,
			detail: true,
		},
	],
	options: {
		total: 3,
		searchable: true,
		selectable: true,
	},
	items: [
		{
			id: '1',
			'1': 'Запись 1',
			'2': 'test 2',
			'3': {title: 'Ссылка 1', url: 'http://ya.ru'},
			enabled: false,
			detail: 'Текст 1',
		},
		{
			id: '2',
			'1': 'Запись 4',
			'2': 'test 1',
			'3': {title: 'Ссылка 2', route: 'view', params: {id: 76}},
			enabled: true,
			detail: 'Текст 2',
		},
		{
			id: '3',
			'1': 'Запись 7',
			'2': 'test 2',
			'3': {title: 'Ссылка 3', url: 'http://ya.ru', target: '_blank'},
			enabled: false,
			detail: 'Текст 3',
		},
		{
			id: '4',
			'1': 'Запись 7',
			'2': 'test 1',
			'3': {title: 'Ссылка 3', url: 'http://ya.ru', target: '_blank'},
			enabled: false,
			detail: 'Текст 4',
		},
		{
			id: '5',
			'1': 'Запись 7',
			'2': 'test 1',
			'3': {title: 'Ссылка 3', url: 'http://ya.ru', target: '_blank'},
			enabled: false,
			detail: 'Текст 5',
		},
	],
};

const GridComponent: React.FC<TableComponentProps> = ({viewItem, skipQuery, title, theme, height, pollInterval}) => {
	const {reloadCounter} = useContext(DataSourceContext);
	const prevReloadCounter = usePrevious(reloadCounter);

	const {
		dataSource,
		component: {props},
	} = viewItem;

	const {filters, waiting} = useComponentFilters(viewItem);
	const {setData, setProp} = useComponentsStoredData(viewItem.id);

	const {
		page,
		firstDefaultSort,
		itemsPerPage,
		sortOrder,
		sort,
		onChangeSort,
		onChangeItemsPerPage,
		onChangePage,
		total,
		setGridData,
		firstItemsPerPage,
		fields: storedFields,
		options: storedOptions,
	} = useDataGridOptions({
		defaultNumberOfRows: props.defaultNumberOfRows,
		viewItemId: viewItem.id,
	});

	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [searchQuery, setSearchQuery] = useState('');

	const handleChangeSearchQuery = useCallback((query: string) => {
		setSearchQuery(query);
		setProp('searchQuery', query);
	}, []);

	if (filters) {
		if (sort) filters.sort = `${sortOrder ? '+' : '-'}${sort}`;
		if (itemsPerPage && (itemsPerPage !== firstItemsPerPage || firstItemsPerPage === props.defaultNumberOfRows)) {
			filters.startIndex = page * itemsPerPage;
			filters.endIndex = page * itemsPerPage + itemsPerPage;
		}

		if (searchQuery) filters.searchQuery = searchQuery;
	}

	const {data, loading, refetch, error, networkStatus} = useGridQuery({
		variables: {
			dataSourceId: dataSource?.type || '',
			filters: filtersToDataSourceParams(filters, dataSource?.columns),
		},
		skip: skipQuery || waiting,
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		notifyOnNetworkStatusChange: true,
		pollInterval,
		onCompleted: result => {
			const dataSourceContentTable = result?.grid;
			if (dataSourceContentTable) {
				setGridData({fields: dataSourceContentTable.fields, options: dataSourceContentTable.options});
				if (isNotifiedDataSource(viewItem.actions))
					notifyDataSource(dataSourceContentTable.notifications as DataSourceNotification[]);
			}
		},
	});

	useEffect(() => {
		const table = skipQuery ? defaultTableData : data?.grid;
		setData({...viewItem.dataSource, data: table});
	}, [defaultTableData, data, skipQuery, viewItem]);

	const table = skipQuery ? defaultTableData : data?.grid;

	const fields = storedFields || table?.fields;
	const options = storedOptions || table?.options;

	useEffect(() => {
		if (prevReloadCounter !== undefined && reloadCounter !== prevReloadCounter && !skipQuery) {
			refetch();
		}
	}, [reloadCounter, skipQuery, prevReloadCounter]);

	const gridOptions: DataGridOptions = useMemo(() => {
		return {
			sort: sort || firstDefaultSort,
			onChangeSort: onChangeSort,
			sortOrder: sortOrder,
			fields,
			selectable: options?.selectable,
			selectedIds,
			onSelect: setSelectedIds,
			onCellRender: dataSource
				? onCellRenderWithDataSource(dataSource.type, fields, options?.forms, refetch)
				: defaultOnCellRender,
			onActionRender: onGridActionRender(dataSource?.type || '', fields, options?.forms, refetch),
			theme,
		};
	}, [sort, firstDefaultSort, onChangeSort, sortOrder, options, selectedIds, fields, theme, dataSource?.type]);

	const commonButtons = useComponentCommonButtons(viewItem);

	return (
		<div className={css.wrapper} style={{height}}>
			{error ? (
				<ErrorSnippet error={error} errorHeader={'Ошибка загрузки данных для таблицы'} refetch={refetch} />
			) : (
				<>
					<div className={css.title}>{title}</div>
					<DataGridHeadPanel
						actionButtons={options?.actions?.filter(
							action =>
								((action.itemAction && action.details) || !action.itemAction) && action.headAction,
						)}
						commonButtons={commonButtons}
						reLoad={refetch}
						loading={loading}
						minLengthSearchQuery={options?.minLengthSearchQuery}
						searchable={options?.searchable}
						onChangeSearchQuery={handleChangeSearchQuery}
						selectedIds={selectedIds}
						onActionButtonRender={onGridActionRender(
							dataSource?.type || '',
							fields || [],
							options?.forms,
							refetch,
						)}
					/>
					<DataGrid
						options={gridOptions}
						actionButtons={options?.actions?.filter(action => action.itemAction)}
						items={table?.items}
						loading={loading && networkStatus !== NetworkStatus.poll}
					/>
					<DataGridPagination
						onChangePage={onChangePage}
						onChangeItemsPerPage={onChangeItemsPerPage}
						itemsPerPage={itemsPerPage}
						page={page}
						total={total}
					/>
				</>
			)}
		</div>
	);
};

export default GridComponent;
