import React from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import Checkbox from './Checkbox';

interface Props extends CommonInputInterface<Array<string>> {
	/* маленький размер */
	indeterminate?: boolean;
	arrayValue: string | number;
}

export type CheckboxProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof Props> & Props;

const CheckboxArray: React.FC<CheckboxProps> = inputProps => {
	const {onChange, value, arrayValue, ...props} = inputProps;

	function handleChange() {
		const values = [...((value as any) as Array<string | number>)];
		const index = values.indexOf(arrayValue);
		if (index === -1) {
			values.push(arrayValue);
		} else {
			values.splice(index, 1);
		}
		onChange(values as any);
	}

	const checked = (value as any).includes(arrayValue);
	const defaultValue = props.defaultValue && (props.defaultValue as any).includes(arrayValue);

	return <Checkbox {...props} defaultValue={defaultValue} checked={checked} onChange={handleChange} />;
};

export default CheckboxArray;
