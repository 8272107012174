import React from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import ChartWrapperWithData from '../ChartsBase/ChartWrapperWithData';
import {ChartTypes} from '../ChartsBase/ChartWrapper';
import {defaultData} from './LineChart';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	pollInterval?: number;
};

const StackedChart: React.FC<Props> = ({viewItem, skipQuery, pollInterval}) => {
	return (
		<ChartWrapperWithData
			pollInterval={pollInterval}
			viewItem={viewItem}
			skipQuery={skipQuery}
			defaultData={defaultData}
			mapper={diagram => diagram}
			type={ChartTypes.Column}
		/>
	);
};

export default StackedChart;
