import {InMemoryCache, defaultDataIdFromObject, ApolloClientOptions, ApolloLink, HttpLink} from '@apollo/client';
import {uniqBy} from '../utils/array-utils';
import fetchWithProgress from '../utils/fetchWithProgress';
import {onError} from '@apollo/client/link/error';
import {createTraceId} from '../utils/createTraceId';

export type ApolloConfig = Omit<ApolloClientOptions<any>, 'cache'> & {createCache: any};

export default (): ApolloConfig => {
	let link: ApolloLink = new HttpLink({
		fetch: fetchWithProgress,
		uri: '/graphql',
		credentials: 'same-origin',
		fetchOptions: {
			credentials: 'same-origin',
		},
	});

	const authMiddleware = new ApolloLink((operation, forward) => {
		// add the authorization to the headers

		operation.setContext(({headers = {}}) => ({
			headers: {
				...headers,
				'x-traceid': createTraceId(operation.operationName),
			},
		}));

		return forward(operation);
	});

	const errorLink = onError(e => {
		const {graphQLErrors, networkError, operation} = e;

		const context = operation.getContext();
		const headers = context && context.headers;
		if (headers) {
			const traceId = headers['x-traceid'];

			if (graphQLErrors) {
				graphQLErrors.forEach(e => {
					e.traceId = traceId;
				});
			}
			if (networkError) {
				networkError.traceId = traceId;
			}
		}
	});
	link = errorLink.concat(authMiddleware).concat(link);

	return {
		link,
		createCache() {
			return new InMemoryCache({
				typePolicies: {
					Query: {
						fields: {
							notificationList: {
								keyArgs: ['checked'],
							},
							newUnreadNotifications: {
								keyArgs: [],
								merge(existing: any[] = [], incoming: any[]) {
									return uniqBy([...incoming, ...existing], '__ref');
								},
							},
						},
					},
					CommonFiltersPreset: {
						// Составной уникальный ключ для кэширования пресета
						keyFields: ['id', 'dashboardId', 'version'],
					},
					// todo Убрать когда бэк уникализирует id, задача TEST-975
					ViewComponentProp: {
						keyFields: false,
					},
					DataSource: {
						keyFields: false,
					},
					// Не имеет уникальных полей для кэширования
					PossibleValue: {
						keyFields: false,
					},
					// Отключил кэширование так как конфигурируемые колонки для датасорса не имеют уникальных id
					AvailableColumn: {
						keyFields: false,
					},
					// Не имеет уникальных полей для кэширования, ключ id практически всегда равен "id"
					ModelPossibleValueMapper: {
						keyFields: false,
					},
					// Не имеет уникальных полей для кэширования
					ModelPossibleValue: {
						keyFields: false,
					},
					PossibleValueMapper: {
						keyFields: false,
					},
					NotificationsData: {
						fields: {
							notifications: {
								merge(existing: any[] = [], incoming: any[], {readField}) {
									return uniqBy([...incoming, ...existing], '__ref').sort(
										(a, b) =>
											parseInt(readField('id', b) || '', 10) -
											parseInt(readField('id', a) || '', 10),
									);
								},
							},
							hasNext: {
								merge(existing: boolean, incoming: boolean) {
									/*
									 * Чтобы запрос без аргументов не перезаписывал поле в том случае,
									 * когда все уведомления с учетом пагинации были загружены
									 * */
									if (existing === false) return false;
									return incoming;
								},
							},
						},
					},
				},
				dataIdFromObject: (object: any) => {
					switch (object.__typename) {
						// TODO: id должен быть в виде {model}.{columnName}
						case 'TableColumn':
						case 'TableInterfaceColumn':
						case 'ModelField':
						case 'Field':
							return object.id + JSON.stringify(Object.values(object));
						default: {
							if (object.guid) {
								return 'GUID:' + object.guid;
							}
							if (object.id) {
								return object.__typename + ':' + object.id;
							}
							if (object.code) {
								return object.__typename + ':' + object.code;
							}
							return defaultDataIdFromObject(object); // fall back to default handling
						}
					}
				},
			});
		},
	};
};
