import React, {useEffect} from 'react';

type MaybeRef = React.RefObject<any> | null | undefined;

function useOnClickOutside(ref: MaybeRef | MaybeRef[] | false, handler?: (e) => void, area?: HTMLElement | MaybeRef) {
	useEffect(() => {
		if (!handler) return;
		const listener = event => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref) return;
			const refs: MaybeRef[] = Array.isArray(ref) ? ref : [ref];
			if (
				refs.some(ref => {
					if (!ref || !ref.current) return false;
					if (ref.current.contains(event.target)) return true;
				})
			)
				return;
			handler(event);
		};

		const element = !area ? document : area instanceof HTMLElement ? area : area.current;

		if (!element) return;

		element.addEventListener('mousedown', listener);
		element.addEventListener('touchstart', listener);

		return () => {
			element.removeEventListener('mousedown', listener);
			element.removeEventListener('touchstart', listener);
		};
	}, [ref, handler, area]);
}

export default useOnClickOutside;
