import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import {CalendarIcon} from '../../SvgIcon';
import {format, formatDateIso, parse} from 'ts-date/locale/ru';
import Calendar, {Value} from '../Calendar/Calendar';
import Popover from '../../controlls/PopoverButton/Popover';
import MaskedInput from '../MaskedInput';
import useOnClickOutside from 'components/hooks/useOnClickOutside';
import usePrevious from '../../hooks/usePrevious';
import fieldCss from '../Field/Field.module.css';
import Button from '../Button/Button';

interface InputInterface extends CommonInputInterface<string | null> {
	setRef?: React.RefObject<HTMLInputElement>;
	withoutTime?: boolean;
	value: string | null;
}

export type InputProps = InputInterface & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

export const DATE_TEMPLATE = 'DD.MM.YYYY';
export const DATE_TIME_TEMPLATE = 'DD.MM.YYYY HH:mm';
export const DATE_MASK = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
export const DATE_TIME_MASK = [...DATE_MASK, ' ', /\d/, /\d/, ':', /\d/, /\d/];
export const DATE_PLACEHOLDER = 'дд.мм.гггг';
export const DATE_TIME_PLACEHOLDER = 'дд.мм.гггг чч:мм';

export const CURRENT_DATE_CONST = 'CURRENT_DATE';

const InputDateTime: React.FC<InputProps> = props => {
	const {error, disabled, className, withoutTime, helperText, onChange: _, setRef, size, ...inputProps} = props;
	const {onChange, value} = props;
	const prevValue = usePrevious(value);
	const minDate = inputProps.min
		? inputProps.min === CURRENT_DATE_CONST
			? new Date()
			: new Date(inputProps.min as string)
		: undefined;
	const maxDate = inputProps.max
		? inputProps.max === CURRENT_DATE_CONST
			? new Date()
			: new Date(inputProps.max as string)
		: undefined;

	const timeNow = useMemo(() => {
		const now = new Date();
		const offset = now.getTimezoneOffset();
		const timezone = `${offset < 0 ? '+' : '-'}${Math.abs(offset / 60)
			.toString()
			.padStart(2, '0')}:${Math.abs(offset % 60)
			.toString()
			.padStart(2, '0')}`;
		return (
			now
				.toISOString()
				.substring(10)
				.replace('Z', '') + timezone
		);
	}, []);

	const date = value
		? new Date(
				withoutTime && (value as string | undefined)?.length === 10 ? `${value}${timeNow}` : (value as string),
		  )
		: null;

	const inputRef = useRef<HTMLInputElement>(null);
	const popoverRef = useRef<HTMLDivElement>(null);
	const iconRef = useRef<HTMLButtonElement>(null);

	const template = withoutTime ? DATE_TEMPLATE : DATE_TIME_TEMPLATE;

	const mask = withoutTime ? DATE_MASK : DATE_TIME_MASK;
	const maskPlaceholder = withoutTime ? DATE_PLACEHOLDER : DATE_TIME_PLACEHOLDER;

	const [fieldValue, setFieldValue] = useState(date ? format(date, template)! : '');
	useEffect(() => {
		if (value !== prevValue) {
			setFieldValue(date ? format(date, template)! : '');
		}
	}, [value, prevValue, date]);

	const handleFieldChange = useCallback(
		field => {
			if (!field) {
				onChange(null);
				setFieldValue('');
				return;
			}
			const parsedDate = parse(field, template);
			if (parsedDate) {
				onChange(withoutTime ? formatDateIso(parsedDate!) : parsedDate.toISOString());
				setFieldValue(format(parsedDate, template));
			} else {
				setFieldValue(field);
			}
		},
		[withoutTime, onChange],
	);

	const [openCalendar, setOpenCalendar] = useState(false);

	const handleOpenCalendar = useCallback(() => {
		setOpenCalendar(true);
		setTimeout(() => {
			if (inputRef.current && popoverRef.current && process.browser) {
				document.body.style.pointerEvents = 'none';
				inputRef.current.style.pointerEvents = 'all';
			}
		});
	}, [setOpenCalendar, inputRef]);

	const handleCloseCalendar = useCallback(() => {
		setOpenCalendar(false);
		if (process.browser) {
			document.body.style.pointerEvents = '';
		}
	}, [setOpenCalendar]);

	useEffect(() => {
		return () => {
			if (process.browser) {
				document.body.style.pointerEvents = '';
			}
		};
	}, []);

	const handleToggleCalendar = useCallback(() => {
		openCalendar ? handleCloseCalendar() : handleOpenCalendar();
	}, [handleOpenCalendar, handleCloseCalendar, openCalendar]);

	useOnClickOutside(openCalendar && [popoverRef, inputRef, iconRef], handleCloseCalendar);

	const handleCalendarChange = useCallback(
		(range: Value) => {
			onChange(withoutTime ? formatDateIso(range.from) : range.from!.toISOString());
			handleFieldChange(format(range.from, template));
		},
		[template, withoutTime, onChange],
	);

	const handleBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement, Element>) => {
			if (!e.relatedTarget || !popoverRef.current || !popoverRef.current.contains(e.relatedTarget)) {
				setFieldValue(format(date, template)!);
				handleCloseCalendar();
			}
		},
		[date, template],
	);

	return (
		<>
			<MaskedInput
				autoComplete={'off'}
				{...(inputProps as any)}
				error={error}
				onChange={handleFieldChange}
				setRef={inputRef}
				value={fieldValue}
				onFocus={handleOpenCalendar}
				onBlur={handleBlur}
				mask={mask}
				maskPlaceholder={maskPlaceholder}
				bottomText={undefined}
				buttons={
					<Button
						action
						iconOnly
						onClick={handleToggleCalendar}
						checked={openCalendar}
						className={fieldCss.actionButton}
						setRef={iconRef}
					>
						<CalendarIcon width={14} height={14} />
					</Button>
				}
			/>
			<Popover
				open={openCalendar}
				parentRef={inputRef}
				forwardRef={popoverRef}
				onClose={handleCloseCalendar}
				focusOnOpen
				returnFocusRef={inputRef}
			>
				<Calendar
					value={{from: date, to: null, duration: null}}
					onChange={handleCalendarChange}
					minDate={minDate}
					maxDate={maxDate}
					time={!withoutTime}
					width={250}
				/>
			</Popover>
		</>
	);
};

export default InputDateTime;
