import React from 'react';
import {useRouter} from 'react-named-hooks-router';
import MainLayout from '../../components/layouts/MainLayout';
import css from './ResourceAttachmentPage.module.css';
import ResourceAttachmentsTableContainer from './ResourceAttachmentsTable/ResourceAttachmentsTableContainer';
import useAccumulatedChangesWithPresentData from '../../components/ResourceRegisterAccumulatedChanges/ResourceRegisterAccumulatedChanges';

type Props = {};

const ResourceAttachmentPage: React.FC<Props> = () => {
	const {routeParams} = useRouter<{keyword: string; id: string; resourceName: string}>();
	const {reFetchSession, AccumulatedChanges} = useAccumulatedChangesWithPresentData();

	return (
		<MainLayout>
			<div className={css.header}>
				<div className={css.headerInner}>
					{AccumulatedChanges}
					<div className={css.resourceNameBlock}>
						<h2>Вложения для ресурса:</h2>
						<div className={css.resourceName}>{decodeURIComponent(routeParams.resourceName)}</div>
					</div>
				</div>
			</div>
			<div className={css.mainLayout}>
				<ResourceAttachmentsTableContainer reFetchSession={reFetchSession} />
			</div>
		</MainLayout>
	);
};

export default ResourceAttachmentPage;
