import React from 'react';
import css from './InputSearch.module.css';
import Input from '../Input/Input';
import cls from '../../../utils/cls';
import Button from '../Button/Button';
import {SearchIcon} from '../../SvgIcon';
import fieldCss from '../Field/Field.module.css';
import {useValue} from '../inputHooks';
import PopoverButton from '../../controlls/PopoverButton/PopoverButton';

type Props = {
	value?: string;
	onChange: (value: string) => void;
	className?: string;
	full?: boolean;
};

const InputSearch: React.FC<Props> = props => {
	const {className, full = true} = props;
	const {value, onChangeValue} = useValue(props);

	const input = (
		<Input
			value={value}
			className={cls(css.wrapper, className)}
			onChange={onChangeValue}
			placeholder={'Поиск'}
			autoFocus={true}
			buttons={
				<Button
					action
					iconOnly
					onClick={() => {}}
					className={cls(fieldCss.actionButton, fieldCss.inactiveActionButton)}
					tabIndex={-1}
				>
					<SearchIcon width={14} height={14} />
				</Button>
			}
		/>
	);

	return full ? (
		input
	) : (
		<PopoverButton popover={input} action iconOnly>
			<SearchIcon />
		</PopoverButton>
	);
};

export default InputSearch;
