import {useApolloClient} from '@apollo/client';
import {useCallback} from 'react';
import {
	DependencyFilter,
	RelationDataQuery,
	RelationDataQueryVariables,
	RelationDataDocument,
} from '../../../../../../queries-generated/types';

type Props = {
	model: string;
	minLength?: number;
	dependencyFilters?: DependencyFilter[];
	filters?: PlainObjectOf<any>;
};

const useRelationData = () => {
	const client = useApolloClient();

	return useCallback(({model, minLength = 1, dependencyFilters, filters}: Props) => {
		return (searchQuery: string) => {
			return new Promise<any[]>(resolve => {
				if (searchQuery.length >= minLength) {
					client
						.query<RelationDataQuery, RelationDataQueryVariables>({
							query: RelationDataDocument,
							variables: {
								model,
								filters: {...filters, searchQuery},
								dependencyFilters,
							},
						})
						.then(result => {
							resolve(result.data.relationData.items);
						});
				} else resolve([]);
			});
		};
	}, []);
};
export default useRelationData;
