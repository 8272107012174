import React, {useCallback} from 'react';
import css from './FullTableForm.module.css';
import Button from '../pirsInputs/Button/Button';
import {CheckIcon, CrossIcon} from '../SvgIcon';

type Props = {
	id?: string;
	onDelete: (id) => void;
	onClose: () => void;
};

const FullTableDeleteModal: React.FC<Props> = ({id, onClose, onDelete}) => {
	const handleDelete = useCallback(() => {
		onDelete(id);
		onClose();
	}, [id, onDelete]);

	return (
		<ul className={css.buttons}>
			<li>
				<Button onClick={handleDelete}>
					<CheckIcon />
					Да
				</Button>
			</li>
			<li>
				<Button onClick={onClose} secondary>
					<CrossIcon />
					Нет
				</Button>
			</li>
		</ul>
	);
};

export default FullTableDeleteModal;
