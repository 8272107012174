import {TableCell, TableHead, TableRow, TableSortLabel} from '@material-ui/core';
import * as React from 'react';
import {SortOrder, TableColumn, TableRelationType} from '../../queries-generated/types';
import css from './FullTableHead.module.css';
import CheckCircle from '../pirsInputs/CheckCircle/CheckCircle';
import {DeleteIcon} from 'components/SvgIcon';
import useDeleteConfirm from '../hooks/useDeleteConfirm/useDeleteConfirm';
import Button from '../pirsInputs/Button/Button';

type Props = {
	visibleColumns: TableColumn[];
	orderBy: string;
	order: SortOrder;
	onChange: (column: string) => void;
	onMassDelete: () => void;
	selectedItems: PlainObjectOf<any>[];
	onSelect?: () => void;
	selected?: boolean;
	hasActionButtons?: boolean;
	selectable?: boolean;
	massDeletable?: boolean;
	actionTableHeadCellCallback?: (selectedItems: PlainObjectOf<any>[]) => React.ReactNode;
};

const FullTableHead: React.FC<Props> = ({
	order,
	orderBy,
	onChange,
	selected,
	onSelect,
	selectable,
	massDeletable,
	selectedItems,
	onMassDelete,
	visibleColumns,
	hasActionButtons,
	actionTableHeadCellCallback,
}) => {
	const {Confirm, openConfirm} = useDeleteConfirm(
		{
			callback: onMassDelete,
			text: 'Вы уверены, что хотите удалить выбранные объекты?',
		},
		[selectedItems],
	);

	return (
		<TableHead>
			{Confirm}
			<TableRow>
				{hasActionButtons && visibleColumns.length > 0 && (
					<TableCell padding={'checkbox'} style={{padding: '0 0 0 16px'}}>
						<div className={css.actionCell}>
							{actionTableHeadCellCallback && actionTableHeadCellCallback(selectedItems || [])}
							{massDeletable && (
								<Button
									onClick={openConfirm}
									title={'Удалить выбранные элементы'}
									disabled={selectedItems.length === 0}
									iconOnly
									action
								>
									<DeleteIcon />
								</Button>
							)}
							{selectable && onSelect && (
								<div className={css.select}>
									<CheckCircle onSelect={onSelect} selected={selected} />
								</div>
							)}
						</div>
					</TableCell>
				)}
				{[...visibleColumns]
					.sort((a, b) => (a.order || 0) - (b.order || 0))
					.map(column => {
						const columnId =
							column.relation?.type === TableRelationType.BelongsTo ? column.id + '_id' : column.id;
						return (
							<TableCell key={columnId} className={css.cell}>
								{column.canSort ? (
									<TableSortLabel
										active={orderBy === columnId}
										direction={order === SortOrder.Asc ? 'asc' : 'desc'}
										onClick={() => onChange(columnId)}
									>
										{column.title}
									</TableSortLabel>
								) : (
									column.title
								)}
							</TableCell>
						);
					})}
			</TableRow>
		</TableHead>
	);
};

export default FullTableHead;
