import React from 'react';
import cls from '../../../../../utils/cls';
import css from '../../../../view/ViewComponent.module.css';

type Props = React.HTMLAttributes<HTMLDivElement>;

const HorizontalBlock: React.FC<Props> = props => {
	const {children, className, ...otherProps} = props;
	const classNames = className ? className : cls(css.horizontalBlock, css.component);

	return (
		<div className={classNames} {...otherProps}>
			{children}
		</div>
	);
};

export default HorizontalBlock;
