import React, {useCallback} from 'react';
import {GraphPopoverContent} from './graphHelper';
import {useBoolean} from '../../../../hooks/useBoolean';
import Button from '../../../../pirsInputs/Button/Button';
import ModalPopup from '../../../../controlls/ModalPopup/ModalPopup';
import {useCreateModelMutation, useModelItemResolveQuery} from '../../../../../queries-generated/types';
import {notifyToastError, notifyToastSuccess} from '../../../../toast/Toast';
import ProgressRing from '../../../../controlls/Loader/ProgressRing';
import FullTableForm from '../../../../FullTable/FullTableForm';
import ErrorSnippet from '../../../../ErrorSnippet/ErrorSnippet';

type Props = {
	content: GraphPopoverContent;
};

type FormProps = Props & {
	onClose: () => void;
};

const Form: React.FC<FormProps> = ({content, onClose}) => {
	const model = content.createModelPopover!.model;
	const item = content.createModelPopover?.initialValues || {};

	const {
		data: resolveItemData,
		loading: resolveItemLoading,
		error: resolveItemError,
		refetch: resolveItemRefetch,
	} = useModelItemResolveQuery({variables: {model, item}});
	const [createModel, {loading: createModelLoading}] = useCreateModelMutation();

	const handleCreateModel = useCallback(
		(item: any) => {
			createModel({variables: {model, items: [item]}})
				.then(() => {
					onClose();
					notifyToastSuccess('Запись успешно создана');
				})
				.catch(error => {
					notifyToastError(error);
				});
		},
		[createModel, onClose, model],
	);

	return (
		<>
			{resolveItemLoading ? (
				<ProgressRing />
			) : resolveItemError ? (
				<ErrorSnippet error={resolveItemError} refetch={resolveItemRefetch} />
			) : (
				<FullTableForm
					submitFormLoading={createModelLoading}
					onClose={onClose}
					allColumns={resolveItemData?.modelItemResolve.columns || []}
					onChange={handleCreateModel}
					initialValues={resolveItemData?.modelItemResolve.item}
				/>
			)}
		</>
	);
};

const CreateModelForm: React.FC<Props> = ({content}) => {
	const [isOpenForm, {setTrue, setFalse}] = useBoolean(false);

	return (
		<>
			<ModalPopup open={isOpenForm} onClose={setFalse} header={content.title} maxWidth={400}>
				<Form onClose={setFalse} content={content} />
			</ModalPopup>
			<Button onClick={setTrue} link>
				{content.title}
			</Button>
		</>
	);
};

export default CreateModelForm;
