exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".SurveyResourceAsPage-module__container__12EZ- {\r\n\tbackground-color: var(--neutral-fill-rest);\r\n}\r\n\r\n.SurveyResourceAsPage-module__headerDataWrapper__6e8lA {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n\tflex-wrap: nowrap;\r\n\tpadding: 20px 24px 0 24px;\r\n}\r\n\r\n.SurveyResourceAsPage-module__stackedHeaderItems__11DAb {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tflex-wrap: nowrap;\r\n\tjustify-content: flex-start;\r\n\talign-items: center;\r\n}\r\n\r\n.SurveyResourceAsPage-module__combobox__1oU35 {\r\n\twidth: 300px;\r\n\tmargin-right: 10px;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\pages\\SurveyResourceAsPage\\SurveyResourceAsPage.module.css"],"names":[],"mappings":"AAAA;CACC,0CAA0C;AAC3C;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,mBAAmB;CACnB,iBAAiB;CACjB,yBAAyB;AAC1B;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,iBAAiB;CACjB,2BAA2B;CAC3B,mBAAmB;AACpB;;AAEA;CACC,YAAY;CACZ,kBAAkB;AACnB","file":"SurveyResourceAsPage.module.css","sourcesContent":[".container {\r\n\tbackground-color: var(--neutral-fill-rest);\r\n}\r\n\r\n.headerDataWrapper {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n\tflex-wrap: nowrap;\r\n\tpadding: 20px 24px 0 24px;\r\n}\r\n\r\n.stackedHeaderItems {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tflex-wrap: nowrap;\r\n\tjustify-content: flex-start;\r\n\talign-items: center;\r\n}\r\n\r\n.combobox {\r\n\twidth: 300px;\r\n\tmargin-right: 10px;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"container": "SurveyResourceAsPage-module__container__12EZ-",
	"headerDataWrapper": "SurveyResourceAsPage-module__headerDataWrapper__6e8lA",
	"stackedHeaderItems": "SurveyResourceAsPage-module__stackedHeaderItems__11DAb",
	"combobox": "SurveyResourceAsPage-module__combobox__1oU35"
};