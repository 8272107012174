import {addDate, addHours, addMinutes, addMonth, addSeconds, addYear} from 'ts-date/locale/ru';

type DurationUnit = 'weeks' | 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds';

type DurationObj = {
	[K in DurationUnit]: number;
};

const numbers = '\\d+(?:[\\.,]\\d+)?';
const weekPattern = `(${numbers}W)`;
const datePattern = `(${numbers}Y)?(${numbers}M)?(${numbers}D)?`;
const timePattern = `T(${numbers}H)?(${numbers}M)?(${numbers}S)?`;

const pattern = new RegExp(`^P(?:${weekPattern}|${datePattern}(?:${timePattern})?)$`);

const durationKeys: DurationUnit[] = ['weeks', 'years', 'months', 'days', 'hours', 'minutes', 'seconds'];

export const parseIsoDuration = (durationString: string): DurationObj => {
	const durationMatchedPattern = durationString.match(pattern);
	if (!durationMatchedPattern) {
		throw new Error('Invalid duration');
	}

	const parsed = durationMatchedPattern.slice(1).reduce((prev: Partial<DurationObj>, next, i) => {
		prev[durationKeys[i]] = parseFloat(next) || 0;
		return prev;
	}, {});

	return parsed as DurationObj;
};

export const addDuration = (date: Date, durationString: DurationIso): Date | null => {
	const duration = parseIsoDuration(durationString);
	let newDate: Date = date;

	for (const unit of Object.keys(duration)) {
		const value = duration[unit];
		switch (unit) {
			case 'weeks':
				newDate = addDate(newDate, value * 7);
				break;
			case 'years':
				newDate = addYear(newDate, value);
				break;
			case 'months':
				newDate = addMonth(newDate, value);
				break;
			case 'days':
				newDate = addDate(newDate, value);
				break;
			case 'hours':
				newDate = addHours(newDate, value);
				break;
			case 'minutes':
				newDate = addMinutes(newDate, value);
				break;
			case 'seconds':
				newDate = addSeconds(newDate, value);
				break;
		}
	}

	return newDate;
};
