exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".RequestedColumnList-module__checkboxes__21N2V {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tjustify-content: flex-start;\r\n\tgap: 5px;\r\n\tpadding-top: 5px;\r\n}\r\n\r\n.RequestedColumnList-module__cardWrapper__2FwYk {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tgap: 5px;\r\n}\r\n\r\n.RequestedColumnList-module__card__28-_I {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tflex-wrap: nowrap;\r\n\talign-items: center;\r\n\tpadding: 10px;\r\n\tgap: 5px;\r\n}\r\n\r\n.RequestedColumnList-module__swapIcon__3CWIQ {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\theight: 70px;\r\n}\r\n\r\n.RequestedColumnList-module__closeButton__2PF-q {\r\n\tmargin-top: 10px;\r\n\tmargin-left: auto;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\settings\\views\\ViewEdit\\constructor\\form\\DataSourceForm\\RequestedColumnList.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,2BAA2B;CAC3B,QAAQ;CACR,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,QAAQ;AACT;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,iBAAiB;CACjB,mBAAmB;CACnB,aAAa;CACb,QAAQ;AACT;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;AAClB","file":"RequestedColumnList.module.css","sourcesContent":[".checkboxes {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tjustify-content: flex-start;\r\n\tgap: 5px;\r\n\tpadding-top: 5px;\r\n}\r\n\r\n.cardWrapper {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tgap: 5px;\r\n}\r\n\r\n.card {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tflex-wrap: nowrap;\r\n\talign-items: center;\r\n\tpadding: 10px;\r\n\tgap: 5px;\r\n}\r\n\r\n.swapIcon {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\theight: 70px;\r\n}\r\n\r\n.closeButton {\r\n\tmargin-top: 10px;\r\n\tmargin-left: auto;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"checkboxes": "RequestedColumnList-module__checkboxes__21N2V",
	"cardWrapper": "RequestedColumnList-module__cardWrapper__2FwYk",
	"card": "RequestedColumnList-module__card__28-_I",
	"swapIcon": "RequestedColumnList-module__swapIcon__3CWIQ",
	"closeButton": "RequestedColumnList-module__closeButton__2PF-q"
};