import React, {CSSProperties, useCallback} from 'react';
import css from './CheckCircle.module.css';
import {CheckIcon} from '../../SvgIcon';
import cls from '../../../utils/cls';

type Props = {
	selected?: boolean;
	onSelect: (selected: boolean) => void;
	style?: CSSProperties;
	className?: string;
};

const CheckCircle: React.FC<Props> = ({selected, onSelect, style, className}) => {
	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			onSelect(!selected);
		},
		[selected, onSelect],
	);
	return (
		<div>
			<div
				onClick={handleClick}
				className={cls(css.wrapper, selected && css.selected, className)}
				style={style}
				role="checkbox"
				aria-checked={selected ? 'true' : 'false'}
			>
				<CheckIcon width={10} height={10} />
			</div>
		</div>
	);
};

export default CheckCircle;
