import React, {useMemo, useState, useCallback} from 'react';
import ModalPopup from '../../controlls/ModalPopup/ModalPopup';
import Button from '../Button/Button';
import {CrossIcon} from '../../SvgIcon';
import DataGrid, {DataGridOptions} from '../../DataGrid/DataGrid';
import {onCellRenderWithDataSource} from '../../DataGrid/onCellRender';
import onGridActionRender from '../../DataGrid/onGridActionRender';
import useDataGridOptions from '../../settings/views/components/GridComponent/useDataGridOptions';
import DataGridHeadPanel from '../../DataGrid/DataGridHeadPanel';
import DataGridPagination from '../../DataGrid/DataGridPagination';
import ErrorSnippet from '../../ErrorSnippet/ErrorSnippet';
import {useGridQuery} from '../../../queries-generated/types';
import css from './ContactPicker.module.css';
import {DataGridTree} from '../../DataGrid/useDataGridSelection';
import {addOrDeleteToArray, arrayDiff, arrayDiffSymmetric} from '../../../utils/array-utils';
import {wordByCount} from '../../../utils/word-by-count';

export const defaultNumberOfRows = 50;

type Props = {
	onClose: () => void;
	setTree: (tree: DataGridTree) => void;
	directiveId: string;
	tree: DataGridTree;
};

const dataSourceId = '1a59a6f6-62c0-4e5d-b175-7ca82b7b4627';

const SelectedContacts: React.FC<Props> = ({onClose, directiveId, tree, setTree}) => {
	const {
		setGridData,
		onChangePage,
		onChangeSort,
		onChangeItemsPerPage,
		total,
		firstDefaultSort,
		options,
		fields,
		page,
		sort,
		sortOrder,
		itemsPerPage,
		firstItemsPerPage,
	} = useDataGridOptions({
		defaultNumberOfRows,
	});
	const [searchQuery, setSearchQuery] = useState('');

	const filters: PlainObjectOf<any> = {directiveId, tree};
	if (sort) filters.sort = `${sortOrder ? '+' : '-'}${sort}`;
	if (itemsPerPage && (itemsPerPage !== firstItemsPerPage || firstItemsPerPage === defaultNumberOfRows)) {
		filters.startIndex = page * itemsPerPage;
		filters.endIndex = page * itemsPerPage + itemsPerPage;
	}
	if (searchQuery) filters.searchQuery = searchQuery;

	const [unSelectedItems, setUnSelectedItems] = useState<any[]>([]);

	const {data, loading, refetch, error} = useGridQuery({
		variables: {
			dataSourceId: dataSourceId,
			filters,
		},
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		notifyOnNetworkStatusChange: true,
		onCompleted: result => {
			const dataSourceContentTable = result?.grid;
			if (dataSourceContentTable) {
				setGridData({fields: dataSourceContentTable.fields, options: dataSourceContentTable.options});
			}
		},
	});

	const pageIds = useMemo(() => {
		return data?.grid.items.map(item => item.id) || [];
	}, [data?.grid]);

	const unSelectedIds = useMemo(() => {
		return unSelectedItems.map(item => item.id);
	}, [unSelectedItems]);

	const selectedPageIds = useMemo(() => {
		return arrayDiff(pageIds, unSelectedIds);
	}, [pageIds, unSelectedIds]);

	const handleSelect = useCallback(
		(ids: string[]) => {
			if (data?.grid.items) {
				const diff = arrayDiffSymmetric(ids, selectedPageIds).map(id =>
					data.grid.items.find(item => item.id === id),
				);
				setUnSelectedItems(unselectedItems => addOrDeleteToArray(unselectedItems, diff, item => item.id));
			}
		},
		[data?.grid.items, selectedPageIds],
	);

	const gridOptions: DataGridOptions = useMemo(() => {
		return {
			sort: sort || firstDefaultSort,
			onChangeSort,
			sortOrder,
			fields,
			selectable: true,
			onCellRender: onCellRenderWithDataSource(dataSourceId, fields, [], refetch),
			theme: 'neutral',
			// Не давать "выбрать все" если есть фильтр поиска, так как нет возможности обработать этот кейс
			total: loading ? 0 : total,
			selectedIds: selectedPageIds,
			onSelect: handleSelect,
		};
	}, [
		sort,
		selectedPageIds,
		handleSelect,
		firstDefaultSort,
		loading,
		onChangeSort,
		options,
		fields,
		total,
		sortOrder,
		refetch,
		searchQuery,
	]);

	const nextTree = (tree: DataGridTree, unSelectedItems: any[], path: string) => {
		let ids = unSelectedItems.filter(item => item.uuidpath === path).map(item => item.id);
		if (tree.include) {
			tree.include = arrayDiff(tree.include!, ids);
			tree.totalSelected = tree.include.length;
		}

		if (tree.exclude) {
			tree.exclude = [...tree.exclude, ...ids];
			tree.totalSelected = (tree.total || 0) - tree.exclude.length;
		}

		if (tree.children) {
			const result = tree.children.map(child => nextTree(child, unSelectedItems, `${path}/${tree.id}`));
			result.forEach(r => (ids = [...ids, r.usedIds]));
		}

		return {tree, usedIds: ids};
	};

	const createDirectory = (tree: DataGridTree, id: string) => {
		const children = tree.children || [];
		// TODO: Невозможнго посчитать totalSelected
		const directory = {totalSelected: 0, id, parentId: tree.id};
		children.push(directory);
		tree.children = children;
		return directory;
	};

	const createExcludeByItem = (tree: DataGridTree, item: any) => {
		const path = item.uuidpath.split('/');
		path[0] = 'root';
		let currentTree = tree;
		let pathCursor = 0;
		while (path[pathCursor + 1]) {
			const next = currentTree.children?.find(child => child.id === path[pathCursor + 1]);
			if (next) currentTree = next;
			else currentTree = createDirectory(currentTree, path[pathCursor + 1]);
			pathCursor++;
		}

		const exclude = currentTree.exclude || [];
		exclude.push(item.id);
		currentTree.exclude = exclude;
		currentTree.total = item.pathTotal;
		currentTree.totalSelected = item.pathTotal - exclude.length;
	};

	const handleClose = useCallback(() => {
		const {tree: newTree, usedIds} = nextTree({...tree}, unSelectedItems, '/');

		const leftItems = unSelectedItems.filter(item => !usedIds.includes(item.id));
		if (leftItems.length > 0) {
			leftItems.forEach(item => {
				createExcludeByItem(newTree, item);
			});
		}
		setTree(newTree);

		onClose();
	}, [onClose, setTree, unSelectedItems]);

	return (
		<ModalPopup open={true} header={'Выбранные контакты'} onClose={handleClose}>
			{data?.grid ? (
				<h3>
					Выбрано{' '}
					{wordByCount(
						(data?.grid?.options.total || 0) - unSelectedItems.length,
						['контакт', 'контакта', 'контактов'],
						true,
					)}{' '}
					из {options?.custom?.totalPossibleItems || 0} возможных
				</h3>
			) : (
				<h3> </h3>
			)}
			<div className={css.tableWrapper}>
				{error ? (
					<ErrorSnippet error={error} errorHeader={'Ошибка загрузки данных для таблицы'} refetch={refetch} />
				) : (
					<>
						<DataGridHeadPanel
							reLoad={refetch}
							loading={loading}
							minLengthSearchQuery={options?.minLengthSearchQuery}
							searchable={options?.searchable}
							onChangeSearchQuery={setSearchQuery}
							selectedIds={[]}
							onActionButtonRender={onGridActionRender(dataSourceId, fields || [], [], refetch)}
						/>
						<DataGrid options={gridOptions} items={data?.grid.items} loading={loading} />
						<DataGridPagination
							onChangePage={onChangePage}
							onChangeItemsPerPage={onChangeItemsPerPage}
							itemsPerPage={itemsPerPage}
							page={page}
							total={total}
						/>
					</>
				)}
			</div>
			<div className="modalPopupButtons">
				<Button onClick={handleClose}>
					<CrossIcon />
					Закрыть
				</Button>
			</div>
		</ModalPopup>
	);
};

export default SelectedContacts;
