import React from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {Diagram} from '../../../../../queries-generated/types';
import ChartWrapperWithData from '../ChartsBase/ChartWrapperWithData';
import {ChartTypes} from '../ChartsBase/ChartWrapper';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	pollInterval?: number;
};

const defaultData: Diagram = {
	series: [
		{
			name: 'Chrome',
			points: [{y: 61.41}],
		},
		{
			name: 'Internet Explorer',
			points: [{y: 11.84}],
		},
		{
			name: 'Firefox',
			points: [{y: 10.85}],
		},
		{
			name: 'Edge',
			points: [{y: 4.67}],
		},
		{
			name: 'Safari',
			points: [{y: 4.18}],
		},
	],
};

const StackedColumnChart: React.FC<Props> = ({viewItem, skipQuery, pollInterval}) => {
	return (
		<ChartWrapperWithData
			pollInterval={pollInterval}
			viewItem={viewItem}
			skipQuery={skipQuery}
			defaultData={defaultData}
			mapper={diagram => ({
				...diagram,
				series: [...diagram.series.map(series => ({...series, points: [{...series.points[0], x: undefined}]}))],
			})}
			type={ChartTypes.StackedColumn}
		/>
	);
};

export default StackedColumnChart;
