import React, {ReactNode} from 'react';
import {ViewItem} from './useViewItemTree';
import cls from '../../../utils/cls';
import PieChart from '../../settings/views/components/Charts/PieChart';
import StackedColumnChart from '../../settings/views/components/Charts/StackedColumnChart';
import LineChart from '../../settings/views/components/Charts/LineChart';
import GridComponent from '../../settings/views/components/GridComponent/GridComponent';
import StackedChart from '../../settings/views/components/Charts/StackedChart';
import StackedColumnChartBar from '../../settings/views/components/Charts/StackedColumnChartBar';
import GraphComponent from '../../settings/views/components/Graph/GraphComponent';
import LineTableChart from '../../settings/views/components/TableDiagram/LineTableChart';
import Snippet from '../../settings/views/components/Snippet/Snippet';
import HorizontalBlock from '../../settings/views/components/HorizontalBlock/HorizontalBlock';
import VerticalBlock from '../../settings/views/components/VerticalBlock/VerticalBlock';
import Text from '../../settings/views/components/Text/Text';
import Heading from '../../settings/views/components/Heading/Heading';
import GraphTimeLineGridComponent from '../../settings/views/components/GraphTimeLineGridComponent/GraphTimeLineGridComponent';
import GraphGridComponent from '../../settings/views/components/GraphGridComponent/GraphGridComponent';
import StackedLineChart from '../../settings/views/components/Charts/StackedLineChart';
import StackedLineTableChart from '../../settings/views/components/TableDiagram/StackedLineTableChart';
import {ComponentCommonButtonsProps} from '../useComponentCommonButtons';
import FormComponent from '../../settings/views/components/FormComponent/FormComponent';
import InputComponent from '../../settings/views/components/InputComponent/InputComponent';
import Button from '../../pirsInputs/Button/Button';
import getIcon from '../../../utils/getIcon';
import GridListComponent from '../../settings/views/components/GridListComponent/GridListComponent';

type Props = {
	skipQuery: boolean;
	pollInterval?: number;
	theme?: 'body' | 'neutral';
	css: any;
	componentCommonButtonsProps?: ComponentCommonButtonsProps;
};

function getViewHtml(
	viewItem: ViewItem,
	children: ReactNode,
	{theme, skipQuery, pollInterval, css, componentCommonButtonsProps}: Props,
): ReactNode {
	const {component} = viewItem;
	const {props} = component;
	const {width, height, content, font, mark_bold, alignment, mark_italic, mark_underlined, font_size, title} = props;

	let renderedComponent: ReactNode;
	switch (component.type) {
		case 'HorizontalBlock':
			renderedComponent = (
				<HorizontalBlock className={cls(css.horizontalBlock, css.component)} style={{width, height}}>
					{children}
				</HorizontalBlock>
			);
			break;
		case 'VerticalBlock':
			renderedComponent = (
				<VerticalBlock className={cls(css.verticalBlock, css.component)} style={{width, height}}>
					{children}
				</VerticalBlock>
			);
			break;
		case 'Dashboard':
			renderedComponent = (
				<div className={cls(css.verticalBlock, css.component)} style={{width, height}}>
					{children}
				</div>
			);
			break;
		case 'Snippet':
			renderedComponent = (
				<Snippet
					title={title}
					className={cls(css.snippet, css.component)}
					snippetWrapperClassName={css.snippetWrapper}
					snippetHeaderClassName={css.snippetHeader}
					snippetBodyClassName={css.snippetBody}
					style={{width, height}}
				>
					{children}
				</Snippet>
			);
			break;
		case 'Text':
			renderedComponent = (
				<Text
					className={cls(css.text, css.component)}
					style={{
						width,
						height,
						fontWeight: mark_bold && 'bold',
						fontStyle: mark_italic && 'italic',
						fontSize: font_size,
						textDecoration: mark_underlined && 'underline',
						fontFamily: font?.id,
						textAlign: alignment?.id,
					}}
				>
					{content}
				</Text>
			);
			break;
		case 'Heading1':
			renderedComponent = (
				<Heading type={'h1'} className={css.component} style={{width, height}}>
					{content}
				</Heading>
			);
			break;
		case 'Heading2':
			renderedComponent = (
				<Heading type={'h2'} className={css.component} style={{width, height}}>
					{content}
				</Heading>
			);
			break;
		case 'Heading3':
			renderedComponent = (
				<Heading type={'h3'} className={css.component} style={{width, height}}>
					{content}
				</Heading>
			);
			break;
		case 'BoxChart':
			renderedComponent = (
				<div className={css.component} style={{width, height}}>
					<StackedColumnChart viewItem={viewItem} skipQuery={skipQuery} pollInterval={pollInterval} />
				</div>
			);
			break;
		case 'DonutChart':
			renderedComponent = (
				<div className={css.component} style={{width, height}}>
					<PieChart viewItem={viewItem} skipQuery={skipQuery} pollInterval={pollInterval} />
				</div>
			);
			break;
		case 'Diagram':
			renderedComponent = (
				<div className={css.component} style={{width, height}}>
					<LineChart viewItem={viewItem} skipQuery={skipQuery} pollInterval={pollInterval} />
				</div>
			);
			break;
		case 'StackedBar':
			renderedComponent = (
				<div className={css.component} style={{width, height}}>
					<StackedChart viewItem={viewItem} skipQuery={skipQuery} pollInterval={pollInterval} />
				</div>
			);
			break;
		case 'StackedDiagram':
			renderedComponent = (
				<div className={css.component} style={{width, height}}>
					<StackedLineChart viewItem={viewItem} skipQuery={skipQuery} pollInterval={pollInterval} />
				</div>
			);
			break;
		case 'StackedColumnBar':
			renderedComponent = (
				<div className={css.component} style={{width, height}}>
					<StackedColumnChartBar viewItem={viewItem} skipQuery={skipQuery} pollInterval={pollInterval} />
				</div>
			);
			break;
		case 'Table':
			renderedComponent = (
				<div className={css.component} style={{width}}>
					<GridComponent
						viewItem={viewItem}
						skipQuery={skipQuery}
						title={title}
						height={height}
						pollInterval={pollInterval}
						theme={theme}
					/>
				</div>
			);
			break;
		case 'SimpleGraph':
			renderedComponent = (
				<div className={css.component} style={{width, height: height || 400}}>
					<GraphComponent
						viewItem={viewItem}
						skipQuery={skipQuery}
						pollInterval={pollInterval}
						height={height || 400}
					/>
				</div>
			);
			break;
		case 'GraphTimelineTable':
			renderedComponent = (
				<div className={css.component} style={{width, height: height || 400}}>
					<GraphTimeLineGridComponent
						viewItem={viewItem}
						skipQuery={skipQuery}
						height={height || 400}
						pollInterval={pollInterval}
						componentCommonButtonsProps={componentCommonButtonsProps}
					/>
				</div>
			);
			break;
		case 'GraphTable':
			renderedComponent = (
				<div className={css.component} style={{width, height: height || 400}}>
					<GraphGridComponent viewItem={viewItem} skipQuery={skipQuery} height={height || 400} />
				</div>
			);
			break;
		case 'DiagramTable':
			renderedComponent = (
				<div className={css.component} style={{width, height: height || 400}}>
					<LineTableChart
						viewItem={viewItem}
						skipQuery={skipQuery}
						height={height || 400}
						pollInterval={pollInterval}
					/>
				</div>
			);
			break;
		case 'StackedDiagramTable':
			renderedComponent = (
				<div className={css.component} style={{width, height: height || 400}}>
					<StackedLineTableChart
						viewItem={viewItem}
						skipQuery={skipQuery}
						height={height || 400}
						pollInterval={pollInterval}
					/>
				</div>
			);
			break;
		case 'Form':
			renderedComponent = (
				<div className={css.component} style={{width, height: height || 400}}>
					<FormComponent viewItem={viewItem}>{children}</FormComponent>
				</div>
			);
			break;
		case 'SubmitButton':
			renderedComponent = (
				<div>
					<Button type="submit">
						{component.props.icon && getIcon(component.props.icon)}
						{component.props.value}
					</Button>
				</div>
			);
			break;
		case 'Input':
			renderedComponent = (
				<div>
					<InputComponent viewItem={viewItem} />
				</div>
			);
			break;
		case 'TableList':
			renderedComponent = (
				<div className={css.component} style={{width}}>
					<GridListComponent
						viewItem={viewItem}
						skipQuery={skipQuery}
						title={title}
						height={height}
						pollInterval={pollInterval}
						theme={theme}
					/>
				</div>
			);
			break;
		default:
			renderedComponent = <div className={css.component}>Не поддерживается</div>;
	}

	return renderedComponent;
}

export default getViewHtml;
