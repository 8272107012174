import {Reducer} from 'react';

function reduxLogger<State, Action>(reducer: Reducer<State, Action>) {
	if (process.env.REACT_APP_REDUX_LOGGER === 'true') {
		return (state: State, action: Action | any) => {
			const prevState: State = {...state};
			const nextState: State = reducer(state, action);

			console.groupCollapsed(`action: ${action.type} ${new Date().toLocaleTimeString()}`);
			console.log('%cPrev state: ', 'color: #9e9e9e', prevState);
			console.log('%cAction:', 'color: #00A7F7', action);
			console.log('%cNext state: ', 'color: #47B04B', nextState);
			console.groupEnd();

			return nextState;
		};
	}

	return reducer;
}

export {reduxLogger};
