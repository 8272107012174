import React, {useCallback, useState} from 'react';
import {useRouter} from 'react-named-hooks-router';
import ListItem from '@material-ui/core/ListItem';
import {ListItemText} from '@material-ui/core';
import css from './ResourceRegisterOrderList.module.css';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List/List';

type Props = {
	header?: string;
	items?: {keyword: string; caption: string; description: string}[];
};

const OrderList: React.FC<Props> = ({header, items}) => {
	const {pushRoute, routeName, routeParams} = useRouter<{
		keyword?: string;
	}>();

	const handleChangeKeyword = useCallback(
		keyword => {
			pushRoute(routeName, {...routeParams, keyword: keyword});
		},
		[routeParams, pushRoute],
	);

	const [open, setOpen] = useState(false);

	const handleOpen = useCallback(() => {
		setOpen(!open);
	}, [open, setOpen]);

	const expand = items?.some(el => (routeParams.keyword ? el.keyword === routeParams.keyword : false));

	if (!items?.length) {
		return null;
	}

	return (
		<>
			<ListItem selected={expand} onClick={handleOpen} divider button>
				<ListItemText primary={<h4 className={css.orderName}>{header}</h4>} />
				{open || expand ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			<Collapse in={open || expand}>
				<List disablePadding>
					{items?.map(item => {
						const match = routeParams.keyword === item.keyword;

						return (
							<ListItem
								key={item.keyword}
								onClick={() => handleChangeKeyword(item.keyword)}
								button
								dense
								selected={match}
							>
								<ListItemText primary={<div className={css.linkStyle}>{item.caption}</div>} />
							</ListItem>
						);
					})}
				</List>
			</Collapse>
		</>
	);
};

export default OrderList;
