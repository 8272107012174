import React, {useCallback, useEffect, useRef} from 'react';
import css from './PopoverButton.module.css';
import Modal from '../../Modal';
import cls from '../../../utils/cls';
import FocusLock, {FocusLockProps} from '../../FocusLock/FocusLock';

export type Coords = {top: number; left: number; height: number; width?: number};
export type PopoverProps = {
	open: boolean;
	className?: string;
	forwardRef?: React.RefObject<HTMLDivElement>;
	onBlur?: FocusLockProps['onBlur'];
	focusOnOpen?: FocusLockProps['focusOnOpen'];
	parentRef?: React.RefObject<HTMLElement>;
	// Возвращает фокус после закрытия на переданный элемент
	returnFocusRef?: FocusLockProps['returnFocusRef'];
	stopPosition?: boolean;
	coords?: Coords;
	useMaxParentWidth?: boolean;
	classNamePositionTop?: string;
	offset?: {top?: number; left?: number};
	message?: boolean;
	paddingLess?: boolean;
	onClose?: () => void;
	// Отключает возврат фокуса
	disableFocusLock?: FocusLockProps['disabled'];
	/*
	 * Использовать вместо useOnClickOutside хука
	 * при клике на область за пределами попапа будет срабатывать onClose
	 * */
	withBackdrop?: boolean;
};

const MAGIC_NUMBER = 15;

const Popover: React.FC<PopoverProps> = ({
	parentRef,
	coords,
	open,
	focusOnOpen,
	stopPosition,
	onBlur,
	message,
	children,
	className,
	forwardRef,
	useMaxParentWidth,
	paddingLess,
	classNamePositionTop,
	offset: propsOffset,
	onClose,
	returnFocusRef,
	disableFocusLock,
	withBackdrop,
}) => {
	const popoverRef = useRef<HTMLDivElement>(null);

	const position = useCallback(() => {
		if (stopPosition) return;
		const popover = popoverRef && popoverRef.current;
		const parent = parentRef && parentRef.current;

		if (open && popover && (parent || coords)) {
			popover.style.width = 'auto';
			const parentBoundary = parent ? parent.getBoundingClientRect() : coords!;
			const popoverBoundary = popover.getBoundingClientRect();
			const clientWidth = window.innerWidth;
			const clientHeight = window.innerHeight;
			let width = useMaxParentWidth ? parentBoundary.width || popoverBoundary.width : popoverBoundary.width;

			const maxElementTopOffset = parentBoundary.top + parentBoundary.height + popoverBoundary.height;
			let offset = parentBoundary.left;
			if (offset + width > clientWidth) {
				offset = clientWidth - width;
				if (offset < 0) {
					offset = 0;
					width = clientWidth;
				}
			}

			popover.style.left = offset + (propsOffset?.left || 0) + 'px';
			popover.style.width = width + 'px';
			popover.style.height = 'auto';
			popover.style.top =
				maxElementTopOffset + MAGIC_NUMBER < clientHeight
					? parentBoundary.top + parentBoundary.height + (propsOffset?.top || 0) + window.scrollY + 'px'
					: parentBoundary.top - popoverBoundary.height + (propsOffset?.top || 0) + window.scrollY + 'px';
			if (classNamePositionTop) {
				if (maxElementTopOffset + MAGIC_NUMBER < clientHeight) {
					popover.classList.remove(classNamePositionTop);
				} else {
					popover.classList.add(classNamePositionTop);
				}
			}
		}
	}, [open, stopPosition, parentRef, coords, children, classNamePositionTop, useMaxParentWidth, propsOffset]);

	useEffect(() => {
		position();
		window.addEventListener('resize', position, false);

		return () => window.removeEventListener('resize', position, false);
	}, [position]);

	if (!open) return null;
	return (
		<Modal>
			{withBackdrop && <div className={css.backdrop} onClick={onClose} />}
			<div
				className={cls(css.popover, message && css.message, paddingLess && css.paddingLess, className)}
				ref={div => {
					if (forwardRef) (forwardRef as any).current = div;
					(popoverRef as any).current = div;
				}}
			>
				<FocusLock
					focusOnOpen={open && focusOnOpen}
					onBlur={onBlur}
					onEscClick={onClose}
					returnFocusRef={returnFocusRef}
					disabled={disableFocusLock}
				>
					{children}
				</FocusLock>
			</div>
		</Modal>
	);
};

export default Popover;
