import {Dispatch, useCallback, useEffect, useState} from 'react';

class CustomEvent<T extends any> extends Event {
	constructor(type: string, params: {bubbles?: boolean; cancelable?: boolean; detail: T}) {
		super(type, params);
		const event = document.createEvent('CustomEvent');
		const init = params || {bubbles: false, cancelable: false, detail: null};
		event.initCustomEvent(type, !!init.bubbles, !!init.cancelable, init.detail);
		return event;
	}
}

export function tryParse(value: string) {
	try {
		return JSON.parse(value);
	} catch (e) {
		return value;
	}
}

interface KVP<K, V> {
	key: K;
	value: V;
}

export function writeStorage<TValue>(key: string, value: TValue) {
	try {
		localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : `${value}`);
		window.dispatchEvent(new LocalStorageChanged({key, value}));
	} catch (err) {
		if (err instanceof TypeError && err.message.includes('circular structure')) {
			throw new TypeError(
				'The object that was given to the writeStorage function has circular references.\n' +
					'For more information, check here: ' +
					'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value',
			);
		}
		throw err;
	}
}

export function deleteFromStorage(key: string) {
	localStorage.removeItem(key);
	window.dispatchEvent(new LocalStorageChanged({key, value: ''}));
}

class LocalStorageChanged<TValue> extends CustomEvent<KVP<string, TValue>> {
	static eventName = 'onLocalStorageChange';

	constructor(payload: KVP<string, TValue>) {
		super(LocalStorageChanged.eventName, {detail: payload});
	}
}

export function useLocalStorage<TValue = string>(
	key: string,
	initialValue?: TValue,
): [TValue, Dispatch<TValue>, Dispatch<void>] {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const localStorageValue = tryParse(localStorage.getItem(key)!);
	const defaultValue = localStorageValue === null ? initialValue : localStorageValue;
	const [localState, updateLocalState] = useState<TValue | undefined>(defaultValue);

	const onLocalStorageChange = useCallback(
		(event: LocalStorageChanged<TValue> | StorageEvent) => {
			if (event.type === LocalStorageChanged.eventName) {
				// @ts-ignore
				if (event.detail.key === key) {
					// @ts-ignore
					updateLocalState(event.detail.value);
				}
			} else {
				// @ts-ignore
				if (event.key === key) {
					// @ts-ignore
					updateLocalState(event.newValue);
				}
			}
		},
		[key],
	) as EventListener;

	useEffect(() => {
		if (localStorageValue === null && initialValue !== undefined) writeStorage(key, initialValue);
		// The custom storage event allows us to update our component
		// when a change occurs in localStorage outside of our component
		window.addEventListener(LocalStorageChanged.eventName, onLocalStorageChange);

		// The storage event only works in the context of other documents (eg. other browser tabs)
		window.addEventListener('storage', onLocalStorageChange);

		return () => {
			window.removeEventListener(LocalStorageChanged.eventName, onLocalStorageChange);
			window.removeEventListener('storage', onLocalStorageChange);
		};
	}, [key, initialValue, onLocalStorageChange, localStorageValue]);

	return [localState as TValue, (value: TValue) => writeStorage(key, value), () => deleteFromStorage(key)];
}

export default useLocalStorage;
