import React, {useCallback, useState} from 'react';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import css from './IncidentsPage.module.css';
import MainLayout from '../../components/layouts/MainLayout';
import SafeLink from '../../components/SafeLink/SafeLink';
import Button from '../../components/pirsInputs/Button/Button';
import {CreateIcon, EditIcon} from '../../components/SvgIcon';
import IncidentsComments from '../../components/incidents/IncidentsComments';
import {Comment} from '@material-ui/icons';
import ModifyIncidentWrapper from '../../components/incidents/ModifyIncidentWrapper';
import tableCss from '../../components/FullTable/FullTable.module.css';

export const IncidentsRoutes = {
	Инциденты: 'incidents',
	Заявки: 'requests',
};

const IncidentsPage: React.FC = () => {
	const [commentsForIncident, setCommentsForIncident] = useState<any>();
	const [openModify, setOpenModify] = useState(false);
	const [selectedIncident, setSelectedIncident] = useState<any>();
	const handleToggleComments = useCallback((incident?: any) => {
		if (incident.id) setCommentsForIncident(incident);
		else setCommentsForIncident(undefined);
	}, []);
	const handleToggleModify = useCallback(
		(incident?: any) => {
			setOpenModify(!openModify);
			if (incident) setSelectedIncident(incident);
			else setSelectedIncident(undefined);
		},
		[openModify],
	);

	const {Table, refresh, columns} = useFullTableWithRefetch('incident', {
		tableFields: ['category', 'external_id', 'created', 'status', 'severity', 'issue_source', 'description'],
		detailsFields: ['issue_source', 'created_by', 'asbi_event_type', 'occured', 'detected', 'closed', 'files'],
		editable: false,
		creatable: false,
		createTitle: 'Создать инцидент',
		editTitle: 'Редактировать инцидент',
		additionalControls: item => {
			return (
				<>
					<Button
						onClick={e => {
							e.stopPropagation();
							handleToggleComments(item);
						}}
						iconOnly
						action
					>
						<Comment width={24} height={24} />
					</Button>
					<Button
						onClick={e => {
							e.stopPropagation();
							handleToggleModify(item);
						}}
						iconOnly
						action
					>
						<EditIcon width={24} height={24} />
					</Button>
				</>
			);
		},

		cellCallback: {
			operator: (value, item) => {
				return (
					<SafeLink route={'surveyResourceOwners'} params={{tin: item.operator}}>
						{value}
					</SafeLink>
				);
			},
		},
		detailCellCallback: {
			files: value => {
				return (
					<>
						{value && value.length > 0 ? (
							<ul>
								{value.map(file => (
									<li key={file.id}>{file.name}</li>
								))}
							</ul>
						) : (
							<>Нет файлов</>
						)}
					</>
				);
			},
		},
	});

	return (
		<MainLayout routes={IncidentsRoutes} wrapperClassName={css.wrapper}>
			{commentsForIncident && <IncidentsComments onClose={handleToggleComments} incident={commentsForIncident} />}
			{openModify && (
				<ModifyIncidentWrapper
					columns={columns}
					incidentId={selectedIncident && selectedIncident.id}
					onClose={() => handleToggleModify()}
					reload={refresh}
					title={(selectedIncident?.external_id && `Инцидент ${selectedIncident.external_id}`) || undefined}
				/>
			)}
			<div className={css.heading}>
				<div>
					<Button onClick={() => handleToggleModify()} className={tableCss.createButton}>
						<CreateIcon />
						Добавить
					</Button>
				</div>
			</div>
			<Table />
		</MainLayout>
	);
};

export default IncidentsPage;
