import css from './Loader.module.css';
import React from 'react';
import SmallLoader from '../SmallLoader/SmallLoader';

type Props = {
	value?: number | null;
};

const ProgressRing: React.FC<Props> = ({value}) => {
	return (
		<div className={css.wrapper}>
			<div className={css.loader}>
				<SmallLoader size={50} value={value} />
			</div>
		</div>
	);
};

export default ProgressRing;
