import React from 'react';
import {ViewItem} from '../../../../view/api/useViewItemTree';
import {FieldType, GridDiagram} from '../../../../../queries-generated/types';
import {ChartTypes} from '../ChartsBase/ChartWrapper';
import GridDiagramBase from '../GridDiagramBase/GridDiagramBase';

type Props = {
	viewItem: ViewItem;
	skipQuery: boolean;
	height: number;
	pollInterval?: number;
};

export const defaultData: GridDiagram = {
	series: [
		{
			id: '1',
			name: 'Chrome',
			points: [
				{y: 10.41, tableItem: {column1: 'Текст', column2: 'Текст 2'}},
				{y: 40.41, tableItem: {column1: 'Текст', column2: 'Текст 2'}},
				{y: 61.41, tableItem: {column1: 'Текст', column2: 'Текст 2'}},
			],
		},
		{
			id: '2',
			name: 'Internet Explorer',
			points: [
				{y: 90.84, tableItem: {column1: 'Текст', column2: 'Текст 2'}},
				{y: 70.84, tableItem: {column1: 'Текст', column2: 'Текст 2'}},
				{y: 11.84, tableItem: {column1: 'Текст', column2: 'Текст 2'}},
			],
		},
		{
			id: '3',
			name: 'Firefox',
			points: [
				{y: 30.85, tableItem: {column1: 'Текст', column2: 'Текст 2'}},
				{y: 10.85, tableItem: {column1: 'Текст', column2: 'Текст 2'}},
			],
		},
	],
	fields: [
		{id: 'column1', title: 'Колонка таблицы', type: FieldType.String, canSort: false},
		{id: 'column2', title: 'Еще одна колонка', type: FieldType.String, canSort: false},
	],
};

const LineTableChart: React.FC<Props> = ({viewItem, skipQuery, height, pollInterval}) => {
	return (
		<GridDiagramBase
			pollInterval={pollInterval}
			viewItem={viewItem}
			skipQuery={skipQuery}
			defaultData={defaultData}
			mapper={diagram => diagram}
			type={ChartTypes.Line}
			align={viewItem.component.props?.tableAlignment?.id || 'bottom'}
			height={height}
		/>
	);
};

export default LineTableChart;
