exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".ArrayInput-module__wrapper__3q7dk {\r\n\tborder: 1px solid var(--neutral-stroke-rest);\r\n\tborder-radius: 8px;\r\n\tpadding: 6px;\r\n\twidth: auto !important;\r\n}\r\n\r\n.ArrayInput-module__items__kHf5V {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tmargin-bottom: 10px;\r\n}\r\n\r\n.ArrayInput-module__items__kHf5V li {\r\n\t\tdisplay: flex;\r\n\t\talign-items: center;\r\n\t\tjustify-content: space-between;\r\n\t}\r\n\r\n.ArrayInput-module__items__kHf5V svg {\r\n\t\tcursor: pointer;\r\n\t}\r\n\r\n.ArrayInput-module__inputWrapper__ybl-1 {\r\n\tdisplay: flex;\r\n\talign-items: flex-end;\r\n\tgap: 6px;\r\n}\r\n\r\n.ArrayInput-module__inputWrapper__ybl-1 .ArrayInput-module__input__2oRkU {\r\n\t\tmargin-right: 10px;\r\n\t}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\pirsInputs\\ArrayInput\\ArrayInput.module.css"],"names":[],"mappings":"AAAA;CACC,4CAA4C;CAC5C,kBAAkB;CAClB,YAAY;CACZ,sBAAsB;AACvB;;AAEA;CACC,gBAAgB;CAChB,SAAS;CACT,UAAU;CACV,mBAAmB;AAWpB;;AATC;EACC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;CAC/B;;AAEA;EACC,eAAe;CAChB;;AAGD;CACC,aAAa;CACb,qBAAqB;CACrB,QAAQ;AAKT;;AAHC;EACC,kBAAkB;CACnB","file":"ArrayInput.module.css","sourcesContent":[".wrapper {\r\n\tborder: 1px solid var(--neutral-stroke-rest);\r\n\tborder-radius: 8px;\r\n\tpadding: 6px;\r\n\twidth: auto !important;\r\n}\r\n\r\n.items {\r\n\tlist-style: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tmargin-bottom: 10px;\r\n\r\n\tli {\r\n\t\tdisplay: flex;\r\n\t\talign-items: center;\r\n\t\tjustify-content: space-between;\r\n\t}\r\n\r\n\tsvg {\r\n\t\tcursor: pointer;\r\n\t}\r\n}\r\n\r\n.inputWrapper {\r\n\tdisplay: flex;\r\n\talign-items: flex-end;\r\n\tgap: 6px;\r\n\r\n\t.input {\r\n\t\tmargin-right: 10px;\r\n\t}\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"wrapper": "ArrayInput-module__wrapper__3q7dk",
	"items": "ArrayInput-module__items__kHf5V",
	"inputWrapper": "ArrayInput-module__inputWrapper__ybl-1",
	"input": "ArrayInput-module__input__2oRkU"
};