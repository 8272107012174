import React from 'react';
import css from './RadioButtonGroup.module.css';
import cls from '../../../utils/cls';

type Props = {
	label?: string;
	value: string;
	onChange?: (value: string) => void;
	labelClassName?: string;
};

export type RadioButtonProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof Props> & Props;

const RadioButton: React.FC<RadioButtonProps> = props => {
	const {onChange, value, checked, disabled, label, name, labelClassName, ...defaultProps} = props;

	return (
		<label className={cls(css.input, disabled && css.disabled, labelClassName)}>
			<input
				{...defaultProps}
				type="radio"
				checked={checked}
				name={name}
				value={value}
				disabled={disabled}
				onChange={() => onChange && onChange(value)}
			/>
			<div className={css.control}>
				<div className={css.indicator} />{' '}
			</div>
			<span>{label}</span>
		</label>
	);
};

export default RadioButton;
