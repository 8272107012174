import React, {useCallback, useState} from 'react';
import {useDataSourceUpdateMutation} from '../../queries-generated/types';
import Toggle from '../pirsInputs/Toggle/Toggle';
import {notifyToastError} from '../toast/Toast';

type Props = {
	dataSourceId: string;
	id: string;
	value: boolean;
	fieldId: string;
};

const DataGridToggle: React.FC<Props> = ({value: propsValue, dataSourceId, fieldId, id}) => {
	const [mutate, {loading}] = useDataSourceUpdateMutation();
	const [value, setValue] = useState(propsValue);
	const handleToggle = useCallback(() => {
		setValue(!value);
		mutate({variables: {item: {id, [fieldId]: !value}, dataSourceId}})
			.then(({errors}) => {
				if (errors) {
					notifyToastError('Ошибка изменения записи');
					setValue(value);
				}
			})
			.catch(() => {
				notifyToastError('Ошибка изменения записи');
				setValue(value);
			});
	}, [dataSourceId, value]);

	return <Toggle onChange={handleToggle} checked={value} disabled={loading} />;
};

export default DataGridToggle;
