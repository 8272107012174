import React from 'react';
import RadioButton, {RadioButtonProps} from './RadioButton';
import css from './RadioButtonGroup.module.css';
import cls from '../../../utils/cls';
import uid from '../../../utils/uid';

export type Props = {
	buttons: RadioButtonProps[];
	onChange?: (value: string) => void;
	horizontal?: boolean;
	value?: string | null;
	name?: string;
	error?: string;
};

const RadioButtonGroup: React.FC<Props> = props => {
	const {buttons, error, onChange, horizontal, value, name} = props;
	const hasError = !!error;

	return (
		<div>
			<ul className={cls(css.wrapper, horizontal && css.horizontal)}>
				{buttons.map((button, index) => {
					const checked = value && button.value === value ? true : !!button.checked;

					return (
						<li key={index}>
							<RadioButton
								{...button}
								checked={checked}
								name={name || 'rbg' + uid()}
								onChange={onChange}
							/>
						</li>
					);
				})}
			</ul>
			{hasError && <div className={css.error}>{error}</div>}
		</div>
	);
};

export default RadioButtonGroup;
