import React, {ReactNode} from 'react';
import {Link, LinkProps} from 'react-named-hooks-router';
import getAllowedRoutes from '../../utils/getAllowedRoutes';

export function isSafeRoute(route: string): boolean {
	const allowedRoutes: string[] | null = getAllowedRoutes();

	if (!allowedRoutes) return false;

	if (allowedRoutes.includes(route)) return true;
	else {
		console.error(`Unknown route: ${route}`);
		return false;
	}
}

type RouteItem = {
	route?: string;
	items?: RouteItem[];
};
export function isSafeRouteItem<T extends RouteItem>(item: T) {
	if (item && item.route && !isSafeRoute(item.route)) return false;

	if (item && item.items) return item.items.some(isSafeRouteItem);

	return true;
}

type Props = LinkProps & {fallback?: ReactNode};

const SafeLink: React.FC<Props> = ({route, fallback, children, ...linkProps}) => {
	return (
		<>
			{isSafeRoute(route) ? (
				<Link route={route} {...linkProps}>
					{children}
				</Link>
			) : (
				<>{fallback || children}</>
			)}
		</>
	);
};

export default SafeLink;
