import * as React from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import View from '../../components/view/View';

export const AlertsRoutes = {
	Статистика: 'alertsStatistic',
	Аномалии: 'alertsAnomalies',
};

type trivialFunc = () => void;
export type Refetcher = null | trivialFunc;

const AlertsStatisticPage: React.FC = () => {
	return (
		<MainLayout routes={AlertsRoutes}>
			<View id={'d56b1524-5c4e-459b-b376-3cb4feac4795'} />
		</MainLayout>
	);
};

export default AlertsStatisticPage;
