export function findKeyByValue<T>(obj: PlainObjectOf<T>, value: T): string | undefined {
	for (const key in obj) {
		if (obj[key] === value) return key;
	}
}

export function fillValueObject<T>(obj: {[key: string]: any} | undefined, value: T): {[key: string]: T} {
	const newObj = {};

	if (!obj) return newObj;

	Object.keys(obj).forEach(key => {
		setValueByPath(newObj, key, value);
	});
	return newObj;
}

export function filterObject<T>(obj: PlainObjectOf<T>, callback: (value: T) => boolean): PlainObjectOf<T> {
	const newObject = {};
	for (const key in obj) {
		if (callback(obj[key])) {
			newObject[key] = obj[key];
		}
	}

	return newObject;
}

export function mapObject<T, U>(obj: PlainObjectOf<T>, callback: (value: T, key: string) => U): PlainObjectOf<U> {
	const newObject = {};
	for (const key in obj) {
		newObject[key] = callback(obj[key], key);
	}

	return newObject;
}

export function setValueByPath(obj, path, value) {
	const a = path.split('.');
	let o = obj;
	while (a.length - 1) {
		const n = a.shift();
		if (!(n in o)) o[n] = {};
		o = o[n];
	}
	o[a[0]] = value;
}

export function getValueByPath(obj, path) {
	path = path.replace(/\[(\w+)]/g, '.$1');
	path = path.replace(/^\./, '');
	const a = path.split('.');
	let o = obj;
	while (a.length) {
		if (!o) return null;
		const n = a.shift();
		if (o === 'INFINITY_LIST') return null;
		if (!(n in o)) return;
		o = o[n];
	}
	return o;
}

export function JSONSafeParse(maybeJson: string) {
	let json: any = null;
	try {
		json = JSON.parse(maybeJson);
	} catch (e) {
		// nothing
	}
	return json;
}

/**
 * Simple object check.
 */
export function isObject(item: any) {
	return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 */
export function mergeDeep(target: any, ...sources: any) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, {[key]: {}});
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, {[key]: source[key]});
			}
		}
	}

	return mergeDeep(target, ...sources);
}

export function omitNull<T>(obj: T): Partial<T> {
	const keys = Object.keys(obj as {});
	const _obj = {};
	for (const key of keys) {
		const value = obj[key];
		if (value !== null) {
			_obj[key] = value;
		}
	}
	return _obj;
}
