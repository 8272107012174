import React, {useState} from 'react';
import useFullTableWithRefetch from '../../components/FullTable/useFullTableHook';
import ToggleModelItemOld from '../../components/FullTable/ToggleModelItemOld';
import InputSearch from '../../components/pirsInputs/InputSearch/InputSearch';

const RecipientBindingOrgTable: React.FC = () => {
	const modelName = 'recipientBindingOrg';

	const [filter, setFilter] = useState<string>();

	const {Table} = useFullTableWithRefetch(modelName, {
		tableFields: ['short_name', 'inn', 'epp', 'sms', 'mail', 'pvvs'],
		detailsFields: ['realm'],
		deletable: true,
		creatable: true,
		variables: {
			filter: filter
				? `(short_name like ${filter}) or (inn like ${filter}) or (realm like ${filter})`
				: undefined,
		},
		additionalFilters: <InputSearch onChange={setFilter} value={filter} />,
		cellCallback: {
			epp: (value, item) => {
				return <ToggleModelItemOld modelName={modelName} value={value} id={item.id} params={{name: 'epp'}} />;
			},
			sms: (value, item) => {
				return <ToggleModelItemOld modelName={modelName} value={value} id={item.id} params={{name: 'sms'}} />;
			},
			mail: (value, item) => {
				return <ToggleModelItemOld modelName={modelName} value={value} id={item.id} params={{name: 'mail'}} />;
			},
			pvvs: (value, item) => {
				return <ToggleModelItemOld modelName={modelName} value={value} id={item.id} params={{name: 'pvvs'}} />;
			},
		},
	});
	return <Table />;
};

export default RecipientBindingOrgTable;
