import * as React from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import View from '../../components/view/View';

const InformationContactsPage: React.FC = () => {
	return (
		<MainLayout>
			<View id={'b7674162-b416-4894-b8ab-b7b55b50b123'} />
		</MainLayout>
	);
};

export default InformationContactsPage;
