import React from 'react';
import css from './Shimmer.module.css';
import {random} from '../../utils/random';
import cls from '../../utils/cls';

const Shimmer: React.FC = ({children}) => {
	let modifiedChildren: React.ReactNode[] = [];
	if (!children) return null;
	if (Array.isArray(children)) {
		children.forEach((element, i) => {
			modifiedChildren.push(appendShimmerToChild(element as React.ReactElement, i.toString()));
		});
	} else {
		modifiedChildren = [appendShimmerToChild(children as React.ReactElement, 'first')];
	}
	return <>{modifiedChildren}</>;
};

const appendShimmerToChild = (child: React.ReactElement, key?: string) => {
	return React.cloneElement(child, {
		className: cls(child.props.className, css.shimmer),
		key,
	});
};

type FontSize = 'text' | 'h1' | 'h2' | 'h3' | 'h4' | 'small';

type ShimmerTextProps = {
	fontSize?: FontSize;
	lines: number;
	short?: boolean;
	styles?: React.CSSProperties;
	rootStyles?: React.CSSProperties;
};

export const ShimmerText: React.FC<ShimmerTextProps> = ({fontSize, short, rootStyles, styles, lines}) => {
	const classes: {[key in FontSize]: string} = {
		text: css.textBlockText,
		h1: css.textBlockH2,
		h2: css.textBlockH2,
		h3: css.textBlockH4,
		h4: css.textBlockH4,
		small: css.textBlockSmall,
	};
	return (
		<div className={classes[fontSize || 'text']} style={rootStyles}>
			<Shimmer>
				{Array.from(Array(lines)).map((_item, index) => (
					<div
						key={index}
						style={{width: short ? '30%' : (index + 1) % 3 ? '100%' : `${random(2, 9)}0%`, ...styles}}
					/>
				))}
			</Shimmer>
		</div>
	);
};

export default Shimmer;
