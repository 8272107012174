import {TableColumn, useModelItemQuery} from '../../queries-generated/types';
import React from 'react';
import ModifyIncident from './ModifyIncident';
import ModalPopup from '../controlls/ModalPopup/ModalPopup';
import ProgressRing from '../controlls/Loader/ProgressRing';
import formCss from '../FullTable/FullTableForm.module.css';

type Props = {
	columns: TableColumn[];
	incidentId?: any;
	reload: () => void;
	onClose: () => void;
	title?: string;
};

const ModifyIncidentWrapper: React.FC<Props> = ({onClose, reload, incidentId, columns, title}) => {
	const {data, loading} = useModelItemQuery({
		variables: {model: 'incident', id: incidentId && incidentId.toString(), withRelations: [{name: 'files'}]},
		fetchPolicy: 'network-only',
		skip: !incidentId,
	});
	if (!incidentId) {
		return <ModifyIncident columns={columns} reload={reload} onClose={onClose} />;
	} else {
		if (!loading && data)
			return (
				<ModifyIncident incident={data.modelItem.item} columns={columns} reload={reload} onClose={onClose} />
			);
		else
			return (
				<ModalPopup open={true} maxWidth={400} onClose={onClose}>
					{title && <h2 className={formCss.header}>{title}</h2>}
					<ProgressRing />
				</ModalPopup>
			);
	}
};

export default ModifyIncidentWrapper;
