import * as React from 'react';
import {useCallback, useState} from 'react';
import {useFileSectionsQuery, useFilesListQuery, useHelpFilesListQuery} from '../../queries-generated/types';
import {FormikProvider, useFormik} from 'formik';
import {FormikErrors} from 'formik/dist/types';
import css from './FileConsolePage.module.css';
import {useRouter} from 'react-named-hooks-router';
import ProgressRing from '../../components/controlls/Loader/ProgressRing';
import FileUploadSnippet from './FileUploadSnippet';
import FileUploadSnippetDone from './FileUploadSnippetDone';
import FileUploadSnippetFail from './FileUploadSnippetFail';
import FilesList from './FilesList';
import useFileChunkUpload, {FileChunksUploadErrorType, LocalFileStatus} from './useFileChunksUpload';
import MainLayout from '../../components/layouts/MainLayout';
import cssHome from '../HomePage/HomePage.module.css';
import cls from '../../utils/cls';
import HelpFileList from '../../components/fileConsole/HelpFilesList/HelpFilesList';
import NavigatePopup from '../../components/controlls/NavigatePopup/NavigatePopup';
import useUser from '../../components/hooks/useUser';
import {formatBytes} from '../../utils/number-utils';
import useSessionStorage from '../../components/hooks/useSessionStorage';
import FileQueueSnippet from './FileQueueSnippet';
import {UploadFileStatus} from '../../components/commonRedux/UploadFileStore/UploadFileQueueReducer';
import ModalPopup from '../../components/controlls/ModalPopup/ModalPopup';
import Card, {CardItem} from '../../components/controlls/Card/Card';

type Values = {file?: File};

const FileConsolePage: React.FC = () => {
	const {params} = useRouter<{id: string}>();
	const {id: sectionId} = params;
	const {tin} = useUser();

	const {data: fileSectionsData, loading: fileSectionsLoading} = useFileSectionsQuery();

	const {data: helpFilesData, loading: helpFilesLoading} = useHelpFilesListQuery({
		variables: {
			input: {
				sectionId,
			},
		},
	});

	const {data: filesListData, loading: filesListLoading, refetch: filesListRefetch} = useFilesListQuery({
		variables: {
			input: {
				sectionId,
				orgINN: tin,
			},
		},
	});

	const filesList = filesListData?.filesList;

	const [tempFileData, setTempFileData] = useState<{fileName: string; fileSize: string; progress: 0}>();

	const {
		publishFile,
		cancelUpload,
		uploadFile,
		localFile,
		loading,
		fileLoading,
		progress,
		error,
		publishStatus,
		currentLoadingFile,
		fileQueue,
	} = useFileChunkUpload({
		sectionId,
		filesListRefetch,
		setTempFileData,
		tempFileData,
	});

	const [showBeforeUnloadModal, setShowBeforeUnloadModal] = useSessionStorage('isModalShow(information)', false);

	const [showErrorModal, setShowErrorModal] = useState(false);
	const handleToggleErrorModal = useCallback(() => {
		setShowErrorModal(!showErrorModal);
	}, [showErrorModal]);

	const [navigateCallback, setNavigateCallback] = useState<() => void | undefined>();
	const handleNavigate = useCallback(
		navigate => {
			if (fileLoading && !showBeforeUnloadModal) {
				setNavigateCallback(() => navigate);
				setShowBeforeUnloadModal(true);
			} else {
				navigate('navigate');
			}
		},
		[fileLoading, showBeforeUnloadModal],
	);
	useRouter(handleNavigate);

	const formikBag = useFormik<Values>({
		initialValues: {
			file: undefined,
		},
		onSubmit: values => {
			const {file} = values;
			if (!file) return;
			setTempFileData({fileName: file.name, fileSize: formatBytes(file.size), progress: 0});
			uploadFile(file);
		},
		validate: values => {
			const errors: FormikErrors<Values> = {};
			if (!values.file || values.file.size === 0) {
				errors.file = 'Ваш файл пустой, укажите корректный файл';
			} else if (values.file.size > 50 * 1024 * 1024) {
				errors.file = 'Размер загружаемого документа должен быть менее 50 МБайт';
			}

			return errors;
		},
	});

	const section = fileSectionsData?.fileSections.find(section => section.id === sectionId);

	const handleCloseModalPopup = useCallback(() => {
		setNavigateCallback(undefined);
	}, []);

	const handleCloseModalPopupAndNavigate = useCallback(() => {
		if (navigateCallback) navigateCallback();
		setNavigateCallback(undefined);
	}, [navigateCallback]);

	const handleSubmitForm = useCallback(() => {
		setTimeout(() => formikBag.submitForm());
	}, []);

	return (
		<MainLayout className={css.topNavigation}>
			<>
				{fileSectionsLoading ? (
					<div className={css.center}>
						<ProgressRing />
					</div>
				) : !section ? (
					<div className={css.center}>
						<h2 className={css.emptyPageMessage}>Страница не найдена</h2>
					</div>
				) : section && !section.enabled ? (
					<div className={css.center}>
						<h2 className={css.emptyPageMessage}>Просмотр страницы отключен</h2>
					</div>
				) : (
					<>
						<NavigatePopup
							navigateCallback={navigateCallback}
							handleCloseModalPopup={handleCloseModalPopup}
							handleCloseModalPopupAndNavigate={handleCloseModalPopupAndNavigate}
							text={'Вы уходите со страницы, загрузка файла продолжится в фоновом режиме'}
						/>
						<ModalPopup
							header={'Ошибка'}
							open={showErrorModal}
							maxWidth={600}
							onClose={handleToggleErrorModal}
						>
							{error && error.message}
						</ModalPopup>
						<div>
							<Card className={cls(cssHome.wrapperWelcome, css.header)}>
								<CardItem>{section?.description}</CardItem>
							</Card>
							<div className={css.wrapper}>
								<Card className={css.uploadArea}>
									<CardItem header={'Представление информации'} />
									<CardItem>
										{loading && <ProgressRing />}
										{!loading && (
											<>
												{error?.type === FileChunksUploadErrorType.APOLLOERROR ||
												error?.type === FileChunksUploadErrorType.QUERYERROR ? (
													<div className={css.error}>
														Представление файла завершилось ошибкой. Для просмотра
														информации об ошибке,{' '}
														<a className={css.errorLink} onClick={handleToggleErrorModal}>
															нажмите сюда
														</a>
													</div>
												) : error?.type === FileChunksUploadErrorType.CUSTOMERROR ? (
													<div className={css.error}>{error?.message}</div>
												) : null}

												{publishStatus?.status === 'finished' ||
												!localFile ||
												!localFile?.status ? (
													<FormikProvider value={formikBag}>
														<FileUploadSnippet
															onSubmitForm={handleSubmitForm}
															onCancelUpload={cancelUpload}
															uploadInProgress={Boolean(tempFileData) || fileLoading}
															uploadProgress={progress || 0}
															fileName={
																tempFileData?.fileName || currentLoadingFile?.file.name
															}
															publishAt={
																publishStatus &&
																new Date(publishStatus?.publishAt || '')
															}
														/>
													</FormikProvider>
												) : fileQueue ? (
													<FileQueueSnippet
														onCancelUpload={cancelUpload}
														fileName={currentLoadingFile?.file.name}
													/>
												) : localFile?.status === LocalFileStatus.finished ||
												  currentLoadingFile?.status === UploadFileStatus.LOADED ? (
													<FileUploadSnippetDone
														createdAt={new Date(localFile?.createdAt || '')}
														uploadedAt={new Date(localFile?.uploadedAt || '')}
														fileName={localFile?.fileName || ''}
														onCancelUpload={cancelUpload}
														onSubmitForm={publishFile}
														size={localFile?.size || 0}
														id={localFile?.id || ''}
													/>
												) : localFile?.status === LocalFileStatus.failed ? (
													<FileUploadSnippetFail
														onCancelUpload={cancelUpload}
														text={`Ошибка загрузки файла ${localFile?.fileName}`}
													/>
												) : localFile?.status === LocalFileStatus.inProgress ? (
													<FileUploadSnippetFail
														onCancelUpload={cancelUpload}
														text={`Файл ${localFile?.fileName} находится в процессе загрузки`}
													/>
												) : localFile?.status === LocalFileStatus.created ? (
													<FileUploadSnippetFail
														onCancelUpload={cancelUpload}
														text={`Файл ${localFile?.fileName} поставлен в очередь для загрузки`}
													/>
												) : (
													<FileUploadSnippetFail
														onCancelUpload={cancelUpload}
														text={`Похоже, при загрузке файла ${localFile?.fileName} что-то пошло не так.`}
													/>
												)}
											</>
										)}
									</CardItem>
								</Card>
								<Card className={css.helpArea}>
									<CardItem header={'Справочная информация'} />
									<CardItem>
										{helpFilesLoading ? (
											<ProgressRing />
										) : (
											<HelpFileList helpFiles={helpFilesData?.helpFilesList || []} />
										)}
									</CardItem>
								</Card>
							</div>
							<Card className={css.fileList}>
								<CardItem header={'Ранее представленная информация'} />
								<CardItem>
									<FilesList files={filesList || []} loading={filesListLoading} />
								</CardItem>
							</Card>
						</div>
					</>
				)}
			</>
		</MainLayout>
	);
};

export default FileConsolePage;
