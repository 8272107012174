import React from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import InputDateTime from '../InputDateTime/InputDateTime';

interface InputInterface extends CommonInputInterface<string | null> {
	setRef?: React.RefObject<HTMLInputElement>;
	value: string | null;
}

export type InputProps = InputInterface & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const InputDate: React.FC<InputProps> = props => {
	return <InputDateTime {...props} withoutTime={true} />;
};

export default InputDate;
