import React, {useMemo} from 'react';
import useUser from '../hooks/useUser';
import css from './UserInfoComponent.module.css';
import PopoverButton from '../controlls/PopoverButton/PopoverButton';
import {User} from '../SvgIcon';

const UserInfoComponent: React.FC = () => {
	const {login, roles, tin, shortOrganizationName, shortUserName, name} = useUser();

	const rolesText = useMemo(
		() => (roles && roles.length ? (roles.length > 1 ? 'Роли: ' : 'Роль: ') + roles.join(', ') : undefined),
		[roles],
	);

	return (
		<PopoverButton
			action
			message
			popover={
				<ul className={css.userInfoList}>
					<li>Логин: {login}</li>
					{name && <li>ФИО: {name}</li>}
					{tin && <li>ИНН организации: {tin}</li>}
					{shortOrganizationName && <li>Организация: {shortOrganizationName}</li>}
					{rolesText && <li>{rolesText}</li>}
				</ul>
			}
		>
			<User />
			<div className={css.userInfo}>
				<div className={css.userName} title={shortUserName}>
					{shortUserName}
				</div>
				{shortOrganizationName && (
					<div className={css.orgName} title={shortOrganizationName}>
						{shortOrganizationName}
					</div>
				)}
			</div>
		</PopoverButton>
	);
};

export default UserInfoComponent;
