import React from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import {useRouter} from 'react-named-hooks-router';
import {usePirsInstanceQuery} from '../../queries-generated/types';
import EntitiesForm from '../../components/pirs/form/EntitiesForm';
import ProgressRing from '../../components/controlls/Loader/ProgressRing';
import {MetadataProvider} from '../../components/pirs/form/useMetadata';
import {parseIso} from 'ts-date/locale/ru';
import {InspectorCommentsProvider} from '../../components/pirs/form/useInspectorComments';

type Props = {};

const ResourceRegisterInstancePage: React.FC<Props> = () => {
	const {routeParams} = useRouter<{id: string; type: string}>();
	const id = routeParams.id === 'create' ? undefined : routeParams.id;
	const {data} = usePirsInstanceQuery({
		variables: {type: routeParams.type, id},
		fetchPolicy: 'network-only',
	});

	return (
		<MainLayout>
			<div style={{minHeight: '100%'}}>
				{data?.pirsInstance ? (
					<MetadataProvider
						values={data.pirsInstance.data.doc}
						entities={data.pirsInstance.metadata.attributes}
					>
						<InspectorCommentsProvider inspectorComments={data.pirsInstance.data.inspectorComments}>
							<EntitiesForm
								entities={data.pirsInstance.metadata.attributes}
								values={data.pirsInstance.data.doc}
								instanceVersion={data.pirsInstance.data.instanceVersion}
								id={id}
								type={routeParams.type}
								caption={data.pirsInstance.metadata.caption}
								subCaptionPath={data.pirsInstance.metadata.nameAttributePath}
								updatedAt={
									data.pirsInstance.data.lastDocChangeInstant
										? parseIso(data.pirsInstance.data.lastDocChangeInstant)
										: null
								}
							/>
						</InspectorCommentsProvider>
					</MetadataProvider>
				) : (
					<ProgressRing />
				)}
			</div>
		</MainLayout>
	);
};

export default ResourceRegisterInstancePage;
