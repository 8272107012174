exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".NotificationsMessageContainer-module__container__18217 {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\theight: 400px;\r\n\twidth: 310px;\r\n}\r\n\r\n.NotificationsMessageContainer-module__error__27Q4N,\r\n.NotificationsMessageContainer-module__loading__3rnxg,\r\n.NotificationsMessageContainer-module__emptyNotifications__1PChq {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tjustify-content: center;\r\n\ttext-align: center;\r\n\twidth: 100%;\r\n\theight: 100%;\r\n}\r\n\r\n.NotificationsMessageContainer-module__list__2ZNoD {\r\n\tlist-style-type: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\twidth: 100%;\r\n\toverflow-y: auto;\r\n}\r\n", "",{"version":3,"sources":["C:\\Users\\andrew\\IdeaProjects\\cmu\\moni2\\src\\components\\Notifications\\NotificationsMessageContainer.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,aAAa;CACb,YAAY;AACb;;AAEA;;;CAGC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,kBAAkB;CAClB,WAAW;CACX,YAAY;AACb;;AAEA;CACC,qBAAqB;CACrB,SAAS;CACT,UAAU;CACV,WAAW;CACX,gBAAgB;AACjB","file":"NotificationsMessageContainer.module.css","sourcesContent":[".container {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\theight: 400px;\r\n\twidth: 310px;\r\n}\r\n\r\n.error,\r\n.loading,\r\n.emptyNotifications {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tjustify-content: center;\r\n\ttext-align: center;\r\n\twidth: 100%;\r\n\theight: 100%;\r\n}\r\n\r\n.list {\r\n\tlist-style-type: none;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\twidth: 100%;\r\n\toverflow-y: auto;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"container": "NotificationsMessageContainer-module__container__18217",
	"error": "NotificationsMessageContainer-module__error__27Q4N",
	"loading": "NotificationsMessageContainer-module__loading__3rnxg",
	"emptyNotifications": "NotificationsMessageContainer-module__emptyNotifications__1PChq",
	"list": "NotificationsMessageContainer-module__list__2ZNoD"
};