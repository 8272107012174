import React from 'react';
import {
	getComboboxItems,
	getCommonColumnProps,
	modelPossibleValueToHtml,
	modelPossibleValueToString,
	modelValueToPossibleValue,
} from './FullTableFormHelpers';
import {
	PossibleValue,
	TableColumn,
	TableColumnType,
	TableDictionaryControl,
	TableRelationType,
} from '../../queries-generated/types';
import DictionaryCombobox from '../controlls/DictionaryCombobox/DictionaryCombobox.formik';
import DictionarySelector from '../controlls/DictionarySelector/DictionarySelector.formik';
import OldDictionarySelector from '../controlls/OldDictionarySelector/OldDictionarySelector.formik';
import RecipientCombobox from '../controlls/RecipientCombobox/RecipientCombobox.formik';
import ArrayInput from '../pirsInputs/ArrayInput/ArrayInput.formik';
import TableFormSelector from '../controlls/TableFormSelector/TableFormSelector.formik';
import Checkbox from '../pirsInputs/Checkbox/Checkbox.formik';
import InputNumber from '../pirsInputs/InputNumber/InputNumber.formik';
import TextArea from '../pirsInputs/TextArea/TextArea.formik';
import AutoComplete from '../pirsInputs/AutoComplete/AutoComplete.formik';
import {possibleValueToHtml, possibleValueToString} from '../settings/views/ViewEdit/constructor/form/DashboardField';
import Selector from '../pirsInputs/Selector/Selector.formik';
import Combobox from '../pirsInputs/Combobox/Combobox.formik';
import MultiCombobox from '../pirsInputs/MultiCombobox/MultiCombobox.formik';
import {wordByCount} from '../../utils/word-by-count';
import Input from '../pirsInputs/Input/Input.formik';
import {useApolloClient} from '@apollo/client';
import useRefData from '../settings/views/ViewEdit/constructor/form/useRefData';
import {useFormikContext} from 'formik';
import InputFile from '../pirsInputs/InputFile/InputFile.formik';
import InputDateTime from '../pirsInputs/InputDateTime/InputDateTime.formik';

type Props = {
	filteredColumns: TableColumn[];
};

const FullTableFormFields: React.FC<Props> = ({filteredColumns}) => {
	const client = useApolloClient();
	const getAutocomplete = useRefData();
	const formikBag = useFormikContext();

	return (
		<>
			{filteredColumns.map(column => {
				let ResultInput;
				const commonProps = getCommonColumnProps(column);
				if (column.dictionary) {
					switch (column.dictionaryControl) {
						case TableDictionaryControl.Combobox:
							ResultInput = (
								<DictionaryCombobox
									dictionary={column.dictionary as string}
									dictionaryKeys={column.dictionaryKeys as string[]}
									dictionaryValues={column.dictionaryValues as string[]}
									{...commonProps}
								/>
							);
							break;
						default:
							ResultInput = (
								<DictionarySelector
									dictionary={column.dictionary}
									dictionaryKeys={column.dictionaryKeys}
									dictionaryValues={column.dictionaryValues}
									{...commonProps}
								/>
							);
					}
				} else if (column.oldDictionary) {
					ResultInput = (
						<OldDictionarySelector
							dictionary={column.oldDictionary}
							dictionaryKeys={column.dictionaryKeys}
							dictionaryValues={column.dictionaryValues}
							{...commonProps}
						/>
					);
				} else if (column.recipientTypeDictionary) {
					ResultInput = (
						<RecipientCombobox
							type={column.recipientTypeDictionary}
							param={(column.dictionaryKeys as string[])[0] || (column.dictionaryValues as string[])[0]}
							{...commonProps}
						/>
					);
				} else if (column.relation?.model === 'file') {
					ResultInput = <InputFile name={column.id} />;
				} else if (column.isArray && column.type) {
					ResultInput = (
						<ArrayInput {...commonProps} validation={column.validation} columnType={column.type} />
					);
				} else if (column.selectorUrl) {
					ResultInput = (
						<TableFormSelector multiple={column.multiSelector} {...commonProps} url={column.selectorUrl} />
					);
				} else if (column.type === TableColumnType.Boolean) {
					ResultInput = <Checkbox label={column.title} name={column.id} />;
				} else if (column.type === TableColumnType.Number || column.type === TableColumnType.Float) {
					ResultInput = <InputNumber {...commonProps} min={column.min} max={column.max} />;
				} else if (column.type === TableColumnType.Json || column.type === TableColumnType.MultiLineString) {
					ResultInput = <TextArea {...commonProps} />;
				} else if (column.type === TableColumnType.AutoComplete) {
					ResultInput = (
						<AutoComplete<any>
							{...commonProps}
							getItems={getAutocomplete({type: column.refType || '', minLength: 1})}
							itemToHtml={possibleValueToHtml}
							itemToString={possibleValueToString}
							onCustomChange={(value, originalValue) => {
								if (originalValue && typeof originalValue === 'object' && originalValue.code) {
									formikBag.setFieldValue(column.id, originalValue.code);
								} else {
									formikBag.setFieldValue(column.id, value);
								}
							}}
						/>
					);
				} else if (column.type === TableColumnType.DateTime) {
					ResultInput = <InputDateTime {...commonProps} />;
				} else if (column.possibleValues) {
					ResultInput = (
						<Selector<PossibleValue>
							{...commonProps}
							items={column.possibleValues}
							itemToString={item => modelPossibleValueToString(item)}
						/>
					);
				} else if (column.relation && column.relation.possibleValueMapper) {
					if ([TableRelationType.BelongsTo, TableRelationType.HasOne].includes(column.relation.type)) {
						ResultInput = (
							<Combobox<any>
								{...commonProps}
								getItems={getComboboxItems(
									column.relation.model,
									client,
									column.refQueryMinLength ?? 3,
									column.relation.params,
								)}
								itemToString={item =>
									modelPossibleValueToString(modelValueToPossibleValue(item, column))
								}
								itemToHtml={item => {
									return modelPossibleValueToHtml(modelValueToPossibleValue(item, column));
								}}
							/>
						);
					}
					if ([TableRelationType.HasMany, TableRelationType.ManyToMany].includes(column.relation.type)) {
						ResultInput = (
							<MultiCombobox<any>
								{...commonProps}
								getItems={getComboboxItems(
									column.relation.model,
									client,
									column.refQueryMinLength ?? 3,
									column.relation.params,
								)}
								itemToString={item =>
									modelPossibleValueToString(modelValueToPossibleValue(item, column))
								}
								itemToHtml={item => {
									return modelPossibleValueToHtml(modelValueToPossibleValue(item, column));
								}}
								blurHtml={values => {
									if (!values) return '';
									else if (values.length < 3)
										return values
											.map(value =>
												modelPossibleValueToString(modelValueToPossibleValue(value, column)),
											)
											.join(', ');
									else
										return `${modelPossibleValueToString(
											modelValueToPossibleValue(values[0], column),
										)} и еще ${wordByCount(
											values.length - 1,
											['другой', 'других', 'других'],
											true,
										)}`;
								}}
							/>
						);
					}
				} else {
					ResultInput = <Input {...commonProps} />;
				}
				return <li key={column.id}>{ResultInput}</li>;
			})}
		</>
	);
};

export default FullTableFormFields;
