import {
	AcrobatIcon,
	AudioIcon,
	CodeIcon,
	DocumentIcon,
	ExcelIcon,
	FileIcon,
	FolderIcon,
	ImageIcon,
	VideoIcon,
	WordIcon,
	ZipIcon,
} from './FilesIcons';
import React from 'react';

export const imagesTypes = ['image/gif', 'image/jpeg', 'image/png'];

const formats: {[key: string]: string[]} = {
	word: ['doc', 'docx', 'odt', 'ott'],
	zip: ['zip', 'rar'],
	pdf: ['pdf'],
	excel: ['xlsx', 'xlsm', 'xlsb', 'xltm', 'csv', 'dbf', 'slk', 'dif', 'xls', 'xlt', 'ods', 'ots'],
	image: ['jpg', 'jpeg', 'gif', 'tiff', 'bmp', 'png', 'ico', 'raw', 'webp', 'svg', 'odg'],
	code: ['html', 'htm', 'json', 'xml', 'js', 'css'],
	text: ['txt'],
	video: [
		'webm',
		'mkv',
		'vob',
		'ogv',
		'ogg',
		'avi',
		'ts',
		'mov',
		'qt',
		'wmv',
		'mp4',
		'm4v',
		'm4p',
		'mpg',
		'mp2',
		'mpeg',
		'mpe',
		'mpv',
		'3gp',
		'flv',
	],
	audio: [
		'aac',
		'ac3',
		'amr',
		'flac',
		'm3u',
		'm3u8',
		'm4a',
		'm4b',
		'm4p',
		'm4r',
		'mid',
		'midi',
		'mod',
		'mp3',
		'wav',
		'wave',
		'wma',
	],
};

export const getFileExtension = (fileName: string) => {
	const parts = fileName.split('.');
	return parts[parts.length - 1].toLowerCase();
};

export const getFileType = (fileName: string) => {
	const ext = getFileExtension(fileName);
	if (!ext) return 'file';
	const format = Object.keys(formats).find(format => formats[format].includes(ext)) as string;
	return format || 'file';
};

export const getFileTypeIcon = (type: string, width: number, height: number) => {
	switch (type) {
		case 'folder':
			return <FolderIcon width={width} height={height} />;
		case 'word':
			return <WordIcon width={width} height={height} />;
		case 'zip':
			return <ZipIcon width={width} height={height} />;
		case 'pdf':
			return <AcrobatIcon width={width} height={height} />;
		case 'excel':
			return <ExcelIcon width={width} height={height} />;
		case 'image':
			return <ImageIcon width={width} height={height} />;
		case 'code':
			return <CodeIcon width={width} height={height} />;
		case 'text':
			return <DocumentIcon width={width} height={height} />;
		case 'video':
			return <VideoIcon width={width} height={height} />;
		case 'audio':
			return <AudioIcon width={width} height={height} />;
		default:
			return <FileIcon width={width} height={height} />;
	}
};

export const readPartAsBase64Encode = part => {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			const base64data = reader.result?.toString() || '';
			const content = base64data.substring(base64data.indexOf(',') + 1);
			resolve(content);
		};
		reader.onerror = reject;
		reader.readAsDataURL(part);
	});
};

export function getInputFilesSize(files: FileList): number {
	let filesSize = 0;
	for (let i = 0; i < files.length; i++) {
		filesSize += files[i].size;
	}
	return filesSize;
}
