/**
 * Выводит список строк в человекочитаемом виде
 * ['a', 'b', 'c'] => "a, b и c"
 */
export function humanEnumeration(list: string[]) {
	if (!list || !list.length) {
		return '';
	}
	const len1 = list.length - 1;
	if (!len1) {
		return list[0];
	}
	return list.slice(0, len1).join(', ') + ' и ' + list[len1];
}

/**
 * Метод для преобразования объекта к queryString
 * Параметры со значением null|undefined выкидываются
 * Пустая строка записывается без "="
 * Остальное приводится к строке
 *
 * @param params
 * @return {string}
 *
 * @example
 * toQueryString({test: 'ok', DebugGds: ''}) === "test=ok&DebugGds"
 */
export function toQueryString(params: any) {
	let queryString = '';
	for (const key in params) {
		if ({}.hasOwnProperty.call(params, key) && params[key] != null) {
			if (queryString) queryString += '&';
			const value = params[key];
			queryString += encodeURIComponent(key) + (value === '' ? '' : '=' + encodeURIComponent(value));
		}
	}
	return queryString;
}

/**
 * Метод для преобразования queryString к объекту
 * @param queryString
 * @return {{}}
 */
export function parseQueryString(queryString: string) {
	const query: PlainObjectOf<string> = {};
	if (!queryString) return query;
	const queryStrings = queryString.split('&');
	for (let i = 0; i < queryStrings.length; i++) {
		const [key, value] = queryStrings[i].split('=');
		query[decodeURIComponent(key)] = (value && decodeURIComponent(value)) || '';
	}
	return query;
}

export function uppercaseFirstLetter(str: string) {
	return str.charAt(0).toUpperCase() + str.substr(1);
}

export function lowerizeFirstLetter(str: string) {
	return str.charAt(0).toLowerCase() + str.substr(1);
}

export function toStringArray(str?: string | string[] | number[]): string[] | undefined {
	if (!str) return undefined;

	const arr = Array.isArray(str) ? (str as Array<string | number>) : (str as string).split(',');

	return arr.map(s => (typeof s === 'number' ? s.toString() : s.trim())).filter(Boolean);
}

export function toNumberArray(str?: string | string[] | number[]): number[] | undefined {
	if (!str) return undefined;

	const arr = Array.isArray(str) ? (str as Array<string | number>) : (str as string).split(',');

	return arr.map(s => (typeof s === 'number' ? s : parseInt(s.trim(), 10))).filter(Boolean);
}
