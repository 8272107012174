import React, {useMemo} from 'react';
import css from './HighLightText.module.css';

type Props = {
	matches?: {start: number; end: number}[];
	value?: string;
};

const HighLightText: React.FC<Props> = ({matches, value = ''}) => {
	const highlightedText = useMemo(() => {
		if (matches && value) {
			let string = '';
			matches.forEach((val, index, array) => {
				const {start, end} = val;
				const a = index === 0 ? 0 : array[index - 1].end;
				const b = array.length - 1 === index ? value.length : end;
				string += `${value.slice(a, start)}<mark>${value.slice(start, end)}</mark>${value.slice(end, b)}`;
			});
			return string;
		}
		return value;
	}, [matches, value]);

	return <span className={css.text} dangerouslySetInnerHTML={{__html: highlightedText}} />;
};

export default HighLightText;
