import React, {useCallback} from 'react';
import Input from '../pirsInputs/Input/Input';
import css from './FullTablePagination.module.css';
import {FirstPage, LastPage, ChevronLeft, ChevronRight, Refresh} from '@material-ui/icons';
import Button from '../pirsInputs/Button/Button';

type Props = {
	onChangePage: (page: number) => void;
	onChangeItemsPerPage: (itemsPerPage: number) => void;
	onReload: () => void;
	itemsPerPage: number;
	page: number;
	total: number;
};

const FullTablePagination: React.FC<Props> = ({
	onChangePage,
	onChangeItemsPerPage,
	onReload,
	itemsPerPage,
	total,
	page,
}) => {
	const totalPages = Math.ceil(total / itemsPerPage) || 1;
	const handleChangeFirstPage = useCallback(() => {
		onChangePage(0);
	}, [onChangePage]);

	const handleChangePrevPage = useCallback(() => {
		onChangePage(page - 1 <= 0 ? 0 : page - 1);
	}, [onChangePage, page]);

	const handleChangeNextPage = useCallback(() => {
		onChangePage(page + 1 >= totalPages ? totalPages : page + 1);
	}, [onChangePage, page, totalPages]);

	const handleChangeLastPage = useCallback(() => {
		onChangePage(totalPages - 1);
	}, [onChangePage, totalPages]);

	const handleChangeInput = useCallback(
		value => {
			let newPage = parseInt(value, 10);
			if (isNaN(newPage)) return;
			newPage = newPage >= totalPages ? totalPages : newPage <= 1 ? 1 : newPage;
			onChangePage(newPage - 1);
		},
		[onChangePage, totalPages],
	);

	const handleChangeItemsPerPage = useCallback(
		value => {
			const intValue = parseInt(value, 10);
			const itemsPerPage = (!isNaN(intValue) && intValue > 0 && intValue) || 1;
			const totalPages = Math.ceil(total / itemsPerPage);
			if (page > totalPages) {
				onChangePage(totalPages);
			}
			onChangeItemsPerPage(itemsPerPage);
		},
		[onChangeItemsPerPage, onChangePage, page, total],
	);

	const currentItemsFrom = Math.min(page * itemsPerPage + 1, total);
	const currentItemsTo = Math.min(page * itemsPerPage + itemsPerPage, total);

	return (
		<ul className={css.wrapper}>
			<li>
				<Button action iconOnly disabled={page === 0} onClick={handleChangeFirstPage}>
					<FirstPage />
				</Button>
			</li>
			<li>
				<Button action iconOnly disabled={page === 0} onClick={handleChangePrevPage}>
					<ChevronLeft />
				</Button>
			</li>
			<li>
				Страница{' '}
				<Input
					value={(page + 1).toString()}
					underlined
					noButtons
					onChange={handleChangeInput}
					className={css.input}
				/>
				из {totalPages}
			</li>
			<li>
				<Button action iconOnly disabled={page === totalPages - 1} onClick={handleChangeNextPage}>
					<ChevronRight />
				</Button>
			</li>
			<li>
				<Button action iconOnly disabled={page === totalPages - 1} onClick={handleChangeLastPage}>
					<LastPage />
				</Button>
			</li>
			<li>
				Отображать{' '}
				<Input
					value={itemsPerPage.toString()}
					underlined
					noButtons
					onChange={handleChangeItemsPerPage}
					className={css.input}
				/>
			</li>
			<li>
				Показаны {currentItemsFrom} — {currentItemsTo} из {total}
			</li>
			<li>
				<Button action iconOnly onClick={onReload}>
					<Refresh />
				</Button>
			</li>
		</ul>
	);
};

export default FullTablePagination;
