import React from 'react';
import {Entity} from '../form/useMetadata';
import {castToType} from '../form/helper';

type Props = {
	entity: Entity;
	value: any;
};

const EntityValue: React.FC<Props> = ({entity, value}) => {
	return (
		<div>
			{entity.elementCaption || entity.caption}: {castToType(value, entity)}
		</div>
	);
};

export default EntityValue;
