import {ActionsUnion, createAction} from '../../../../../redux/helper';

export type State = {
	/**
	 * Флаг, указывает, что на элемент навели
	 */
	mouseOnId?: string;
	/**
	 * Перетаскиваемый элемент внутри текущего
	 */
	parentDragOnId?: string;
	/**
	 * Текущий ViewItem
	 */
	chosenViewItemId?: string;
};

export const initialState: State = {};

export enum ActionType {
	MOUSE_ENTER_COMPONENT = 'MOUSE_ENTER_COMPONENT',
	MOUSE_LEAVE_COMPONENT = 'MOUSE_LEAVE_COMPONENT',
	SET_PARENT_DRAG_ON = 'SET_PARENT_DRAG_ON',
	CHOOSE_VIEW_ITEM = 'CHOOSE_VIEW_ITEM',
}

export const actions = {
	mouseEnterComponent: (id: string) => createAction(ActionType.MOUSE_ENTER_COMPONENT, id),
	mouseLeaveComponent: () => createAction(ActionType.MOUSE_LEAVE_COMPONENT),
	setParentDragOn: (id?: string) => createAction(ActionType.SET_PARENT_DRAG_ON, id),
	chooseViewItem: (id?: string) => createAction(ActionType.CHOOSE_VIEW_ITEM, id),
};

export function reducer(state: State, action: ActionsUnion<typeof actions>): State {
	switch (action.type) {
		case ActionType.MOUSE_ENTER_COMPONENT:
			return {
				...state,
				mouseOnId: action.payload,
			};
		case ActionType.MOUSE_LEAVE_COMPONENT:
			return {
				...state,
				mouseOnId: undefined,
			};
		case ActionType.SET_PARENT_DRAG_ON:
			return {
				...state,
				parentDragOnId: action.payload,
			};
		case ActionType.CHOOSE_VIEW_ITEM:
			return {...state, chosenViewItemId: action.payload};
		default:
			return state;
	}
}
