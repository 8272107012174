import React, {useCallback} from 'react';
import css from './ResourceRegisterTablePagination.module.css';
import Selector from '../../../components/pirsInputs/Selector/Selector';
import Button from '../../../components/pirsInputs/Button/Button';
import {ChevronLeftIcon, ChevronRightIcon, RefreshIcon} from '../../../components/SvgIcon';

type Props = {
	hasPerv: boolean;
	hasNext: boolean;
	page: number;
	itemsPerPage: number;
	onChangePage: (page: number) => void;
	onChangeItemsPerPage: (perPage: number) => void;
	onReload: () => void;
};

const ResourceRegisterTablePagination: React.FC<Props> = ({
	page,
	onChangeItemsPerPage,
	onReload,
	itemsPerPage,
	onChangePage,
	hasNext,
	hasPerv,
}) => {
	const handleChangeItemsPerPage = useCallback(
		value => {
			if (value === null) return;
			const itemsPerPage = parseInt(value || 1, 10);
			onChangeItemsPerPage(itemsPerPage);
			onChangePage(0);
		},
		[onChangeItemsPerPage, onChangePage],
	);

	const handleChangePrevPage = useCallback(() => {
		onChangePage(page - 1);
	}, [onChangePage, page]);

	const handleChangeNextPage = useCallback(() => {
		onChangePage(page + 1);
	}, [onChangePage, page]);

	return (
		<ul className={css.wrapper}>
			<li>
				<Button iconOnly action disabled={!hasPerv} onClick={handleChangePrevPage}>
					<ChevronLeftIcon />
				</Button>
			</li>
			<li>
				<Button iconOnly action disabled={!hasNext} onClick={handleChangeNextPage}>
					<ChevronRightIcon />
				</Button>
			</li>
			Отображать
			<li>
				<Selector<string>
					className={css.itemsPerPage}
					value={itemsPerPage.toString(10)}
					items={['25', '50', '75', '100']}
					onChange={handleChangeItemsPerPage}
					itemToHtml={item => item}
					itemToString={item => item || ''}
					disableClear
					underlined
				/>
			</li>
			<li>
				<Button action iconOnly onClick={onReload}>
					<RefreshIcon />
				</Button>
			</li>
		</ul>
	);
};

export default ResourceRegisterTablePagination;
