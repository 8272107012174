import React from 'react';
import {
	Field,
	FieldControlType,
	FieldType,
	PossibleValue,
	PossibleValueMapper,
} from '../../../../../../queries-generated/types';
import InputNumber from '../../../../../pirsInputs/InputNumber/InputNumber.formik';
import Checkbox from '../../../../../pirsInputs/Checkbox/Checkbox.formik';
import Input from '../../../../../pirsInputs/Input/Input.formik';
import Selector from '../../../../../pirsInputs/Selector/Selector.formik';
import TextArea from '../../../../../pirsInputs/TextArea/TextArea.formik';
import InputDateTime from '../../../../../pirsInputs/InputDateTime/InputDateTime.formik';
import MultiCombobox from '../../../../../pirsInputs/MultiCombobox/MultiCombobox.formik';
import useRefData from './useRefData';
import comboboxCss from '../../../../../Comboboxes/Comboboxes.module.css';
import Combobox from '../../../../../pirsInputs/Combobox/Combobox.formik';
import CheckboxArray from '../../../../../pirsInputs/Checkbox/CheckboxArray.formik';
import LinkedFiltersSelector from '../CommonFilters/LinkedFiltersSelector';
import {InputLabel} from '../../../../../FullTable/FullTableFormHelpers';
import InputDate from '../../../../../pirsInputs/InputDate/InputDate.formik';
import {getItemsByInput} from '../../../../../pirsInputs/MultiCombobox/MultiCombobox';
import css from './DashboardFields.module.css';
import {FieldProps, getControlType} from '../../../components/helper';
import InputDateRange from '../../../../../pirsInputs/InputDateRange/InputDateRange.formik';
import useRelationData from './useRelationData';
import DashboardInputFiles from '../../../../../pirsInputs/DashboardInputFile/DashboardInputFiles.formik';
import DashboardInputFile from '../../../../../pirsInputs/DashboardInputFile/DashboardInputFile.formik';
import NullableBoolean from '../../../../../pirsInputs/NullableBoolean/NullableBoolean.formik';
import InputContacts from '../../../../../pirsInputs/InputContacts/InputContacts.formik';
import {useFormikContext} from 'formik';
import Password from '../../../../../pirsInputs/Password/Password.formik';

type Props = {
	field: Field & {name: string};
	userFilters?: boolean;
	linkedFilters?: boolean;
	fieldProps?: FieldProps;
};

export const possibleValueToHtml = (value: PossibleValue | null) => {
	return value ? (
		<div className={comboboxCss.item}>
			{value.iconText && <div className={comboboxCss.likeIcon}>{value.iconText}</div>}
			<div>
				<div>{value.firstLine || value.title}</div>
				<div>{value.secondLine}</div>
			</div>
		</div>
	) : null;
};

export const possibleValueToString = (value: PossibleValue | null) => (value ? value.title : '');

export const relationValueToPossibleValue = (
	value: any,
	possibleValueMapper: PossibleValueMapper,
): PossibleValue | null => {
	if (!value) return null;
	return {
		id: value[possibleValueMapper.id || 'id'],
		title: value[possibleValueMapper.title],
		firstLine: possibleValueMapper.firstLine && value[possibleValueMapper.firstLine],
		secondLine: possibleValueMapper.secondLine && value[possibleValueMapper.secondLine],
		iconText: possibleValueMapper.iconText && value[possibleValueMapper.iconText],
	};
};

export const relationValueToHtml = (possibleValueMapper: PossibleValueMapper) => {
	return value => possibleValueToHtml(relationValueToPossibleValue(value, possibleValueMapper));
};

export const relationValueToString = (possibleValueMapper: PossibleValueMapper) => {
	return value => possibleValueToString(relationValueToPossibleValue(value, possibleValueMapper));
};

const DashboardField: React.FC<Props> = ({field, userFilters, linkedFilters, fieldProps}) => {
	const getRefData = useRefData();
	const getRelationData = useRelationData();

	const formikBag = useFormikContext<any>();

	let disabled = false;

	if (fieldProps) {
		if (fieldProps.disabled !== undefined) {
			disabled = fieldProps.disabled;
		}
		if (
			fieldProps.enabled !== undefined &&
			((fieldProps.disabled !== undefined && !fieldProps.disabled) || fieldProps.disabled === undefined)
		) {
			disabled = !fieldProps.enabled;
		}
	}

	const props = {
		name: field.name,
		label: <InputLabel title={field.title} required={field.required} />,
		disabled: disabled || field.disabled,
		bottomText: (
			<>
				{field.description}
				{fieldProps?.helperTexts?.length && (
					<>
						<br />
						<span className={css.dependencyError}>
							{fieldProps?.helperTexts.map((text, index) => (
								<React.Fragment key={index}>
									{text}
									<br />
								</React.Fragment>
							))}
						</span>
					</>
				)}
			</>
		),
	};

	let input: JSX.Element | null = null;

	const controlType = getControlType(field);

	const id = formikBag?.values?.id;

	switch (controlType) {
		case FieldControlType.Number:
			input = <InputNumber {...props} min={field.min} max={field.max} step={field.step} />;
			break;
		case FieldControlType.CheckBox:
		case FieldControlType.Boolean:
			input = <Checkbox {...props} />;
			break;
		case FieldControlType.Select:
			input = field.possibleValues ? (
				<Selector<PossibleValue>
					items={field.possibleValues || []}
					itemToString={item => item?.title || ''}
					{...props}
				/>
			) : null;
			break;
		case FieldControlType.Combobox:
			let getItems: ((value: string) => any) | undefined;
			let itemToHtml: ((value: any) => React.ReactNode) | undefined;
			let itemToString: ((value: any) => string) | undefined;

			if (field.type === FieldType.Relation) {
				if (field.relation) {
					const {model, filters, possibleValueMapper} = field.relation;
					getItems = getRelationData({
						minLength: field.minLengthQuery || 0,
						model,
						filters,
						dependencyFilters: fieldProps?.filters,
					});

					if (possibleValueMapper) {
						itemToHtml = relationValueToHtml(possibleValueMapper);
						itemToString = relationValueToString(possibleValueMapper);
					}
				}
			} else {
				const {possibleValuesRef, possibleValues} = field;
				getItems = possibleValuesRef
					? getRefData({
							minLength: field.minLengthQuery || 0,
							type: possibleValuesRef,
							dependencyFilters: fieldProps?.filters,
					  })
					: possibleValues && possibleValues.length
					? getItemsByInput(possibleValues, possibleValueToString)
					: undefined;
				itemToHtml = possibleValueToHtml;
				itemToString = possibleValueToString;
			}

			if (getItems) {
				if (field.isArray) {
					input = (
						<MultiCombobox
							getItems={getItems}
							itemToHtml={itemToHtml}
							itemToString={itemToString}
							minLengthQuery={field.minLengthQuery}
							{...props}
						/>
					);
				} else {
					input = (
						<Combobox
							getItems={getItems}
							itemToHtml={itemToHtml}
							itemToString={itemToString}
							minLengthQuery={field.minLengthQuery}
							{...props}
						/>
					);
				}
			}
			break;
		case FieldControlType.TextArea:
			input = (
				<TextArea {...props} autoHeightAdjustable={field.autoHeightAdjustable} resizable={field.resizable} />
			);
			break;
		case FieldControlType.DateTime:
			input = <InputDateTime {...props} min={field.min} max={field.max} />;
			break;
		case FieldControlType.Date:
			input = <InputDate {...props} min={field.min} max={field.max} />;
			break;
		case FieldControlType.DateRangeDuration:
			const durations = field.possibleValues
				? field.possibleValues.map(value => ({duration: value.id, title: value.title}))
				: [];
			input = <InputDateRange {...props} durations={durations} />;
			break;
		case FieldControlType.String:
			input = <Input {...props} />;
			break;
		case FieldControlType.Contacts:
			input = <InputContacts {...props} directiveId={id} excludeDirectoryId={id} />;
			break;
		case FieldControlType.ContactsStateLess:
			input = <InputContacts {...props} excludeDirectoryId={id} />;
			break;
		case FieldControlType.File:
			input = field.isArray ? (
				<DashboardInputFiles validation={field.validation} max={field.max} {...props} />
			) : (
				<DashboardInputFile validation={field.validation} {...props} />
			);
			break;
		case FieldControlType.NullableBoolean:
			input = <NullableBoolean {...props} />;
			break;
		case FieldControlType.Password:
			input = <Password {...props} />;
			break;
		default:
			input = (
				<p>
					Компонент {field.type}/{field.controlType || 'clarified'} не поддерживается.
				</p>
			);
			break;
	}

	return (
		<div key={field.id} className={css.field}>
			{input}
			{userFilters && (
				<div className={comboboxCss.userFilter}>
					<CheckboxArray name={`filteredByUser`} arrayValue={field.id} label={'Использовать в фильтрах?'} />
				</div>
			)}
			{linkedFilters && <LinkedFiltersSelector field={field} />}
		</div>
	);
};

export default DashboardField;
