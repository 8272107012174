import React from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import Dropdown from '../Combobox/Dropdown';

export interface Props<T> extends CommonInputInterface<T | null> {
	/* список значений */
	items: T[];
	/* текущее значение */
	value: T | null;
	/* Название класса */
	className?: string;
	/* значение для input */
	itemToString?: (item: T | null) => string;
	/* значение для li */
	itemToHtml?: (item: T | null) => string | React.ReactNode;
	/* плейсхолдер */
	placeholder?: string;
	/* класс для выпадайки */
	popdownClassName?: string;
	/* класс для списка элементов */
	listClassName?: string;
	/* класс для инпута */
	inputClassName?: string;
	/* класс для инпута */
	inputStyle?: React.CSSProperties;
}

/**
 * Компонент для выбора из статичного списка
 * По аналогии с <select>
 */
function Selector<T>(props: Props<T>) {
	return (
		<Dropdown
			itemToString={value => (value as string | null) || ''}
			{...props}
			popoverClassName={props.popdownClassName}
		/>
	);
}

export default Selector;
